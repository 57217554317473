import { makeAutoObservable } from 'mobx';
import {
  clearPersistedStore,
  makePersistable,
  stopPersisting,
} from 'mobx-persist-store';
import { ClientManagementEnums } from '@aider/constants-library';
import type { RootStore } from './Store';

export default class ConnectionsStore {
  rootStore: RootStore;

  pendingConnections: boolean;

  previousConnectionCount: number;

  isReconnection: boolean;

  loading: boolean;

  addError: boolean = false;

  goingToXero: boolean = false;

  connectionUrl: string;

  initialConnection: boolean;

  connectionType: ClientManagementEnums.OSPKeys;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
    if (process.env.NODE_ENV !== 'test') {
      this.initStorePersistance();
    }
  }

  get newConnectionCount() {
    const count =
      this.rootStore.businessesStore.clientBusinesses.size
      - this.previousConnectionCount;
    if (count > 0) {
      this.loading = false;
    }
    return count;
  }

  async modalAddConnection() {
    await this.initiateConnection();
  }

  initStorePersistance() {
    makePersistable(this, {
      name: 'ConnectionsStore',
      properties: [
        'pendingConnections',
        'previousConnectionCount',
        'loading',
        'initialConnection',
      ],
      storage: window.localStorage,
    });
  }

  async clearStoredData() {
    await clearPersistedStore(this);
    await stopPersisting(this);
  }

  async initiateConnection() {
    this.goingToXero = true;
    this.previousConnectionCount =
      this.rootStore.businessesStore.clientBusinesses.size;
    this.pendingConnections = true;
    this.loading = true;
    const canConnect =
      await this.rootStore.authenticationStore.createConnectionWithBackend();

    return canConnect;
  }

  resetAddClient() {
    this.addError = false;
    this.pendingConnections = false;
    this.loading = false;
  }
}
