import React from 'react';
import { observer } from 'mobx-react';
import { Table, TableProps } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import AIP_DEMO from '../../../entities/icons/AIP_demo.png';
import { useStore } from '../../../stores/Store';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import { Routers } from '../../../models/enums/utils';

const format = new Format();

interface TableDataType {
  key: string;
  name: string;
  email: string;
  status: string;
  role: string;
}

const AdvisorContactsContent = () => {
  const { businessesStore, pageStore } = useStore();

  const colFilters = {
    name: [],
    email: [],
    status: ['Active', 'Inactive'],
    role: [],
  };

  if (businessesStore.selectedBusiness?.advisors) {
    businessesStore.selectedBusiness.advisors.forEach((advisor) => {
      const name = `${advisor.givenName} ${advisor.familyName}`;
      if (!colFilters.name.includes(name)) {
        colFilters.name.push(name);
      }
      if (!colFilters.email.includes(advisor.email)) {
        colFilters.email.push(advisor.email);
      }
      if (!colFilters.role.includes(advisor.roleKey)) {
        colFilters.role.push(advisor.roleKey);
      }
    });
  }

  const columns: TableProps<TableDataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
      filters: colFilters.name.sort((a, b) => a?.localeCompare(b)).map((text) => ({ text: format.formatValue({ format: ValueTypes.textUpperFirst, value: text }), value: text })),
      onFilter: (value, record) => record.name === value,
      filterSearch: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      className: 'email-column',
      filters: colFilters.email.sort((a, b) => a?.localeCompare(b)).map((text) => ({ text: text.toLowerCase(), value: text })),
      onFilter: (value, record) => record.email === value,
      filterSearch: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: colFilters.status.sort((a, b) => a?.localeCompare(b)).map((text) => ({ text: format.formatValue({ format: ValueTypes.textUpperFirst, value: text }), value: text })),
      onFilter: (value, record) => record.status === value,
      filterSearch: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => role.split('')[0].toUpperCase() + role.slice(1),
      sorter: (a, b) => a.role.localeCompare(b.role),
      filters: colFilters.role.sort((a, b) => a?.localeCompare(b)).map((text) => ({ text: format.formatValue({ format: ValueTypes.textUpperFirst, value: text }), value: text })),
      onFilter: (value, record) => record.role === value,
      filterSearch: true,
    }
  ];

  const data: TableDataType[] = businessesStore.selectedBusiness?.advisors?.map((advisor) => ({
    key: advisor.id,
    name: format.formatValue({ format: ValueTypes.textUpperFirst, value: `${advisor.givenName} ${advisor.familyName}` }),
    email: advisor.email.toLowerCase(),
    status: format.formatValue({ format: ValueTypes.textUpperFirst, value: 'Active' }),
    role: format.formatValue({ format: ValueTypes.textUpperFirst, value: advisor.roleKey })
  })) || [];

  return (
    <>
      <header className='advisor-contacts__header'>
        <span>
          <h2>Advisor contacts</h2>
          <p>These are the advisors with access to this client on the advisory platform</p>
        </span>
        <img src={AIP_DEMO} alt='AIP demo' />
      </header>

      <Table
        loading={businessesStore.fetchingBusinessAdvisors}
        className='advisor-contacts__table scrollbar'
        columns={columns}
        dataSource={data}
        pagination={{
          className: 'aider-pagination',
          position: ['topLeft', 'bottomLeft'],
          defaultPageSize: 50,
          showSizeChanger: false,
          hideOnSinglePage: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} advisors`,
        }}
      />
      <footer className='advisor-contacts__footer'>
        <ButtonPrimary
          type='link'
          icon={<RightOutlined />}
          mixpanelEvent='Advisor Contacts: Manage Permissions'
          mixpanelProperties={{ businessId: businessesStore.selectedBusiness?.id }}
          onClick={() => {
            NiceModal.hide('AdvisorContactsModal');
            pageStore.setNavigateTo(Routers.PERMISSIONS);
          }}
        >Manage existing advisor permissions
        </ButtonPrimary>
        <ButtonPrimary
          type='link'
          icon={<RightOutlined />}
          mixpanelEvent='Advisor Contacts: Invite Advisor'
          mixpanelProperties={{ businessId: businessesStore.selectedBusiness?.id }}
          onClick={() => {
            NiceModal.hide('AdvisorContactsModal');
            pageStore.setNavigateTo(Routers.ADVISORS);
          }}

        >
          Invite new advisor
        </ButtonPrimary>
      </footer>
    </>
  );
};

export default observer(AdvisorContactsContent);
