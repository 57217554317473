import React, { useEffect } from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { RightOutlined } from '@ant-design/icons';
import AiderBrand from '../components/icons/AiderBrand';
import WarningIllustration from '../components/icons/WarningIllustration';
import { useStore } from '../stores/Store';
import { mailToHref } from '../lib/supportHelpers';
import handleError from '../lib/errorHandler';

const ErrorBoundary = () => {
  const error:any = useRouteError();
  const rootStore = useStore();
  const errorId = uuid();

  /** Create the email copy */
  const errorSubject = 'Aider: Broken Link';
  const errorBody = `${
    'Hi Aider,\n\n'
    + 'I got an error message when I was using the Aider Advisory Platform.\n\n'
    + `The error message appeared on the page: ${window.location.href}\n\n`
    + 'The steps that led to this error were:\n'
    + '[Please provide details here]\n\n'
    + 'Regards,\n'
  }`;

  const technicalDetails = [
    { key: 'Operation', value: 'Router Error Encountered' },
    { key: 'Transaction', value: errorId },
    { key: 'Error', value: error?.message },
  ];

  const mailto = mailToHref({ subject: errorSubject, body: errorBody, technicalDetails, rootStore });

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary', error?.stack);

    // Log to sentry
    handleError({ error, operation: 'Router Error Encountered', transaction: errorId, status: error?.message });
  }, [error, errorId]);

  return (
    <div className='main'>
      <div className='page-not-found wrapper'>
        <Link to='/'>
          <AiderBrand className='page-not-found--brand' aria-label='Aider Advisory Service' />
        </Link>
        <WarningIllustration className='page-not-found--warning' aria-label='Warning' />
        <main className='page-not-found--content'>
          <h1>Oops something went wrong!</h1>
          <ul>
            <li>
              Please <a href={mailto}>report this issue</a> to our
              Customer Success team. Thank you!
            </li>
            <li>
              <Link to='/'>Return to the Advisory Platform <RightOutlined /></Link>
            </li>
          </ul>
          <pre className='errorCode scrollbar'>
            {error?.message}
          </pre>
        </main>
      </div>
    </div>
  );
};

export default ErrorBoundary;
