import React, { useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface ContentEditorProps {
  content: string;
  onChange: (content: string) => void;
}

const ContentEditor: React.FC<ContentEditorProps> = ({ content, onChange }) => {
  const [state, setState] = useState(
    EditorState.createWithContent(stateFromMarkdown(content))
  );

  return (
    <Editor
      editorState={state}
      onEditorStateChange={(newState) => {
        setState(newState);
        onChange(stateToMarkdown(newState.getCurrentContent()));
      }}
      editorStyle={{ width: '100%', height: 200, border: '1px solid #f1f1f1', padding: '0 5px' }}
      toolbar={{
        options: ['inline', 'blockType', 'list'],
        inline: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['bold', 'italic'],
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2', 'H3'],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
        list: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['unordered', 'ordered'],
        },
      }}
    />
  );
};

export default ContentEditor;
