/**
 * Helper function to obtain the active / pending status from a
 * **react-router-dom** NavLink component.
 * @param {boolean} isPending
 * @param {boolean} isActive
 * @returns {string} - The class name to be applied to the NavLink component.
 * @usage ```<NavLink className=`nav-class ${navLinkStatus}` />```
 */
export const navLinkStatus = ({ isPending, isActive }) => {
  if (isPending) return 'pending';
  if (isActive) {
    return 'active';
  }
  return '';
};
