import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import ActivateClientCommonContent from './ActivateClientCommonContent';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import { useStore } from '../../../stores/Store';
import { Routers } from '../../../models/enums/utils';

const ActivateClientBespokeContent = ({ subscription }) => {
  const { pageStore } = useStore();
  const [buttonLoading, setButtonLoading] = React.useState(false);

  return (
    <>
      <ActivateClientCommonContent newConnectionCount={subscription.newClientCount - subscription.previousClientCount} />
      <h4>Your practice is now on bespoke enterprise pricing.</h4>
      <p>
        Please contact Customer Success to confirm your bespoke pricing and unlock
        even more savings.
      </p>
      <ButtonPrimary
        loading={buttonLoading}
        disabled={buttonLoading}
        onClick={async () => {
          await setButtonLoading(true);
          await pageStore.setNavigateTo(Routers.CONTACT);
          NiceModal.hide('ActivateClientBespokeModal');
        }}
      >Contact Us!
      </ButtonPrimary>
    </>
  );
};

export default ActivateClientBespokeContent;
