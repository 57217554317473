import React from 'react';
import { v4 as uuid } from 'uuid';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import { useStore } from '../../../stores/Store';
import { truncateString } from '../../../lib/stringMutation';
import { mailToHref } from '../../../lib/supportHelpers';
import AlertIllustration from '../../illustrations/AlertIllustration';

interface IDeleteRuleErrorProps {
  rule: any;
  error: unknown;
  checklistId: string;
}

const DeleteRuleError = ({ rule, error, checklistId }:IDeleteRuleErrorProps) => {
  // Need to refactor out the business store once we migrate the checklists to the new store

  const rootStore = useStore();
  const { userStore, businessesStore, businessStore } = rootStore;
  const tableGroup = businessStore.checkListData.tableGroups.find((tg) => tg.tableGroupId === checklistId);

  const errorId = uuid();
  const emailSubject = 'Error deleting data check';
  const emailBody = 'Hi Aider,\n'
  + 'I am trying to delete a data check but I am getting an error.\n'
  + 'Please can you help me delete this data check?\n\n'
  + 'Thanks,\n'
  + `${userStore.givenName} ${userStore.familyName}`;

  const technicalDetails = [
    {
      key: 'Error ID',
      value: errorId,
    },
    {
      key: 'Error',
      value: JSON.stringify(error),
    },
    {
      key: 'Table Group Period Name',
      value: tableGroup?.tableGroupPeriod?.periodName,
    },
    {
      key: 'Table Group Section',
      value: rule.section,
    },
    {
      key: 'Rule Title',
      value: rule.title,
    },
    {
      key: 'Rule Show',
      value: rule.rule.show,
    },
    {
      key: 'Rule Criteria',
      value: rule.rule.criteria,
    },
    {
      key: 'Rule Value',
      value: rule.rule.value,
    },
    {
      key: 'Rule Accounts Count',
      value: rule.rule.from.length,
    },
  ];

  const trackContact = () => {
    trackMixpanelEvent({
      description: 'Delete Rule - Contact Email',
      properties: {
        errorID: errorId,
        error,
        businessName: businessesStore.selectedBusiness.name,
        businessId: businessesStore.selectedBusinessId,
        tableGroupPeriodName: tableGroup?.tableGroupPeriod?.periodName,
        checklistId,
      },
      rootStore,
    });
  };

  return (
    <section className='rule-modal__body centered-modal'>
      <div className='illustration'>
        <AlertIllustration />
      </div>
      <h3>
        Oops, we were unable to delete
        <br />
        &quot;{truncateString(rule?.title, 33)}&quot;
      </h3>
      <p>
        You can try deleting again.
        <br />
        If the problem persists, please{' '}
        <a
          onClick={trackContact}
          href={mailToHref({
            subject: emailSubject,
            body: emailBody,
            rootStore,
            technicalDetails,
          })}
        >
          contact us for help
        </a>
        .
      </p>
    </section>
  );
};

export default DeleteRuleError;
