import * as React from 'react';
import { HelpItemData, HelpType } from '../../entities/helpText';
import minus from '../../entities/icons/minus-circle.png';
import plus from '../../entities/icons/plus-circle.png';
import { rootStore } from '../../stores/Store';

function isVisible(item: HelpItemData) {
  return rootStore.helpPanelStore.visibleHelpItems.includes(item.subTitle);
}

function renderBody(item: HelpItemData) {
  if (item.type === HelpType.LIST) {
    return item.headings.map((heading, ix) => (
      <div>
        <ul>
          <li className='disc-item'>{heading}</li>
        </ul>
        <p>{item.content[ix]}</p>
      </div>
    ));
  } if (item.type === HelpType.HTML) {
    return <div dangerouslySetInnerHTML={{ __html: item.body }} />;
  }
  return item.body;
}
interface Props {
  helpItem: HelpItemData;
}

const HelpItem = ({ helpItem }: Props) => (
  <div style={{ borderBottom: '1px solid #E1E1E5' }}>
    <div
      style={{
        borderTop: '1px solid #E1E1E5',
        padding: 12,
        height: 65,
        paddingTop: 25,
        lineHeight: '30px',
        fontSize: 14,
        color: '#0A1E70',
        fontWeight: 700,
      }}
    >
      {helpItem.subTitle}
      { /* eslint-disable-next-line max-len  */}
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
      <div
        onClick={() => {
          rootStore.helpPanelStore.toggleHelpItemVisibility(helpItem.subTitle);
        }}
        style={{ float: 'right', cursor: 'pointer' }}
      >
        {' '}
        <img width={24} src={isVisible(helpItem) ? minus : plus} alt='' />
      </div>
    </div>
    <div
      className={`collapseEase ${isVisible(helpItem) ? 'easeOpen' : ''}`}
      style={{
        whiteSpace: 'break-spaces',
        paddingTop: 0,
        marginBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 14,
        color: '#272830',
        fontWeight: 400,
        height: isVisible(helpItem) ? 'auto' : 0,
        overflow: 'hidden',
      }}
    >
      {renderBody(helpItem)}
      <br />

      {helpItem.table && (
      <table>
        <thead>
          <tr>
            {helpItem.table.headings.map((heading) => <th>{heading}</th>)}
          </tr>
        </thead>
        <tbody>
          {helpItem.table.content.map((contentRow) => (
            <tr style={{ borderTop: '1px solid #E1E1E5' }}>
              <td>{contentRow.cellOne}</td>
              <td>{contentRow.cellTwo}</td>
            </tr>
          ))}
        </tbody>
      </table>
      )}
    </div>
  </div>
);

export default HelpItem;
