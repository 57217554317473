// TODO: Refactor this file to remove all the eslint-disable
import * as Sentry from '@sentry/browser';
import cookie from 'js-cookie';
import { makeAutoObservable } from 'mobx';

// eslint-disable-next-line import/no-cycle
import AuthService from '../../services/authService';

export default class AuthStore {
  rootStore: any;

  initializeFirebaseApp: any;

  initializeTokens: any;

  handleSignIn: any;

  handleSignOut: any;

  signUpUsingEmail: any;

  signUpUsingSocial: any;

  initiateConnection: any;

  PaywallRedirect: any;

  PaywallRedirectCancellation: any;

  authService: AuthService;

  getUserDetails: Function;

  loadUserData: Function;

  impersonateUser: Function;

  stopImpersonatingUser: Function;

  constructor(initStore) {
    this.rootStore = initStore;
    this.authService = new AuthService(this.rootStore);
    this.initializeFirebaseApp = this.authService.initializeFirebaseApp;
    this.initializeTokens = this.authService.initializeTokens;
    this.initiateConnection = this.authService.initiateConnection;
    this.handleSignOut = this.authService.handleSignOut;
    this.loadUserData = this.authService.loadUserData;
    this.getUserDetails = this.authService.getUserDetails;
    this.impersonateUser = this.authService.impersonateUser;
    this.stopImpersonatingUser = this.authService.stopImpersonatingUser;
    this.PaywallRedirect = this.authService.PaywallRedirect;
    this.PaywallRedirectCancellation =
      this.authService.PaywallRedirectCancellation;

    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }

  config = {};

  loginType = '';

  loading = true;

  shallowLoading = true;

  tokenRegistry = [
    {
      id: '123',
      tokens: {
        id_token: 'nothing here',
        expiresAt: new Date(),
        access_token: '',
        refresh_token: '',
      },
    },
  ];

  signInError = null;

  businessNavigatorPopupOpen = true;

  notificationsPopupOpen = true;

  messagingPopUpOpen = true;

  trainAiderPopUpOpen = true;

  favoritesPopupOpen = true;

  actionCentrePopupOpen = true;

  currentConversation = {};

  redirected = false;

  connectionCount = null;

  trustCount = null;

  isAuthed = false;

  authResult = {};

  user = null;

  authenticatedUser = null;

  partnerCustomerTokenInRedirectURL = null;

  links = {};

  // eslint-disable-next-line camelcase
  access_token = '';

  // eslint-disable-next-line camelcase
  refresh_token = '';

  // eslint-disable-next-line camelcase
  id_token = '';

  // eslint-disable-next-line camelcase
  expires_in = '';

  practiceUser = null;

  practiceUserDetail = null;

  userModalErrors = null;

  GAInitialized = false;

  dropdownOpen = false;

  userTriggeredSSO = false;

  editUserModalVisible = false;

  exceededQuota = false;

  subscriptionCancellation = false;

  subscriptionCreationError = false;

  renderAppAccountKickback = false;

  takeMeToXero = false;

  bulkConnectionSuccess = false;

  fallbackPaymentModal = false;

  authDetail = null;

  utm = {};

  editAdvisorsModalVisible = false;

  bulkConnectionsStatus = false;

  showConfigureAccessTab = false;

  advisorTokens = {
    access_token: '',
    refresh_token: '',
    id_token: '',
  };

  persistIsAuthed = false;

  persistAccessToken = null;

  persistExpiry = null;

  persistIdToken = null;

  persistRefreshToken = null;

  persistExpiresIn = null;

  isReconnection = null;

  get isLoading() {
    return this.loading;
  }

  setAuthDetail(authDetail) {
    this.authDetail = authDetail;
  }

  setBulkConnectionsStatus(status) {
    this.bulkConnectionsStatus = status;
  }

  setReconnectionStatus(name) {
    this.isReconnection = name;
  }

  setUserTriggeredSSO(didUserTriggerSSO) {
    this.userTriggeredSSO = didUserTriggerSSO;
  }

  setLoginType(type) {
    cookie.set('userHasLoggedIn', 'true', { secure: true });
    this.loginType = type;
  }

  invalidateCacheButKeepUserAuth() {
    cookie.remove('redirect');
    this.renderAppAccountKickback = false;
    this.userTriggeredSSO = false;
    this.loginType = '';
    this.bulkConnectionSuccess = false;
    this.rootStore.businessStore.invalidateBusinessList();
  }

  invalidateCache() {
    cookie.remove('redirect');
    cookie.remove('userHasLoggedIn');
    this.renderAppAccountKickback = false;
    this.userTriggeredSSO = false;
    this.loginType = '';
    this.persistIsAuthed = false;
    this.persistAccessToken = null;
    this.persistExpiry = null;
    this.persistIdToken = null;
    this.persistRefreshToken = null;
    this.bulkConnectionSuccess = false;
    this.bulkConnectionsStatus = false;
    this.rootStore.businessStore.invalidateBusinessList();
  }

  hasExceededQuota(exceeded) {
    this.exceededQuota = exceeded;
  }

  renderFallbackPaymentModal(fallback) {
    this.fallbackPaymentModal = fallback;
  }

  setAcquisitionData(utm) {
    cookie.set('utm', JSON.stringify(utm), { secure: true });
    this.utm = utm;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setShallowLoader(loading: boolean) {
    this.shallowLoading = loading;
  }

  setRenderAppAccountKickback(renderKickback) {
    this.renderAppAccountKickback = renderKickback;
  }

  setPracticeUser(user) {
    this.practiceUser = user;
  }

  setPracticeUserDetail(user) {
    this.practiceUserDetail = user;
  }

  LogoutAndRedirect(redirectURL) {
    this.handleSignOut(true).then(() => {
      window.location.href = redirectURL;
    });
  }

  closeActionCentrePopup() {
    this.actionCentrePopupOpen = false;
  }

  renderSubscriptionCancellation(showConfirmation) {
    this.subscriptionCancellation = showConfirmation;
  }

  renderSubscriptionCreationError(showConfirmation) {
    this.subscriptionCreationError = showConfirmation;
  }

  closeNotificationsPopup() {
    this.notificationsPopupOpen = false;
  }

  closeMessagingPopup() {
    this.messagingPopUpOpen = false;
  }

  closeTrainAiderPopup() {
    this.trainAiderPopUpOpen = false;
  }

  setBulkConnectionSuccess(
    connectionSuccess,
    activeModal?,
    trustCount?,
    connectionCount?,
    successArray?
  ) {
    this.authService.handleBulkConnectionSuccess(
      connectionSuccess,
      successArray
    );
    this.connectionCount = connectionCount;
    this.trustCount = trustCount;
    this.bulkConnectionSuccess = connectionSuccess;
  }

  closeFavoritesPopup() {
    this.favoritesPopupOpen = false;
  }

  closeBusinessNavigatorPopup() {
    this.businessNavigatorPopupOpen = false;
  }

  validateUserModal(error) {
    this.userModalErrors = error;
  }

  setConfig(config) {
    this.config = config;
  }

  setSignInError(error) {
    this.signInError = error;
  }

  isUserAuthenticated(authResult) {
    this.persistIsAuthed = authResult !== 'undefined' || authResult !== false;
    this.isAuthed = typeof authResult !== 'undefined' || authResult !== false;
    this.authResult = authResult;
    this.loading = false;
  }

  setUser(user) {
    this.user = user;
    if (user !== null) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: (user && user.uid) || null,
          email: (user && user.email) || null,
          name: (user && user.name) || null,
        });
        // @ts-ignore
        scope.setLevel('warning');
      });
    }
  }

  setAuthenticatedUser(user) {
    this.authenticatedUser = user;
  }

  setPartnerCustomerTokenInRedirectURL(cat) {
    this.partnerCustomerTokenInRedirectURL = cat;
  }

  registerLinks(links) {
    this.links = links;
  }

  triggerDropdownOpen(isOpen) {
    this.dropdownOpen = isOpen;
  }

  setConfigureAccessTab(isOpen) {
    this.showConfigureAccessTab = isOpen;
  }

  renderAdvisorsModal(isOpen, redirect?) {
    if (redirect === 'share') {
      this.editAdvisorsModalVisible = true;
      // eslint-disable-next-line no-return-assign
      return (this.showConfigureAccessTab = true);
    }

    if (redirect === 'invite') {
      this.editAdvisorsModalVisible = true;
      // eslint-disable-next-line no-return-assign
      return (this.showConfigureAccessTab = false);
    }

    if (
      this.editAdvisorsModalVisible
      && cookie.get('inviteAdvisors') === 'true'
    ) {
      cookie.remove('inviteAdvisors');
    }

    // eslint-disable-next-line no-return-assign
    return (this.editAdvisorsModalVisible = isOpen);
  }

  renderEditUserModal(isOpen) {
    if (!isOpen) {
      this.editAdvisorsModalVisible = false;
    }
    this.editUserModalVisible = isOpen;
  }

  /* eslint-disable camelcase */
  setTokens(access_token, refresh_token, expires_in, id_token) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.id_token = id_token;
    this.expires_in = expires_in;
  }

  /* eslint-disable camelcase */
  persistTokens(
    access_token,
    refresh_token,
    expires_in,
    id_token
  ) {
    this.persistAccessToken = access_token;
    this.persistRefreshToken = refresh_token;
    this.persistIdToken = id_token;
    this.persistExpiresIn = expires_in;
    this.persistExpiry = Math.floor(Date.now() / 1000) + expires_in;
  }

  get tokens() {
    return {
      accessToken: this.access_token,
      refreshToken: this.refresh_token,
      idToken: this.id_token,
      expiresIn: this.expires_in,
    };
  }
}
