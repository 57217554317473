// TODO: Refactor this file to remove all the eslint-disable
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import React, { useEffect, useRef } from 'react';

import { toJS } from 'mobx';
import moment from 'moment';
import { rootStore } from '../stores/Store';

import tick from './icons/tick_upscale.png';
import { BUSINESS_NAME_REGEX_TOAST } from './types';
import Mixpanel from '../lib/mixpanel';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useHasChanged = (val: any) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};
export function weekOfMonth(input = moment()) {
  const firstDayOfMonth = input.clone().startOf('month');
  const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

  const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

  return Math.ceil((input.date() + offset) / 7);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function renderDueDateStyle(dueDate) {
  return 'ac-standard';
}

export function renderDueDate(dueDate) {
  return dueDate;
}

export function renderAlertLevelStyle(alertLevel) {
  switch (alertLevel) {
    case 1:
      return 'ac-border-urgent';
    case 0:
    default:
      return 'ac-border-success';
  }
}

export function logActionEvent(selectedAction) {
  let eventName = selectedAction;
  switch (selectedAction) {
    case 'incomeTax':
      eventName = 'send_estimateIncomeTax';
      break;
    case 'invoiceStatus':
      eventName = 'send_checkInvoiceBills';
      break;
    case 'gst':
      eventName = 'send_estimateGST';
      break;
    case 'gst-due-dates':
      eventName = 'send_dueDatesGST';
      break;
    case 'cashFlow':
      eventName = 'send_checkCashflow';
      break;
    case 'reconciliation':
      eventName = 'send_requestReconciliation';
      break;
    default:
      break;
  }
  const eventData = {
    businessId: rootStore.businessStore.selectedBusinessId,
    businessName: rootStore.businessStore.selectedBusinessName,
  };
  Mixpanel.track(eventName, eventData);
}

function renderDisplayIcon(user) {
  return `${user.givenName && user.givenName.toUpperCase()[0]}${user.familyName && user.familyName.toUpperCase()[0]
  }`;
}
export function renderUserAvatar(user) {
  return (
    <div
      className='z-depth-item'
      style={{
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        marginBottom: 10,
        justifyContent: 'center',
        color: '#0d206c',
        backgroundColor: '#fff',
        borderRadius: '50%',
        height: 40,
        width: 40,
      }}
    >
      <span style={{ color: '#0d206c' }}>{renderDisplayIcon(user)}</span>
    </div>
  );
}

export function renderCellAlertLevel(alertLevel) {
  switch (alertLevel) {
    case 0:
      return '';
    case -1:
      return 'summaryAlert';
    case 1:
      return 'friendlyAlert';
    case 2:
      return '';
    default:
      return '';
  }
}

export function renderToastTitle(selectedAction) {
  const { title } = selectedAction;
  const body = title.slice(5);
  const head = body.charAt(0).toUpperCase();
  const tail = body.slice(1);
  return `${head}${tail}`;
}

export function renderLowercaseDataType(type: string) {
  switch (type) {
    case 'cashflow':
      return 'cash flow';
    case 'compliance':
      return 'compliance';
    case 'profitability':
      return 'profitability';
    case 'health':
    default:
      return 'data health';
  }
}

export function renderUppercaseDataType(type: string) {
  switch (type) {
    case 'cashflow':
      return 'Cash Flow';
    case 'compliance':
      return 'Compliance';
    case 'profitability':
      return 'Profitability';
    case 'health':
    default:
      return 'Data Health';
  }
}

export function renderDataType(type: string) {
  switch (type) {
    case 'cashflow':
      return 'Cashflow';
    case 'compliance':
      return 'Compliance';
    case 'profitability':
      return 'Profitability';
    case 'advisory':
      return 'Advisory';
    case 'health':
    default:
      return 'DataHealth';
  }
}

export function renderToastBody(toastTitle, selectedBusinessName, userName?) {
  return (
    <div style={{ position: 'absolute', display: 'flex' }}>
      <div
        style={{
          width: 40,
          height: 40,
          position: 'relative',
          top: 8,
          marginRight: 5,
        }}
      >
        <img height={35} width={35} src={tick} alt='Check Icon' />
      </div>
      <div>
        <div
          style={{
            fontSize: 16,
            width: '100%',
            height: 20,
            paddingTop: 5
          }}
        >
          {toastTitle}
        </div>
        <div
          style={{
            fontSize: 16,
            width: '100%',
            height: 30,
            paddingTop: 5,
          }}
        >
          Sent successfully to
          {' '}
          {userName
            ? userName.replace(BUSINESS_NAME_REGEX_TOAST, '$1...')
            : selectedBusinessName.replace(BUSINESS_NAME_REGEX_TOAST, '$1...')}
          .
        </div>
      </div>
    </div>
  );
}
function renderToastDetailBasedOnChanges(levelOfConfiguration) {
  let finalString = '';
  if (levelOfConfiguration.includes('taxAgent')) {
    finalString += 'Tax agent setting saved.\n';
  }
  if (levelOfConfiguration.includes('taxRate')) {
    finalString += 'Tax rate saved.\n';
  }
  if (levelOfConfiguration.includes('accounts')) {
    finalString += 'Bank accounts saved.\n';
  }
  return finalString;
}
export function buildDataChangeLevel(
  fields,
  businessDetails,
  selectedBankAccounts
) {
  const levelOfChange = [];
  if (businessDetails.profile.hasSelectedBankAccount) {
    /**
     * There are previously saved accounts
     * and the new selection differs from the previous selection
     */
    if (
      !!toJS(selectedBankAccounts)
      && toJS(selectedBankAccounts.length)
      !== businessDetails.profile.hasSelectedBankAccount.length
    ) {
      levelOfChange.push('accounts');
    }
  } else if (selectedBankAccounts?.length) {
    // if there are no accounts saved previously but there is a new selection of accounts to save.
    levelOfChange.push('accounts');
  }

  if (fields.taxRate !== businessDetails.profile.taxRate) {
    // if the tax rate in the business doc differs from the selection
    levelOfChange.push('taxRate');
  }
  if (
    !!fields.taxAgent
    && fields.taxAgent !== businessDetails.profile.taxAgent
  ) {
    // if the tax agent selection exists and differs from the selection in the profile
    console.log(fields.taxAgent, toJS(businessDetails.profile.taxAgent));
    levelOfChange.push('taxAgent');
  }
  return levelOfChange;
}
export function renderUpdateToastBody(
  toastTitle,
  selectedBusinessName,
  levelOfConfiguration
) {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: 40,
          minHeight: 50,
          position: 'relative',
          top: 8,
          marginRight: 5,
        }}
      >
        <img height={35} width={35} src={tick} alt='Checkmark Icon' />
      </div>
      <div>
        <div style={{ fontSize: 12, width: '100%', paddingTop: 0 }}>
          {renderToastDetailBasedOnChanges(levelOfConfiguration)}
        </div>
        <div style={{ fontSize: 12, width: '100%', paddingTop: 2 }}>
          Updating insights - please wait.
          <br />
        </div>
      </div>
    </div>
  );
}

// Need a better place for this processing of field data, this is happening in multiple locations accessing the same data structure
// check business store and business service for client fields
// This is used when opening the client settings modal from src/actions line 89
export function constructFields(businessDetail, e, isBas?) {
  try {
    let field;
    if (businessDetail && !isBas) {
      field = {
        selectedBankAccounts:
          (businessDetail.profile
            && businessDetail.profile.hasSelectedBankAccount)
          || (e.profile && e.profile.hasSelectedBankAccounts)
          || [],

        taxRate:
          (businessDetail.profile && businessDetail.profile.taxRate)
          || (e.profile && e.profile.taxRate)
          || '',
        lineOfBusiness:
          (businessDetail.data && businessDetail.data.lineOfBusiness) || '',
        taxNumber: (businessDetail.data && businessDetail.data.taxNumber) || '',
        salesTaxBasis:
          (businessDetail.data && businessDetail.data.salesTaxBasis) || '',
        salesTaxPeriod:
          (businessDetail.data && businessDetail.data.salesTaxPeriod) || '',
        financialYearEnd:
          (businessDetail.data
            && `${businessDetail.data.financialYearEnd}/YYYY`)
          || '',
        address:
          (businessDetail.profile && businessDetail.profile.address)
          || (e.profile && e.profile.address)
          || (businessDetail.data && businessDetail.data.address)
          || '  ',
        phoneNumber:
          (businessDetail.profile && businessDetail.profile.phoneNumber)
          || (e.profile && e.profile.phoneNumber)
          || (businessDetail.data && businessDetail.data.phoneNumber)
          || '  ',
        // config
        operationalExpensesSource:
          (businessDetail.profile
            && businessDetail.profile.operationalExpensesSource)
          || (e.profile && e.profile.operationalExpensesSource)
          || '',
        salesSource:
          (businessDetail.profile && businessDetail.profile.salesSource)
          || (e.profile && e.profile.salesSource)
          || '',
        purchasesSource:
          (businessDetail.profile && businessDetail.profile.purchasesSource)
          || (e.profile && e.profile.purchasesSource)
          || '',
        reconciledDays:
          (businessDetail.profile && businessDetail.profile.reconciledDays)
          || (e.profile && e.profile.reconciledDays)
          || '',
        // new config
        incomeTax:
          (businessDetail.profile && businessDetail.profile.incomeTax)
          || (e.profile && e.profile.incomeTax)
          || '',
        taxAgent:
          (businessDetail.profile && businessDetail.profile.taxAgent)
          || (e.profile && e.profile.taxAgent)
          || '',
        payrollTax:
          (businessDetail.profile && businessDetail.profile.payrollTax)
          || (e.profile && e.profile.payrollTax)
          || '',
        fringeBenefitTax:
          (businessDetail.profile && businessDetail.profile.fringeBenefitTax)
          || (e.profile && e.profile.fringeBenefitTax)
          || '',
        dividendWithholdingTax:
          (businessDetail.profile
            && businessDetail.profile.dividendWithholdingTax)
          || (e.profile && e.profile.dividendWithholdingTax)
          || '',
        disconnected: businessDetail.disconnected || false,
        countryCode:
          businessDetail.countryCode
          || e.countryCode
          || (businessDetail.data && businessDetail.data.countryCode),
      };
    } else if (e && businessDetail && businessDetail.profile) {
      field = {
        selectedBankAccounts: [],
        taxRate:
          (businessDetail.profile && businessDetail.profile.taxRate)
          || (e.profile && e.profile.taxRate)
          || '',
        lineOfBusiness:
          (businessDetail.data && businessDetail.data.lineOfBusiness) || '',
        taxNumber: (businessDetail.data && businessDetail.data.taxNumber) || '',
        salesTaxBasis:
          (businessDetail.data && businessDetail.data.salesTaxBasis) || '',
        salesTaxPeriod:
          (businessDetail.data && businessDetail.data.salesTaxPeriod) || '',
        financialYearEnd:
          (businessDetail.data
            && `${businessDetail.data.financialYearEnd}/YYYY`)
          || '',
        address:
          (businessDetail.profile && businessDetail.profile.address)
          || (businessDetail.data && businessDetail.data.address)
          || (e.profile && e.profile.address)
          || (businessDetail.data && businessDetail.data.address)
          || '  ',
        phoneNumber:
          (businessDetail.profile && businessDetail.profile.phoneNumber)
          || (businessDetail.data && businessDetail.data.phoneNumber)
          || (e.profile && e.profile.phoneNumber)
          || (businessDetail.data && businessDetail.data.phoneNumber)
          || '  ',

        // config
        operationalExpensesSource:
          (businessDetail.profile
            && businessDetail.profile.operationalExpensesSource)
          || (e.profile && e.profile.operationalExpensesSource)
          || '',
        salesSource:
          (businessDetail.profile && businessDetail.profile.salesSource)
          || (e.profile && e.profile.salesSource)
          || '',
        purchasesSource:
          (businessDetail.profile && businessDetail.profile.purchasesSource)
          || (e.profile && e.profile.purchasesSource)
          || '',
        reconciledDays:
          (businessDetail.profile && businessDetail.profile.reconciledDays)
          || (e.profile && e.profile.reconciledDays)
          || '',
        // new config
        incomeTax:
          (businessDetail.profile && businessDetail.profile.incomeTax)
          || (e.profile && e.profile.incomeTax)
          || '',
        taxAgent:
          (businessDetail.profile && businessDetail.profile.taxAgent)
          || (e.profile && e.profile.taxAgent)
          || '',
        payrollTax:
          (businessDetail.profile && businessDetail.profile.payrollTax)
          || (e.profile && e.profile.payrollTax)
          || '',
        fringeBenefitTax:
          (businessDetail.profile && businessDetail.profile.fringeBenefitTax)
          || (e.profile && e.profile.fringeBenefitTax)
          || '',
        dividendWithholdingTax:
          (businessDetail.profile
            && businessDetail.profile.dividendWithholdingTax)
          || (e.profile && e.profile.dividendWithholdingTax)
          || '',
        disconnected: businessDetail.disconnected || false,
        countryCode:
          businessDetail.countryCode
          || e.countryCode
          || (businessDetail.data && businessDetail.data.countryCode),
      };
    } else {
      field = {
        taxRate: '',
        lineOfBusiness: '',
        taxNumber: '',
        salesTaxBasis: '',
        salesTaxPeriod: '',
        financialYearEnd: '',
        address: '  ',
        // config
        operationalExpensesSource: '',
        salesSource: '',
        purchasesSource: '',
        reconciledDays: '',
        // new config
        incomeTax: '',
        taxAgent: '',
        payrollTax: '',
        fringeBenefitTax: '',
        dividendWithholdingTax: '',
        disconnected: false,
        hasSelectedBankAccount: [],
        countryCode: null,
        selectedBankAccounts: [],
      };
    }
    rootStore.businessStore.setConstructedFields(field);
    return field;
  } catch (error) {
    const field = {
      taxRate: '',
      lineOfBusiness: '',
      taxNumber: '',
      salesTaxBasis: '',
      salesTaxPeriod: '',
      financialYearEnd: '',
      address: '  ',
      // config
      operationalExpensesSource: '',
      salesSource: '',
      purchasesSource: '',
      reconciledDays: '',
      // new config
      incomeTax: '',
      taxAgent: '',
      payrollTax: '',
      fringeBenefitTax: '',
      dividendWithholdingTax: '',
      disconnected: false,
      hasSelectedBankAccount: [],
      countryCode: null,
      selectedBankAccounts: [],
    };
    rootStore.businessStore.setConstructedFields(field);
    return field;
  }
}
