import React from 'react';
import LoadingSpinner from '../LoadingSpinner';

const AssistantLoader = () => {
  const [messageIndex, setMessageIndex] = React.useState(0);
  const messageMap = [
    'Processing your request…',
    'Scanning for relevant details…',
    'Gathering all the important stuff...',
    'Crunching data, one byte at a time…',
    'Cross-referencing details…',
    'Almost there, just polishing up!',
    'Sorry this last bit is taking so long. Thanks for being patient!'
  ];

  const incrementMessageIndexInterval = setInterval(() => {
    if (messageIndex < messageMap.length - 1) {
      setMessageIndex(messageIndex + 1);
    }
  }, 5000);

  React.useEffect(
    () => () => clearInterval(incrementMessageIndexInterval),
    [incrementMessageIndexInterval]
  );

  return (
    <div className='assistant-loader'>
      <LoadingSpinner />
      <div className='assistant-loader__message'>{messageMap[messageIndex]}</div>
    </div>
  );
};

export default AssistantLoader;
