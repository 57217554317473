import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const sortIncreaseSvg = () => (
  <svg
    height='1em'
    viewBox='0 0 20 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='currentColor'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path
      d='M11.6667 6.1427H14.1667M11.6667 10.0317H15.8334M11.6667 13.9206L17.5001 13.9206M11.6667 17.8096H19.1667'

    />
    <path
      d='M2.5 15.3086L5 17.8086M5 17.8086L7.5 15.3086M5 17.8086L5 6.14193'
    />
  </svg>

);

const SortIncreaseIndicator = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={sortIncreaseSvg}
    {...props}
    aria-label='insights'
  />
);

export default SortIncreaseIndicator;
