import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import SubLayoutSettings from '../../layouts/SubLayoutSettings';
import AccountNavbar from '../../components/accountSettings/AccountNavbar';
import AccountHeader from '../../components/accountSettings/Header';
import ManageChecklists from '../../components/accountSettings/manageChecklists/ManageChecklists';
import { useStore } from '../../stores/Store';

const PracticeChecklists = () => {
  const { localeStore, practiceStore } = useStore();

  useEffect(() => {
    localeStore.setLocale(practiceStore?.countryCode);
  }, [practiceStore?.countryCode]);

  return (
    <SubLayoutSettings
      SubNav={(<AccountNavbar />)}
      HeaderContent={(<AccountHeader />)}
      SubMain={(<ManageChecklists />)}
    />
  );
};

export default observer(PracticeChecklists);
