import React from 'react';
import { ClientManagementEnums } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import Markdown from 'markdown-to-jsx';
import { LoadingOutlined } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import XeroImportHover from '../icons/XeroImportHover';
import XeroImport from '../icons/XeroImport';
import QuickBooksImportHover from '../icons/QuickBooksImportHover';
import QuickBooksImport from '../icons/QuickBooksImport';
import ButtonSecondary from '../customAntD/ButtonSecondary';

const OSPSelector = ({ initial } : {initial?: boolean}) => {
  const { connectionsStore, localeStore } = useStore();
  const [xeroHovered, setXeroHovered] = React.useState(false);
  const [xeroLoading, setXeroLoading] = React.useState(false);
  const [qbHovered, setQbHovered] = React.useState(false);
  const [qbLoading, setQbLoading] = React.useState(false);

  const handleClick = (osp: ClientManagementEnums.OSPKeys) => {
    connectionsStore.connectionType = osp;
    connectionsStore.modalAddConnection();
  };

  return (
    <div className={`osp-selector ${initial ? 'osp-selector__initial' : ''}`}>
      <Tooltip
        title={(
          <Markdown>
            {localeStore.translation('loading-zone.xero.description')}
          </Markdown>
        )}
        overlayClassName='osp-selector__tooltip'
        placement='bottom'
      >
        <ButtonSecondary
          shape='default'
          className={`osp-buttons xero ${xeroLoading ? 'loading' : ''} ${connectionsStore.connectionType === ClientManagementEnums.OSPKeys.xero ? 'btn-active' : ''}`}
          onClick={() => {
            setXeroLoading(true);
            handleClick(ClientManagementEnums.OSPKeys.xero);
          }}
          size='large'
          onMouseEnter={() => setXeroHovered(true)}
          onMouseLeave={() => setXeroHovered(false)}
        >
          {xeroLoading && <LoadingOutlined spin />}
          {!xeroLoading && (xeroHovered
            ? <XeroImportHover className='osp-buttons__icon' />
            : <XeroImport className='osp-buttons__icon' />)}
        </ButtonSecondary>
      </Tooltip>
      <Tooltip
        title={(
          <Markdown>
            {localeStore.translation('loading-zone.intuit.description')}
          </Markdown>
        )}
        overlayClassName='osp-selector__tooltip'
        placement='bottom'
      >
        <ButtonSecondary
          shape='default'
          className={`osp-buttons intuit ${qbLoading ? 'loading' : ''} ${connectionsStore.connectionType === ClientManagementEnums.OSPKeys.intuit ? 'btn-active' : ''}`}
          onClick={() => {
            setQbLoading(true);
            handleClick(ClientManagementEnums.OSPKeys.intuit);
          }}
          size='large'
          onMouseEnter={() => setQbHovered(true)}
          onMouseLeave={() => setQbHovered(false)}
        >
          {qbLoading && <LoadingOutlined spin />}
          {!qbLoading && (qbHovered
            ? <QuickBooksImportHover className='osp-buttons__icon' />
            : <QuickBooksImport className='osp-buttons__icon' />)}
        </ButtonSecondary>
      </Tooltip>
    </div>
  );
};

OSPSelector.defaultProps = {
  initial: false,
};

export default observer(OSPSelector);
