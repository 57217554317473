import React from 'react';
import { observer } from 'mobx-react';
import { PracticeTypes } from '@aider/constants-library';
import { useStore } from '../../../stores/Store';

const PageActions = ({
  block,
  blockInstance,
  className
} : {
  block: PracticeTypes.ReportBlock,
  blockInstance: number,
  className: string
}) => {
  const { localeStore } = useStore();
  return (
    <aside className={`${className} ${className}--left-aligned`}>
      {block?.id && localeStore.translation('report-editor.page-number', { pageNumber: blockInstance })}
    </aside>
  );
};

export default observer(PageActions);
