import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { PracticeTypes } from '@aider/constants-library';
import { PlusOutlined } from '@ant-design/icons';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import AddComponentPopup from './AddComponentPopup';

const AddComponent = ({
  block,
  className
} : {
  block: PracticeTypes.ReportBlock,
  className: string
}) => {
  const [tooltipHintVisible, setTooltipHintVisible] = React.useState(false);
  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  return (
    <section key={`component-action_${block?.id ? block.id : 'last'}`} className={`${className} ${className}__component`}>
      <Tooltip
        open={tooltipHintVisible}
        title='Add...'
        placement='right'
      >
        <Tooltip
          open={tooltipVisible}
          title={<AddComponentPopup blockId={block.id} setTooltipVisible={setTooltipVisible} />}
          placement='right'
          trigger='click'
        >
          <ButtonSecondary
            shape='circle'
            icon={<PlusOutlined />}
            size='small'
            mixpanelEvent='Report Editor - View Add Component Popup'
            onMouseEnter={() => setTooltipHintVisible(true)}
            onMouseLeave={() => setTooltipHintVisible(false)}
            onClick={() => {
              setTooltipHintVisible(false);
              setTooltipVisible(!tooltipVisible);
            }}
            onBlur={() => setTimeout(() => setTooltipVisible(false), 250)}
          />
        </Tooltip>
      </Tooltip>
    </section>
  );
};

export default observer(AddComponent);
