import { DateTime } from 'luxon';
import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import ButtonLink from '../atoms/ButtonLink';
import { RoundedBoxClock, RoundedBoxEmpty, RoundedBoxTick } from '../atoms/Icons';
import Mixpanel from '../../lib/mixpanel';

const PeriodStatus = ({ editedBy, status, text, buttonText, tableGroupId }: {
    editedBy: string,
    status: string,
    text: string,
    buttonText: string,
    tableGroupId: string,
}) => {
  const { businessesStore, userStore } = useStore();
  const [isEditedBy, setEditedBy] = useState('');
  const [isStatus, setStatus] = useState('');
  const [isText, setText] = useState('');
  const [isButtonText, setButtonText] = useState('');
  const [isLoading, setLoading] = useState(true);
  const currentDate = DateTime.now().toFormat('dd/LL');

  function statusImage(imageStatus: string) {
    switch (imageStatus) {
      case 'To do':
        return <RoundedBoxEmpty />;
      case 'In Progress':
        return <RoundedBoxClock />;
      case 'Done':
        return <RoundedBoxTick />;
      default:
        return <RoundedBoxEmpty />;
    }
  }

  React.useEffect(() => {
    setEditedBy(editedBy);
    setStatus(status);
    setText(text);
    setButtonText(buttonText);
    setLoading(false);

    return () => {
      setLoading(true);
      setEditedBy('');
      setStatus('');
      setText('');
      setButtonText('');
    };
  }, [editedBy, status, text, buttonText, tableGroupId]);

  function updateState() {
    setEditedBy(userStore.username);
    const [nextStatus, nextText, nextButtonText] =
      isStatus === 'To do'
        ? ['In Progress', 'In progress', 'Close period']
        : isStatus === 'Done'
          ? ['In Progress', 'In progress', 'Close period']
          : ['Done', `Period closed ${currentDate}\nby ${isEditedBy}`, 'Reopen period'];
    setStatus(nextStatus);
    setText(nextText);
    setButtonText(nextButtonText);
    Mixpanel.track('Period Close Period Status', {
      editedBy: userStore.username,
      status: nextStatus,
      businessId: businessesStore.selectedBusinessId,
      businessName: businessesStore.selectedBusiness.name,
      tableGroupId,
    });
  }

  return !isLoading && (
    <div className='w-100 d-flex flex-row justify-content-between'>
      <div className='d-flex flex-row justify-content-between'>
        <div className='align-self-center' style={{ paddingLeft: 6 }}>
          {statusImage(isStatus)}
        </div>
        <div className='display-6 text-primary pr-3 pl-2 align-self-center' style={{ fontSize: 30, paddingTop: 3 }}>
          Checklist Status:
        </div>
        <div className='d-flex flex-column justify-content-around' style={{ paddingTop: 8 }}>
          {isText}
        </div>
      </div>
      { /* eslint-disable-next-line max-len  */}
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
      <div onClick={updateState}>
        <ButtonLink className='blue-button' text={isButtonText} state={status} type='periodStatus' tableGroupId={tableGroupId} insightData={{ insightKey: 'DataCompliance' }} />
      </div>
    </div>
  );
};

export default observer(PeriodStatus);
