import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../stores/Store';
import { Routers } from '../models/enums/utils';

// eslint-disable-next-line no-undef
export function TimeoutProvider({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const rootStore = useStore();

  const exemptedRoutes = [Routers.LOGIN];

  useEffect(() => {
    if (exemptedRoutes.includes(location.pathname as Routers)) {
      // Clean up any existing timeouts when landing on an exempt route
      rootStore.removeTimeout();
      return () => null;
    }
    const handleWindowEvents = () => {
      rootStore.createTimeout();
    };

    // listen for specific window events to ensure user is still active
    window.addEventListener('mousemove', handleWindowEvents);
    window.addEventListener('keydown', handleWindowEvents);
    window.addEventListener('click', handleWindowEvents);
    window.addEventListener('scroll', handleWindowEvents);

    handleWindowEvents();

    // cleanup function
    return () => {
      window.removeEventListener('mousemove', handleWindowEvents);
      window.removeEventListener('keydown', handleWindowEvents);
      window.removeEventListener('click', handleWindowEvents);
      window.removeEventListener('scroll', handleWindowEvents);
    };
  }, [location.pathname]);

  return children;
}
