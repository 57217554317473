import { CustomRuleEnums } from '@aider/constants-library';
import { ITreeSelectEntry, ITreeValue } from '../../models/interfaces/components';
import { AccountsTreeData } from '../../ts/interfaces/molecules/DataComplianceRules';

export const getExpandedKeys = (fromOptions: ITreeSelectEntry[]) => {
  const keys = [];
  if (fromOptions.length) {
    fromOptions.forEach((tree) => {
      keys.push(tree.value);

      if (tree.children) {
        tree.children.forEach((child) => {
          keys.push(child.value);
        });
      }
    });
  }
  return keys;
};

/**
 * Checks if the node value matches the current value
 * OR if the value is an account (has an underscore in it) and the account ID matches
 * but with different categories
 *
 * @param {ITreeValue} value - The value to check
 * @param {(ITreeSelectEntry | ITreeValue)} node - The node to check against
 * @returns
 */
export const findMatchingAccountId = (value: ITreeValue, node: ITreeSelectEntry | ITreeValue) => {
  if (node.value === value.value) {
    return true;
  }
  if (value.value.indexOf('_') === -1) {
    return false;
  }
  return node.value.split('_')[1] === value.value.split('_')[1];
};

export const halfCheckAndDisableChildren = (accounts: AccountsTreeData[]): ITreeValue[] => accounts?.flatMap((account) => {
  const children = [];
  if (account?.children) {
    children.push(...halfCheckAndDisableChildren(account.children));
  }

  return [{
    label: account.title,
    value: account.value,
    disabled: true,
    halfChecked: true,
    childless: children.length === 0,
  }].concat(children);
});

const findAndMarkParentsChildrenAndDuplicates = (
  accounts: AccountsTreeData[],
  oldParents: ITreeValue[],
  selectedValues: ITreeValue[]
) => accounts?.flatMap((account) => {
  const parents = [...oldParents];
  // If the account marked is the account we are working with we return the parents
  if (selectedValues.find((value) => value.value === account.value)) {
    let children = [];
    if (account.children) {
      children = halfCheckAndDisableChildren(account.children);
    }
    // Currently selected item
    parents.push({
      label: account.title,
      value: account.value,
      childless: !account?.children?.length,
    });

    return parents.concat(children);
  }

  if (account.children) {
    parents.push({
      label: account.title,
      value: account.value,
      halfChecked: true,
      disabled: false,
      childless: false,
    });
    return findAndMarkParentsChildrenAndDuplicates(account.children, parents, selectedValues);
  }
  return null;
})
  .filter((v) => v);

const markDisabledAccountsAndSetSelectableRecursive = (accounts: AccountsTreeData[], selectedAccounts: string | ITreeValue[], setSelectable?: boolean): AccountsTreeData[] => (
  accounts?.map((account) => {
    const sameAccountCopy = { ...account };
    // if we find the account in the selected accounts, we use the selected accounts disabled value
    const selectedAccount = typeof selectedAccounts === 'string' ? selectedAccounts : selectedAccounts.find((selected) => selected.value === account.value);
    if (selectedAccount) {
      const newAccount = {
        ...account,
        disabled: typeof selectedAccount === 'string' ? false : selectedAccount.disabled!,
      };
      // if the account has children, we recursively mark them as disabled based on the selected accounts
      if (account.children?.length) {
        newAccount.children = markDisabledAccountsAndSetSelectableRecursive(account.children, selectedAccounts, setSelectable);
      }

      // Set whether the account is selectable based
      if (setSelectable) {
        newAccount.selectable = !account.children?.length;
      }
      return newAccount;
    }

    // Set whether the account is selectable based
    if (setSelectable) {
      sameAccountCopy.selectable = !account.children?.length;
      if (account.children?.length) {
        sameAccountCopy.children = markDisabledAccountsAndSetSelectableRecursive(account.children, selectedAccounts, setSelectable);
      }
    }

    return sameAccountCopy;
  })
);

/**
 * Generate a structure of select options from the chart of accounts
 * Is memoised to prevent unnecessary re-renders and re-calculations
 * @returns {AccountsTreeData[]} - Array of options for the value field
 */
export const fromOptionsTree = (selectedFrom: string | ITreeValue[], activeSection: string, chartOfAccounts, onlyActualAccounts?: boolean) => {
  let allTitle = 'ALL ACCOUNTS';
  switch (activeSection) {
    case CustomRuleEnums.RuleCategories.balanceSheet:
      allTitle = 'ALL BALANCE SHEET ACCOUNTS';
      break;
    case CustomRuleEnums.RuleCategories.profitAndLoss:
      allTitle = 'ALL PROFIT AND LOSS ACCOUNTS';
      break;
    default:
      break;
  }

  const treeData = markDisabledAccountsAndSetSelectableRecursive(chartOfAccounts, selectedFrom, onlyActualAccounts);
  const res = [{
    title: allTitle,
    value: 'allAccounts',
    children: treeData,
    selectable: !onlyActualAccounts,
  }];
  return res;
};
/**
 * Find the selected items and mark any parents / children as indeterminate
 * If both the parent and child are selected, mark the parent as selected
 * and child as indeterminate
 * @param {ITreeValue[]} values The selected values
 * @param doNotUseRequiredForExtraArg - unused argument, required to gain access to the third argument
 * @param extra - extra argument containing the trigger value that caused the change
 */
export const findAndMarkItems = (optionsTree: AccountsTreeData[], selectedFrom: string | ITreeValue[], values: string | ITreeValue[], activeSection, chartOfAccounts, callback) => {
  if (typeof values === 'string') {
    return callback(values);
  }
  /**
   * values are in the format of:
   *  [
   *    { label: 'label', value: 'value', disabled: true, halfChecked: true },
   * ]
   *
   * and are the selected values from the tree select
   */

  /** Find the difference between the selected values and the previous selection.
   *  Used to make sure any accounts with the same ID but different categories are removed
   *  if their doppleganger is removed
   * */
  const valDiff = (!selectedFrom || typeof selectedFrom === 'string') ? [] : selectedFrom?.filter((v) => (
    values.findIndex((val) => val.value === v.value) === -1
  ));

  /** Remove any "halfChecked" / indeterminate selections, as these are purely placeholder for visually tracking.
   * Also remove any accounts that have been removed from the selection or had their dopplegangers removed */
  const newValues = values.filter((v) => !v.halfChecked && (valDiff?.findIndex((d) => findMatchingAccountId(v, d)) === -1));
  const selectedItems = [];

  /**
   * If the selected items contains 'ALL ACCOUNTS' select it and mark all children
   */
  if (newValues.findIndex((v) => v.value === optionsTree[0]?.value) !== -1) {
    selectedItems.push({
      label: optionsTree[0].title,
      value: optionsTree[0].value,
    });
    selectedItems.push(...halfCheckAndDisableChildren(optionsTree[0].children));
  } else {
    /**
   * Otherwise, loop through each selected item and mark the parents and children as indeterminate
   */
    const marks = findAndMarkParentsChildrenAndDuplicates(
      optionsTree[0].children,
      [{
        label: optionsTree[0].title,
        value: optionsTree[0].value,
        halfChecked: true,
        disabled: false,
      }],
      newValues
    ) || [];
    selectedItems.push(...marks);
  }

  return callback(selectedItems);
};

export const parseFrom = (fromItems: any) => {
  if (!fromItems) return null;
  let from = Array.isArray(fromItems) ? fromItems : [{ value: fromItems }];
  from = from.filter((item) => !(item.halfChecked)).map((item) => item.value);

  return from;
};

export const parseChildrenFrom = (fromItems: any) => {
  if (!fromItems) return null;
  let from = Array.isArray(fromItems) ? fromItems : [{ value: fromItems }];
  from = from.filter((item) => (item.childless)).map((item) => item.value);
  return from;
};

export const parseKeywords = (keywords: any) => {
  if (!keywords) return null;
  const keywordString = keywords.join(',');
  return `KEYWORDS:${keywordString}`;
};

export const parseSelectedAccounts = (formItems: any, targetChildren: boolean = false, ospList: string[] = null) => {
  let fromAccounts = null;
  const practiceAccounts = !ospList ? [] : ospList.flatMap((osp) => {
    const key = `from-${osp}`;

    if (formItems[key] && (Array.isArray(formItems[key]) && formItems[key].length)) {
      if (targetChildren) {
        return parseChildrenFrom(formItems[key]);
      }
      return parseFrom(formItems[key]);
    }
    // Cannot have a single select for practice accounts so return an empty array as no entries have been selected
    return [];
  });

  if (formItems.from && (Array.isArray(formItems.from) && formItems.from.length)) {
    if (targetChildren) {
      fromAccounts = parseChildrenFrom(formItems.from);
    } else {
      fromAccounts = parseFrom(formItems.from);
    }
  }

  if (fromAccounts) {
    return fromAccounts.concat(practiceAccounts);
  }

  if (practiceAccounts.length) {
    return practiceAccounts;
  }

  if (formItems.keywordFrom && (Array.isArray(formItems.keywordFrom) && formItems.keywordFrom.length)) {
    return parseKeywords(formItems.keywordFrom);
  }

  // Returns string
  return formItems?.from;
};
