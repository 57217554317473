import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const iconSvg = () => (
  <svg
    width='1em'
    viewBox='0 0 28 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='1' y='1.95215' width='26' height='26' rx='5' fill='currentColor' />
    <path d='M22 8.95215L11 19.9521L6 14.9521' stroke='white' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
    <rect x='1' y='1.95215' width='26' height='26' rx='5' stroke='currentColor' strokeWidth='2' />
  </svg>
);

const ChecklistStatusIconDone = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={iconSvg}
    {...props}
    aria-label='Checklist status: Done'
  />
);

export default ChecklistStatusIconDone;
