import React, { useState } from 'react';
import { formValidation } from '../../utils/formValidation';
import { IInputProps } from '../../ts/interfaces/components/input';
import { removeSpace } from '../../utils/validation';

/**
 * Input component.
 *
 * This component is used to render input.
 *
 * @state: label(optional), type(optional), isRequired, value(optional), onChange
 * @actions: input/textarea change event
 *
 * @TODO: Error should be from parent
 */
const Input = ({
  label,
  type,
  isRequired,
  value,
  onChange,
  onError,
  // eslint-disable-next-line no-undef
}: IInputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const currentValue = e.target.value;
    const validatedResult = formValidation(currentValue, label, isRequired);

    setInputValue(currentValue);
    onChange(currentValue);

    setErrorMsg(validatedResult);
    onError(errorMsg);
  };

  const ariaLabel = `input-${removeSpace(label)}`;
  return (
    <div className='input'>
      {
        label
        && (
        <label htmlFor={label} id={ariaLabel}>
          <p>{label}</p>
          {!isRequired && <p className='optional'>Optional</p>}
        </label>
        )
      }
      {
        type !== 'textarea'
          ? (
            <input
              name={label}
              type={type}
              value={inputValue}
              onChange={handleInputChange}
            />
          )
          : (
            <textarea
              name={label}
              id={label}
              cols={40}
              rows={4}
              onChange={handleInputChange}
            />
          )
      }
      {errorMsg && <p className='text--error'>{errorMsg}</p>}
    </div>
  );
};

export default Input;
