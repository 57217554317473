import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';

const DashboardFilterEmpty = ({ filter }:{filter:string}) => (
  <div className='dashboard__filters__empty'>
    <InfoCircleOutlined className='dashboard__filters__empty__icon' />
    {filter === 'industry' && (
    <>
      <h5>No Industry Data Imported</h5>
      <p>Please make sure the clients' industries are specified in their Xero files' Line of Business settings.</p>
    </>
    )}
    {filter === 'tags' && (
    <>
      <h5>No Clients Tagged</h5>
      <p>You can add custom tags to specific clients in their Client Setting screen.</p>
    </>
    )}
  </div>
);

export default DashboardFilterEmpty;
