import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import OnboardingHeader from '../components/onboarding/OnboardingHeader';
import OnboardingFirmBody from '../components/onboarding/OnboardingFirmBody';
import { useStore } from '../stores/Store';
import { Routers } from '../models/enums/utils';
import { CookieKeys } from '../ts/enums/Constants';

const OnboardingFirmDetails = () => {
  const { loadingStore, practiceStore, connectionsStore } = useStore();
  const navigate = useNavigate();
  if (practiceStore.hasId) {
    connectionsStore.initialConnection = false;
    const lastLocation = cookie.get(CookieKeys.prevDestination) || null;
    if (lastLocation) {
      cookie.remove('prevDestination');
      navigate(lastLocation);
    } else {
      navigate(Routers.DASHBOARD);
    }
  }

  useEffect(() => {
    loadingStore.setDoneLoading('appReferral');
  }, []);

  return !loadingStore.isAnyLoading && (
    <div className='onboarding'>
      <OnboardingHeader />
      <OnboardingFirmBody />
    </div>
  );
};

export default observer(OnboardingFirmDetails);
