import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import { BounceEllipsis } from '../../components/icons';

interface IDataLoadingInfoProps {
  businessId: string;
  handleComplete: () => { done: boolean; callback: () => any };
}
const DataLoadingInfo = ({
  businessId,
  handleComplete,
}: IDataLoadingInfoProps) => {
  const { resyncStore } = useStore();
  const { resyncingBusinesses } = resyncStore;
  const [prevState, setPrevState] = useState<boolean>(false);
  const pendingData = resyncStore.dataStatusPendingData?.get(businessId);
  const firestoreDataStatus = resyncStore.dataStatusSnapshots?.get(businessId);

  if (firestoreDataStatus && pendingData) {
    resyncingBusinesses.set(businessId, false);
  }

  /**
   * Check if any of the data sources are still loading
   */
  useEffect(() => {
    if (pendingData !== prevState) {
      if (!pendingData && prevState) {
        handleComplete();
      }
      setPrevState(pendingData);
    }
  }, [pendingData]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Determine which icon to show for any given data check
   * Duplicated in ResyncStatus.tsx
   */
  const pendingIcon = (check: any, key: string) => {
    if (!check?.status || !firestoreDataStatus?.etl?.started) return null;
    if (key !== 'etl') {
      if (check?.started < firestoreDataStatus?.etl?.started) return null;

      if (check?.status === 'failed') {
        return <CloseOutlined />;
      }

      if (check?.status === 'done') {
        return <CheckOutlined />;
      }

      return (
        <BounceEllipsis />
      );
    }

    const etlDone = firestoreDataStatus?.etl?.status === 'done';
    return etlDone ? <CheckOutlined /> : <BounceEllipsis />;
  };
  /**
   * Build the tooltip content for the pending status
   * Duplicated in ResyncStatus.tsx
   */
  const pendingTooltip = (
    <ul className='resync-status'>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.etl, 'etl')}
        </span>
        Getting Data
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.modelTraining, 'modelTraining')
          || pendingIcon(firestoreDataStatus?.cashflowTraining, 'cashflowTraining')
          || pendingIcon(firestoreDataStatus?.gstTraining, 'gstTraining')}
        </span>
        Calculating Estimates
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.insights, 'insights')}
        </span>
        Calculating Insights
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.tablegroup, 'tablegroup')}
        </span>
        Checking Period Close
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.dashboard, 'dashboard')}
        </span>
        Updating Dashboard
      </li>
    </ul>
  );

  return (pendingData
    && !resyncingBusinesses.get(businessId)
    && (
      <Tooltip
        title={pendingTooltip}
        placement='right'
      >
        <InfoCircleOutlined className='DataLoadingInfo-Info' />
      </Tooltip>
    ));
};

export default observer(DataLoadingInfo);
