export const ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];

export const borderArrayCF = [
  '#4264ea',
  '#00E0E0',
  '#009494',
  '#00E0E1',
  '#009494',
  '#006161',
];

export const borderArray = [
  '#4264ea',
  '#00E0E1',
  '#4264ea',
  '#00E0E1',
  '#009494',
  '#006161',
];

export const backgroundArray = [
  '#4263ea',
  '#00E0E1',
  'rgba(66, 100, 234, 0.1)',
  'rgba(0, 224, 225, 0.3)',
  'rgba(0, 148, 148, 0.3)',
  'rgba(0, 97, 97, 0.3)',
];

export const backgroundArrayPoint = [
  '#4264ea',
  '#00E0E1',
  '#4264ea',
  '#00E0E1',
  '#009494',
  '#006161',
];

export const barArray = [
  'rgba(66,101,234,0.78)',
  'rgba(235,87,87,0.41)',
  'rgba(103,180,234,0.4)',
  'rgba(66,101,234,0.18)',
];

export const barArrayFNonGrouped = [
  ['rgba(66,100,234,0.58)', 'rgba(66,100,234,0.58)'],
  ['#fff', '#fff'],
  ['rgba(159,160,172,0.67)', 'rgba(159,160,172,0.67)'],
];

export const barArrayF = [
  'rgba(66,100,234,0.58)',
  '#fff',
  'rgba(159,160,172,0.67)',
  '#fff',
];

export const borderArrayF = [
  'rgba(66,100,234,0.58)',
  'rgba(66,100,234,0.58)',
  'rgba(159,160,172,0.67)',
  'rgba(159,160,172,0.67)',
  '#4264ea50',
  'rgba(159,160,172,0.67)',
];
