import React from 'react';
import {
  ExclamationCircleFilled,
  InfoCircleFilled,
  WarningFilled
} from '@ant-design/icons';
import { PeriodCloseTypes } from '@aider/constants-library';

const InfoCell = ({
  cell
}:{
  cell: Extract<PeriodCloseTypes.ChecklistCell, { cellType: 'info' }>
}) => {
  let Icon = InfoCircleFilled;

  if (cell?.data === 'warning') {
    Icon = WarningFilled;
  }

  if (cell?.data === 'danger') {
    Icon = ExclamationCircleFilled;
  }

  const data = cell?.rawData ?? '';

  return (
    <>
      <Icon
        className={`period-close-info-Icon
          period-close-info-icon__${cell?.data}`}
      /> {data}
    </>
  );
};

export default InfoCell;
