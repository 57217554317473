import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable, stopPersisting } from 'mobx-persist-store';
import jwt from 'jsonwebtoken';
import cookie from 'js-cookie';

import { ApiConstants } from '@aider/constants-library';
import type { RootStore } from './Store';
import { POST } from '../lib/requests';
import handleError from '../lib/errorHandler';

export default class InvitationStore {
  rootStore: RootStore;

  // The invitation token from the query string
  inviteToken: string;

  // Practice information
  practice: any;

  email: string;

  // Invitation Document from firestore
  invitation: any;

  // If there is an error accepting the invitation
  error: any;

  // If the user has accepted the terms and conditions
  termsAccepted: boolean = false;

  // If the acception process is in progress
  accepting: string;

  // If the invitation has been accepted
  inviteAccepted: boolean = false;

  constructor(initStore) {
    this.rootStore = initStore;
    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });

    if (process.env.NODE_ENV !== 'test') {
      this.initStorePersistance();
    }
  }

  // Computed prop to decode the invite token when provided
  get decodedToken() {
    const decoded = jwt.decode(this.inviteToken);
    if (decoded && this.inviteToken) {
      this.retrieveInvitationByToken();
    }
    return decoded;
  }

  // Computed prop to confirm if there is an invitation token
  get isInvitation() {
    return !!this.inviteToken;
  }

  // Computed prop to return the practice name once it has been retrieved
  get practiceName() {
    return this.practice?.name;
  }

  initStorePersistance() {
    makePersistable(this, {
      name: 'InvitationStore',
      properties: [
        'email',
        'accepting',
        'termsAccepted',
      ],
      storage: window.localStorage,
    });
  }

  async clearStoredData() {
    await clearPersistedStore(this);
    await stopPersisting(this);
  }

  // Action to retrieve the invitation document from firestore
  async retrieveInvitationByToken() {
    const data = {
      _embedded: {
        token: this.inviteToken
      }
    };
    const url = `${ApiConstants.apiEndpointsBase.root}/invitations/token`;

    const invitationsResponse = await POST({ url, data, skipAuth: true, rootStore: this.rootStore });
    this.practice = invitationsResponse?._embedded?.business;
    this.invitation = invitationsResponse?._embedded?.invitation;
  }

  // Action to accept the invitation
  async acceptInvitation() {
    const headers = {
      Authorization: `Bearer ${this.rootStore.authenticationStore.accessToken}`,
      'X-API-Key': process.env.REACT_APP_API_KEY,
      'Content-Type': 'application/hal+json',
    };
    const data = {
      _embedded: {
        invitationToken: this.inviteToken,
      }
    };
    const url = `${ApiConstants.apiEndpointsBase.root}/businesses/invitations/accept`;
    return POST({ url, data, headers, rootStore: this.rootStore })
      .then((res) => {
        if (res?.status === 403) {
          return { status: 403, message: 'INVALID_EMAIL' };
        }
        return res;
      })
      .catch((error) => {
        handleError({ error });
        return error;
      });
  }

  setInviteAccepted() {
    // Invite accepted, clear invite tokens and redirect to dashboard
    cookie.remove('inviteToken');
    this.rootStore.connectionsStore.initialConnection = false;
    cookie.set('initialConnection', false);
    this.error = null;
    this.inviteToken = null;
    this.clearStoredData();
    this.inviteAccepted = true;
    this.accepting = null;

    // Placeholder to prevent onboarding modal from showing with historic auth / modals
    this.rootStore.authStore.setUserTriggeredSSO(true);
  }
}
