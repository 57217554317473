import React from 'react';
import { PrivacyShield, SecurityShield } from '../icons';
import GenAIWhite from '../icons/GenAIWhite';

const DashboardIntro = () => (
  <p>
    <GenAIWhite className='icon__blue' />
    <span className='brand-name'>Aider's</span>{' '}
    <span className='secondary-brand'>Dashboard Assistant</span> can help you with tasks like identifying advisory opportunities, generating
    sales script, prioritising clients to work on based on top KPIs,
    reconciliation and GST statuses shown on this Dashboard.
    <br />
    It is <strong>not recommended</strong> to use public Generative AI tools
    directly (eg. ChatGPT, Bard etc) due to privacy and security risks.
  </p>
);

const InsightsIntro = () => (
  <p>
    <GenAIWhite className='icon__blue' />
    <span className='brand-name'>Aider's</span>{' '}
    <span className='secondary-brand'>Advisory Assistant</span> can help you
    with tasks like client meeting prep, generate sales scripts for selling
    advisory, high level analysis of data trends, with much more to
    <br />
    It is <strong>not recommended</strong> to use public Generative AI tools
    directly (eg. ChatGPT, Bard etc) due to privacy and security risks.come.
  </p>
);

const AiIntro = () => (
  <p>
    <GenAIWhite className='icon__blue' />
    <span className='brand-name'>Aider's</span>{' '}
    <span className='secondary-brand'>AI Assistant</span> can help you with internal and generic tasks like…
    <ul>
      <li>
        brainstorm ways to improve your processes, service offerings, and
        marketing
      </li>
      <li>draft implementation and marketing plans</li>
      <li>answer general accounting and financial advisory queries</li>
      <li>
        figure out how to do things such as complex calculations - e.g. gross
        income from net income where progressive taxation applies
      </li>
    </ul>
  </p>
);

const AssistantIntro = ({ page = 'insights' }) => (
  <div className='assistant-intro'>
    {page === 'dashboard' && <DashboardIntro />}
    {page === 'insights' && <InsightsIntro />}
    {page === 'assistant' && <AiIntro />}
    <div className='assistant-features'>
      <div className='assistant-features__icons'>
        <SecurityShield />
        <PrivacyShield />
      </div>
      <div className='assistant-features__details'>
        {page === 'assistant' && (
          <>
            <p>
              Aider's AI Assistants are your one-stop shop for AI for
              accountants.
            </p>
            <p>
              Best of all Aider's AI Assistants keep you and your client's data
              secure and private.
            </p>
          </>
        )}
        {page !== 'assistant' && (
          <>
            <p>Aider's AI Assistant...</p>
            <ul>
              <li>keeps you and your client's data secure and private.</li>
              <li>
                is preconfigured with the relevant advisory context, so you get
                better answers.
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  </div>
);

export default AssistantIntro;
