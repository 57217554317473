// Polyfill for fetch
import 'isomorphic-fetch';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { rootStore } from '../../stores/Store';

const jsonReponse = (response) => response.text().then((text) => (text ? JSON.parse(text) : {}));

const makeApiRequest = (url, method, headers = {}, body) => {
  const request = {
    method,
    headers: {
      'X-API-Key': process.env.REACT_APP_API_KEY,
      ...headers
    }
  };

  if (body) {
    request.body = body;
  }

  return fetch(url, request)
    .then(async (response) => {
      const jsonData = await jsonReponse(response);
      if (response.ok) {
        return jsonData;
      }

      let error = {};
      if (jsonData._embedded && jsonData._embedded.error) {
        error = jsonData._embedded.error;
      } else {
        error = jsonData;
      }
      Sentry.captureException(error);
      return Promise.reject(error);
    });
};

export const makeGetRequest = (url, headers) => makeApiRequest(url, 'GET', headers);
export const makeHeadRequest = (url, headers) => makeApiRequest(url, 'HEAD', headers);
export const makePostRequest = (url, headers, body) => makeApiRequest(url, 'POST', headers, body);
export const makeDeleteRequest = (url, headers, body) => makeApiRequest(url, 'DELETE', headers, body);
export const makePutRequest = (url, headers) => makeApiRequest(url, 'PUT', headers);

//  To capture the pending, fulfilled, and rejected statuses of both the request and response
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
  // set loading status to true when request is pending
  rootStore.businessStore.setIsDownloading(true);
  console.log('Request is pending/Loading statue:', config, rootStore.businessStore.isDownloading);
  return config;
}, (error) => {
  Sentry.captureException(error);
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  // set loading status to false when request is fulfilled
  rootStore.businessStore.setIsDownloading(false);
  console.log('Response is fulfilled:', response, rootStore.businessStore.isDownloading);
  return response;
}, (error) => {
  // set loading status to false when request is rejected
  rootStore.businessStore.setIsDownloading(false);
  console.log('Response is rejected:', error.response, rootStore.businessStore.isDownloading);
  Sentry.captureException(error);
  return Promise.reject(error);
});

export default axiosInstance;
