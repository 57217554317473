// TODO: Refactor this file to remove all the eslint-disable

import { Button, MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import 'flatpickr/dist/themes/material_blue.css';
import * as React from 'react';

import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { rootStore } from '../../stores/Store';
import Search from '../Search';
import { objectMapKeyReturned } from '../actions';

/** Import Enums, Interfaces and Types */
import {
  EMessageSubjectMap,
  EInsightActionReducerActions as ReducerActions,
} from '../../ts/enums/entities/ActionCenterModal';
import {
  IActionInsightCardProps,
  IActionInsightReducerState,
} from '../../ts/interfaces/entities/ActionCenterModal';
import { IReducerAction } from '../../ts/interfaces/util';
import { CaretDown } from '../../components-v2/atoms/Icons';
import Mixpanel from '../../lib/mixpanel';

/**
 * Reducer for component state, updating the state based on the action type
 */
function ActionInsightCardReducer(
  state: IActionInsightReducerState,
  action: IReducerAction
): IActionInsightReducerState {
  const { type, payload } = action;
  switch (type) {
    case ReducerActions.CHARTS:
      return { ...state, selectedCharts: payload };
    case ReducerActions.END_DATE:
      return { ...state, endDate: payload };
    case ReducerActions.START_DATE:
      return { ...state, startDate: payload };
    case ReducerActions.TEXT_INPUT:
      return { ...state, textInput: payload };
    case ReducerActions.CATEGORY:
      return { ...state, category: payload };
    case ReducerActions.RECONCILIATION_MSG:
      return { ...state, reconciliationMessage: payload };
    case ReducerActions.TOUCH_PREVIEW:
      return { ...state, previewTouched: payload };
    case ReducerActions.TOUCH_SUBJECT:
      return { ...state, subjectTouched: payload };
    case ReducerActions.SELECT_USERS:
      return { ...state, selectedUsers: payload };
    case ReducerActions.SELECT_IDS:
      return { ...state, selectedIds: payload };
    case ReducerActions.SET_DAYS:
      return { ...state, days: payload };
    case ReducerActions.SET_HOUR:
      return { ...state, hour: payload };
    case ReducerActions.SELECTED_FREQUENCY:
      return { ...state, selectedFrequency: payload };
    case ReducerActions.SELECTED_DAYS:
      return { ...state, selectedDays: payload };
    case ReducerActions.SEND_TYPE:
      return { ...state, sendType: payload };
    case ReducerActions.NEW_EMAIL:
      return { ...state, newEmail: payload };
    case ReducerActions.SEND_SINGLE:
      return { ...state, sendSingle: payload };
    default:
      return state;
  }
}

/**
 * ActionInsightCard component.
 *
 * This component is used to render the action insight card.
 * Contains the logic for selecting insights to send to a client.
 *
 * @param {IActionInsightCardProps} props
 * @returns
 */
export default function ActionInsightCard({
  period,
  ...props
}: IActionInsightCardProps) {
  const [state, dispatch] = React.useReducer(ActionInsightCardReducer, {
    selectedCharts: rootStore.actionStore.selectedChartArrayForMessage,
    endDate: new Date(),
    startDate: new Date(),
    textInput: '',
    category: '',
    reconciliationMessage: null,
    previewTouched: false,
    selectedUsers: props.savedSelectedUsers || [],
    selectedIds: [],
    days: '',
    hour: 15,
    selectedFrequency: '',
    selectedDays: '',
    sendType: 'instant',
    newEmail: '',
    sendSingle: !rootStore.actionStore.isMultiInsightDraft,
    subjectTouched: false,
  });

  /**
   * Function to initialise the insight array on component mount
   */
  React.useEffect(() => {
    props.getMessagePreview();
    const insightArray = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const charts = objectMapKeyReturned(
      rootStore.actionStore.chartImages,
      // eslint-disable-next-line consistent-return
      (val, key) => {
        try {
          const titleFromKey = rootStore.insightStore?.insightData?.find(
            (insight) => insight.insightKey === key
          );
          const insightMap = {
            base64Image: val[key],
            title: titleFromKey.headline.title,
            insightKey: key,
            insightData: titleFromKey,
          };
          insightArray.push(insightMap);
          return insightMap;
        } catch (e) {
          console.error(e);
        }
      }
    );
    props.setMultiInsightCharts(insightArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to undo changes and reset the period of the insight
   */
  const undoChanges = () => {
    props.selectPeriod(period);
  };

  /**
   * Function to change the text input value in state
   * @deprecated not used
   * @param e
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeTextInput = (e) => {
    dispatch({ type: ReducerActions.TEXT_INPUT, payload: e.target.value });
  };

  /**
   * Function to change the hour value in state
   * @deprecated not used
   * @param e
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHourInput = (e) => {
    dispatch({ type: ReducerActions.SET_HOUR, payload: e.target.value });
  };

  /**
   * Function to obtain the subject line for the message
   * @param {string?} subjectKey
   * @param {boolean?} isMulipleInsights
   * @returns
   */
  const renderSubjectLine = (
    subjectKey = 'default',
    isMultipleInsights = false
  ) => {
    let subject = EMessageSubjectMap[subjectKey] || EMessageSubjectMap.default;
    if (
      isMultipleInsights
      && subjectKey !== 'default'
      && typeof EMessageSubjectMap[subjectKey] !== 'undefined'
    ) {
      subject += ` and ${EMessageSubjectMap.default.toLowerCase()}`;
    }
    return `${subject} for`;
  };

  /**
   * Automatically generates the subject line for the email based on the selected insight(s)
   * @param multipleInsights
   * @returns
   */
  const generateSubjectLine = (multipleInsights: boolean = false) => `${renderSubjectLine(
    rootStore.actionStore.selectedSubjectKey,
    multipleInsights
  )} ${rootStore.businessesStore.selectedBusiness.name}`;

  /**
   *  Function to update message subject automatically if the user has not edited it
   */
  const updateSubject = () => {
    if (!state.subjectTouched) {
      props.setMessageSubject(
        generateSubjectLine(state.selectedCharts.length > 1)
      );
    }
  };

  /**
   * Function to update state with the preview message for
   * selected charts and display in the editor
   *
   * @param key
   * @returns
   */
  const selectChart = (key) => {
    const index = state.selectedCharts.indexOf(key);
    if (index > -1) {
      const newArray = state.selectedCharts;
      newArray.splice(index, 1);
      props.calculateMessagePreviewForKey(key, true);
      dispatch({ type: ReducerActions.CHARTS, payload: newArray });
    } else {
      const charts = state.selectedCharts;
      charts.push(key);
      props.calculateMessagePreviewForKey(key);
      dispatch({ type: ReducerActions.CHARTS, payload: charts });
      const textarea = document.getElementById('ac-textArea');
      textarea.scrollTop = textarea.scrollHeight;
    }
    return updateSubject();
  };

  /**
   * Function to trigger generation of message subject on MobX store change
   */
  React.useEffect(() => {
    if (rootStore.actionStore.confirmedMessageSubject) {
      dispatch({ type: ReducerActions.TOUCH_SUBJECT, payload: true });
    } else {
      updateSubject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootStore.actionStore.confirmedMessageSubject]);

  /**
   * Store that user has edited subject line, and therefore should not be overwritten
   * @param {Event} e
   */
  const touchSubject = (e) => {
    dispatch({ type: ReducerActions.TOUCH_SUBJECT, payload: true });
    props.editMessageSubject(e);
  };

  /**
   * Reset the selected charts to a single chart
   * @param key
   * @returns
   */
  const mutateChartArrayFromSingleOption = (key) => {
    const charts = [];
    charts.push(key);
    dispatch({ type: ReducerActions.CHARTS, payload: charts });
    return updateSubject();
  };

  /**
   * Function to clear global state and close the modal
   * @param {Event} e
   */
  const closeModal = (e) => {
    rootStore.actionStore.setConfirmedMessageSubjectNull();
    rootStore.actionStore.setSelectedPeriod(null, 0);
    rootStore.actionStore.closeConfirmedMessageModal();
    props.closeModal(e);
  };

  /**
   * Defines the options for rendering
   * @param summaries
   * @returns
   */
  const renderOptions = (summaries) => {
    if (summaries) {
      return summaries
        .filter((summary) => !summary.disabled)
        .map((summaryPeriod) => {
          if (
            rootStore.actionStore.selectedInsight.actions[0].options.summaryMap.includes(
              summaryPeriod.id
            )
          ) {
            return (
              // eslint-disable-next-line max-len
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                onClick={() => {
                  // @ts-ignore
                  props.selectPeriod(summaryPeriod.id);
                  mutateChartArrayFromSingleOption(
                    `${rootStore.actionStore.selectedInsight.insightKey}_${summaryPeriod.id}`
                  );
                }}
                className={`${
                  summaryPeriod.severity && summaryPeriod.severity.alertLevel > 0 ? '' : ''
                } radioButton rboption ${
                  period === summaryPeriod.id ? 'rbactive' : 'rbinactive'
                }`}
              >
                <div
                  className={`rbItemOption ${
                    period === summaryPeriod.id
                      ? 'rbactiveOption'
                      : 'rbinactiveOption'
                  }`}
                >
                  <div
                    className={`${
                      period === summaryPeriod.id
                        ? 'internal '
                        : 'internal-inactive'
                    }`}
                  />
                </div>
                {summaryPeriod.name}{' '}
                {summaryPeriod.severity && summaryPeriod.severity.alertLevel > 0 && (
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      marginLeft: 10,
                      marginTop: 4,
                    }}
                  />
                )}
              </div>
            );
          }
          return null;
        });
    }
    return null;
  };

  /**
   * Defines the Option Info for rendering
   * @param summaries
   * @returns
   */
  const renderOptionInfo = (summaries) => (
    <div
      style={{
        width: '100%',
        borderTop: '1px solid #d8d8d8',
        padding: 20,
        position: 'relative',
      }}
    >
      <span style={{ fontWeight: 700, fontSize: 18, color: '#333333' }}>
        {summaries[props.selectedSummaryIndex].severity.alertInfo
            && summaries[props.selectedSummaryIndex].severity.alertInfo.title}
      </span>
      {summaries[props.selectedSummaryIndex].severity.alertInfo
          && summaries[props.selectedSummaryIndex].severity.alertInfo
            .colourBubble && (
            <div
              className={`${
                summaries[props.selectedSummaryIndex].severity.alertLevel > 0
                  ? 'ac-urgent'
                  : 'alert-standard'
              }`}
              style={{
                width: 80,
                height: 25,
                borderRadius: 12,
                float: 'right',
                fontSize: 10,
                fontWeight: 100,
                textAlign: 'center',
                lineHeight: '24px',
                position: 'absolute',
                top: 20,
                right: 15,
              }}
            >
              {summaries[props.selectedSummaryIndex].severity.alertInfo
                && summaries[props.selectedSummaryIndex].severity.alertInfo
                  .colourBubble}
            </div>
      )}
    </div>
  );

  /**
   * Function to remove the specified user from the list of selected users
   * @param user
   */
  const removeRecipient = (user) => {
    const newIdList = state.selectedIds.filter((id) => id !== user.id);
    const newUserList = state.selectedUsers.filter(
      (userInstance) => userInstance.displayName !== user
    );
    dispatch({ type: ReducerActions.SELECT_IDS, payload: newIdList });
    dispatch({ type: ReducerActions.SELECT_USERS, payload: newUserList });
  };

  /**
   * Confirms if the user has been selected and exists in state
   * @param user
   * @returns
   */
  const isUserSelected = (user) => state.selectedUsers.indexOf(user) !== -1;

  /**
   * Handle the addition of an email address that does not exist for the current client
   * @param title
   * @returns
   */
  // eslint-disable-next-line consistent-return
  const createNewItemFromQuery = (title) => {
    if (title.includes('@')) {
      const id = document.getElementsByClassName('bp3-input-ghost')[0];
      // @ts-ignore
      id.defaultValue = '';
      // @ts-ignore
      id.value = '';
      const newUser = {
        displayName: title,
        name: title,
        id: null,
        manualEntry: true,
        email: title,
      };
      const newUsersArray = state.selectedUsers;
      newUsersArray.push(newUser);
      dispatch({ type: ReducerActions.SELECT_USERS, payload: newUsersArray });
      dispatch({ type: ReducerActions.NEW_EMAIL, payload: '' });
      return newUser;
    }
  };

  /**
   * Defines the render function
   * @returns
   */
  const render = () => {
    const searchConfig = {
      itemPredicate: Search.filter,
      itemRenderer:
        () => (temp, { handleClick }) => (
          <MenuItem
            icon={isUserSelected(temp) ? 'tick' : null}
            disabled={false}
            key={temp.id}
            onClick={handleClick}
            text={`${temp.displayName} ${temp.email && `<${temp.email}>`}`}
          />
        ),
    };

    const clearButton = (
      <Button
        className='tagClearButton'
        icon={state.selectedUsers.length > 0 ? 'cross' : 'chevron-down'}
        minimal
        onClick={() => {
          dispatch({ type: ReducerActions.SELECT_USERS, payload: [] });
        }}
      />
    );

    // @ts-ignore
    const multisel = (
      <MultiSelect
        onQueryChange={(value) => {
          dispatch({ type: 'SET_NEW_EMAIL', payload: value });
        }}
        onItemSelect={(e) => {
          const newUsersArray = state.selectedUsers;
          const exists = isUserSelected(e);
          if (!exists) {
            newUsersArray.push(e);
            dispatch({
              type: ReducerActions.SELECT_USERS,
              payload: newUsersArray,
            });
          }
        }}
        placeholder={
          (props.usersList && props.usersList.length >= 1)
          || (props.emailContacts
            && props.emailContacts.length >= 1)
            ? 'Select contacts or enter an email address.'
            : 'No contacts available, enter an email address.'
        }
        itemRenderer={searchConfig.itemRenderer()}
        itemPredicate={searchConfig.itemPredicate}
        openOnKeyDown={false}
        itemsEqual={(a, b) => a.displayName === b.displayName}
        items={props.usersList.concat(props.emailContacts)}
        noResults={(
          <MenuItem
            icon='add'
            disabled={!state.newEmail.includes('@')}
            onClick={() => {
              createNewItemFromQuery(state.newEmail);
            }}
            text={`Send to ${state.newEmail}`}
          />
        )}
        tagRenderer={(value) => value.displayName}
        resetOnSelect
        query={state.newEmail}
        tagInputProps={{
          className: 'userTag',
          onRemove: (user) => {
            removeRecipient(user);
          },
          rightElement: clearButton,
        }}
        selectedItems={state.selectedUsers}
        popoverProps={{ minimal: false, popoverClassName: 'tagPopover' }}
      />
    );

    const popoverParentHandler = (e) => {
      const target = e.currentTarget.children[0].querySelector('input');
      if (target) target.click();
    };

    const popoverSiblingHandler = (e) => {
      const target = e.currentTarget.parentElement.children[0].querySelector('input');
      if (target) target.click();
    };

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        style={{
          backgroundColor: '#f5f4fa',
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div
            style={{
              width: '40%',
              minWidth: 600,
              backgroundColor: '#fff',
              padding: '60px 0px 60px 60px',
              height: '100%',
              paddingLeft: 80,
            }}
          >
            <div
              style={{
                color: '#4264ea',
                fontSize: 18,
                fontWeight: 700,
                cursor: 'pointer',
                position: 'relative',
                paddingLeft: 35,
              }}
            >
              { /* eslint-disable-next-line max-len  */}
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
              <div onClick={closeModal}>
                <div className='backArrowHolder'>
                  <LeftOutlined />
                </div>
                Back to client insights
              </div>
            </div>
            <div
              style={{
                height: 75,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '2em'
              }}
            >
              { /* eslint-disable-next-line max-len  */}
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
              <div
                className='notificationManagerCard'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  rootStore.actionStore.setMultiInsight(true);
                  dispatch({ type: ReducerActions.SEND_SINGLE, payload: true });
                }}
              >
                <span
                  className={`${state.sendSingle ? 'ttActive' : ''} tabTitle`}
                >
                  Send Single Insight
                </span>
              </div>
              { /* eslint-disable-next-line max-len  */}
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
              <div
                className='notificationManagerCard'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  rootStore.actionStore.setMultiInsight(false);
                  dispatch({
                    type: ReducerActions.SEND_SINGLE,
                    payload: false,
                  });
                }}
              >
                <span
                  className={`${!state.sendSingle ? 'ttActive' : ''} tabTitle`}
                >
                  Send Multiple Insight
                </span>
              </div>
            </div>
            <div style={{ paddingBottom: 20, marginRight: 60 }}>
              {' '}
              <div
                style={{
                  borderTop: '1px solid #e1e1e5',
                  width: '100%',
                  fontSize: 25,
                  fontWeight: 400,
                  color: '#0A1E70',
                  display: 'inline-block',
                  paddingTop: 40,
                }}
              >
                {state.sendSingle ? props.title : 'Send multiple insights for '}
              </div>
              <br />
              <div
                style={{
                  width: '100%',
                  fontSize: 20,
                  fontWeight: 700,
                  color: '#0A1E70',
                  display: 'inline-block',
                }}
              >
                {rootStore.businessStore.selectedBusinessData.name}
              </div>
            </div>
            {!state.sendSingle ? (
              <div
                className='scrollbar'
                style={{
                  paddingRight: 60,
                  maxHeight: '50vh',
                  overflowY: 'auto',
                }}
              >
                {props.insightsWithCharts
                  && props.insightsWithCharts.map((insight) => {
                    const periodConfig = {
                      itemPredicate: Search.filter,
                      itemRenderer:
                        () => (temp, { handleClick }) => (
                          <MenuItem
                            icon={
                                state.selectedCharts
                                && state.selectedCharts.includes(`${temp.key}`)
                                  ? 'tick'
                                  : null
                              }
                            disabled={false}
                            key={temp.insightKey}
                            onClick={handleClick}
                            text={`${temp.title}`}
                          />
                        ),
                    };
                    const multiSelPeriod = (
                      <MultiSelect
                        onItemSelect={(e) => {
                          selectChart(e.key);
                        }}
                        itemRenderer={periodConfig.itemRenderer()}
                        itemPredicate={periodConfig.itemPredicate}
                        openOnKeyDown={false}
                        fill
                        items={insight.insightData.summaries
                          .filter(
                            (summary) => summary.templateData && !summary.disabled
                          )
                          .map((summaryI) => ({
                            key: `${insight.insightKey}_${summaryI.id}`,
                            period: summaryI,
                            title: summaryI.name,
                          }))}
                        placeholder='Select period'
                        resetOnSelect
                        popoverProps={{
                          minimal: false,
                          position: 'top',
                          popoverClassName: 'tagPopoverPeriod periodDrop',
                        }}
                        tagRenderer={() => 'selected'}
                      />
                    );
                    return (
                      <div
                        style={{
                          paddingTop: 30,
                          paddingBottom: 30,
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          minWidth: 200,
                          width: '100%',
                          minHeight: 100,
                          backgroundColor: '#fff',
                          borderTop: '1px solid #E1E1E5',
                        }}
                      >
                        <div style={{ width: 20, marginRight: '0.5rem' }}>
                          {state.selectedCharts
                            && state.selectedCharts
                              .map((item) => item.split('_')[0])
                              .includes(insight.insightKey) && (
                                <CheckOutlined style={{ color: '#4263EA' }} />
                          )}
                        </div>
                        <div
                          style={{
                            maxWidth: '100%',
                            flexGrow: 1,
                            color: '#5b5c61',
                            fontWeight: 700,
                            fontSize: 16,
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          {insight.title}
                        </div>
                        <div
                          style={{
                            color: '#5b5c61',
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          { /* eslint-disable-next-line  */}
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                          <div
                            role='dialog'
                            id='periodSelectionDropdown'
                            className='multiselwrapperPeriod'
                            style={{
                              borderRadius: 4,
                              border: '1.5px solid #E1E1E5',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              cursor: 'pointer',
                              justifySelf: 'flex-end',
                            }}
                            onClick={popoverParentHandler}
                            onFocus={popoverParentHandler}
                            onKeyPress={popoverParentHandler}
                          >
                            {multiSelPeriod}
                            <CaretDown
                              style={{ marginRight: '0.5rem' }}
                              onClick={popoverSiblingHandler}
                              onFocus={popoverSiblingHandler}
                              onKeypress={popoverSiblingHandler}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <>
                {
                  // @ts-ignore
                  rootStore.actionStore.selectedInsight.summaries
                    && rootStore.actionStore.selectedInsight.summaries.length
                      >= 2 && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          fontWeight: 900,
                          color: '#272830',
                          paddingTop: 25,
                          width: 450,
                        }}
                      >
                        {rootStore.actionStore.selectedInsight.actions[0]
                          && rootStore.actionStore.selectedInsight.actions[0]
                            .options.title}
                      </div>
                  )
                }
                <div
                  style={{
                    width: 450,
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: 20,
                  }}
                >
                  {renderOptions(
                    rootStore.actionStore.selectedInsight.summaries
                  )}
                </div>
                {rootStore.actionStore.selectedInsight.summaries[0].severity
                  && rootStore.actionStore.selectedInsight.summaries[0].severity
                    .alertInfo.title && (
                    <div
                      style={{
                        width: 450,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {renderOptionInfo(
                        rootStore.actionStore.selectedInsight.summaries
                      )}
                    </div>
                )}
                {
                  // @ts-ignore
                  rootStore.actionStore.selectedInsight.summaries[
                    props.selectedSummaryIndex
                  ].datapoints
                    && rootStore.actionStore.selectedInsight.summaries[
                      props.selectedSummaryIndex
                    ].datapoints.length >= 1 && (
                      <>
                        <div
                          style={{
                            marginTop: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: 5,
                            fontSize: 16,
                            paddingTop: 30,
                            color: '#828282',
                            borderTop: '1px solid #b6b6c699',
                            marginRight: 30,
                            width: 450,
                          }}
                        >
                          {
                            // @ts-ignore
                            rootStore.actionStore.selectedInsight.summaries[
                              props.selectedSummaryIndex
                            ].datapoints.map((datapoint, ix) => {
                              if (ix < 2) {
                                return (
                                  <div
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 900,
                                      padding: 10,
                                      paddingTop: 0,
                                      width: '50%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        color: '#4f4f4f',
                                      }}
                                    >
                                      {datapoint.formattedValue}
                                    </div>
                                    <div
                                      style={{
                                        width: 100,
                                        fontSize: 16,
                                        fontWeight: 500,
                                        color: '#8e8e8e',
                                      }}
                                    >
                                      {datapoint.label}
                                    </div>
                                  </div>
                                );
                              }
                              return '';
                            })
                          }
                        </div>
                        <div
                          style={{
                            marginTop: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: 5,
                            fontSize: 16,
                            paddingTop: 20,
                            color: '#828282',
                            marginRight: 30,
                            width: 450,
                          }}
                        >
                          {
                            // @ts-ignore
                            rootStore.actionStore.selectedInsight.summaries[
                              props.selectedSummaryIndex
                            ].datapoints.map((datapoint, ix) => {
                              if (ix > 1 && ix < 4) {
                                return (
                                  <div
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 900,
                                      padding: 10,
                                      paddingTop: 0,
                                      width: '50%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        color: '#4f4f4f',
                                      }}
                                    >
                                      {datapoint.formattedValue}
                                    </div>
                                    <div
                                      style={{
                                        width: 100,
                                        fontSize: 16,
                                        fontWeight: 500,
                                        color: '#8e8e8e',
                                      }}
                                    >
                                      {datapoint.label}
                                    </div>
                                  </div>
                                );
                              }
                              return '';
                            })
                          }
                        </div>
                      </>
                  )
                }
                <div
                  style={{
                    marginTop: 0,
                    marginBottom: 5,
                    fontSize:
                      props.description && props.description.length > 500
                        ? 16
                        : 18,
                    paddingTop: 20,
                    color: '#828282',
                    borderTop: '1px solid #b6b6c699',
                    width: 450,
                  }}
                >
                  {props.description}
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxHeight: 170,
                      overflowY: 'auto',
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <section
          role='dialog'
          style={{
            display: 'inline-block',
            backgroundColor: '#f5f5f9',
            color: '#505050',
            margin: '4vh auto',
          }}
        >

          <div className='form-row form-row--full'>
            <div className='form-row__col'>
              <div style={{ fontSize: 16, fontWeight: 700 }}>Recipients</div>
              <div className='multiselwrapper'>{multisel}</div>
            </div>
          </div>

          <div className='form-row form-row--full'>
            <div className='form-row__col'>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  marginBottom: '0.5rem',
                }}
              >
                Subject line
              </div>
              <input
                type='text'
                name='subject'
                style={{
                  border: 'none',
                  borderRadius: 4,
                  backgroundColor: '#fff',
                  height: 50,
                  padding: '0 1em',
                  boxSizing: 'border-box',
                  width: '100%',
                  margin: 0,
                }}
                value={props.messageSubject || ''}
                onChange={touchSubject}
              />
            </div>
          </div>

          <div className='form-row no-wrap'>
            <div
              className='form-row__col'
              style={{
                fontSize: 16,
                fontWeight: 700,
                display: 'inherit',
                maxWidth: '40vw',
                width: 'auto',
                height: 30,
              }}
            >
              Message
            </div>
            {state.sendType === 'instant' && (
              // eslint-disable-next-line max-len
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                className='form-row__col'
                style={{
                  textAlign: 'right',
                  color: '#4264ea',
                  fontWeight: 400,
                  cursor: 'pointer',
                  fontSize: 16,
                }}
                onClick={undoChanges}
              >
                Undo changes
              </div>
            )}
          </div>
          <div className='form-row form-row--full'>
            <div className=' speech-bubble-left' style={{ marginTop: 0 }}>
              <textarea
                id='ac-textArea'
                className={`text-area-input no-outline scrollbar lite ${
                  state.sendType !== 'instant' ? 'disabledTextArea' : ''
                }`}
                ng-model-options="{ updateOn: 'blur' }" // eslint-disable-line react/no-unknown-property
                spellCheck='false'
                style={{
                  whiteSpace: 'pre-wrap',
                  borderRadius: 5,
                  fontFamily: 'arial',
                  height: '33vh',
                  fontSize: 18,
                  fontWeight: 400,
                  border: 'none',
                  minHeight: 150,
                  maxHeight: 350,
                  lineHeight: '34px',
                  width: '100%',
                  padding: 20,
                }}
                disabled={state.sendType !== 'instant'}
                name='textarea'
                placeholder={
                  state.previewTouched ? '' : 'Loading preview response...'
                }
                value={props.messagePreview || ''}
                onChange={(e) => {
                  dispatch({
                    type: ReducerActions.TOUCH_PREVIEW,
                    payload: true,
                  });
                  props.editMessagePreview(e);
                }}
              />
              <div className='speech-bubblearrow_left' />
            </div>
          </div>
          <div className='form-row--full' style={{ justifyItems: 'end' }}>
            { /* eslint-disable-next-line max-len  */}
            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
            <div
              id='previewAC'
              className={`actionButtonNew ${
                state.selectedUsers.length < 1 && 'disabledButtonNew'
              }`}
              onClick={(e) => {
                if (state.selectedUsers.length >= 1) {
                  Mixpanel.track('Preview Email', {
                    businessId: rootStore.businessStore.selectedBusinessId,
                    businessName: rootStore.businessStore.selectedBusinessName,
                  });
                  props.confirmMessageAction(
                    e,
                    state.selectedUsers,
                    state.sendType,
                    state.selectedCharts
                  );
                }
              }}
              style={{ width: '10vw', marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div>Preview</div>
              <RightOutlined />
            </div>
          </div>
        </section>
      </div>
    );
  };

  /** Render the component */
  return render();
}
