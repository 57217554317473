import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import InvitationForm from '../components/signin/InvitationForm';
import { useStore } from '../stores/Store';
import AuthHandler from '../components/AuthHandler';
import { AiderBrand } from '../components/icons';
import HeroLaptopIllustration from '../components/illustrations/HeroLaptopIllustration';
import { useAuthenticatedEffect } from '../lib/customHooks/useAuthenticatedEffect';
import { Routers } from '../models/enums/utils';

const Invitation = () => {
  const rootStore = useStore();
  const { loadingStore, invitationStore } = rootStore;
  const navigate = useNavigate();

  useAuthenticatedEffect(() => {
    navigate(Routers.DASHBOARD);
  }, []);
  return (
    <>
      <AuthHandler rootStore={rootStore} />
      {!loadingStore.isAnyLoading && invitationStore?.decodedToken && (
        <main className='signup-page'>
          <section className='signup-page__col'>
            <div className='signup-page__col__content'>
              <header className='signup-page__header'>
                <AiderBrand />
              </header>
              <div className='signup-page__illustration'>
                <HeroLaptopIllustration />
              </div>
              <div className='signup-page__col__content'>
                <h1 className='signup-page__title'>Join your firm on Aider</h1>
                {invitationStore?.practiceName && (
                  <p className='signup-page__subtitle'>You have been invited to join {invitationStore?.practiceName}'s Aider Advisory Intelligence Platform</p>
                )}
              </div>
            </div>
          </section>
          <section className='signup-page__col'>
            <div className='signup-page__col__content'>
              <InvitationForm />
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default observer(Invitation);
