import React from 'react';
import { CheckOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps } from 'antd';
import { observer } from 'mobx-react';
import { ClientManagementEnums } from '@aider/constants-library';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import { useStore } from '../../stores/Store';

const ClientListDisplay = ({ clientList, label }:{clientList:Map<string, any>, label: string}) => {
  const rootStore = useStore();
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label,
      children: (
        <ol className='clientListDisplay__list scrollbar'>
          {Array
            .from(clientList.values())
            .sort((a, b) => a?.name?.localeCompare(b?.name))
            .map((client:any) => (
              <li key={client.id}>
                <span className={client?.aipStatus === ClientManagementEnums.BusinessActivationStatus.activated ? 'client--activated' : ''}>
                  <span className='client-name'>
                    {client?.name}
                  </span>
                  {client?.aipStatus === ClientManagementEnums.BusinessActivationStatus.activated && (<span className='activated-flag'><CheckOutlined /> activated</span>)}
                </span>
              </li>
            ))}
        </ol>
      ),
    }
  ];

  return clientList.size > 0 && (

  <Collapse
    className='clientListDisplay'
    items={items}
    size='small'
    ghost
    expandIcon={(props) => (props.isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />)}
    onChange={(selectedIndex) => {
      trackMixpanelEvent({ description: (selectedIndex.length > 0 ? 'Loading Zone: Client list expanded' : 'Loading Zone: Client List closed'), rootStore });
    }}
  />
  );
};

export default observer(ClientListDisplay);
