// @ts-nocheck
import * as React from 'react';
import { MessageConfirmationModal } from '../../components-v2/organisms/MessageConfirmationModal';
import { rootStore } from '../../stores/Store';
import ObservedActionCentreModal from './actionCentreModal';

export function renderVisibleModals(
  actionType,
  actionModalVisible,
  closeActionModal,
  renderActionModal,
) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {actionModalVisible && (
        <ObservedActionCentreModal
          rootStore={rootStore}
          type={actionType}
          // eslint-disable-next-line react/jsx-no-bind
          closeActionModal={closeActionModal.bind(this)}
        />
      )}
      {rootStore.actionStore.confirmedMessageModalVisible
        && rootStore.actionStore.confirmedMessageMedium === 'email'
        && (
          <MessageConfirmationModal
            renderLoadingComponent={false}
            recipientCount={false}
            renderActionModal={renderActionModal}
            businessName={rootStore.businessStore.selectedBusinessData.name}
            subject={rootStore.actionStore.confirmedMessageSubject}
            message={rootStore.actionStore.confirmedMessage}
            users={rootStore.actionStore.confirmedMessageUsers}
            type={rootStore.actionStore.confirmedMessageType}
            actionDetail={rootStore.actionStore.selectedActionItem}
          />
        )}
    </div>
  );
}
