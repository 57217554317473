import React, { useEffect, useState } from 'react';
import { firestore } from 'firebase';
import { ClientManagementEnums, CustomRuleEnums, PeriodCloseTypes } from '@aider/constants-library';
import { Urgency } from '../../ts/enums/Constants';

import PendingRuleBanner from './CheckList/PendingRuleBanner';
import NoRulesBanner from './CheckList/NoRulesBanner';
import { useStore } from '../../stores/Store';
import ButtonSecondary from '../../components/customAntD/ButtonSecondary';
import ComplianceSectionHeader from '../../components/dataCompliance/ComplianceSectionHeader';
import BankRecSyncBanner from '../../components/dataCompliance/BankRecSyncBanner';
import AiderAlert from '../../components/AiderAlert';
import { TableGroupPeriod } from '../../models/interfaces/stores';
import BankRecReconnectBanner from '../../components/dataCompliance/BankRecReconnectBanner';
import PeriodCloseChecklistTable from '../../components/dataCompliance/PeriodCloseChecklistTable';

enum PayrollETLStatus {
  success = 'SUCCESS',
  notConfigured = 'NOT_CONFIGURED',
  noRecentData = 'NO_RECENT_DATA',
  missingScope = 'MISSING_SCOPE',
  countryNotSupported = 'COUNTRY_NOT_SUPPORTED',
}

/**
 * Component that renders the Insight Data Breakdown
 * making up the Period Close page
 * @param props
 * @returns
 */
const InsightDataBreakdown = ({
  title,
  status,
  data,
  actions,
  tableGroupId,
  categoryId,
  selectedPeriod,
  period,
  lastUpdated,
  firestoreUpdatedAt,
}: {
  title: string;
  status: {
    urgency: Urgency;
    urgencyRank: number;
    urgencyIcon: string;
  };
  data: any[];
  actions: {
    text: string;
    link: string;
    type: string;
  }[];
  tableGroupId: string;
  categoryId: string;
  countryCode: string;
  // eslint-disable-next-line react/require-default-props
  selectedPeriod?: string;
  // eslint-disable-next-line react/require-default-props
  period?: TableGroupPeriod;
  lastUpdated: string;
  firestoreUpdatedAt: string;
}) => {
  const { businessesStore, authenticationStore, checklistStore, practiceStore } = useStore();
  const [firestoreDataStatus, setFirestoreDataStatus] = useState<any>(null);

  useEffect(() => {
    if (checklistStore.activeItem) {
      if (data?.length > 0
        && data.findIndex((entry) => entry.itemId === checklistStore.activeItem) > -1
      ) {
        const element = document.getElementById(checklistStore.activeItem);
        const parent = document.getElementById('insightContainer');
        if (parent && element) {
          parent.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth'
          });
        }
      }
    }
    return () => {
      if (checklistStore.activeItem) {
        checklistStore.activeItem = null;
      }
    };
  }, [checklistStore.activeItem]);

  useEffect(() => {
    /**
     * Watch the firestore document for changes and update state
     * as required
     */
    const unsubscribe = firestore()
      .collection('/businesses')
      .doc(businessesStore.selectedBusinessId)
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            setFirestoreDataStatus(snapshot.data()?.dataStatus);
          }
        },
        // eslint-disable-next-line no-console
        (error) => console.error('Could not watch document', error)
      );
    return () => unsubscribe();
  }, [businessesStore.selectedBusinessId]);

  if (businessesStore.selectedBusinessOsp === ClientManagementEnums.OSPKeys.intuit
      && categoryId === CustomRuleEnums.RuleCategories.payroll) {
    return null;
  }
  /**
   * Function to return the appropriate InsightTableItem component
   * with the current data
   * @param tableId - the id of the table to be rendered
   * @param itemData - the data to be rendered
   * @returns
   */
  function renderChecklistItems(tableId: string, itemData) {
    // Transforming data from backend to what we want on the checklist
    const checkListProps: PeriodCloseTypes.ChecklistItem = {
      title: itemData.itemTitle,
      table: itemData.table,
      checkbox: itemData.checkBox,
      checklistIdentifier: {
        checklistId: tableGroupId,
        sectionId: categoryId,
        itemId: itemData.itemId,
        type: itemData.type,
        ruleType: itemData.ruleType,
        ruleItemId: itemData.ruleItemId,
      },
      status: itemData.status,
      countryCode: businessesStore.selectedBusiness?.countryCode,
      selectedPeriod,
      period,
      periodType: period?.periodType,
      itemId: tableId,
      ospKey: businessesStore.selectedBusinessOsp,
      markdown: itemData.markdown,
    };

    return (
      <PeriodCloseChecklistTable
        key={`${businessesStore.selectedBusinessId}-${tableId}-${selectedPeriod}`}
        defaultOpen={checklistStore.activeItem === itemData.itemId}
        {...checkListProps}
      />
    );
  }

  const grantPermissionOnClick = async () => {
    const res = await authenticationStore.reinitializeBusinessAuth(
      window.location.href
    );
    window.location.href = res._links.redirect.href;
    return true;
  };

  /**
   *
   * @returns category component with sub checklists
   */
  const renderCategories = () => {
    let hasAlert = false;
    let showBRAlert = false;
    let alertTitle;
    let alertContent;
    const payrollETLStatus =
      firestoreDataStatus?.payrollETL?.status ?? PayrollETLStatus.notConfigured;
    const hasTableItems = data?.length > 0;

    switch (categoryId) {
      case CustomRuleEnums.RuleCategories.payroll:
        switch (payrollETLStatus) {
          case PayrollETLStatus.notConfigured:
          case PayrollETLStatus.noRecentData:
          case PayrollETLStatus.countryNotSupported:
            alertTitle = 'Xero payroll data not available';
            alertContent = (
              <>
                <p className='alertContent'>
                  Please note: This automated check currently only supports Xero
                  Payroll software.{' '}
                </p>
              </>
            );
            hasAlert = true;
            break;
          case PayrollETLStatus.missingScope:
            alertContent = (
              <>
                <p className='alertContent'>
                  Additional permission needed to access the relevant Xero data.
                  <ButtonSecondary
                    type='link'
                    className='payroll-permission-button'
                    onClick={grantPermissionOnClick}
                  >
                    Grant Permissions
                  </ButtonSecondary>
                </p>
              </>
            );
            hasAlert = true;
            break;
          case PayrollETLStatus.success:
          default:
            hasAlert = false;
            break;
        }
        break;
      case CustomRuleEnums.RuleCategories.bankReconciliation:
        showBRAlert = true;
        break;
      default:
        break;
    }

    const usPractice = practiceStore?.countryCode?.toLowerCase() === 'us';
    const businessDetails = businessesStore.businesses.get(businessesStore.selectedBusinessId);

    return (
      <>
        {hasAlert && (
          <AiderAlert
            className='insight-banner'
            showIcon
            title={alertTitle}
            type='warning'
          >
            {alertContent}
          </AiderAlert>
        )}
        {!hasTableItems && !hasAlert && (
          <NoRulesBanner categoryId={categoryId} />
        )}
        {(showBRAlert) && (businessDetails.profile?.dataStatus === 'disconnected' ? (
          <BankRecReconnectBanner
            periodType={checklistStore.activeChecklistType}
            lastUpdated={lastUpdated}
          />
        ) : (
          <BankRecSyncBanner
            periodType={checklistStore.activeChecklistType}
            lastUpdated={lastUpdated}
            firestoreUpdatedAt={firestoreUpdatedAt}
            checklistId={tableGroupId}
            sectionId={categoryId}
          />
        ))}
        {hasTableItems
          && data
            .filter(
              (entry) => !usPractice || entry.itemId !== 'gstBalanceVsReturn'
            )
            .map((entry) => (
              <div
                id={entry.itemId}
                key={`insightDataSection-${entry.itemId}`}
                className={`period-close-item insightDataSection-${entry.itemId}`}
              >
                {renderChecklistItems(entry.itemId, entry)}
              </div>
            ))}
      </>
    );
  };

  const hasAlertUnchecked = data.some((entry) => entry.status === 'RED' && entry.checkbox?.status !== 'checked');
  /**
   * Component Return
   */
  return (
    <div style={{ width: '100%' }}>
      <ComplianceSectionHeader
        title={title}
        lastUpdated={lastUpdated}
        urgency={status?.urgency}
        sectionId={categoryId}
        checklistId={tableGroupId}
        actions={actions}
        firestoreUpdatedAt={firestoreUpdatedAt}
        hasAlertUnchecked={hasAlertUnchecked}
      />
      <PendingRuleBanner
        businessId={businessesStore.selectedBusinessId}
        checklistId={tableGroupId}
        sectionId={categoryId}
      />
      {renderCategories()}
    </div>
  );
};

/** Export Component */
export default InsightDataBreakdown;
