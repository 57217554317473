import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { IDeleteRequestProps, IGetRequestProps, IPostRequestProps, IPutRequestProps } from '../models/interfaces/lib';

export const GET = async ({
  url,
  headers = null,
  rootStore,
  skipAuth = false,
  sentryTransaction = null,
  sentrySpanName = undefined,
  options = {},
}: IGetRequestProps
) => {
  const transaction = sentryTransaction || Sentry.startTransaction({
    name: 'Make GET Request',
  });

  if (!skipAuth) {
    await rootStore.authenticationStore.renewTokens(transaction);
  }
  const span = transaction.startChild({ op: sentrySpanName || 'makeGETRequest', data: { url } });

  return axios.get(url, {
    validateStatus: (status) => status < 400 || status === 403,
    headers: headers || {
      Authorization: `Bearer ${rootStore.authenticationStore.accessToken}`,
      'X-API-Key': process.env.REACT_APP_API_KEY,
    },
    ...options,
  })
    .then((res) => {
      if (res.status === 403) {
        span.setStatus('Invalid Request');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        Sentry.captureException(res.data);
        return res;
      }
      if (res.status === 200) {
        span.setStatus('ok');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        return res.data;
      }
      span.setStatus(res.statusText);
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(`Request Error: ${res.statusText}`);
      return res.statusText;
    })
    .catch((error) => {
      span.setStatus('unknown_error');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(error);
      throw error;
    });
};

export const POST = async ({
  url,
  data = {},
  rootStore,
  skipAuth = false,
  headers = null,
  sentryTransaction = null,
  sentrySpanName = undefined,
  options = {},
}: IPostRequestProps) => {
  const transaction = sentryTransaction || Sentry.startTransaction({
    name: 'Make Post Request',
  });

  if (!skipAuth) {
    await rootStore.authenticationStore.renewTokens(transaction);
  }
  const reqOpts = {
    validateStatus: (status) => status < 400 || status === 403,
    headers: headers || {
      Authorization: `Bearer ${rootStore.authenticationStore.accessToken}`,
      'X-API-Key': process.env.REACT_APP_API_KEY,
    },
    ...options,
  };

  const span = transaction.startChild({ op: sentrySpanName || 'makePostRequest', data: { url } });
  return axios.post(url, data, reqOpts)
    .then((res) => {
      if (res.status === 403) {
        span.setStatus('Invalid Request');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        Sentry.captureException(res.data);
        return res;
      }
      if (res.status === 200) {
        span.setStatus('ok');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        return res.data;
      }
      span.setStatus(res.statusText);
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(`Request Error: ${res.statusText}`);
      return res.statusText;
    })
    .catch((error) => {
      span.setStatus('unknown_error');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(error);
      throw error;
    });
};

export const PUT = async ({
  url,
  headers = null,
  data = {},
  rootStore,
  skipAuth = false,
  sentryTransaction = null,
  sentrySpanName = undefined,
  options = {},
}: IPutRequestProps) => {
  const transaction = sentryTransaction || Sentry.startTransaction({
    name: 'Make PUT Request',
  });

  if (!skipAuth) {
    await rootStore.authenticationStore.renewTokens(transaction);
  }
  const span = transaction.startChild({ op: sentrySpanName || 'makePUTRequest', data: { url } });

  return axios.put(url, data, {
    validateStatus: (status) => status < 400 || status === 403,
    headers: headers || {
      Authorization: `Bearer ${rootStore.authenticationStore.accessToken}`,
      'X-API-Key': process.env.REACT_APP_API_KEY,
    },
    ...options,
  })
    .then((res) => {
      if (res.status === 403) {
        span.setStatus('Invalid Request');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        Sentry.captureException(res.data);
        return res;
      }
      if (res.status === 200) {
        span.setStatus('ok');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        return res.data;
      }
      span.setStatus(res.statusText);
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(`Request Error: ${res.statusText}`);
      return res.statusText;
    })
    .catch((error) => {
      span.setStatus('unknown_error');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(error);
      throw error;
    });
};

export const DELETE = async ({
  url,
  headers = null,
  data = {},
  rootStore,
  skipAuth = false,
  sentryTransaction = null,
  sentrySpanName = undefined,
  options = {}
}: IDeleteRequestProps) => {
  const transaction = sentryTransaction || Sentry.startTransaction({
    name: 'Make DELETE Request',
  });
  if (!skipAuth) {
    await rootStore.authenticationStore.renewTokens(transaction);
  }
  const span = transaction.startChild({
    op: sentrySpanName || 'makeDELETERequest',
    data: { url },
  });
  return axios.delete(url, {
    validateStatus: (status) => status < 400 || status === 403,
    data,
    headers: headers || {
      Authorization: `Bearer ${rootStore.authenticationStore.accessToken}`,
      'X-API-Key': process.env.REACT_APP_API_KEY,
    },
    ...options,
  })
    .then((res) => {
      if (res.status === 403) {
        span.setStatus('Invalid Request');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        Sentry.captureException(res.data);
        return res;
      }
      if (res.status === 200) {
        span.setStatus('ok');
        span.finish();
        if (!sentryTransaction) {
          transaction.finish();
        }
        return res.data;
      }
      span.setStatus(res.statusText);
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(`Request Error: ${res.statusText}`);
      return res.statusText;
    })
    .catch((error) => {
      span.setStatus('unknown_error');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException(error);
      throw error;
    });
};
