import React from 'react';

import * as Sentry from '@sentry/browser';
import { inject, observer } from 'mobx-react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './App.scss';
import { StoreProvider } from './stores/Store';
import generateRouterConfig from './routes/RoutesConfig';

try {
  const sentryUrl = `${process.env.REACT_APP_SENTRY_URL}/${process.env.REACT_APP_SENTRY_KEY}`;
  Sentry.init({
    dsn: sentryUrl,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.8,
  });
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('Could not setup sentry DSN', e);
}

const App = () => {
  const RouterConfig = generateRouterConfig();
  const router = createBrowserRouter(RouterConfig);

  return (
    <StoreProvider>
      <RouterProvider router={router} />
    </StoreProvider>
  );
};

export default inject('authStore', 'businessStore', 'pageStore', 'GraphStore')(observer(App));
