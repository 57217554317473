import { Tooltip } from 'antd';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';
import { trackMixpanelEvent } from '../lib/mixpanel';
import ButtonSecondary from './customAntD/ButtonSecondary';
import handleError from '../lib/errorHandler';
import DeleteRuleModal from './modals/DeleteRuleModal';

export interface ICreateRuleButtonProps {
  businessId?: string;
  ruleId?: string;
  checklistId: string;
  sectionId: string;
  selectedPeriod?: string;
}

const CreateRuleButton = ({
  businessId,
  ruleId,
  checklistId,
  sectionId,
  selectedPeriod,
}:ICreateRuleButtonProps) => {
  const rootStore = useStore();
  const { localeStore } = rootStore;
  const [buttonLoading, setButtonLoading] = React.useState(false);

  return (
    <Tooltip
      title={localeStore.translation('custom-rules.delete-rule-button.tooltip')}
      placement='bottom'
    >
      <ButtonSecondary
        loading={buttonLoading}
        disabled={buttonLoading}
        type='primary'
        ghost
        shape='circle'
        icon={<DeleteOutlined />}
        className='rule-button rule-button__edit'
        onClick={async () => {
          try {
            setButtonLoading(true);
            trackMixpanelEvent({
              description: 'Delete Rule - Launch Modal',
              rootStore,
              properties: { ruleId },
            });
            DeleteRuleModal({
              businessId: businessId || rootStore.businessesStore.selectedBusinessId,
              ruleId,
              rootStore,
              checklistId,
              sectionId,
              selectedPeriod
            });
            setButtonLoading(false);
          } catch (error) {
            handleError({ error, status: 'unknown_error', transaction: 'Delete Rule', operation: 'handleClick' });
          }
          setButtonLoading(false);
        }}
      />
    </Tooltip>
  );
};

CreateRuleButton.defaultProps = {
  ruleId: null,
  rule: null,
  businessId: undefined,
};

export default observer(CreateRuleButton);
