import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';
import { Brand } from '../../../ts/enums/Colors';
/**
 * The arrow down icon. Also known as a "". `↓`
 * @param props
 * @returns
 */
export const EarlyWarning = ({
  width = 19,
  height = 16,
  color = Brand.teal,
  strokeWidth = 0.5,
  fill = Brand.teal,
  lineCap = 'round',
  lineJoin = 'round',
  padding = 0.25,
  scale = 1,
  ...rest
}: IconInterface) => {
  const scaleWidth = width * scale;
  const scaleHeight = height * scale;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { min, max } = getPlotExtremes({ width: scaleWidth, height: scaleHeight, padding });
  return (

    <svg width={scaleWidth} height={scaleHeight} viewBox='0 0 18 14' fill='none' strokeLinecap={lineCap} strokeLinejoin={lineJoin} xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.91257 4.32525C10.2734 4.11186 11.5648 5.04348 11.797 6.40603C12.0292 7.76866 11.1143 9.04622 9.7535 9.25957L9.40078 10.3624C9.38976 10.3832 9.37348 10.4006 9.35358 10.413C9.2905 10.4522 9.20685 10.4326 9.1667 10.369L8.7106 9.21086C8.70911 9.2082 8.70898 9.20422 8.70686 9.20224C8.30922 9.09406 7.94304 8.89 7.64073 8.60809C6.62448 7.66049 6.55828 6.07968 7.49298 5.0772C7.86758 4.67528 8.36692 4.4108 8.91257 4.32525Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.33394 11.2949C9.8863 11.2949 10.334 11.7427 10.334 12.2949C10.334 12.8473 9.8863 13.2949 9.33394 13.2949C8.78167 13.2949 8.33398 12.8473 8.33398 12.2949C8.33398 11.7427 8.78167 11.2949 9.33394 11.2949Z'
        fill={fill}
      />
      <path
        d='M2.59656 4.00456C2.96305 3.11985 3.50021 2.31599 4.17739 1.63889C4.27226 1.54066 4.32476 1.40909 4.32357 1.27253C4.32238 1.13597 4.26761 1.00534 4.17104 0.908772C4.07448 0.812206 3.94384 0.75743 3.80728 0.756243C3.67072 0.755057 3.53916 0.807554 3.44093 0.902428C2.66706 1.67625 2.05319 2.59493 1.63438 3.60601C1.21556 4.61708 1 5.70075 1 6.79514C1 7.88952 1.21556 8.97319 1.63438 9.98426C2.05319 10.9953 2.66706 11.914 3.44093 12.6878C3.53916 12.7827 3.67072 12.8352 3.80728 12.834C3.94384 12.8328 4.07448 12.7781 4.17104 12.6815C4.26761 12.5849 4.32238 12.4543 4.32357 12.3177C4.32476 12.1812 4.27226 12.0496 4.17739 11.9514C3.50021 11.2743 2.96305 10.4704 2.59656 9.58571C2.23007 8.70099 2.04144 7.75275 2.04144 6.79514C2.04144 5.83752 2.23007 4.88928 2.59656 4.00456Z'
        fill={fill}
      />
      <path
        d='M5.16786 6.79514C5.16786 5.69029 5.60667 4.63067 6.3878 3.8493C6.4856 3.7515 6.54054 3.61886 6.54054 3.48055C6.54054 3.34224 6.4856 3.2096 6.3878 3.1118C6.29 3.014 6.15736 2.95906 6.01905 2.95906C5.88074 2.95906 5.7481 3.014 5.6503 3.1118C5.16652 3.59546 4.78275 4.16968 4.52092 4.80168C4.2591 5.43367 4.12433 6.11105 4.12433 6.79514C4.12433 7.47922 4.2591 8.1566 4.52092 8.7886C4.78275 9.42059 5.16652 9.99481 5.6503 10.4785C5.69873 10.5269 5.75622 10.5653 5.81949 10.5915C5.88276 10.6177 5.95057 10.6312 6.01905 10.6312C6.08754 10.6312 6.15535 10.6177 6.21862 10.5915C6.28189 10.5653 6.33938 10.5269 6.3878 10.4785C6.43623 10.43 6.47464 10.3726 6.50085 10.3093C6.52706 10.246 6.54054 10.1782 6.54054 10.1097C6.54054 10.0412 6.52706 9.97342 6.50085 9.91015C6.47464 9.84688 6.43623 9.78939 6.3878 9.74097C5.60667 8.9596 5.16786 7.89999 5.16786 6.79514Z'
        fill={fill}
      />
      <path
        d='M12.4486 2.99856C12.3853 3.02482 12.3279 3.0633 12.2795 3.1118C12.231 3.16018 12.1925 3.21766 12.1662 3.28093C12.14 3.34421 12.1265 3.41205 12.1265 3.48055C12.1265 3.54906 12.14 3.61689 12.1662 3.68017C12.1925 3.74345 12.231 3.80092 12.2795 3.8493C13.0606 4.63067 13.4994 5.69029 13.4994 6.79514C13.4994 7.89999 13.0606 8.9596 12.2795 9.74097C12.231 9.78939 12.1926 9.84688 12.1664 9.91015C12.1402 9.97342 12.1267 10.0412 12.1267 10.1097C12.1267 10.1782 12.1402 10.246 12.1664 10.3093C12.1926 10.3726 12.231 10.43 12.2795 10.4785C12.3279 10.5269 12.3854 10.5653 12.4487 10.5915C12.5119 10.6177 12.5797 10.6312 12.6482 10.6312C12.7167 10.6312 12.7845 10.6177 12.8478 10.5915C12.9111 10.5653 12.9685 10.5269 13.017 10.4785C13.5008 9.99481 13.8845 9.42059 14.1463 8.7886C14.4082 8.1566 14.5429 7.47922 14.5429 6.79514C14.5429 6.11105 14.4082 5.43367 14.1463 4.80168C13.8845 4.16968 13.5008 3.59546 13.017 3.1118C12.9686 3.0633 12.9111 3.02482 12.8478 2.99856C12.7846 2.9723 12.7167 2.95879 12.6482 2.95879C12.5797 2.95879 12.5119 2.9723 12.4486 2.99856Z'
        fill={fill}
      />
      <path
        d='M14.8581 0.750977C14.72 0.750977 14.5876 0.805828 14.4899 0.90347V0.902428C14.3922 1.0001 14.3374 1.13255 14.3374 1.27066C14.3374 1.40876 14.3922 1.54122 14.4899 1.63889C15.1671 2.31599 15.7042 3.11985 16.0707 4.00456C16.4372 4.88928 16.6258 5.83752 16.6258 6.79514C16.6258 7.75275 16.4372 8.70099 16.0707 9.58571C15.7042 10.4704 15.1671 11.2743 14.4899 11.9514C14.395 12.0496 14.3425 12.1812 14.3437 12.3177C14.3449 12.4543 14.3997 12.5849 14.4962 12.6815C14.5928 12.7781 14.7234 12.8328 14.86 12.834C14.9966 12.8352 15.1281 12.7827 15.2263 12.6878C16.7889 11.1251 17.6668 9.00561 17.6668 6.79566C17.6668 4.5857 16.7889 2.46624 15.2263 0.90347C15.1287 0.805828 14.9962 0.750977 14.8581 0.750977Z'
        fill={fill}
      />
      <path
        d='M2.59656 4.00456C2.96305 3.11985 3.50021 2.31599 4.17739 1.63889C4.27226 1.54066 4.32476 1.40909 4.32357 1.27253C4.32238 1.13597 4.26761 1.00534 4.17104 0.908772C4.07448 0.812206 3.94384 0.75743 3.80728 0.756243C3.67072 0.755057 3.53916 0.807554 3.44093 0.902428C2.66706 1.67625 2.05319 2.59493 1.63438 3.60601C1.21556 4.61708 1 5.70075 1 6.79514C1 7.88952 1.21556 8.97319 1.63438 9.98426C2.05319 10.9953 2.66706 11.914 3.44093 12.6878C3.53916 12.7827 3.67072 12.8352 3.80728 12.834C3.94384 12.8328 4.07448 12.7781 4.17104 12.6815C4.26761 12.5849 4.32238 12.4543 4.32357 12.3177C4.32476 12.1812 4.27226 12.0496 4.17739 11.9514C3.50021 11.2743 2.96305 10.4704 2.59656 9.58571C2.23007 8.70099 2.04144 7.75275 2.04144 6.79514C2.04144 5.83752 2.23007 4.88928 2.59656 4.00456Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
      <path
        d='M5.16786 6.79514C5.16786 5.69029 5.60667 4.63067 6.3878 3.8493C6.4856 3.7515 6.54054 3.61886 6.54054 3.48055C6.54054 3.34224 6.4856 3.2096 6.3878 3.1118C6.29 3.014 6.15736 2.95906 6.01905 2.95906C5.88074 2.95906 5.7481 3.014 5.6503 3.1118C5.16652 3.59546 4.78275 4.16968 4.52092 4.80168C4.2591 5.43367 4.12433 6.11105 4.12433 6.79514C4.12433 7.47922 4.2591 8.1566 4.52092 8.7886C4.78275 9.42059 5.16652 9.99481 5.6503 10.4785C5.69873 10.5269 5.75622 10.5653 5.81949 10.5915C5.88276 10.6177 5.95057 10.6312 6.01905 10.6312C6.08754 10.6312 6.15535 10.6177 6.21862 10.5915C6.28189 10.5653 6.33938 10.5269 6.3878 10.4785C6.43623 10.43 6.47464 10.3726 6.50085 10.3093C6.52706 10.246 6.54054 10.1782 6.54054 10.1097C6.54054 10.0412 6.52706 9.97342 6.50085 9.91015C6.47464 9.84688 6.43623 9.78939 6.3878 9.74097C5.60667 8.9596 5.16786 7.89999 5.16786 6.79514Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
      <path
        d='M12.4486 2.99856C12.3853 3.02482 12.3279 3.0633 12.2795 3.1118C12.231 3.16018 12.1925 3.21766 12.1662 3.28093C12.14 3.34421 12.1265 3.41205 12.1265 3.48055C12.1265 3.54906 12.14 3.61689 12.1662 3.68017C12.1925 3.74345 12.231 3.80092 12.2795 3.8493C13.0606 4.63067 13.4994 5.69029 13.4994 6.79514C13.4994 7.89999 13.0606 8.9596 12.2795 9.74097C12.231 9.78939 12.1926 9.84688 12.1664 9.91015C12.1402 9.97342 12.1267 10.0412 12.1267 10.1097C12.1267 10.1782 12.1402 10.246 12.1664 10.3093C12.1926 10.3726 12.231 10.43 12.2795 10.4785C12.3279 10.5269 12.3854 10.5653 12.4487 10.5915C12.5119 10.6177 12.5797 10.6312 12.6482 10.6312C12.7167 10.6312 12.7845 10.6177 12.8478 10.5915C12.9111 10.5653 12.9685 10.5269 13.017 10.4785C13.5008 9.99481 13.8845 9.42059 14.1463 8.7886C14.4082 8.1566 14.5429 7.47922 14.5429 6.79514C14.5429 6.11105 14.4082 5.43367 14.1463 4.80168C13.8845 4.16968 13.5008 3.59546 13.017 3.1118C12.9686 3.0633 12.9111 3.02482 12.8478 2.99856C12.7846 2.9723 12.7167 2.95879 12.6482 2.95879C12.5797 2.95879 12.5119 2.9723 12.4486 2.99856Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
      <path
        d='M14.8581 0.750977C14.72 0.750977 14.5876 0.805828 14.4899 0.90347V0.902428C14.3922 1.0001 14.3374 1.13255 14.3374 1.27066C14.3374 1.40876 14.3922 1.54122 14.4899 1.63889C15.1671 2.31599 15.7042 3.11985 16.0707 4.00456C16.4372 4.88928 16.6258 5.83752 16.6258 6.79514C16.6258 7.75275 16.4372 8.70099 16.0707 9.58571C15.7042 10.4704 15.1671 11.2743 14.4899 11.9514C14.395 12.0496 14.3425 12.1812 14.3437 12.3177C14.3449 12.4543 14.3997 12.5849 14.4962 12.6815C14.5928 12.7781 14.7234 12.8328 14.86 12.834C14.9966 12.8352 15.1281 12.7827 15.2263 12.6878C16.7889 11.1251 17.6668 9.00561 17.6668 6.79566C17.6668 4.5857 16.7889 2.46624 15.2263 0.90347C15.1287 0.805828 14.9962 0.750977 14.8581 0.750977Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
