import React, { ReactChild, ReactElement } from 'react';

const Separator = ({ children }: {children?:ReactElement|ReactChild}) => (
  <div className='separator'>
    {children && <span className='separator-text'>{children}</span>}
  </div>
);

Separator.defaultProps = {
  children: null,
};

export default Separator;
