import React from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import DashboardSelectedFilters from './DashboardSelectedFilters';
import { useAuthenticatedEffect } from '../../lib/customHooks/useAuthenticatedEffect';
import OpenAssistant from '../OpenAssistant';
import ImpersonateUser from '../ImpersonateUser';
import DashboardFilterEmpty from './DashboardFilterEmpty';
import { Routers } from '../../models/enums/utils';

const DashboardFilters = ({ location } : { location?: Routers.DASHBOARD | Routers.PERIOD_CLOSE_DASHBOARD }) => {
  const { businessesStore, periodCloseDashboardStore, dashboardStore, practiceStore, practiceTagStore } =
    useStore();

  const targetStore = location === Routers.DASHBOARD ? dashboardStore : periodCloseDashboardStore;

  useAuthenticatedEffect(() => {
    if (practiceStore.id) {
      practiceTagStore.fetchTags();
    }
  }, [practiceStore.id]);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const filterSort = (optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
  const dropdownRender = (menu) => (
    <div className='dashboard__filters__dropdown'>
      {menu}
    </div>
  );

  return (
    <header className='dashboard__filters'>
      <section className='dashboard__filters__top'>
        <aside className='dashboard__filters__items'>
          <h5>Filter by:</h5>
          <Select
            allowClear
            maxTagCount={0}
            className='dashboard__filters__items--input'
            mode='multiple'
            size='large'
            showSearch
            filterOption={filterOption}
            filterSort={filterSort}
            options={businessesStore?.linesOfBusiness?.map((lob) => ({
              value: lob,
              label: lob,
            }))}
            value={targetStore?.activeIndustryFilter}
            placeholder='Industry'
            onSelect={targetStore.addIndustryFilter}
            onDeselect={targetStore.removeIndustryFilter}
            onClear={targetStore.clearIndustryFilter}
            maxTagPlaceholder={`${targetStore?.activeIndustryFilter?.length} Industries Selected`}
            dropdownRender={dropdownRender}
            notFoundContent={<DashboardFilterEmpty filter='industry' />}
          />
          <Select
            maxTagCount={0}
            allowClear
            className='dashboard__filters__items--input'
            mode='multiple'
            size='large'
            showSearch
            filterOption={filterOption}
            filterSort={filterSort}
            options={practiceTagStore?.tags?.map((tag) => ({
              value: tag,
              label: tag,
            }))}
            value={targetStore?.activeTagFilter}
            placeholder='Custom Tags'
            onSelect={targetStore.addTagFilter}
            onDeselect={targetStore.removeTagFilter}
            onClear={targetStore.clearTagFilter}
            maxTagPlaceholder={`${targetStore?.activeTagFilter?.length} Tags Selected`}
            dropdownRender={dropdownRender}
            notFoundContent={<DashboardFilterEmpty filter='tags' />}
          />
        </aside>
        <aside className='dashboard__filters__items'>
          {location === Routers.DASHBOARD && <OpenAssistant />}
          <ImpersonateUser />
        </aside>
      </section>
      <DashboardSelectedFilters targetStore={targetStore} />
    </header>
  );
};

DashboardFilters.defaultProps = {
  location: Routers.DASHBOARD,
};

export default observer(DashboardFilters);
