// TODO: Refactor out the eslint-disables
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import { isEmpty } from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';
import { clearPersistedStore, makePersistable, stopPersisting } from 'mobx-persist-store';
import {
  getLineOfBusinessList,
  getOverviewScreenData,
  getTooltipData,
} from '../../services/DataService';
import { FilterType } from '../../ts/enums/Filter';
import { SelectedTags } from '../../ts/types/Overview';
import { multiExist } from '../../utils';

export default class BusinessAggregationStore {
  rootStore: any;

  getOverviewScreenData: any;

  getLineOfBusinessList: any;

  getTooltipData: any;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.getOverviewScreenData = getOverviewScreenData;
    this.getLineOfBusinessList = getLineOfBusinessList;
    this.getTooltipData = getTooltipData;

    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }

  initStorePersistance() {
    makePersistable(this, {
      name: 'GraphStore',
      properties: [
        'linesOfBusiness',
        'sortBy',
        'selectedBusinessesOverview',
      ],
      storage: window.localStorage,
    });
  }

  async clearStoredData() {
    await clearPersistedStore(this);
    await stopPersisting(this);
  }

  businessesOverview = { businesses: [] };

  selectedBusinessesOverview = { businesses: [] };

  overviewScreenDataLoading = false;

  overviewScreenDataAppendLoading = false;

  currentBankAccountList = [];

  pagesTotal = 0;

  totalBusinessesNumber = 0;

  currentBusinessNumber = 0;

  linesOfBusiness = [];

  sortBy = null;

  businessesLoading: string[] = [];

  businessesDoneLoading: string[] = [];

  setSortBy(sortBy) {
    this.sortBy = sortBy;
  }

  setOverviewScreenLoading(loading) {
    this.overviewScreenDataLoading = loading;
  }

  setOverviewScreenAppendLoading(loading) {
    this.overviewScreenDataAppendLoading = loading;
  }

  setOverviewScreenData(data, append = false) {
    if (data.businesses) {
      if (append) {
        this.businessesOverview.businesses =
          this.businessesOverview.businesses.concat(data.businesses);
      } else {
        this.businessesOverview.businesses = data.businesses;
        this.pagesTotal = data.pagesTotal;
        this.totalBusinessesNumber = data.totalBusinesses;
        this.currentBusinessNumber = data.totalBusinesses;
      }
    } else {
      this.businessesOverview.businesses = [];
      this.pagesTotal = 0;
      this.totalBusinessesNumber = 0;
      this.currentBusinessNumber = 0;
    }
  }

  setLineOfBusinessList(data) {
    if (data && data.linesOfBusiness) {
      this.linesOfBusiness = [...data.linesOfBusiness, 'All Industries'];
    } else {
      this.linesOfBusiness = ['All industries'];
    }
  }

  // eslint-disable-next-line consistent-return
  setSelectedBusinessesOverview({ currentBusinessesList, tags }) {
    const customTag = toJS(tags).filter(
      (tag: SelectedTags) => tag.type === FilterType.CUSTOM_TAG
    )[0];
    const industryTag = tags.filter(
      (tag: SelectedTags) => tag.type === FilterType.INDUSTRY
    )[0];

    if (isEmpty(tags)) {
      this.selectedBusinessesOverview.businesses = currentBusinessesList;
    }

    if (tags.length === 1) {
      const filteredBusiness = currentBusinessesList.filter(
        (item) => multiExist(item?.tags ?? [], customTag?.tags ?? [])
          || multiExist(item?.lineOfBusiness ?? '', industryTag?.tags ?? [])
      );
      this.selectedBusinessesOverview.businesses = filteredBusiness;
    }

    if (tags.length > 1) {
      const filteredBusiness = currentBusinessesList.filter(
        (item) => multiExist(item?.tags ?? [], customTag?.tags)
          && multiExist(item?.lineOfBusiness ?? '', industryTag?.tags)
      );
      this.selectedBusinessesOverview.businesses = filteredBusiness;
    }
  }

  setBusinessNumber(augmentedBusinessesList, filteredBusinessesList) {
    this.totalBusinessesNumber = augmentedBusinessesList.length;
    this.currentBusinessNumber = filteredBusinessesList.length;
  }

  resyncingBusinesses: string[] = [];

  setBusinessesLoading(
    businessId: string,
    loading: boolean,
    error: boolean = false
  ): boolean {
    if (loading) {
      if (!this.businessesLoading.includes(businessId)) {
        this.businessesLoading.push(businessId);
      }
    } else {
      this.setBusinessDoneLoading(businessId, true);
      this.businessesLoading = this.businessesLoading.filter(
        (id) => id !== businessId
      );
    }

    if (this.businessesLoading.length === 0 && !error) {
      this.clearBusinessesDoneLoading();
      return true;
    }

    return false;
  }

  setBusinessResyncing(businessId: string, resyncing: boolean): void {
    if (resyncing) {
      if (!this.resyncingBusinesses.includes(businessId)) this.resyncingBusinesses.push(businessId);
    } else {
      this.resyncingBusinesses = this.resyncingBusinesses.filter(
        (id) => id !== businessId
      );
    }
  }

  setBusinessDoneLoading(businessId: string, status: boolean): void {
    if (status) {
      if (!this.businessesDoneLoading.includes(businessId)) {
        this.businessesDoneLoading.push(businessId);
      }
    } else {
      this.businessesDoneLoading = this.businessesDoneLoading.filter(
        (id) => id !== businessId
      );
    }
  }

  clearBusinessesDoneLoading(): void {
    this.businessesDoneLoading = [];
  }
}
