import { ApiConstants } from '@aider/constants-library';
import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import type { RootStore } from './Store';
import { GET } from '../lib/requests';

export default class SubscriptionStore {
  rootStore: RootStore;

  trialDaysLeft: number;

  type: string;

  status: string;

  reason: string;

  requiresPaywallRedirect: boolean;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = rootStore;
  }

  set subscription(subscription) {
    this.trialDaysLeft = subscription.trialDaysLeft;
    this.type = subscription.type;
    this.status = subscription.status;
    this.reason = subscription.reason;
  }

  get isTrial() {
    return this.type === 'trial' && this.trialDaysLeft > 0;
  }

  get isTrialExpired() {
    return (typeof this.trialDaysLeft !== 'undefined'
      && this.trialDaysLeft < 1
      && this.type === 'trial')
    || this.status === 'inactive';
  }

  get isCancelled() {
    return this.reason && this.reason.toLowerCase().includes('cancelled');
  }

  get subscription() {
    return {
      trialDaysLeft: this.trialDaysLeft,
      type: this.type,
      status: this.status,
      reason: this.reason,
    };
  }

  paywallRedirect() {
    if (this?.isCancelled) {
      return this.checkoutRedirect();
    }
    return this.portalRedirect();
  }

  portalRedirect() {
    try {
      const practiceId = this.rootStore.practiceStore.id;
      const url = `${ApiConstants.apiEndpointsBase.business}/practices/${practiceId}/subscription/portal`;
      GET({ url, rootStore: this.rootStore }).then((data) => {
        window.location.href = data.portalRedirect;
      });
    } catch (error) {
      Sentry.captureException('Could not navigate to subscription portal', error);
    }
  }

  checkoutRedirect() {
    try {
      const practiceId = this.rootStore.practiceStore.id;
      const url = `${ApiConstants.apiEndpointsBase.business}/practices/${practiceId}/subscription/checkout`;
      GET({ url, rootStore: this.rootStore }).then((data) => {
        window.location.href = data.checkoutRedirect;
      });
    } catch (error) {
      Sentry.captureException('Could not navigate to subscription checkout', error);
    }
  }
}
