import { useEffect } from 'react';

// Global attributes to store the effect, dependencies, and delay
const debounceAttributes = {
  timeout: null,
  effect: null,
  dependencies: null,
  delay: null,
};

// Initialise the debounce effect
const debounceEffect = () => setTimeout(debounceAttributes.effect, debounceAttributes.delay);

// Clear the debounce effect
const clearDebounce = () => clearTimeout(debounceAttributes.timeout);

// Custom hook to debounce a useEffect
export const useDebouncedEffect = (effect, dependencies = [], delay) => useEffect(() => {
  // Store the effect, dependencies, and delay in global attributes
  debounceAttributes.effect = effect;
  debounceAttributes.dependencies = dependencies;
  debounceAttributes.delay = delay;

  // Initialise the debounce effect
  debounceAttributes.timeout = debounceEffect();

  // Clear the debounce effect on unmount
  return clearDebounce;

  // Ignore warning for spreads in deps as it is necessary for the hook to work
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, dependencies);
