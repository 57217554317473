import React from 'react';

import { ToolOutlined } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import { PeriodCloseTypes } from '@aider/constants-library';
import CreateRuleButton from '../../../components/CreateRuleButton';
import DeleteRuleButton from '../../../components/DeleteRuleButton';
import { useStore } from '../../../stores/Store';
import ButtonSecondary from '../../../components/customAntD/ButtonSecondary';
import PayrollAccountsModal from '../../../components/modals/PayrollAccountsModal';

const configurableItems = [
  'wagesComparison',
  'payeComparison',
  'superPaymentVsPayroll',
  'payePaymentVsPayroll',
  'superBalanceVsPayroll',
];

const itemAccountNameMap = {
  wagesComparison: 'Wages',
  payeComparison: 'Payroll',
  superPaymentVsPayroll: 'Superannuation',
  payePaymentVsPayroll: 'Payroll',
  superBalanceVsPayroll: 'Superannuation',
};

const RuleCheckListOptions = (
  { checklistIdentifier, selectedPeriod, isPractice }: PeriodCloseTypes.RuleChecklistOptions
) => {
  const rootStore = useStore();
  const { practiceStore, businessesStore } = rootStore;
  const [form] = Form.useForm();
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);
  return (
    <>
      {checklistIdentifier.type !== 'STANDARD' && (
        <>
          <DeleteRuleButton
            businessId={isPractice ? practiceStore.id : businessesStore.selectedBusiness?.id}
            ruleId={checklistIdentifier.itemId}
            checklistId={checklistIdentifier.checklistId}
            sectionId={checklistIdentifier.sectionId}
            selectedPeriod={selectedPeriod}
          />
          <CreateRuleButton
            businessId={isPractice ? practiceStore.id : businessesStore.selectedBusiness?.id}
            ruleId={checklistIdentifier.itemId}
            checklistId={checklistIdentifier.checklistId}
            sectionId={checklistIdentifier.sectionId}
            selectedPeriod={selectedPeriod}
            allowKeywords={isPractice}
          />
        </>
      )}
      {(configurableItems.includes(checklistIdentifier.itemId)) && (
        <Tooltip
          visible={tooltipOpen}
          title={`Edit ${itemAccountNameMap[checklistIdentifier.itemId]} Accounts`}
          placement='bottom'
        >
          <ButtonSecondary
            onMouseOver={() => {
              if (!modalLoading) {
                setTooltipOpen(true);
              }
            }}
            onMouseLeave={() => setTooltipOpen(false)}
            loading={modalLoading}
            className='rule-button rule-button__edit'
            onClick={() => {
              setModalLoading(true);
              PayrollAccountsModal({
                modalType: checklistIdentifier.itemId,
                rootStore,
                form,
                checklistId: checklistIdentifier.checklistId,
                selectedPeriod,
                setOpened: () => setModalLoading(false),
              });
              setTooltipOpen(false);
            }}
            icon={<ToolOutlined />}
            shape='circle'
          />
        </Tooltip>
      )}
    </>
  );
};

RuleCheckListOptions.defaultProps = {
  isPractice: false,
};

export default RuleCheckListOptions;
