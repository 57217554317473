import _ from 'lodash';
import { TextRun, ExternalHyperlink, Paragraph, TableRow, TableCell, Table, ShadingType, convertInchesToTwip, WidthType, ImageRun } from 'docx';
import { ReportEnums } from '@aider/constants-library';
import { BankAccount, RawBankAccount } from '../models/interfaces/stores';
import { formatChartAndTableName, formatTableData, sortPerformanceReportTable } from './componentHelpers/reportHelpers';

export function convertBankAccounts(bankAccounts: RawBankAccount[]): BankAccount[] {
  return bankAccounts.map((account) => ({
    accountId: account.account_id,
    accountCode: account.account_code,
    accountType: account.account_type,
    name: account.account_name,
    id: account.account_id,
    number: account.account_code,
    businessId: account.business_id,
    createdAt: account.created_at,
    currencyCode: account.currency_code,
    entityId: account.entity_id,
    systemAccount: account.system_account,
    updatedAt: account.updated_at,
    accountName: account.account_name,
  }));
}

/**
  * Interlaces style ranges to correctly handle overlapping styles
  * Used to convert Draft.js inlineStyleRanges to styled substring groups
  * @param styleRanges - Array of style ranges
  * returns
  */
export function interlaceStyleRanges(styleRanges: {
  offset: number;
  length: number;
  style: string
}[]): {
  offset: number;
  length: number;
  style: string[]
}[] {
  styleRanges.sort((a, b) => a.offset - b.offset);
  const result = [];

  styleRanges.forEach(({ offset, length, style }) => {
    const rangeEnd = offset + length;
    let found = false;

    for (let i = 0; i < result.length; i++) {
      const item = result[i];
      const itemEnd = item.offset + item.length;
      const itemOffset = item.offset;

      if (offset >= item.offset && offset <= itemEnd) {
        if (offset > item.offset) {
          item.length = offset - itemOffset;

          if (rangeEnd <= itemEnd) {
            result.push({
              offset,
              length,
              style: [...item.style, style],
            });
          }

          if (rangeEnd < itemEnd) {
            result.push({
              offset: rangeEnd,
              length: itemEnd - rangeEnd,
              style: item.style,
            });
          }
        }

        if (offset === item.offset && length === item.length) {
          item.length = rangeEnd - item.offset;
          item.style.push(style);
        }

        found = true;
        break;
      }
    }

    if (!found) {
      result.push({
        offset,
        length,
        style: [style],
      });
    }
  });

  return result;
}

/**
 * Converts Draft.js text block to styled substring groups for
 * inserting into DocX templates
 * @param text - The text to convert
 * @param inlineStyleRanges - Array of style ranges
 * returns
 */
export function convertToStyledSubstrings({ text, inlineStyleRanges, entityRanges }, entityMap) {
  const interlacedRanges = interlaceStyleRanges(inlineStyleRanges);
  const pairings = [];
  let currEntity: any;
  interlacedRanges.forEach((range, rangeIndex) => {
    entityRanges.forEach((entityRange, entityIndex) => {
      if ((range.offset <= entityRange.offset && range.offset + range.length >= entityRange.offset)
        || (range.offset + range.length >= entityRange.offset && range.offset + range.length <= entityRange.offset + entityRange.length)
        || (range.offset >= entityRange.offset && range.offset <= entityRange.offset + entityRange.length)
      ) {
        pairings.push([rangeIndex, entityIndex]);
      }
    });
    if (pairings.length > 1) {
      return pairings;
    }
    return false;
  });

  const result = [];
  let currentIndex = 0;

  let textSlice;
  let segmentEntities = [];

  interlacedRanges.forEach(({ offset, length, style }, index) => {
    const entities = pairings.filter((pair) => pair[0] === index).map((pair) => entityRanges[pair[1]]);

    if (currentIndex < offset) {
      textSlice = text.slice(currentIndex, offset);
      segmentEntities = entities.filter((entity) => (
        ((entity.offset >= currentIndex && entity.offset < offset)
          || (entity.offset + entity.length >= currentIndex && entity.offset + entity.length <= offset)
          || (entity.offset >= currentIndex && entity.offset + entity.length >= offset))
        && entityMap?.[entity.key]?.type === 'LINK'
      ));
      if (segmentEntities.length > 0) {
        segmentEntities.forEach((entity) => {
          const entityData = entityMap?.[entity.key];
          const start = entity.offset < currentIndex ? 0 : entity.offset - currentIndex;
          const end = start + entity.length > textSlice.length ? textSlice.length : start + entity.length;
          if (start > 0) {
            result.push({
              text: textSlice.slice(0, start),
              style: null,
            });
          }
          result.push({
            text: textSlice.slice(start, end),
            entityData,
          });
          if (end < textSlice.length) {
            result.push({
              text: textSlice.slice(end),
              style: null,
            });
          }
        });
      } else {
        result.push({
          text: textSlice,
          style: null,
        });
      }
    }

    textSlice = text.slice(offset, offset + length);

    segmentEntities = entities.filter((entity) => (
      ((entity.offset >= offset && entity.offset <= offset + length)
        || (entity.offset + entity.length >= offset && entity.offset + entity.length <= offset + length)
        || (entity.offset >= offset && entity.offset + entity.length >= offset + length))
      && entityMap?.[entity.key]?.type === 'LINK'
    ));
    if (segmentEntities.length > 0) {
      segmentEntities.forEach((entity) => {
        const entityData = entityMap?.[entity.key];
        const start = entity.offset < offset ? 0 : entity.offset - offset;
        const end = (start - (offset - entity.offset) + entity.length) > textSlice.length ? textSlice.length : start - (offset - entity.offset) + entity.length;
        if (start > 0) {
          result.push({
            text: textSlice.slice(0, start),
            style,
          });
        }
        result.push({
          text: textSlice.slice(start, end),
          entityData,
          style,
        });
        if (end < textSlice.length) {
          result.push({
            text: textSlice.slice(end),
            style,
          });
        }
      });
    } else {
      result.push({
        text: textSlice,
        style,
      });
    }

    currentIndex = offset + length;
  });
  if (currentIndex < text.length) {
    textSlice = text.slice(currentIndex);
    segmentEntities = entityRanges.filter((entity) => (
      (entity.offset >= currentIndex
        || entity.offset + entity.length >= currentIndex)
      && entityMap?.[entity.key]?.type === 'LINK'
    ));

    if (segmentEntities.length > 0) {
      segmentEntities.forEach((entity) => {
        const entityData = entityMap?.[entity.key];
        const start = entity.offset < currentIndex ? 0 : entity.offset - currentIndex;
        const end = (start - (currentIndex - entity.offset) + entity.length) > textSlice.length ? textSlice.length : start - (currentIndex - entity.offset) + entity.length;
        if (start > 0) {
          result.push({
            text: textSlice.slice(0, start),
            style: null,
          });
        }
        result.push({
          text: textSlice.slice(start, end),
          entityData,
          style: null,
        });
        if (end < textSlice.length) {
          result.push({
            text: textSlice.slice(end),
            style: null,
          });
        }
      });
    } else {
      currEntity = Object.values(entityMap).find((entity: any) => entity.data === textSlice);
      result.push({
        text: textSlice,
        style: null,
        type: currEntity?.type
      });
    }
  }
  return result;
}

export function generateStyledChildren(block: any, entityMap: any = {}, data: any = {}) {
  return (convertToStyledSubstrings(block, entityMap).flatMap((styledSubstring) => {
    const variableKey = styledSubstring.text.replace('#{', '').replace('}', '').replaceAll(' ', '');
    const blockContent = _.template(styledSubstring.text, { interpolate: /#{([\s\S]+?)}/g })(data);

    if (styledSubstring?.type === 'IMAGE'
      || ReportEnums.ReportVariables?.[variableKey] === ReportEnums.VariableFormattingTypes.IMAGE
    ) {
      return new ImageRun({
        data: blockContent,
        transformation: {
          width: 150,
          height: 150,
        }
      });
    }
    return blockContent.split('\n').map((text) => {
      const textRun = text !== '' ? new TextRun({
        text,
        bold: styledSubstring.style?.includes('BOLD'),
        italics: styledSubstring.style?.includes('ITALIC'),
        style: styledSubstring?.entityData && styledSubstring?.entityData?.type === 'LINK' ? 'Hyperlink' : null,
      })
        : new TextRun({ break: 1 });
      if (styledSubstring.entityData && styledSubstring.entityData.type === 'LINK') {
        return new ExternalHyperlink({
          children: [textRun],
          link: styledSubstring.entityData?.data?.url,
        });
      }
      return textRun;
    });
  }));
}

export function generateDocXTable(selectedBusiness, financialYearEnd, selectedInsight, settings, granularity) {
  if (!selectedInsight) {
    return new Paragraph({});
  }
  const rawTableData = formatTableData(selectedBusiness, financialYearEnd, selectedInsight.graph, settings, granularity);
  const headerRow = new TableRow({
    children: rawTableData.columns.map((column, inx) => new TableCell({
      margins: {
        top: convertInchesToTwip(0.02),
        bottom: convertInchesToTwip(0.02),
        left: convertInchesToTwip(0.04),
        right: convertInchesToTwip(0.04),
      },
      children: [new Paragraph({
        children: [new TextRun({
          text: column.title,
          bold: true,
          color: '0A1E70',
        })],
        alignment: inx === 0 ? 'start' : 'end',
      })],
      shading: {
        type: ShadingType.CLEAR,
        fill: 'F5F5F9',
      },
    })),
  });
  const rawData = [...rawTableData.data];

  // Sort data according to stored settings if target column is included in data
  if (rawTableData.columns.findIndex((col) => col.dataIndex === settings?.sortTarget) > -1) {
    if (settings?.sortTarget) {
      rawData.sort((a, b) => sortPerformanceReportTable(a, b, settings?.sortTarget, granularity));
    }

    if (settings?.sortOrder === 'descend') {
      rawData.reverse();
    }
  }

  const tableData = [];
  for (let i = 0; i < rawData.length; i++) {
    const row = [];
    rawTableData
      .columns
      .forEach((column, inx) => {
        row.push(new TableCell({
          margins: {
            top: convertInchesToTwip(0.02),
            bottom: convertInchesToTwip(0.02),
            left: convertInchesToTwip(0.04),
            right: convertInchesToTwip(0.04),
          },
          children: [new Paragraph({
            text: rawData[i]?.[column.dataIndex]?.formattedValue || '',
            alignment: inx === 0 ? 'start' : 'end'
          })],
        }));
      });
    tableData.push(new TableRow({ children: row }));
  }

  return [
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [headerRow, ...tableData]
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: formatChartAndTableName(selectedInsight.graph.yAxis),
          italics: true,
          color: '5B5C61',
          size: 10,
        })
      ],
      shading: {
        type: ShadingType.SOLID,
        color: 'F5F5F9',
      },
      alignment: 'start',
    })
  ];
}

export function determineValueValidity(value: any) {
  if (_.isString(value)) {
    return value;
  }

  if (_.isNil(value) || _.isNaN(value) || !_.isFinite(value)) {
    return 'N/A';
  }

  return value;
}

export function calculateTrendData(trendData: any) {
  const newTrendDta = {};

  Object.keys(trendData).forEach((key) => {
    const period = trendData[key];
    const newData = { ...period };

    Object.keys(trendData).forEach((compareKey) => {
      if (key !== compareKey) {
        const comparePeriod = trendData[compareKey];

        Object.keys(period).forEach((insight) => {
          if (!_.isNil(period[insight]) && !_.isNil(comparePeriod[insight])) {
            const percentage = Math.abs((period[insight] - comparePeriod[insight]) / comparePeriod[insight]) * 100;
            const trend = percentage > 0 ? 'higher' : 'lower';

            newData[`${insight}_${compareKey}`] = determineValueValidity(comparePeriod[insight]);
            newData[`${insight}_${compareKey}_percentage`] = percentage;
            newData[`${insight}_${compareKey}_trend`] = trend;
          }
        });
      }
    });
    newTrendDta[key] = newData;
  });

  return newTrendDta;
}
