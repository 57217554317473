import { CustomRuleEnums, AccountEnums } from '@aider/constants-library';
import _ from 'lodash';

const buildChartOfAccounts = (chartOfAccounts: any) => {
  const setAccountsRecursive = (accounts: any, path: string) => Object.entries(accounts).flatMap((account: any) => {
    if (account[0] === 'accounts') {
      return account[1].map((accountInfo: any) => ({
        value: `${path}.${accountInfo.accountId}`,
        title: accountInfo.accountName,
      }));
    }

    const newPath = `${path}.${account[0]}`;
    const treeItem: any = {
      value: newPath,
      title: AccountEnums.AccountMapping[account[0]] ?? _.startCase(account[0]),
    };

    if (account[1]) {
      treeItem.children = setAccountsRecursive(account[1], newPath);
    }

    return treeItem;
  });

  return Object.entries(chartOfAccounts).map((accountClass) => ({
    value: accountClass[0],
    title: AccountEnums.CategoryMap[accountClass[0]],
    children: setAccountsRecursive(accountClass[1], accountClass[0]),
  }));
};

const getSortAndFilteredChartOfAccounts = (chartOfAccounts: any) => {
  const sortedChartOfAccounts = {};
  const sectionCategories = {
    [CustomRuleEnums.RuleCategories.profitAndLoss]: [AccountEnums.CategoryMap.REVENUE, AccountEnums.CategoryMap.EXPENSE],
    [CustomRuleEnums.RuleCategories.balanceSheet]: [AccountEnums.CategoryMap.ASSET, AccountEnums.CategoryMap.LIABILITY, AccountEnums.CategoryMap.EQUITY],
    other: [AccountEnums.CategoryMap.ASSET, AccountEnums.CategoryMap.LIABILITY, AccountEnums.CategoryMap.EQUITY, AccountEnums.CategoryMap.REVENUE, AccountEnums.CategoryMap.EXPENSE],
    revenueAccounts: [AccountEnums.CategoryMap.REVENUE, AccountEnums.CategoryMap.EXPENSE],
    directCostsAccounts: [AccountEnums.CategoryMap.REVENUE, AccountEnums.CategoryMap.EXPENSE],
    opexAccounts: [AccountEnums.CategoryMap.REVENUE, AccountEnums.CategoryMap.EXPENSE],
    incomeTaxAccounts: [AccountEnums.CategoryMap.REVENUE, AccountEnums.CategoryMap.EXPENSE],
  };

  const sortAccounts = (account) => {
    if (account.children?.length) {
      // eslint-disable-next-line no-param-reassign
      account.children = account.children.slice().sort((a, b) => a.title?.localeCompare(b.title));
      // eslint-disable-next-line no-param-reassign
      account.children = account.children.map(sortAccounts);
    }

    return account;
  };

  Object.keys(sectionCategories).forEach((section) => {
    sortedChartOfAccounts[section] = chartOfAccounts
      .filter((account) => sectionCategories[section].includes(account.title))
      .map(sortAccounts);
  });

  return sortedChartOfAccounts;
};

export {
  buildChartOfAccounts,
  getSortAndFilteredChartOfAccounts,
};
