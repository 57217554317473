export function getIndexOfClosestFutureDate(dateArray: string[]) {
  const currentDate = new Date();
  const futureDates = dateArray?.filter((date) => new Date(date) > currentDate);

  if (!futureDates || futureDates.length === 0) {
    return 0; // indicating no future date found
  }

  const closestFutureDate = futureDates.reduce((a, b) => (new Date(a) < new Date(b) ? a : b));
  return dateArray?.indexOf(closestFutureDate);
}

export function getIndexOfClosestPastDate(dateArray: string[]) {
  const currentDate = new Date();
  const pastDates = dateArray?.filter((date) => new Date(date) > currentDate);

  if (!pastDates || pastDates.length === 0) {
    return 0; // indicating no past date found
  }

  const closestPastDate = pastDates.reduce((a, b) => (new Date(a) > new Date(b) ? a : b));
  return dateArray?.indexOf(closestPastDate);
}
