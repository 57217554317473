import React, { useState } from 'react';
import { ToolOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';
import handleError from '../../../lib/errorHandler';
import { ModalType } from '../../../models/enums/modals';
import DeletePromptModal from '../../modals/DeletePromptModal';
import SavePromptModal from '../../modals/SavePromptModal';

const PromptActions = ({ prompt }) => {
  const [buttonLoadingDelete, setButtonLoadingDelete] = useState(false);
  const [tooltipVisibleDelete, setTooltipVisibleDelete] = useState(false);
  const [buttonLoadingEdit, setButtonLoadingEdit] = useState(false);
  const [tooltipVisibleEdit, setTooltipVisibleEdit] = useState(false);
  const rootStore = useStore();
  const { localeStore } = rootStore;
  const [form] = Form.useForm();
  form.setFieldsValue({
    label: prompt.label,
    prompt: prompt.text,
  });

  return (
    <>
      <div>
        <Tooltip
          title={localeStore.translation('custom-rules.delete-rule-button.tooltip')}
          placement='bottom'
          open={tooltipVisibleDelete}
          onOpenChange={setTooltipVisibleDelete}
        >
          <ButtonSecondary
            loading={buttonLoadingDelete}
            disabled={buttonLoadingDelete}
            type='primary'
            ghost
            shape='circle'
            icon={<DeleteOutlined />}
            className={`rule-button ${'rule-button__edit'}`}
            mixpanelEvent='Button Click - Delete Prompt Modal Launched'
            mixpanelProperties={{ prompt }}
            onClick={async () => {
              setTooltipVisibleDelete(false);
              try {
                setButtonLoadingDelete(true);
                // Delete Button
                DeletePromptModal({ rootStore, prompt });
              } catch (error) {
                handleError({ error, status: 'unknown_error', transaction: 'Create Rule', operation: 'handleClick' });
              }
              setButtonLoadingDelete(false);
            }}
          />
        </Tooltip>
      </div>
      <div>
        <Tooltip
          title={localeStore.translation('custom-rules.edit-rule-button.tooltip')}
          placement='bottom'
          open={tooltipVisibleEdit}
          onOpenChange={setTooltipVisibleEdit}
        >
          <ButtonSecondary
            loading={buttonLoadingEdit}
            disabled={buttonLoadingEdit}
            type='primary'
            ghost
            shape='circle'
            icon={<ToolOutlined />}
            className={`rule-button ${'rule-button__edit'}`}
            mixpanelEvent='Button Click - Edit Prompt Modal Launched'
            mixpanelProperties={{ prompt }}
            onClick={async () => {
              setTooltipVisibleEdit(false);
              try {
                setButtonLoadingEdit(true);
                // Edit Button
                SavePromptModal(rootStore, prompt, form, ModalType.EDIT);
              } catch (error) {
                handleError({ error, status: 'unknown_error', transaction: 'Create Rule', operation: 'handleClick' });
              }
              setButtonLoadingEdit(false);
            }}
          />
        </Tooltip>
      </div>
    </>
  );
};

export default observer(PromptActions);
