import React from 'react';
import { observer } from 'mobx-react';
import AssistantChat from '../components/AdvisoryAssistant/AssistantChat';
import { useStore } from '../stores/Store';

const AdvisoryAssistant = () => {
  const { loadingStore } = useStore();
  return (!loadingStore.isAnyLoading) && (
    <AssistantChat page='assistant' />
  );
};

export default observer(AdvisoryAssistant);
