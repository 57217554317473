import React from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';
import { useStore } from '../../../stores/Store';
import LoadingSpinner from '../../LoadingSpinner';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import Notification from '../../Notification';
import { useAuthenticatedEffect } from '../../../lib/customHooks/useAuthenticatedEffect';

const ClientInformation = () => {
  const { businessesStore, practiceTagStore } = useStore();
  const [saving, setSaving] = React.useState(false);
  const [form] = Form.useForm();

  useAuthenticatedEffect(() => {
    practiceTagStore.fetchTags();
    businessesStore.fetchBusinessConnection();
  });

  const handlePhoneChange = (syntheticEvent) => {
    const updatedBusiness = { ...businessesStore.selectedBusiness };
    _.set(updatedBusiness, 'profile.phoneNumber', syntheticEvent.currentTarget.value);
    businessesStore.updateBusiness(updatedBusiness);
  };

  const handleTagChange = (tags: string[]) => {
    const updatedBusiness = { ...businessesStore.selectedBusiness };
    updatedBusiness.tags = tags;
    businessesStore.updateBusiness(updatedBusiness);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await businessesStore.persistSelectedBusiness();
      Notification({
        type: 'success',
        title: 'Client information updated successfully',
      });
    } catch {
      Notification({
        type: 'error',
        title: 'Could not save client information.',
        description: ' Please try again later',
      });
    }
    setSaving(false);
  };

  return (businessesStore?.selectedBusiness?.name) ? (
    <section className='client-settings__content scrollbar'>
      <div className='client-settings__content--main scrollbar'>
        <h3>Client Information</h3>
        <Form form={form} layout='vertical'>
          <Row>
            <Col span={24}>
              <Form.Item
                label={(<h5>Business Phone Number</h5>)}
              >
                <Input onChange={handlePhoneChange} defaultValue={businessesStore.selectedBusiness?.profile?.phoneNumber} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <h5>Industry</h5>
              <p>{businessesStore.selectedBusiness.lineOfBusiness}</p>
            </Col>
            <Col span={12}>
              <h5>GST Number</h5>
              <p>{businessesStore.selectedBusiness?.connection?.taxNumber}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label={(<h5>Custom Tags</h5>)}>
                <Select
                  defaultValue={businessesStore.selectedBusiness?.tags?.map((tag) => ({ value: tag }))}
                  options={practiceTagStore.tags?.map((tag) => ({ value: tag }))}
                  mode='tags'
                  showSearch
                  onChange={handleTagChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <footer>
        <ButtonPrimary
          loading={saving}
          disabled={saving}
          onClick={handleSave}
          mixpanelEvent='Client Information - Save'
          size='large'
          mixpanelProperties={{ updatedBusiness: { id: businessesStore.selectedBusinessId, name: businessesStore.selectedBusiness?.name } }}
        >
          Save
        </ButtonPrimary>

      </footer>
    </section>
  ) : (
    <LoadingSpinner />
  );
};

export default observer(ClientInformation);
