import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { UsersEnums, ApiConstants } from '@aider/constants-library';
import type { RootStore } from './Store';
import { IManageAdvisorsProps } from '../models/interfaces/components';
import { GET, PUT, POST } from '../lib/requests';

class AdvisorsStore {
  rootStore: RootStore;

  activeAdvisors: IManageAdvisorsProps[] = [];

  inactiveAdvisors: IManageAdvisorsProps[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get advisors(): IManageAdvisorsProps[] {
    return [...this.activeAdvisors, ...this.inactiveAdvisors];
  }

  get sortedAdvisors(): IManageAdvisorsProps[] | null {
    const { email } = this.rootStore.userStore;

    // get current user
    const currentAdvisor = this.advisors.find(
      (advisor) => advisor.email.toLowerCase() === email.toLowerCase()
    );

    // get admin user
    const adminAdvisor = this.advisors.find(
      (advisor) => advisor.role.includes('practice_admin')
        || advisor.role.includes('admin')
    );

    // updated current user's role to include 'ME'
    if (currentAdvisor && !currentAdvisor.role.includes('Me')) {
      currentAdvisor.role = [...currentAdvisor.role, 'Me'];
    }

    // sort rest of the advisors's name  with alphabetical order
    const sortedAdvisors = this.advisors
      .filter(
        (advisor) => ![currentAdvisor?.id, adminAdvisor?.id].includes(advisor.id)
      )
      .sort((a, b) => a.fullName.localeCompare(b.fullName, 'en'));

    if (currentAdvisor) {
      sortedAdvisors.unshift(currentAdvisor);
    }

    if (adminAdvisor && adminAdvisor !== currentAdvisor) {
      sortedAdvisors.unshift(adminAdvisor);
    }

    return sortedAdvisors;
  }

  get sortedPracticeAdvisors(): IManageAdvisorsProps[] | null {
    return this.sortedAdvisors?.filter(
      (advisor) => (
        (this.isPracticeAdmin(advisor) || this.isPracticeUser(advisor))
        && !this.isAiderStaff(advisor)
      )
    );
  }

  get sortedActiveAdvisors(): IManageAdvisorsProps[] | null {
    return this.sortedPracticeAdvisors?.filter((advisor) => (this.isActive(advisor)));
  }

  retrieveAllAdvisors = async () => {
    await this.retrieveActiveAdvisors();
    await this.retrieveInactiveAdvisors();
  };

  retrieveActiveAdvisors = async () => {
    try {
      const practiceId = this.rootStore.practiceStore.id;
      const urlUser = `${ApiConstants.apiEndpointsBase.user}/businesses/${practiceId}/users`;

      const res = await GET({ url: urlUser, rootStore: this.rootStore });

      if (!res.businessUsers) {
        throw Error('Advisor data not returned');
      }

      const advisorsActive: IManageAdvisorsProps[] = res.businessUsers.map(
        (advisor) => ({
          id: advisor.id,
          fullName: advisor.displayName,
          email: advisor.email.toLowerCase(),
          role: [advisor.roleKey],
          status: UsersEnums.Status.ACTIVE,
          type: advisor.accountType,
        })
      );

      this.activeAdvisors = advisorsActive;

      return this.activeAdvisors;
    } catch (error) {
      this.activeAdvisors = [];
      Sentry.captureException('Could not retrieve advisors', error);
      return error;
    }
  };

  retrieveInactiveAdvisors = async () => {
    const practiceId = this.rootStore.practiceStore.id;
    const urlInvitation = `${ApiConstants.apiEndpointsBase.business}/practices/${practiceId}/invitations/unaccepted`;

    const invitationsList = await GET({
      url: urlInvitation,
      rootStore: this.rootStore,
    });

    const advisorsInactive: IManageAdvisorsProps[] =
      invitationsList.invitations.map((advisor) => {
        let status: UsersEnums.Status;

        switch (advisor.status) {
          case 'pending':
            status = UsersEnums.Status.PENDING;
            break;
          case 'cancel':
            status = UsersEnums.Status.CANCELLED;
            break;
          default:
            status = UsersEnums.Status.EXPIRED;
            break;
        }

        return {
          id: advisor.invitationId,
          fullName: advisor.displayName,
          email: advisor.email.toLowerCase(),
          role: [advisor.roleKey],
          status,
          type: advisor.accountType,
        };
      });
    this.inactiveAdvisors = advisorsInactive;
  };

  inviteAdvisor = (
    value: Pick<IManageAdvisorsProps, 'givenName' | 'familyName' | 'email'>
  ): Promise<void> => new Promise((resolve, reject) => {
    const practiceId = this.rootStore.practiceStore.id;
    const { givenName, familyName, email } = value;

    const urlInvite = `${ApiConstants.apiEndpointsBase.business}/practices/${practiceId}/invitations`;

    const invitation = {
      givenName,
      familyName,
      displayName: givenName,
      email: email.toLowerCase(),
    };

    POST({
      url: urlInvite,
      data: { invitation },
      rootStore: this.rootStore,
    })
      .then((res) => {
        if (res?.message === 'Invitation sent') {
          this.retrieveAllAdvisors();
          resolve();
        } else {
          reject();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        reject();
      });
  });

  reinviteAdvisor = (
    value: Pick<IManageAdvisorsProps, 'id'>
  ): Promise<void> => new Promise((resolve, reject) => {
    const { practiceStore } = this.rootStore;
    const practiceId = practiceStore.id;
    const invitationId = value.id;

    const urlReinvite = `${ApiConstants.apiEndpointsBase.business}/practices/${practiceId}/invitations/${invitationId}/resend`;

    PUT({
      url: urlReinvite,
      data: value.id,
      rootStore: this.rootStore,
    })
      .then((res) => {
        if (res?.message === 'Invitation re-sent') {
          this.retrieveAllAdvisors();
          resolve();
        } else {
          reject();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        reject();
      });
  });

  isActive = (advisor): boolean => {
    const { status } = advisor;
    return status === UsersEnums.Status.ACTIVE;
  }

  isAdmin = (advisor): boolean => {
    const { role } = advisor;
    return role.includes(UsersEnums.RoleKeys.Admin) || role.includes(UsersEnums.RoleKeys.PRACTICE_ADMIN);
  }

  isPracticeAdmin = (advisor): boolean => {
    const { role } = advisor;
    return role.includes(UsersEnums.RoleKeys.PRACTICE_ADMIN);
  }

  isPracticeUser = (advisor): boolean => {
    const { role } = advisor;
    return role.includes(UsersEnums.RoleKeys.PRACTICE_USER);
  }

  isAiderStaff = (advisor): boolean => {
    const { type } = advisor;
    return type === 'AI';
  }
}

export default AdvisorsStore;
