import { RefObject, useEffect, useMemo, useState } from 'react';

const useIsInViewport = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const screenObserver = useMemo(() => new IntersectionObserver(
    ([entry]) => {
      setIntersecting(entry.isIntersecting);
    }
  ), [ref]);

  useEffect(() => {
    if (ref) {
      screenObserver.observe(ref.current);
    }
    return () => screenObserver.disconnect();
  }, [ref]);

  return isIntersecting;
};

export default useIsInViewport;
