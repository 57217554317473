import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const externalLinkSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='currentColor'>
    <path d='M15.6328 11.6048V16.6048C15.6328 17.0468 15.4572 17.4708 15.1447 17.7833C14.8321 18.0959 14.4082 18.2715 13.9661 18.2715H4.79948C4.35745 18.2715 3.93353 18.0959 3.62097 17.7833C3.30841 17.4708 3.13281 17.0468 3.13281 16.6048V7.43815C3.13281 6.99612 3.30841 6.5722 3.62097 6.25964C3.93353 5.94708 4.35745 5.77148 4.79948 5.77148H9.79948' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.1328 3.27148H18.1328V8.27148' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.96606 12.4382L18.1327 3.27148' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

const ExternalLinkIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={externalLinkSvg} {...props} />
);

export default ExternalLinkIcon;
