import * as React from 'react';
import { rootStore } from '../../stores/Store';
import { ChartColor } from '../../ts/enums/Colors';
import { ChartFontSize } from '../../ts/enums/Constants';
import formatNumber from '../atoms/FormatNumber';
import { barArrayFNonGrouped, borderArrayF } from '../atoms/GraphSettings';
import { renderTooltipActionItems } from '../atoms/TooltipActionItem';
import ChartWrapper from './ChartWrapper';
// eslint-disable-next-line import/no-named-as-default
import yAxisFormat from '../../utils/yAxisFormat';

interface BarChartProps {
  insightData: any;
  index?: number | null;
  hoverIndex?: number | null;
  chartId: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BarChart({ insightData, index, hoverIndex, chartId }: BarChartProps) {
  const dataSetArray = insightData.graph.legend.map((item, ix) => ({
    label: item.label,
    data: insightData.graph.data.map(
      (dp) => dp.values.length > ix && dp.values[ix] && dp.values[ix]?.rawValue
    ),
    order: 1,
    categoryPercentage: 0.9,
    barPercentage: 0.28,
    borderColor: borderArrayF[ix],
    borderRadius: 6,
    backgroundColor: barArrayFNonGrouped[ix],
    type: 'bar',
    borderWidth: 2,
  }));
  const annotationObject = {
    zero: {
      // Indicates the type of annotation
      type: 'line',
      yMin: 0,
      yMax: 0,
      borderColor: 'rgba(159,160,172,0.67)',
    },
    box1: undefined,
  };
  const { title, units, isCurrency } = insightData.graph.yAxis;
  if (insightData.graph.yAxis.annotations) {
    annotationObject.box1 = {
      // Indicates the type of annotation
      type: 'line',
      yMin: insightData.graph.yAxis.annotations[0].rawValue,
      yMax: insightData.graph.yAxis.annotations[0].rawValue,
      borderColor: 'rgba(159,160,172,0.67)',
      borderDash: [4, 3],
      label: {
        content: insightData.graph.yAxis.annotations[0].label,
        enabled: true,
        position: 'end',
        backgroundColor: 'rgba(159,160,172,0.67)',
        color: '#505050',
      },
    };
  }

  return (
    // @ts-ignore
    <ChartWrapper
      // @ts-ignore
      setChartHandle={(chart, id) => rootStore.actionStore.setChartObject(chart, id)}
      setB64Image={(id, chart) => rootStore.actionStore.setChartImage(chart, id)}
      id={`${insightData.insightKey}`}
      chartId={chartId}
      type='bar'
      data={{
        labels: insightData.graph.data.map((datapoint) => datapoint.xLabel),
        datasets: dataSetArray,
      }}
      options={{
        aspectRatio: 1.5,
        responsive: true,
        animation: true,
        plugins: {
          annotation: {
            annotations: annotationObject,
          },
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 20,
              boxHeight: 20,
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
          },
          tooltip: {
            callbacks: {
              label(context) {
                let { label } = context.dataset;
                if (
                  !context.parsed.y
                  || !insightData.graph.data[context.dataIndex]
                ) {
                  return null;
                }
                const value =
                  insightData.graph.data[context.dataIndex].values[
                    context.datasetIndex
                  ];
                if (!value || value.formattedValue === null) {
                  return null;
                }
                label += `: ${value.formattedValue}`;
                return label;
              },
              footer: (contextUnresolved) => {
                const context = contextUnresolved?.[0];
                if (!insightData?.summaries?.[context?.dataIndex]) return '';
                return renderTooltipActionItems(context, insightData);
              },
            },
            backgroundColor: ChartColor.opaqueBlack,
            titleFont: {
              size: ChartFontSize.defaultSize,
            },
            bodyFont: {
              size: ChartFontSize.defaultSize,
            },
            footerFont: {
              size: ChartFontSize.defaultSize,
            },
          },
        },

        scales: {
          x: {
            ticks: {
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            stacked: true,
            title: {
              display: true,
              text: '',
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              callback(value, inx, values) {
                return formatNumber(value, insightData.graph.yAxis.units);
              },
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            title: {
              display: true,
              text: yAxisFormat(title, units, isCurrency),
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            grid: {
              display: true,
              borderDash: [1, 2],
              color: '#d5d8e3',
            },
            stacked: true,
          },
        },
      }}
    />
  );
}

BarChart.defaultProps = {
  index: null,
  hoverIndex: null,
};

export default BarChart;
