import { makeAutoObservable } from 'mobx';
import { DateTime } from 'luxon';
import { getFinancialYear } from '@aider/aider-period-library';
import type { RootStore } from './Store';

export default class TemplateTextStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getProfitabilityInsightText(insightKey: string) {
    // TODO: implement period granularity changes for the period
    // this.rootStore.reportTemplateStore.selectedPeriodData?.granularity
    let trendTemplate = '';
    const currentPeriod = DateTime.fromISO(this.rootStore.reportTemplateStore.selectedPeriodData?.end).minus({ months: 1 });
    const insightName = this.rootStore.localeStore.translation(`report-template-editor.variables.${insightKey}`);
    const month = currentPeriod.monthLong;
    const lastMonth = currentPeriod.minus({ months: 1 }).monthLong;
    const { year } = currentPeriod;
    const lastYear = currentPeriod.minus({ years: 1 }).year;
    const financialYear = getFinancialYear(
      DateTime.fromISO(this.rootStore.reportTemplateStore.selectedPeriodData?.start),
      this.rootStore.businessesStore.selectedBusinessFinancialYearEndObject,
      this.rootStore.businessesStore.selectedBusiness.timeZoneId
    );
    const financialYearEnd = financialYear.end.year;
    const lastFinancialYearEnd = financialYearEnd - 1;
    const previousFinancialYearEnd = lastFinancialYearEnd - 1;
    const currentVsLastYearPercentage = `${insightKey}_lastYear_percentage`;
    const currentVsLastYearTrend = `${insightKey}_lastYear_trend`;
    const currentVsLastPercentage = `${insightKey}_last_percentage`;
    const currentVsLastTrend = `${insightKey}_last_trend`;
    const yearToDateAmount = `${insightKey}_financialYear`;
    const yearToDateVsLastYearPercentage = `${insightKey}_lastFinancialYear_percentage`;
    const yearToDateVsLastYearTrend = `${insightKey}_lastFinancialYear_trend`;
    const yearToDateVsPreviousYearPercentage = `${insightKey}_previousFinancialYear_percentage`;
    const yearToDateVsPreviousYearTrend = `${insightKey}_previousFinancialYear_trend`;

    trendTemplate += `${month} ${year} ${insightName} is #{${insightKey}}, this is #{${currentVsLastYearPercentage}} `;
    trendTemplate += `#{${currentVsLastYearTrend}} compared to ${month} ${lastYear} and #{${currentVsLastPercentage}} `;
    trendTemplate += `#{${currentVsLastTrend}} than ${lastMonth} ${year}.\n\n`;
    trendTemplate += `${insightName} for ${financialYearEnd} financial year to ${month} is #{${yearToDateAmount}} this is `;
    trendTemplate += `#{${yearToDateVsLastYearPercentage}} #{${yearToDateVsLastYearTrend}} compared to the same period in `;
    trendTemplate += `${lastFinancialYearEnd} and #{${yearToDateVsPreviousYearPercentage}} #{${yearToDateVsPreviousYearTrend}} `;
    trendTemplate += `than ${previousFinancialYearEnd}.`;

    return trendTemplate;
  }
}
