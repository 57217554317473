import React from 'react';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import { useStore } from '../../stores/Store';
import TierPriceConfirmForm from '../molecules/TierPriceConfirmForm';
import tierPageLogo from '../../entities/icons/tierPageLogo.svg';

/**
 * TierPriceConfirmForm Page.
 *
 * This page is used to display the Tier Price Confirmation Page:
 * To make an request to the CS team to upgrade to a Bespoke plan (current Tier is the top tier).
 *
 * @state: practice name, current tier info
 * @action: To check TierPriceConfirmForm
 */
const TierPriceConfirmPage: React.FC = () => {
  const { practiceStore } = useStore();
  const format = new Format(practiceStore.subscription?.currency, practiceStore?.countryCode);

  return (
    <div className='tier__confirm'>
      <img
        src={tierPageLogo}
        alt='Request upgrade to Bespoke Plan'
      />
      <h3 className='confirm__title'>Request upgrade to Bespoke Plan</h3>
      <div className='content__wrapper'>
        <div className='tier__confirm__info'>
          <h4 className='confirm__subTitle'>
            Who should we contact about upgrading your practice to a Bespoke
            Plan?
          </h4>

          <TierPriceConfirmForm />
        </div>

        <div className='tier__current__info'>
          <h4 className='current__info__title'>Your Current Plan</h4>

          <div className='current__info__subContent'>
            <p className='current__subTitle'>Practice</p>
            <p>{practiceStore.name}</p>
          </div>

          <div className='current__info__subContent'>
            <p className='current__subTitle'>Plan</p>
            <span>{practiceStore?.subscriptionDetails?.currentTier?.name}</span>
            <span>
              {format.formatValue({ format: ValueTypes.currency, value: practiceStore?.subscriptionDetails?.currentTier?.prices.monthly })}{' '}
              / monthly or{' '}
              {format.formatValue({ format: ValueTypes.currency, value: practiceStore?.subscriptionDetails?.currentTier?.prices.yearly })} /
              year
            </span>
            <span>
              {practiceStore?.subscriptionDetails?.currentTier?.clients.min} -{' '}
              {practiceStore?.subscriptionDetails?.currentTier?.clients.max}
            </span>
          </div>

          <div className='current__info__subContent'>
            <p className='current__subTitle'>Current number of clients added</p>
            <span>
              {practiceStore?.subscriptionDetails?.currentClientCount}
            </span>
            <span>
              You can add{' '}
              {practiceStore?.subscriptionDetails?.currentTier?.clients?.max || 0
                - practiceStore?.subscriptionDetails?.currentClientCount || 0}{' '}
              more
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TierPriceConfirmPage;
