import { toJS } from 'mobx';
/**
 * Check if Array has some elements of another array -
 * @param: target: string[] | string, checkValues: string[]
 * @returns boolean
 * @example multiExist([2, 4, 5], [1, 2, 3, 4]) return true
 */
export const multiExist = (
  target: string[] | string,
  checkValues: string[] | any[]
) => (
  checkValues.some(
    (value) => toJS(target).includes(value)
  )
);

export default multiExist;
