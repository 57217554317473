/**
 * Enum containing map of key value pairs for
 * subject placeholders
 */
export enum EMessageSubjectMap {
  invoiceStatus = 'Invoicing reminder',
  reconciliation = 'Reconciliation request',
  default = 'Financial performance updates',
}

/**
 * Enum containing the possible actions for the reducer
 * controlling state for the Insight Action Card
 */
export enum EInsightActionReducerActions {
  CHARTS = 'SET_SELECTED_CHARTS',
  END_DATE = 'SET_END_DATE',
  START_DATE = 'SET_START_DATE',
  TEXT_INPUT = 'SET_TEXT_INPUT',
  CATEGORY = 'SET_CATEGORY',
  RECONCILIATION_MSG = 'SET_RECONCILIATION_MESSAGE',
  TOUCH_PREVIEW = 'SET_PREVIEW_TOUCHED',
  TOUCH_SUBJECT = 'SET_SUBJECT_TOUCHED',
  SELECT_USERS = 'SET_SELECTED_USERS',
  SELECT_IDS = 'SET_SELECTED_IDS',
  SET_DAYS = 'SET_DAYS',
  SET_HOUR = 'SET_HOUR',
  SELECTED_FREQUENCY = 'SET_SELECTED_FREQUENCY',
  SELECTED_DAYS = 'SET_SELECTED_DAYS',
  SEND_TYPE = 'SET_SEND_TYPE',
  NEW_EMAIL = 'SET_NEW_EMAIL',
  SEND_SINGLE = 'SET_SEND_SINGLE'
}
