import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { InsightColumnType } from '../../ts/enums/utils';
import CellSpacer from './CellSpacer';

const CellPrefix = ({ cell }) => {
  if (cell.cellType === InsightColumnType.TREND && cell.rawData !== 0) {
    return (
      <span className='text-bold'>
        {cell.rawData > 0 ? (
          <ArrowUpOutlined
            aria-label='arrow-up'
          />
        ) : (
          <ArrowDownOutlined
            aria-label='arrow-down'
          />
        )}
        <CellSpacer newline={false} />
      </span>
    );
  }

  return cell?.prefix
    ? (
      <span className='text-bold'>
        {cell?.prefix.value}
        <CellSpacer newline={cell?.prefix.newline} />
      </span>
    )
    : null;
};

export default CellPrefix;
