import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import InsightDataHealthHeader from '../../components-v2/molecules/InsightDataHealthHeader';

const DataHealthBannerContainer = ({ insightData }) => {
  const { timePeriodStore } = useStore();

  if (insightData.insightKey === 'incomeTax'
  && timePeriodStore.isDataHealthStatusAvailable
    && timePeriodStore.incomeTaxPeriodDataHealthStatus?.color.length > 0) {
    return (
      <InsightDataHealthHeader
        data={timePeriodStore.incomeTaxPeriodDataHealthStatus}
      />
    );
  }

  if ((insightData.insightKey === 'cashFlow'
      || insightData.insightKey === 'cashFlowActual')
      && timePeriodStore.incomeTaxPeriods?.[0]?.dataHealthStatus) {
    return (
      <InsightDataHealthHeader
        data={timePeriodStore.incomeTaxPeriods[0].dataHealthStatus}
      />
    );
  }

  if (insightData.insightKey !== 'incomeTax'
    && insightData.dataHealthStatus) {
    return (
      <InsightDataHealthHeader
        data={insightData.dataHealthStatus}
      />
    );
  }

  return <></>;
};

export default observer(DataHealthBannerContainer);
