import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
  <svg width='1em' viewBox='0 0 33 32' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M21.9591 3.57116C22.1935 3.1396 22.7333 2.97977 23.1649 3.21417C27.6303 5.63951 30.6723 10.3725 30.6723 15.8162C30.6723 23.7291 24.2574 30.1504 16.3381 30.1504C15.847 30.1504 15.4489 29.7523 15.4489 29.2612C15.4489 28.7701 15.847 28.372 16.3381 28.372C23.2747 28.372 28.8939 22.7474 28.8939 15.8162C28.8939 11.0518 26.233 6.90442 22.3161 4.77697C21.8845 4.54257 21.7247 4.00271 21.9591 3.57116Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M16.3381 3.2664C9.40152 3.2664 3.78234 8.891 3.78234 15.8222C3.78234 20.5873 6.43791 24.7343 10.3542 26.8614C10.7858 27.0958 10.9456 27.6357 10.7112 28.0672C10.4768 28.4988 9.93695 28.6586 9.50539 28.4242C5.03933 25.9985 2.00391 21.2652 2.00391 15.8222C2.00391 7.9093 8.41881 1.48796 16.3381 1.48796C16.8292 1.48796 17.2273 1.88608 17.2273 2.37718C17.2273 2.86828 16.8292 3.2664 16.3381 3.2664Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M22.8871 10.1946C23.2679 10.5048 23.3251 11.0649 23.0149 11.4457L15.172 21.0729C15.0066 21.2759 14.7602 21.3957 14.4984 21.4004C14.2366 21.405 13.986 21.2941 13.8136 21.097L9.85357 16.5739C9.53008 16.2044 9.56737 15.6426 9.93687 15.3191C10.3064 14.9956 10.8682 15.0329 11.1917 15.4024L14.4581 19.1334L21.6361 10.3224C21.9463 9.94166 22.5064 9.88446 22.8871 10.1946Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M13.475 0.119039C13.7503 -0.0397802 14.0893 -0.0396728 14.3644 0.119321L17.37 1.85627C17.6449 2.01517 17.8143 2.30862 17.8143 2.62617C17.8143 2.94373 17.6449 3.23718 17.37 3.39607L14.3644 5.13301C14.0893 5.292 13.7503 5.29211 13.475 5.13329C13.1998 4.97447 13.0303 4.68086 13.0303 4.36311V0.889218C13.0303 0.571464 13.1998 0.277858 13.475 0.119039ZM14.8087 2.43014V2.82219L15.1479 2.62617L14.8087 2.43014Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M18.8697 26.873C19.1446 27.0319 19.3139 27.3253 19.3139 27.6428V31.1108C19.3139 31.4285 19.1444 31.7222 18.8692 31.881C18.594 32.0398 18.2549 32.0397 17.9798 31.8807L14.9742 30.1438C14.6991 29.9847 14.5297 29.691 14.53 29.3732C14.5302 29.0554 14.7 28.7619 14.9754 28.6033L17.9809 26.8723C18.2561 26.7138 18.5949 26.7141 18.8697 26.873ZM17.1986 29.3752L17.5355 29.5699V29.1811L17.1986 29.3752Z' />
  </svg>
);

const QuickbooksLogoScaleable = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={Svg}
    {...props}
    aria-label='insights'
  />
);

export default QuickbooksLogoScaleable;
