import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ewsSvg = () => (

  <svg height='1em' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M8.57952 8.54996C9.94036 8.33657 11.2318 9.26819 11.464 10.6307C11.6962 11.9934 10.7813 13.2709 9.42045 13.4843L9.06773 14.5871C9.05671 14.6079 9.04043 14.6253 9.02053 14.6377C8.95745 14.6769 8.8738 14.6573 8.83365 14.5937L8.37755 13.4356C8.37607 13.4329 8.37593 13.4289 8.37381 13.4269C7.97618 13.3188 7.60999 13.1147 7.30768 12.8328C6.29143 11.8852 6.22523 10.3044 7.15993 9.30192C7.53453 8.89999 8.03387 8.63551 8.57952 8.54996Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M8.99996 15.5195C9.55232 15.5195 10 15.9673 10 16.5195C10 17.0719 9.55232 17.5195 8.99996 17.5195C8.44768 17.5195 8 17.0719 8 16.5195C8 15.9673 8.44768 15.5195 8.99996 15.5195Z' fill='currentColor' />
    <path d='M2.26306 8.22917C2.62955 7.34446 3.16672 6.5406 3.84389 5.8635C3.93876 5.76527 3.99126 5.6337 3.99008 5.49714C3.98889 5.36058 3.93411 5.22995 3.83755 5.13338C3.74098 5.03682 3.61035 4.98204 3.47379 4.98085C3.33723 4.97967 3.20566 5.03216 3.10743 5.12704C2.33357 5.90086 1.7197 6.81954 1.30088 7.83062C0.882066 8.84169 0.666504 9.92536 0.666504 11.0197C0.666504 12.1141 0.882066 13.1978 1.30088 14.2089C1.7197 15.2199 2.33357 16.1386 3.10743 16.9125C3.20566 17.0073 3.33723 17.0598 3.47379 17.0586C3.61035 17.0575 3.74098 17.0027 3.83755 16.9061C3.93411 16.8095 3.98889 16.6789 3.99008 16.5424C3.99126 16.4058 3.93876 16.2742 3.84389 16.176C3.16672 15.4989 2.62955 14.695 2.26306 13.8103C1.89658 12.9256 1.70795 11.9774 1.70795 11.0197C1.70795 10.0621 1.89658 9.11389 2.26306 8.22917Z' fill='currentColor' />
    <path d='M4.83436 11.0197C4.83436 9.91489 5.27318 8.85528 6.05431 8.07391C6.15211 7.97611 6.20705 7.84347 6.20705 7.70516C6.20705 7.56685 6.15211 7.43421 6.05431 7.33641C5.95651 7.23861 5.82386 7.18367 5.68556 7.18367C5.54725 7.18367 5.41461 7.23861 5.31681 7.33641C4.83302 7.82007 4.44926 8.39429 4.18743 9.02629C3.9256 9.65828 3.79084 10.3357 3.79084 11.0197C3.79084 11.7038 3.9256 12.3812 4.18743 13.0132C4.44926 13.6452 4.83302 14.2194 5.31681 14.7031C5.36523 14.7515 5.42272 14.7899 5.48599 14.8161C5.54926 14.8423 5.61707 14.8558 5.68556 14.8558C5.75404 14.8558 5.82185 14.8423 5.88512 14.8161C5.94839 14.7899 6.00588 14.7515 6.05431 14.7031C6.10273 14.6547 6.14114 14.5972 6.16735 14.5339C6.19356 14.4706 6.20705 14.4028 6.20705 14.3343C6.20705 14.2658 6.19356 14.198 6.16735 14.1348C6.14114 14.0715 6.10273 14.014 6.05431 13.9656C5.27318 13.1842 4.83436 12.1246 4.83436 11.0197Z' fill='currentColor' />
    <path d='M12.1151 7.22317C12.0518 7.24943 11.9944 7.28791 11.946 7.33641C11.8975 7.38479 11.859 7.44227 11.8327 7.50554C11.8065 7.56882 11.793 7.63665 11.793 7.70516C11.793 7.77367 11.8065 7.8415 11.8327 7.90478C11.859 7.96806 11.8975 8.02553 11.946 8.07391C12.7271 8.85528 13.1659 9.91489 13.1659 11.0197C13.1659 12.1246 12.7271 13.1842 11.946 13.9656C11.8975 14.014 11.8591 14.0715 11.8329 14.1348C11.8067 14.198 11.7932 14.2658 11.7932 14.3343C11.7932 14.4028 11.8067 14.4706 11.8329 14.5339C11.8591 14.5972 11.8975 14.6547 11.946 14.7031C11.9944 14.7515 12.0519 14.7899 12.1152 14.8161C12.1784 14.8423 12.2462 14.8558 12.3147 14.8558C12.3832 14.8558 12.451 14.8423 12.5143 14.8161C12.5776 14.7899 12.635 14.7515 12.6835 14.7031C13.1673 14.2194 13.551 13.6452 13.8129 13.0132C14.0747 12.3812 14.2094 11.7038 14.2094 11.0197C14.2094 10.3357 14.0747 9.65828 13.8129 9.02629C13.551 8.39429 13.1673 7.82007 12.6835 7.33641C12.6351 7.28791 12.5776 7.24943 12.5143 7.22317C12.4511 7.19691 12.3832 7.1834 12.3147 7.1834C12.2462 7.1834 12.1784 7.19691 12.1151 7.22317Z' fill='currentColor' />
    <path d='M14.5246 4.97559C14.3865 4.97559 14.2541 5.03044 14.1564 5.12808V5.12704C14.0587 5.22471 14.0039 5.35716 14.0039 5.49527C14.0039 5.63337 14.0587 5.76582 14.1564 5.8635C14.8336 6.5406 15.3707 7.34446 15.7372 8.22917C16.1037 9.11389 16.2923 10.0621 16.2923 11.0197C16.2923 11.9774 16.1037 12.9256 15.7372 13.8103C15.3707 14.695 14.8336 15.4989 14.1564 16.176C14.0615 16.2742 14.009 16.4058 14.0102 16.5424C14.0114 16.6789 14.0662 16.8095 14.1627 16.9061C14.2593 17.0027 14.3899 17.0575 14.5265 17.0586C14.6631 17.0598 14.7946 17.0073 14.8928 16.9125C16.4554 15.3497 17.3333 13.2302 17.3333 11.0203C17.3333 8.81031 16.4554 6.69085 14.8928 5.12808C14.7952 5.03044 14.6627 4.97559 14.5246 4.97559Z' fill='currentColor' />
    <path d='M2.26306 8.22917C2.62955 7.34446 3.16672 6.5406 3.84389 5.8635C3.93876 5.76527 3.99126 5.6337 3.99008 5.49714C3.98889 5.36058 3.93411 5.22995 3.83755 5.13338C3.74098 5.03682 3.61035 4.98204 3.47379 4.98085C3.33723 4.97967 3.20566 5.03216 3.10743 5.12704C2.33357 5.90086 1.7197 6.81954 1.30088 7.83062C0.882066 8.84169 0.666504 9.92536 0.666504 11.0197C0.666504 12.1141 0.882066 13.1978 1.30088 14.2089C1.7197 15.2199 2.33357 16.1386 3.10743 16.9125C3.20566 17.0073 3.33723 17.0598 3.47379 17.0586C3.61035 17.0575 3.74098 17.0027 3.83755 16.9061C3.93411 16.8095 3.98889 16.6789 3.99008 16.5424C3.99126 16.4058 3.93876 16.2742 3.84389 16.176C3.16672 15.4989 2.62955 14.695 2.26306 13.8103C1.89658 12.9256 1.70795 11.9774 1.70795 11.0197C1.70795 10.0621 1.89658 9.11389 2.26306 8.22917Z' stroke='currentColor' strokeWidth='0.5' />
    <path d='M4.83436 11.0197C4.83436 9.91489 5.27318 8.85528 6.05431 8.07391C6.15211 7.97611 6.20705 7.84347 6.20705 7.70516C6.20705 7.56685 6.15211 7.43421 6.05431 7.33641C5.95651 7.23861 5.82386 7.18367 5.68556 7.18367C5.54725 7.18367 5.41461 7.23861 5.31681 7.33641C4.83302 7.82007 4.44926 8.39429 4.18743 9.02629C3.9256 9.65828 3.79084 10.3357 3.79084 11.0197C3.79084 11.7038 3.9256 12.3812 4.18743 13.0132C4.44926 13.6452 4.83302 14.2194 5.31681 14.7031C5.36523 14.7515 5.42272 14.7899 5.48599 14.8161C5.54926 14.8423 5.61707 14.8558 5.68556 14.8558C5.75404 14.8558 5.82185 14.8423 5.88512 14.8161C5.94839 14.7899 6.00588 14.7515 6.05431 14.7031C6.10273 14.6547 6.14114 14.5972 6.16735 14.5339C6.19356 14.4706 6.20705 14.4028 6.20705 14.3343C6.20705 14.2658 6.19356 14.198 6.16735 14.1348C6.14114 14.0715 6.10273 14.014 6.05431 13.9656C5.27318 13.1842 4.83436 12.1246 4.83436 11.0197Z' stroke='currentColor' strokeWidth='0.5' />
    <path d='M12.1151 7.22317C12.0518 7.24943 11.9944 7.28791 11.946 7.33641C11.8975 7.38479 11.859 7.44227 11.8327 7.50554C11.8065 7.56882 11.793 7.63665 11.793 7.70516C11.793 7.77367 11.8065 7.8415 11.8327 7.90478C11.859 7.96806 11.8975 8.02553 11.946 8.07391C12.7271 8.85528 13.1659 9.91489 13.1659 11.0197C13.1659 12.1246 12.7271 13.1842 11.946 13.9656C11.8975 14.014 11.8591 14.0715 11.8329 14.1348C11.8067 14.198 11.7932 14.2658 11.7932 14.3343C11.7932 14.4028 11.8067 14.4706 11.8329 14.5339C11.8591 14.5972 11.8975 14.6547 11.946 14.7031C11.9944 14.7515 12.0519 14.7899 12.1152 14.8161C12.1784 14.8423 12.2462 14.8558 12.3147 14.8558C12.3832 14.8558 12.451 14.8423 12.5143 14.8161C12.5776 14.7899 12.635 14.7515 12.6835 14.7031C13.1673 14.2194 13.551 13.6452 13.8129 13.0132C14.0747 12.3812 14.2094 11.7038 14.2094 11.0197C14.2094 10.3357 14.0747 9.65828 13.8129 9.02629C13.551 8.39429 13.1673 7.82007 12.6835 7.33641C12.6351 7.28791 12.5776 7.24943 12.5143 7.22317C12.4511 7.19691 12.3832 7.1834 12.3147 7.1834C12.2462 7.1834 12.1784 7.19691 12.1151 7.22317Z' stroke='currentColor' strokeWidth='0.5' />
    <path d='M14.5246 4.97559C14.3865 4.97559 14.2541 5.03044 14.1564 5.12808V5.12704C14.0587 5.22471 14.0039 5.35716 14.0039 5.49527C14.0039 5.63337 14.0587 5.76582 14.1564 5.8635C14.8336 6.5406 15.3707 7.34446 15.7372 8.22917C16.1037 9.11389 16.2923 10.0621 16.2923 11.0197C16.2923 11.9774 16.1037 12.9256 15.7372 13.8103C15.3707 14.695 14.8336 15.4989 14.1564 16.176C14.0615 16.2742 14.009 16.4058 14.0102 16.5424C14.0114 16.6789 14.0662 16.8095 14.1627 16.9061C14.2593 17.0027 14.3899 17.0575 14.5265 17.0586C14.6631 17.0598 14.7946 17.0073 14.8928 16.9125C16.4554 15.3497 17.3333 13.2302 17.3333 11.0203C17.3333 8.81031 16.4554 6.69085 14.8928 5.12808C14.7952 5.03044 14.6627 4.97559 14.5246 4.97559Z' stroke='currentColor' strokeWidth='0.5' />
  </svg>

);

const EWSIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={ewsSvg}
    {...props}
    aria-label='ews'
  />
);

export default EWSIcon;
