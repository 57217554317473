import React from 'react';
import { observer } from 'mobx-react';
import { Skeleton } from 'antd';
import { getCellClassName, getIconPosition } from '../../lib/componentHelpers/dashboardHelpers';
import { useStore } from '../../stores/Store';
import { DashboardColumns } from '../../models/constants/components';

const DashboardSkeletonCell = ({ colKey }: {colKey: string}) => {
  const { dashboardStore } = useStore();
  const colType = DashboardColumns[colKey]?.type;
  const groupPositions = dashboardStore.columnPosition(colKey);
  // Render
  return (
    <div className={`dashboard-skeleton ${!colKey ? 'no-cols' : ''} ${getCellClassName(groupPositions, null, colType, colKey, dashboardStore)}`}>
      <div className='dashboardCell__data'>
        <Skeleton.Input className='dashboardCell__value' active size='small' />
        <Skeleton.Input className='dashboardCell__subtext' active size='small' />
      </div>
      {colKey && (
        <div className={`dashboardCell__icon dashboardCell__icon--${getIconPosition(colType)}`}>
          <Skeleton.Button active size='small' shape='circle' />
        </div>
      )}
    </div>
  );
};

export default observer(DashboardSkeletonCell);
