import React from 'react';
import '../App.scss';
import { observer } from 'mobx-react';

import { renderVisibleModals } from '../entities/modals/Modals';
import { useStore } from '../stores/Store';
import HeaderRow from './HeaderRow';
import TabManager from './TabManager';

const AdvisoryPortal = () => {
  const rootStore = useStore();
  const { actionStore, businessesStore, localeStore, pageStore } = rootStore;
  const [actionType, setActionType] = React.useState(null);
  const [actionModalVisible, setActionModalVisible] = React.useState(false);

  localeStore.setLocale(businessesStore.selectedBusiness?.countryCode);

  const renderActionCentreModal = (type) => {
    setActionType(type);
    setActionModalVisible(true);
  };

  const toggleModal = (modalType) => {
    pageStore.setToggledModal(modalType);
  };

  const closeActionModal = () => {
    setActionModalVisible(false);
  };

  const renderActionModal = (renderModal) => {
    setActionModalVisible(renderModal);
  };

  return !rootStore.loadingStore.isAnyLoading && (
    <>
      {renderVisibleModals(
        actionType,
        actionModalVisible,
        closeActionModal,
        renderActionModal,
      )}
      <HeaderRow />
      <TabManager
        renderActionCentreModal={renderActionCentreModal}
        actionCenterItems={actionStore.actionItems}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default observer(AdvisoryPortal);
