import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { TeamOutlined } from '@ant-design/icons';
import StandardModal from './wrappers/StandardModal';
import { ModalWidths } from '../../models/enums/modals';
import AdvisorContactsContent from './content/AdvisorContactsContent';

const AdvisorContactsModal = (rootStore) => {
  const ModalProps = {
    className: 'client-advisor-contacts-modal',
    name: 'client-advisor-contacts-modal',
    title: <><TeamOutlined /> Contacts for {rootStore.businessesStore.selectedBusiness.name}</>,
    width: ModalWidths.XXLARGE,
    content: <AdvisorContactsContent />,
    footer: null,
  };

  NiceModal.register('AdvisorContactsModal', StandardModal);
  return (() => {
    rootStore.businessesStore.getUsersForBusinesses();
    return NiceModal.show('AdvisorContactsModal', ModalProps);
  })();
};

export default AdvisorContactsModal;
