import React from 'react';
import AdvisoryPortal from '../components-v1/AdvisoryPortal';
import TierPriceConfirmPage from '../components-v2/organisms/TierPriceConfimPage';
import ContactThankYouPage from '../components-v2/organisms/ContactThankYouPage';
import Profile from '../pages/accountSettings/Profile';
import Branding from '../pages/accountSettings/Branding';
import Advisors from '../pages/accountSettings/Advisors';
import Permissions from '../pages/accountSettings/Permissions';
import PracticeChecklists from '../pages/accountSettings/PracticeChecklists';
import MainLayout from '../layouts/MainLayout';
import Firm from '../pages/accountSettings/Firm';
import ProtectedRoutes from './ProtectedRoute';
import PageNotFound from '../pages/PageNotFound';
import { Routers } from '../models/enums/utils';
import ErrorBoundary from '../pages/ErrorBoundary';
import Dashboard from '../pages/Dashboard';
import OnboardingFirmDetails from '../pages/OnboardingFirmDetails';
import NoNavLayout from '../layouts/NoNavLayout';
import Invitation from '../pages/Invitation';
import OpenRoute from './OpenRoute';
import InvitationAcceptance from '../pages/InvitationAcceptance';
import AssistantSettings from '../pages/accountSettings/AssistantSettings';
import LoadingZone from '../pages/LoadingZone';
import ActivateClients from '../pages/ActivateClients';
import PeriodCloseDashboard from '../pages/PeriodCloseDashboard';
import LogIn from '../pages/LogIn';
import SignUp from '../pages/SignUp';
import AdvisoryAssistant from '../pages/AdvisoryAssistant';
import AppReferral from '../pages/AppReferral';
import AppDisconnect from '../pages/AppDisconnect';

const generateRouterConfig = () => [
  {
    path: Routers.DASHBOARD,
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: Routers.PERIOD_CLOSE_DASHBOARD,
            element: <PeriodCloseDashboard />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.INSIGHTS,
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <AdvisoryPortal />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.CLIENTS_MANAGEMENT,
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <LoadingZone />,
          },
          {
            path: Routers.ACTIVATE_CLIENTS,
            element: <ActivateClients />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.ADVISORY_ASSISTANT,
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <AdvisoryAssistant />,
          }
        ],
      },
    ],
  },
  {
    path: Routers.ONBOARDING,
    element: <NoNavLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <OnboardingFirmDetails />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.INVITE_ACCEPT,
    element: <NoNavLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <InvitationAcceptance />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.INVITATION,
    errorElement: <ErrorBoundary />,
    element: <NoNavLayout />,
    children: [
      {
        element: <OpenRoute />,
        children: [
          {
            index: true,
            element: <Invitation />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.LOGIN,
    errorElement: <ErrorBoundary />,
    element: <NoNavLayout />,
    children: [
      {
        element: <OpenRoute />,
        children: [
          {
            index: true,
            element: <LogIn />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.SIGNUP,
    errorElement: <ErrorBoundary />,
    element: <NoNavLayout />,
    children: [
      {
        element: <OpenRoute />,
        children: [
          {
            index: true,
            element: <SignUp />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.CONTACT,
    element: <TierPriceConfirmPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routers.CONTACT_THANKS,
    element: <ContactThankYouPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routers.SETTINGS,
    element: <MainLayout hasSubLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <Profile />,
          },
          {
            path: Routers.FIRM,
            element: <Firm />,
          },
          {
            path: Routers.BRAND,
            element: <Branding />,
          },
          {
            path: Routers.ADVISORS,
            element: <Advisors />,
          },
          {
            path: Routers.PERMISSIONS,
            element: <Permissions />,
          },
          {
            path: Routers.CHECKLIST_TEMPLATES,
            element: <PracticeChecklists />,
          },
          {
            path: Routers.ASSISTANT_SETTINGS,
            element: <AssistantSettings />,
          },
        ],
      },
    ],
  },
  {
    path: Routers.APP_REFERRAL,
    element: <NoNavLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <OpenRoute />,
        children: [
          {
            index: true,
            element: <AppReferral type='login' />,
          },
          {
            path: Routers.DISCONNECT_REFERRAL,
            element: <AppReferral type='disconnect' />
          },
          {
            path: Routers.CONNECT_REFERRAL,
            element: <AppReferral type='connect' />,
          }
        ],
      }
    ],
  },
  {
    path: Routers.CLIENT_DISCONNECT,
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <AppDisconnect />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
    errorElement: <ErrorBoundary />,
  },
];

export default generateRouterConfig;
