import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import DashboardSkeletonCell from './DashboardSkeletonCell';
import DashboardBusinessNameCell from './DashboardBusinessNameCell';

const DashboardPendingRow = ({ id } : { id: string }) => {
  const { dashboardStore } = useStore();
  const columnOrder = dashboardStore.determineColumnOrder;
  const business = dashboardStore.businesses.get(id);

  return (
    <div className={`dashboard-table__body--row ${Math.abs(business?.unreconciled?.current?.rawValue) >= 10 ? 'dashboard-table__body--row--unreconciled' : ''}`}>
      <DashboardBusinessNameCell
        key={`dashboard-data-cell-${id}-client`}
        businessId={id}
      />
      {columnOrder?.length === 0 ? (
        <DashboardSkeletonCell
          key={`dashboard-cell__skeleton__${id}__empty`}
          colKey={null}
        />
      ) : (
      columnOrder.map((column) => (
        <DashboardSkeletonCell
          key={`dashboard-cell__skeleton__${id}__${column}`}
          colKey={column}
        />
      )))}
    </div>
  );
};

export default observer(DashboardPendingRow);
