import { LineChartOutlined, TableOutlined } from '@ant-design/icons';
import React from 'react';

const ReportEditorSettingsTitle = ({
  type
}: {
  type: 'chart' | 'table'
}) => {
  let icon = null;
  let title = '';

  switch (type) {
    case 'table':
      icon = <TableOutlined />;
      title = 'Table Settings';
      break;
    case 'chart':
    default:
      icon = <LineChartOutlined />;
      title = 'Graph Settings';
  }

  return (
    <>
      {icon} {title}
    </>
  );
};

export default ReportEditorSettingsTitle;
