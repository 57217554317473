import { LevelFormat, convertInchesToTwip } from 'docx';

export const DOCX_CONFIG = {
  numbering: {
    config: [
      {
        reference: 'block-numbering',
        levels: [
          {
            level: 0,
            format: LevelFormat.DECIMAL,
            text: '%1.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 1,
            format: LevelFormat.LOWER_LETTER,
            text: '%2.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 2,
            format: LevelFormat.LOWER_ROMAN,
            text: '%3.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 3,
            format: LevelFormat.UPPER_LETTER,
            text: '%4.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(2), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 4,
            format: LevelFormat.UPPER_ROMAN,
            text: '%5.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(2.5), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
        ]
      },
    ],
  }
};
