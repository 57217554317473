import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const secureShieldSVG = () => (
  <svg width='1em' viewBox='0 0 52 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M51.0312 32.2232C51.0312 50.7485 26.3281 63.0987 26.3281 63.0987C26.3281 63.0987 1.625 50.7485 1.625 32.2232V10.6103L26.3281 1.34766L51.0312 10.6103V32.2232Z' fill='#DEE3F6' />
    <path fillRule='evenodd' clipRule='evenodd' d='M25.602 0.163267C25.8284 0.07839 26.0779 0.07839 26.3042 0.163267L38.6558 4.79459C39.1729 4.98849 39.4349 5.5649 39.241 6.08202C39.0471 6.59915 38.4707 6.86118 37.9536 6.66728L25.9531 2.1676L2.25 11.0553V31.9751C2.25 40.7913 8.14398 48.2563 14.26 53.6072C17.297 56.2643 20.3388 58.3558 22.6232 59.7834C23.7644 60.4966 24.714 61.0424 25.3759 61.4088C25.6038 61.535 25.7975 61.6398 25.9531 61.7228C26.1087 61.6398 26.3024 61.535 26.5303 61.4088C27.1923 61.0424 28.1418 60.4966 29.283 59.7834C31.5675 58.3558 34.6092 56.2643 37.6462 53.6072C43.7623 48.2563 49.6562 40.7913 49.6562 31.9751V11.0495L44.7526 9.16687C44.237 8.96892 43.9795 8.39048 44.1775 7.87489C44.3755 7.3593 44.9539 7.1018 45.4695 7.29975L51.0147 9.4287C51.4012 9.57708 51.6562 9.94827 51.6562 10.3623V31.9751C51.6562 41.6842 45.1987 49.657 38.9632 55.1125C35.8244 57.8586 32.6903 60.0125 30.3429 61.4795C29.1682 62.2136 28.1878 62.7773 27.4989 63.1587C27.1543 63.3494 26.8824 63.4946 26.6953 63.5928C26.6018 63.6419 26.5294 63.6793 26.4798 63.7047L26.4224 63.7339L26.4069 63.7417L26.4025 63.744L26.4011 63.7447C26.4007 63.7449 26.4003 63.7451 25.9531 62.8506C25.506 63.7451 25.5056 63.7449 25.5051 63.7447L25.5038 63.744L25.4993 63.7417L25.4838 63.7339L25.4265 63.7047C25.3768 63.6793 25.3045 63.6419 25.2109 63.5928C25.0238 63.4946 24.7519 63.3494 24.4074 63.1587C23.7184 62.7773 22.738 62.2136 21.5633 61.4795C19.2159 60.0125 16.0819 57.8586 12.9431 55.1125C6.70758 49.657 0.25 41.6842 0.25 31.9751V10.3623C0.25 9.9454 0.508589 9.57227 0.898911 9.42592L25.602 0.163267ZM25.9531 62.8506L25.506 63.7451C25.7875 63.8858 26.1188 63.8858 26.4003 63.7451L25.9531 62.8506Z' fill='#4263EA' />
    <path d='M43.5247 7.35558C43.5247 7.61478 43.4143 7.87351 43.2346 8.06269C43.0447 8.24214 42.7844 8.35156 42.5247 8.35156C42.2644 8.35156 42.0046 8.24214 41.8147 8.06269C41.4446 7.69454 41.4446 7.0171 41.8147 6.64847C42.0046 6.4695 42.2644 6.35959 42.5247 6.35959C42.7844 6.35959 43.0447 6.4695 43.2346 6.64847C43.4143 6.83765 43.5247 7.09685 43.5247 7.35558Z' fill='#4263EA' />
    <path d='M26.901 59.0969C26.7631 59.3168 26.5319 59.4779 26.2788 59.5435C26.0223 59.5955 25.7433 59.5509 25.523 59.4138C25.3023 59.2764 25.1403 59.0465 25.0747 58.7941C24.9621 58.2779 25.3117 57.7207 25.8271 57.5948C26.0834 57.5433 26.3622 57.5872 26.5829 57.7245C26.8032 57.8616 26.9654 58.0922 27.0313 58.3442C27.083 58.5995 27.0387 58.8775 26.901 59.0969Z' fill='#4263EA' />
    <path d='M23.4889 57.3059C23.351 57.5257 23.1197 57.6869 22.8667 57.7525C22.6101 57.8045 22.3312 57.7599 22.1109 57.6228C21.8902 57.4854 21.7281 57.2555 21.6625 57.0031C21.55 56.4869 21.905 55.921 22.415 55.8038C22.6713 55.7522 22.9501 55.7961 23.1708 55.9335C23.3911 56.0706 23.5533 56.3012 23.6191 56.5532C23.6709 56.8085 23.6266 57.0865 23.4889 57.3059Z' fill='#4263EA' />
    <path d='M20.1158 54.8743C19.9779 55.0941 19.7467 55.2553 19.4937 55.3209C19.2371 55.3728 18.9582 55.3282 18.7379 55.1911C18.5172 55.0538 18.3551 54.8239 18.2895 54.5715C18.1769 54.0552 18.5265 53.4981 19.0419 53.3721C19.2982 53.3206 19.577 53.3645 19.7977 53.5019C20.018 53.639 20.1802 53.8695 20.2461 54.1215C20.2978 54.3768 20.2535 54.6549 20.1158 54.8743Z' fill='#4263EA' />
    <path d='M16.8405 52.136C16.7025 52.3558 16.4713 52.517 16.2183 52.5826C15.9617 52.6345 15.6828 52.59 15.4625 52.4529C15.2418 52.3155 15.0797 52.0856 15.0141 51.8332C14.9015 51.317 15.2511 50.7598 15.7666 50.6338C16.0228 50.5823 16.3016 50.6262 16.5223 50.7636C16.7426 50.9007 16.9049 51.1312 16.9707 51.3833C17.0224 51.6385 16.9781 51.9166 16.8405 52.136Z' fill='#4263EA' />
    <path d='M30.2565 57.3059C30.1186 57.5257 29.8873 57.6869 29.6343 57.7525C29.3777 57.8045 29.0988 57.7599 28.8785 57.6228C28.6578 57.4854 28.4957 57.2555 28.4301 57.0031C28.3175 56.4869 28.6671 55.9297 29.1826 55.8038C29.4389 55.7522 29.7176 55.7961 29.9383 55.9335C30.1586 56.0706 30.3209 56.3012 30.3867 56.5532C30.4385 56.8085 30.3941 57.0865 30.2565 57.3059Z' fill='#4263EA' />
    <path d='M26.8639 54.8743C26.726 55.0941 26.4947 55.2553 26.2417 55.3209C25.9851 55.3728 25.7062 55.3282 25.4859 55.1911C25.2652 55.0538 25.1031 54.8239 25.0375 54.5715C24.925 54.0552 25.28 53.4894 25.79 53.3721C26.0463 53.3206 26.3251 53.3645 26.5458 53.5019C26.7661 53.639 26.9283 53.8695 26.9941 54.1215C27.0459 54.3768 27.0016 54.6549 26.8639 54.8743Z' fill='#4263EA' />
    <g clipPath='url(#clip0_11944_268901)'>
      <path d='M35.474 25.7656H16.8073C15.3345 25.7656 14.1406 26.9595 14.1406 28.4323V37.7656C14.1406 39.2384 15.3345 40.4323 16.8073 40.4323H35.474C36.9467 40.4323 38.1406 39.2384 38.1406 37.7656V28.4323C38.1406 26.9595 36.9467 25.7656 35.474 25.7656Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M16.8073 26.7656C15.8868 26.7656 15.1406 27.5118 15.1406 28.4323V37.7656C15.1406 38.6861 15.8868 39.4323 16.8073 39.4323H35.474C36.3944 39.4323 37.1406 38.6861 37.1406 37.7656V28.4323C37.1406 27.5118 36.3944 26.7656 35.474 26.7656H16.8073ZM13.1406 28.4323C13.1406 26.4072 14.7822 24.7656 16.8073 24.7656H35.474C37.499 24.7656 39.1406 26.4072 39.1406 28.4323V37.7656C39.1406 39.7907 37.499 41.4323 35.474 41.4323H16.8073C14.7822 41.4323 13.1406 39.7907 13.1406 37.7656V28.4323Z' fill='#4263EA' />
      <path d='M19.4746 20.4323V25.7656H32.8079V20.4323C32.8079 18.6642 32.1056 16.9685 30.8553 15.7182C29.6051 14.468 27.9094 13.7656 26.1413 13.7656C24.3732 13.7656 22.6775 14.468 21.4272 15.7182C20.177 16.9685 19.4746 18.6642 19.4746 20.4323Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M26.1413 14.7656C24.6384 14.7656 23.197 15.3626 22.1343 16.4254C21.0716 17.4881 20.4746 18.9294 20.4746 20.4323V24.7656H31.8079V20.4323C31.8079 18.9294 31.2109 17.4881 30.1482 16.4254C29.0855 15.3626 27.6442 14.7656 26.1413 14.7656ZM20.7201 15.0111C22.1579 13.5734 24.1079 12.7656 26.1413 12.7656C28.1746 12.7656 30.1247 13.5734 31.5624 15.0111C33.0002 16.4489 33.8079 18.399 33.8079 20.4323V25.7656C33.8079 26.3179 33.3602 26.7656 32.8079 26.7656H19.4746C18.9223 26.7656 18.4746 26.3179 18.4746 25.7656V20.4323C18.4746 18.399 19.2823 16.4489 20.7201 15.0111Z' fill='#4263EA' />
    </g>
    <defs>
      <clipPath id='clip0_11944_268901'>
        <rect width='26' height='30' fill='white' transform='translate(13.1406 12.0996)' />
      </clipPath>
    </defs>
  </svg>

);

/**
 * SecurityShield icon
 * Icon in the shape of a triangle, with an exclamation mark in the middle
 * @param {Partial<CustomIconComponentProps>} props
 */
const SecurityShield = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={secureShieldSVG}
    {...props}
    aria-label='logo'
  />
);

export default SecurityShield;
