import React from 'react';

import { Button, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { InfoCircleFilled, SendOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import UserIcon from './UserIcon';
import ButtonSecondary from '../customAntD/ButtonSecondary';

const UserChatInput = ({ userChat, waitingForAi }) => {
  const form = useFormInstance();
  const messageValue = Form.useWatch('message', form);
  return (
    <div
      className='assistant-user-input'
      id='assistant-user-input'
    >
      <div className='assistant-user-input__form'>
        <UserIcon />
        <Form.Item
          name='message'
          required
          className='assistant-user__form-item'
        >
          <TextArea
            className='assistant-user-text scrollbar'
            placeholder={
              waitingForAi
                ? 'Analysing your request... please wait.'
                : 'Enter your prompt/request/question here'
            }
            disabled={waitingForAi}
            autoSize={{ minRows: 5, maxRows: 10 }}
          />
        </Form.Item>
        <Button
          className='assistant-user-send'
          type='primary'
          icon={<SendOutlined />}
          shape='circle'
          onClick={() => userChat(form)}
          disabled={waitingForAi || !messageValue}
          loading={waitingForAi}
          style={{ alignSelf: 'flex-start' }}
        />
      </div>
      <ButtonSecondary
        className='assistant-user-input__link'
        type='link'
        icon={<InfoCircleFilled />}
        onClick={() => {
          window.open(
            'https://ask.aider.ai/help/advisory-assistant-prompts',
            '_blank'
          );
        }}
        mixpanelEvent='assistant-user__prompt-link'
      >
        Prompt examples and tips
      </ButtonSecondary>
    </div>
  );
};

export default UserChatInput;
