import { SubscriptionEnums } from '@aider/constants-library';
import ActivateClientBespokeModal from '../../components/modals/ActivateClientBespokeModal';
import ActivateClientUpgradeModal from '../../components/modals/ActivateClientUpgradeModal';
import Mixpanel from '../mixpanel';
import { TSubscription } from '../../models/interfaces/stores';

export const handleTierChange = (subscription: TSubscription, currencyCode:string, countryCode:string) => {
  const { previousClientCount, previousTier, newClientCount, newTier } = subscription;

  Mixpanel.people.set({
    numberOfClients: newClientCount,
    priceTier: newTier.name,
  });

  Mixpanel.track('Clients Activated', {
    previousClientCount,
    newClientCount,
    previousTier: previousTier.name,
    newTier: newTier.name,
  });

  if (previousTier.name === newTier.name) {
    return;
  }

  if (newTier.name === SubscriptionEnums.TierNames.customTier) {
    ActivateClientBespokeModal(subscription);
  } else if (newTier.name !== SubscriptionEnums.TierNames.perClient) {
    ActivateClientUpgradeModal(subscription, currencyCode, countryCode);
  }
};
