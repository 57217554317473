import React from 'react';
import { observer } from 'mobx-react';
import { AlertEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import DashboardBusinessNameCell from './DashboardBusinessNameCell';
import DashboardCell from './DashboardCell';
import { getAlertValue } from '../../lib/componentHelpers/dashboardHelpers';

const DashboardDataRow = ({ row }) => {
  const { dashboardStore } = useStore();
  const columns = dashboardStore.determineColumnOrder;
  const alert = dashboardStore?.businesses?.get(row?.id)?.data?.find((d) => d.key === 'unreconciled')?.alert;
  const alertValue = getAlertValue(typeof alert, alert);
  return (
    <div key={`dashboard-data-row-${row.id}`} className={`dashboard-table__body--row ${alertValue === AlertEnums.Type.ALERT_RED ? 'dashboard-table__body--row--unreconciled' : ''}`}>
      <DashboardBusinessNameCell
        key={`dashboard-data-cell-${row.id}-client`}
        businessId={row.id}
      />

      {columns.map((column) => (
        <DashboardCell
          key={`dashboard-data-cell-${row.id}-${column}`}
          businessId={row.id}
          column={column}
        />
      ))}

    </div>

  );
};

export default observer(DashboardDataRow);
