import React from 'react';
import { observer } from 'mobx-react';
import AiderTag from '../AiderTag';
import type DashboardStore from '../../stores/dashboardStore';
import type PeriodCloseDashboardStore from '../../stores/periodCloseDashboardStore';

const SelectedDashboardFilters = ({ targetStore } : { targetStore: DashboardStore | PeriodCloseDashboardStore }) => (
  <ul className='dashboard__filters--active'>
    {targetStore.activeIndustryFilter?.map((industry) => (
      <li key={industry}>
        <AiderTag onClick={() => targetStore.removeIndustryFilter(industry)}>
          {industry}
        </AiderTag>
      </li>
    ))}
    {targetStore.activeTagFilter?.map((tag) => (
      <li key={tag}>
        <AiderTag onClick={() => targetStore.removeTagFilter(tag)}>
          {tag}
        </AiderTag>
      </li>
    ))}
  </ul>
);

export default observer(SelectedDashboardFilters);
