import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { State, Text } from '../../../ts/enums/Colors';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';

/**
 * Icon component for the CheckAlert icon
 * @param props
 * @returns
 */
export const CheckAlert = ({
  width = 18,
  height = 18,
  color = Text.white,
  strokeWidth = 1,
  fill = State.success,
  lineCap = 'round',
  padding = 0.33,
  ...rest
}: IconInterface) => {
  const { min, max } = getPlotExtremes({ width, height, padding });
  const scale = 6;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      stroke={color}
      strokeWidth={strokeWidth}
      viewBox={`0 0 ${width} ${height}`}
      strokeLinecap={lineCap}
      fill='none'
      {...rest}
    >
      <circle cx={width / 2} cy={height / 2} r={width / 2} fill={fill} />
      <polyline
        stroke={color}
        points={`${min.x},${height / 2} ${
          width / 2 - (max.y - min.y) / scale
        },${max.y - (max.y - min.y) / scale} ${max.x},${
          min.y + (max.y - min.y) / scale
        }`}
      />
    </svg>
  );
};
