import React from 'react';
import { observer } from 'mobx-react';
import { Grid, Tooltip } from 'antd';
import ButtonPrimary from './customAntD/ButtonPrimary';
import AdvisoryAssistantModal from './modals/AdvisoryAssistantModal';
import { useStore } from '../stores/Store';
import GenAIWhite from './icons/GenAIWhite';

const OpenAssistant = () => {
  const rootStore = useStore();
  const breakpoint = Grid.useBreakpoint();
  return (
    <Tooltip
      title='Open Advisory Assistant'
      placement='bottom'
    >
      <ButtonPrimary
        size='large'
        disabled={rootStore.dashboardStore?.rows?.length === 0}
        onClick={() => {
          AdvisoryAssistantModal();
        }}
        icon={<GenAIWhite className='icon__white' />}
        mixpanelEvent='Open Dashboard Assistant'
        mixpanelProperties={{
          source: 'Dashboard',
          practiceId: rootStore.practiceStore.id,
        }}
        shape={breakpoint.lg ? 'round' : 'circle'}
      >
        {breakpoint.lg
          && <>Dashboard Assistant</>}
      </ButtonPrimary>
    </Tooltip>
  );
};

export default observer(OpenAssistant);
