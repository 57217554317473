import { Form, Select } from 'antd';
import React, { ReactNode, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import { IAddressInputProps } from '../../models/interfaces/formElements';
import { useDebouncedEffect } from '../../lib/customHooks';
import { addressAutocomplete } from '../../lib/googleUtils';

const AddressInput = ({ loading, className, locationDataCallback, ...rest }: IAddressInputProps) => {
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [address, setAddress] = useState('');
  const { errors } = Form.Item.useStatus();
  const rootStore = useStore();

  const handleAddressLookup = async () => {
    const res = await addressAutocomplete({
      address,
      rootStore,
      callback: setData,
    });
    setRawData(res);
  };

  const dropdownRender = (originNode: ReactNode) => (
    <div className='address-input-dropdown' data-manual-label='Manual Entry'>
      {originNode}
    </div>
  );

  useDebouncedEffect(() => {
    handleAddressLookup();
  }, [address], 250);

  const handleSelect = (value: string) => {
    const selectedAddress = rawData.find((d) => d.description === value);
    if (locationDataCallback) {
      locationDataCallback(selectedAddress.place_id);
    }
  };

  return (
    <Select
      className={`address-input ${className}`}
      disabled={loading}
      showSearch
      placeholder='Enter your address'
      defaultActiveFirstOption
      filterOption={false}
      onSearch={setAddress}
      notFoundContent={null}
      onSelect={handleSelect}
      options={(data).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
      dropdownRender={dropdownRender}
      suffixIcon={errors.length > 0 ? <ExclamationCircleFilled /> : null}
      {...rest}
    />
  );
};

export default AddressInput;
