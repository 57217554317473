import React from 'react';
import { observer } from 'mobx-react';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import { PeriodCloseTypes } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import { processMathInstructions } from '../../lib/componentHelpers/periodCloseHelpers';

const MathCell = ({
  cell,
  table
} : {
  cell: Extract<PeriodCloseTypes.ChecklistCell, { cellType: 'math' }>,
  table: PeriodCloseTypes.ChecklistTable
}) => {
  const { businessesStore } = useStore();

  const format = new Format(businessesStore.selectedBusiness?.currencyCode, businessesStore.selectedBusiness?.countryCode);
  format.precision = 2;
  let cellData = cell.data;

  if (cell.mathInstructions) {
    cellData = format.formatValue({
      format: ValueTypes.currency,
      value: processMathInstructions(cell, table),
    });
  }

  return cellData && <>{cellData}</>;
};

export default observer(MathCell);
