import React from 'react';
import { observer } from 'mobx-react';
import { ClientManagementEnums } from '@aider/constants-library';
import { useStore } from '../../../stores/Store';

const AddClientSuccessCommon = () => {
  const { connectionsStore } = useStore();
  return (
    <>
      <h2>
        {connectionsStore.newConnectionCount} {ClientManagementEnums.OSPNames[connectionsStore.connectionType]} client
        {connectionsStore.newConnectionCount > 1 ? 's' : ''} imported
      </h2>
    </>
  );
};
export default observer(AddClientSuccessCommon);
