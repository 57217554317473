import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const iconSvg = () => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 32 32'
    fill='currentColor'
  >
    <path d='M12.2177 5.98568H30.4677C30.6052 5.98568 30.7177 6.09818 30.7177 6.23568V7.98568C30.7177 8.12318 30.6052 8.23568 30.4677 8.23568H12.2177C12.0802 8.23568 11.9677 8.12318 11.9677 7.98568V6.23568C11.9677 6.09818 12.0802 5.98568 12.2177 5.98568Z' />
    <path d='M7.98367 4.1582H9.39558C9.55034 4.1582 9.64081 4.33439 9.55034 4.46058L5.60272 9.90582C5.56713 9.95453 5.52055 9.99415 5.46677 10.0215C5.41299 10.0488 5.35352 10.063 5.2932 10.063C5.23287 10.063 5.1734 10.0488 5.11962 10.0215C5.06584 9.99415 5.01926 9.95453 4.98367 9.90582L2.38367 6.31773C2.2932 6.19154 2.38367 6.01535 2.53843 6.01535H3.95034C3.98054 6.01509 4.01036 6.02209 4.0373 6.03577C4.06423 6.04944 4.08748 6.06938 4.1051 6.09392L5.2932 7.73201L7.82891 4.23677C7.86462 4.18677 7.92177 4.1582 7.98367 4.1582Z' />
    <path d='M12.2177 14.8683H30.4677C30.6052 14.8683 30.7177 14.9808 30.7177 15.1183V16.8683C30.7177 17.0058 30.6052 17.1183 30.4677 17.1183H12.2177C12.0802 17.1183 11.9677 17.0058 11.9677 16.8683V15.1183C11.9677 14.9808 12.0802 14.8683 12.2177 14.8683Z' />
    <path d='M7.98367 13.0408H9.39558C9.55034 13.0408 9.64081 13.217 9.55034 13.3432L5.60272 18.7884C5.56713 18.8371 5.52055 18.8767 5.46677 18.9041C5.41299 18.9314 5.35352 18.9456 5.2932 18.9456C5.23287 18.9456 5.1734 18.9314 5.11962 18.9041C5.06584 18.8767 5.01926 18.8371 4.98367 18.7884L2.38367 15.2003C2.2932 15.0741 2.38367 14.8979 2.53843 14.8979H3.95034C3.98054 14.8977 4.01036 14.9047 4.0373 14.9184C4.06423 14.932 4.08748 14.952 4.1051 14.9765L5.2932 16.6146L7.82891 13.1194C7.86462 13.0694 7.92177 13.0408 7.98367 13.0408Z' />
    <path d='M12.2177 23.7509H30.4677C30.6052 23.7509 30.7177 23.8634 30.7177 24.0009V25.7509C30.7177 25.8884 30.6052 26.0009 30.4677 26.0009H12.2177C12.0802 26.0009 11.9677 25.8884 11.9677 25.7509V24.0009C11.9677 23.8634 12.0802 23.7509 12.2177 23.7509Z' />
    <path d='M7.98367 21.9234H9.39558C9.55034 21.9234 9.64081 22.0996 9.55034 22.2258L5.60272 27.671C5.56713 27.7197 5.52055 27.7593 5.46677 27.7866C5.41299 27.814 5.35352 27.8282 5.2932 27.8282C5.23287 27.8282 5.1734 27.814 5.11962 27.7866C5.06584 27.7593 5.01926 27.7197 4.98367 27.671L2.38367 24.0829C2.2932 23.9567 2.38367 23.7805 2.53843 23.7805H3.95034C3.98054 23.7803 4.01036 23.7873 4.0373 23.8009C4.06423 23.8146 4.08748 23.8346 4.1051 23.8591L5.2932 25.4972L7.82891 22.002C7.86462 21.952 7.92177 21.9234 7.98367 21.9234Z' />
  </svg>

);

const NavIconPeriodCloseDashboard = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={iconSvg}
    {...props}
    aria-label='Compliance Dashboard'
  />
);

export default NavIconPeriodCloseDashboard;
