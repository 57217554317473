import { makeAutoObservable } from 'mobx';
import { firestore } from 'firebase';
import type { RootStore } from './Store';
import { TComment } from '../models/interfaces/stores';
import handleError from '../lib/errorHandler';

export default class CommentsStore {
  rootStore: RootStore;

  comments: Map<string, TComment[]>;

  selectedLocation: string = null;

  isLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });

    this.rootStore = rootStore;
    this.comments = new Map();
  }

  get selectedComments() {
    if (this.selectedLocation && this.comments.has(this.selectedLocation)) {
      return this.comments.get(this.selectedLocation);
    }
    return ([] as TComment[]);
  }

  getLocationIdentifier(featureType: string, periodStart: string, periodEnd: string, itemId: string, businessId?: string) {
    /**  comment identifier is structured in this way
    * <businessId>-<featureType>-<periodStart>-<periodEnd>-<itemId>
    * where featureType is something like gstPeriodChecklist
    * */
    const start = periodStart.split('T')[0];
    const end = periodEnd.split('T')[0];
    const businessIdString = businessId || this.rootStore.businessesStore.selectedBusinessId;
    return `${businessIdString}-${featureType}-${start}-${end}-${itemId}`;
  }

  getComments = (locationIdentifier: string) => this.comments.get(locationIdentifier) || [];

  async addComment(locationIdentifier: string, comment: TComment) {
    const currentComments = this.getComments(locationIdentifier);

    this.comments.set(locationIdentifier, [...currentComments, comment]);

    return firestore()
      .collection('/comments')
      .add(comment);
  }

  loadComments = (locationIdentifier: string) => {
    this.isLoading = true;
    const query = firestore()
      .collection('/comments')
      .where('locationIdentifier', '==', locationIdentifier);

    const unsubscribe = query.onSnapshot(
      (querySnapshot) => {
        const comments = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            comments.push(data as TComment);
          }
        });
        this.comments.set(locationIdentifier, comments);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = true;
        handleError({ error, transaction: 'Dashboard - Firestore Snapshot' });
      }
    );
    return unsubscribe;
  }
}
