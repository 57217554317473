import React from 'react';
import { v4 as uuid } from 'uuid';
import { ISubNavigationLink, ISubNavigationMain } from '../models/interfaces/layouts';
import ButtonPrimary from '../components/customAntD/ButtonPrimary';

/**
 * A navigation modal layout component
 * @category Layout
 *
 * This component is used to create a modal with a navigation bar,
 * and content for each navigation link.
 * The links are an array of **link** objects,
 * which contain the **label** to display in the nav link,
 * and either a **content** component to display in the modal
 * when the link is selected,
 * or a **link** object containing a URL to navigate to and its target,
 * and if the link is external.
 *
 * @param {string} layoutName  The Name of the modal, to be used in tracking
 * @param {INavigationLink} links An array of links to be used in the navigation modal.
 * @returns {React.ReactElement} A navigation modal component
 *
 * @example <NavigationModal layoutName='Example navigation modal' links={links} />
 */
const ModalNavigationLayout = ({ layoutName, links, initialIndex } : ISubNavigationMain) => {
  const [selectedTab, setSelectedTab] = React.useState(initialIndex);

  /**
   * Function to generate the className and onclick for each nav link
   * The className is set to active if the link is selected
   * The onClick function is set to change the selected tab as well as track the event
   * The onClick function only changes the selected tab if the link does not have a URL
   * as if there is a URL this will force a page navigation event.
   * @param {number} navIndex The index of the nav link
   * @param {INavigationLink} link The link object
   * @returns {object} An object containing the className and onClick function
   * for the nav link which can be spread as props.
   */
  const navHandler = (navIndex: number, link: ISubNavigationLink) => {
    const className = `nav-link ${selectedTab === navIndex ? 'active' : ''}`;
    const onClick = () => {
      if (!link.url) {
        setSelectedTab(navIndex);
      }
    };
    return { className, onClick };
  };

  return (
    <div className='aider-modal navigation-modal'>
      <nav>
        {links.map((link, index) => (link?.url
          ? (
            // If there is a link object, render a button with an href
            <ButtonPrimary
              key={uuid()}
              type='link'
              href={link.url.path}
              target={link?.url?.target ? link.url.target : '_self'}
              mixpanelEvent={`${layoutName} - Tab Navigation`}
              mixpanelProperties={{ tab: link.label }}
              {...navHandler(index, link)}
            >
              {link.label}
            </ButtonPrimary>
          ) : (
            // If there is no link object, render a button with an onClick
            <ButtonPrimary
              key={uuid()}
              type='link'
              mixpanelEvent={`${layoutName} - Tab Navigation`}
              mixpanelProperties={{ tab: link.label }}
              {...navHandler(index, link)}
            >
              {link.label}
            </ButtonPrimary>
          )))}
      </nav>

      <section className='tab-content scrollbar'>
        {links?.[selectedTab]?.content}
      </section>
    </div>
  );
};

ModalNavigationLayout.defaultProps = {
  initialIndex: 0,
};

export default ModalNavigationLayout;
