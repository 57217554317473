import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { CustomRuleEnums, SplitEnums } from '@aider/constants-library';
import { ModalWidths } from '../../models/enums/modals';
import AsyncModal from './wrappers/AsyncModal';
import RuleModalContent from './content/RuleModalContent';
import RuleModalTitle from './content/RuleModalTitle';
import RuleModalError from './content/RuleModalError';
import type { RootStore } from '../../stores/Store';
import handleError from '../../lib/errorHandler';
import { Rule } from '../../models/interfaces/Rules';
import { parseSelectedAccounts } from '../../lib/componentHelpers/accountFormHelpers';

export interface IRuleModal {
  businessId: string;
  ruleId?: string;
  checklistId: string;
  sectionId: string;
  selectedPeriod?: string;
  form: any;
  rootStore: RootStore;
  allowKeywords?: boolean,
}

/**
 * The modal for the add client functionality
 */
const CreateRuleModal = ({
  businessId,
  ruleId,
  checklistId,
  sectionId,
  selectedPeriod,
  form,
  rootStore,
  allowKeywords,
}: IRuleModal) => {
  const newRule = !ruleId;
  let textBasedRules = [CustomRuleEnums.RuleShow.manualChecks];

  if (rootStore.splitStore.featureEnabled(SplitEnums.FeatureFlags.PromptChecklistItem)) {
    textBasedRules = [...textBasedRules, CustomRuleEnums.RuleShow.prompt];
  }

  const parseValue = (value?: string | number) => {
    if (typeof value === 'number') {
      return value;
    }
    return parseFloat(value.replace(/,/g, '')) || 0;
  };

  const handleSave = async () => {
    let formItems;

    // Validate form and return error without logging to sentry
    try {
      formItems = await form.validateFields();
      if (formItems.value && !(/^[\d ,]+(\.[\d]{0,2})?$/.test(formItems.value))) {
        throw Error('Cannot use non-numerical character in value field');
      }
    } catch (error) {
      return ({ status: 'error', error });
    }

    // Try save the rule log error to sentry
    try {
      const from = parseSelectedAccounts(formItems, false, rootStore.practiceStore.connectedOSPs);
      const storedRule = rootStore.rulesStore.rule(businessId, checklistId, sectionId, ruleId);
      // Transform values into rule object
      const ruleObject: Rule = {
        title: formItems.title,
        section: CustomRuleEnums.RuleCategories[sectionId],
        rule: {
          show: formItems.show,
          from: Array.isArray(from) ? from : [from],
          criteria: formItems.criteria,
          value: textBasedRules.includes(formItems.show)
            ? formItems.value : parseValue(formItems.value),
          instruction: formItems?.instruction || formItems?.prompt || null
        },
        order: 1, // PLACEHOLDER TILL FEATURE IS NEEDED
        type: storedRule?.type,
      };
      if (formItems.id) {
        ruleObject.id = formItems.id;
      }

      if (!formItems.criteria) {
        // Rule service needs the criteria present, Manual check doesn't have a criteria
        ruleObject.rule.criteria = formItems.show;
      }

      if (newRule) {
        /*
         * if businessRules exists,
         * push new rule to businessRules,
         * otherwise create new array with new rule
        */
        const res = await rootStore.rulesStore.createRule(businessId, checklistId, ruleObject);
        // Add the id to the rule after it has been created
        ruleObject.id = res.id;
        rootStore.rulesStore.storeRule(businessId, checklistId, ruleObject);
        // Create the rule in the checklist
        rootStore.rulesStore.handleCreateRule(ruleObject.id, checklistId, sectionId, selectedPeriod, businessId, ruleObject);
      } else {
        rootStore.rulesStore.updateRule(businessId, checklistId, sectionId, ruleObject);
        // Update the rule in the checklist
        rootStore.rulesStore.handleUpdateRule(ruleObject.id, checklistId, sectionId, selectedPeriod, businessId, ruleObject);
      }

      form.resetFields(); // EMPTY FORM
      return ruleObject; // Close Modal
    } catch (error) {
      handleError({ error, status: 'unknown_error', transaction: 'Save/Edit data rule', operation: 'handleSave' });
      return { status: 'error', error };
    }
  };

  const ModalProps = {
    name: `${newRule ? 'New' : 'Edit'} Rule Modal`,
    className: `rule-modal ${newRule ? 'rule-modal__new' : 'rule-modal__edit'}`,
    rootStore,
    width: ModalWidths.XXLARGE,
    okText: newRule ? 'Add' : 'Update',
    onOk: handleSave,
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      shape: 'round',
      size: 'large',
      className: 'button-secondary',
    },
    asyncOk: true,
    title: <RuleModalTitle sectionId={sectionId} newRule={newRule} />,
    errorMessage: <RuleModalError newRule={newRule} />,
    content: <RuleModalContent
      businessId={businessId}
      newRule={newRule}
      sectionId={sectionId}
      rule={rootStore.rulesStore.rule(businessId, checklistId, sectionId, ruleId)}
      form={form}
      allowKeywords={allowKeywords}
             />,
  };

  NiceModal.register('CreateRuleModal', AsyncModal);
  return NiceModal.show('CreateRuleModal', ModalProps);
};

export default CreateRuleModal;
