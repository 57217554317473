import React from 'react';
import { observer } from 'mobx-react';
import { Form, Tooltip } from 'antd';
import { DownloadOutlined, FileAddOutlined, SaveOutlined } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import Notification from '../Notification';
import SaveReportAsModal from '../modals/SaveReportAsModal';
import handleError from '../../lib/errorHandler';

const ReportTemplateFooter = () => {
  const rootStore = useStore();
  const { reportTemplateStore } = rootStore;
  const [saving, setSaving] = React.useState(false);
  const [savingAs, setSavingAs] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [saveAsForm] = Form.useForm();

  enum SaveButtonKeys {
    SAVE = 'save',
    CREATE = 'create',
  }

  const handleClick = (key: SaveButtonKeys) => {
    if (key === SaveButtonKeys.SAVE) {
      setSaving(true);
      reportTemplateStore.updatePerformanceReportTemplate(reportTemplateStore.editedPerformanceReportTemplate)
        .then(() => {
          Notification({ type: 'success', title: 'Template saved successfully' });
        })
        .catch(() => {
          Notification({ type: 'error', title: 'Failed to save template' });
        })
        .finally(() => {
          reportTemplateStore.performanceReportTemplates.set(reportTemplateStore.selectedPerformanceReportTemplate, reportTemplateStore.editedPerformanceReportTemplate);
          reportTemplateStore.editedPerformanceReportTemplate = null;
          setSaving(false);
        });
    }
    if (key === SaveButtonKeys.CREATE) {
      setSavingAs(true);
      if (!reportTemplateStore.isTemplateEdited) {
        reportTemplateStore.prepEditReportTemplate();
      }
      SaveReportAsModal(saveAsForm, reportTemplateStore.editedPerformanceReportTemplate.templateName,
        (templateName: string) => {
          reportTemplateStore.editedPerformanceReportTemplate.templateName = templateName;
          return reportTemplateStore.createPerformanceReportTemplate(reportTemplateStore.editedPerformanceReportTemplate)
            .then(() => {
              reportTemplateStore.editedPerformanceReportTemplate = null;
              Notification({ type: 'success', title: 'Template saved successfully' });
            })
            .catch((error) => {
              Notification({ type: 'error', title: 'Failed to save template' });
              handleError(error);
            })
            .finally(() => {
              setSavingAs(false);
            });
        });
    }
  };

  const handleDownloadButtonClick = () => {
    setDownloading(true);
    reportTemplateStore.generateReport().then(() => setDownloading(false));
  };

  return (
    <footer className='report-editor__footer'>
      <aside className='report-editor__footer__actions'>
        <Tooltip title='Save the current template, with any changes, as a new template'>
          <ButtonSecondary
            loading={savingAs}
            disabled={savingAs}
            onClick={() => handleClick(SaveButtonKeys.CREATE)}
            icon={<FileAddOutlined />}
            mixpanelEvent='Save performance report template'
            mixpanelProperties={{ action: 'Save as New Template' }}
          >
            Sav{savingAs ? 'ing' : 'e'} As New Template
          </ButtonSecondary>
        </Tooltip>
        {!reportTemplateStore.isDefaultTemplateSelected
        && (
          <Tooltip title='Save changes to the current template'>
            <ButtonSecondary
              loading={saving}
              disabled={saving || !reportTemplateStore.isTemplateEdited}
              icon={<SaveOutlined />}
              onClick={() => handleClick(SaveButtonKeys.SAVE)}
              mixpanelEvent='Save performance report template'
              mixpanelProperties={{ action: 'Save Changes' }}
            >
              Sav{saving ? 'ing' : 'e'} Changes
            </ButtonSecondary>
          </Tooltip>
        )}
      </aside>
      <Tooltip title='Download the current template, with any changes, as a Word document'>
        <ButtonPrimary
          loading={downloading}
          disabled={downloading}
          onClick={() => {
            handleDownloadButtonClick();
          }}
          icon={<DownloadOutlined />}
          mixpanelEvent='Download performance report template'
        >
          Download{downloading ? 'ing' : ''} as Word Doc
        </ButtonPrimary>
      </Tooltip>
    </footer>
  );
};

export default observer(ReportTemplateFooter);
