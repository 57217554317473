import { observer } from 'mobx-react';
import React from 'react';
import { ClientManagementEnums } from '@aider/constants-library';
import { Tooltip } from 'antd';
import Markdown from 'markdown-to-jsx';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import QuickBooksImportHover from '../components/icons/QuickBooksImportHover';
import QuickBooksImport from '../components/icons/QuickBooksImport';
import SuccessIllustration from '../components/illustrations/SuccessIllustration';
import { useStore } from '../stores/Store';
import ButtonSecondary from '../components/customAntD/ButtonSecondary';
import { Routers } from '../models/enums/utils';
import XeroImportHover from '../components/icons/XeroImportHover';
import XeroImport from '../components/icons/XeroImport';

const AppDisconnect = () => {
  const rootStore = useStore();
  const { connectionsStore, localeStore, loadingStore } = rootStore;
  const [hovered, setHovered] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { ospKey } = useParams();
  const osp = ClientManagementEnums.OSPKeys?.[ospKey as keyof typeof ClientManagementEnums.OSPKeys] || null;
  const ospName = localeStore.translation(`osps.${osp}`);

  if (!osp) {
    navigate(Routers.DASHBOARD);
  }

  const handleClick = () => {
    connectionsStore.connectionType = osp;
    connectionsStore.modalAddConnection();
  };

  return !loadingStore.isAnyLoading && (
    <main className='client-disconnect scrollbar'>
      <div className='client-disconnect__illustration'>
        <SuccessIllustration />
      </div>
      <h1>{localeStore.translation('client-disconnect.title', { ospName })}</h1>
      <Markdown>
        {localeStore.translation('client-disconnect.description', { ospName })}
      </Markdown>
      <h2>{localeStore.translation('client-disconnect.subtitle', { ospName })}</h2>

      {osp === ClientManagementEnums.OSPKeys.intuit && (
        <Tooltip
          title={(
            <Markdown>
              {localeStore.translation('loading-zone.intuit.description')}
            </Markdown>
          )}
          overlayClassName='osp-selector__tooltip'
          placement='bottom'
        >
          <ButtonSecondary
            shape='default'
            disabled={loading}
            className={`osp-buttons intuit ${loading ? 'loading' : ''} ${connectionsStore.connectionType === ClientManagementEnums.OSPKeys.intuit ? 'btn-active' : ''}`}
            onClick={() => {
              setLoading(true);
              handleClick();
            }}
            size='large'
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {loading && <LoadingOutlined spin />}
            {!loading && (hovered
              ? <QuickBooksImportHover className='osp-buttons__icon' />
              : <QuickBooksImport className='osp-buttons__icon' />)}
          </ButtonSecondary>
        </Tooltip>
      )}

      {osp === ClientManagementEnums.OSPKeys.xero && (
        <Tooltip
          title={(
            <Markdown>
              {localeStore.translation('loading-zone.xero.description')}
            </Markdown>
          )}
          overlayClassName='osp-selector__tooltip'
          placement='bottom'
        >
          <ButtonSecondary
            shape='default'
            className={`osp-buttons xero ${loading ? 'loading' : ''} ${connectionsStore.connectionType === ClientManagementEnums.OSPKeys.xero ? 'btn-active' : ''}`}
            onClick={() => {
              setLoading(true);
              handleClick();
            }}
            size='large'
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {loading && <LoadingOutlined spin />}
            {!loading && (hovered
              ? <XeroImportHover className='osp-buttons__icon' />
              : <XeroImport className='osp-buttons__icon' />)}
          </ButtonSecondary>
        </Tooltip>
      )}
    </main>
  );
};

export default observer(AppDisconnect);
