import { CommonConstants } from '@aider/constants-library';

/**
  * @description This function checks if the value exists or is one of the common falsy values
  * @param {any} value - The value to be checked
  * @returns {boolean} - Returns true if the value exists or is one of the common falsy values
  */
export const existsOrIsNull = (value: any):boolean => {
  if (value || value === null || value === CommonConstants.NullString || value === 0) {
    return true;
  }
  return false;
};

/**
  * @description Check if value is null and return enum null string instead
  * @param {any} value - The value to be checked
  * @returns {any} - Returns the value if it is not null, otherwise returns the enum null string
  */
export const nullToEmptyString = (value: any):any => (
  value === null
    ? CommonConstants.NullString
    : value
);

/**
  * @description Check if value is enum null string and return null instead
  * @param {any} value - The value to be checked
  * @returns {any | null} - Returns the value if it is not the enum null string, otherwise returns null
  */
export const emptyStringToNull = (value: any):any => (
  value === CommonConstants.NullString
    ? null
    : value
);
