import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const sortDecreaseSvg = () => (
  <svg
    height='1em'
    viewBox='0 0 20 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='currentColor'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M11.6667 16.8241H14.1667M11.6667 12.9351H15.8334M11.6667 9.04618L17.5001 9.04618M11.6667 5.15723L19.1667 5.15723' />
    <path d='M2.5 14.3242L5 16.8242L7.5 14.3242' />
    <path d='M5 5.15723L5 16.8239' />
  </svg>
);

const SortDecreaseIndicator = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={sortDecreaseSvg}
    {...props}
    aria-label='insights'
  />
);

export default SortDecreaseIndicator;
