import { SplitFactory } from '@splitsoftware/splitio';
import { makeAutoObservable } from 'mobx';
import { SplitEnums } from '@aider/constants-library';
import type { RootStore } from './Store';

export default class SplitStore {
  rootStore: RootStore;

  splitApiKey: string = process.env.REACT_APP_SPLIT_KEY;

  splitPracticeId: string;

  factory;

  manager;

  client;

  splitNames: Array<string>;

  features: any;

  setSplitFactory() {
    this.factory = SplitFactory({
      core: {
        authorizationKey: this.splitApiKey,
        key: this.splitPracticeId
      }
    });
  }

  initSplit() {
    if (this.splitPracticeId === undefined || this.splitPracticeId !== this.rootStore.practiceStore.id) {
      this.rootStore.loadingStore.setLoading('split');
      this.splitPracticeId = this.rootStore.practiceStore.id || 'anonymous';
      this.setSplitFactory();
      this.manager = this.factory.manager();
      this.client = this.factory.client();
      this.manager.on(this.manager.Event.SDK_READY, () => {
        this.splitNames = this.manager.names();
      });
      this.client.on(this.client.Event.SDK_READY, () => {
        this.features = this.client.getTreatments(this.splitNames);
      });
      this.rootStore.loadingStore.setDoneLoading('split');
    }
  }

  featureEnabled(featureName, enabledValue = 'on') {
    if (this.localOverride[featureName]) {
      return this.localOverride[featureName] === enabledValue;
    }
    if (this.features && this.features[featureName] === enabledValue) {
      return true;
    }
    return false;
  }

  /**
   * This is useful for testing features locally
   * To use, add a key value pair to the object below
   * The key is the feature and the value is the value you want to return
   * Example feature is provided as an example
   */
  localOverride:{[x:string] :string} = {
    [SplitEnums.FeatureFlags.ExampleFeature]: 'off',
  };

  get splitInitiated() {
    return this.splitPracticeId !== undefined && this.features !== undefined;
  }

  constructor(initStore) {
    this.rootStore = initStore;
    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }
}
