import * as React from 'react';
// import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Button as AntButton } from 'antd';
import BusinessService from '../../services/businessService';
import { rootStore } from '../../stores/Store';
import Mixpanel from '../../lib/mixpanel';

interface Props {
    type?: string,
    link?: string,
    insightData?: string | any,
    text: string,
    id?: string,
    className?: string,
    renderActionCentreModal?: any,
    ix?: any,
    state?: any, // eslint-disable-line react/no-unused-prop-types
    tableGroupId?: string,
    onClick?: any,
    disabled?: boolean,
    antD?: boolean,
    ghost?: boolean,
}

const ButtonLink = ({
  type,
  link,
  insightData,
  text,
  id,
  className,
  renderActionCentreModal,
  ix,
  tableGroupId,
  onClick,
  disabled,
  antD,
  ghost,
}: Props) => {
  function trackMixpanelEvent(event: string, extraProperites: any = {}) {
    Mixpanel.track(event, {
      businessId: rootStore.businessStore.selectedBusinessId,
      businessName: rootStore.businessStore.selectedBusinessName,
      insight: insightData.insightKey,
      ...extraProperites,
    });
  }

  const HandleClick = (event: any) => {
    switch (type) {
      case 'externalLink':
        trackMixpanelEvent(text);
        window.open(link, '_blank');
        return null;
      case 'email':
        trackMixpanelEvent(text);
        rootStore.actionStore.setSubjectKey(insightData.insightKey, false, `${ix}-insightChart`);
        return renderActionCentreModal('cashflow', insightData);
      case 'periodStatus':
        trackMixpanelEvent(text, { tableGroupId });
        BusinessService.updateChecklistStatus(tableGroupId);
        return null;
      default:
        onClick(event);
        return null;
    }
  };

  return (
    antD ? (
      <AntButton
        id={id}
        type='primary'
        className={`text-capitalize ${className}`}
        shape='round'
        ghost={ghost}
        onClick={HandleClick}
        disabled={disabled}
      >
        {text}
      </AntButton>
    ) : (
      <Button
        id={id}
        className={`rounded-pill text-capitalize btn-aider ${className}`}
        variant={`${className === 'white-button' ? 'outline-secondary' : 'secondary'}`}
        onClick={HandleClick}
        disabled={disabled}
      >
        {text}
      </Button>
    )
  );
};

ButtonLink.defaultProps = {
  type: undefined,
  link: '',
  insightData: '',
  id: '',
  renderActionCentreModal: () => {},
  ix: '',
  state: '',
  tableGroupId: '',
  onClick: () => {},
  disabled: false,
  antD: false,
  ghost: false,
  className: '',
};

export default ButtonLink;
