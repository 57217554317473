// TODO: Refactor this file to remove all the eslint-disable
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import * as Sentry from '@sentry/browser';
import { ApiConstants } from '@aider/constants-library';
import axios from 'axios';
import { rootStore } from '../stores/Store';
import BusinessService from './businessService';

export async function getOverviewScreenData(pageNumber, sortBy, append = false) {
  const transaction = Sentry.startTransaction({
    name: 'Get Dashboard Data',
  });
  let span = transaction.startChild({ op: 'refreshPracticeCredentials' });
  try {
    rootStore.GraphStore.setOverviewScreenLoading(true);
    const refreshedCredentials =
      await BusinessService.refreshPracticeCredentials();

    span.finish();
    if (
      !refreshedCredentials
      || !refreshedCredentials.access_token
      || !rootStore.businessStore.practiceId.id
    ) {
      span.setStatus('ok');
      span.finish();
      transaction.finish();
      return;
    }
    span = transaction.startChild({ op: 'getOverviewScreenData' });
    // eslint-disable-next-line camelcase
    const { access_token } = refreshedCredentials;
    if (sortBy) {
      rootStore.GraphStore.setSortBy(sortBy);
    }
    // eslint-disable-next-line consistent-return
    return axios({
      method: 'get',
      url: `${ApiConstants.apiEndpointsBase.overview}/${
        rootStore.businessStore.practiceId.id
      }/overview?pageNumber=${pageNumber || 1}&pageSize=${100}${
        sortBy ? `&sortBy=${sortBy}` : ''
      }`,
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        if (res) {
          rootStore.GraphStore.setOverviewScreenLoading(false);
          rootStore.GraphStore.setOverviewScreenData(res.data, append);
          span.setStatus('ok');
          span.finish();
          transaction.finish();
        }
      })
      .catch((error) => {
        rootStore.GraphStore.setOverviewScreenLoading(false);
        rootStore.GraphStore.setOverviewScreenData({});

        console.error(error);
        span.setStatus('unknown_error');
        span.finish();
        transaction.finish();
        Sentry.captureException(error);
      });
  } catch (e) {
    rootStore.GraphStore.setOverviewScreenLoading(false);
    span.setStatus('unknown_error');
    span.finish();
    transaction.finish();
    Sentry.captureException(e);
    console.error(e);
  }
}
export async function getTooltipData() {
  // eslint-disable-next-line no-useless-return
  return;
}

export async function getLineOfBusinessList(practiceId?) {
  try {
    const refreshedCredentials =
      await BusinessService.refreshPracticeCredentials();

    if (
      !refreshedCredentials
      || !refreshedCredentials.access_token
      || !rootStore.businessStore.practiceId.id
    ) {
      return;
    }
    // eslint-disable-next-line camelcase
    const { access_token } = refreshedCredentials;
    // @ts-ignore
    const url = `${ApiConstants.apiEndpointsBase.business}/practices/${
      practiceId || rootStore.businessStore.practiceId.id
    }/clients/lineofbusiness`;
    // eslint-disable-next-line consistent-return
    return axios({
      method: 'get',
      url,
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        rootStore.GraphStore.setLineOfBusinessList(res.data);
      })
      .catch((e) => {
        console.log('lob error:', e);
      });
  } catch (e) {
    console.log('LOB error:', e);
  }
}

/*
 * Gets the tags for the current practice from the business service API
 * and sets them in the tag store.
 * @returns A promise that resolves when the tags have been successfully retrieved and set.
*/
export async function getTags(practiceId?): Promise<void> {
  try {
    const token = await BusinessService.refreshPracticeCredentials();

    if (!token || !token.access_token || !rootStore.businessStore.practiceId.id) return;

    const url: string = `${ApiConstants.apiEndpointsBase.business}/practices/${
      practiceId || rootStore.businessStore.practiceId.id
    }/tags`;
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        rootStore.tagStore.setTags(res?.data?.tags);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  } catch (error) {
    Sentry.captureException(error);
  }
}
