import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';
import { useAuthenticatedEffect } from '../lib/customHooks/useAuthenticatedEffect';
import { Routers } from '../models/enums/utils';

const InvitationAcceptance = () => {
  const { loadingStore, invitationStore, authStore } = useStore();
  const navigate = useNavigate();

  useAuthenticatedEffect(() => {
    if (invitationStore.inviteToken) {
      loadingStore.setLoading('invitationAcceptance');
      invitationStore.acceptInvitation()
        .then((res) => {
          if (res?.status === 403) {
            throw Error(res.message);
          } else {
            invitationStore.setInviteAccepted();
            loadingStore.setDoneLoading('invitationAcceptance');
            navigate(Routers.DASHBOARD);
          }
        })
        .catch((error) => {
          // Error encountered, set error, logout user, and return to invite form
          loadingStore.setDoneLoading('invitationAcceptance');
          invitationStore.error = error;
          authStore.handleSignOut(true)
            .then(() => {
              navigate(Routers.INVITATION);
              invitationStore.accepting = null;
            });
        });
    }

    if (invitationStore.inviteAccepted && !invitationStore.inviteToken) {
      navigate(Routers.DASHBOARD);
    }
  }, [invitationStore.inviteToken, invitationStore.inviteAccepted]);

  return <></>;
};

export default observer(InvitationAcceptance);
