import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import { WarningIllustration } from '../icons';
import StandardModal from './wrappers/StandardModal';

const OverwriteChangesModal = async (successCallback, failureCallback) => {
  const ModalProps = {
    name: 'Change template selection',
    width: ModalWidths.MEDIUM,
    okText: 'Change Template',
    cancelText: 'Stay on Template',
    onOk: successCallback,
    onCancel: failureCallback,
    okButtonProps: {
      className: 'button-secondary',
      danger: true,
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      shape: 'round',
      size: 'large',
      className: 'button-primary',
    },
    content: (
      <section className='centered-modal'>
        <WarningIllustration className='modal-illustration' />
        <h2>Unsaved Changes</h2>
        <p>It looks like you have unsaved changes. If you choose a new template, you'll lose any changes you made.</p>
        <p>
          <strong>Stay on Template:</strong> Keep editing your changes.<br />
          <strong>Change Template:</strong> Discard changes and proceed.
        </p>
      </section>
    ),
  };
  NiceModal.register('OverwriteChangesModal', StandardModal);
  return NiceModal.show('OverwriteChangesModal', ModalProps);
};

export default OverwriteChangesModal;
