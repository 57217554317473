import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { ReactNode } from 'react';

const AiderTag = ({ ghost, onClick, children }:{ghost?:boolean, onClick?:()=>void, children:ReactNode}) => (
  <span className={`aider-tag ${ghost && 'ghost'}`}>
    {children}
    {onClick && <Button type='link' icon={<CloseOutlined />} onClick={onClick} shape='circle' size='small' className='aider-tag__close' />}
  </span>
);

AiderTag.defaultProps = {
  ghost: false,
  onClick: null,
};

export default AiderTag;
