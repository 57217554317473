// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import { makeAutoObservable } from 'mobx';
import actionService from '../../services/actionService';

export default class ActionStore {
  rootStore: any;

  sendEmailAction: any;

  getEmailContacts: any;

  sendCustomTierEmailToCS: any;

  constructor(initStore) {
    this.rootStore = initStore;
    this.sendEmailAction = actionService.sendEmailAction;
    this.getEmailContacts = actionService.getEmailContacts;
    this.sendCustomTierEmailToCS = actionService.sendCustomTierEmailToCS;

    makeAutoObservable(this, {
      rootStore: false,
      chartImages: false,
    }, { autoBind: true });
  }

  overviewAlerts = [];

  alertFilter = null;

  overviewTotals = {
    overdue: undefined,
    count: undefined,
  };

  actionsLoading = false;

  overviewAlertsLoading = false;

  chartHandle = {};

  chartImages = {};

  selectedChartArrayForMessage = [];

  isMultiInsightDraft = false;

  confirmedMessageModalVisible = false;

  confirmedMessageUsers = [];

  isInsightAction = false;

  confirmedMessageAction = null;

  confirmedMessageSubject = null;

  confirmedMessage = null;

  confirmedMessageType = null;

  confirmedMessageMedium = null;

  confirmedChartSelection = null;

  confirmedChartSelectionImageKey = null;

  selectedSubjectKey = null;

  selectedActionIsBlank = false;

  selectedChartb64 = null;

  serializedChartSVG = null;

  savedMessagePreview = null;

  savedSelectedUsers = null;

  savedMedium = 'email';

  actionItems = [];

  scheduledItems = [];

  emailContacts = [];

  selectedScheduledItem = {};

  mappedScheduleItems = [];

  selectedPeriod = null;

  imageAttached = true;

  selectedActionItem = {
    actions: [],
    status: null,
    title: '',
    description: '',
    subjectKey: '',
  };

  selectedInsight = {
    summaries: [],
    actions: [],
    body: '',
    graph: {
      title: '',
    },
    insightKey: '',
  };

  brandFileUploadVisible = false;

  brandUploadStatus = null;

  setBrandUploadStatus(status) {
    this.brandUploadStatus = status;
  }

  setBrandFileUploadVisible(visible) {
    this.brandUploadStatus = null;
    this.brandFileUploadVisible = visible;
  }

  setActionItems(actionItems) {
    this.setActionsLoading(false);

    // eslint-disable-next-line no-return-assign
    return (this.actionItems = actionItems);
  }

  setFilter(filter) {
    // eslint-disable-next-line no-return-assign
    return (this.alertFilter = filter);
  }

  storeEmailContacts(contacts) {
    // eslint-disable-next-line no-return-assign
    return (this.emailContacts = contacts);
  }

  setOverviewAlerts(overviewAlerts) {
    this.setOverviewAlertsLoading(false);
    // alerts:
    //     another-example:
    // alertLevelCounts: {0: 12, 1: 7}
    // count: 19
    // name: "Another example alert"
    // overdue: 12

    this.overviewAlerts = Object.values(overviewAlerts.alerts);
    // eslint-disable-next-line no-return-assign
    return (this.overviewTotals = overviewAlerts.totals);
  }

  setSelectedScheduledItem(item) {
    // eslint-disable-next-line no-return-assign
    return (this.selectedScheduledItem = item);
  }

  selectActionCenterItem(item) {
    // eslint-disable-next-line no-return-assign
    return (this.selectedActionItem = item);
  }

  selectInsightAsAction(item) {
    this.selectedChartArrayForMessage = [
      `${item.insightKey}_${
        item.summaries[0]
        && item.summaries.filter((summary) => !summary.disabled)[0].id
      }`,
    ];
    // eslint-disable-next-line no-return-assign
    return (this.selectedInsight = item);
  }

  confirmMessageAction(
    users,
    message,
    subject,
    type,
    isInsight,
    charts,
    medium?,
    usersList?
  ) {
    console.log('CHARTS?...', charts);
    this.selectedChartArrayForMessage = charts;
    this.savedMedium = medium;
    this.savedSelectedUsers = usersList || users;
    this.savedMessagePreview = message;
    this.isInsightAction = isInsight;
    this.confirmedMessageUsers = users;
    this.confirmedMessageSubject = subject;
    this.confirmedMessage = message;
    this.confirmedMessageType = type;
    this.confirmedMessageMedium = medium;
    this.confirmedChartSelection = charts;
    this.confirmedChartSelectionImageKey = charts.map(
      (chart) => chart.split('_')[0]
    );
    // eslint-disable-next-line no-return-assign
    return (this.confirmedMessageModalVisible = true);
  }

  setActionsLoading(loading) {
    // eslint-disable-next-line no-return-assign
    return (this.actionsLoading = loading);
  }

  setOverviewAlertsLoading(loading) {
    // eslint-disable-next-line no-return-assign
    return (this.overviewAlertsLoading = loading);
  }

  setChartObject(chart, id) {
    // eslint-disable-next-line no-return-assign
    return (this.chartHandle[id] = chart);
  }

  setChartImage(chart, id) {
    // eslint-disable-next-line no-return-assign
    return (this.chartImages[id] = chart);
  }

  setIsInsightActionFalse() {
    // eslint-disable-next-line no-return-assign
    return (this.isInsightAction = false);
  }

  setSubjectKey(
    key,
    isBlank?,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    b64ImageKey?
  ) {
    this.selectedChartb64 = this.chartImages[key];
    this.selectedActionIsBlank = isBlank;
    // eslint-disable-next-line no-return-assign
    return (this.selectedSubjectKey = key);
  }

  closeConfirmedMessageModal() {
    this.savedMedium = null;
    this.savedSelectedUsers = null;
    this.savedMessagePreview = null;
    this.confirmedMessageUsers = [];
    this.confirmedMessage = null;
    this.confirmedMessageType = null;
    this.confirmedMessageModalVisible = false;
    this.confirmedMessageMedium = null;
  }

  setConfirmedChartSelectionNull() {
    this.confirmedChartSelection = null;
    this.confirmedChartSelectionImageKey = null;
  }

  closeConfirmedMessageModalButKeepRecipientInfo() {
    this.confirmedMessageUsers = [];
    this.confirmedMessage = null;
    this.confirmedMessageType = null;
    this.confirmedMessageModalVisible = false;
    this.confirmedMessageMedium = null;
    this.confirmedChartSelection = null;
  }

  setSelectedPeriod(period, index) {
    this.selectedPeriod = { index, period };
  }

  setImageAttached(imageIsAttached) {
    this.imageAttached = imageIsAttached;
  }

  setMultiInsight(isMultiInsight) {
    this.isMultiInsightDraft = isMultiInsight;
  }

  setConfirmedMessageSubjectNull() {
    this.confirmedMessageSubject = null;
  }
}
