import React from 'react';
import { Tooltip } from 'antd';
import Format, { FormatTypes, ValueTypes } from '@aider/aider-formatting-library';
import { ClientManagementEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import { XeroLogoScaleable } from '../icons';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import XeroReconnectHover from '../icons/XeroReconnectHover';
import XeroReconnect from '../icons/XeroReconnect';

const SectionReconnect = (
  { buttonOnly, lastUpdated }: { buttonOnly?: boolean, lastUpdated?: string }
) => {
  const { businessesStore, connectionsStore } = useStore();
  const handleReconnectClick = (osp: ClientManagementEnums.OSPKeys) => {
    connectionsStore.connectionType = osp;
    connectionsStore.modalAddConnection();
  };

  const [xeroHovered, setXeroHovered] = React.useState(false);
  const format = new Format(businessesStore.selectedBusiness?.currencyCode, businessesStore.selectedBusiness?.countryCode);
  const checklistUpdated = lastUpdated ? new Date(lastUpdated) : new Date();
  return (
    <p className='section-reconnect'>
      {!buttonOnly && (
      <>Updated: {format.formatValue({
        value: checklistUpdated.toISOString(),
        format: ValueTypes.formatDate,
        dateFormat: { day: FormatTypes.short, month: FormatTypes.short, time: true },
      })}<span className='osp__logo'><XeroLogoScaleable /></span><span className='status-text'>Disconnected</span>
      </>
      )}
      <Tooltip
        placement='right'
        title=''
      >
        {buttonOnly
          && (
          <ButtonSecondary
            shape='default'
            className='reconnect-xero'
            size='middle'
            onMouseEnter={() => setXeroHovered(true)}
            onMouseLeave={() => setXeroHovered(false)}
            onClick={() => handleReconnectClick(ClientManagementEnums.OSPKeys.xero)}
            mixpanelEvent='Button Click - Reconnect Client'
          >
            {(xeroHovered
              ? <XeroReconnectHover className='osp-buttons__icon' />
              : <XeroReconnect className='osp-buttons__icon' />)}
          </ButtonSecondary>
          )}
        {!buttonOnly
          && (
          <ButtonSecondary
            className='reconnect'
            size='small'
            ghost
            onClick={() => handleReconnectClick(ClientManagementEnums.OSPKeys.xero)}
            mixpanelEvent='Button Click - Reconnect Client'
          >
            Reconnect
          </ButtonSecondary>
          )}
      </Tooltip>
    </p>
  );
};

SectionReconnect.defaultProps = {
  buttonOnly: false,
  lastUpdated: null,
};

export default SectionReconnect;
