import { makeAutoObservable } from 'mobx';
import qs from 'qs';
import * as Sentry from '@sentry/browser';
import firebase, { firestore } from 'firebase';
import cookie from 'js-cookie';
import {
  clearPersistedStore,
  makePersistable,
  stopPersisting,
} from 'mobx-persist-store';
import { ApiConstants, ClientManagementEnums } from '@aider/constants-library';
import { BusinessActivationStatus } from '@aider/constants-library/dist/enums/clientManagement';
import type { RootStore } from './Store';
import { GET, POST } from '../lib/requests';
import { AccountType } from '../entities/types';
import handleError from '../lib/errorHandler';
import { Routers } from '../models/enums/utils';

export enum LoginErrorType {
  EMAIL_NOT_VERIFIED = 'emailNotVerified',
}

class AuthenticationStore {
  rootStore: RootStore;

  accessToken: string;

  expiresAt: number;

  expiresIn: number;

  refreshToken: string;

  tokenType: string;

  config: any;

  authenticatedUserId: string;

  authenticatedPracticeId: string;

  impersonatingUser: boolean;

  firestoreAuthId: string;

  initiatingConnection: boolean;

  currentNonce: string;

  data: any;

  performedInitialAuthCheck: boolean = false;

  termsAccepted: boolean = false;

  loginError: boolean = false;

  loginErrorType: LoginErrorType | string = '';

  connectionOpts = {
    [ClientManagementEnums.OSPKeys.xero]: {
      key: 'xero',
      app: 'accounting',
    },
    [ClientManagementEnums.OSPKeys.intuit]: {
      key: 'intuit',
      app: 'quickbooks',
    }
  }

  set tokens({ accessToken, expiresAt, refreshToken, expiresIn, tokenType }) {
    this.accessToken = accessToken;
    this.expiresAt = expiresAt;
    this.expiresIn = expiresIn;
    this.refreshToken = refreshToken;
    this.tokenType = tokenType;
  }

  get tokens() {
    return {
      accessToken: this.accessToken,
      expiresAt: this.expiresAt,
      expiresIn: this.expiresIn,
      refreshToken: this.refreshToken,
      tokenType: this.tokenType,
    };
  }

  get isAuthenticated() {
    return !!this.accessToken;
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    if (process.env.NODE_ENV !== 'test') {
      this.initStorePersistance();
    }
  }

  initStorePersistance() {
    makePersistable(this, {
      name: 'AuthenticationStore',
      properties: [
        'firestoreAuthId',
      ],
      storage: window.localStorage,
    });
  }

  async clearStoredData() {
    await clearPersistedStore(this);
    await stopPersisting(this);
  }

  async constructTokens() {
    if (this.accessToken) {
      await this.renewTokens();
    } else {
      await this.initTokens();
    }
  }

  tokenIsExpired() {
    const now = Math.floor(Date.now() / 1000);
    return !this.expiresAt || now >= this.expiresAt;
  }

  async renewTokens(sentryTransaction = null) {
    const transaction =
      sentryTransaction
      || Sentry.startTransaction({
        name: 'Renew Authentication Tokens',
      });
    // If there is no refresh_token, there is nothing we can do here
    let span = transaction.startChild({
      op: 'checkRefreshToken',
    });
    if (!this.refreshToken) {
      span.setStatus('no_refresh_token');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      return;
    }
    span.setStatus('ok');
    span.finish();

    span = transaction.startChild({
      op: 'checkTokenExpiry',
    });
    // If current tokens are still valid, exit
    if (!this.tokenIsExpired()) {
      span.setStatus('tokens_valid');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      return;
    }
    span.setStatus('ok');
    span.finish();

    // Tokens expired or about to expire, refresh from server
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(process.env.REACT_APP_API_KEY)}`,
    };

    const data = qs.stringify({
      grant_type: 'refresh_token',
      refresh_token: this.refreshToken,
    });

    const url = `${ApiConstants.apiEndpointsBase.auth}/oauth2/token`;

    span = transaction.startChild({
      op: 'refreshTokens',
    });
    try {
      const refreshedTokens = await POST({
        url,
        headers,
        data,
        rootStore: this.rootStore,
        skipAuth: true,
      });
      this.tokens = {
        accessToken: refreshedTokens.access_token,
        expiresAt: Math.floor(Date.now() / 1000) + refreshedTokens.expires_in,
        expiresIn: refreshedTokens.expires_in,
        refreshToken: refreshedTokens.refresh_token,
        tokenType: refreshedTokens.token_type,
      };
      span.setStatus('ok');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }

      // TODO: Remove once all stores are migrated to v2
      this.updateV1Tokens();
    } catch (error) {
      span.setStatus('error');
      span.finish();
      if (!sentryTransaction) {
        transaction.finish();
      }
      Sentry.captureException('Error refreshing tokens:', error);
    }
  }

  initTokens = async () => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${process.env.REACT_APP_API_KEY}`)}`,
    };
    const body = qs.stringify({
      grant_type: 'client_credentials',
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
      userId: this.rootStore.userStore.id,
    });

    const url = `${ApiConstants.apiEndpointsBase.auth}/oauth2/token`;
    try {
      const backendTokens = await POST({
        url,
        headers,
        data: body,
        rootStore: this.rootStore,
        skipAuth: true,
      });
      const {
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: refreshToken,
        token_type: tokenType,
      } = backendTokens;

      const expiresAt = Math.floor(Date.now() / 1000) + expiresIn;

      this.tokens = {
        accessToken,
        expiresAt,
        expiresIn,
        refreshToken,
        tokenType,
      };

      // TODO: Remove once all stores are migrated to v2
      this.updateV1Tokens();
    } catch (e) {
      Sentry.captureException(e);
    }
    return this.tokens;
  };

  updateV1Tokens = () => {
    this.rootStore.authStore.setTokens(
      this.tokens.accessToken,
      this.tokens.refreshToken,
      this.tokens.expiresIn,
      this.rootStore.authStore.id_token
    );
  };

  async impersonateUser(userId) {
    try {
      this.impersonatingUser = true;
      this.authenticatedUserId = this.rootStore.userStore.id;
      this.authenticatedPracticeId = this.rootStore.practiceStore.id;
      this.rootStore.userStore.id = userId;
      this.expiresAt = undefined;
      this.rootStore.businessesStore.businesses = new Map();
      this.rootStore.dashboardStore.businesses = new Map();

      const user = await this.impersonationProcess();
      return user;
    } catch (e) {
      Sentry.captureException('Could not impersonate user', e);
      this.impersonatingUser = false;
      return e;
    }
  }

  async stopImpersonatingUser() {
    try {
      this.rootStore.userStore.id = this.authenticatedUserId;
      this.expiresAt = undefined;
      this.rootStore.businessesStore.businesses = new Map();
      this.rootStore.dashboardStore.businesses = new Map();
      const user = await this.impersonationProcess();
      this.rootStore.practiceStore.id = this.authenticatedPracticeId;
      this.impersonatingUser = false;
      this.rootStore.splitStore.initSplit();
      return user;
    } catch (e) {
      Sentry.captureException('Could not impersonate user', e);
      this.impersonatingUser = false;
      return e;
    }
  }

  async impersonationProcess() {
    this.rootStore.businessesStore.selectedBusinessId = undefined;
    await this.initTokens();

    const user = await this.retrieveUserDetails();
    if (!user) throw Error('User not found');
    this.rootStore.userStore.user = {
      id: user.id,
      username: user.displayName,
      email: user.email.toLowerCase(),
      givenName: user.givenName,
      familyName: user.familyName,
      phoneNumber: user.phoneNumber,
    };
    this.rootStore.authStore.setUser({
      uid: user.id,
      name: user.displayName,
      email: user.email.toLowerCase(),
    });

    await this.loadUserData();
    return user;
  }

  async retrieveUserDetails() {
    try {
      const user = await this.retrieveUserDetail(this.rootStore.userStore.id);
      if (user?.accountType === AccountType.DigitalAssistant) {
        Sentry.captureMessage('digital_app account detected');
        this.rootStore.authStore.setRenderAppAccountKickback(true);
        this.rootStore.pageStore.setNavigateTo(Routers.INSIGHTS);
      }
      return user;
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  }

  async loadUserData() {
    this.rootStore.dashboardStore.initialiseDashboard();
    this.rootStore.businessesStore.fetchBusinessData({ businessStatus: BusinessActivationStatus.activated });

    // Initialise v1 stores
    this.rootStore.authStore.authService.loadUserData();
  }

  async retrieveUserDetail(userId) {
    try {
      const res = await GET({
        url: `${ApiConstants.apiEndpointsBase.user}/users/${userId}`,
        rootStore: this.rootStore,
      });

      if (!res) throw Error('User data not returned');
      this.rootStore.authStore.setPracticeUser({ user: res });
      this.rootStore.authStore.setPracticeUserDetail(res);
      this.rootStore.userStore.user = res;
      return res;
    } catch (e) {
      Sentry.captureException('Could not retrieve user detail', e);
      return e;
    }
  }

  async initiateConnection() {
    if (this.rootStore.practiceStore.id) {
      this.initiatingConnection = true;
      await this.initialiseConnectionWithBackend();
      await this.updateAuthDetails();
    }
  }

  async initialiseConnectionWithBackend() {
    const target = this.connectionOpts[this.rootStore.connectionsStore.connectionType] || this.connectionOpts[ClientManagementEnums.OSPKeys.xero];
    const url = `${ApiConstants.apiEndpointsBase.connectionModuleV2
    }/businesses/${this.rootStore.practiceStore.id
    }/connections/osp/${target.key}/app/${target.app}?connectionType=managed&redirectUrl=${encodeURIComponent(
      process.env.REACT_APP_SSO_REDIRECT_URL + (this.rootStore.connectionsStore.connectionType === ClientManagementEnums.OSPKeys.intuit ? Routers.ACTIVATE_CLIENTS : window.location.pathname)
    )}`;
    try {
      const res = await POST({ url, rootStore: this.rootStore });
      if (!res.authDocId) throw Error('Auth doc id not returned');
      this.firestoreAuthId = res.authDocId;
      if (!res._links.redirect.href) throw Error('Redirect url not returned');
      this.rootStore.connectionsStore.connectionUrl = res._links.redirect.href;
      return res;
    } catch (e) {
      Sentry.captureException('Initiate Connection Error:', e);
      return e;
    }
  }

  async createConnectionWithBackend() {
    await this.initialiseConnectionWithBackend();
    window.location.href = this.rootStore.connectionsStore.connectionUrl;
  }

  async updateAuthDetails() {
    const doc = await firestore()
      .collection('/authDetails')
      .doc(this.firestoreAuthId)
      .get();
    const data = doc.data();
    if (data.status === 'complete') {
      this.rootStore.practiceStore.subscriptionDetails =
        data.subscriptionDetails;
      this.currentNonce = data.nonce;
    }
    this.data = data;
    return data;
  }

  async reinitializeBusinessAuth(redirectUrl: string) {
    try {
      const practiceId = this.rootStore.practiceStore.id;
      const ospKey = 'xero';
      const appKey = 'accounting';
      const connectionType = 'managed';
      // eslint-disable-next-line prefer-template
      const url =
        `${ApiConstants.apiEndpointsBase.connectionModuleV2
        }/businesses/${practiceId
        }/connections/osp/${ospKey
        }/app/${appKey
        }?connectionType=${connectionType
        }&redirectUrl=${redirectUrl}`;

      const res = await POST({
        url,
        rootStore: this.rootStore,
      });

      if (!res) throw Error('No response from reinit business auth');

      return res;
    } catch (error) {
      Sentry.captureException('Could not add user scope', error);
      return error;
    }
  }

  async signInWithGoogle() {
    cookie.set('userHasLoggedIn', 'true', { secure: true });
    this.loginError = false;
    this.loginErrorType = null;
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    firebase.auth().signInWithPopup(provider).catch((error) => {
      this.rootStore.loadingStore.setDoneLoading('auth');
      handleError({ error });
      this.loginError = true;
      cookie.remove('loggingIn');
      cookie.remove('userHasLoggedIn');
    });
    this.rootStore.loadingStore.setLoading('auth');
    cookie.set('loggingIn', 'true');
  }

  async signInWithSSO(provider = 'xero', app = 'accounting') {
    cookie.set('userHasLoggedIn', 'true', { secure: true });
    this.loginError = false;
    this.loginErrorType = null;
    const sentryTransaction = Sentry.startTransaction({
      name: 'Sign In With SSO',
      data: {
        provider,
        app,
      },
    });
    const redirectUrl = process.env.REACT_APP_SSO_REDIRECT_URL;
    const serviceEndpoint = `${ApiConstants.apiEndpointsBase.connectionService}/sso/osp/{ospKey}/app/{appKey}`;
    const url = `${serviceEndpoint.replace(
      '{ospKey}',
      provider
    ).replace('{appKey}', app)}?redirectUrl=${encodeURIComponent(
      redirectUrl
    )}`;

    this.rootStore.loadingStore.setLoading('auth');
    return POST({ url, rootStore: this.rootStore, sentryTransaction, skipAuth: true, sentrySpanName: 'SSO Post Request' })
      .then((response) => {
        cookie.set('loggingIn', 'true');
        const resultUrl = response._embedded.sso._links.redirect.href;
        sentryTransaction.finish();
        window.location.href = resultUrl;
      })
      .catch((error) => {
        this.rootStore.loadingStore.setDoneLoading('auth');
        handleError({ error });
        sentryTransaction.finish();
        this.loginError = true;
        cookie.remove('loggingIn');
        cookie.remove('userHasLoggedIn');
      });
  }
}

export default AuthenticationStore;
