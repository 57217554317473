import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const AiderLogoIconSvg = () => (
  <svg width='1em' viewBox='0 0 73 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <ellipse cx='43.8718' cy='30.3543' rx='16.1943' ry='16.2625' fill='white' />
    <path d='M5.97565 69.8477C4.57137 69.4007 3.27317 68.7944 2.28766 67.6228C0.583261 65.598 0.298497 62.6089 1.62461 60.2711C2.97305 57.8967 5.66994 56.6729 8.40033 57.1988C10.9604 57.6909 13.0096 59.8735 13.3865 62.5074C13.8709 65.8955 11.8217 68.9242 8.512 69.7152C8.40591 69.7405 8.3082 69.8026 8.2077 69.8491C7.46368 69.8477 6.71967 69.8477 5.97565 69.8477Z' fill='#02E3E2' />
    <path d='M43.2528 59.734C36.78 59.5944 30.9577 57.6359 25.7538 53.798C25.3699 53.516 25.0475 53.4666 24.605 53.5893C22.0323 54.3013 19.4527 54.988 16.8745 55.6803C15.2678 56.1117 14.2628 55.0909 14.6927 53.4624C15.4744 50.5001 16.2491 47.5363 17.0588 44.5824C17.2011 44.0649 17.1313 43.6546 16.8996 43.1865C11.3719 32.0336 13.2647 18.6375 21.7448 9.56711C27.3256 3.59728 34.2325 0.465727 42.3901 0.171042C56.5474 -0.340778 69.7037 9.69964 72.3434 24.7709C75.1449 40.7698 64.5277 56.2076 48.7136 59.1136C46.9142 59.4421 45.0731 59.5323 43.2528 59.734ZM52.442 42.5845C52.442 42.9891 52.4127 43.25 52.4476 43.5023C52.6305 44.8207 52.9557 46.0995 54.0529 46.9638C55.7517 48.3033 57.6683 48.4415 59.5653 47.5645C61.4526 46.6917 62.4367 45.0731 62.459 42.9637C62.4939 39.6588 62.5148 36.3524 62.4506 33.0474C62.4129 31.1186 62.265 29.1869 62.0542 27.2693C61.6145 23.2608 59.8584 19.85 57.1099 16.9751C55.5493 15.3424 53.7025 14.079 51.6338 13.1414C47.7644 11.3874 43.726 10.9263 39.59 11.6567C35.1008 12.4491 31.2983 14.5739 28.3223 18.1158C25.9227 20.971 24.5226 24.3027 24.1597 27.9771C23.8694 30.9212 24.1234 33.8765 25.0852 36.7415C26.2452 40.1988 28.1715 43.0836 31.0764 45.2606C33.3 46.9272 35.8392 47.9085 38.5682 48.285C39.9808 48.481 41.4689 48.6375 42.8578 48.4091C46.7258 47.7703 50.0201 46.0107 52.442 42.5845Z' fill='#3D60F2' />
    <path d='M52.4423 42.5841C50.019 46.0103 46.7261 47.77 42.8567 48.4073C41.4678 48.6357 39.9811 48.4792 38.5671 48.2832C35.8381 47.9053 33.299 46.9254 31.0753 45.2588C28.1704 43.0818 26.2441 40.197 25.0841 36.7398C24.1223 33.8747 23.8683 30.9194 24.1586 27.9754C24.5216 24.301 25.9216 20.9692 28.3212 18.114C31.2972 14.5736 35.0997 12.4473 39.5889 11.6549C43.7249 10.9246 47.7647 11.387 51.6327 13.1396C53.7014 14.0773 55.5496 15.3406 57.1088 16.9733C59.8573 19.8497 61.6134 23.2604 62.0531 27.2676C62.2639 29.1851 62.4118 31.1182 62.4495 33.0456C62.5137 36.3492 62.4928 39.657 62.4579 42.962C62.4356 45.0713 61.4515 46.6914 59.5642 47.5627C57.6672 48.4397 55.7506 48.3016 54.0518 46.9621C52.956 46.0978 52.6294 44.8189 52.4465 43.5006C52.413 43.2496 52.4423 42.9874 52.4423 42.5841ZM52.4325 41.6761C52.4325 41.4618 52.4325 41.2813 52.4325 41.1008C52.4325 37.3503 52.4479 33.5983 52.4284 29.8478C52.4116 26.7163 51.2823 24.0881 48.8967 22.0267C45.7001 19.2646 40.0314 19.3407 37.0232 22.3143C33.9104 25.3909 33.3046 29.1795 34.5623 33.2303C35.7236 36.9682 38.3661 39.1833 42.2523 39.6923C44.5555 39.994 46.5084 39.0916 48.1527 37.5477C49.5584 38.9055 50.9655 40.2619 52.4325 41.6761Z' fill='#00E3E2' />
  </svg>
);

const AiderLogoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AiderLogoIconSvg} {...props} />
);

export default AiderLogoIcon;
