import * as React from 'react';

/**
 * D (for draft) in a circle icon
 * @param props
 * @returns
 */

export const CircleDraft = () => (
  <svg width='17' height='16' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1.0332' y='0.5' width='19' height='19' rx='9.5' fill='white' />
    <path d='M7.23117 15C6.92317 15 6.68517 14.9207 6.51717 14.762C6.35851 14.594 6.27917 14.356 6.27917 14.048V6.082C6.27917 5.774 6.35851 5.54067 6.51717 5.382C6.68517 5.214 6.92317 5.13 7.23117 5.13H9.94717C11.5992 5.13 12.8732 5.55467 13.7692 6.404C14.6745 7.25333 15.1272 8.47133 15.1272 10.058C15.1272 10.8513 15.0105 11.556 14.7772 12.172C14.5532 12.7787 14.2172 13.292 13.7692 13.712C13.3305 14.132 12.7892 14.454 12.1452 14.678C11.5105 14.8927 10.7778 15 9.94717 15H7.23117ZM8.07117 13.516H9.83517C10.4138 13.516 10.9132 13.446 11.3332 13.306C11.7625 13.1567 12.1172 12.942 12.3972 12.662C12.6865 12.3727 12.9012 12.0133 13.0412 11.584C13.1812 11.1453 13.2512 10.6367 13.2512 10.058C13.2512 8.90067 12.9665 8.03733 12.3972 7.468C11.8278 6.89867 10.9738 6.614 9.83517 6.614H8.07117V13.516Z' fill='#EB5757' />
    <rect x='1.0332' y='0.5' width='19' height='19' rx='9.5' stroke='#EB5757' />
  </svg>
);
