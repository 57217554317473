import React from 'react';
import { Select } from 'antd';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';

const PeriodCloseDataHeaderRender = () => {
  const { localeStore, periodCloseDashboardStore, practiceStore } = useStore();

  const PeriodClosePeriodHeaderText = () => {
    switch (periodCloseDashboardStore.activeChecklistType) {
      case ComplianceChecklistEnums.Types.yearlyChecklist:
        return 'last complete financial year';
      case ComplianceChecklistEnums.Types.gstPeriodChecklist:
        return 'GST next due';
      case ComplianceChecklistEnums.Types.monthlyChecklist:
        return 'last complete month';
      default:
        return '';
    }
  };

  const usPractice = practiceStore?.countryCode?.toLowerCase() === 'us';

  return (
    <div className='period-close__dashboard-group-header'>
      <span>Period Close Alerts</span>
      <span>-</span>(
      <Select
        defaultValue={periodCloseDashboardStore.activeChecklistType}
        onChange={(value) => periodCloseDashboardStore.selectChecklist(value)}
        size='small'
        dropdownStyle={{
          width: '20ch',
          maxWidth: '20ch',
          overflow: 'visible',
          minWidth: '20ch',
        }}
      >
        <Select.Option value={ComplianceChecklistEnums.Types.yearlyChecklist}>
          {localeStore.translation('period-close.checklist-label', {
            periodType: localeStore.translation('period-close.period-type.yearlyDataCompliance')
          })}
        </Select.Option>
        {!usPractice
        && (
        <Select.Option
          value={ComplianceChecklistEnums.Types.gstPeriodChecklist}
        >
          {localeStore.translation('period-close.checklist-label', {
            periodType: localeStore.translation('period-close.period-type.gstPeriodChecklist')
          })}
        </Select.Option>
        )}
        <Select.Option value={ComplianceChecklistEnums.Types.monthlyChecklist}>
          {localeStore.translation('period-close.checklist-label', {
            periodType: localeStore.translation('period-close.period-type.monthlyChecklist')
          })}
        </Select.Option>
      </Select>
      )<span>-</span>
      <span>{PeriodClosePeriodHeaderText()}</span>
    </div>
  );
};

export default observer(PeriodCloseDataHeaderRender);
