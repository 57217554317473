import { Button, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import React from 'react';
import { useStore } from '../../stores/Store';
import { SortDecreaseIndicator, SortIncreaseIndicator, SortOptionIndicator } from '../icons';

const DashboardSort = ({ column }) => {
  const { dashboardStore, localeStore } = useStore();
  const getLabel = (key: string) => {
    const placeholder = localeStore.translation(`performance-dashboard.sort.placeholders.${column}`);
    if (['client', 'unreconciled', 'gstcompletion'].includes(column)) {
      return localeStore.translation(`performance-dashboard.sort.labels.${column}.${key}`, { placeholder });
    }
    return localeStore.translation(`performance-dashboard.sort.labels.default.${key}`, { placeholder });
  };

  const onClick: MenuProps['onClick'] = async ({ key }) => {
    dashboardStore.loadingMore = true;
    await dashboardStore.sort(key);
  };

  const GetIcon = () => {
    if (dashboardStore.sortColumn === column) {
      return dashboardStore.sortDirection === 'desc' ? (<SortDecreaseIndicator className='dashboardSort__button active client' />) : (<SortIncreaseIndicator className='dashboardSort__button active client' />);
    }
    return <SortOptionIndicator className='dashboardSort__button' />;
  };

  let items: MenuProps['items'] = [
    {
      key: `${column}:value.desc`,
      label: <>{getLabel('valueDesc')}</>,
      icon: <SortDecreaseIndicator />,
      disabled: dashboardStore.activeSort === `${column}:value.desc`,
    },
    {
      key: `${column}:value.asc`,
      label: <>{getLabel('valueAsc')}</>,
      icon: <SortIncreaseIndicator />,

      disabled: dashboardStore.activeSort === `${column}:value.asc`,
    },
    {
      key: `${column}:change.desc`,
      label: <>{getLabel('changeDesc')}</>,
      icon: <SortDecreaseIndicator />,
      disabled: dashboardStore.activeSort === `${column}:change.desc`,
    },
    {
      key: `${column}:change.asc`,
      label: <>{getLabel('changeAsc')}</>,
      icon: <SortIncreaseIndicator />,
      disabled: dashboardStore.activeSort === `${column}:change.asc`,
    },
  ];

  if (column === 'client') {
    items = [
      {
        key: `${column}:value.asc`,
        label: <>{getLabel('valueAsc')}</>,
        icon: <SortIncreaseIndicator />,
        disabled: dashboardStore.activeSort === `${column}:value.asc`,
      },
      {
        key: `${column}:value.desc`,
        label: <>{getLabel('valueDesc')}</>,
        icon: <SortDecreaseIndicator />,
        disabled: dashboardStore.activeSort === `${column}:value.desc`,
      },
    ];
  }
  return (
    <Dropdown
      menu={{ items, onClick }}
      placement='bottomRight'
      className='dashboardSort__dropdown'
      dropdownRender={(menu) => (
        <div className='dashboardSort__dropdown'>
          {React.cloneElement(menu as React.ReactElement)}
        </div>
      )}
    >
      <Button
        className='dashboardSort'
        ghost
        icon={<GetIcon />}
        disabled={dashboardStore.loading}
      />
    </Dropdown>
  );
};

export default DashboardSort;
