import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { v4 as uuid } from 'uuid';
import { ModalType, ModalWidths } from '../../models/enums/modals';
import AsyncModal from './wrappers/AsyncModal';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import EditPromptContent from './content/EditPromptContent';
import Notification from '../Notification';

const SavePromptModal = (rootStore, prompt, form, type = ModalType.NEW) => {
  const ModalProps = {
    title: `${type === ModalType.NEW ? 'Save' : 'Edit'} Prompt`,
    name: `${type === ModalType.NEW ? 'save' : 'edit'}-prompt-modal`,
    onOk: async () => {
      try {
        const validation = await form.validateFields();
        const savePrompt = {
          id: prompt.id || uuid(),
          page: prompt.page || 'insights',
          text: validation.prompt,
          label: validation.label,
        };
        trackMixpanelEvent({ description: `${type === ModalType.NEW ? 'Save' : 'Edit'} Prompt`, properties: { savePrompt }, rootStore });

        const res = await rootStore.assistantStore.addUserPrompt(savePrompt);
        Notification({ type: 'success', title: 'Prompt edited successfully', description: `Successfully edited prompt "${savePrompt.label}".` });
        if (!res) {
          Notification({ type: 'error', title: 'Could not edit' });
          throw Error('Error saving prompt');
        }
        return { status: 'ok' };
      } catch (err: any) {
        return { status: 'error', error: err };
      }
    },
    okText: `${type === ModalType.NEW ? 'Save' : 'Update'} Prompt`,
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'medium',
    },
    cancelButtonProps: {
      danger: true,
      shape: 'round',
      size: 'medium',
      className: 'button-secondary',
    },
    width: ModalWidths.XXLARGE,
    content: <EditPromptContent form={form} />,
    errorMessage: 'Error saving prompt - please try again',
  };

  return NiceModal.show(AsyncModal, ModalProps);
};

export default SavePromptModal;
