import { observer } from 'mobx-react';
import * as React from 'react';
import { Select } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../stores/Store';
import { trackMixpanelEvent } from '../lib/mixpanel';
import { Routers } from '../models/enums/utils';
import ButtonSecondary from '../components/customAntD/ButtonSecondary';

const BusinessHeader = () => {
  const rootStore = useStore();
  const { alertStore, businessStore, businessesStore } = rootStore;
  const navigate = useNavigate();

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <header role='navigation' className='insights-navigation'>
      <div className='wrapper wrapper-xl'>
        <label htmlFor='client-search' className='insights-navigation--label'>
          Client
          <Select
            id='client-search'
            value={businessesStore.selectedBusiness?.id}
            size='large'
            className='insights-navigation--search'
            popupClassName='insights-navigation--search-dropdown'
            showSearch
            placeholder='Search clients'
            options={businessesStore.sortedActiveClientBusinesses.map((business) => {
              const alerts = alertStore.getBusinessAlerts(business.id);
              return ({
                value: business.id,
                label: `${business.name} (${alerts} alerts)`
              });
            })}
            optionFilterProp='children'
            filterOption={filterOption}
            suffixIcon={<SearchOutlined />}
            onSelect={(value) => {
              trackMixpanelEvent({
                description: 'Insights: Client search selected',
                properties: { clientId: value },
                rootStore
              });
              businessStore.selectBusiness2(value);
            }}
            notFoundContent={(
              <div className='insights-navigation--client-not-found'>
                <p>No clients found</p>
                <ButtonSecondary onClick={() => navigate(Routers.CLIENTS_MANAGEMENT)} icon={<PlusOutlined />}>Add Client</ButtonSecondary>
              </div>
            )}
          />
        </label>
      </div>
    </header>
  );
};

export default observer(BusinessHeader);
