import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import Markdown from 'markdown-to-jsx';
import { IInsightMissingProps } from '../../ts/interfaces/molecules/Insights';
import { useStore } from '../../stores/Store';
import AlertIllustration from '../../components/illustrations/AlertIllustration';
import ButtonPrimary from '../../components/customAntD/ButtonPrimary';

const InsightMissing = ({
  insightKey,
  error,
  helpUrl,
  helpLabel
}:IInsightMissingProps) => {
  const { businessesStore, localeStore } = useStore();
  const describedErrorKeys = ['gst-basis', 'gst-sales-period', 'gst-report-period', 'gst-country', 'cashflow-bank-accounts', 'cashflow-data'];
  const insightTitle = localeStore.translation(`insights.${insightKey}.title`);

  return (
    <>
      <h3 className='insightTitle'>{insightTitle}</h3>
      <div className='insightComponentContainer insight-missing'>
        <div className='insight-missing__illustration'>
          <AlertIllustration />
        </div>
        <h4>{localeStore.translation('insights.missing.title', { insightTitle })}</h4>
        <div className='insight-missing__body'>
          <div className='insight-missing__body--errors'>
            <Markdown>
              {error?.code && describedErrorKeys.includes(error.code)
                ? localeStore.translation(`insights.errors.${error.code}`, { osp: businessesStore.selectedBusinessOspName })
                : localeStore.translation('insights.errors.default', { osp: businessesStore.selectedBusinessOspName })}
            </Markdown>
            <Markdown>
              {localeStore.translation('insights.missing.description')}
            </Markdown>
          </div>
          <ButtonPrimary
            type='link'
            href={helpUrl}
            target='_blank'
            icon={<RightOutlined />}
          >
            {helpLabel}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};

InsightMissing.defaultProps = {
  error: null,
  helpUrl: 'https://ask.aider.ai/help/troubleshooting-advisory-insights-not-available',
  helpLabel: 'Help & Support - Insight Troubleshooting Guide'
};

export default InsightMissing;
