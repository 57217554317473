import React from 'react';
import SubLayoutSettings from '../../layouts/SubLayoutSettings';
import AccountNavbar from '../../components/accountSettings/AccountNavbar';
import AccountHeader from '../../components/accountSettings/Header';
import ManageBranding from '../../components/accountSettings/ManageBranding';

const Branding = () => (
  <SubLayoutSettings
    SubNav={(<AccountNavbar />)}
    HeaderContent={(<AccountHeader />)}
    SubMain={(<ManageBranding />)}
  />
);

export default Branding;
