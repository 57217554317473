// TODO: Refactor this file to remove all the eslint-disable
// @ts-nocheck
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import * as firebase from 'firebase';
import { rootStore } from '../stores/Store';
import { LIMIT_EXCEEDED } from './types';
import Mixpanel from '../lib/mixpanel';

export function objectMap(object, mapFn) {
  if (typeof object !== 'undefined') {
    return Object.keys(object).reduce((result, key) => {
      const newRes = result;
      newRes[key] = mapFn(object[key]);
      return newRes;
    }, {});
  }
  return null;
}
export function objectMapKeyReturned(object, mapFn) {
  if (typeof object !== 'undefined') {
    return Object.keys(object).reduce((result, key) => {
      const newRes = result;
      newRes[key] = mapFn(object, key);
      return newRes;
    }, {});
  }
  return null;
}
export function fetchBusinessDetailedData(businessId, fields) {
  this.setState({ editClientFields: fields });
}

export function renderFeedbackModal(id, messageId, answer, type) {
  return this.setState({
    feedbackModalVisible: true,
    feedbackElementId: id,
    messageId,
    messageToGiveFeedback: answer,
    feedbackType: type,
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function renderClientModal(id) {
  return this.setState({ clientModalVisible: true });
}

export function renderMetadataModal() {
  return this.setState({ metadataModalVisible: true });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function addClient(id) {
  return this.setState({ clientModalVisible: false });
}

export function inviteUser(fields) {
  rootStore.businessStore
    .inviteUser(
      rootStore.authStore.links,
      rootStore.businessStore.selectedBusinessId,
      fields
    )
    .then(() => {
      rootStore.businessStore.fetchPendingInviteCount(
        rootStore.authStore.links,
        rootStore.businessStore.selectedBusinessId,
      );
      rootStore.businessStore.getInvitationsForBusinesses();
    });
}

export function inviteAdvisor(fields) {
  rootStore.businessStore.inviteAdvisor(
    fields
  );
}

export function disconnectBusiness() {
  if (rootStore.businessStore.configureBusinessModalOpen) {
    rootStore.businessStore.shouldConfigureBusiness(false);
  }
  rootStore.businessStore.renderDisconnectConfirmation(true);
}
export function removeBusiness() {
  this.toggleModal(null);
  if (rootStore.businessStore.configureBusinessModalOpen) {
    rootStore.businessStore.shouldConfigureBusiness(false);
  }
  rootStore.businessStore.renderRemoveConfirmation(true);
}

/**
 * @deprecated
 */
export function updateSelectedBankAccounts(accounts) {
  const newBankArray = accounts.map((account) => account.accountId);
  rootStore.businessStore.setSelectedBankAccounts(newBankArray);
  this.setState({ selectedBankAccounts: newBankArray });
}

export function changeSort(type: any) {
  if (this.state.sortBy === type) {
    if (this.state.sortBy === 'alphabetical') {
      rootStore.businessStore.sortBusinessListData(
        'alphabetical',
        !this.state.sortStateDescending
      );
      this.setState({ sortStateDescending: !this.state.sortStateDescending });
    } else if (this.state.sortBy !== 'shortlist') {
      this.setState({ sortBy: 'alphabetical', sortStateDescending: true });
      rootStore.businessStore.changeSortInStore('alphabetical');
      rootStore.businessStore.sortBusinessListData('alphabetical', true);
    }
  } else {
    this.setState({ sortBy: type, sortStateDescending: true });
    rootStore.businessStore.changeSortInStore(type);
    rootStore.businessStore.sortBusinessListData(type, true);
  }
}

export async function uploadBrandImage(e, fileInput?) {
  let file;
  try {
    if (!fileInput) {
      this.setState({ filename: e.target.files[0].name, uploading: true });
      const files = Array.from(e.target.files);
      file = files.shift();
    } else {
      this.setState({ filename: fileInput.name, uploading: true });
      file = fileInput;
    }
    rootStore.actionStore.setBrandUploadStatus('loading');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const formData = new FormData();
    const storageRef = firebase.storage().ref();
    // @ts-ignore
    const practiceImageRef = storageRef.child(
      `logos/practice/${rootStore.businessStore.practiceId.id}`
    );
    // @ts-ignore
    const uploadTask = practiceImageRef.put(file);
    // @ts-ignore
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes
        // uploaded and the total number of bytes to be uploaded
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log('ERROR writefile', error);
        rootStore.actionStore.setBrandUploadStatus('error');
        this.setState({ imageURL: null, filename: null, uploading: false });
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // @ts-ignore
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.setState({ uploading: false, imageURL: downloadURL });
          rootStore.businessStore.uploadProfileImage(downloadURL);
          // @ts-ignore
        });
      }
    );
  } catch (err) {
    console.log('ERROR catchblock', err);
    rootStore.actionStore.setBrandUploadStatus('error');

    this.setState({ imageURL: null, filename: null, uploading: false });
  }
}

export function editUser(fields) {
  return rootStore.businessStore.editUserDetails(
    rootStore.authStore.links,
    fields,
    rootStore.userStore.id
  );
}

export function resendInvite(invitationId, businessId) {
  rootStore.businessStore.resendInvite(
    rootStore.authStore.links,
    invitationId,
    businessId
  );
}

export function setSelectedBusinessName(name, id) {
  rootStore.businessStore.setSelectedBusinessName(name);
  rootStore.businessStore.setSelectedBusinessId(id);
  rootStore.businessesStore.selectedBusinessId = id;
}

// eslint-disable-next-line consistent-return
export async function initiateConnection() {
  const canConnect = await rootStore.authStore.initiateConnection();
  if (
    canConnect.code === 'PAYMENT_REQUIRED_EXCEPTION'
    || canConnect.error === LIMIT_EXCEEDED
  ) {
    // eslint-disable-next-line consistent-return
    rootStore.businessStore.initiateSubscription().then((canInitiate) => {
      if (canInitiate) {
        return rootStore.authStore.hasExceededQuota(true);
      }
    });
  } else {
    return canConnect;
  }
}

export function closeHeaderDropdown() {
  rootStore.authStore.triggerDropdownOpen(false);
}

export function closeMetadataModal() {
  this.setState({
    metadataModalVisible: false,
  });
}

export function renderAdvisorModalRedirect(redirectType) {
  this.setState({ clientModalVisible: false });
  rootStore.authStore.renderAdvisorsModal(true, redirectType);
}

/**
 * Action to update the selected business tags in global store
 * by comparing the existing tags against the new tags and adding / removing appropriate
 * tags
 * @param {string[]} oldTags - The old tags that were applied to the business
 * @param {string[]} newTags - The new tags that are being applied to the business
 */
export async function updateBusinessTags(oldTags: string[] = [], newTags: string[] = []) {
  const businessId = rootStore.businessStore.selectedBusinessId;
  const tagsToAdd = newTags.filter((tag) => !oldTags?.includes(tag));
  const tagsToRemove = oldTags.filter((tag) => !newTags?.includes(tag));
  if (tagsToAdd?.length > 0) await rootStore.businessStore.addBusinessTags(businessId, tagsToAdd);
  if (tagsToRemove?.length > 0) {
    await rootStore.businessStore.removeBusinessTags(businessId, tagsToRemove);
  }
  rootStore.businessStore.updateCurrentBusinessListTags(newTags);

  /** Track Tag Update if any changes were made */
  if (tagsToAdd.length > 0 || tagsToRemove.length > 0) {
    Mixpanel.track('tagUpdate', {
      businessId: rootStore.businessStore.selectedBusinessId,
      businessName: rootStore.businessStore.selectedBusinessName,
      addedTags: tagsToAdd,
      removedTags: tagsToRemove,
    });
  }
}

/**
 * Run the action to update the practice tags in store
 * @param {string[]} newTags - Array of tags present in the business
 */
export async function updatePracticeTags(newTags: string[]) {
  await rootStore.tagStore.addPracticeTags(newTags);
}
