import { Button, Table, Space, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { RightOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { UsersEnums } from '@aider/constants-library';
import NavLinkCustom from '../NavLinkCustom';
import { Routers } from '../../models/enums/utils';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import { IManageAdvisorsProps } from '../../models/interfaces/components';
import { useStore, rootStore } from '../../stores/Store';
import { AccountSettings } from '../../models/enums/components';
import ManageAdvisorsFooter from './ManageAdvisorsFooter';
import Notification from '../Notification';

const { Content, Footer } = Layout;

const ManageAdvisors = () => {
  const { advisorsStore, loadingStore, userStore, practiceStore } = useStore();
  const {
    sortedAdvisors,
    retrieveAllAdvisors,
    reinviteAdvisor
  } = advisorsStore;
  const practiceId = practiceStore.id;
  const { hasUserDetails } = userStore;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reInviteKey, setReInviteKey] = useState<string>('');

  useEffect(() => {
    if (practiceId && hasUserDetails) {
      const fetchAdvisors = async () => {
        loadingStore.setLoading('advisors');
        await retrieveAllAdvisors();
        setIsLoading(false);
        loadingStore.setDoneLoading('advisors');
      };
      fetchAdvisors();
    }
  }, [practiceId, hasUserDetails, retrieveAllAdvisors]);

  const AdvisorsNameAndRole = (
    {
      fullName,
      role
    }: Pick<IManageAdvisorsProps, 'fullName' | 'role'>
  ) => (
    <h5 className='manageAdvisors__nameAndRole'>
      {fullName}
      {
          role
          && role.map((
            roleItem,
            index
          ) => (
            roleItem !== 'practice_user'
              ? <span key={`${index * Math.random() * 1000000}`}>({roleItem})</span>
              : null
          ))
        }
    </h5>
  );

  const ActiveStatus = (
    { status }: Pick<IManageAdvisorsProps, 'status'>
  ) => {
    const isActiveOrPending =
      status !== UsersEnums.Status.EXPIRED
      && status !== UsersEnums.Status.CANCELLED;

    return (
      isActiveOrPending
        ? <span className='manageAdvisors__status'>{status}</span>
        : (
          <span className='manageAdvisors__status--error'>
            <ExclamationCircleFilled />
            <Space />
            {status}
          </span>
        )
    );
  };

  const ReInviteButton = ({
    status,
    id
  }: Pick<IManageAdvisorsProps, 'status' | 'id'>) => {
    const isNotActive = status !== UsersEnums.Status.ACTIVE;

    const handleReInviteButton = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      trackMixpanelEvent({
        description: 'Re-Invite Advisor',
        rootStore,
      });
      setReInviteKey(id);
      reinviteAdvisor({ id })
        .then(() => {
          Notification({
            type: 'success',
            title: 'Advisor re-invited',
          });
          setReInviteKey('');
          return { status: 'success' };
        })
        .catch((error) => {
          Notification({
            type: 'error',
            title: 'Sorry, unable to re-invited advisor',
            description: 'Please try again.',
          });
          setReInviteKey('');
          Sentry.captureException(error);
          return { status: 'error' };
        });
    };

    return (
      isNotActive
      && (
      <Button
        type='link'
        onClick={handleReInviteButton}
        key={id}
      >
        { id === reInviteKey ? 'Reinviting' : 'Reinvite'}
        <RightOutlined />
      </Button>
      )
    );
  };

  const columns: ColumnsType<IManageAdvisorsProps> = [
    {
      render: (record) => (
        <>
          <AdvisorsNameAndRole fullName={record.fullName} role={record.role} />
          <br />
          {record.email}
        </>
      ),
      responsive: ['xs']
    },
    {
      render: (record) => (
        <>
          <ActiveStatus status={record.status} />
          <br />
          <ReInviteButton
            status={record.status}
            id={record.id}
          />
        </>
      ),
      responsive: ['xs']
    },
    {
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, record) => (
        <AdvisorsNameAndRole fullName={record.fullName} role={record.role} />
      ),
      responsive: ['sm']
    },
    {
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm']
    },
    {
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <ActiveStatus status={record.status} />
      ),
      responsive: ['sm']
    },

    {
      key: 'action',
      render: (_, record) => (
        <ReInviteButton
          status={record.status}
          id={record.id}
        />
      ),
      responsive: ['sm']
    },
  ];

  return (
    <>
      <header className='sub-content__header'>
        <h2 className='sub-content__header--title wrapper'>{AccountSettings.ADVISORS}</h2>
      </header>
      <Content className='sub-content__content manageAdvisors'>
        <div className='wrapper'>
          <h4>Colleagues invited to your Advisory Platform account</h4>
          <p>
            Need to give your colleagues access to client data?
            <NavLinkCustom
              to={Routers.PERMISSIONS}
              label={(
                <>
                  {AccountSettings.PERMISSIONS}
                  <RightOutlined />
                </>
              )}
              trackPath={AccountSettings.PERMISSIONS}
            />
          </p>
        </div>
      </Content>
      <div className='scrollbar manageAdvisors__table'>
        <Table
          className='wrapper'
          columns={columns}
          dataSource={sortedAdvisors}
          rowKey={(record) => record.id}
          pagination={false}
          showHeader={false}
          loading={isLoading}
          rowClassName='table__manageAdvisor'
        />
      </div>
      <Footer className='sub-content__footer'>
        <ManageAdvisorsFooter />
      </Footer>
    </>
  );
};

export default observer(ManageAdvisors);
