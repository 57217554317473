export enum Routers {
  DASHBOARD = '/',
  PERIOD_CLOSE_DASHBOARD = '/period-close-dashboard',
  INSIGHTS = '/insights',
  LOGIN = '/login',
  SIGNUP = '/signup',
  CONTACT = '/contact',
  CONTACT_THANKS = '/contact-thankyou',
  HELP = 'https://ask.aider.ai/help',
  SETTINGS = '/account-settings',
  FIRM = '/account-settings/firm-information',
  BRAND = '/account-settings/branding',
  ADVISORS = '/account-settings/advisors',
  PERMISSIONS = '/account-settings/permissions',
  CHECKLIST_TEMPLATES = '/account-settings/practice-checklist',
  ASSISTANT_SETTINGS = '/account-settings/assistant-settings',
  ONBOARDING = '/onboarding',
  INVITATION = '/invitation',
  INVITE_ACCEPT = '/invitation-acceptance',
  CLIENTS_MANAGEMENT = '/clients-management',
  ACTIVATE_CLIENTS = '/clients-management/activate-clients',
  PAGE_NOT_FOUND = '/page-not-found',
  ADVISORY_ASSISTANT = '/advisory-assistant',
  APP_REFERRAL = '/app-referral/:ospKey',
  DISCONNECT_REFERRAL = '/app-referral/:ospKey/disconnect',
  CONNECT_REFERRAL = '/app-referral/:ospKey/connect',
  CLIENT_DISCONNECT = '/client-disconnect/:ospKey',
}
