import {
  IconPaddedPlotInterface,
  IconPlotInterface,
} from '../ts/interfaces/atoms/Icons';

/**
 * Calculate the amount of padding to apply to the X and Y axis of the icon.
 * Accepts a width and height and a padding percentage decimal.
 * @param {IconPlotInterface} options - The options for calculating the plot.
 * @returns
 */
const getPaddedPlot = ({ width, height, padding }: IconPlotInterface) => {
  const xPad = width * padding;
  const yPad = height * padding;
  return {
    xPad,
    yPad,
  };
};

/**
 * Takes a coordinate and relative padding, and returns the adjusted coordinate.
 * @param {IconPaddedPlotInterface} options - The options for calculating the padded plot.
 * @returns
 */
const getPaddedXY = ({
  x,
  y,
  xPad,
  yPad,
  inset = false,
}: IconPaddedPlotInterface) => {
  if (inset) {
    return { x: x - xPad, y: y - yPad };
  }
  return { x: x + xPad, y: y + yPad };
};

/**
 * Obtains the minimum and maximum X and Y coordinates based on the given
 * width, height and percentage padding.
 * @param {IconPlotInterface} options - The options for calculating the plot.
 * @returns
 */
export const getPlotExtremes = ({
  width,
  height,
  padding,
}: IconPlotInterface) => {
  const { xPad, yPad } = getPaddedPlot({ width, height, padding });
  const min = getPaddedXY({ x: 0, y: 0, xPad, yPad });
  const max = getPaddedXY({ x: width, y: height, inset: true, xPad, yPad });
  return { min, max };
};
