/**
 * Brand Colors
 */
export enum Brand {
  blue = '#4263EA',
  teal = '#00E0E1',
  darkTeal = '#03BABB',
  white = '#FFFFFF',
}

/**
 * Text Colors
 */
export enum Text {
  primary = '#9FA0AC',
  secondary = '#5B5C61',
  white = '#FFFFFF',
  black = '#272830',
  heading = '#0A1E70'
}

/**
 * State Colors
 */
export enum State {
  disabled = '#C3C4C9',
  hover = '#304FC8',
  success = '#27AE60',
  warning = '#F39E53',
  danger = '#EB5757'
}

/**
 * Background Colors
 */
export enum Background {
  bg1 = '#FFFFFF',
  bg2 = '#F5F5F9',
  bg3 = '#EEEDF3',
  bg4 = '#E1E1E5',
  blue = '#F0F3FE',
  teal = '#E0FBFB',
  darkBlue = '#DEE3F6',
  navy = '#CFD2E2',
  success = '#D0EDDB',
  warning = '#FCE8D6',
  danger = 'FAD7D7'
}

/**
 * Chart Colors
 */
export enum ChartColor {
  opaqueBlack = '#000000',
  darkGrey = '#272830',
}
