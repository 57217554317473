import React from 'react';
import { observer } from 'mobx-react';
import { TreeSelect } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { ReportEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';

const WysiwygVariableSelector = () => {
  const { reportTemplateStore } = useStore();

  const treeData = reportTemplateStore?.sortedTemplateVariables;

  const handleSelect = (item) => {
    const varType = ReportEnums.ReportVariables?.[item.replace('{', '').replace('}', '')];
    switch (varType) {
      case ReportEnums.VariableFormattingTypes.IMAGE:
        reportTemplateStore.injectImage(item);
        break;
      default:
        reportTemplateStore.insertVariable(item);
    }
  };

  return (
    <TreeSelect
      loading={reportTemplateStore.fetchingVariables}
      showSearch
      className='wysiwyg-custom-dropdown wysiwyg-custom-dropdown__variables'
      popupClassName='ant-tree-dropdown'
      onChange={handleSelect}
      treeExpandAction='click'
      treeData={treeData}
      placeholder='Insert Data Point'
      value={null}
      suffixIcon={<CaretDownFilled />}
    />
  );
};

export default observer(WysiwygVariableSelector);
