import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { ExclamationAlert } from '../../components-v2/atoms/Icons';
import { State } from '../../ts/enums/Colors';
import { useStore } from '../../stores/Store';
import CommentButton from '../comments/CommentButton';
import ClientListRedirect from '../dashboard/ClientListRedirect';
import { InsightTab } from '../../stores/v1/pageStore';
import { Routers } from '../../models/enums/utils';

const PeriodCloseDashboardTooltipTitle = ({ active = false, detail, period, checklistType, businessId, businessName, periodKey }) => {
  const { commentsStore, checklistStore } = useStore();
  const navigate = useNavigate();

  const handleLinkClick = (itemId) => {
    ClientListRedirect({ businessId, businessName }, InsightTab.dataCompliance);
    checklistStore.activeChecklistType = checklistType;
    checklistStore.activePeriod = periodKey;
    checklistStore.activeItem = itemId;
    navigate(Routers.INSIGHTS);
  };

  return (
    <div>
      <span className='tooltip-header'>
        {active ? (
          <ExclamationAlert
            className='compliance-checks__icon'
          />
        ) : (
          <ExclamationAlert
            className='compliance-checks__icon'
            fill={State.disabled}
          />
        )}
        {active ? 'Outstanding alerts & manual checks' : 'Alerts marked as reviewed'}
      </span>
      <div className='period-close__tooltip-wrapper'>
        {detail.length > 0 && detail.map((detailItem) => (
          <div className='button-comment-group' key={`period-close-comment-${active ? 'active' : 'reviewed'}-${detailItem.itemId}`}>
            <ButtonSecondary
              className='compliance-tooltip-button'
              key={`link-${detailItem.itemId}`}
              icon={<RightOutlined />}
              shape='default'
              onClick={() => handleLinkClick(detailItem.itemId)}
            >
              {detailItem.title}
            </ButtonSecondary>
            <div className='comment-count'>
              <CommentButton
                locationIdentifier={commentsStore.getLocationIdentifier(
                  checklistType,
                  period.periodStart,
                  period.periodEnd,
                  detailItem.itemId,
                  businessId
                )}
                title={detailItem.title}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PeriodCloseDashboardTooltipTitle;
