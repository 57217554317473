import { Form, Tooltip } from 'antd';
import React from 'react';
import { PlusOutlined, ToolOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';
import { trackMixpanelEvent } from '../lib/mixpanel';
import CreateRuleModal from './modals/RuleModal';
import ButtonSecondary from './customAntD/ButtonSecondary';
import handleError from '../lib/errorHandler';

export interface ICreateRuleButtonProps {
  businessId?: string;
  ruleId?: string;
  checklistId: string;
  sectionId: string;
  selectedPeriod?: string;
  allowKeywords?: boolean;
}

const CreateRuleButton = ({
  businessId,
  ruleId,
  checklistId,
  sectionId,
  selectedPeriod,
  allowKeywords,
}: ICreateRuleButtonProps) => {
  const newRule = !ruleId;
  const rootStore = useStore();
  const { businessesStore, localeStore, practiceStore } = rootStore;
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  return (
    <Tooltip
      title={newRule ? localeStore.translation('custom-rules.new-rule-button.tooltip') : localeStore.translation('custom-rules.edit-rule-button.tooltip')}
      placement='bottom'
      open={tooltipVisible}
      onOpenChange={setTooltipVisible}
    >
      <ButtonSecondary
        loading={buttonLoading}
        disabled={buttonLoading}
        type='primary'
        ghost
        shape={newRule ? 'round' : 'circle'}
        icon={newRule ? <PlusOutlined /> : <ToolOutlined />}
        className={`rule-button ${newRule ? 'rule-button__new' : 'rule-button__edit'}`}
        onClick={async () => {
          setTooltipVisible(false);
          try {
            setButtonLoading(true);
            const useBusinessId = businessId || businessesStore.selectedBusiness?.id;
            const business = businessesStore.businesses.get(useBusinessId);

            if (businessId !== practiceStore.id) {
              if (!business?.chartOfAccounts || business?.chartOfAccounts.length < 1) {
                await businessesStore.getChartOfAccounts(useBusinessId);
              }
            } else {
              // This is for the practice checklist
              practiceStore.chartOfAccounts();
            }

            trackMixpanelEvent({
              description: `${newRule ? 'New' : 'Edit'} Rule`,
              rootStore,
            });

            CreateRuleModal({
              businessId: useBusinessId,
              ruleId,
              checklistId,
              sectionId,
              selectedPeriod,
              rootStore,
              form,
              allowKeywords,
            });
          } catch (error) {
            handleError({ error, status: 'unknown_error', transaction: 'Create Rule', operation: 'handleClick' });
          }
          setButtonLoading(false);
        }}
      >
        {newRule && localeStore.translation('custom-rules.new-rule-button.label')}
      </ButtonSecondary>
    </Tooltip>
  );
};

CreateRuleButton.defaultProps = {
  ruleId: null,
  rule: null,
  businessId: undefined
};

export default observer(CreateRuleButton);
