import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

interface WarningTriangleProps extends Partial<CustomIconComponentProps> {
  iconFill?: string;
}

const warningTriangleSvg = (iconFill: string) => (
  <svg height='1em' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.0517 2.41841L0.323458 19.6401C0.122884 20.0084 0.0167571 20.426 0.015634 20.8513C0.0145109 21.2767 0.118431 21.6949 0.317056 22.0644C0.515681 22.4339 0.802087 22.7418 1.14778 22.9575C1.49347 23.1732 1.8864 23.2892 2.28748 23.2939H21.7439C22.145 23.2892 22.5379 23.1732 22.8836 22.9575C23.2293 22.7418 23.5157 22.4339 23.7143 22.0644C23.9129 21.6949 24.0169 21.2767 24.0157 20.8513C24.0146 20.426 23.9085 20.0084 23.7079 19.6401L13.9797 2.41841C13.7749 2.06047 13.4867 1.76453 13.1426 1.55914C12.7986 1.35375 12.4105 1.24585 12.0157 1.24585C11.6209 1.24585 11.2328 1.35375 10.8887 1.55914C10.5447 1.76453 10.2564 2.06047 10.0517 2.41841V2.41841Z' fill={iconFill} />
    <path d='M12.0112 8.43481V13.2698' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.0112 18.1047H12.0196' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

/**
 * WarningTriangle icon
 * Icon in the shape of a triangle, with an exclamation mark in the middle
 * @param {Partial<CustomIconComponentProps>} props
 */
const WarningTriangle = ({ iconFill, ...props }: WarningTriangleProps) => (
  <Icon
    component={() => warningTriangleSvg(iconFill)}
    {...props}
    aria-label='logo'
  />
);

WarningTriangle.defaultProps = {
  iconFill: 'currentColor',
};

export default WarningTriangle;
