import * as React from 'react';
import { rootStore } from '../../stores/Store';
import { ChartColor } from '../../ts/enums/Colors';
import { ChartFontSize } from '../../ts/enums/Constants';
import formatNumber from '../atoms/FormatNumber';
import { barArrayF, borderArrayF } from '../atoms/GraphSettings';
import {
  renderTooltipActionItems,
  renderTooltipActionItemsFromResponse,
} from '../atoms/TooltipActionItem';
import ChartWrapper from './ChartWrapper';
// eslint-disable-next-line import/no-named-as-default
import yAxisFormat from '../../utils/yAxisFormat';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
function SGBarChart({ insightData, index, hoverIndex, chartId }) {
  // eslint-disable-next-line react/prop-types
  const dataSource = insightData.graph.data[0].datasets.map((ds, i) => {
    const newDS = { ...ds };
    newDS.barPercentage = 0.89;
    newDS.categoryPercentage = 0.39;
    newDS.borderRadius = 6;
    newDS.borderWidth = 2;
    newDS.backgroundColor = barArrayF[i];
    newDS.borderColor = borderArrayF[i];
    return newDS;
  });
  // eslint-disable-next-line react/prop-types
  const labels = insightData.graph.data[0].xLabels;
  const annotationObject = {
    zero: {
      // Indicates the type of annotation
      type: 'line',
      yMin: 0,
      yMax: 0,
      borderColor: 'rgba(159,160,172,0.67)',
    },
    box1: undefined,
  };
  // eslint-disable-next-line react/prop-types
  const { title, units, isCurrency } = insightData.graph.yAxis;

  // eslint-disable-next-line react/prop-types
  if (insightData.graph.yAxis.annotations) {
    annotationObject.box1 = {
      // Indicates the type of annotation
      type: 'line',
      // eslint-disable-next-line react/prop-types
      yMin: insightData.graph.yAxis.annotations[0].rawValue,
      // eslint-disable-next-line react/prop-types
      yMax: insightData.graph.yAxis.annotations[0].rawValue,
      borderColor: 'rgba(159,160,172,0.67)',
      borderDash: [4, 3],
      label: {
        // eslint-disable-next-line react/prop-types
        content: insightData.graph.yAxis.annotations[0].label,
        enabled: true,
        position: 'end',
        backgroundColor: 'rgba(159,160,172,0.67)',
        color: '#505050',
      },
    };
  }

  return (
    // @ts-ignore
    <ChartWrapper
      // @ts-ignore
      setChartHandle={(chart, id) => rootStore.actionStore.setChartObject(chart, id)}
      setB64Image={(id, chart) => rootStore.actionStore.setChartImage(chart, id)}
      // eslint-disable-next-line react/prop-types
      id={`${insightData.insightKey}`}
      chartId={chartId}
      type='bar'
      data={{
        xLabels: labels,
        datasets: dataSource,
      }}
      options={{
        responsive: true,
        animation: true,
        aspectRatio: 1.5,
        plugins: {
          annotation: {
            annotations: annotationObject,
          },
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 20,
              boxHeight: 20,
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
          },
          tooltip: {
            callbacks: {
              label(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                // TODO: Fix this. Format should be provided by the back end
                // rather than front end rendering.
                if (context.parsed.y !== null) {
                  // Bandaid fix to present the correct number of decimal places
                  label += `$${Math.round(context.parsed.y).toLocaleString()}`;
                } else {
                  return null;
                }
                return label;
              },
              footer: (contextUnresolved) => {
                const context = contextUnresolved[0];
                // eslint-disable-next-line react/prop-types
                if (insightData.summaries[0].tooltips) {
                  return renderTooltipActionItemsFromResponse(
                    context,
                    insightData
                  );
                }
                // eslint-disable-next-line react/prop-types
                if (insightData.summaries[context.dataIndex]) {
                  return renderTooltipActionItems(context, insightData);
                }

                return '';
              },
            },
            backgroundColor: ChartColor.opaqueBlack,
            titleFont: {
              size: ChartFontSize.defaultSize,
            },
            bodyFont: {
              size: ChartFontSize.defaultSize,
            },
            footerFont: {
              size: ChartFontSize.defaultSize,
            },
          },
        },

        scales: {
          x: {
            ticks: {
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            stacked: true,
            title: {
              display: true,
              // eslint-disable-next-line react/prop-types
              text: insightData.graph.xAxis.title,
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              callback(value, inx, values) {
                // eslint-disable-next-line react/prop-types
                return formatNumber(value, insightData.graph.yAxis.units);
              },
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            title: {
              display: true,
              text: yAxisFormat(title, units, isCurrency),
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            grid: {
              display: true,
              borderDash: [1, 2],
              color: '#d5d8e3',
            },
            stacked: true,
          },
        },
      }}
    />
  );
}

export default SGBarChart;
