import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Table, Tooltip } from 'antd';
import { ColumnGroupType, ColumnsType, ColumnType } from 'antd/es/table';
import Search from 'antd/es/input/Search';
import { CaretDownFilled, CaretUpFilled, PlusOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import { useNavigate } from 'react-router-dom';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { useStore } from '../../stores/Store';
import { DashboardSortDirection, DashboardSortTarget } from '../../models/enums/components';
import PeriodCloseTableNameSort from './PeriodCloseTableNameSort';
import PeriodCloseDataHeaderRender from './PeriodCloseDataHeaderRender';
import PeriodCloseStatusCell from './PeriodCloseStatusCell';
import PeriodCloseDataCellRender from './PeriodCloseDataCellRender';
import PeriodCloseDashboardNameCell from './PeriodCloseDashboardNameCell';
import { Routers } from '../../models/enums/utils';
import PeriodCloseDataHeaderGroupRender from './PeriodCloseDataHeaderGroupRender';
import { InsightTab } from '../../stores/v1/pageStore';
import ClientListRedirect from '../dashboard/ClientListRedirect';

const PeriodCloseDashboardTable = () => {
  const { businessesStore, checklistStore, periodCloseDashboardStore, practiceStore } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const { CategoryKeys } = ComplianceChecklistEnums;
  const navigate = useNavigate();

  const usPractice = practiceStore?.countryCode?.toLowerCase() === 'us';

  const handleSort = (
    column: ComplianceChecklistEnums.CategoryKeys,
    target: DashboardSortTarget,
    direction: DashboardSortDirection
  ) => {
    periodCloseDashboardStore.activeSort = {
      column,
      target,
      direction
    };
  };

  const onImportButtonClick = async () => {
    setLoading(true);
    navigate(Routers.CLIENTS_MANAGEMENT);
  };

  const handleKeyCellClick = (record) => {
    ClientListRedirect({ businessId: record.businessId, businessName: record.businessName }, InsightTab.dataCompliance);
    checklistStore.activeChecklistType = record.checklistType;
    checklistStore.activePeriod = record.period_key;
    navigate('/insights');
  };

  const columns: ColumnsType<any> = [
    {
      align: 'left',
      width: 300,
      title: (
        <div className='client-header'>
          <Tooltip
            placement='bottom'
            title={`${periodCloseDashboardStore.sortedDashboard.length} out of ${businessesStore.clientBusinesses?.size} imported clients shown`}
          >
            <span className='client-count'>{periodCloseDashboardStore.sortedDashboard.length} Clients</span>
          </Tooltip>
          <Tooltip
            placement='right'
            title='Import / Activate more clients'
          >
            <ButtonSecondary
              className='import-button'
              loading={loading}
              icon={<PlusOutlined />}
              onClick={onImportButtonClick}
              mixpanelEvent='Period Close Dashboard: Add Clients button Clicked'
            >
              <span className='import-text'>Add Clients</span>
            </ButtonSecondary>
          </Tooltip>
        </div>
      ),
      className: 'aider-column',
      dataIndex: 'name',
      children: [
        {
          className: 'aider-column aider-column__linked',
          fixed: 'left',
          title: (
            <div className='business-name'>
              <Search
                className='business-search'
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => { periodCloseDashboardStore.activeSearchFilter = e.currentTarget.value; }}
                placeholder='Search by name'
                allowClear
              />
              <Tooltip
                placement='bottomLeft'
                title={(
                  <PeriodCloseTableNameSort
                    activeOrder={periodCloseDashboardStore.sortDirection}
                    activeTarget={periodCloseDashboardStore.sortTarget}
                    setSort={handleSort}
                  />
                )}
              >
                {periodCloseDashboardStore.sortColumn === 'name'
                  && periodCloseDashboardStore.sortTarget === DashboardSortTarget.name
                  && periodCloseDashboardStore.sortDirection === DashboardSortDirection.ascending
                  && (<SortAscendingOutlined className='column-sort active' />)}
                {periodCloseDashboardStore.sortColumn === 'name'
                  && periodCloseDashboardStore.sortTarget === DashboardSortTarget.name
                  && periodCloseDashboardStore.sortDirection === DashboardSortDirection.descending
                  && (<SortDescendingOutlined className='column-sort active' />)}
                {periodCloseDashboardStore.sortColumn === 'name'
                  && periodCloseDashboardStore.sortTarget === DashboardSortTarget.updated
                  && periodCloseDashboardStore.sortDirection === DashboardSortDirection.ascending
                  && (
                    <div className='dashboard-subheading__sorter'>
                      <CaretUpFilled className='active' />
                      <CaretDownFilled />
                    </div>
                  )}
                {periodCloseDashboardStore.sortColumn === 'name'
                  && periodCloseDashboardStore.sortTarget === DashboardSortTarget.updated
                  && periodCloseDashboardStore.sortDirection === DashboardSortDirection.descending
                  && (
                    <div className='dashboard-subheading__sorter'>
                      <CaretUpFilled />
                      <CaretDownFilled className='active' />
                    </div>
                  )}
                {periodCloseDashboardStore.sortColumn !== 'name' && (
                  <div className='dashboard-subheading__sorter'>
                    <CaretUpFilled />
                    <CaretDownFilled />
                  </div>
                )}
              </Tooltip>
            </div>
          ),
          dataIndex: 'businessName',
          defaultSortOrder: 'ascend',
          width: 300,
          render: (name, record) => <PeriodCloseDashboardNameCell name={name} record={record} />,
          onCell: (record) => ({
            onClick: () => handleKeyCellClick(record),
          })
        },
      ]
    },
    {
      align: 'left',
      title: (<PeriodCloseDataHeaderGroupRender />),
      children: [{
        dataIndex: 'checklistStatus', // TODO: create enums for data keys to reference data indexes
        className: 'aider-column__linked',
        width: 250,
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column='checklistStatus'
            title='Checklist Status'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        render: PeriodCloseStatusCell,
        onCell: (record) => ({
          onClick: () => handleKeyCellClick(record),
        })
      },
      {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.bankReconciliation}
            title='Bank Reconciliation'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: CategoryKeys.bankReconciliation,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      },
      {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.invoices}
            title='Invoices'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: ComplianceChecklistEnums.CategoryKeys.invoices,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      },
      {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.bills}
            title='Bills'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: ComplianceChecklistEnums.CategoryKeys.bills,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      },
      {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.gst}
            title='GST'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: ComplianceChecklistEnums.CategoryKeys.gst,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      },
      (!usPractice ? {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.payroll}
            title='Payroll'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: ComplianceChecklistEnums.CategoryKeys.payroll,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      } : null),
      {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.profitAndLoss}
            title='Profit and Loss'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: ComplianceChecklistEnums.CategoryKeys.profitAndLoss,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      },
      {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.balanceSheet}
            title='Balance Sheet'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: ComplianceChecklistEnums.CategoryKeys.balanceSheet,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      },
      {
        title: (
          <PeriodCloseDataHeaderRender
            sortDirection={periodCloseDashboardStore.sortDirection}
            setSort={handleSort}
            column={CategoryKeys.additionalChecks}
            title='Additional Checks'
            sortTarget={periodCloseDashboardStore.sortTarget}
            sortColumn={periodCloseDashboardStore.sortColumn}
          />
        ),
        width: 200,
        dataIndex: ComplianceChecklistEnums.CategoryKeys.additionalChecks,
        render: PeriodCloseDataCellRender,
        className: 'period-close__body-cell'
      },
      ].filter((item) => (item))
    },
  ];

  if (usPractice) {
    const index = (columns as ColumnGroupType<any>[])[1].children.indexOf(
      (columns as ColumnGroupType<any>[])[1].children.find(
        (column) => (column as ColumnType<any>).dataIndex
          === ComplianceChecklistEnums.CategoryKeys.gst
      )
    );
    (columns as ColumnGroupType<any>[])[1].children.splice(index, 1);
  }

  useEffect(() => {
    const wrapperCont = document.querySelector('.period-close__body') as HTMLElement;
    if (!wrapperCont) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      const paginationCont = document.querySelector('.ant-pagination') as HTMLElement;
      const offset = paginationCont ? 192 : 130;
      setContainerHeight(wrapperCont.offsetHeight - offset);
    });
    resizeObserver.observe(wrapperCont);
    return () => resizeObserver.disconnect();
  }, [periodCloseDashboardStore.sortedDashboard.length]);

  return (
    periodCloseDashboardStore.sortedDashboard && (
      <Table
        loading={periodCloseDashboardStore.rowsLoading}
        sticky
        dataSource={periodCloseDashboardStore.sortedDashboard}
        columns={columns}
        pagination={{
          className: 'aider-pagination',
          position: ['topLeft'],
          defaultPageSize: 20,
          showSizeChanger: false,
          hideOnSinglePage: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} clients shown`,
        }}
        scroll={{ x: '100svw', y: containerHeight }}
        showSorterTooltip={false}
      />
    )
  );
};

export default observer(PeriodCloseDashboardTable);
