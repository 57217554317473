import React from 'react';

const ActivateClientCommonContent = ({ newConnectionCount }) => (
  <>
    <h2>
      {newConnectionCount > 0 && newConnectionCount} Xero client
      {newConnectionCount > 1 ? 's' : ''} added
    </h2>
  </>
);
export default ActivateClientCommonContent;
