import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
  <svg
    width='1em'
    viewBox='0 0 33 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    strokeWidth={1.5}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path
      d='M8.70312 22.6784V24.8761C8.70312 25.6678 9.34494 26.3096 10.1367 26.3096H10.7303C11.522 26.3096 12.1638 25.6678 12.1638 24.8761V22.6784C12.1638 21.8867 11.522 21.2449 10.7303 21.2449H10.1367C9.34494 21.2449 8.70312 21.8867 8.70312 22.6784Z'
      fill='currentColor'
    />
    <path
      d='M14.4297 19.6788V24.8761C14.4297 25.6678 15.0715 26.3096 15.8632 26.3096H16.4568C17.2485 26.3096 17.8903 25.6678 17.8903 24.8761V19.6788C17.8903 18.8871 17.2485 18.2453 16.4568 18.2453H15.8632C15.0715 18.2453 14.4297 18.8871 14.4297 19.6788Z'
      fill='currentColor'
    />
    <path
      d='M20.1602 15.7066V24.8761C20.1602 25.6678 20.8019 26.3096 21.5936 26.3096H22.1873C22.979 26.3096 23.6208 25.6678 23.6208 24.8761V15.7066C23.6208 14.9149 22.979 14.2731 22.1873 14.2731H21.5936C20.8019 14.2731 20.1602 14.9149 20.1602 15.7066Z'
      fill='currentColor'
    />
    <path
      d='M22.1344 1.3335H4.76172V30.6668H27.9189V7.11809L22.1344 1.3335Z'
      stroke='currentColor'
    />
    <path
      d='M22.1602 7.0928H27.9195L22.1602 1.3335V7.0928Z'
      stroke='currentColor'
    />
  </svg>

);

const NonSalesTaxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={Svg}
    {...props}
    aria-label='insights'
  />
);

export default NonSalesTaxIcon;
