import cookie from 'js-cookie';
import { Routers } from '../models/enums/utils';
import type { RootStore } from '../stores/Store';
import { LoginErrorType } from '../stores/authenticationStore';
import { CookieKeys } from '../ts/enums/Constants';

const queryStringInspector = (rootStore: RootStore, navigate) => {
  const {
    authStore,
    connectionsStore,
    userStore,
    businessStore,
    businessesStore,
    loadingStore,
    subscriptionStore,
    invitationStore,
    authenticationStore,
    pageStore,
  } = rootStore;

  let queryParams = new URLSearchParams(window.location.search);

  const hasLoggedIn = typeof cookie.get(CookieKeys.userHasLoggedIn) !== 'undefined';
  const invitedAdvisor = cookie.get(CookieKeys.inviteAdvisors) === 'true';
  const unclaimedConnection = cookie.get(CookieKeys.unclaimedConnection);
  const isRedirecting = queryParams.get(CookieKeys.connectionId);
  const isInvitation = queryParams.get(CookieKeys.inviteToken) || cookie.get(CookieKeys.inviteToken);
  const isConnect = cookie.get(CookieKeys.appConnect);

  if (isConnect && window.location.pathname !== Routers.ONBOARDING) {
    loadingStore.setLoading('appReferral');
  }

  loadingStore.setLoading('queryParams');
  if (
    (!hasLoggedIn
      && !authStore.user
      && !authStore.loading
      && !invitedAdvisor
      && !isRedirecting
      && !unclaimedConnection)
    || isInvitation
  ) {
    if (isInvitation) {
      cookie.remove(CookieKeys.prevDestination);
      queryParams.delete('inviteToken');
      cookie.set(CookieKeys.prevQueryString, `?${queryParams.toString()}`);
      invitationStore.inviteToken = isInvitation;
      cookie.set(CookieKeys.inviteToken, isInvitation);
      navigate(Routers.INVITATION);
    } else {
      if (window.location.pathname !== Routers.INVITATION
        && window.location.pathname !== Routers.INVITE_ACCEPT
        && window.location.pathname !== Routers.LOGIN
        && window.location.pathname !== Routers.SIGNUP
        && cookie.get(CookieKeys.prevDestination) !== window.location.pathname
      ) {
        cookie.set(CookieKeys.prevDestination, window.location.pathname);
      }
      const stringedQueryParams = `?${queryParams.toString()}`;
      if (cookie.get(CookieKeys.prevQueryString) !== stringedQueryParams && stringedQueryParams !== '?') {
        cookie.set(CookieKeys.prevQueryString, stringedQueryParams);
      }

      if (window.location.pathname !== Routers.LOGIN && window.location.pathname !== Routers.SIGNUP) {
        navigate(Routers.LOGIN);
      }
    }
    loadingStore.setDoneLoading('queryParams');
  } else {
    const redirect = cookie.get(CookieKeys.redirect);
    const prevQueryString = cookie.get(CookieKeys.prevQueryString);
    const prevDestination = cookie.get(CookieKeys.prevDestination);
    const initialConnection = cookie.get(CookieKeys.initialConnection);
    connectionsStore.initialConnection = initialConnection === 'true';
    cookie.remove(CookieKeys.initialConnection);

    if (redirect) {
      cookie.remove(CookieKeys.redirect);
      loadingStore.setLoading('auth');
    }

    if (prevDestination) {
      cookie.remove(CookieKeys.prevDestination);
      loadingStore.setDoneLoading('queryParams');
      navigate(prevDestination);
    }

    if (prevQueryString && !prevDestination) {
      queryParams = new URLSearchParams(prevQueryString);
      cookie.remove(CookieKeys.prevQueryString);
    }

    // Handle result param. This is returned as error from xero when no connection is made
    const result = queryParams.get('result');
    if (result) {
      connectionsStore.addError = result === 'error';
      queryParams.delete('result');
    }

    const error = queryParams.get('error');
    if (error) {
      authenticationStore.loginError = !!error;
      authenticationStore.loginErrorType = error === 'unverified_email' ? LoginErrorType.EMAIL_NOT_VERIFIED : null;
      queryParams.delete('error');

      // Logout the user
      authStore.handleSignOut(true)
        .then(() => {
          loadingStore.loading = [];
          pageStore.navigateTo = Routers.SIGNUP;
        });
    }

    // Assigned in partnership to result param. This is returned as error from xero when no connection is made
    const reason = queryParams.get('reason');
    if (reason) queryParams.delete('reason');

    // Handle action param. This can be used to trigger actions on page load
    const action = queryParams.get('action');
    if (action) {
      switch (action) {
        case 'unsubscribe':
          userStore.requiresUnsubscribe = true;
          break;
        case 'stripeCheckout':
          subscriptionStore.requiresPaywallRedirect = true;
          break;
        default:
          break;
      }
      queryParams.delete('action');
    }

    // Handle selectedClient param. This can be used to set the selected client on page load
    const selectedClient = queryParams.get('selectedClient');
    if (selectedClient) {
      businessStore.setSelectedBusinessId(selectedClient);
      businessesStore.selectedBusinessId = selectedClient;
      queryParams.delete('selectedClient');
    }
    let newPath = window.location.pathname;
    const stringifiedQueryParams = queryParams.toString();
    if (stringifiedQueryParams.length > 0) {
      newPath += `?${stringifiedQueryParams}`;
    }

    loadingStore.setDoneLoading('queryParams');
    window.history.replaceState(
      {},
      '',
      newPath
    );
  }
};

export default queryStringInspector;
