import { ApiConstants } from '@aider/constants-library';
import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import type { RootStore } from './Store';
import { GET } from '../lib/requests';
import handleError from '../lib/errorHandler';

export enum SummaryType {
  fy = 'financialYear',
  gst = 'gst',
}

export interface ClientSummary {
  businessId: string;
  type: string;
  activity: {
    activity: string;
    current: any;
    last: any;
    previous: any;
  }[];
}

export default class ClientSummaryStore {
  rootStore: RootStore;

  _summaryType: SummaryType = SummaryType.fy;

  summary: ClientSummary;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }

  get summaryType() {
    return this._summaryType;
  }

  set summaryType(type: SummaryType) {
    this._summaryType = type;
  }

  async fetchSummaryData() {
    try {
      const sentryTransaction = Sentry.startTransaction({
        name: 'Get Client Summary Data',
      });
      const url = `${ApiConstants.apiEndpointsBase.insights}/businesses/${this.rootStore.businessesStore.selectedBusinessId}/summary/${this.summaryType}`;
      const res = await GET({
        url,
        rootStore: this.rootStore,
        sentryTransaction,
      });
      sentryTransaction.finish();
      this.summary = res;
    } catch (err) {
      handleError({ error: err, transaction: 'get client summary data' });
    }
  }
}
