import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import StandardModal from './wrappers/StandardModal';
import Comments from './content/Comments';

const CommentsModal = ({ rootStore, form, locationIdentifier, title }) => {
  const ModalProps = {
    name: 'Comments Modal',
    rootStore,
    width: ModalWidths.LARGE,
    okText: 'Close',
    cancelButtonProps: { className: 'button-secondary btn-hidden' },
    okButtonProps: { className: 'button-secondary btn-hidden' },
    title: <><MessageOutlined /> {title}</>,
    footer: null,
    centered: false,
    content: (
      <Comments
        locationIdentifier={locationIdentifier}
        form={form}
      />
    ),
  };

  NiceModal.register('CreateRuleModal', StandardModal);
  return NiceModal.show('CreateRuleModal', ModalProps);
};

export default CommentsModal;
