import * as React from 'react';
import { ITextPillProps } from '../models/interfaces/components';

/**
 * A simple pill icon with text
 *
 * @param type - The type of pill to render
 * @param size - The size of the pill text (small = 0.5em, medium = 0.8em, large = 1em)
 * @param children - The text to render in the pill
 * @returns
 */
const TextPill = ({
  type,
  size,
  children
}:ITextPillProps) => (
  <div
    className={`text-pill text-pill__${type} text-pill__${size}`}
  >
    {children}
  </div>
);

TextPill.defaultProps = {
  type: 'secondary',
  size: 'medium'
};

export default TextPill;
