import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import AddClientModalPending from './content/AddClientModalPending';
import AddClientModalError from './content/AddClientModalError';
import StandardModal from './wrappers/StandardModal';
/**
 * The modal for the add client functionality
 */
const AddClientModalFollowup = ({
  onOk,
  error,
}: {
  onOk: any;
  error?: boolean;
}) => {
  let content = <AddClientModalPending />;
  if (error) {
    content = <AddClientModalError />;
  }
  const ModalProps = {
    name: 'Add Client Modal - Followup',
    onOk,
    afterClose: onOk,
    okText: error ? 'Close' : "Let's Go!",
    className: 'add-client-modal',
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      danger: true,
      shape: 'round',
      size: 'large',
      className: 'button-secondary btn-hidden',
    },
    width: ModalWidths.MEDIUM,
    content,
  };
  NiceModal.register('AddClientModalFollowup', StandardModal);
  return NiceModal.show('AddClientModalFollowup', ModalProps);
};
export default AddClientModalFollowup;
