import { makeAutoObservable } from 'mobx';
import { ApiConstants } from '@aider/constants-library';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import type { RootStore } from './Store';
import { GET } from '../lib/requests';
import {
  IBusinessDashboardMap,
  IDashboardRow,
} from '../models/interfaces/stores';
import { IDashboardColumnsType } from '../models/interfaces/components';
import handleError from '../lib/errorHandler';

/**
 * Dashboard Store
 * This store is responsible for retrieving and managing the dashboard data
 * It is also responsible for managing the dashboard filters
 *
 * It performs filtering and searching on all data retrieved from the back end,
 * to provide a seamless experience for the user, while the backend searches and filters
 * all businesses, not just the ones provided.
 */
class DashboardStore {
  rootStore: RootStore;

  /** ********************************************
   * OBSERVABLES
   ********************************************* */

  /* A flag to indicate that the businesses have been retrieved from the BE */
  retrievedBusinesses: boolean = false;

  /* A flag to indicate that loading businesses have been generated */
  loadingBusinessesGenerated: boolean = false;

  /** Dashboard rows for businesses retrieved */
  businesses: IBusinessDashboardMap;

  /** Number of businesses loaded, plus loading businesses */
  businessStoreClientBusinessCount: number = 0;

  /** Number of pages of data available */
  pagesTotal: number = 1;

  /** Number of businesses to load per page */
  pageSize: number = 100;

  /** Dashboard categories (parent columns) */
  categories = [];

  /** Array showing the order of columns from left to right
   * across all categories
   */
  columnOrder = [];

  /** Current page of data */
  activePage: number = 1;

  /** Current sort key */
  activeSort: string = 'client:value.asc';

  /** Current Tag Filters */
  activeTagFilter: string[] = [];

  /** Current Industry Filters */
  activeIndustryFilter: string[] = [];

  /** Current Search String */
  activeSearchString: string = '';

  /** Flag tracking if the dashboard is loading data */
  loading: boolean = false;

  /** Flag tracking if the dashboard is loading more data */
  loadingMore: boolean = false;

  /** List of columns that depend on bank reconciliation values */
  bankRecDependantCols: string[] = ['revenue', 'netProfit', 'incometax'];

  /** A timeout id used for debouncing API calls */
  timeoutId: any;

  /** Flag for tracking if the dashboard has loaded at least once */
  initialLoadComplete: boolean = false;

  sorting = false;

  totalBusinessCount: number = 0;

  /** ********************************************
   * COMPUTED
   ********************************************* */

  get newBaseIndex() {
    if (this.businesses.size === 0) return 0;
    return [...this.businesses.values()].reduce((a, e) => ((e.order > a.order) ? e : a)).order + 1;
  }

  /** Returns true if there are more pages */
  get hasMorePages() {
    return this.activePage < this.pagesTotal;
  }

  /** Extracts the sort target column from the sort key */
  get sortColumn() {
    return this.activeSort?.split(':')?.[0];
  }

  /** Extracts the sort direction from the sort key */
  get sortDirection() {
    return this.activeSort?.split('.')?.[1];
  }

  /**
   * Extracts the loading businesses from the client businesses
   * to be displayed in the dashboard skeleton
   */
  get loadingBusinesses() {
    const loadingBusinesses = new Map();
    let inx = this.newBaseIndex;
    if (this.retrievedBusinesses) {
      this.rootStore.businessesStore.activeClientBusinesses.forEach((val, key) => {
        if (
          (this.businesses.size === 0
          || (this.businesses?.size > 0 && !this.businesses.has(key)))
          && !this.hasMorePages
        ) {
          let include = true;
          if (this.activeTagFilter) {
            include = include && this.activeTagFilter.every((tag) => val?.tags?.includes(tag));
          }
          if (this.activeIndustryFilter) {
            this.activeIndustryFilter.forEach((industry) => {
              if (!val?.lineOfBusiness?.includes(industry)) {
                include = false;
              }
            });
          }
          if (this.activeSearchString) {
            if (!val.name.toLowerCase().includes(this.activeSearchString.toLowerCase())) {
              include = false;
            }
          }

          if (include) {
            _.set(val, 'businessId', val.id);
            _.set(val, 'businessName', val.name);
            _.set(val, 'loading', true);
            _.set(val, 'order', inx);
            _.remove(val, 'id');
            _.remove(val, 'name');

            loadingBusinesses.set(key, val);
            inx += 1;
          }
        }
      });
      this.loadingBusinessesGenerated = true;
    }
    return loadingBusinesses;
  }

  /** Computes the column order to access dashboard data when building rows */
  get determineColumnOrder() {
    const columnOrder = [];
    this.columns.forEach((column) => {
      if (!column.children) {
        if (!columnOrder.includes(column.key)) {
          columnOrder.push(column.key);
        }
      } else {
        column.children.forEach((child) => {
          if (!columnOrder.includes(child.key)) {
            columnOrder.push(child.key);
          }
        });
      }
    });
    return columnOrder;
  }

  /**
   * Returns the dashboard columns
   */
  get columns() {
    const columns: IDashboardColumnsType[] = [];

    if (this.categories) {
      this.categories.forEach((category) => {
        if (!columns.find((column) => column.key === category.key)) {
          columns.push({
            title: category.title,
            dataIndex: category.key,
            key: category.key,
            children: [],
          });
        }
      });

      this.businesses.forEach((business) => {
        if (!business.loading && business?.data?.length > 0) {
          business.data.forEach((insight) => {
            const groupInx = columns.findIndex(
              (column) => column.key === insight.category
            );
            if (groupInx !== -1) {
              if (
                !columns[groupInx].children.find(
                  (child) => child.key === insight.key
                )
              ) {
                columns[groupInx].children.push({
                  title: insight.title,
                  dataIndex: insight.key,
                  key: insight.key,
                });
              }
            }
          });
        }
      });

      const orderedColumns = columns.map((column) => {
        if (!column.children) {
          return column;
        }
        const newColumn = { ...column };
        newColumn.children = column?.children?.slice().sort((a, b) => {
          const aIndex = this.columnOrder?.findIndex(
            (columnKey) => columnKey === a.key
          );
          const bIndex = this.columnOrder?.findIndex(
            (columnKey) => columnKey === b.key
          );
          return aIndex - bIndex;
        });
        return newColumn;
      });
      return orderedColumns;
    }
    return columns;
  }

  /**
   * Returns the dashboard rows
   */
  get rows(): IDashboardRow[] {
    const rows = [];
    this.businesses.forEach((business) => {
      const data = {
        id: business?.businessId,
      };
      rows.push(data);
    });

    return rows;
  }

  columnPosition(colKey: string) {
    const positions = { first: false, last: false };
    if (!colKey) return { first: true, last: true };
    this.columns.forEach((col) => {
      const inx = col.children.findIndex((child) => child.key === colKey);
      if (inx === 0) {
        positions.first = true;
      }

      if (inx === col.children.length - 1) {
        positions.last = true;
      }
    });
    return positions;
  }

  businessRow(businessId: string) {
    const business = this.businesses.get(businessId);
    const columns = {};
    if (business?.data?.length > 0) {
      business.data.forEach((insight) => {
        columns[insight.key] = insight;
        const groupInx = this.columns.findIndex(
          (column) => column.key === insight.category
        );
        if (groupInx !== -1) {
          if (
            !this.columns[groupInx].children.find(
              (child) => child.key === insight.key
            )
          ) {
            this.columns[groupInx].children.push({
              title: insight.title,
              dataIndex: insight.key,
              key: insight.key,
            });
          }
        }
      });
    }
    return columns;
  }

  get allBusinessesWatched():boolean {
    if (!this.retrievedBusinesses) return false;

    let allBusinessesWatched = true;
    if (this.businesses?.size > 0) {
      this.businesses.forEach((business) => {
        if (business.lastUpdated === null) {
          allBusinessesWatched = false;
        }
      });
    }
    return allBusinessesWatched;
  }

  get hasValidRequest(): boolean {
    return !!this.rootStore?.practiceStore?.id;
  }

  /** ********************************************
   * ACTIONS
   ********************************************* */

  /**
   * Performs page navigation;
   * Updates active page number and appends dashboard data
   * @param pageNumber
   */
  async navigatePage(pageNumber) {
    this.activePage = pageNumber;
    await this.appendDashboard();
    return this.businesses;
  }

  /**
   * Performs dashboard sorting
   * Updates active sort and initialises dashboard data
   * @param sortBy
   */
  async sort(sortBy) {
    this.activeSort = sortBy;
    await this.initialiseDashboard(true);
    return this.businesses;
  }

  /**
   * Retrieves the dashboard data from the API
   * @returns
   */
  async retrieveDashboardRows(pageSizeOverride?: number, pageNumber?: number) {
    if (!this.hasValidRequest) {
      return null;
    }

    const transaction = Sentry.startTransaction({
      name: 'Get Dashboard Data',
    });

    const url = `${ApiConstants.apiEndpointsBase.overview}/${
      this.rootStore.practiceStore.id
    }/dashboard?pageNumber=${pageNumber || this.activePage}&pageSize=${
      pageSizeOverride > this.pageSize ? pageSizeOverride : this.pageSize
    }${this.activeSort ? `&sortBy=${this.activeSort}` : ''}${
      this.activeTagFilter.length > 0
        ? `&tags=${encodeURIComponent(this.activeTagFilter.join(','))}`
        : ''
    }${
      this.activeIndustryFilter.length > 0
        ? `&lob=${encodeURIComponent(this.activeIndustryFilter.join(','))}`
        : ''
    }${
      this.activeSearchString
        ? `&search=${encodeURIComponent(this.activeSearchString)}`
        : ''
    }`;

    return GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction: transaction,
    }).then((res) => {
      if (!res.businesses) {
        throw Error('Businesses not returned');
      }

      if (!res.pagesTotal) {
        throw Error('Number of Pages not returned');
      }

      if (!res.totalBusinesses && res.totalBusinesses !== 0) {
        throw Error('Business Count not returned');
      }
      transaction.finish();

      return res;
    }).catch((e) => {
      transaction.finish();
      Sentry.captureException('Error Retrieving Dashboard Data', e);
      return e;
    });
  }

  /** Locate the identified business and set it's `lastUpdated` property */
  updateBusinessLastUpdateTime(businessId: string, lastUpdateTime: string) {
    let workingBusiness;
    let updatedBusiness;
    if (this.businesses.size > 0 && this.businesses?.has(businessId)) {
      workingBusiness = this.businesses.get(businessId);
      updatedBusiness = _.set(workingBusiness, 'lastUpdated', lastUpdateTime);
      this.businesses.set(businessId, updatedBusiness);
    }

    if (
      this.loadingBusinesses.size > 0
      && this.loadingBusinesses?.has(businessId)
    ) {
      workingBusiness = this.loadingBusinesses.get(businessId);
      updatedBusiness = _.set(workingBusiness, 'lastUpdated', lastUpdateTime);
      this.loadingBusinesses.set(businessId, updatedBusiness);
    }
  }

  /** Retrieve a single buisiness row from the BE and update it in place */
  async updateBusinessRow(businessId: string) {
    const business = await this.retrieveBusinessRow(businessId);
    const prevBusiness = this.businesses.get(businessId);
    const order = prevBusiness?.order || prevBusiness?.order === 0 ? prevBusiness.order : this.newBaseIndex;
    _.set(business, 'order', order);
    _.set(business, 'loading', prevBusiness?.loading || false);
    if (business?.businessId) {
      this.businesses.set(business.businessId, business);
    }
    this.rootStore.resyncStore.loadingBusinesses.set(businessId, false);
  }

  /** Perform API request to obtain single row */
  async retrieveBusinessRow(businessId: string) {
    if (this.businesses.size === 0) {
      await this.loadDashboard();
      return this.businesses;
    }
    const url = `${ApiConstants.apiEndpointsBase.overview}/${this.rootStore.practiceStore.id}/dashboard/business/${businessId}`;
    try {
      const res = await GET({
        url,
        rootStore: this.rootStore,
      });
      return res;
    } catch (e) {
      Sentry.captureException('Error Retrieving Business Row', e);
      return e;
    }
  }

  /** Actions to clear all, add a single or remove a single industry filter */
  clearIndustryFilter() {
    this.activeIndustryFilter = [];
    this.initialiseDashboard(true);
  }

  addIndustryFilter(industry: string) {
    if (this.activeIndustryFilter?.indexOf(industry) === -1) {
      this.activeIndustryFilter.push(industry);
      this.initialiseDashboard(true);
    }
  }

  removeIndustryFilter(industry: string) {
    this.activeIndustryFilter = this.activeIndustryFilter.filter(
      (activeIndustry) => activeIndustry !== industry
    );
    this.initialiseDashboard(true);
  }

  /** Actions to clear all, add a single or remove a single tag filter */
  clearTagFilter() {
    this.activeTagFilter = [];
    this.initialiseDashboard(true);
  }

  addTagFilter(tag: string) {
    if (this.activeTagFilter?.indexOf(tag) === -1) {
      this.activeTagFilter.push(tag);
      this.initialiseDashboard(true);
    }
  }

  removeTagFilter(tag: string) {
    this.activeTagFilter = this.activeTagFilter.filter(
      (activeTag) => activeTag !== tag
    );
    this.initialiseDashboard(true);
  }

  /**
   * Dynamic method to load the dashboard.
   * If it has not been loaded before, it will initialise the dashboard
   * If it has been loaded before, it will refresh the dashboard in place
   */
  async loadDashboard() {
    this.rootStore.loadingStore.setLoading('dashboard');
    if (!this.loading && !this.loadingMore) {
      if (this.businesses?.size > 0) {
        await this.refreshDashboard();
      } else {
        await this.initialiseDashboard(!(this.loadingBusinesses?.size > 0));
      }
    }
    this.rootStore.loadingStore.setDoneLoading('dashboard');
    return true;
  }

  /**
   * Initialises the dashboard data this function is debounced to prevent multiple calls
   * Sets the active page to 1
   * Sets the loaded businesses to the first page of data
   * uses the timeoutId to prevent multiple calls
   * adds the index of the business to the business object so order can be maintained
   * @returns
   */
  async initialiseDashboard(inlineLoading: boolean = false) {
    const transaction = Sentry.startTransaction({
      name: 'Initialise Dashboard',
    });

    const debounceSpan = transaction.startChild({
      op: 'Begin debounced API Call',
    });

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      const setupSpan = transaction.startChild({
        op: 'Setup',
      });

      this.retrievedBusinesses = false;
      this.loadingBusinessesGenerated = false;

      setupSpan.finish();
      const loadingStateSpan = transaction.startChild({
        op: 'Loading State',
      });
      if (inlineLoading) {
        this.loadingMore = true;
      } else {
        this.loading = true;
      }
      this.activePage = 1;
      loadingStateSpan.finish();

      const APISpan = transaction.startChild({
        op: 'API Call',
      });
      this.retrieveDashboardRows().then(async (data) => {
        APISpan.finish();
        if (!data?.businesses) {
          this.loading = false;
          this.loadingMore = false;
          return null;
        }

        const processingSpan = transaction.startChild({
          op: 'Transform Business Structure',
        });

        const newBusinesses:IBusinessDashboardMap = new Map();

        if (data?.businesses?.length) {
          for (let inx = 0; inx < data.businesses.length; inx++) {
            const business = data.businesses[inx];
            newBusinesses.set(business.businessId, { ...business, lastUpdated: null, loading: false, order: inx });
          }
        }
        processingSpan.finish();

        const storeSpan = transaction.startChild({
          op: 'Store Businesses in Observable',
        });
        this.businesses = newBusinesses;
        storeSpan.finish();

        const updateStateSpan = transaction.startChild({
          op: 'Update State',
        });

        this.totalBusinessCount = data?.totalBusinesses || 0;
        this.pagesTotal = data?.pagesTotal;
        this.categories = data?.categories;
        if (this.rootStore.practiceStore?.countryCode?.toLowerCase() === 'us') {
          this.categories = data?.categories?.filter((category) => category?.title !== 'GST');
        }
        this.columnOrder = data?.columnOrder;
        this.loadingMore = false;
        this.loading = false;
        this.retrievedBusinesses = true;
        this.initialLoadComplete = true;

        updateStateSpan.finish();
        transaction.finish();
        return this.businesses;
      }).catch((e) => {
        handleError({ error: e, transaction: 'Dashboard - Initialise Dashboard' });
      });
    }, 1000);
    debounceSpan.finish();
  }

  /**
   * Retrieves the current page of data and appends it to the loaded businesses
   * @returns
   */
  async appendDashboard() {
    const transaction = Sentry.startTransaction({
      name: 'Append Dashboard',
    });
    const setupSpan = transaction.startChild({
      op: 'Setup',
    });
    this.retrievedBusinesses = false;
    this.loadingBusinessesGenerated = false;
    this.loadingMore = true;
    setupSpan.finish();
    const APISpan = transaction.startChild({
      op: 'API Call',
    });

    return this.retrieveDashboardRows()
      .then(async (data) => {
        APISpan.finish();
        const processingSpan = transaction.startChild({
          op: 'Transform Business Structure',
        });
        this.pagesTotal = data.pagesTotal;
        const newBaseInx = this.newBaseIndex;

        const newBusinesses:IBusinessDashboardMap = new Map(this.businesses);

        if (data.businesses?.length) {
          for (let inx = 0; inx < data.businesses.length; inx++) {
            const business = data.businesses[inx];
            newBusinesses.set(business.businessId, { ...business, lastUpdated: null, loading: false, order: newBaseInx + inx });
          }
        }
        processingSpan.finish();
        const storeSpan = transaction.startChild({
          op: 'Store Businesses in Observable',
        });
        this.businesses = newBusinesses;
        storeSpan.finish();

        const updateStateSpan = transaction.startChild({
          op: 'Update State',
        });
        this.categories = data.categories;
        if (this.rootStore.practiceStore.countryCode.toLowerCase() === 'us') {
          this.categories = data.categories.filter(
            (category) => category.title !== 'GST'
          );
        }
        this.columnOrder = data.columnOrder;
        this.loadingMore = false;
        this.retrievedBusinesses = true;
        this.totalBusinessCount = data.totalBusinesses || 0;
        updateStateSpan.finish();
        transaction.finish();
        return this.businesses;
      }).catch((e) => {
        handleError({ error: e, transaction: 'Dashboard - Append Dashboard' });
      });
  }

  /**
   * Initialises the dashboard data
   * Sets the active page to 1
   * Sets the loaded businesses to the first page of data
   * @returns
   */
  async refreshDashboard() {
    this.loadingMore = true;
    const transaction = Sentry.startTransaction({
      name: 'Refresh Dashboard',
    });
    let span = transaction.startChild({
      op: 'Setup',
    });
    this.retrievedBusinesses = false;
    this.loadingBusinessesGenerated = false;
    span.finish();
    span = transaction.startChild({
      op: 'API Call',
    });
    this.retrievedBusinesses = false;
    return this.retrieveDashboardRows(this.rows?.length, 1)
      .then(async (data) => {
        span.finish();
        if (!data.businesses) {
          throw Error('Businesses not returned');
        }

        const newBusinesses:IBusinessDashboardMap = new Map();
        if (data.businesses?.length) {
          span = transaction.startChild({
            op: 'Transform Business Structure',
          });
          for (let inx = 0; inx < data.businesses.length; inx++) {
            const business = data.businesses[inx];
            newBusinesses.set(business.businessId, {
              ...business,
              lastUpdated: null,
              loading: false,
              order: inx,
            });
          }

          span.finish();
          const storeSpan = transaction.startChild({
            op: 'Store Businesses in Observable',
          });
          this.businesses = newBusinesses;
          storeSpan.finish();
        }

        span = transaction.startChild({
          op: 'Update State',
        });
        this.categories = await data.categories;
        if (this.rootStore.practiceStore.countryCode.toLowerCase() === 'us') {
          this.categories = await data.categories.filter(
            (category) => category.title !== 'GST'
          );
        }
        this.columnOrder = await data.columnOrder;
        this.totalBusinessCount = data.totalBusinesses || 0;
        this.retrievedBusinesses = true;
        span.finish();
        transaction.finish();
        this.loadingMore = false;
        return this.businesses;
      }).catch((e) => {
        this.retrievedBusinesses = true;
        span.finish();
        transaction.finish();
        Sentry.captureException('Error Refreshing Dashboard Data', e);
        this.loadingMore = false;
        return e;
      });
  }

  /** Initialise the store */
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );

    this.businesses = new Map();
  }
}

export default DashboardStore;
