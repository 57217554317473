import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { saveAs } from 'file-saver';
import { PeriodTypes } from '@aider/aider-period-library';
import { ComplianceChecklistEnums, ApiConstants } from '@aider/constants-library';
import { ReportTypes } from '@aider/constants-library/dist/enums/docX';
import lookup from 'country-code-lookup';
import AiderLogo from '../../entities/icons/tierPageLogoSmall.png';
import type { RootStore } from '../Store';
import { TabItems } from '../../entities/types';
import { GET, POST } from '../../lib/requests';
import Notification from '../../components/Notification';
import formatTemplate from '../../external/lib/formatHelper';

export default class ReportDownloadStore {
  rootStore: RootStore;

  insightsSelected: string[] = [];

  insightsWithSummariesMonth: string[] = [
    'revenue',
    'directCosts',
    'grossProfit',
    'operationalExpenses',
    'netProfit',
    'invoiceStatus',
  ];

  insights: any[] = [];

  mappedInsights: any[] = [];

  executiveSummary: string = '';

  actionPoints: string = '';

  setInsightsSelected(insights: string[]) {
    this.insightsSelected = insights;
  }

  get hasMonthlyInsights() {
    return this.insightsSelected.some(
      (r) => TabItems.Profitability.indexOf(r) >= 0
    );
  }

  get hasQuarterlyInsights() {
    return (
      this.rootStore.timePeriodStore.periodGranularity === PeriodTypes.QUARTERLY
    );
  }

  constructor(initStore: RootStore) {
    this.rootStore = initStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }

  copyInsights = () => {
    this.insights = this.rootStore.insightStore.insightData;
  };

  resetGeneratedContent = () => {
    this.executiveSummary = '';
    this.actionPoints = '';
  };

  mapInsights = (insightsToInclude: any) => {
    if (!this.insights) {
      this.copyInsights();
    }

    const nonProfitabilityInsights = [
      'cashFlow',
      'cashFlowActual',
      'invoiceStatus',
      'gst',
      'incomeTax',
    ];

    const sortedInsights = insightsToInclude
      .map((key: string) => this.insights.find(({ insightKey }) => insightKey === key))
      .filter((item) => item !== undefined);

    this.mappedInsights = sortedInsights
      .filter((insight) => insight.headline !== undefined)
      .map(
        ({
          insightKey,
          headline,
          summaries,
          periods,
          quarters,
          chartSummary,
          graph,
          actions,
        }) => {
          const title = headline?.title;
          const relevantIndex = nonProfitabilityInsights.includes(insightKey)
            ? insightKey === 'cashFlowActual'
              ? 2
              : 1
            : 0;
          let message = chartSummary[0].body;
          let { templateData } = summaries[relevantIndex];
          let chart = graph || null;
          // if profitability periods
          if (!nonProfitabilityInsights.includes(insightKey)) {
            let period;
            switch (this.rootStore.timePeriodStore.periodGranularity) {
              case PeriodTypes.QUARTERLY:
                period = quarters.find(
                  (p) => p?.periodData?.name
                    === this.rootStore.timePeriodStore.profitabilityPeriodSelected
                );
                break;
              default:
                period = periods.find(
                  (p) => p?.periodData?.name
                    === this.rootStore.timePeriodStore.profitabilityPeriodSelected
                );
                break;
            }

            if (period) {
              templateData = period?.summaries?.[relevantIndex]?.templateData;
              message = period?.chartSummary?.[0]?.body;
              chart = period?.graph;
            }
          } else {
            const messagePreview = actions[0].templates
              ? actions[0].templates[0].message
              : actions[0].messageTemplate;
            message = formatTemplate(messagePreview, templateData);
          }
          return {
            insightKey,
            title,
            templateData,
            message,
            graph: chart,
            chartId: `${insightKey}-report-chart`,
          };
        }
      );
  };

  convertImageToBase64 = (imageUrl) => new Promise((resolve) => {
    try {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        resolve(canvas.toDataURL());
      };
      image.onerror = () => {
        resolve(imageUrl);
      };
      image.src = imageUrl;
    } catch (e) {
      Sentry.captureException(e);
      resolve(imageUrl);
    }
  });

  reportPeriod = (month: string, year: string): string => {
    const convertShortMonthToLong = (shortMonth: string) => {
      const date = new Date(`${shortMonth} 1, 2000`);
      return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    };

    if (
      this.rootStore.reportDownloadStore.hasMonthlyInsights
      || this.rootStore.reportDownloadStore.hasQuarterlyInsights
    ) {
      const splitDate = this.rootStore.timePeriodStore.profitabilityPeriodSelected.split(' ');
      if (splitDate.length === 5) {
        splitDate[0] = convertShortMonthToLong(splitDate[0]);
        splitDate[3] = convertShortMonthToLong(splitDate[3]);
        // This is a quarterly time period remove the second item from the array
        splitDate.splice(1, 1);
      }
      return splitDate.join(' ');
    }

    if (this.rootStore.reportDownloadStore.insightsSelected.length > 0) {
      new Intl.DateTimeFormat('en', {
        month: 'short',
        year: 'numeric',
      }).format(new Date(`${month} ${year}`));
    }

    return '';
  };

  downloadAsDocx = async (
    type: ReportTypes = ReportTypes.performanceReport
  ) => {
    Notification({
      type: 'info',
      title: 'Report Download Started',
      description:
        'Your report is being generated, and will be downloaded shortly',
    });
    switch (type) {
      case ReportTypes.dataCompliance:
        return this.downloadComplianceReport();
      case ReportTypes.performanceReport:
      default:
        return this.downloadPerformanceReport();
    }
  };

  downloadAsExcel = async () => {
    const { selectedBusiness, selectedBusinessId } = this.rootStore.businessesStore;
    const { activePeriod, defaultPeriod, activeChecklistType } = this.rootStore.checklistStore;
    const periodType = activeChecklistType === ComplianceChecklistEnums.Types.gstPeriodChecklist
      ? 'GST Period Checklist'
      : (activeChecklistType === ComplianceChecklistEnums.Types.monthlyChecklist
        ? 'Monthly Checklist'
        : 'Annual Checklist');

    const fileName = `${periodType} - ${activePeriod || defaultPeriod} - ${selectedBusiness.name}.xlsx`;

    const checkListData = this.rootStore.checklistStore.businessChecklists
      ?.get(this.rootStore.businessesStore.selectedBusinessId)
      ?.get(this.rootStore.checklistStore.activeChecklistType)
      ?.tableGroups?.find((tableGroup) => {
        if (this.rootStore.checklistStore.activePeriod) {
          return (tableGroup.tableGroupPeriod.periodName === this.rootStore.checklistStore.activePeriod);
        }
        return (tableGroup.tableGroupPeriod.periodName === this.rootStore.checklistStore.defaultPeriod);
      });

    Notification({
      type: 'info',
      title: 'Spreadsheet Download Started',
      description: 'Your spreadsheet is being generated, and will be downloaded shortly',
    });

    return GET({
      url: `${ApiConstants.apiEndpointsBase.insights}/businesses/${selectedBusinessId}/insights/tablegroups/${checkListData.tableGroupId}/spreadsheet`,
      rootStore: this.rootStore,
      options: {
        responseType: 'blob',
      },
    }).then((fileBuffer) => {
      saveAs(fileBuffer, fileName);
    });
  };

  retrieveReport = (fileName: string, data: any) => POST({
    url: `${ApiConstants.apiEndpointsBase.insightManager}/report`,
    data,
    rootStore: this.rootStore,
    options: {
      responseType: 'blob',
    },
  }).then((fileBuffer) => {
    saveAs(fileBuffer, fileName);
  });

  downloadPerformanceReport = async () => {
    const { selectedBusiness } = this.rootStore.businessesStore;
    let selectedPeriodDate = new Date();
    if (
      this.rootStore.timePeriodStore.periodGranularity === PeriodTypes.QUARTERLY
    ) {
      let splitDate =
        this.rootStore.timePeriodStore.profitabilityPeriodSelected.split(
          '-'
        )[1];
      // Loop through the first character of the string
      // and remove any spaces before the first character
      while (splitDate[0] === ' ') {
        splitDate = splitDate.replace(' ', '');
      }

      selectedPeriodDate = new Date(splitDate.replace(' ', ' 1, '));
    } else {
      selectedPeriodDate = new Date(
        this.rootStore.timePeriodStore.profitabilityPeriodSelected.replace(
          ' ',
          ' 1, '
        )
      );
    }

    const { month, year } = this.rootStore.reportDownloadStore
      .hasMonthlyInsights
      ? {
        month: new Intl.DateTimeFormat('en', { month: 'long' }).format(
          selectedPeriodDate
        ),
        year: selectedPeriodDate.getFullYear(),
      }
      : this.rootStore.reportDownloadStore.insightsSelected.length > 0
        ? this.rootStore.insightStore?.insightData.find(
          (insight) => insight.summaries
            && insight.summaries[0]
            && insight.summaries[0].templateData
            && insight.summaries[0].templateData.year
            && insight.summaries[0].templateData.month
        ).summaries[0].templateData
        : { month: null, year: null };

    const advisorLogo = await this.convertImageToBase64(
      this.rootStore.businessStore.practiceLogoURL
    );
    const fileName = `Performance Report - ${this.reportPeriod(
      month,
      year
    )} - ${selectedBusiness.name}.docx`;

    const data = {
      reportType: ReportTypes.performanceReport,
      reportTitle: 'Performance Report',
      businessName: selectedBusiness.name,
      lineOfBusiness: selectedBusiness.lineOfBusiness,
      managedBy: this.rootStore.businessStore.practiceId.id,
      country: lookup.byIso(selectedBusiness.countryCode).country,
      header: null,
      footer: null,
      content: {
        insights: this.mappedInsights.map(
          ({ insightKey, title, templateData, message }) => ({
            insightKey,
            title,
            templateData,
            message,
            chartImageSource:
              this.rootStore.actionStore.chartImages[insightKey],
          })
        ),
        timeData: {
          month,
          year,
          period: {
            formatted: this.reportPeriod(month, year),
          },
        },
        executiveSummary: this.executiveSummary,
        actionPoints: this.actionPoints,
      },
      aiderLogo: AiderLogo,
      advisorLogo,
      advisorCompany: this.rootStore.businessStore.practiceId.name,
    };
    return this.retrieveReport(fileName, data);
  };

  downloadComplianceReport = async () => {
    const { selectedBusiness } = this.rootStore.businessesStore;
    const cdnBase = `${process.env.REACT_APP_CDN}/aip/icons/`;

    const images: any = {
      alert: `${cdnBase}checklist-alert.png`,
      alertSm: `${cdnBase}checklist-alert-sm.png`,
      alertDone: `${cdnBase}checklist-alert-grey.png`,
      alertDoneSm: `${cdnBase}checklist-alert-done-sm.png`,
      checkboxChecked: `${cdnBase}checklist-checkbox-checked.png`,
      checkboxCheckedSm: `${cdnBase}checklist-checkbox-checked-sm.png`,
      checkbox: `${cdnBase}checklist-checkbox.png`,
      checkboxSm: `${cdnBase}checklist-checkbox-sm.png`,
      statusClosed: `${cdnBase}checklist-status-closed.png`,
      statusClosedSm: `${cdnBase}checklist-status-closed-sm.png`,
      statusInProgress: `${cdnBase}checklist-status-in-progress.png`,
      statusInProgressSm: `${cdnBase}checklist-status-in-progress-sm.png`,
      statusReview: `${cdnBase}checklist-status-review.png`,
      statusReviewSm: `${cdnBase}checklist-status-review-sm.png`,
      statusTodo: `${cdnBase}checklist-status-todo.png`,
      statusTodoSm: `${cdnBase}checklist-status-todo-sm.png`,
      success: `${cdnBase}checklist-success.png`,
      successSm: `${cdnBase}checklist-success-sm.png`,
      warning: `${cdnBase}checklist-warning.png`,
      warningSm: `${cdnBase}checklist-warning-sm.png`,
    };

    const { activePeriod, defaultPeriod, activeChecklistType } =
      this.rootStore.checklistStore;
    const periodType =
      activeChecklistType === ComplianceChecklistEnums.Types.gstPeriodChecklist
        ? 'GST Period Checklist'
        : (activeChecklistType === ComplianceChecklistEnums.Types.monthlyChecklist
          ? 'Monthly Checklist'
          : 'Annual Checklist');

    const advisorLogo = await this.convertImageToBase64(
      this.rootStore.businessStore.practiceLogoURL
    );

    const fileName = `${periodType} - ${activePeriod || defaultPeriod} - ${selectedBusiness.name}.docx`;

    const checkListData = this.rootStore.checklistStore.businessChecklists
      ?.get(this.rootStore.businessesStore.selectedBusinessId)
      ?.get(this.rootStore.checklistStore.activeChecklistType)
      ?.tableGroups?.find((tableGroup) => {
        if (this.rootStore.checklistStore.activePeriod) {
          return (tableGroup.tableGroupPeriod.periodName === this.rootStore.checklistStore.activePeriod);
        }
        return (tableGroup.tableGroupPeriod.periodName === this.rootStore.checklistStore.defaultPeriod);
      });

    const alerts = {
      alerts: 0,
      complete: 0,
    };

    const comments = {};

    checkListData.alertCount.forEach((alert) => {
      if (alert.level === -1) {
        alerts.alerts += 1;
      }
    });

    checkListData.categories.forEach((category) => {
      category.categoryItems.forEach((item) => {
        const commentId = this.rootStore.commentsStore.getLocationIdentifier(
          checkListData.tableGroupType,
          checkListData.tableGroupPeriod.periodStart,
          checkListData.tableGroupPeriod.periodEnd,
          item.itemId
        );
        comments[commentId] =
          this.rootStore.commentsStore.getComments(commentId);
        if (item.checkBox.state === 'checked') {
          alerts.complete += 1;
        }
      });
    });

    const payloadData = {
      alerts,
      images,
      comments,
      status: checkListData.checklistStatus.slice(-1)[0],
      checklistUpdated: checkListData.lastRefreshedUtc,
      checkListData,
    };

    const data = {
      reportType: ReportTypes.dataCompliance,
      reportTitle: periodType,
      businessName: selectedBusiness.name,
      businessId: this.rootStore.businessesStore.selectedBusinessId,
      lineOfBusiness: selectedBusiness.lineOfBusiness,
      managedBy: this.rootStore.businessStore.practiceId.id,
      country: lookup.byIso(selectedBusiness.countryCode).country,
      header: null,
      footer: null,
      content: {
        insights: payloadData,
        timeData: {
          period: {
            formatted: activePeriod || defaultPeriod,
          },
        },
      },
      aiderLogo: AiderLogo,
      advisorLogo,
      advisorCompany: this.rootStore.businessStore.practiceId.name,
    };

    return this.retrieveReport(fileName, data);
  };

  generateContent = async (prompt) => {
    const { lineOfBusiness } = this.rootStore.businessesStore.selectedBusiness;
    const country =
      lookup.byIso(this.rootStore.businessesStore.selectedBusiness.countryCode)
        .country || this.rootStore.businessesStore.selectedBusiness.countryCode;

    const content = prompt
      .replace(
        '<<insights>>',
        this.mappedInsights
          .map((insight: any) => `\n## ${insight.title}\n${insight.message}`)
          .join('')
      )
      .replace('<<lineOfBusiness>>', lineOfBusiness)
      .replace('<<country>>', country);

    const data = {
      model: 'gpt-4o',
      question: {
        role: 'user',
        content,
      },
      context: {
        role: 'system',
        content: '',
      },
    };

    return POST({
      url: `${process.env.REACT_APP_LLM_ENDPOINT}/api/v1/business/${this.rootStore.businessesStore.selectedBusinessId}/dataChatWithContext`,
      data,
      rootStore: this.rootStore,
    }).then((response) => {
      if (!response?.answer) {
        throw new Error('LLM provided no answer');
      }
      return response.answer;
    });
  };

  generateExecutiveSummary = async (prompt) => {
    this.executiveSummary = (await this.generateContent(prompt))
      .replace('Executive Summary:', '')
      .replace('Summary:', '')
      .trim();
  };

  generateActionPoints = async (prompt) => {
    this.actionPoints = await this.generateContent(prompt);
    return this.actionPoints;
  };

  rewriteInsight = async (insightKey, prompt) => {
    const insight = this.mappedInsights.find(
      (i) => i.insightKey === insightKey
    );
    if (insight) {
      insight.message = await this.generateContent(prompt);
    }
  };

  setExecutiveSummary(editedExecutiveSummary: string) {
    this.executiveSummary = editedExecutiveSummary;
  }

  setActionPoints(actionPoints: string) {
    this.actionPoints = actionPoints;
  }

  setInsightText(insightKey: string, message: string) {
    const insight = this.mappedInsights.find(
      (i) => i.insightKey === insightKey
    );
    if (insight) {
      insight.message = message;
    }
  }
}
