/**
 * @file RequiredInput.tsx
 * @desc RequiredInput component is a wrapper around the antd Input
 * component that adds a red exclamation mark to the right of the input
 */
import React from 'react';
import { Form, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { IRequiredInputProps } from '../../models/interfaces/formElements';

const RequiredInput = ({ loading, className, ...rest }: IRequiredInputProps) => {
  const { errors } = Form.Item.useStatus();

  return (
    <Input
      className={`required-input ${className}`}
      disabled={loading}
      suffix={(errors?.[0]) ? <ExclamationCircleFilled /> : <span />}
      {...rest}
    />
  );
};

RequiredInput.defaultProps = {
  className: '',
};

export default RequiredInput;
