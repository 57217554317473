import * as React from 'react';
import {
  longMonthLabelArray,
  shortMonthLabelArray,
} from '../../entities/types';
import { rootStore } from '../../stores/Store';
import { ChartColor } from '../../ts/enums/Colors';
import { ChartFontSize } from '../../ts/enums/Constants';
import formatNumber from '../atoms/FormatNumber';
import {
  backgroundArray,
  backgroundArrayPoint,
  borderArray,
} from '../atoms/GraphSettings';
import ChartWrapper from './ChartWrapper';
// eslint-disable-next-line import/no-named-as-default
import yAxisFormat from '../../utils/yAxisFormat';

interface HybridChartProps {
  insightData: any;
  index: number;
  hoverIndex: number;
  chartId: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function HybridChart({ insightData, index, hoverIndex, chartId }: HybridChartProps) {
  const insightDataArr = insightData;
  const dataSetArray = insightData.graph.legend.map(
    (item, ix, insightDataLegend) => ({
      label: item.label,
      data: insightDataArr.graph.data.map(
        (dp) => dp.values[ix] && dp.values[ix].rawValue
      ),
      borderColor: borderArray[ix],
      backgroundColor: backgroundArray[ix],
      pointBackgroundColor: backgroundArrayPoint[ix],
      order: 1,
      borderRadius: 6,
      hidden:
        insightDataArr.graph.data[0].values[ix].type === 'line'
        && insightDataLegend.length < 2
        && ix > 3,
      type: insightDataArr.graph.data[0].values[ix].type
        ? insightDataArr.graph.data[0].values[ix].type
        : 'bar',
      fill: insightDataArr.graph.data[0].values[ix].type
        ? ix === 2 && insightDataArr.graph.data[0].values[ix].type === 'line'
        : false,
    })
  );
  const annotationObject = {
    zero: {
      // Indicates the type of annotation
      type: 'line',
      yMin: 0,
      yMax: 0,
      borderColor: 'rgba(159,160,172,0.67)',
    },
    box1: undefined,
  };
  const { title, units, isCurrency } = insightData.graph.yAxis;
  if (insightData.graph.yAxis.annotations) {
    annotationObject.box1 = {
      // Indicates the type of annotation
      type: 'line',
      yMin: insightData.graph.yAxis.annotations[0].rawValue,
      yMax: insightData.graph.yAxis.annotations[0].rawValue,
      borderColor: 'rgba(159,160,172,0.67)',
      borderDash: [4, 3],
      label: {
        content: insightData.graph.yAxis.annotations[0].label,
        enabled: true,
        position: 'end',
        backgroundColor: 'rgba(159,160,172,0.67)',
        color: '#505050',
      },
    };
  }
  return (
    // @ts-ignore
    <ChartWrapper
      // @ts-ignore
      id={`${insightData.insightKey}`}
      chartId={chartId}
      setChartHandle={(chart, id) => rootStore.actionStore.setChartObject(chart, id)}
      setB64Image={(id, chart) => rootStore.actionStore.setChartImage(chart, id)}
      type='bar'
      hasGradient
      data={{
        labels: insightData.graph.data.map((datapoint) => datapoint.xLabel),
        datasets: dataSetArray,
      }}
      options={{
        responsive: true,
        animation: true,
        plugins: {
          title: {
            display: false,
            text: insightData.graph.title,
          },
          tooltip: {
            callbacks: {
              title: (headerInstance) => {
                const tooltipTitle = headerInstance[0].label;
                const isTitleAMonth = shortMonthLabelArray.includes(tooltipTitle);
                if (isTitleAMonth) {
                  const replaceIndex = shortMonthLabelArray.indexOf(tooltipTitle);
                  return longMonthLabelArray[replaceIndex];
                }
                return tooltipTitle;
              },
              label(context) {
                const graphData = insightData.graph.data[context.dataIndex];
                if (!graphData) return null;

                const value = graphData.values[context.datasetIndex];
                if (!value || value.formattedValue === null) return null;

                let { label } = context.dataset;
                if (label) label += ': ';

                label += value.formattedValue;
                return label;
              },
            },
            backgroundColor: ChartColor.opaqueBlack,
            titleFont: {
              size: ChartFontSize.defaultSize,
            },
            bodyFont: {
              size: ChartFontSize.defaultSize,
            },
            footerFont: {
              size: ChartFontSize.defaultSize,
            },
          },
          annotation: {
            annotations: annotationObject,
          },
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 20,
              boxHeight: 20,
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              callback(value, inx, values) {
                return formatNumber(value, insightData.graph.yAxis.units);
              },
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            title: {
              display: true,
              text: yAxisFormat(title, units, isCurrency),
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            grid: {
              display: true,
              borderDash: [1, 2],
              color: '#d5d8e3',
            },
          },
          x: {
            ticks: {
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            grid: {
              display: false,
            },
          },
        },
      }}
    />
  );
}

export default HybridChart;
