import { makeAutoObservable } from 'mobx';
import { CONFIG } from '../../entities/types';

export enum InsightTab {
  overview = 'overview',
  dataCompliance = 'dataCompliance',
  cashflow = 'cashflow',
  taxAndCompliance = 'taxAndCompliance',
  profitability = 'profitability',
  report = 'reports',
  advisory = 'advisory',
}

export default class PageStore {
  rootStore: any;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.modalTab = rootStore.businessStore.configureBusinessBankAccount
      ? CONFIG.configuration
      : CONFIG.info;

    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }

  // Default values when page loads

  navBarActive = window.location.pathname.includes('insights') ? 'CLIENT_LIST' : 'OVERVIEW_SCREEN';

  tabActive = InsightTab.overview;

  LOBActive = [];

  selectedLinesOfBusiness: string[] = [];

  toggledModal: string = null;

  supressCloseOnClick: boolean = false;

  modalTab: string = null;

  activePage: string = '';

  navigateTo = null;

  setModalTab(modal: string) {
    this.modalTab = modal;
  }

  toggleSupressCloseOnClick() {
    this.supressCloseOnClick = !this.supressCloseOnClick;
  }

  setToggledModal(modal: string) {
    this.toggledModal = modal;
  }

  async setNavBarActive(activePage) {
    this.navBarActive = activePage;
  }

  setTabActive(activeTab) {
    this.tabActive = activeTab;
  }

  setSelectedLOB(LOBActive) {
    this.LOBActive = LOBActive;
  }

  setLinesOfBusiness(linesOfBiz: string[]) {
    this.selectedLinesOfBusiness = linesOfBiz;
  }

  /**
   * @deprecated Not used anywhere else when searched
   * @param page
   */
  setActivePage(page: string) {
    this.activePage = page;
  }

  setNavigateTo = (navigateTo) => {
    this.navigateTo = navigateTo;
  };
}
