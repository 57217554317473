import React from 'react';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import ActivateClientCommonContent from './ActivateClientCommonContent';

const ActivateClientUpgradeContent = ({ subscription, currencyCode, countryCode }) => {
  const format = new Format(currencyCode, countryCode);
  const { newClientCount, previousClientCount, newTier } = subscription;
  return (
    <>
      <ActivateClientCommonContent newConnectionCount={newClientCount - previousClientCount} />
      <h2>Your practice is now on {newTier.name} plan.</h2>
      <p>
        You will be able to manage up to {newTier?.clients?.max} clients at{' '}
        {format.formatValue({ format: ValueTypes.currency, value: newTier?.prices?.monthly })}/month
        <br />
        (or {format.formatValue({ format: ValueTypes.currency, value: newTier?.prices?.perMonthYearly })}/month if paid annually).
        That&apos;s as low as {format.formatValue({ format: ValueTypes.currency, value: newTier?.prices?.asLowAs })}/client!
      </p>
    </>
  );
};
export default ActivateClientUpgradeContent;
