import { PeriodCloseTypes } from '@aider/constants-library';
import { get } from 'lodash';

/**
 * Perform a math operation on two values from the given operator string
 * @param valA - The first value to perform the operation on
 * @param valB - The second value to perform the operation on
 * @param operator - The operator to use for the operation
 * @returns The result of the operation
 */
export const performMath = (
  valA: number,
  valB: number,
  operator: '+' | '-' | '*' | '/'
) => {
  switch (operator) {
    case '-':
      return valA - valB;
    case '*':
      return valA * valB;
    case '/':
      return valA / valB;
    case '+':
    default:
      return valA + valB;
  }
};

/**
 * Take a cell and the containing table, and process the math instructions
 * @param cell - The cell to process
 * @param table - The table containing the cell
 * @returns The result of the math instructions
 */
export const processMathInstructions = (
  cell: Extract<PeriodCloseTypes.ChecklistCell, { cellType: 'math' }>,
  table: PeriodCloseTypes.ChecklistItem['table']
) => {
  if (cell.cellType !== 'math') {
    return cell.rawData || 0;
  }

  return cell.mathInstructions.reduce((accumulator, instruction) => {
    const { operator, cellPath } = instruction;
    const targetCell = get(table, cellPath);
    const value = targetCell?.cellType === 'math' ? processMathInstructions(targetCell, table) : targetCell?.rawData || 0;
    return performMath(accumulator, value, operator);
  }, 0);
};
