import * as React from 'react';
import { Tooltip } from 'antd';
import { SettingOutlined, TeamOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import BusinessHeader from './BusinessHeader';
import ImpersonateUser from '../components/ImpersonateUser';
import ButtonSecondary from '../components/customAntD/ButtonSecondary';
import ClientSettingsModal from '../components/modals/ClientSettingsModal';
import { useStore } from '../stores/Store';
import { ClientSettingsPages } from '../models/enums/modals';
import AdvisorContactsModal from '../components/modals/AdvisorContactsModal';
import InsightResyncButton from '../components-v2/molecules/InsightResyncButton';
import InsightReconnectButton from '../components/InsightReconnectButton';

const HeaderRow = () => {
  const rootStore = useStore();
  const { businessesStore } = rootStore;
  const businessDetails = businessesStore.businesses.get(businessesStore.selectedBusinessId);

  return businessesStore.activeClientBusinesses.size > 0 && (
    <>
      {businessesStore?.selectedBusiness && <BusinessHeader />}
      <header className='insights-header wrapper wrapper-xl'>
        <div className='insights-header-info'>
          {businessDetails.profile?.dataStatus === 'disconnected' ? <InsightReconnectButton /> : <InsightResyncButton />}
          {businessesStore.selectedBusiness?.connection?.taxNumber
            && <span>GST: {businessesStore.selectedBusiness?.connection?.taxNumber}</span>}
          {businessesStore.selectedBusiness?.currencyCode
            && <span>Currency: {businessesStore.selectedBusiness.currencyCode}</span>}
          {businessesStore.selectedBusiness?.profile?.phoneNumber
            && businessesStore.selectedBusiness.profile.phoneNumber.length > 2
            && <span>Phone: {businessesStore.selectedBusiness.profile.phoneNumber}</span>}
        </div>

        <div className='insights-header--actions'>
          <ImpersonateUser />
          <Tooltip title='Manage advisors' placement='bottom'>
            <ButtonSecondary
              size='large'
              shape='circle'
              onClick={() => AdvisorContactsModal(rootStore)}
              icon={<TeamOutlined />}
              mixpanelEvent='Insights -> Manage Advisors'
            />
          </Tooltip>

          <Tooltip title='Client Settings' placement='bottom'>
            <ButtonSecondary
              size='large'
              shape='circle'
              onClick={() => {
                ClientSettingsModal(rootStore, ClientSettingsPages.CLIENT_CONFIGURATION);
              }}
              icon={<SettingOutlined />}
              mixpanelEvent='Open Client Settings'
            />
          </Tooltip>
        </div>
      </header>
    </>
  );
};

export default observer(HeaderRow);
