import React from 'react';
import { Button } from 'antd';
import { Resync } from './Icons';

interface IResyncPillButtonProps {
  children: any;
  onClick: (e: any) => any;
  state: boolean;
}

const ResyncPillButton = ({
  children,
  onClick,
  state,
}:IResyncPillButtonProps) => (
  <Button
    className='resync-button'
    type='primary'
    ghost
    shape='round'
    icon={(
      <Resync
        className={state ? 'loading' : ''}
        scale={0.8}
        style={{ margin: '0 0.2rem 0.1rem 0' }}
      />
    )}
    onClick={onClick}
    disabled={state}
  >
    {children}
  </Button>
);

export default ResyncPillButton;
