import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import _ from 'lodash';
import type { RootStore } from '../../stores/Store';
import { ModalWidths } from '../../models/enums/modals';
import RuleModalError from './content/RuleModalError';
import PayrollAccountsModalContent from './content/PayrollAccountsModalContent';
import AsyncModal from './wrappers/AsyncModal';
import { parseSelectedAccounts } from '../../lib/componentHelpers/accountFormHelpers';
import handleError from '../../lib/errorHandler';

const PayrollAccountsModal = (
  { modalType, rootStore, form, checklistId, selectedPeriod, setOpened } : {modalType: string, rootStore: RootStore, form, checklistId: string, selectedPeriod: string, setOpened: () => void},
) => {
  const handleSave = async () => {
    let formItems;

    // Validate form and return error without logging to sentry
    try {
      formItems = await form.validateFields();
    } catch (error) {
      return ({ status: 'error', error });
    }

    // Try save the rule log error to sentry
    try {
      const businessConfig = rootStore.businessesStore.selectedBusinessConfig || {};
      const from = parseSelectedAccounts(formItems, true);
      _.set(businessConfig, `overrides.${modalType}`, from);
      rootStore.businessesStore.updateSelectedBusinessConfig(businessConfig);
      rootStore.businessesStore.persistSelectedBusinessWithChecklistResync(
        modalType,
        checklistId,
        'payroll',
        selectedPeriod,
      );
      form.resetFields(); // EMPTY FORM
      return null; // Close Modal
    } catch (error) {
      handleError({ error, status: 'unknown_error', transaction: 'Save/Edit data rule', operation: 'handleSave' });
      return { status: 'error', error };
    }
  };

  const ModalProps = {
    name: 'Payroll Accounts Modal',
    className: 'rule-modal rule-modal__new',
    width: ModalWidths.MEDIUM,
    okText: 'Update',
    onOk: handleSave,
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      shape: 'round',
      size: 'large',
      className: 'button-secondary',
    },
    asyncOk: true,
    title: `Edit ${['payeComparison', 'payePaymentVsPayroll'].includes(modalType) ? 'Payroll' : 'Wages'} data check`,
    errorMessage: <RuleModalError newRule={false} />,
    content: <PayrollAccountsModalContent modalType={modalType} form={form} />
  };

  NiceModal.register('PayrollAccountsModal', AsyncModal);

  const useBusinessId = rootStore.businessesStore.selectedBusiness?.id;
  const business = rootStore.businessesStore.businesses.get(useBusinessId);

  if (useBusinessId !== rootStore.practiceStore.id) {
    if (
      !business?.chartOfAccounts
      || business?.chartOfAccounts.length < 1
    ) {
      return rootStore.businessesStore.getChartOfAccounts(useBusinessId)
        .then(() => {
          NiceModal.show('PayrollAccountsModal', ModalProps);
          setOpened();
        });
    }
    return (() => {
      NiceModal.show('PayrollAccountsModal', ModalProps);
      setOpened();
    })();
  }
  // This is for the practice checklist
  rootStore.practiceStore.chartOfAccounts();
  return NiceModal.show('PayrollAccountsModal', ModalProps);
};

export default PayrollAccountsModal;
