import React from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { SummaryType } from '../../stores/clientSummaryStore';

const SummarySelector = ({ className }) => {
  const { clientSummaryStore, localeStore } = useStore();

  const options = [
    { value: SummaryType.fy, label: localeStore.translation('overview.selector.fy') },
    { value: SummaryType.gst, label: localeStore.translation('overview.selector.gst') },
  ];

  return (
    <div className={className}>
      <Select
        defaultValue={clientSummaryStore.summaryType || SummaryType.gst}
        size='large'
        onChange={(selectedValue) => { clientSummaryStore.summaryType = selectedValue; }}
        options={options}
      />
    </div>
  );
};

export default observer(SummarySelector);
