import React, { useState } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PromptActions from './PromptActions';

const PromptItem = ({ prompt }) => {
  const [isOpened, setOpened] = useState<boolean>(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpened(!isOpened);
  };

  return (
    <div className='practice-checklist'>
      <div
        className='practice-checklist__summary'
        onClick={handleOpen}
      >
        <h5 className='practice-checklist__title'>
          {isOpened ? (<MinusCircleOutlined />) : (<PlusCircleOutlined />)}
          {prompt.label}
        </h5>
        <div className='practice-checklist__actions'>
          <PromptActions prompt={prompt} />
        </div>
      </div>
      {isOpened && (
        <div className='practice-checklist__detail'>
          {prompt.text}
        </div>
      )}
    </div>
  );
};
export default PromptItem;
