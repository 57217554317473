import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useStore } from '../../../stores/Store';
import LoadingSpinner from '../../LoadingSpinner';
import AlertIllustration from '../../illustrations/AlertIllustration';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import Notification from '../../Notification';
import handleError from '../../../lib/errorHandler';

const ClientInformation = () => {
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const { businessesStore, localeStore } = useStore();

  const handleRemoveClient = async () => {
    setSaving(true);
    const businessId = businessesStore.selectedBusinessId;
    try {
      await businessesStore.disconnectAndRemoveBusiness(businessId);

      await NiceModal.hide('ClientSettingsModal');
      NiceModal.remove('ClientSettingsModal');
    } catch (e) {
      Notification({
        type: 'error',
        title: 'Could not remove client.',
        description: ' Please try again later',
      });
      handleError({ error: e, transaction: 'Remove Client' });
    }
    setSaving(false);
  };
  return businessesStore?.selectedBusiness?.name ? (
    <section className='client-settings__content remove-client'>
      <div className='client-settings__content--main scrollbar'>
        <div className='illustration'>
          <AlertIllustration />
        </div>
        <h5>Remove & Disconnect {businessesStore?.selectedBusiness?.name}</h5>
        <div className='client-settings__content--description'>
          <p>
            <b>Use this to permanently remove clients you no longer work with.</b>
            <ul>
              <li>This will remove the client completely from Aider for EVERYONE in your firm.</li>
              <li>It will also disconnect Aider from this client's {localeStore.translation(`osps.${businessesStore.selectedBusinessOsp}`)} account.</li>
              <li>You will no longer be charged for this client.</li>
              <li>If you change your mind, you will have to re-import and connect this client.</li>
            </ul>
          </p>
        </div>

        <Checkbox
          className='remove-client__terms'
          checked={termsChecked}
          onChange={() => setTermsChecked(!termsChecked)}
        >
          I understand and wish to REMOVE this client.
        </Checkbox>
      </div>

      <footer>
        <ButtonPrimary
          danger
          className='remove-client__button'
          loading={saving}
          disabled={!termsChecked || saving}
          onClick={handleRemoveClient}
          size='large'
          mixpanelEvent='Remove Client - Save'
          mixpanelProperties={{ removedBusiness: { id: businessesStore.selectedBusinessId, name: businessesStore.selectedBusiness?.name } }}
        >Remove client & Disconnect from {localeStore.translation(`osps.${businessesStore.selectedBusinessOsp}`)}
        </ButtonPrimary>
      </footer>
    </section>
  ) : (
    <LoadingSpinner />
  );
};

export default observer(ClientInformation);
