import * as React from 'react';
import Cookies from 'js-cookie';
import slugify from 'slugify';
import { CircleMinus, CirclePlus } from '../../atoms/Icons';
import { Brand } from '../../../ts/enums/Colors';
import { ExternalLink } from '../../atoms/ExternalLink';
import { InsightUseProps } from '../../../ts/interfaces/molecules/EarlyWarning';

export const InsightUse = ({ tips, action, identifier }:InsightUseProps) => {
  const cookieName = `showTips-${identifier}`;
  const cookieTips = Cookies.get(cookieName);
  const [showTips, setShowTips] = React.useState(typeof cookieTips === 'undefined' ? true : cookieTips === 'true');
  const toggleTips = () => {
    setShowTips(!showTips);
    Cookies.set(cookieName, !showTips ? 'true' : 'false', { expires: 365 });
  };

  return (
    <aside
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-start',
        borderRadius: '0.5em',
        fontSize: '1rem',
        fontWeight: 400,
        gap: '0.5rem',
        marginBottom: '1rem',
        maxWidth: '80ch',
      }}
    >
      <button
        type='button'
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.2em',
          cursor: 'pointer',
          background: 'none',
          border: 'none',
          fontSize: '1.2rem',
          width: '100%',
          color: Brand.blue,
          fontWeight: 600,
        }}
        onClick={toggleTips}
      >
        {showTips ? <CircleMinus /> : <CirclePlus /> }<span>How to use this insight</span>
      </button>
      <div style={{ padding: '0 1rem', transform: `scaleY(${showTips ? 1 : 0})`, maxHeight: showTips ? '100vh' : 0, transition: 'max-height 100ms ease-out, transform 100ms ease-in-out', transformOrigin: 'top left' }}>
        <ol>
          {tips.map((tip: any) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${identifier}-${slugify(tip.header)}`}>
              {tip.header && (
                <span style={{ fontWeight: 600, color: Brand.darkTeal }}>{tip.header}</span>
              )}
              {tip.header && tip.body && (' ')}
              {tip.body && (
                <span>{tip.body}</span>
              )}
            </li>
          ))}
        </ol>
        {action && (
          <ExternalLink to={action.url} label={action.title} newTab style={{ fontWeight: 600 }} />
        )}
      </div>
    </aside>
  );
};

InsightUse.defaultProps = {
  action: null,
};
