import React, { useState } from 'react';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Format, { FormatTypes, ValueTypes } from '@aider/aider-formatting-library';
import { observer } from 'mobx-react';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';

const Comments = ({ locationIdentifier, form }) => {
  const { businessesStore, commentsStore, userStore } = useStore();
  const [saving, setSaving] = useState(false);
  const format = new Format(businessesStore.selectedBusiness?.connection?.currencyCode, businessesStore.selectedBusiness?.connection?.countryCode);
  const comments = commentsStore.selectedComments;
  return (
    <div className='comments-modal'>
      <div className='comments-modal__body scrollbar'>
        <h2 className='comments-modal__count'>{comments.length} comments</h2>
        {comments
          .slice()
          .sort((a, b) => {
            if (a.timestamp === b.timestamp) return 0;
            return a.timestamp > b.timestamp ? 1 : -1;
          })
          .map((comment) => (
            <div
              key={`${comment.locationIdentifier}-${comment.timestamp}`}
              className='comments-modal__comment'
            >
              <div className='comments-modal__comment__header'>
                <span className='comments-modal__comment__user'>{comment.user}</span>
                <span className='comments-modal__comment__time'>
                  {format.formatValue({
                    format: ValueTypes.formatDate,
                    value: new Date(comment.timestamp).toISOString(),
                    dateFormat: {
                      day: FormatTypes.numeric,
                      month: FormatTypes.short,
                      year: FormatTypes.long,
                      time: true
                    }
                  })}
                </span>
              </div>
              <div className='comments-modal__comment__text'>{comment.text}</div>
            </div>
          ))}
      </div>
      <Form className='comments-modal__footer' form={form}>
        <Form.Item
          name='comment'
          required
        >
          <TextArea
            className='comments-modal__footer--input scrollbar'
            placeholder='Write a comment...'
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <ButtonSecondary
          loading={saving}
          disabled={saving}
          mixpanelEvent='Added comment'
          mixpanelProperties={{ locationIdentifier }}
          onClick={() => {
            setSaving(true);
            commentsStore.addComment(locationIdentifier,
              {
                locationIdentifier,
                user: `${userStore.givenName} ${userStore.familyName}`,
                text: form.getFieldValue('comment'),
                timestamp: Date.now(),
              }).then(() => {
              setSaving(false);
            });
            form.setFieldValue('comment', '');
          }}
        >
          Save
        </ButtonSecondary>
      </Form>
    </div>
  );
};

export default observer(Comments);
