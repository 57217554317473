import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const sortOptionSvg = () => (
  <svg
    height='1em'
    viewBox='0 0 20 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    stroke='currentColor'
  >
    <path
      className='icon-down'
      d='M11.6667 8.64193L14.1667 6.14193M14.1667 6.14193L16.6667 8.64193M14.1667 6.14193L14.1667 17.8086'
    />
    <path
      className='icon-up'
      d='M3.33325 15.3086L5.83325 17.8086M5.83325 17.8086L8.33325 15.3086M5.83325 17.8086L5.83325 6.14193'
    />
  </svg>

);

const SortOptionIndicator = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    className='sort-option-indicator'
    component={sortOptionSvg}
    {...props}
    aria-label='insights'
  />
);

export default SortOptionIndicator;
