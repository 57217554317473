import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { HelpItemGroups } from '../../entities/helpText';
import { rootStore } from '../../stores/Store';
import HelpItem from '../atoms/HelpItem';

interface Props {
  isClient: boolean;
}

const HelpContainer = ({ isClient }: Props) => {
  if (rootStore.helpPanelStore.selectedIndex === null) {
    return null;
  }
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      id='metadataModal'
      className='help-modal-container fadedshort'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={{
        zIndex: 999999,
        minHeight: 500,
        position: isClient ? 'fixed' : 'absolute',
      }}
    >
      <div className='card--panel aiderModalHelp'>
        <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
          <h2
            style={{
              display: 'inline-flex',
              paddingLeft: 12,
              paddingRight: 12,
              fontSize: 24,
              color: '#0A1E70',
              fontWeight: 700,
              height: 60,
              lineHeight: '50px',
              width: '100%',
            }}
          >
            <span style={{ width: '80%' }}>Help</span>{' '}
            <div style={{ width: 30, marginRight: 15, cursor: 'pointer' }}>
              { /* eslint-disable-next-line max-len  */}
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
              <CloseOutlined
                onClick={() => {
                  rootStore.helpPanelStore.setSelectedIndex(null);
                  rootStore.helpPanelStore.hideAllHelpItems();
                }}
              />
            </div>
          </h2>
          <div
            style={{
              fontSize: 24,
              color: '#0A1E70',
              fontWeight: 400,
              marginBottom: 10,
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            {HelpItemGroups[rootStore.helpPanelStore.selectedIndex].title}
          </div>
          {HelpItemGroups[rootStore.helpPanelStore.selectedIndex].content.map(
            (helpItem) => <HelpItem helpItem={helpItem} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpContainer;
