import React from 'react';
import { useStore } from '../../stores/Store';

const UserIcon = () => {
  const { practiceStore, userStore } = useStore();
  return practiceStore?.brandingLogo ? (
    <img
      className='user-icon image-icon'
      src={practiceStore?.brandingLogo}
      alt='User Logo'
      style={{ alignSelf: 'flex-start' }}
    />
  ) : (
    <div
      className='user-icon text-icon'
      style={{ alignSelf: 'flex-start' }}
    >
      {userStore?.userInitials}
    </div>
  );
};

export default UserIcon;
