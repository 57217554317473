import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useStore } from '../../../stores/Store';
import LoadingSpinner from '../../LoadingSpinner';
import AlertIllustration from '../../illustrations/AlertIllustration';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import Notification from '../../Notification';
import handleError from '../../../lib/errorHandler';

const ClientInformation = () => {
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const { businessesStore } = useStore();

  const handleDeactivateClient = async () => {
    setSaving(true);
    const businessId = businessesStore.selectedBusinessId;
    try {
      businessesStore.deactivateBusiness(businessId);

      NiceModal.hide('ClientSettingsModal');
      NiceModal.remove('ClientSettingsModal');
    } catch (e) {
      Notification({
        type: 'error',
        title: 'Could not deactivate client.',
        description: ' Please try again later',
      });
      handleError({ error: e, transaction: 'Deactivate Client' });
    }
    setSaving(false);
  };
  return businessesStore?.selectedBusiness?.name ? (
    <section className='client-settings__content remove-client'>
      <div className='client-settings__content--main scrollbar'>
        <div className='illustration'>
          <AlertIllustration />
        </div>
        <h5>Deactivate {businessesStore?.selectedBusiness?.name}</h5>
        <div className='client-settings__content--description'>
          <p>
            <b>Use this to temporarily stop tracking this client's performance in Aider.</b>
            <ul>
              <li>This will remove access to the client's period close checklists and performance insights for EVERYONE in your firm.</li>
              <li>You will no longer be charged for this client.</li>
              <li>If you change your mind, simply go to the Client Management section to re-activate this client.</li>
              <li>If you just want to hide this client for a user, use the '<a href='/account-settings/permissions'>Manage Permissions</a>' settings instead.
                You can find this in your firm's '<a href='/account-settings'>Account Settings</a>' section.
              </li>
            </ul>
          </p>
        </div>

        <Checkbox
          className='remove-client__terms'
          checked={termsChecked}
          onChange={() => setTermsChecked(!termsChecked)}
        >
          I understand and wish to DEACTIVATE this client.
        </Checkbox>
      </div>

      <footer>
        <ButtonPrimary
          danger
          className='remove-client__button'
          loading={saving}
          disabled={!termsChecked || saving}
          onClick={handleDeactivateClient}
          size='large'
          mixpanelEvent='Deactivate Client - Save'
          mixpanelProperties={{ removedBusiness: { id: businessesStore.selectedBusinessId, name: businessesStore.selectedBusiness?.name } }}
        >Deactivate Client
        </ButtonPrimary>
      </footer>
    </section>
  ) : (
    <LoadingSpinner />
  );
};

export default observer(ClientInformation);
