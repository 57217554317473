import React, { useState } from 'react';
import { firestore } from 'firebase';
import * as Sentry from '@sentry/browser';
import { Input, Tooltip } from 'antd';
import { CloseOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { SplitEnums } from '@aider/constants-library';
import { useStore } from '../stores/Store';
import ButtonPrimary from './customAntD/ButtonPrimary';
import ButtonSecondary from './customAntD/ButtonSecondary';

const ImpersonateUser = ({ className }:{className?:string}) => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const rootStore = useStore();

  const impersonate = () => {
    setLoading(true);
    setOpen(false);
    firestore()
      .collection('/users')
      .where('email', '==', email)
      .limit(1)
      .get()
      .then((snapsot) => {
        const user = snapsot.docs[0];
        if (!user) throw new Error('User not found');
        return user;
      })
      .then(async (user) => {
        await rootStore.authenticationStore.impersonateUser(user.id);
        setLoading(false);
      })
      .catch((err) => {
        Sentry.captureException('Error encountered trying to impersonate user', err);
        setLoading(false);
      });
  };

  const stopImpersonating = async () => {
    setLoading(true);
    setEmail(null);
    await rootStore.authenticationStore.stopImpersonatingUser();
    setLoading(false);
    setOpen(false);
  };

  const handleEmailChange = (newValue) => {
    setEmail(newValue.currentTarget.value);
  };

  return (
    rootStore.splitStore.featureEnabled(SplitEnums.FeatureFlags.impersonateUser) && (
      <div className={`impersonate-user ${className}`}>
        {rootStore.authenticationStore.impersonatingUser ? (
          <Tooltip title={`Stop Impersonating User: ${rootStore.userStore.username}`} placement='left'>
            <ButtonPrimary
              className='greyed-out'
              shape='circle'
              loading={loading}
              onClick={stopImpersonating}
              icon={<UserSwitchOutlined />}
              mixpanelEvent={`Stop Impersonating User: ${rootStore.userStore.email}`}
            />
          </Tooltip>
        ) : (
          <>
            {open && (
            <div className='impersonate-user__inputs'>
              <Input
                size='large'
                required
                onChange={handleEmailChange}
                onPressEnter={impersonate}
              />
              <ButtonPrimary
                size='large'
                onClick={impersonate}
                loading={loading}
                mixpanelEvent={`Impersonate User: ${email}`}
              >
                Impersonate
              </ButtonPrimary>
            </div>
            )}
            <Tooltip title={open ? 'Close' : 'Impersonate User'} placement='bottom'>
              <ButtonSecondary
                shape='circle'
                size='large'
                className='greyed-out'
                loading={loading}
                onClick={() => setOpen(!open)}
                icon={open ? <CloseOutlined /> : <UserSwitchOutlined />}
                mixpanelEvent='Open Impersonate User'
              />
            </Tooltip>
          </>
        )}
      </div>

    ));
};

ImpersonateUser.defaultProps = {
  className: '',
};

export default observer(ImpersonateUser);
