import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import DashboardTable from '../components/dashboard/DashboardTable';
import { useStore } from '../stores/Store';
import DashboardFilters from '../components/dashboard/DashboardFilters';
import PerformanceDashboardSplash from '../components/splashScreens/PerformanceDashboardSplash';
import { Routers } from '../models/enums/utils';

const Dashboard = () => {
  const { businessesStore, dashboardStore, localeStore, practiceStore, resyncStore } = useStore();

  useEffect(() => {
    localeStore.setLocale(practiceStore.countryCode);
  }, [practiceStore.countryCode]);

  useEffect(() => {
    resyncStore.watchFirestore(Routers.DASHBOARD);
    return resyncStore.unsubscribeDataStatus;
  }, [practiceStore.id, dashboardStore.businesses.size]);

  return (
    <div className='dashboard'>
      {businessesStore?.activeClientBusinesses?.size > 0
        && dashboardStore?.initialLoadComplete
        ? (
          <>
            <DashboardFilters />
            <DashboardTable />
          </>
      ) : (
        dashboardStore.initialLoadComplete
        && businessesStore?.activeClientBusinesses?.size === 0
          && (
            <PerformanceDashboardSplash />
          )
      )}
    </div>
  );
};

export default observer(Dashboard);
