import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const aiderSvg = () => (
  <svg
    height='1em'
    viewBox='0 0 37 37'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.1899 18.6969C24.7458 19.3756 26.2365 20.0087 27.6999 20.6996C28.0317 20.8562 28.2685 21.2139 28.5491 21.479C28.2534 21.7341 27.9966 22.0743 27.6556 22.2302C25.6816 23.1328 23.692 24.0017 21.6994 24.8629C19.9665 25.6118 18.7441 26.8539 17.9948 28.5787C17.1712 30.4745 16.3624 32.3774 15.4959 34.2536C15.3018 34.6738 14.8948 34.9957 14.5858 35.3629C14.2838 34.9813 13.8919 34.6407 13.6956 34.2108C12.8941 32.4558 12.1283 30.6833 11.3952 28.8984C10.6206 27.0122 9.32493 25.681 7.44684 24.8777C5.45178 24.0243 3.47091 23.1362 1.50294 22.2222C1.17271 22.0688 0.939287 21.707 0.661133 21.4415C0.949358 21.1816 1.19594 20.8304 1.53266 20.6771C3.50759 19.7781 5.50382 18.9259 7.49235 18.0568C9.2146 17.3041 10.4537 16.0736 11.201 14.3417C12.0746 12.3175 12.9516 10.2944 13.8645 8.28776C14.0037 7.98186 14.3447 7.76778 14.5923 7.51123C14.8421 7.76327 15.1841 7.97205 15.326 8.27467C16.0276 9.77167 16.7877 11.2555 17.3199 12.8147C18.3166 15.7354 20.1559 17.7355 23.1899 18.6969Z'
      fill='currentColor'
    />
    <path
      d='M24.1033 6.96148C25.4138 6.46547 26.2238 5.58811 26.7162 4.37139C27.1032 3.41499 27.5333 2.47576 27.9568 1.53457C28.0878 1.24349 28.2601 0.97101 28.5458 0.447571C28.8747 1.09242 29.1011 1.49123 29.2872 1.90799C31.4544 6.75965 30.6218 5.86993 35.4481 8.05247C35.8607 8.23905 36.257 8.46153 36.6609 8.66734C36.6584 8.7748 36.656 8.88226 36.6535 8.98972C35.6909 9.40847 34.7509 9.89318 33.761 10.2319C31.8603 10.8821 30.6279 12.109 29.9975 14.0274C29.674 15.0117 29.1533 15.9312 28.5861 17.178C28.2317 16.5063 28.0094 16.1463 27.8438 15.7619C25.7332 10.8638 26.4432 11.7107 21.7402 9.5664C21.2948 9.36333 20.8662 9.12352 20.4298 8.90077C20.4246 8.79302 20.4193 8.68526 20.4141 8.5775C21.6212 8.05175 22.8283 7.526 24.1033 6.96148Z'
      fill='currentColor'
    />
  </svg>
);

const GenAI = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={aiderSvg}
    {...props}
  />
);

export default GenAI;
