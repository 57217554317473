import Mixpanel from '../../lib/mixpanel';
import { rootStore } from '../../stores/Store';

// For business selection and tab selection from overview page
const ClientListRedirect = (business, tab?) => {
  Mixpanel.track('Overview Click Through', {
    businessId: business.businessId,
    businessName: business.businessName,
    tab,
  });
  rootStore.businessStore.setSelectedBusinessDefaults(business.businessId);
  rootStore.businessStore.setSelectedBusinessName(business.businessName);
  rootStore.businessStore.setSelectedBusinessLine(business.lineOfBusiness);
  rootStore.businessStore.setSelectedBusinessTaxBasis(business.salesTaxBasis);
  rootStore.businessStore.selectBusiness2(business.businessId);
  rootStore.pageStore.setTabActive(tab);
  // @ts-ignore
  rootStore.pageStore.setNavBarActive('CLIENT_LIST');
};

export default ClientListRedirect;
