import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const aiderSvg = () => (
  <svg width='1em' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M2.29893 16.3301C3.20633 16.3301 3.94176 17.0758 3.94176 17.9956C3.94176 18.9155 3.20633 19.6611 2.29893 19.6611C1.39168 19.6611 0.65625 18.9155 0.65625 17.9956C0.65625 17.0758 1.39168 16.3301 2.29893 16.3301Z' fill='#00E0E1' />
    <path fillRule='evenodd' clipRule='evenodd' d='M16.6751 9.2294C16.6693 9.06418 16.6488 8.89983 16.6142 8.73842C16.3865 6.28499 14.3812 4.37399 11.6988 4.37399C8.83218 4.37399 6.70361 6.54518 6.70361 9.22499V9.26027C6.70361 11.9401 8.40298 14.0804 11.2522 14.0804C12.4615 14.0804 13.6794 13.244 14.0666 12.5281C14.0666 13.1234 14.2494 13.9746 15.3716 13.9746C16.0922 13.9746 16.6765 13.3822 16.6765 12.6516L16.6751 9.2294ZM16.3765 3.08039C19.7224 5.70273 20.3377 10.5783 17.7511 13.9702C15.1644 17.3622 10.3552 17.986 7.00956 15.3637L4.75776 15.9752C4.68772 15.9941 4.61406 15.9941 4.54417 15.9752C4.32291 15.9154 4.19125 15.6852 4.25026 15.4607L4.9245 12.9073C4.9274 12.8984 4.9361 12.8897 4.9361 12.8807C4.34335 11.7556 4.0332 10.4996 4.0332 9.22397C4.03306 4.93583 7.46167 1.45928 11.6915 1.45898C13.3871 1.45854 15.035 2.0289 16.3765 3.08039ZM14.0666 12.4869H14.0651H14.0666ZM14.0666 9.01331V12.337L13.1023 11.3506L12.9733 11.2036C12.6344 11.5523 12.1819 11.764 11.7002 11.799C10.2589 11.799 9.28022 10.598 9.28022 9.22352V9.18824C9.28022 7.8138 10.1719 6.64809 11.6654 6.64809C13.0327 6.64809 13.9723 7.73001 14.0666 9.01331Z' fill='#4263EA' />
  </svg>

);

const AiderBrandColorful = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={aiderSvg} {...props} />
);

export default AiderBrandColorful;
