import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const bounceEllipsisSvg = () => (

  <svg
    width='1em'
    viewBox='0 0 30 30'
    fill='currentColor'
    stroke='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    xmlns='http://www.w3.org/2000/svg'
    className='bounce-ellipsis'
  >
    <circle className='first' cx='5' cy='15' r='3' />
    <circle className='second' cx='15' cy='15' r='3' />
    <circle className='third' cx='25' cy='15' r='3' />

  </svg>

);

const BounceEllipsis = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={bounceEllipsisSvg}
    {...props}
    aria-label='ews'
  />
);

export default BounceEllipsis;
