import { useEffect } from 'react';
import { useStore } from '../../stores/Store';

// Custom hook to require authentication before running a useEffect
export const useAuthenticatedEffect = (effect, dependencies = []) => {
  const { authenticationStore, loadingStore } = useStore();
  return useEffect(() => {
    if (authenticationStore.isAuthenticated) {
      loadingStore.setDoneLoading('auth');
      effect();
    }

  // Ignore warning for spreads in deps as it is necessary for the hook to work
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStore.isAuthenticated, ...dependencies]);
};
