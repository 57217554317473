import React from 'react';
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import { ISubLayoutSettings } from '../models/interfaces/layouts';
import { useStore } from '../stores/Store';
import { useAuthenticatedEffect } from '../lib/customHooks/useAuthenticatedEffect';
import GlobalLoader from '../components/GlobalLoader';

const { Header, Content } = Layout;

/**
 * Sublayout component for the settings pages
 * @category Layouts
 * @subcategory Sublayouts
 *
 * @param {ReactNode} SubNav - The sub-navigation component
 * @param {ReactNode} HeaderContent - The header component
 * @param {ReactNode} Main - The main content component
 * @returns {ReactElement}
 */
const SubLayoutSettings = ({ SubNav, HeaderContent, SubMain }: ISubLayoutSettings) => {
  const { businessesStore, loadingStore } = useStore();

  const initialiseBusinesses = async () => {
    if (businessesStore.businesses.size === 0) {
      businessesStore.fetchBusinessData();
    }
  };

  useAuthenticatedEffect(() => {
    initialiseBusinesses();
  });

  return (
    <Layout className='subLayout-settings'>
      <Header className='subLayout-settings__header'>
        {HeaderContent}
      </Header>
      <Layout className='subLayout-settings__content'>
        <div className='subLayout-settings__content--subNav scrollbar'>
          {SubNav}
        </div>
        <Content className='subLayout-settings__content--main'>
          <GlobalLoader />
          <section className={loadingStore.isAnyLoading ? 'global-loader--loading' : 'global-loader--loaded'}>
            {SubMain}
          </section>
        </Content>
      </Layout>
    </Layout>
  );
};

export default observer(SubLayoutSettings);
