import React from 'react';
import InsightsIllustration from '../illustrations/InsightsIllustration';
import SplashCommon from './SplashCommon';

const PeriodCloseDashboardSplash = () => (
  <SplashCommon
    title='Take a deep dive into a client&apos;s data status and performance KPIs'
    image={<InsightsIllustration />}
  >
    <ul>
      <li>Live alerts about data and performance issues</li>
      <li>Streamline your workflow with easy-to-read, comprehensive client metrics</li>
      <li>Smart AI assistance with performance analysis and client communications</li>
      <li>Client-friendly reports or email notifications to guide your clients on what they need to do next</li>
    </ul>
  </SplashCommon>
);

export default PeriodCloseDashboardSplash;
