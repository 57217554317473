import { observer } from 'mobx-react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Checkbox, Form } from 'antd';
import { v4 as uuid } from 'uuid';
import { useStore } from '../../../stores/Store';
import LoadingSpinner from '../../LoadingSpinner';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import { IManagePermissionsClientPanelProps } from '../../../models/interfaces/components';
import AiderAlert from '../../AiderAlert';
import { mailToHref } from '../../../lib/supportHelpers';

const ClientPanel = forwardRef(({
  clientsLoading,
  form,
  onFinish,
  saving,
  selectedUserEmail,
}:IManagePermissionsClientPanelProps, ref) => {
  const rootStore = useStore();
  const { advisorClientStore } = rootStore;
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const errorSubject = 'Manage Permissions - Missing Clients';
  const errorBody = `${
    'Hi Aider,\n\n'
    + 'I\'m trying to manage permissions for my practice but I can\'t see any clients.\n\n'
    + 'I have confirmed that I have access to clients in my practice, but they are not appearing here.\n\n'
    + 'Regards,'}`;

  const mailto = mailToHref({ subject: errorSubject, body: errorBody, rootStore });

  /**
   * Function to retrieve the updated checklist values based on the state or accessor
   * and update the form values
   * @param {boolean?} state The state to set all checklist values to
   * @param {(id:string) => boolean?} accessor The accessor function to retrieve the checklist value per client
   */
  const getUpdatedChecklistValues = ({ state, accessor }: {state?: boolean, accessor?: (clientId:string) => boolean }) => {
    if (typeof state === 'undefined' && typeof accessor === 'undefined') {
      throw new Error('Either state or accessor must be defined');
    }

    const newChecklistValues = {};
    advisorClientStore.advisorClientIds.forEach((id) => {
      if (typeof state !== 'undefined') {
        newChecklistValues[id] = state;
      } else {
        newChecklistValues[id] = accessor(id);
      }
    });
    form.setFieldsValue(newChecklistValues);
  };

  /**
   * Updates the select all checkbox based on the number of selected clients
   * @param selectedClientCount The number of clients selected
   */
  const updateSelectAll = (selectedClientCount: number) => {
    if (selectedClientCount === advisorClientStore.advisorClientIds.length) {
      setIndeterminate(false);
      setSelectAll(true);
    } else if (selectedClientCount === 0) {
      setIndeterminate(false);
      setSelectAll(false);
    } else {
      setIndeterminate(true);
      setSelectAll(false);
    }
  };

  /**
   * Change Handler for tracking changes to the checklist
   * @param e change event
   */
  const handleChange = (e) => {
    const { id, checked } = e.target;

    trackMixpanelEvent({
      description: 'Manage Permissions: Change Permission',
      properties: {
        selectedAdvisor: selectedUserEmail,
        [`Permission ${checked ? 'Granted' : 'Revoked'}`]: advisorClientStore.advisorPermissionList.find((permission) => permission.id === id).name,
      },
      rootStore
    });
  };

  /**
   * Handles changes to the form, updating the select all checkbox
   * @param changedValues the values that have changed
   * @param allValues all the values in the form
   */
  const handleFormChange = (changedValues, allValues) => {
    const activeChecklistItems = allValues.filter((item) => item.value);
    updateSelectAll(activeChecklistItems.length);
  };

  /**
   * Handles the toggle of the select all checkbox
   * Updates the checklist values and select all state
   * @param e Event from the checkbox
   */
  const handleSelectAll = (e) => {
    const { checked } = e.target;
    trackMixpanelEvent({
      description: 'Manage Permissions: Change Permission',
      properties: {
        selectedAdvisor: selectedUserEmail,
        [`Permission ${checked ? 'Granted' : 'Revoked'}`]: 'All Clients',
      },
      rootStore
    });

    // Either all or none will be selected so not indeterminate
    setIndeterminate(false);

    // Update checklist values
    if (checked) {
      form.setFieldsValue(getUpdatedChecklistValues({ state: true }));
      setSelectAll(true);
    } else {
      form.setFieldsValue(getUpdatedChecklistValues({ state: false }));
      setSelectAll(false);
    }
  };

  // Expose functions to parent component
  useImperativeHandle(ref, () => ({
    getUpdatedChecklistValues,
    updateSelectAll,
    setIndeterminate,
    setSelectAll,
  }));

  return (
    <>
      <h3>Give them access to...</h3>
      <aside className='manage-permissions__panel scrollbar'>
        {clientsLoading
          ? (<LoadingSpinner />)
          : advisorClientStore.advisorPermissionList.length === 0 ? (
            <AiderAlert
              className='manage-permissions__alert'
              title='Cannot find any clients that you have access to.'
            >
              <p>Please add some clients first.</p>
              <p>You can only grant other advisors permission to clients that you have access to.</p>
              <p>If there are clients added by other advisors that you don't have permission to see, then they won't be listed here.</p>
              <p>If you think you are seeing this message in error, please <a href={mailto}>contact Customer Success</a>.</p>
            </AiderAlert>
          ) : (
            <Form
              layout='vertical'
              className='settings-form manage-permissions__panel__form'
              onFinish={onFinish}
              form={form}
              onFieldsChange={handleFormChange}
            >
              <header className='manage-permissions__panel__form__header'>
                <Checkbox
                  indeterminate={indeterminate}
                  className='manage-permissions__panel__form__header--checkbox'
                  defaultChecked={advisorClientStore.activeClients.length === advisorClientStore.advisorPermissionList.length}
                  checked={selectAll}
                  onChange={handleSelectAll}
                  disabled={saving}
                >
                  All clients I have access to
                </Checkbox>
              </header>
              <section className='manage-permissions__panel__form__items'>
                {advisorClientStore.advisorPermissionList.map((client) => (
                  <Form.Item key={`${client.id}-${uuid()}`} name={client.id} valuePropName='checked' initialValue={advisorClientStore.isActiveClient(client.id)} dependencies={['selectAll']}>
                    <Checkbox
                      key={client.id}
                      className='manage-permissions__panel__form__items--item'
                      disabled={saving}
                      onChange={handleChange}
                    >{client.name}
                    </Checkbox>
                  </Form.Item>
                ))}
              </section>
            </Form>
          )}
      </aside>
    </>
  );
});

export default observer(ClientPanel);
