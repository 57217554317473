import React from 'react';
import { Link } from 'react-router-dom';
import AiderBrand from '../components/icons/AiderBrand';
import WarningIllustration from '../components/icons/WarningIllustration';

const PageNotFound = () => {
  const mailToHref = () => {
    /** The email address the contact us email will be sent to */
    const contactEmail = 'success@aider.ai';

    /** Create the email copy */
    const errorSubject = 'Aider: Broken Link';
    let errorBody = `${
      'Hi Aider,\n\n'
      + 'I found a broken link to your website that you may want to fix..\n\n'
      + `The link tries to take me to this missing page: ${window.location.href}\n\n`}`;

    if (document.referrer) {
      errorBody += `I found the link on this page: ${document?.referrer}\n\n`;
    }

    errorBody += 'Regards,\n';

    /** Format the email copy so able to be parsed by url */
    const formattedSubject = errorSubject.replace(/\s/g, '%20');
    const formattedBody = errorBody.replace(/\n/g, '%0D%0A').replace(/\s/g, '%20');

    /** Return formatted mailto string */
    return `mailto:${contactEmail}?subject=${formattedSubject}&body=${formattedBody}`;
  };

  return (
    <div className='main'>
      <div className='page-not-found'>
        <Link to='/'>
          <AiderBrand className='page-not-found--brand' aria-label='Aider Advisory Service' />
        </Link>
        <WarningIllustration className='page-not-found--warning' aria-label='Warning' />
        <main className='page-not-found--content'>
          <h1>Page not found!</h1>
          <ul>
            <li>
              If you manually entered the page URL, please check that it&apos;s entered correctly.
            </li>
            <li>
              If you clicked on a link, please <a href={mailToHref()} target='_blank' rel='noreferrer noopener'>report the broken link</a> to our
              Customer Success team. Thank you!
            </li>
          </ul>
        </main>
      </div>
    </div>
  );
};

export default PageNotFound;
