import { Tooltip } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import { WarningTriangle } from '../icons';
import { useStore } from '../../stores/Store';

interface IResyncStatusProps {
  businessId: string;
  className?: string;
}

const ResyncError = ({ businessId, className }: IResyncStatusProps) => {
  // Global State
  const { resyncStore } = useStore();
  const { resyncingBusinesses } = resyncStore;

  const hasResyncError = (): boolean => {
    const dataStatusError = resyncStore.dataStatusErrorData(businessId);
    if (dataStatusError.length) {
      return true;
    }
    return false;
  };
  /**
   * Build the tooltip content for the pending status
   */
  const statusTooltip = (
    <div className='resync-error'>
      <b>Could not update all data</b>
      <p>Please try resyncing again</p>
    </div>
  );

  return (hasResyncError()
    && !resyncingBusinesses.get(businessId)
    ? (
      <Tooltip
        title={statusTooltip}
        placement='right'
      >
        <WarningTriangle className={className} iconFill='var(--clr-btn-fill-warning)' />
      </Tooltip>
    ) : null
  );
};

ResyncError.defaultProps = {
  className: '',
};

export default observer(ResyncError);
