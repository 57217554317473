import React from 'react';
import { observer } from 'mobx-react';
import { CheckCircleFilled, ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import { CustomRuleEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import { Urgency } from '../../ts/enums/Constants';
import CreateRuleButton from '../CreateRuleButton';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import SectionResync from './SectionResync';
import SectionReconnect from './SectionReconnect';

const ComplianceSectionHeader = ({
  urgency,
  title,
  lastUpdated,
  sectionId,
  checklistId,
  actions,
  firestoreUpdatedAt,
  hasAlertUnchecked,
}:{
  urgency: Urgency;
  title: string;
  lastUpdated: string;
  sectionId: string;
  checklistId: string;
  actions: { text: string; link: string, type: string }[];
  firestoreUpdatedAt: string;
  hasAlertUnchecked: boolean;
}) => {
  const { checklistStore, businessesStore } = useStore();
  const businessDetails = businessesStore.businesses.get(businessesStore.selectedBusinessId);
  return (
    <div className='compliance-section-header'>
      <div className='compliance-section-header__block'>
        <div className='compliance-section-header__icon'>
          {urgency === Urgency.success && (
            <CheckCircleFilled className='compliance-section-header__icon--success' />
          )}
          {((urgency === Urgency.muted && !hasAlertUnchecked)
          || (urgency === Urgency.danger && !hasAlertUnchecked)) && (
            <ExclamationCircleFilled className='compliance-section-header__icon--muted' />
          )}
          {urgency === Urgency.warning && (
            <WarningFilled className='compliance-section-header__icon--warning' />
          )}
          {((urgency === Urgency.danger && hasAlertUnchecked)
            || (urgency === Urgency.muted && hasAlertUnchecked)
            || !urgency) && (
            <ExclamationCircleFilled className='compliance-section-header__icon--danger' />
          )}
        </div>
        <div className='compliance-section-header__text'>
          <h2 className='compliance-section-header__title'>{title}</h2>
          {(businessDetails.profile?.dataStatus === 'disconnected' && sectionId === 'bankReconciliation')
            ? (<SectionReconnect lastUpdated={lastUpdated} />) : (
              <SectionResync checklistId={checklistId} sectionId={sectionId} lastUpdated={lastUpdated} firestoreUpdatedAt={firestoreUpdatedAt} />
            )}
        </div>
      </div>
      <div className='compliance-section-header__block'>
        {CustomRuleEnums.RuleCategories[sectionId] && (
          <>
            <CreateRuleButton
              checklistId={checklistId}
              sectionId={sectionId}
              selectedPeriod={checklistStore.activePeriod}
              allowKeywords={false}
            />
          </>
        )}
        {actions.map((entry) => (
          <ButtonSecondary
            key={`compliance-section-header__action-${entry.link}`}
            onClick={() => window.open(entry.link, '_blank')}
            mixpanelEvent='Period Close: Section Action Clicked'
            mixpanelProperties={{
              section: title,
              ...entry
            }}
          >
            {entry.text}
          </ButtonSecondary>
        ))}
      </div>
    </div>
  );
};

export default observer(ComplianceSectionHeader);
