// TODO: Refactor this file to remove all the eslint-disabled
import axios from 'axios';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import formatTemplate from '../../external/lib/formatHelper';
import businessService from '../../services/businessService';
import { rootStore } from '../../stores/Store';
import ActionInsightCard from '../actionCards/ActionInsightCard';

export interface IFormState {
  category: any,
  usersList: any,
  usersSelected: any,
  savedSelectedUsers: any,
  messagePreview: any,
  messageSubject: any,
  hour: any,
  startDate: any,
  optionDays: any,
  period: any,
  selectedSummaryIndex: any,
  medium: any;
  emailContacts: any;
  imageAttached: any;
  insightsWithCharts: any;
  intro: any;
  outro: any;
}
interface IFormProps {
  store?: any;
  rootStore?: any;
  type: any;
  closeActionModal: any;
}

class ActionCentreModal extends React.Component<IFormProps, IFormState> {
  constructor(props: IFormProps) {
    super(props);
    const period =
      (rootStore.actionStore.selectedPeriod
        && rootStore.actionStore.selectedPeriod.period)
      || (rootStore.actionStore.selectedInsight.summaries[0]
          && rootStore.actionStore.selectedInsight.summaries.filter(
            (summary) => !summary.disabled
          )[0].id);

    const selectedSummaryIndex =
      (rootStore.actionStore.selectedPeriod && rootStore.actionStore.selectedPeriod.index)
      || (rootStore.actionStore.selectedInsight.summaries[0]
         && rootStore.actionStore.selectedInsight.summaries.indexOf(
           rootStore.actionStore.selectedInsight.summaries.filter(
             (summary) => !summary.disabled
           )[0]
         )
      );

    this.state = {
      intro: `Hi ${'<-client_name->'},\n`,
      outro: '\nThanks',
      insightsWithCharts: [],
      imageAttached: rootStore.actionStore.imageAttached,
      emailContacts: [],
      medium: rootStore.actionStore.savedMedium || 'email',
      optionDays: 7,
      hour: 15,
      startDate: new Date(),
      // eslint-disable-next-line react/no-unused-state
      category: '',
      usersList: [],
      // eslint-disable-next-line react/no-unused-state
      usersSelected: [],
      savedSelectedUsers: rootStore.actionStore.savedSelectedUsers || [],
      messagePreview: '',
      messageSubject: rootStore.actionStore.confirmedMessageSubject || 'Generating subject',
      period,
      selectedSummaryIndex
    };
  }

  // eslint-disable-next-line consistent-return
  componentDidMount(): void {
    const { type } = this.props;
    const { selectedSummaryIndex } = this.state;
    const business = rootStore.businessesStore.selectedBusiness;

    if (business) {
      rootStore.actionStore.getEmailContacts().then((contacts) => {
        const newContacts = contacts.map((contact) => (
          { ...contact, displayName: contact.name, externalContact: true }
        ));

        this.setState({
          emailContacts: newContacts
        });
      });
      if (type === 'insight') {
        if (rootStore.actionStore.savedMessagePreview) {
          this.setState({
            messagePreview: rootStore.actionStore.savedMessagePreview,
            savedSelectedUsers: rootStore.actionStore.savedSelectedUsers
          });
        } else if (rootStore.actionStore.selectedActionIsBlank) {
          this.calculateBlankMessagePreviewFromSummary(rootStore.actionStore.selectedInsight);
        } else {
          const selectedPeriod =
            rootStore.actionStore.selectedInsight.summaries[selectedSummaryIndex];
          const selectedPeriodToSet = selectedPeriod.id;
          const selectedIndex = selectedSummaryIndex;
          rootStore.actionStore.setSelectedPeriod(selectedPeriodToSet, selectedIndex);
          this.calculateMessagePreview(selectedPeriod);
        }
        return this.setState({ usersList: business.liveUsers });
      }

      try {
        const messagePreviewObj =
          rootStore.actionStore.selectedActionItem.actions[0].options.default.templateData;
        // @ts-ignore
        const messagePreview = rootStore.actionStore.selectedActionItem.messageTemplate;
        const previewCompiled = formatTemplate(messagePreview, messagePreviewObj);

        this.setState({ usersList: business.liveUsers, messagePreview: previewCompiled });
      } catch (e) {
        this.setState({ usersList: business.liveUsers });
      }
    }
  }

  handleHourInput(e) {
    this.setState({ hour: e.target.value });
  }

  setStartDate(date) {
    this.setState({ startDate: date });
  }

  setAttachImage(shouldAttachImage) {
    this.setState({ imageAttached: shouldAttachImage });
  }

  private async getMessagePreview(url, optionDays) {
    const actions = rootStore.actionStore.selectedActionItem.actions[0];
    if (
      actions
      && actions.options
      && actions.options.default
      && actions.options.default.templateData
    ) {
      return false;
    }
    const tokens = await businessService.refreshPracticeCredentials();
    let preview = '';
    return axios({
      method: 'post',
      url,
      data: {
        businessId: rootStore.businessStore.selectedBusinessId,
        days: optionDays,

      },
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    }).then((res) => {
      preview = res.data;
      if (rootStore.actionStore.selectedActionItem.title !== 'Send Client Reconciliation Request') {
        this.setState({ messagePreview: preview });
      }
    }).catch(() => {
      this.setState({ messagePreview: 'Error loading message preview.' });
    });
  }

  setMultiInsightCharts(charts) {
    this.setState({ insightsWithCharts: charts });
  }

  private async setMedium(medium) {
    if (medium === 'app') {
      this.setState({ imageAttached: false });
    }
    this.setState({ medium });
  }

  private async setMessageSubject(subject) {
    this.setState({ messageSubject: subject });
  }

  private getMessageSubject() {
    const { messageSubject } = this.state;
    return messageSubject;
  }

  // eslint-disable-next-line consistent-return
  private async calculateMessagePreview(period) {
    const { type } = this.props;
    const key = rootStore.actionStore.selectedInsight.insightKey;
    try {
      if (type === 'insight' && period) {
        const selectedAction = rootStore.actionStore.selectedInsight;
        // @ts-ignore
        const messagePreview = selectedAction.actions[0].templates
          ? selectedAction.actions[0].templates[0].message
          : selectedAction.actions[0].messageTemplate;
        const previewCompiled = formatTemplate(messagePreview, period.templateData);

        const intro = `Hi ${'<-client_name->'},\n`;
        const outro = `Kind Regards,\n ${rootStore.authStore.practiceUser.user.displayName}`;
        const endToken = `<-end_${key}_${period.id}->`;
        const startToken = `<-${key}_${period.id}->`;
        return this.setState({ intro, outro, messagePreview: `${intro}\n${startToken}\n${previewCompiled}\n {${rootStore.actionStore.selectedInsight.insightKey}}\n\n${endToken}\n${outro}` });
      }
    } catch (e) {
      return this.setState({ messagePreview: '' });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }

  // eslint-disable-next-line class-methods-use-this
  spliceSlice(str, index, count, add) {
    // We cannot pass negative indexes directly to the 2nd slicing operation.
    let newIndex = index;
    if (newIndex < 0) {
      newIndex = str.length + newIndex;
      if (newIndex < 0) {
        newIndex = 0;
      }
    }

    return str.slice(0, newIndex) + (add || '') + str.slice(newIndex + count);
  }

  // eslint-disable-next-line consistent-return
  private async calculateMessagePreviewForKey(key, isDeletion?) {
    const { messagePreview, intro, outro } = this.state;
    const { type } = this.props;
    const parameters = key.split('_');
    const insightKey = parameters[0];
    const periodId = parameters[1];
    const selectedAction = rootStore.insightStore?.insightData.find(
      (insight) => insight.insightKey === insightKey
    );
    const period = selectedAction?.summaries.find(
      (summaryInstance) => summaryInstance.id === periodId
    );
    if (isDeletion) {
      const endToken = `<-end_${key}->\n`;
      const startToken = `<-${key}->`;
      let mutatedMessage = this.spliceSlice(messagePreview, messagePreview
        .indexOf(startToken), messagePreview
        .indexOf(endToken) + endToken.length - messagePreview
        .indexOf(startToken), '');
      if (mutatedMessage.length < 1) {
        mutatedMessage = `${intro}\n${mutatedMessage}\n${outro}`;
      }
      return this.setState({ messagePreview: mutatedMessage });
    }

    try {
      if (type === 'insight' && period) {
        const generateMessagePreview = selectedAction.actions[0].templates
          ? selectedAction.actions[0].templates[0].message
          : selectedAction.actions[0].messageTemplate;
        const previewCompiled = formatTemplate(generateMessagePreview, period.templateData);
        const messagePreviewJoined = `<-${key}->\n${previewCompiled}\n{${insightKey}}\n\n<-end_${key}->\n`;
        // intro template
        const outroLength = messagePreview.length - outro.length;
        const newOld = this.insert(messagePreview, outroLength, messagePreviewJoined);
        return this.setState({ messagePreview: newOld });
      }
    } catch (e) {
      return this.setState({ messagePreview: '' });
    }
  }

  async selectOption(previewURL, optionDays, days) {
    const tokens = await businessService.refreshPracticeCredentials();
    let preview = '';
    this.setState({
      optionDays: days,
    });
    return axios({
      method: 'post',
      url: previewURL,
      data: {
        businessId: rootStore.businessStore.selectedBusinessId,
        days,

      },
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    }).then((res) => {
      preview = res.data;
      this.setState({ messagePreview: preview });
    }).catch(() => {
      this.setState({ messagePreview: 'Error loading message preview.' });
    });
  }

  async selectPeriod(period) {
    const selectedPeriod = rootStore.actionStore.selectedInsight.summaries.find(
      (summary) => summary.id === period
    );
    const selectedIndex = rootStore.actionStore.selectedInsight.summaries.indexOf(selectedPeriod);
    rootStore.actionStore.setSelectedPeriod(period, selectedIndex);
    this.setState({
      period, selectedSummaryIndex: selectedIndex
    });
    this.calculateMessagePreview(selectedPeriod);
  }

  confirmMessageAction(e, users, scheduleType, charts?) {
    const { closeActionModal } = this.props;
    const { medium, messagePreview, messageSubject } = this.state;
    closeActionModal(e);
    if (medium === 'email') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const newUsers = users.map((user) => {
        const newUser = { ...user };
        if (newUser.emailAddress) {
          newUser.name = newUser.displayName;
          newUser.email = newUser.emailAddress.toLowerCase();
        }
        return newUser;
      });
      return rootStore.actionStore.confirmMessageAction(
        users,
        messagePreview,
        messageSubject,
        scheduleType,
        true,
        charts,
        medium
      );
    }
    return rootStore.actionStore.confirmMessageAction(
      messagePreview,
      messageSubject,
      scheduleType,
      true,
      charts,
      medium,
      users
    );
  }

  private async calculateBlankMessagePreviewFromSummary(insight) {
    // @ts-ignore
    console.debug('.message', rootStore.actionStore.selectedInsight.chartSummary[0].body.replace(/\s{2,}/g, ' '), toJS(insight));
    // @ts-ignore
    return this.setState({ messagePreview: `Hi,\n${rootStore.actionStore.selectedInsight.chartSummary[0].body.replace(/\s{2,}/g, ' ')} \nThanks,\n${rootStore.authStore.practiceUser.user.displayName}` });
  }

  private editMessageSubject(e) {
    this.setState({ messageSubject: e.target.value });
  }

  private editMessagePreview(e) {
    this.setState({ messagePreview: e.target.value });
  }

  // eslint-disable-next-line class-methods-use-this
  renderLoadingComponent() {
    return null;
  }

  // eslint-disable-next-line consistent-return
  renderCardFromType(cardType) {
    const { state, props } = this;
    if (cardType === 'insight') {
      if (!rootStore.actionStore.selectedActionIsBlank) {
        return (
          <div className='actionCenterModal z-depth-6 client-modal-container-full optionHeight'>
            {' '}
            <ActionInsightCard
              // eslint-disable-next-line react/jsx-no-bind
              setMultiInsightCharts={this.setMultiInsightCharts.bind(this)}
              insightsWithCharts={state.insightsWithCharts}
              imageAttached={state.imageAttached}
              // eslint-disable-next-line react/jsx-no-bind
              setAttachImage={this.setAttachImage.bind(this)}
              medium={state.medium}
              savedSelectedUsers={state.savedSelectedUsers}
              // eslint-disable-next-line react/jsx-no-bind
              setMedium={this.setMedium.bind(this)}
              title={
                rootStore.actionStore.selectedInsight.actions[0]
                && rootStore.actionStore.selectedInsight.actions[0].title
              }
              description={null}
              recipientCount={null}
              // eslint-disable-next-line react/jsx-no-bind
              selectOption={this.selectOption.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              selectPeriod={this.selectPeriod.bind(this)}
              selectedSummaryIndex={state.selectedSummaryIndex}
              period={state.period}
              emailContacts={state.emailContacts}
              // eslint-disable-next-line react/jsx-no-bind
              setMessageSubject={this.setMessageSubject.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              editMessageSubject={this.editMessageSubject.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              getMessageSubject={this.getMessageSubject.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              editMessagePreview={this.editMessagePreview.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              getMessagePreview={this.calculateMessagePreview.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              calculateMessagePreviewForKey={this.calculateMessagePreviewForKey.bind(this)}
              messagePreview={state.messagePreview}
              messageSubject={state.messageSubject}
              actions={[]}
              // @ts-ignore
              options={[]}
              usersList={state.usersList ? state.usersList : []}
              renderLoadingComponent={null}
              type={props.type}
              store={props.store}
              rootStore={props.rootStore}
              closeModal={props.closeActionModal}
              // eslint-disable-next-line react/jsx-no-bind
              confirmMessageAction={this.confirmMessageAction.bind(this)}
              hour={state.hour}
              startDate={state.startDate}
              optionDays={state.optionDays}
            />
          </div>
        );
      }
    }
    if (cardType !== 'manage-messages') {
      return (
        <div className='actionCenterModal z-depth-6 client-modal-container-full optionHeight'>
          {' '}
        </div>
      );
    }
  }

  public render() {
    const { type } = this.props;
    return (
      <div
        id='metadataModal'
        style={{ display: 'flex', justifyContent: 'center' }}
      >

        {this.renderCardFromType(type)}
      </div>
    );
  }
}
const ObservedActionCentreModal = observer(ActionCentreModal);
export default ObservedActionCentreModal;
