import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import SocialButtons from './SocialButtons';
import { Routers } from '../../models/enums/utils';
import { useAuthenticatedEffect } from '../../lib/customHooks/useAuthenticatedEffect';

const InvitationForm = () => {
  const rootStore = useStore();
  const { invitationStore, loadingStore } = rootStore;
  const navigate = useNavigate();

  useAuthenticatedEffect(() => {
    if ((invitationStore.inviteToken || invitationStore.inviteAccepted) && !invitationStore.error) {
      navigate(Routers.INVITE_ACCEPT);
    }
  }, [invitationStore.inviteAccepted]);

  return (!loadingStore.isAnyLoading && (
    <SocialButtons invitation className='signup__details--form' label='Create your user login with' />
  ));
};

export default observer(InvitationForm);
