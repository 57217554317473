import cookie from 'js-cookie';
import qs from 'qs';

const options = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  expires: parseInt(process.env.REACT_APP_COOKIE_EXPIRE_DAYS),
};

export const getAcquisitionData = () => {
  const query = qs.parse((window.location.search || '').replace('?', ''));
  const utm = JSON.parse(cookie.get('utm') || '{}');
  const getParameterValue = (name) => (
    query[name] || utm[name]
  );

  return {
    acquisition: {
      utm_source: getParameterValue('utm_source'),
      utm_medium: getParameterValue('utm_medium'),
      utm_campaign: getParameterValue('utm_campaign'),
      utm_term: getParameterValue('utm_term'),
      utm_content: getParameterValue('utm_content'),
    },
    cat: getParameterValue('cat'),
    partnerToken: getParameterValue('token'),
  };
};
