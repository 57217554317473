import { CustomRuleEnums } from '@aider/constants-library';
import { PlusOutlined, ToolOutlined } from '@ant-design/icons';
import React from 'react';

const RuleModalTitle = ({ newRule, sectionId }:{newRule:boolean, sectionId:string}) => (
  <div className='rule-modal__title'>
    {newRule ? (
      <>
        <PlusOutlined />
        New {CustomRuleEnums.RuleLibrary[sectionId]} data check
      </>
    ) : (
      <>
        <ToolOutlined />
        Edit {CustomRuleEnums.RuleLibrary[sectionId]} data check
      </>
    )}
  </div>
);

export default RuleModalTitle;
