import React from 'react';
import { useStore } from '../stores/Store';
import { AccountSettingsIcon } from './icons';

const PracticeInitialsLogo = () => {
  const { practiceStore } = useStore();

  return (
    <div className='practice-initials-logo'>
      {practiceStore?.practiceInitials ? practiceStore?.practiceInitials : (<AccountSettingsIcon className='practice-initials-logo__icon' />)}
    </div>
  );
};

export default PracticeInitialsLogo;
