import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import { INavLinkProps } from '../models/interfaces/components';
import { trackMixpanelEvent } from '../lib/mixpanel';
import { rootStore } from '../stores/Store';
import { navLinkStatus } from '../lib/routing';
/**
 * Component: NavLinkCustom
 * A custom NavLink component with optional tooltip support
 *
 * @param to - The destination URL for the NavLink
 * @param label - The content / icon to display in the NavLink
 * @param tooltipTitle - Optional tooltip text to display when hovering over the NavLink
 * @param className - Optional CSS class name for styling the NavLink
 * @param onSelect - Optional callback function to handle NavLink selection
 * @param trackPath - Optional path to track with Mixpanel analytics
 * @returns A NavLink component that renders the provided label with optional tooltip
 */

const NavLinkCustom = <T, >({
  to,
  label,
  tooltipTitle,
  className,
  onSelect,
  trackPath,
  openInNewTab = false,
  isEnd = false,
}: INavLinkProps<T>) => {
  const handleNavLinkClick = () => {
    trackMixpanelEvent({
      description: `Navigate to ${trackPath}`,
      properties: { NavLinkCustom: trackPath },
      rootStore
    });
    if (onSelect) {
      onSelect();
    }
  };
  return (
    <NavLink
      end={isEnd}
      to={to}
      className={`${navLinkStatus} ${className || ''}`}
      onClick={handleNavLinkClick}
      target={openInNewTab ? '_blank' : '_self'}
    >
      {tooltipTitle ? (
        <Tooltip
          placement='rightTop'
          title={tooltipTitle}
        >
          {label}
        </Tooltip>
      ) : (
        label
      )}
    </NavLink>
  );
};

export default NavLinkCustom;
