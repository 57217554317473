import { AlertEnums, DashboardEnums } from '@aider/constants-library';
import type DashboardStore from '../../stores/dashboardStore';

/**
 * Extract the actual alert value from the various alert types
 * @param alertType
 * @param alert
 * @returns
 */
export function getAlertValue(alertType: string, alert: any): AlertEnums.Type {
  if (alertType === 'object') {
    return alert.alertLevel;
  }
  return alert;
}

/**
 * Obtain status string from alert value
 * @param alertValue
 * @returns
 */
export function getStatus(alertValue: AlertEnums.Type): string {
  switch (alertValue) {
    case AlertEnums.Type.ALERT_GREEN:
      return 'good';
    case AlertEnums.Type.ALERT_GREY:
      return 'neutral';
    case AlertEnums.Type.ALERT_RED:
      return 'bad';
    default:
      return '';
  }
}

/**
 * Return a string with an appropriate placeholder
 * depending on the column type
 * @param colKey
 * @param value
 * @returns
 */
export const cleanMainValue = (colKey: string, value: number | string) => {
  switch (colKey) {
    case 'unreconciled':
      return value || 0;
    default:
      return value || 'N/A';
  }
};

/**
 * Return a string or null depending on if the substring
 * is the same as the main value or if the secondary value
 * or if the secondary value contains 'Invalid DateTime'
 * or 'Oldest: N/A'
 * @param mainValue
 * @param secondaryValue
 * @returns
 */
export const cleanSubtext = (
  mainValue: string,
  secondaryValue: string
): string | null => {
  if (
    mainValue === secondaryValue
    || secondaryValue?.indexOf('Invalid DateTime') > -1
    || secondaryValue?.indexOf('Oldest: N/A') > -1
  ) {
    return null;
  }

  return secondaryValue;
};

/**
 * Construct the ClassName for a dashboard cell based on the
 * group positions, alert value, column type and column key
 *
 * This is used to determine the styling of the cell
 *
 * @param groupPositions
 * @param alertValue
 * @param colType
 * @param colKey
 * @param dashboardStore
 * @returns
 */
export const getCellClassName = (
  groupPositions: any,
  alertValue: AlertEnums.Type,
  colType: DashboardEnums.ColumnTypes,
  colKey: string,
  dashboardStore: DashboardStore
): string => {
  let className = 'dashboardCell';
  if (groupPositions.first) {
    className += ' firstPos';
  }
  if (groupPositions.last) {
    className += ' lastPos';
  }
  const cellStatus = getStatus(alertValue);
  if (cellStatus) {
    className += ` status-${cellStatus}`;
  }
  if (colType) {
    className += ` type-${colType}`;
  }
  if (dashboardStore.bankRecDependantCols.includes(colKey)) {
    className += ' bank-rec-dependant';
  }

  className += ` ${colKey}`;

  return className;
};

/**
 * Return a string with the desired position of the icon in the cell
 * based on the column type
 * @param colType
 * @returns
 */
export const getIconPosition = (colType: DashboardEnums.ColumnTypes):string => {
  switch (colType) {
    case DashboardEnums.ColumnTypes.name:
    case DashboardEnums.ColumnTypes.trend:
      return 'bottom';
    default:
      return 'top';
  }
};
