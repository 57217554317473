import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import StandardModal from './wrappers/StandardModal';
import TextPill from '../TextPill';
import AssistantChat from '../AdvisoryAssistant/AssistantChat';
import { AdvisoryAssistant } from '../icons';

const AdvisoryAssistantModal = () => {
  NiceModal.register('AdvisoryAssistantModal', StandardModal);

  const ModalProps = {
    name: 'Advisory Assistant Modal',
    title: (
      <>
        <AdvisoryAssistant className='icon__navy' /> Dashboard Assistant <TextPill>Beta</TextPill>
      </>
    ),
    className: 'advisory-assistant-modal',
    footer: false,
    width: ModalWidths.CONTAINER,
    content: <AssistantChat page='dashboard' />,
    afterClose: () => {
      NiceModal.remove('AdvisoryAssistantModal');
    },
  };

  return NiceModal.show('AdvisoryAssistantModal', ModalProps);
};

export default AdvisoryAssistantModal;
