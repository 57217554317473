import * as React from 'react';

/**
 * @param props
 * @returns
 */
export const RoundedBoxEmpty = () => (
  <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1' y='1.95215' width='26' height='26' rx='5' fill='white' />
    <rect x='1' y='1.95215' width='26' height='26' rx='5' stroke='#0A1E70' strokeWidth='2' />
  </svg>
);
