import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { PracticeTypes } from '@aider/constants-library';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';

const PageComponent = ({
  block,
  blockInstance,
  className
} : {
  block: PracticeTypes.ReportBlock,
  blockInstance: number,
  className: string
}) => {
  const { reportTemplateStore } = useStore();
  return (
    <section key={`page-action_${block.id}`} className={`${className} ${className}__page outdent`}>
      <Tooltip
        title='Add a New Page'
        placement='right'
      >
        <ButtonSecondary
          className='borderless'
          size='large'
          mixpanelEvent='Report Editor - Add Page'
          icon={<CopyOutlined />}
          onClick={() => reportTemplateStore.addPageBlock(block.id)}
        />
      </Tooltip>
      <hr />
      {block.id && (
        <>
          <Tooltip title={`Delete Page ${blockInstance} and all of its contents`} placement='left'>
            <ButtonSecondary
              className='borderless'
              mixpanelEvent='Report Editor - Delete Page'
              icon={<DeleteOutlined />}
              size='large'
              onClick={() => reportTemplateStore.deletePageBlock(block.id)}
            />
          </Tooltip>
        </>
      )}
    </section>
  );
};

export default observer(PageComponent);
