import React from 'react';
import SubLayoutSettings from '../../layouts/SubLayoutSettings';
import AccountNavbar from '../../components/accountSettings/AccountNavbar';
import AccountHeader from '../../components/accountSettings/Header';
import FirmInformation from '../../components/accountSettings/FirmInformation';

const Firm = () => (
  <SubLayoutSettings
    SubNav={(<AccountNavbar />)}
    HeaderContent={(<AccountHeader />)}
    SubMain={(<FirmInformation />)}
  />
);

export default Firm;
