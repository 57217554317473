import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, MenuProps, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CheckOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';

import Search from 'antd/es/input/Search';
import { ClientManagementEnums, ClientManagementTypes } from '@aider/constants-library';
import { FeatureFlags } from '@aider/constants-library/dist/enums/split';
import { useStore } from '../../stores/Store';
import ClientListRedirect from '../dashboard/ClientListRedirect';
import { InsightTab } from '../../stores/v1/pageStore';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { NonSalesTaxIcon, QuickbooksLogoScaleable, SalesTaxIcon, XeroLogoScaleable } from '../icons';
import { ClientManagementDataType } from '../../models/interfaces/stores';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import XeroImportHover from '../icons/XeroImportHover';
import XeroImport from '../icons/XeroImport';
import QuickBooksImportHover from '../icons/QuickBooksImportHover';
import QuickBooksImport from '../icons/QuickBooksImport';
import SectionReconnect from '../dataCompliance/SectionReconnect';

const ActivateClientTable = ({ savingData }: {savingData: boolean}) => {
  const rootStore = useStore();
  const { businessesStore, clientManagementStore, connectionsStore, localeStore, practiceStore, splitStore } = rootStore;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [clientCount, setClientCount] = useState(clientManagementStore.filteredRows.length ?? 0);
  const [activatedClientCount, setActivatedClientCount] = useState(businessesStore.activeClientBusinesses?.size ?? 0);

  const [xeroHovered, setXeroHovered] = React.useState(false);
  const [qbHovered, setQbHovered] = React.useState(false);

  useEffect(() => {
    setSelectedRows(businessesStore.selectedForActivation);
  }, [businessesStore?.selectedForActivation]);

  useEffect(() => {
    setClientCount(clientManagementStore.filteredRows.length);
    setActivatedClientCount(clientManagementStore.filteredRows.reduce((total, row) => (
      row.activationStatus === ClientManagementEnums.BusinessActivationStatus.activated ? total + 1 : total
    ), 0));
  }, [clientManagementStore.filteredRows]);

  const handleNameClick = (business: ClientManagementTypes.ClientManagementRow) => {
    if (business.activationStatus === ClientManagementEnums.BusinessActivationStatus.activated) {
      ClientListRedirect({ businessId: business.id, businessName: business.name }, InsightTab.overview);
      navigate('/insights');
    } else if (businessesStore.selectedForActivation.indexOf(business.id) > -1) {
      businessesStore.selectedForActivation = businessesStore.selectedForActivation.filter((id) => id !== business.id);
    } else {
      businessesStore.selectedForActivation = [...businessesStore.selectedForActivation, business.id];
    }
  };
  const activationStatusFilters = [
    {
      value: `${ClientManagementEnums.BusinessActivationStatus.activated}-${ClientManagementEnums.PlatformSupportStatus.supported}`,
      text: localeStore.translation('client-management.filters.activation-status.active-supported')
    },
    {
      value: `${ClientManagementEnums.BusinessActivationStatus.activated}-${ClientManagementEnums.PlatformSupportStatus.notSupported}`,
      text: localeStore.translation('client-management.filters.activation-status.active-unsupported')
    },
    {
      value: `${ClientManagementEnums.BusinessActivationStatus.notActivated}-${ClientManagementEnums.PlatformSupportStatus.supported}`,
      text: localeStore.translation('client-management.filters.activation-status.inactive-supported')
    },
    {
      value: `${ClientManagementEnums.BusinessActivationStatus.notActivated}-${ClientManagementEnums.PlatformSupportStatus.notSupported}`,
      text: localeStore.translation('client-management.filters.activation-status.inactive-unsupported')
    }
  ];

  const clientInfoColumns = [{
    title: localeStore.translation('client-management.header.org-type.label'),
    dataIndex: 'organisationType',
    sorter: (a, b) => a.organisationType.localeCompare(b.organisationType),
    filters: clientManagementStore.dashboardFilters?.organizationType || [],
    filterSearch: true,
    onFilter: (value, record) => record.organisationType === value,
    render: (record) => (
      <Tooltip
        placement='right'
        title={record}
      >{record}
      </Tooltip>
    )
  },
  {
    title: localeStore.translation('client-management.header.subscription-type.label'),
    dataIndex: 'subscriptionType',
    sorter: (a, b) => a.subscriptionType.localeCompare(b.subscriptionType),
    render: (subscription, record) => (
      <Tooltip
        placement='right'
        title={subscription}
      >
        <span className='aider-column__logos'>
          {record.ospType.map((osp) => {
            if (osp === ClientManagementEnums.OSPKeys.intuit) {
              return <QuickbooksLogoScaleable />;
            }
            return <XeroLogoScaleable />;
          })}
        </span>
        {subscription}
      </Tooltip>
    ),
    filters: clientManagementStore.dashboardFilters?.subscription || [],
    filterSearch: true,
    onFilter: (value, record) => {
      if ((value === ClientManagementEnums.OSPKeys.intuit
        || value === ClientManagementEnums.OSPKeys.xero)
        && record.ospType.indexOf(value) > -1) {
        return true;
      }
      if (record.subscriptionType === value) {
        return true;
      }
      return false;
    },
  },
  {
    dataIndex: 'salesTaxBasis',
    title: localeStore.translation('client-management.header.tax-basis.label'),
    sorter: (a, b) => a.salesTaxBasis.localeCompare(b.salesTaxBasis),
    filters: clientManagementStore.dashboardFilters.salesTaxBasis,
    filterSearch: true,
    onFilter: (value, record) => record.salesTaxBasis === value,
    render: (record) => (
      <Tooltip
        placement='right'
        title={record}
      >{record}
      </Tooltip>
    )
  },
  {
    dataIndex: 'industry',
    title: localeStore.translation('client-management.header.industry.label'),
    sorter: (a, b) => a.industry.localeCompare(b.industry),
    filters: clientManagementStore.dashboardFilters.industries,
    filterSearch: true,
    onFilter: (value, record) => record.industry === value,
    render: (record) => (
      <Tooltip
        placement='left'
        title={record}
      >{record}
      </Tooltip>
    )
  }
  ];

  // Remove columns and filters for US practices
  if (practiceStore.isUSPractice) {
    clientInfoColumns.splice(2, 1);
    activationStatusFilters.splice(2, 1);
    activationStatusFilters.splice(0, 1);
  }

  const onImportButtonClick = async ({ key }) => {
    connectionsStore.connectionType = key;
    setLoading(true);
    trackMixpanelEvent({
      description: 'Client management: Import button clicked',
      properties: { importOSP: key },
      rootStore
    });
    connectionsStore.modalAddConnection();
  };

  const importItems: MenuProps['items'] = [
    {
      key: 'heading',
      label: (<h6>Import more clients:</h6>),
    },
    {
      key: ClientManagementEnums.OSPKeys.xero,
      label: (
        <ButtonSecondary
          shape='default'
          className='osp-buttons xero'
          size='large'
          onMouseEnter={() => setXeroHovered(true)}
          onMouseLeave={() => setXeroHovered(false)}
          onClick={() => onImportButtonClick({ key: ClientManagementEnums.OSPKeys.xero })}
        >
          {xeroHovered
            ? <XeroImportHover className='osp-buttons__icon' />
            : <XeroImport className='osp-buttons__icon' />}
        </ButtonSecondary>
      )
    },
    {
      key: ClientManagementEnums.OSPKeys.intuit,
      label: (
        <ButtonSecondary
          shape='default'
          className='osp-buttons intuit'
          size='large'
          onMouseEnter={() => setQbHovered(true)}
          onMouseLeave={() => setQbHovered(false)}
          onClick={() => onImportButtonClick({ key: ClientManagementEnums.OSPKeys.intuit })}
        >
          {qbHovered
            ? <QuickBooksImportHover className='osp-buttons__icon' />
            : <QuickBooksImport className='osp-buttons__icon' />}
        </ButtonSecondary>
      )
    }
  ];

  const importMenu = {
    items: importItems,
  };
  const columns: ColumnsType<ClientManagementDataType> = [
    {
      title: (
        <div className='client-header'>
          <Tooltip
            placement='bottom'
            title={localeStore.translation('client-management.header.client-count.tooltip', {
              count: clientCount,
              totalCount: businessesStore.clientBusinesses?.size
            })}
          >
            <span className='client-count'>
              {
                localeStore.translation(
                  'client-management.header.client-count.label',
                  { count: clientCount }
                )
              }
            </span>
          </Tooltip>
          <Dropdown menu={importMenu} className='import-button__dropdown' overlayClassName='import-button__dropdown'>
            <ButtonSecondary
              className='import-button'
              loading={loading}
              icon={<DownloadOutlined />}
              mixpanelEvent='Client management: Import button clicked'
            >
              <span className='import-text'>
                {
                  localeStore.translation('client-management.import.label')
                }
              </span>
            </ButtonSecondary>
          </Dropdown>
        </div>
      ),
      className: 'aider-column',
      dataIndex: 'name',
      children: [
        {
          className: 'aider-column',
          title: localeStore.translation(
            'client-management.header.selected-count.label',
            { count: selectedRows.length }
          ),
          dataIndex: 'name',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.name.localeCompare(b.name),
          filterDropdown: () => (
            <Search
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => { clientManagementStore.searchString = e.currentTarget.value; }}
              placeholder={localeStore.translation('client-management.filters.search')}
              allowClear
            />
          ),
          filterIcon: () => (
            <SearchOutlined className='anticon-filter' />
          ),
          onFilter: (value, record) => record.name.toLowerCase().includes((value as string).toLowerCase()),
          render: (name, record) => (
            <>
              <Tooltip
                placement='right'
                title={record.activationStatus === ClientManagementEnums.BusinessActivationStatus.notActivated
                  ? selectedRows.indexOf(record.id) > -1
                    ? localeStore.translation('client-management.body.name.deselect', { name })
                    : localeStore.translation('client-management.body.name.select', { name })
                  : localeStore.translation('client-management.body.name.view', { name })}
              >
                <ButtonPrimary
                  key={record.key}
                  className='business-name-button'
                  type='link'
                  onClick={() => handleNameClick(record)}
                  mixpanelEvent='Client management: Client name clicked'
                >{name}
                </ButtonPrimary>
              </Tooltip>
            </>
          )
        },

      ]
    },
    {
      title: 'Advisory Platform',
      dataIndex: 'activationStatus',
      className: 'aider-column',
      children: [
        {
          className: 'aider-column aider-column__centered',
          title: (
            <Tooltip
              placement='bottom'
              title={
                localeStore.translation(
                  'client-management.header.active-count.tooltip',
                  {
                    count: activatedClientCount,
                    totalCount: clientManagementStore.filteredRows.length ?? 0
                  }
                )
              }
            >
              <span>
                {
                  localeStore.translation(
                    'client-management.header.active-count.label',
                    { count: activatedClientCount }
                  )
                }
              </span>
            </Tooltip>
          ),
          dataIndex: 'activationStatus',
          sorter: (a, b) => {
            if (a.activationStatus === ClientManagementEnums.BusinessActivationStatus.activated
              && b.activationStatus === ClientManagementEnums.BusinessActivationStatus.notActivated
            ) {
              return -1;
            }

            if (a.activationStatus === ClientManagementEnums.BusinessActivationStatus.notActivated
              && b.activationStatus === ClientManagementEnums.BusinessActivationStatus.activated) {
              return 1;
            }

            return 0;
          },
          filters: activationStatusFilters,
          onFilter: (value, record) => {
            const strVal = value as string;
            const parts = strVal.split('-');
            const activated = parts[0];
            const supported = parts[1];

            if (practiceStore.isUSPractice) {
              return activated === record.activationStatus;
            }

            return activated === record.activationStatus && supported === record.supported;
          },
          render: (activated, record) => (
            <span className='aider-column__activation'>
              <Tooltip
                placement='right'
                title={activated === ClientManagementEnums.BusinessActivationStatus.activated
                  ? localeStore.translation('client-management.body.activation.active')
                  : localeStore.translation('client-management.body.activation.inactive')}
              >{activated === ClientManagementEnums.BusinessActivationStatus.activated
                ? <CheckOutlined className='activated-icon' />
                : localeStore.translation('client-management.body.activation.inactive')}
                {record.dataStatus === 'disconnected'
                  && (
                  <>
                    <span className='disconnected'>
                      Disconnected
                    </span>
                    <SectionReconnect buttonOnly />
                  </>
                  )}
              </Tooltip>
              {!practiceStore.isUSPractice && (
                <>
                  {record.supported === ClientManagementEnums.PlatformSupportStatus.notSupported && (
                    <Tooltip
                      placement='bottom'
                      title={localeStore.translation('client-management.body.support-status.unsupported')}
                    >
                      <NonSalesTaxIcon className='secondary-icon' />
                    </Tooltip>
                  )}
                  {record.supported === ClientManagementEnums.PlatformSupportStatus.supported && (
                    <Tooltip
                      placement='bottom'
                      title={localeStore.translation('client-management.body.support-status.supported')}
                    >
                      <SalesTaxIcon className='primary-icon' />
                    </Tooltip>
                  )}
                </>
              )}
            </span>
          )
        }
      ],
    },
    {
      title: localeStore.translation('client-management.header.client-info.label'),
      align: 'left',
      children: clientInfoColumns
    },
  ];

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'filter') {
      const filteredData = extra.currentDataSource;
      const activatedCount = filteredData.filter((item) => item.activationStatus === ClientManagementEnums.BusinessActivationStatus.activated).length;
      setActivatedClientCount(activatedCount);
      setClientCount(filteredData.length);
    }
  };

  return (clientManagementStore.filteredRows && (
    <Table
      className='scrollbar'
      sticky={{ getContainer: () => document.querySelector('.client-management') as HTMLElement }}
      dataSource={clientManagementStore.filteredRows}
      columns={columns}
      pagination={{
        className: 'aider-pagination',
        position: ['topLeft', 'bottomLeft'],
        defaultPageSize: 50,
        showSizeChanger: false,
        hideOnSinglePage: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
      }}
      showSorterTooltip={false}
      onChange={handleTableChange}
      rowSelection={{
        type: 'checkbox',
        columnWidth: 30,
        onChange: (changedRowKeys: React.Key[]) => {
          businessesStore.setBusinessForActivation(changedRowKeys as string[]);
        },
        selectedRowKeys: selectedRows,
        getCheckboxProps: (record: ClientManagementDataType) => ({
          disabled: (!splitStore.featureEnabled(FeatureFlags.BulkDeactivate)
            && record.activationStatus === ClientManagementEnums.BusinessActivationStatus.activated)
            || savingData,
          name: record.name,
        }),
      }}
    />
  )
  );
};

export default observer(ActivateClientTable);
