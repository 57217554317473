import React from 'react';
import { observer } from 'mobx-react';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { AiderBrand } from '../components/icons';
import HeroLaptopIllustration from '../components/illustrations/HeroLaptopIllustration';
import SocialButtons from '../components/signin/SocialButtons';
import ButtonPrimary from '../components/customAntD/ButtonPrimary';
import { Routers } from '../models/enums/utils';
import { useStore } from '../stores/Store';
import Root from '../components/Root';

const SignUp = () => {
  const { authenticationStore, loadingStore } = useStore();
  const navigate = useNavigate();

  if (authenticationStore.loginError
    && loadingStore.isLoading('appReferral')) {
    loadingStore.setDoneLoading('appReferral');
  }

  return (
    <>
      <Root />
      {!loadingStore.isAnyLoading && (
      <main className='signup-page'>
        <section className='signup-page__col'>
          <div className='signup-page__col__content'>
            <header className='signup-page__header'>
              <AiderBrand />
            </header>
            <div className='signup-page__illustration'>
              <HeroLaptopIllustration />
            </div>
            <div className='signup-page__col__content'>
              <h1 className='signup-page__title'>Create an Aider account for your firm.</h1>
              <p className='signup-page__subtitle'>Unlock proactive advisory tools to help you nail period close & scale advisory.</p>
            </div>
          </div>
        </section>
        <section className='signup-page__col'>
          <div className='signup-page__col__content'>
            <SocialButtons registration />
            <section className='login-page__links'>
              <p className='login-page__links__link'>
                <span className='login-page__links__link__text'>Already have a login?</span>
                <ButtonPrimary
                  icon={<RightOutlined />}
                  type='link'
                  onClick={() => {
                    authenticationStore.loginError = false;
                    navigate(Routers.LOGIN);
                  }}
                >Log in
                </ButtonPrimary>
              </p>
              <p className='login-page__links__link'>
                <span className='login-page__links__link__text'>Need access to your firm's account?</span>
                <ButtonPrimary
                  icon={<RightOutlined />}
                  type='link'
                  href='https://ask.aider.ai/help/get-started-advisory-platform#Q2'
                  target='_blank'
                >Find out how
                </ButtonPrimary>
              </p>
            </section>
          </div>
        </section>
      </main>
      )}
    </>
  );
};

export default observer(SignUp);
