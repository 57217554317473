import React from 'react';
import { observer } from 'mobx-react';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import AlertIllustration from '../illustrations/AlertIllustration';
import { mailToHref } from '../../lib/supportHelpers';

const ComplianceChecklistMissing = () => {
  const rootStore = useStore();
  const { checklistStore, businessesStore } = rootStore;
  const mailTo = mailToHref({
    subject: 'Missing Checklist',
    body: 'Hi Aider,\n\nI\'m missing my Period Close checklist.',
    technicalDetails: [
      { key: 'Checklist Type', value: checklistStore.activeChecklistType },
      { key: 'Checklist Period', value: checklistStore.activePeriod },
      { key: 'Error', value: checklistStore?.businessChecklists?.get(businessesStore.selectedBusinessId)?.get(checklistStore.activeChecklistType)?.error }
    ],
    includeAdditionalDetails: true,
    rootStore
  });

  return (
    <>
      <div className='insight-missing'>
        <AlertIllustration />
        <h4>
          {ComplianceChecklistEnums.TypeNames?.[checklistStore.activeChecklistType] || 'Checklist'} not available
        </h4>
        <div className='insight-missing__body'>
          <p>This could be caused by the relevant data not being ready or available from Xero.</p>
          <p>
            If this issue persists, please contact{' '}
            <a
              href={mailTo}
              onClick={() => {
                window.open(mailTo, '_blank');
              }}
            >Customer Success
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default observer(ComplianceChecklistMissing);
