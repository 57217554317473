// TODO: Refactor this file to remove all the eslint-disable
import * as React from 'react';
import { observer } from 'mobx-react';
import { ClientManagementEnums } from '@aider/constants-library';
import { DateFormats } from '@aider/aider-formatting-library';
import alert from '../../entities/icons/alert_indicator.png';
import tick from '../../entities/icons/tick_upscale.png';
import { useStore } from '../../stores/Store';

function RenderDataHealthIcon(icon) {
  switch (icon) {
    case 'alert':
      return alert;
    case 'tick':
      return tick;
    default:
      return icon;
  }
}

interface InsightDataHealthHeaderProps {
  data: {
    icon: string;
    color: string;
    header: string;
    text: string;
    link?: string;
    parts?: {
      month: string;
      completed: boolean;
      reconciledDate: string;
      unreconciledItems: number;
    }
  };
}

// TODO: Split the text into parts with separate date to enable date formatting
const InsightDataHealthHeader = ({ data }:InsightDataHealthHeaderProps) => {
  const { localeStore, businessesStore } = useStore();
  return (
    data?.parts ? (
      <div className='dataHealthStatus'>
        <img width='20' src={RenderDataHealthIcon(data.icon)} alt='' />{' '}
        <b style={{ color: data.color }}>
          {localeStore.translation(`insights.dataHealthStatus.header.${data.parts.completed ? 'complete' : 'incomplete'}`, { month: data.parts.month })}
        </b>
        {data.parts?.reconciledDate && (
          localeStore.translation('insights.dataHealthStatus.reconciled', { date: localeStore.formatDate(data.parts.reconciledDate, DateFormats.string) })
        )}
        {data.parts?.unreconciledItems && (
          localeStore.translation('insights.dataHealthStatus.unreconciled', { count: data.parts.unreconciledItems })
        )}
        {data.parts?.unreconciledItems && data.link && (
          <>{' - '}</>
        )}
        {data.link && (
          <a href={data.link} target='_blank' rel='noreferrer'>
            <b>
              {
                localeStore.translation('insights.dataHealthStatus.link', {
                  osp: localeStore.translation(`osps.${businessesStore.selectedBusiness?.osp || ClientManagementEnums.OSPKeys.xero}`),
                })
              }
            </b>
          </a>
        )}
      </div>
    ) : (
      <div className='dataHealthStatus'>
        <img width='20' src={RenderDataHealthIcon(data.icon)} alt='' /> <b style={{ color: data.color }}>{data.header}</b>
        {data.text}
        {data.link ? <a href={data.link} target='_blank' rel='noreferrer'><b>Fix in Xero &gt;</b></a> : null}
      </div>
    )
  );
};

export default observer(InsightDataHealthHeader);
