import React from 'react';
import AlertIllustration from '../../illustrations/AlertIllustration';
import { truncateString } from '../../../lib/stringMutation';

const DeletePromptContent = ({ promptName }: {promptName: string}) => (
  <section className='rule-modal__body centered-modal'>
    <div className='illustration'>
      <AlertIllustration />
    </div>
    <h3>
      Delete Prompt
    </h3>
    <p>
      Are you sure you want to delete the prompt:
    </p>
    <p>&quot;{truncateString(promptName, 33)}&quot;</p>
  </section>
);

export default DeletePromptContent;
