import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Alert } from 'antd';
import { AiderLogoIcon } from '../../icons';

const AddClientModalPending = () => {
  const [showExit, setShowExit] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowExit(true);
    }, 10000);
    return () => clearTimeout(timeout);
  });

  return (
    <div className='centered-modal add-client-modal'>
      <AiderLogoIcon className='xero-aider-logo__aider' />

      <h2>Connecting clients</h2>
      <p>We are loading your clients information from your accounting platform.</p>
      {showExit && (
        <Alert type='info' message='This is taking longer than expected. You can close this screen and it will continue in the background.' />
      )}
    </div>
  );
};

export default observer(AddClientModalPending);
