import mp from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';
import { IMixpanelTracking } from '../models/interfaces/mixpanel';

const envCheck = () => ['production', 'staging'].includes(process.env.REACT_APP_MODE);

if (envCheck()) {
  mp.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    api_host: process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN,
  });
}

const Mixpanel = {
  identify: (id) => {
    if (envCheck()) mp.identify(id);
  },
  alias: (id) => {
    if (envCheck()) mp.alias(id);
  },
  track: (name, props = {}) => {
    if (envCheck()) mp.track(name, props);
  },
  people: {
    set: (props) => {
      if (envCheck()) mp.people.set(props);
    },
  },
};

export default Mixpanel;

export const trackMixpanelEvent = ({
  description,
  properties = null,
  rootStore,
}: IMixpanelTracking): void => {
  if (process.env.NODE_ENV !== 'test') {
    const eventProperties = {
      businessId: rootStore.businessesStore.selectedBusinessId,
      businessName: rootStore.businessesStore.selectedBusiness?.name,
      ...properties,
    };
    try {
      Mixpanel.track(description, eventProperties);
      if ((window as any).Intercom) {
        (window as any).Intercom('trackEvent', description, eventProperties);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
};
