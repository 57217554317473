import React from 'react';

const AlertIllustration = () => (
  <svg viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='60' cy='60' r='60' fill='#F5F5F9' />
    <path d='M86.7613 85.375C89.0449 85.375 90.4912 82.9252 89.388 80.9258L62.4695 32.136C61.3287 30.0683 58.3568 30.0683 57.216 32.136L30.2975 80.9258C29.1944 82.9252 30.6407 85.375 32.9243 85.375L86.7613 85.375Z' fill='white' />
    <path d='M91.8425 84.3742C91.8425 84.7263 91.6565 85.0676 91.3269 85.2502C90.8435 85.5178 90.2351 85.3425 89.9675 84.8596L59.8425 30.4402L29.7175 84.8596C29.4499 85.3425 28.8415 85.5178 28.3581 85.2502C27.8757 84.9826 27.7009 84.3738 27.9675 83.8908L58.9675 27.8908C59.1438 27.5725 59.4787 27.3752 59.8425 27.3752C60.2063 27.3752 60.5412 27.5725 60.7175 27.8908L91.7175 83.8908C91.8025 84.0442 91.8425 84.2107 91.8425 84.3742Z' fill='#EB5757' />
    <path d='M91.8428 84.375C91.8428 84.9272 91.395 85.375 90.8428 85.375L49.8428 85.375C49.2905 85.375 48.8428 84.9273 48.8428 84.375C48.8428 83.8227 49.2905 83.375 49.8428 83.375L90.8428 83.375C91.395 83.375 91.8428 83.8227 91.8428 84.375Z' fill='#EB5757' />
    <path d='M43.8428 84.375C43.8428 84.9272 43.395 85.375 42.8428 85.375L28.8428 85.375C28.2905 85.375 27.8428 84.9273 27.8428 84.375C27.8428 83.8227 28.2905 83.375 28.8428 83.375L42.8428 83.375C43.395 83.375 43.8428 83.8227 43.8428 84.375Z' fill='#EB5757' />
    <path d='M47.843 84.375C47.843 84.6352 47.7327 84.895 47.553 85.085C47.363 85.2651 47.1028 85.375 46.843 85.375C46.5828 85.375 46.323 85.2651 46.1331 85.085C45.7629 84.7153 45.7629 84.0352 46.1331 83.665C46.323 83.4853 46.5828 83.375 46.843 83.375C47.1028 83.375 47.363 83.4853 47.553 83.665C47.7327 83.855 47.843 84.1152 47.843 84.375Z' fill='#EB5757' />
    <path d='M84.843 80.375C84.843 80.6352 84.7327 80.895 84.553 81.085C84.363 81.2651 84.1028 81.375 83.843 81.375C83.5828 81.375 83.323 81.2651 83.1331 81.085C82.7629 80.7051 82.7629 80.0454 83.1331 79.665C83.323 79.4853 83.5828 79.375 83.843 79.375C84.1028 79.375 84.363 79.4853 84.553 79.665C84.7327 79.855 84.843 80.1152 84.843 80.375Z' fill='#EB5757' />
    <path d='M80.843 80.375C80.843 80.6352 80.7327 80.895 80.553 81.085C80.363 81.2651 80.1028 81.375 79.843 81.375C79.5828 81.375 79.323 81.2651 79.1331 81.085C78.7629 80.7051 78.7629 80.0352 79.1331 79.665C79.323 79.4853 79.5828 79.375 79.843 79.375C80.1028 79.375 80.363 79.4853 80.553 79.665C80.7327 79.855 80.843 80.1152 80.843 80.375Z' fill='#EB5757' />
    <path d='M76.843 80.375C76.843 80.6352 76.7327 80.895 76.553 81.085C76.363 81.2651 76.1028 81.375 75.843 81.375C75.5828 81.375 75.323 81.2651 75.1331 81.085C74.7629 80.7051 74.7629 80.0454 75.1331 79.665C75.323 79.4853 75.5828 79.375 75.843 79.375C76.1028 79.375 76.363 79.4853 76.553 79.665C76.7327 79.855 76.843 80.1152 76.843 80.375Z' fill='#EB5757' />
    <path d='M80.843 76.375C80.843 76.6352 80.7327 76.895 80.553 77.085C80.363 77.2651 80.1028 77.375 79.843 77.375C79.5828 77.375 79.323 77.2651 79.1331 77.085C78.7629 76.7051 78.7629 76.0352 79.1331 75.665C79.323 75.4853 79.5828 75.375 79.843 75.375C80.113 75.375 80.363 75.4853 80.553 75.665C80.7327 75.855 80.843 76.105 80.843 76.375Z' fill='#EB5757' />
    <path d='M76.843 76.375C76.843 76.6352 76.7327 76.895 76.553 77.085C76.363 77.2651 76.1028 77.375 75.843 77.375C75.5828 77.375 75.323 77.2651 75.1331 77.085C74.7629 76.7051 74.7629 76.0352 75.1331 75.665C75.323 75.4853 75.5828 75.375 75.843 75.375C76.1028 75.375 76.363 75.4853 76.553 75.665C76.7327 75.855 76.843 76.1152 76.843 76.375Z' fill='#EB5757' />
    <path d='M80.843 72.375C80.843 72.6352 80.7327 72.895 80.553 73.085C80.363 73.2651 80.113 73.375 79.843 73.375C79.5828 73.375 79.323 73.2651 79.1331 73.085C78.7629 72.7153 78.7629 72.0352 79.1331 71.665C79.323 71.4853 79.5828 71.375 79.843 71.375C80.1028 71.375 80.363 71.4853 80.553 71.665C80.7327 71.855 80.843 72.1152 80.843 72.375Z' fill='#EB5757' />
    <path d='M76.843 68.375C76.843 68.6352 76.7327 68.895 76.553 69.085C76.363 69.2651 76.1028 69.375 75.843 69.375C75.5828 69.375 75.323 69.2651 75.1331 69.085C74.7629 68.7153 74.7629 68.0454 75.1331 67.665C75.323 67.4853 75.5828 67.375 75.843 67.375C76.1028 67.375 76.363 67.4853 76.553 67.665C76.7327 67.855 76.843 68.1152 76.843 68.375Z' fill='#EB5757' />
    <path d='M59.8428 46.375L59.8428 68.3751' stroke='#EB5757' strokeWidth='2' strokeLinecap='round' />
    <path d='M59.8428 73.375V74.3749' stroke='#EB5757' strokeWidth='2.5' strokeLinecap='round' />
  </svg>
);

export default AlertIllustration;
