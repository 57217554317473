// TODO: Refactor this file to remove all the eslint-disabled
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { rootStore } from '../stores/Store';
import infoIcon from './icons/info.png';
import logoIcon from './icons/logo_icon.png';

// eslint-disable-next-line react/prop-types
export const ModalHeader = ({ isNew = false, text }) => (
  <div style={{ width: '100%', marginBottom: isNew ? 20 : 40 }}>
    <h4 className='metadata modalHeader'>{text}</h4>
  </div>
);

export interface IHelpState {
  visible: any;
}

export const AiderModal = ({
  // eslint-disable-next-line react/prop-types
  showIcon = null,
  // eslint-disable-next-line react/prop-types
  altIcon = null,
  // eslint-disable-next-line react/prop-types
  header = null,
  // eslint-disable-next-line react/prop-types
  transitionText = null,
  // eslint-disable-next-line react/prop-types
  subtext = null,
  // eslint-disable-next-line react/prop-types
  subtext2 = null,
  // eslint-disable-next-line react/prop-types
  subtext3 = null,
  // eslint-disable-next-line react/prop-types
  infoText = null,
  // eslint-disable-next-line react/prop-types
  seeMoreText = null,
  // eslint-disable-next-line react/prop-types
  seeMoreToggled = false,
  // eslint-disable-next-line react/prop-types
  cancelButtonFunction = null,
  // eslint-disable-next-line react/prop-types
  cancelButtonText = null,
  // eslint-disable-next-line react/prop-types
  actionButtonFunction = null,
  // eslint-disable-next-line react/prop-types
  actionButtonText = null,
  // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
  centerText = false
}) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    id='metadataModal'
    className='confirm-modal-container fadedshort'
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    style={{ zIndex: 999999 }}
  >
    <div className='card--panel metadata-card aiderModal'>
      {showIcon && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          {altIcon ? (
            <img src={altIcon} width={178} height={70} alt='' />
          ) : (
            <img src={logoIcon} width={54} alt='' />
          )}
        </div>
      )}
      <ModalHeader text={header} />
      <div
        style={{ paddingLeft: 40, paddingRight: 40 }}
        className={transitionText ? 'fadedShort' : ''}
      >
        <p
          style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}
          className={transitionText ? 'fadedShort' : ''}
        >
          {subtext && subtext}
        </p>
        <p
          style={{ fontSize: 16 }}
          className={transitionText ? 'fadedShort' : ''}
        >
          {subtext2 && subtext2}
        </p>
        <p
          style={{ fontSize: 16 }}
          className={transitionText ? 'fadedShort' : ''}
        >
          {subtext3 && subtext3}
        </p>
        {infoText && (
          <div style={{ fontSize: 14, display: 'flex' }}>
            <img src={infoIcon} height={30} alt='' />
            <p style={{ paddingLeft: 10 }}>{infoText}</p>
          </div>
        )}
        {seeMoreText && !seeMoreToggled && (
          <p className='fadedshort'>
            { /* eslint-disable-next-line max-len  */}
            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => rootStore.businessStore.toggleSeeMore(true)}
              style={{ color: '#4264ea', cursor: 'pointer', fontSize: 14 }}
            >
              See More
            </div>
          </p>
        )}
        {seeMoreToggled && (
          <div className='fadedshort' style={{ fontSize: 14 }}>
            <p style={{ paddingLeft: 10 }}>{seeMoreText}</p>
            <br />
            { /* eslint-disable-next-line max-len  */}
            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              style={{ color: '#4264ea', cursor: 'pointer', fontSize: 14 }}
              onClick={() => rootStore.businessStore.toggleSeeMore(false)}
            >
              See Less
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: cancelButtonFunction
            ? 'space-evenly'
            : 'center',
          paddingTop: 20,
        }}
      >
        {cancelButtonFunction && (
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className='actionButtonNew aiderModalButton cancelButton'
            onClick={() => {
              cancelButtonFunction();
            }}
          >
            {cancelButtonText}
          </div>
        )}
        {actionButtonFunction && (
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className='actionButtonNew aiderModalButton'
            onClick={() => {
              actionButtonFunction();
            }}
          >
            {actionButtonText}
          </div>
        )}
      </div>
    </div>
  </div>
);

export function contains(target: string, pattern: string[]) {
  let value = 0;
  pattern.forEach((word) => { value += target.includes(word) ? 1 : 0; });
  return value >= 1;
}

// eslint-disable-next-line consistent-return
export function closeModal(this: any, e) {
  // it is pretty optimistic to assume anything being clicked on the screen
  // is a primitive html object with a class, so we have a few checks here.
  if (e.target.className !== undefined) {
    try {
      this.props.authStore.triggerDropdownOpen(false);
      const testStr = e.target.className.split(/[ -]/);
      const containsContainer =
        contains(testStr, ['feedback', 'dropdownOption', 'dropdown'])
        && e.target.className !== '';
      const containsMetadata =
        testStr.includes('metadata') && e.target.className !== '';
      const containsClient =
        e.target.className.split(/[ -]/).includes('client')
        && e.target.className !== '';
      if (
        e.target.className === 'closeModal'
        || ((this.state.feedbackModalVisible
          || this.state.clientModalVisible
          || this.state.metadataModalVisible
          || this.state.actionModalVisible
          || this.state.editClientModalVisible
          || this.state.editUserModalVisible
          || this.state.isNiceModalOpen)
          && !containsContainer
          && !containsClient
          && !containsMetadata
          && typeof e.target !== 'undefined')
      ) {
        return this.setState({
          editUserModalVisible: false,
          editClientModalVisible: false,
          infoModalVisible: false,
          feedbackModalVisible: false,
          actionModalVisible: false,
          clientModalVisible: false,
          metadataModalVisible: false,
          feedbackElementId: null,
          messageId: null,
          messageToGiveFeedback: null,
          feedbackType: null,
          metaData: null,
          metadataApps: null,
          actionType: null,
          isNiceModalOpen: false
        });
      }
      if (this.props.authStore.editUserModalVisible) {
        this.props.authStore.validateUserModal(null);
        this.props.authStore.renderEditUserModal(false);
      }

      // eslint-disable-next-line no-empty
    } catch (err) { }
  }
  // do nothing if undefined (special html object)
}

export const smallLoadingComponentWrapped = (
  <div
    style={{
      height: 120,
      width: 180,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <div className='preloader-wrapper small active'>
      <div className='spinner-layer spinner-blue-only'>
        <div className='circle-clipper left'>
          <div className='circle' />
        </div>
        <div className='gap-patch'>
          <div className='circle' />
        </div>
        <div className='circle-clipper right'>
          <div className='circle' />
        </div>
      </div>
    </div>
  </div>
);

// eslint-disable-next-line react/prop-types
export const AppAccountKickback = ({ loginType }) => (
  <AiderModal
    header='App account detected'
    subtext={
      "Oops! It looks like you're trying to sign into the Advisory Platform with a client app login."
    }
    subtext2={
      loginType === 'xero'
        ? 'If you are trying to use Xero to sign in, make sure you are logged into the correct Xero account for your practice and try again.'
        : 'Please click below to be redirected to the Aider mobile app, where you can sign in to the app.'
    }
    actionButtonFunction={() => (
      loginType === 'xero'
        ? rootStore.authStore.LogoutAndRedirect('https://go.xero.com/logout')
        : window.open('https://aider.ai', '_self')
    )}
    actionButtonText={
      loginType === 'xero'
        ? 'Log me out of Xero'
        : 'Redirect me to the App'
    }
    showIcon
  />
);

// eslint-disable-next-line react/prop-types
export const ModalOverlay = ({ toggleModal, toggleOtherModal, toggleNiceModal }) => {
  if (typeof toggleOtherModal !== 'undefined' && toggleOtherModal && toggleNiceModal) {
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        onClick={() => {
          toggleModal(null);
          rootStore.authStore.renderEditUserModal(false);
          rootStore.actionStore.setBrandFileUploadVisible(false);
        }}
        className='modalOverlay'
        style={{
          position: 'absolute',
          height: '100%',
          width: '95.96vw',
          backgroundColor: 'black',
          opacity: '0.45',
          zIndex: 1000,
        }}
      />
    );
  }
  return null;
};

export const Welcome = (
  { imgHeight = null, imgWidth = null, img = null, heading = null, body = null } :
  {
    imgHeight?: number,
    imgWidth?: number,
    img: string,
    heading: string,
    body: any
  }
) => (
  <div className='fadedshort' style={{ padding: 40, color: '#505050' }}>
    <div
      style={{
        padding: 0,
        marginTop: 20,
        borderRadius: 16,
        height: 270,
      }}
    >
      <div
        style={{
          color: '#7e7e7e',
          fontSize: 18,
          fontWeight: 500,
          lineHeight: '32px',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 30,
            }}
          >
            <img
              height={imgHeight || 60}
              width={imgWidth || 60}
              src={img}
              alt=''
            />
          </div>
          <span
            style={{
              fontSize: 20,
              color: '#0D206C',
              justifyContent: 'center',
              display: 'flex',
              fontWeight: 700,
            }}
          >
            {heading}
          </span>
          <div
            style={{
              textAlign: 'center',
              padding: 10,
              paddingLeft: 30,
              paddingRight: 30,
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'break-spaces',
            }}
          >
            <div
              style={{
                color: '#272830',
                fontSize: 16,
                fontWeight: 400,
                maxWidth: '36vw',
              }}
            >
              {body}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Welcome.defaultProps = {
  imgWidth: null,
  imgHeight: null
};

export const TabMessage = (
  { img, heading, body, actionText, actionText2, buttonAction, buttonAction2 }
    : {
      img: string,
      heading: string,
      body: string,
      actionText: string,
      actionText2: string,
      buttonAction: () => void,
      buttonAction2: () => void
    }
) => (
  <div className='fadedshort' style={{ padding: 40, color: '#505050' }}>
    <div
      style={{
        padding: 0,
        marginTop: 20,
        borderRadius: 16,
        height: 270,
      }}
    >
      <div
        style={{
          color: '#7e7e7e',
          fontSize: 18,
          fontWeight: 500,
          lineHeight: '32px',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 30,
            }}
          >
            <img height={90} width={90} src={img} alt='' />
          </div>
          <span
            style={{
              fontSize: 20,
              color: '#0D206C',
              justifyContent: 'center',
              display: 'flex',
              fontWeight: 700,
            }}
          >
            {heading}
          </span>
          <div
            style={{
              whiteSpace: 'pre-line',
              textAlign: 'center',
              padding: 10,
              paddingLeft: 30,
              paddingRight: 30,
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <span>{body}</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                maxWidth: 600,
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              {actionText && (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  id='previewAC'
                  className='actionButtonNew transparent-link'
                  style={{ fontSize: 18 }}
                  onClick={() => {
                    buttonAction();
                  }}
                >
                  <div>{actionText}</div>
                  <div className='buttonArrowHolder-link'>
                    <RightOutlined />
                  </div>
                </div>
              )}
              {actionText2 && (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  id='previewAC'
                  className='actionButtonNew transparent-link'
                  style={{ fontSize: 18 }}
                  onClick={() => {
                    buttonAction2();
                  }}
                >
                  <div>{actionText2}</div>
                  <div className='buttonArrowHolder-link'>
                    <RightOutlined />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const loadingComponent2 = (
  <div>
    <div className='loading-container '>
      <div className='preloader-wrapper massive active'>
        <div className='spinner-layer spinner-blue-only'>
          <div className='circle-clipper left'>
            <div className='circle' />
          </div>
          <div className='gap-patch'>
            <div className='circle' />
          </div>
          <div className='circle-clipper right'>
            <div className='circle' />
          </div>
        </div>
      </div>
    </div>
  </div>
);

// TODO: Deprecated
export const loadingComponent = (
  <div>
    <div className='loading-container '>
      <div className='preloader-wrapper big active'>
        <div className='spinner-layer spinner-blue-only'>
          <div className='circle-clipper left'>
            <div className='circle' />
          </div>
          <div className='gap-patch'>
            <div className='circle' />
          </div>
          <div className='circle-clipper right'>
            <div className='circle' />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const smallLoadingComponent = (
  <div>
    <div className='preloader-wrapper small active'>
      <div className='spinner-layer spinner-blue-only'>
        <div className='circle-clipper left'>
          <div className='circle' />
        </div>
        <div className='gap-patch'>
          <div className='circle' />
        </div>
        <div className='circle-clipper right'>
          <div className='circle' />
        </div>
      </div>
    </div>
  </div>
);

export function displaySignInError(error) {
  if (!error.code) return 'Login Unsuccessful.';
  switch (error.code) {
    case 'auth/user-disabled':
      return 'Your account has been disabled. Please contact us.';
    case 'auth/invalid-email':
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'Invalid email or password';
    case 'auth/email-already-in-use':
      return 'A user with that email already exists';
    case 'INVALID_DATA':
    case 'other':
    default:
      return `Something went wrong with the information provided: ${error.message}`;
  }
}

function MoneyFormat(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? `${Math.abs(Number(labelValue)) / 1.0e9}B`
    // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e6
      ? `${Math.abs(Number(labelValue)) / 1.0e6}M`
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e3
        ? `${Math.abs(Number(labelValue)) / 1.0e3}K`
        : Math.abs(Number(labelValue));
}

export function renderCurrency(unrounded) {
  let number = unrounded;
  if (number > 1000) {
    number = Math.ceil(unrounded / 10) * 10;
  } else {
    number = Math.ceil(unrounded);
  }
  const op = number.toString()[0];
  if (op === '-') {
    return `-$${MoneyFormat(number)}`;
  }

  return `$${MoneyFormat(number)}`;
}
