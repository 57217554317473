import { Tooltip } from 'antd';
import React from 'react';
import PeriodCloseDashboardTooltipTitle from './PeriodCloseDashboardTooltipTitle';
import { PeriodCloseDashboardData } from '../../models/interfaces/stores';

const PeriodCloseDataCellRender = (value: PeriodCloseDashboardData, record: any, index: number) => {
  if (!value?.reviewed && !value?.active) {
    return (
      <div className='period-close__data-cell cell-success' key={`${record.businessName}-${index}`}>
        {typeof value?.categoryItemCount === 'number' && value?.categoryItemCount === 0 ? 'N/A' : 0}
      </div>
    );
  }

  return (
    <div className={`period-close__data-cell ${value.active === 0 ? 'cell-success' : ''}`} key={`${record.businessName}-${index}`}>
      {(value.reviewed && value.reviewedDetail && (
        <Tooltip
          placement='bottomRight'
          title={(
            <PeriodCloseDashboardTooltipTitle
              detail={value.reviewedDetail}
              period={record.period}
              checklistType={record.checklistType}
              businessId={record.businessId}
              businessName={record.businessName}
              periodKey={record.period_key}
            />
          )}
        >
          <span className='dashboard-pill dashboard-pill--muted'>{value.reviewed}</span>
        </Tooltip>
      )) || ''}
      {(value.active && value.activeDetail && (
        <Tooltip
          placement='bottomRight'
          title={(
            <PeriodCloseDashboardTooltipTitle
              active
              detail={value.activeDetail}
              period={record.period}
              checklistType={record.checklistType}
              businessId={record.businessId}
              businessName={record.businessName}
              periodKey={record.period_key}
            />
          )}
        >
          <span className='dashboard-pill dashboard-pill--danger'>{value.active}</span>
        </Tooltip>
      )) || ''}
    </div>
  );
};

export default PeriodCloseDataCellRender;
