import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';
import { Brand } from '../../../ts/enums/Colors';
/**
 * The Aider Early Warning Icon, this icon contains the aider logo
 * surrounded by broadcast waves.
 *
 * The color of the waves is controlled by the `fill` prop
 * The color of the logo is controlled by the `color` prop
 *
 * @param props
 * @returns
 */
export const AiderEarlyWarning = ({
  width = 41,
  height = 29,
  color = Brand.blue,
  strokeWidth = 0.5,
  fill = Brand.teal,
  lineCap = 'round',
  lineJoin = 'round',
  padding = 0.25,
  scale = 1,
  ...rest
}: IconInterface) => {
  const scaleWidth = width * scale;
  const scaleHeight = height * scale;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { min, max } = getPlotExtremes({ width: scaleWidth, height: scaleHeight, padding });
  return (
    <svg
      width={scaleWidth}
      height={scaleHeight}
      viewBox='0 0 41 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      strokeLinecap={lineCap}
      strokeLinejoin={lineJoin}
      {...rest}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.787 18.3735C24.782 18.2295 24.7642 18.0863 24.734 17.9456C24.5356 15.8076 22.788 14.1422 20.4504 14.1422C17.9523 14.1422 16.0973 16.0343 16.0973 18.3697V18.4004C16.0973 20.7358 17.5782 22.601 20.0612 22.601C21.115 22.601 22.1765 21.872 22.5138 21.2482C22.5138 21.767 22.6732 22.5087 23.6511 22.5087C24.2791 22.5087 24.7883 21.9925 24.7883 21.3558L24.787 18.3735ZM19.3142 11.7313C22.9741 11.1574 26.4472 13.6629 27.0717 17.3274C27.6962 20.992 25.2356 24.4279 21.5758 25.0017L20.6272 26.8003C20.5976 26.8561 20.5538 26.9031 20.5003 26.9364C20.3307 27.0419 20.1057 26.989 19.9977 26.818L18.771 24.8707C18.7671 24.8635 18.7667 24.8528 18.761 24.8475C17.6916 24.5565 16.7068 24.0078 15.8937 23.2496C13.1606 20.7011 12.9826 16.4497 15.4964 13.7536C16.5038 12.6726 17.8468 11.9614 19.3142 11.7313ZM22.5138 18.1852V21.0816L21.6735 20.2221L21.5611 20.094C21.2658 20.3978 20.8714 20.5823 20.4516 20.6128C19.1956 20.6128 18.3427 19.5662 18.3427 18.3684V18.3376C18.3427 17.1399 19.1198 16.124 20.4213 16.124C21.6129 16.124 22.4317 17.0668 22.5138 18.1852Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.445 28.0461C21.4116 28.0461 22.1951 28.8297 22.1951 29.7961C22.1951 30.7627 21.4116 31.5461 20.445 31.5461C19.4785 31.5461 18.6951 30.7627 18.6951 29.7961C18.6951 28.8297 19.4785 28.0461 20.445 28.0461'
        fill={fill}
      />
      <path
        d='M4.72458 11.8559C5.57971 9.79159 6.8331 7.91591 8.41317 6.33601C8.63454 6.1068 8.75704 5.79982 8.75427 5.48118C8.7515 5.16254 8.62369 4.85773 8.39837 4.63241C8.17304 4.40709 7.86824 4.27928 7.54959 4.27651C7.23095 4.27374 6.92397 4.39623 6.69477 4.6176C4.88908 6.4232 3.45672 8.56678 2.47948 10.926C1.50225 13.2851 0.999268 15.8137 0.999268 18.3673C0.999268 20.9208 1.50225 23.4494 2.47948 25.8086C3.45672 28.1677 4.88908 30.3113 6.69477 32.1169C6.92397 32.3383 7.23095 32.4608 7.54959 32.458C7.86824 32.4552 8.17304 32.3274 8.39837 32.1021C8.62369 31.8768 8.7515 31.572 8.75427 31.2533C8.75704 30.9347 8.63454 30.6277 8.41317 30.3985C6.8331 28.8186 5.57971 26.9429 4.72458 24.8786C3.86944 22.8143 3.4293 20.6017 3.4293 18.3673C3.4293 16.1328 3.86944 13.9203 4.72458 11.8559Z'
        fill={fill}
      />
      <path
        d='M10.7243 18.3673C10.7243 15.7893 11.7482 13.3168 13.5708 11.4936C13.799 11.2654 13.9272 10.9559 13.9272 10.6332C13.9272 10.3105 13.799 10.001 13.5708 9.77281C13.3426 9.54462 13.0331 9.41642 12.7104 9.41642C12.3877 9.41642 12.0782 9.54462 11.85 9.77281C10.7211 10.9013 9.82569 12.2412 9.21476 13.7158C8.60382 15.1905 8.28938 16.7711 8.28938 18.3673C8.28938 19.9635 8.60382 21.544 9.21476 23.0187C9.82569 24.4933 10.7211 25.8332 11.85 26.9617C11.963 27.0747 12.0971 27.1643 12.2447 27.2255C12.3924 27.2866 12.5506 27.3181 12.7104 27.3181C12.8702 27.3181 13.0284 27.2866 13.176 27.2255C13.3237 27.1643 13.4578 27.0747 13.5708 26.9617C13.6838 26.8487 13.7734 26.7146 13.8346 26.5669C13.8957 26.4193 13.9272 26.2611 13.9272 26.1013C13.9272 25.9415 13.8957 25.7833 13.8346 25.6356C13.7734 25.488 13.6838 25.3539 13.5708 25.2409C11.7482 23.4177 10.7243 20.9452 10.7243 18.3673Z'
        fill={fill}
      />
      <path
        d='M27.7127 9.50858C27.565 9.56985 27.4309 9.65964 27.318 9.77281C27.2049 9.8857 27.1151 10.0198 27.0538 10.1675C26.9925 10.3151 26.961 10.4734 26.961 10.6332C26.961 10.7931 26.9925 10.9514 27.0538 11.099C27.1151 11.2466 27.2049 11.3808 27.318 11.4936C29.1407 13.3168 30.1646 15.7893 30.1646 18.3673C30.1646 20.9452 29.1407 23.4177 27.318 25.2409C27.205 25.3539 27.1154 25.488 27.0543 25.6356C26.9931 25.7833 26.9616 25.9415 26.9616 26.1013C26.9616 26.2611 26.9931 26.4193 27.0543 26.5669C27.1154 26.7146 27.205 26.8487 27.318 26.9617C27.431 27.0747 27.5652 27.1643 27.7128 27.2255C27.8604 27.2866 28.0187 27.3181 28.1784 27.3181C28.3382 27.3181 28.4965 27.2866 28.6441 27.2255C28.7917 27.1643 28.9259 27.0747 29.0389 26.9617C30.1677 25.8332 31.0631 24.4933 31.6741 23.0187C32.285 21.544 32.5995 19.9635 32.5995 18.3673C32.5995 16.7711 32.285 15.1905 31.6741 13.7158C31.0631 12.2412 30.1677 10.9013 29.0389 9.77281C28.926 9.65964 28.7919 9.56985 28.6442 9.50858C28.4966 9.44731 28.3383 9.41578 28.1784 9.41578C28.0186 9.41578 27.8603 9.44731 27.7127 9.50858Z'
        fill={fill}
      />
      <path
        d='M33.3349 4.26422C33.0126 4.26422 32.7036 4.39221 32.4757 4.62003V4.6176C32.2478 4.8455 32.1199 5.15456 32.1199 5.47681C32.1199 5.79905 32.2478 6.10811 32.4757 6.33601C34.0557 7.91591 35.3091 9.79159 36.1643 11.8559C37.0194 13.9203 37.4595 16.1328 37.4595 18.3673C37.4595 20.6017 37.0194 22.8143 36.1643 24.8786C35.3091 26.9429 34.0557 28.8186 32.4757 30.3985C32.2543 30.6277 32.1318 30.9347 32.1346 31.2533C32.1373 31.572 32.2652 31.8768 32.4905 32.1021C32.7158 32.3274 33.0206 32.4552 33.3392 32.458C33.6579 32.4608 33.9649 32.3383 34.1941 32.1169C37.8401 28.4704 39.8884 23.525 39.8884 18.3685C39.8884 13.2119 37.8401 8.26651 34.1941 4.62003C33.9662 4.39221 33.6571 4.26422 33.3349 4.26422Z'
        fill={fill}
      />
      <path
        d='M4.72458 11.8559C5.57971 9.79159 6.8331 7.91591 8.41317 6.33601C8.63454 6.1068 8.75704 5.79982 8.75427 5.48118C8.7515 5.16254 8.62369 4.85773 8.39837 4.63241C8.17304 4.40709 7.86824 4.27928 7.54959 4.27651C7.23095 4.27374 6.92397 4.39623 6.69477 4.6176C4.88908 6.4232 3.45672 8.56678 2.47948 10.926C1.50225 13.2851 0.999268 15.8137 0.999268 18.3673C0.999268 20.9208 1.50225 23.4494 2.47948 25.8086C3.45672 28.1677 4.88908 30.3113 6.69477 32.1169C6.92397 32.3383 7.23095 32.4608 7.54959 32.458C7.86824 32.4552 8.17304 32.3274 8.39837 32.1021C8.62369 31.8768 8.7515 31.572 8.75427 31.2533C8.75704 30.9347 8.63454 30.6277 8.41317 30.3985C6.8331 28.8186 5.57971 26.9429 4.72458 24.8786C3.86944 22.8143 3.4293 20.6017 3.4293 18.3673C3.4293 16.1328 3.86944 13.9203 4.72458 11.8559Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
      <path
        d='M10.7243 18.3673C10.7243 15.7893 11.7482 13.3168 13.5708 11.4936C13.799 11.2654 13.9272 10.9559 13.9272 10.6332C13.9272 10.3105 13.799 10.001 13.5708 9.77281C13.3426 9.54462 13.0331 9.41642 12.7104 9.41642C12.3877 9.41642 12.0782 9.54462 11.85 9.77281C10.7211 10.9013 9.82569 12.2412 9.21476 13.7158C8.60382 15.1905 8.28938 16.7711 8.28938 18.3673C8.28938 19.9635 8.60382 21.544 9.21476 23.0187C9.82569 24.4933 10.7211 25.8332 11.85 26.9617C11.963 27.0747 12.0971 27.1643 12.2447 27.2255C12.3924 27.2866 12.5506 27.3181 12.7104 27.3181C12.8702 27.3181 13.0284 27.2866 13.176 27.2255C13.3237 27.1643 13.4578 27.0747 13.5708 26.9617C13.6838 26.8487 13.7734 26.7146 13.8346 26.5669C13.8957 26.4193 13.9272 26.2611 13.9272 26.1013C13.9272 25.9415 13.8957 25.7833 13.8346 25.6356C13.7734 25.488 13.6838 25.3539 13.5708 25.2409C11.7482 23.4177 10.7243 20.9452 10.7243 18.3673Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
      <path
        d='M27.7127 9.50858C27.565 9.56985 27.4309 9.65964 27.318 9.77281C27.2049 9.8857 27.1151 10.0198 27.0538 10.1675C26.9925 10.3151 26.961 10.4734 26.961 10.6332C26.961 10.7931 26.9925 10.9514 27.0538 11.099C27.1151 11.2466 27.2049 11.3808 27.318 11.4936C29.1407 13.3168 30.1646 15.7893 30.1646 18.3673C30.1646 20.9452 29.1407 23.4177 27.318 25.2409C27.205 25.3539 27.1154 25.488 27.0543 25.6356C26.9931 25.7833 26.9616 25.9415 26.9616 26.1013C26.9616 26.2611 26.9931 26.4193 27.0543 26.5669C27.1154 26.7146 27.205 26.8487 27.318 26.9617C27.431 27.0747 27.5652 27.1643 27.7128 27.2255C27.8604 27.2866 28.0187 27.3181 28.1784 27.3181C28.3382 27.3181 28.4965 27.2866 28.6441 27.2255C28.7917 27.1643 28.9259 27.0747 29.0389 26.9617C30.1677 25.8332 31.0631 24.4933 31.6741 23.0187C32.285 21.544 32.5995 19.9635 32.5995 18.3673C32.5995 16.7711 32.285 15.1905 31.6741 13.7158C31.0631 12.2412 30.1677 10.9013 29.0389 9.77281C28.926 9.65964 28.7919 9.56985 28.6442 9.50858C28.4966 9.44731 28.3383 9.41578 28.1784 9.41578C28.0186 9.41578 27.8603 9.44731 27.7127 9.50858Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
      <path
        d='M33.3349 4.26422C33.0126 4.26422 32.7036 4.39221 32.4757 4.62003V4.6176C32.2478 4.8455 32.1199 5.15456 32.1199 5.47681C32.1199 5.79905 32.2478 6.10811 32.4757 6.33601C34.0557 7.91591 35.3091 9.79159 36.1643 11.8559C37.0194 13.9203 37.4595 16.1328 37.4595 18.3673C37.4595 20.6017 37.0194 22.8143 36.1643 24.8786C35.3091 26.9429 34.0557 28.8186 32.4757 30.3985C32.2543 30.6277 32.1318 30.9347 32.1346 31.2533C32.1373 31.572 32.2652 31.8768 32.4905 32.1021C32.7158 32.3274 33.0206 32.4552 33.3392 32.458C33.6579 32.4608 33.9649 32.3383 34.1941 32.1169C37.8401 28.4704 39.8884 23.525 39.8884 18.3685C39.8884 13.2119 37.8401 8.26651 34.1941 4.62003C33.9662 4.39221 33.6571 4.26422 33.3349 4.26422Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    </svg>

  );
};
