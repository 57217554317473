import React from 'react';
import SubLayoutSettings from '../../layouts/SubLayoutSettings';
import AccountNavbar from '../../components/accountSettings/AccountNavbar';
import AccountHeader from '../../components/accountSettings/Header';
import ManagePermissions from '../../components/accountSettings/managePermissions/ManagePermissions';

const Permissions = () => (
  <SubLayoutSettings
    SubNav={(<AccountNavbar />)}
    HeaderContent={(<AccountHeader />)}
    SubMain={(<ManagePermissions />)}
  />
);

export default Permissions;
