import React from 'react';
import AccountNavbar from '../../components/accountSettings/AccountNavbar';
import AccountHeader from '../../components/accountSettings/Header';
import MyProfile from '../../components/accountSettings/ManageProfile';
import SubLayoutSettings from '../../layouts/SubLayoutSettings';

const Profile = () => (
  <SubLayoutSettings
    SubNav={(<AccountNavbar />)}
    HeaderContent={(<AccountHeader />)}
    SubMain={(<MyProfile />)}
  />
);

export default Profile;
