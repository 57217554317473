import React from 'react';
import { notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { INotificationProps } from '../models/interfaces/notifications';

const Notification = ({
  type = 'info',
  title,
  description = undefined,
  className = '',
  duration = 5
}:INotificationProps) => {
  const openNotification = ({ ...rest }) => {
    notification.open({
      type,
      message: title,
      description,
      placement: 'bottomRight',
      className: `notification notification__${type}${!description ? ' notification__title-only' : ''}${className ? ` ${className}` : ''}`,
      ...rest
    });
  };

  if (type === 'error') {
    return openNotification({ duration, icon: <ExclamationCircleFilled /> });
  }

  return openNotification({ duration });
};

export default Notification;
