import { ranges } from './GraphSettings';

function formatNumber(input, unit) {
  if (unit === '%') {
    return input;
  }
  if (input.toString()[0] === '-') {
    const n = Math.abs(input);
    for (let i = 0; i < ranges.length; i++) {
      if (n >= ranges[i].divider) {
        return `-${(n / ranges[i].divider).toString() + ranges[i].suffix}`;
      }
    }
    return `-${n.toString()}`;
  }
  for (let i = 0; i < ranges.length; i++) {
    if (input >= ranges[i].divider) {
      return (input / ranges[i].divider).toString() + ranges[i].suffix;
    }
  }
  return input.toString();
}

export default formatNumber;
