import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { PeriodCloseTypes } from '@aider/constants-library';
import { DateFormats } from '@aider/aider-formatting-library';
import ChecklistTableTooltip from '../checklist/ChecklistTableTooltip';
import CellSpacer from './CellSpacer';
import CellPrefix from './CellPrefix';
import ImageCell from './ImageCell';
import MathCell from './MathCell';
import CustomCell from './CustomCell';
import InfoCell from './InfoCell';
import { useStore } from '../../stores/Store';

const CellContent = ({
  cell,
  checklistIdentifier,
  row,
  table
}: {
  cell: PeriodCloseTypes.ChecklistCell,
  checklistIdentifier: PeriodCloseTypes.ChecklistIdentifier
  row: PeriodCloseTypes.ChecklistRow,
  table: PeriodCloseTypes.ChecklistTable
}) => {
  const { localeStore } = useStore();
  return (
    <>
      <CellPrefix cell={cell} />

      {cell?.cellType === 'link' && (
        <Link to={cell.link} target='_blank' rel='noopener noreferrer'>
          {cell.data}
        </Link>
      )}

      {cell?.cellType === 'image' && (
        <ImageCell cell={cell} />
      )}

      {cell?.cellType === 'custom' && (
        <CustomCell checklistIdentifier={checklistIdentifier} cell={cell} row={row} />
      )}

      {cell?.cellType === 'math' && (
        <MathCell cell={cell} table={table} />
      )}

      {cell?.cellType === 'info' && (
        <InfoCell cell={cell} />
      )}

      {!['link', 'image', 'custom', 'math', 'info'].includes(cell?.cellType) && (
        <>{localeStore.formatDate(cell.data, DateFormats.numeric)}</>
      )}

      {cell?.tooltip && <ChecklistTableTooltip tooltipData={cell.tooltip} />}

      {cell?.postfix && (
        <>
          <CellSpacer newline={cell?.postfix.newline} />
          <span className={`checklist-postfix checklist-postfix__${cell?.postfix?.type ? cell.postfix.type : 'standard'}`}>
            {cell.postfix.value}
          </span>
        </>
      )}
    </>
  );
};

export default observer(CellContent);
