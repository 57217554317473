import { PracticeTypes, ReportEnums, ApiConstants, ReportConstants } from '@aider/constants-library';
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { capitalizeString } from '@aider/aider-formatting-library/dist/lib/formatUtils';
import { Document as DocXDoc, SectionType, Paragraph, HeadingLevel, Packer, ImageRun } from 'docx';
import fileDownload from 'js-file-download';
import { convertFromRaw, convertToRaw, Modifier, EditorState, ContentState, Entity } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import { getFinancialYear, PeriodTypes } from '@aider/aider-period-library';
import { DateTime } from 'luxon';
import { DELETE, GET, POST, PUT } from '../lib/requests';
import type { RootStore } from './Store';
import handleError from '../lib/errorHandler';
import Notification from '../components/Notification';
import { calculateTrendData, determineValueValidity, generateDocXTable, generateStyledChildren } from '../lib/storeUtils';
import { DOCX_CONFIG } from '../models/constants/stores';
import { trackMixpanelEvent } from '../lib/mixpanel';
import { AntDesignTreeData } from '../models/interfaces/antDesignElements';
import { EditorSuggestionItem, RawReportEditorVariable } from '../models/interfaces/components';
import { InsightsOrder } from '../entities/types';
import { InsightTab } from './v1/pageStore';
import { sortReportInsightTree } from '../lib/componentHelpers/reportHelpers';

const variablePattern = /#{[^}]+}/g;

export default class ReportTemplateStore {
  rootStore: RootStore;

  performanceReportTemplates: Map<string, PracticeTypes.ReportPage> = new Map();

  selectedPerformanceReportTemplate: string = ReportEnums.ReportPredefinedTemplates.default;

  editedPerformanceReportTemplate: PracticeTypes.ReportPage;

  selectedPeriodData: {
    start: string;
    end: string;
    name: string;
    granularity?: string;
  };

  blockInsights = {};

  editBlock: string;

  fetchingVariables: boolean = false;

  variableFetchTimeout: any;

  templateData: RawReportEditorVariable = new Map();

  formatTemplateVariable(key: string, valueParam: any): string {
    // Added check for nil value here as some values were undefined and causing the every check in text component to fail
    const value = determineValueValidity(valueParam);

    if (value === 'N/A') {
      return value;
    }

    const format = new Format(this.rootStore.businessesStore.selectedBusiness.currencyCode, this.rootStore.businessesStore.selectedBusiness.countryCode);
    let formatType = ReportEnums.ReportVariables?.[key];

    if (key.indexOf('_') > -1) {
      const splitKey = key.split('_');
      const keyType = splitKey[splitKey.length - 1];

      if (keyType === 'percentage') {
        formatType = ReportEnums.VariableFormattingTypes.PERCENTAGE;
      } else if (keyType === 'trend') {
        formatType = ReportEnums.VariableFormattingTypes.TEXT;
      } else {
        formatType = ReportEnums.VariableFormattingTypes.CURRENCY;
      }
    }

    switch (formatType) {
      case (ReportEnums.VariableFormattingTypes.CURRENCY):
        return format.formatValue({ value, format: ValueTypes.currency });
      case (ReportEnums.VariableFormattingTypes.PERCENTAGE):
        return format.formatValue({ value, format: ValueTypes.percentage });
      case (ReportEnums.VariableFormattingTypes.DATE):
        return format.formatValue({ value, format: ValueTypes.allShortDate });
      case (ReportEnums.VariableFormattingTypes.IMAGE):
      case (ReportEnums.VariableFormattingTypes.TEXT):
      default:
        return value;
    }
  }

  get createTemplateVariableOptions() {
    const setVariables = {};
    const templateVariableCategories = ReportConstants.ReportVariableStructure;
    const availableVariables = this.templateData?.get(
      this.rootStore.businessesStore.selectedBusinessId
    )?.get(
      this.selectedPeriodData?.name
    ) || {};

    // add the practice variables to the available variables
    ReportConstants.ReportVariableStructure.practice.forEach((variable) => {
      // Only need the keys to be defined, the values are not used
      availableVariables[variable] = '';
    });

    Object.keys(availableVariables).forEach((variable) => {
      let variableCategory;
      const variableSplit = variable.split('_');
      const insightKey = variableSplit[0];

      Object.keys(templateVariableCategories).forEach((category) => {
        if (templateVariableCategories[category].includes(insightKey)) {
          variableCategory = category;
        }
      });

      if (!setVariables[variableCategory]) {
        setVariables[variableCategory] = [];
      }

      setVariables[variableCategory].push(variable);
    });

    return setVariables;
  }

  get filteredInsightGraphData() {
    const usPractice = this.rootStore.practiceStore.isUSPractice;
    const filteredInsightsOrder = InsightsOrder?.filter((insight) => !usPractice || !['reconciliation', 'gst'].includes(insight)) || [];
    const graphInsightData = this.rootStore.insightStore?.insightData?.filter((insight) => filteredInsightsOrder.includes(insight.insightKey));
    // eslint-disable-next-line array-callback-return, consistent-return
    const filteredInsightData = graphInsightData.reduce((acc, insight) => {
      if (insight.categoryData?.categoryId === InsightTab.profitability) {
        if (insight.periods.length
          && this.selectedPeriodData.granularity === PeriodTypes.MONTHLY
        ) {
          const insightData = insight.periods.find((p) => p?.periodData?.name === this.selectedPeriodData.name);
          if (insightData) acc.push(insightData);
        }
        if (insight.quarters.length && this.selectedPeriodData.granularity === PeriodTypes.QUARTERLY) {
          const insightData = insight.quarters?.find(
            (q) => q?.periodData?.name === this.selectedPeriodData.name
          );
          if (insightData) acc.push(insightData);
        }
      } else {
        const nonProfitabilityArray = Object.values(ReportEnums.VariableInsightMapping).reduce((npa, key) => {
          if (key && npa.indexOf(key) === -1) npa.push(key);
          return npa;
        }, []);

        if (nonProfitabilityArray.includes(insight.insightKey) && !insight.missing) {
          const selectedGraph = insight;
          if (selectedGraph) acc.push(selectedGraph);
        }
      }
      return acc;
    }, []);
    return filteredInsightData;
  }

  get templateVariables(): AntDesignTreeData[] {
    const templateVariableCategories = ReportConstants.ReportVariableStructure;
    const templateVariableOptions = this.createTemplateVariableOptions;

    return Object.keys(templateVariableCategories).sort().map((key) => ({
      title: this.rootStore.localeStore.translation(`report-template-editor.variables.${key}`),
      value: key,
      key,
      selectable: false,
      children: templateVariableOptions?.[key]?.sort().map((child: string) => {
        const childSplit = child.split('-');
        let title = this.rootStore.localeStore.translation(`report-template-editor.variables.${child}`);

        if (childSplit.length > 1) {
          const insight = childSplit[0];
          const period = childSplit[1];
          const variable = childSplit[2];

          title = `${this.rootStore.localeStore.translation(`report-template-editor.periods.${period}`)} \
          ${this.rootStore.localeStore.translation(`report-template-editor.variables.${insight}`)} \
          ${_.capitalize(variable)}`;
        }

        return {
          title,
          value: `{${child}}`,
          key: `{${child}}`,
        };
      }),
    }));
  }

  updatedReportContent: string;

  conversationHistory: any[] = [];

  /**
    * If this property is set, the text will be injected into the currently active
    * WYSIWYG editor at the current cursor position
    */
  inject: string = null;

  injectEntity: { type: string, entityKey: string } = null;

  selectedInsightDataPoints: string[] = null;

  setEditBlock(block: PracticeTypes.ReportBlock) {
    this.editBlock = block.id;
  }

  setEditBlockId(blockId: string) {
    this.editBlock = blockId;
  }

  clearEditBlock() {
    this.editBlock = null;
  }

  get reportTemplateSelectionList() {
    const reportTemplateSelectionList = _.sortBy(Array.from(this.performanceReportTemplates.values()), ['templateName'])
      .map((reportTemplate) => ({
        value: reportTemplate.templateId,
        label: reportTemplate.templateName,
      }));
    return reportTemplateSelectionList;
  }

  get sortedPerformanceReportTemplates() {
    return Array.from(this.performanceReportTemplates.values()).sort((a, b) => {
      if (a.templateName < b.templateName) {
        return -1;
      }
      if (a.templateName > b.templateName) {
        return 1;
      }
      return 0;
    });
  }

  get formattedTemplateVariables() {
    const mutatedData = { ...this.templateData.get(this.rootStore.businessesStore.selectedBusinessId)?.get(this.selectedPeriodData?.name) };
    const { practice } = this.rootStore.practiceStore;

    ReportConstants.ReportVariableStructure.practice.forEach((trend) => {
      const storeKey = ReportEnums.VariableKeyMapping?.[trend] || trend;
      mutatedData[trend] = practice?.[storeKey] || 'Firm data not available';
    });

    Object.keys(mutatedData).forEach((key) => {
      mutatedData[key] = this.formatTemplateVariable(key, mutatedData[key]);
    });

    return mutatedData;
  }

  get fallbackFormattedVariables() {
    return { ...this.fallbackVariableState, ...this.formattedTemplateVariables };
  }

  get selectedPerformanceReport() {
    const selectedPerformanceReport = this.editedPerformanceReportTemplate || this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate);
    const sortedBlocks = _.sortBy(selectedPerformanceReport?.blocks, ['position']);
    return { ...selectedPerformanceReport, blocks: sortedBlocks };
  }

  get selectedPerformanceReportPageCount() {
    return this.selectedPerformanceReport?.blocks?.filter((block) => block.type === 'page').length || 0;
  }

  get selectedPerformanceReportBlocks() {
    return _.sortBy(this.selectedPerformanceReport.blocks, ['position']) || [];
  }

  get isDefaultTemplateSelected() {
    return this.selectedPerformanceReportTemplate === ReportEnums.ReportPredefinedTemplates.default;
  }

  get isTemplateEdited() {
    return !!this.editedPerformanceReportTemplate
      && !_.isEqual(this.editedPerformanceReportTemplate, this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate));
  }

  /**
   * Returns the template variables in a sorted array
   * for use in the WYSIWYG editor variable header dropown
   * @returns The sorted array of template variables
   */
  get sortedTemplateVariables(): AntDesignTreeData[] {
    if (!this.templateVariables) {
      return [];
    }
    return sortReportInsightTree(this.templateVariables);
  }

  /**
   * Returns a flat array of all template variables and their children
   * For use in the WYSIWYG editor variable # suggest dropown
   * @returns The flat array of template variables
   */
  get flatSortedTemplateVariables(): EditorSuggestionItem[] {
    if (!this.templateVariables) {
      return [];
    }

    return this.templateVariables
      ?.flatMap((variable) => {
        if (!variable.children) {
          return { text: variable.title, value: variable.value };
        }
        return variable.children.flatMap((child) => ({ text: child.title, value: child.value }));
      })
      ?.sort((a, b) => a.text?.localeCompare(b?.text));
  }

  get fallbackVariableState() {
    const fallbackVariableState = {};
    // Creates fallback state for all variables from ENUM definition
    Object.keys(ReportEnums.ReportVariables).forEach((variable) => {
      const variableName = this.rootStore.localeStore.translation(`report-template-editor.variables.${variable}`) || variable;
      fallbackVariableState[variable] = `${variableName} data not available`;
      fallbackVariableState[`${variable}_last_trend`] = `${variable} last trend not available`;
      fallbackVariableState[`${variable}_last_percentage`] = `${variable} last percentage not available`;
      fallbackVariableState[`${variable}_previous_trend`] = `${variable} previous trend not available`;
      fallbackVariableState[`${variable}_previous_percentage`] = `${variable} previous percentage not available`;
      fallbackVariableState[`${variable}_financialYear`] = `${variable} financial year amount not available`;
      fallbackVariableState[`${variable}_financialYear_trend`] = `${variable} financial year trend not available`;
      fallbackVariableState[`${variable}_financialYear_percentage`] = `${variable} financial year percentage not available`;
      fallbackVariableState[`${variable}_lastYear_trend`] = `${variable} last year trend not available`;
      fallbackVariableState[`${variable}_lastYear_percentage`] = `${variable} last year percentage not available`;
      fallbackVariableState[`${variable}_lastFinancialYear_trend`] = `${variable} last financial year trend not available`;
      fallbackVariableState[`${variable}_lastFinancialYear_percentage`] = `${variable} last financial year percentage not available`;
      fallbackVariableState[`${variable}_previousFinancialYear_trend`] = `${variable} previous financial year trend not available`;
      fallbackVariableState[`${variable}_previousFinancialYear_percentage`] = `${variable} previous financial year percentage not available`;
    });

    // Adds fallback state for all insights while we are using the templates from
    // the insight store, this will be removed once we define the templates manually with
    // specific variables for the quick content.
    InsightsOrder.forEach((insight) => {
      let variableName = this.rootStore.localeStore.translation(`report-template-editor.variables.${insight}`);
      if (variableName.indexOf('Translation not found') !== -1) {
        variableName = this.rootStore.localeStore.translation(`report-template-editor.variables.${insight}`);
      }
      fallbackVariableState[insight] = `${variableName} Trend data not available for selected period`;
    });
    return fallbackVariableState;
  }

  updateBlockInsight(blockId: string, insight: string) {
    this.blockInsights[blockId] = insight;
  }

  /**
   * Prepares the selected report template for editing
   * by creating a deep clone of the selected template and sorting the blocks by position
   * to ensure they are in the correct order
   */
  prepEditReportTemplate() {
    const templateClone = _.cloneDeep(this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate));
    const sortedBlocks = _.sortBy(templateClone?.blocks, ['position']);

    if (templateClone) {
      templateClone.blocks = sortedBlocks;
    }

    this.editedPerformanceReportTemplate = templateClone;
  }

  /**
   * Sets the selected report template block to the edited content
   * @param editedContent - The edited content to set
   * @param blockId - The block id to set the content for
   */
  editReportTemplate(editedContent: PracticeTypes.ReportBlock['content'], blockId: PracticeTypes.ReportBlock['id']) {
    const inx = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    this.editedPerformanceReportTemplate.blocks[inx].content = editedContent;
  }

  insertChartPointer(pointer: string, selectedDataPoints: string[]) {
    const inx = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === this.editBlock);
    this.editedPerformanceReportTemplate.blocks[inx].content = convertToRaw(stateFromMarkdown(pointer));
    this.selectedInsightDataPoints = selectedDataPoints;
  }

  insertVariable(variable: string) {
    trackMixpanelEvent({ description: 'Report Editor - Insert Variable', properties: { variable }, rootStore: this.rootStore });
    this.injectEntity = { type: 'MENTION', entityKey: `#${variable}` };
  }

  saveReportSettings(blockId: PracticeTypes.ReportBlock['id'], settings: PracticeTypes.ReportSettingsObject) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }
    if (!this.editedPerformanceReportTemplate.settings) {
      this.editedPerformanceReportTemplate.settings = {};
    }

    if (!this.editedPerformanceReportTemplate.settings[blockId]) {
      this.editedPerformanceReportTemplate.settings[blockId] = {};
    }

    Object.keys(settings).forEach((key) => {
      this.editedPerformanceReportTemplate.settings[blockId][key] = settings[key];
    });
  }

  // eslint-disable-next-line class-methods-use-this
  convertTemplateToDraftJS(editorState: EditorState, currentState: ContentState, template: string): ContentState {
    let modifiedEditorState = editorState;
    // Split template into blocks
    const parts = template.split(variablePattern);

    // Extract all variables from the template
    const variables = template.match(variablePattern) || [];
    let variableIndex = 0;
    let modifiedState = currentState;

    // Modify editor state with new content
    parts.forEach((part) => {
      // Add the block content
      modifiedState = Modifier.insertText(modifiedState, modifiedEditorState.getSelection(), part, null, null);
      modifiedEditorState = EditorState.push(modifiedEditorState, modifiedState, 'insert-fragment');

      if (variableIndex < variables.length) {
        // Add the variable entity
        const entityKey = Entity.create('MENTION', 'IMMUTABLE', variables[variableIndex]);
        modifiedState = Modifier.insertText(modifiedState, modifiedEditorState.getSelection(), variables[variableIndex], null, entityKey);
        variableIndex += 1;
      }

      modifiedEditorState = EditorState.push(modifiedEditorState, modifiedState, 'insert-fragment');
    });

    return modifiedEditorState;
  }

  /**
    * Adds a trend text block to the end of the currently edited block
    */
  injectTrendText(insightKey: string) {
    let trendTemplate = '';
    if (!insightKey) {
      Notification({ type: 'error', title: 'Failed to inject trend text' });
      return;
    }

    switch (insightKey) {
      case 'revenue':
      case 'directCosts':
      case 'netProfit':
      case 'grossProfit':
        trendTemplate = this.rootStore.templateTextStore.getProfitabilityInsightText(insightKey);
        break;
      case 'operationalExpenses':
        trendTemplate = this.rootStore.templateTextStore.getProfitabilityInsightText('opex');
        break;
      default:
        break;
    }

    this.inject = trendTemplate;
  }

  /**
    * Adds a trend text block to the end of the currently edited block
    */
  injectImage(insightKey: string) {
    if (!insightKey) {
      Notification({ type: 'error', title: 'Failed to inject image' });
      return;
    }
    this.injectEntity = { type: 'IMAGE', entityKey: `#${insightKey}` };
  }

  /**
   * Gets the block position and index for the block id
   * @param blockId - The block id to find
   * @returns The block index and position
   */
  getBlockPositionAndIndex(blockId: string): { blockIndex: number, blockPosition: PracticeTypes.ReportBlock['position'] } {
    let blockIndex: number = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    let blockPosition: PracticeTypes.ReportBlock['position'];

    if (blockIndex === -1) {
      blockIndex = this.editedPerformanceReportTemplate.blocks.length;
      blockPosition = (this.editedPerformanceReportTemplate.blocks.slice(-1)[0]?.position || -1) + 1;
    } else {
      blockPosition = this.editedPerformanceReportTemplate.blocks[blockIndex].position;
    }

    return { blockIndex, blockPosition };
  }

  /**
   * Gets the index and position for the next page block after the block id
   * @param blockId - The block id to find the next page block after
   * @returns The next page block index and position
   */
  getNextPagePositionAndIndex(blockId: string): { nextPageInx: number, nextPagePosition: PracticeTypes.ReportBlock['position'] } {
    const templateIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    const searchStartIndex = templateIndex === -1 ? 0 : templateIndex + 1;
    const nextPageInx = this.editedPerformanceReportTemplate.blocks.findIndex((block, index) => index >= searchStartIndex && block.type === 'page');
    const nextPagePosition = nextPageInx !== -1 ? this.editedPerformanceReportTemplate.blocks[nextPageInx].position : this.editedPerformanceReportTemplate.blocks.length;
    return { nextPageInx, nextPagePosition };
  }

  /**
    * Adds a page block to the end of the report template
    */
  addPageToEnd() {
    const lastPosition = this.editedPerformanceReportTemplate.blocks.slice(-1)[0]?.position || -1;
    this.addPageToPosition(
      this.editedPerformanceReportTemplate.blocks.length,
      lastPosition + 1
    );
  }

  /**
    * Adds a page block to the target index
    * @param targetInx - The target index to add the page block to
    * @param position - The position to set the page block to
    */
  addPageToPosition(targetInx: number, position: PracticeTypes.ReportBlock['position']) {
    if (targetInx === -1) {
      this.addPageToEnd();
      return;
    }
    this.editedPerformanceReportTemplate.blocks.splice(targetInx, 0, {
      id: uuid(),
      type: 'page',
      position,
      content: null
    });
    this.reevaluateBlockPositions();
  }

  /**
    * Increments the position of all blocks from the start index to the end of
    * the blocks array
    */
  incrementBlockPositions(startIndex: number) {
    if (startIndex === -1) {
      return;
    }

    for (let i = startIndex; i < this.editedPerformanceReportTemplate.blocks.length; i += 1) {
      this.editedPerformanceReportTemplate.blocks[i].position += 1;
    }
  }

  /**
    * Iterates through all blocks and reevaluates the block positions
    * to cleanup any gaps in the block positions
    * This is useful when deleting blocks
    */
  reevaluateBlockPositions() {
    const placedBlocks = [];
    this.editedPerformanceReportTemplate.blocks.forEach((block, index) => {
      const updatedBlock = { ...block };
      updatedBlock.position = index;
      placedBlocks.push(updatedBlock);
    });
    this.editedPerformanceReportTemplate.blocks = placedBlocks;
  }

  /**
    * Adds a page block before the target block id
    * @param blockId - The block id to add the page block before
    */
  addPageBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);
    this.addPageToPosition(blockIndex, blockPosition);
  }

  /**
    * Deletes a page block and all blocks after it
    * up to the next page block
    * @param blockId - The block id to delete
    */
  deletePageBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const blockIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    if (blockIndex === -1) {
      return;
    }

    let { nextPageInx } = this.getNextPagePositionAndIndex(blockId);
    if (nextPageInx === -1) {
      // Is last page so delete all blocks after this one
      nextPageInx = this.editedPerformanceReportTemplate.blocks.length;
    }
    const deleteBlockCount = nextPageInx - blockIndex;

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, deleteBlockCount);
    this.reevaluateBlockPositions();
  }

  /**
    * Adds a header block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addHeaderBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'header',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  /**
    * Adds a text block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addTextBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'text',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  /**
    * Adds a text block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addChartBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'chart',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  addTableBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'table',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  deleteActiveBlock() {
    const activeBlock = this.editBlock;
    if (!activeBlock) {
      return;
    }
    this.editBlock = null;
    this.deleteBlock(activeBlock);
  }

  deleteBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const blockIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    if (blockIndex === -1) {
      return;
    }

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 1);
    this.reevaluateBlockPositions();
  }

  async fetchPerformanceReportTemplates() {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates`;
    GET({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        const reportTemplates = new Map<string, PracticeTypes.ReportPage>();
        response.forEach((reportTemplate: PracticeTypes.ReportPage) => {
          const mutatedTemplate = { ...reportTemplate };
          if (!mutatedTemplate.settings) {
            mutatedTemplate.settings = {};
          }
          reportTemplates.set(reportTemplate.templateId, mutatedTemplate);
        });
        this.performanceReportTemplates = reportTemplates;
      }
    });
  }

  async fetchPerformanceReportTemplate(templateId: string) {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates/${templateId}`;
    GET({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        const mutatedTemplate = { ...response };
        if (!mutatedTemplate.settings) {
          mutatedTemplate.settings = {};
        }

        this.performanceReportTemplates.set(templateId, response);
      }
    });
  }

  async createPerformanceReportTemplate(reportTemplate: PracticeTypes.ReportPage) {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates`;
    return POST({
      url,
      rootStore: this.rootStore,
      data: reportTemplate,
    })
      .then((response) => {
        if (response) {
          this.performanceReportTemplates.set(response.templateId, response);
          this.selectedPerformanceReportTemplate = response.templateId;
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async updatePerformanceReportTemplate(reportTemplate: PracticeTypes.ReportPage) {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates/${reportTemplate.templateId}`;
    return PUT({
      url,
      rootStore: this.rootStore,
      data: reportTemplate,
    });
  }

  async deletePerformanceReportTemplate(templateId: string) {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates/${templateId}`;
    DELETE({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        this.performanceReportTemplates.delete(templateId);
      }
    });
  }

  async updateLLMReportContent(prompt: string, material: any) {
    this.updatedReportContent = await this.generateLLMContentForReport(prompt, material);
    return this.updatedReportContent;
  }

  async generateLLMContentForReport(prompt: string, context: any) {
    let aggregatedContext = '';

    if (context) {
      aggregatedContext = `${context.originalContent}\n`;
    }

    if (this.conversationHistory.length > 0) {
      aggregatedContext += this.conversationHistory.reduce((acc, item) => (
        `${acc.length > 0 ? `${acc}\n` : ''}${item?.prompt ? `${item.prompt}\n` : ''}${item?.context ? `${item.context}\n` : ''}`
      ), '');
    }

    if (aggregatedContext.length > 0) {
      aggregatedContext += 'You should refer to the above context when answering the following prompt.\n';
    }

    const data = {
      model: 'gpt-4o',
      question: {
        role: 'user',
        content: `${aggregatedContext}${prompt}`,
      },
    };

    return POST({
      url: `${process.env.REACT_APP_LLM_ENDPOINT}/api/v1/business/${this.rootStore.businessesStore.selectedBusinessId}/dataChatWithContext`,
      data,
      rootStore: this.rootStore,
    }).then((response) => {
      if (!response?.answer) {
        throw new Error('LLM provided no answer');
      }

      this.conversationHistory.push({ prompt, context: response.answer });

      return response.answer;
    });
  }

  clearConversationHistory() {
    this.conversationHistory = [];
  }

  breakReportByPages() {
    const pages = [];
    let page = [];
    this.selectedPerformanceReportBlocks.forEach((block, index) => {
      if (block.type === 'page' && index === 0) {
        return;
      }
      if (block.type === 'page') {
        pages.push(page);
        page = [];
      } else {
        page.push(block);
      }
    });
    pages.push(page);
    return pages;
  }

  clearReportContent() {
    this.updatedReportContent = null;
  }

  useReportContent() {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }
    if (this.updatedReportContent) {
      const newContent = convertToRaw(stateFromMarkdown(this.updatedReportContent));
      this.editReportTemplate(newContent, this.editBlock);
    }
    this.clearReportContent();
  }

  get graphImages() {
    return this.rootStore.actionStore.chartImages;
  }

  async generateReport() {
    const { graphImages } = this;
    const reportPages = this.breakReportByPages();
    const reportSections = reportPages.map((page) => (
      {
        properties: {
          type: SectionType.NEXT_PAGE,
        },
        children: page.flatMap((block) => {
          let children;
          let attachment;
          switch (block.type) {
            case 'header':
              return block.content.blocks.flatMap((rawBlock) => {
                children = generateStyledChildren(rawBlock, block?.content?.entityMap);
                return new Paragraph({
                  children,
                  heading: HeadingLevel.TITLE,
                  alignment: rawBlock?.data?.['text-align'] || 'left',
                  spacing: { after: 200 },
                });
              });
            case 'table':
              if (!block.content) {
                return new Paragraph({ text: '' });
              }
              children = convertFromRaw(block.content).getPlainText();
              return generateDocXTable(
                this.rootStore.businessesStore.selectedBusiness,
                this.rootStore.businessesStore?.selectedBusinessFinancialYearEnd,
                this.filteredInsightGraphData.find((insight) => (
                  insight.insightKey === children
                )),
                this.selectedPerformanceReport.settings?.[block.id],
                this.selectedPeriodData?.granularity,
              );
            case 'chart':
              if (!block.content) {
                return new Paragraph({ text: '' });
              }
              children = convertFromRaw(block.content).getPlainText();
              if (this.filteredInsightGraphData.findIndex((insight) => insight.insightKey === children) > -1) {
                attachment = graphImages[children];
                return new Paragraph({
                  children: [new ImageRun({
                    data: attachment,
                    transformation: {
                      width: 600,
                      height: 300,
                    },
                  })]
                });
              }
              return new Paragraph({});
            case 'text':
              if (!block.content) {
                return new Paragraph({ text: '' });
              }
              return block.content.blocks.flatMap((rawBlock, index, arr) => {
                children = generateStyledChildren(rawBlock, block?.content?.entityMap, this.fallbackFormattedVariables);
                const blockOptions: any = {
                  children,
                  spacing: { after: 200 },
                  alignment: rawBlock?.data?.['text-align'] || 'left',
                };
                switch (rawBlock.type) {
                  case 'title':
                    blockOptions.heading = HeadingLevel.TITLE;
                    break;
                  case 'header-one':
                    blockOptions.heading = HeadingLevel.HEADING_1;
                    break;
                  case 'header-two':
                    blockOptions.heading = HeadingLevel.HEADING_2;
                    break;
                  case 'header-three':
                    blockOptions.heading = HeadingLevel.HEADING_3;
                    break;
                  case 'header-four':
                    blockOptions.heading = HeadingLevel.HEADING_4;
                    break;
                  case 'header-five':
                    blockOptions.heading = HeadingLevel.HEADING_5;
                    break;
                  case 'header-six':
                    blockOptions.heading = HeadingLevel.HEADING_6;
                    break;
                  case 'ordered-list-item':
                    blockOptions.numbering = { reference: 'block-numbering', level: rawBlock.depth };
                    if (arr?.[index + 1]?.type === 'ordered-list-item') {
                      delete blockOptions.spacing;
                    }
                    break;
                  case 'unordered-list-item':
                    blockOptions.bullet = { level: rawBlock.depth };
                    if (arr?.[index + 1]?.type === 'unordered-list-item') {
                      delete blockOptions.spacing;
                    }
                    break;
                  default:
                    break;
                }

                return new Paragraph(blockOptions);
              });
            default:
              return new Paragraph({ text: block?.content || '' });
          }
        })
      }
    ));

    const doc = new DocXDoc({ sections: reportSections, ...DOCX_CONFIG });

    return Packer.toBlob(doc)
      .then((blob) => {
        const granularity = this.rootStore.timePeriodStore.periodGranularity;
        const period = this.rootStore.timePeriodStore.profitabilityPeriodSelected;
        fileDownload(
          blob,
          `${this.selectedPerformanceReport.templateName} for ${this.rootStore.businessesStore.selectedBusiness.name} - ${capitalizeString(granularity, true)} for ${capitalizeString(period, true)}.docx`
        );
      })
      .then(() => {
        Notification({ type: 'success', title: 'Report generated successfully' });
      })
      .catch((error) => {
        handleError(error);
        Notification({ type: 'error', title: 'Failed to generate report' });
      });
  }

  dataFetchPeriods() {
    let months = 1;
    const { businessesStore } = this.rootStore;
    const periods = [];
    const currentPeriod = { ...this.selectedPeriodData };
    const period = { ...this.selectedPeriodData };
    const financialYear = getFinancialYear(DateTime.fromISO(currentPeriod.start), businessesStore.selectedBusinessFinancialYearEndObject, businessesStore.selectedBusiness.timeZoneId);

    switch (period.granularity) {
      case PeriodTypes.YEARLY:
        months = 12;
        break;
      case PeriodTypes.QUARTERLY:
        months = 3;
        break;
      default:
        break;
    }

    // Current period
    periods.push(currentPeriod);

    // One period ago same year
    period.start = DateTime.fromISO(currentPeriod.start, { setZone: true }).minus({ months }).toISO();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ months }).toISO();
    period.name = 'last';
    periods.push({ ...period });

    // Same period last year
    period.start = DateTime.fromISO(currentPeriod.start, { setZone: true }).minus({ years: 1 }).toISO();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ years: 1 }).toISO();
    period.name = 'lastYear';
    periods.push({ ...period });

    // Financial year to current period
    period.start = financialYear.start.toISO();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).toISO();
    period.name = 'financialYear';
    periods.push({ ...period });

    // One financial year ago to current period
    period.start = financialYear.start.minus({ years: 1 }).toISO();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ years: 1 }).toISO();
    period.name = 'lastFinancialYear';
    periods.push({ ...period });

    // Two financial year ago to current period
    period.start = financialYear.start.minus({ years: 2 }).toISO();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ years: 2 }).toISO();
    period.name = 'previousFinancialYear';
    periods.push({ ...period });

    return periods;
  }

  // Gets the period ranges for the selected period plus the active periods
  // for the insights that are not profitability insights
  get periodRanges() {
    const insightPeriods = this.filteredInsightGraphData.filter((insightData) => (
      insightData?.categoryData?.categoryId !== InsightTab.profitability
    )).map((insightData) => {
      const insightPeriod = { key: insightData.insightKey, ...insightData.periodData };
      return insightPeriod;
    });
    const periodAssignment = {};

    let fixedPeriodRanges = insightPeriods?.reduce((acc, insight) => {
      if (!acc[insight.name]) {
        acc[insight.name] = {
          start: DateTime.fromFormat(insight.start, 'yyyy-MM-dd', { zone: this.rootStore.businessesStore.selectedBusiness?.timeZoneId }).startOf('day').toISO(),
          end: DateTime.fromFormat(insight.end, 'yyyy-MM-dd', { zone: this.rootStore.businessesStore.selectedBusiness?.timeZoneId }).endOf('day').toISO(),
          name: insight.name,
        };
      }
      periodAssignment[insight.key] = insight.name;
      return acc;
    }, {});

    if (!fixedPeriodRanges[this.selectedPeriodData?.name]) {
      fixedPeriodRanges[this.selectedPeriodData?.name] = this.selectedPeriodData;
    }

    fixedPeriodRanges = { ...fixedPeriodRanges, ...this.dataFetchPeriods() };

    return [Object.values(fixedPeriodRanges), periodAssignment];
  }

  async fetchSelectedPeriodVariables() {
    const businessId = this.rootStore.businessesStore.selectedBusinessId;

    const [periods, assignments] = this.periodRanges;

    this.fetchingVariables = true;

    if (!this.templateData.has(businessId)) {
      this.templateData.set(businessId, new Map());
    }

    const url = `${ApiConstants.apiEndpointsBase.insights}/businesses/${businessId}/datapoints`;

    clearTimeout(this.variableFetchTimeout);
    this.variableFetchTimeout = setTimeout(() => {
      PUT({
        url,
        data: { periods },
        rootStore: this.rootStore,
      })
        .then((response) => {
          if (response) {
            const assignedVariables = {};
            const calculatedData = calculateTrendData(response);
            // As each date range contains all of the possible datapoints, we need only loop through the first one
            Object.keys(Object.values(calculatedData)[0]).forEach((variableKey) => {
              // As we only define a value in variableInsightMapping for non profitability insights
              // we can safely assume that if the variable is not in the mapping, it is a profitability insight
              // and we can assign the value directly from the response for the selected period
              if (ReportEnums.VariableInsightMapping?.[variableKey]) {
                assignedVariables[variableKey] = calculatedData?.[assignments?.[ReportEnums.VariableInsightMapping?.[variableKey]]]?.[variableKey];
              } else {
                assignedVariables[variableKey] = calculatedData[this.selectedPeriodData.name][variableKey];
              }
            });

            this.templateData
              .get(businessId)
              .set(this.selectedPeriodData.name, assignedVariables);
          }
        })
        .catch(() => {
          Notification({ type: 'error', title: 'Failed to fetch variables' });
          this.fetchingVariables = false;
        })
        .finally(() => {
          this.fetchingVariables = false;
        });
    }, 250);
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
}
