import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import DashboardFilters from '../components/dashboard/DashboardFilters';
import { Routers } from '../models/enums/utils';
import PeriodCloseDashboardTable from '../components/periodCloseDashboard/PeriodCloseDashboardTable';
import { useStore } from '../stores/Store';
import PeriodCloseDashboardSplash from '../components/splashScreens/PeriodCloseDashboardSplash';

const PeriodCloseDashboard = () => {
  const { loadingStore, periodCloseDashboardStore, practiceStore, resyncStore } = useStore();

  useEffect(() => {
    resyncStore.watchFirestore(Routers.PERIOD_CLOSE_DASHBOARD);
    return resyncStore.unsubscribeDataStatus;
  }, [practiceStore.id]);

  return (
    !loadingStore.isAnyLoading && (
      <div className='period-close'>
        {periodCloseDashboardStore.dashboardInitialised
        && periodCloseDashboardStore.sortedDashboard.length === 0
        && !periodCloseDashboardStore.rowsLoading
        && periodCloseDashboardStore.dashboard.size === 0
          ? (
            <PeriodCloseDashboardSplash />
          ) : (
            <>
              <DashboardFilters location={Routers.PERIOD_CLOSE_DASHBOARD} />
              <main className='period-close__body'>
                <PeriodCloseDashboardTable />
              </main>
            </>
          )}
      </div>
    )
  );
};

export default observer(PeriodCloseDashboard);
