import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import PaywallModalContent from './content/PaywallModalContent';
import StandardModal from './wrappers/StandardModal';

/**
 * The modal for the paywall modal
 */
const PaywallModal = () => {
  const ModalProps = {
    name: 'Paywall Modal',
    className: 'paywall-modal',
    footer: null,
    closable: false,
    keyboard: false,
    maskClosable: false,
    width: ModalWidths.LARGE,
    content: (<PaywallModalContent />),
  };

  NiceModal.register('PaywallModal', StandardModal);
  return NiceModal.show('PaywallModal', ModalProps);
};

export default PaywallModal;
