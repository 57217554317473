import React from 'react';
import { observer } from 'mobx-react';
import { AiderLogoIcon } from '../../icons';

const AddClientModalError = () => (
  <div className='centered-modal add-client-modal'>
    <AiderLogoIcon className='xero-aider-logo__aider' />
    <h2>No clients connected</h2>
  </div>
);
export default observer(AddClientModalError);
