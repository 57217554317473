/* eslint-disable consistent-return */
/**
 * To validate form with validation rules
 * @param: value: string, type: string (label name), isRequired: boolean
 * @returns string (error msg)
 * @example formValidation('mia@', 'Email Address', true) return 'Please enter a valid email'
 */
import {
  isBlank,
  isEmail,
  isPhoneNum,
} from './validation';

export const formValidation = (
  value: any,
  type?: string,
  isRequired?: boolean
) => {
  const lowerCaseType = type.toLowerCase();
  if (isRequired) {
    if (lowerCaseType.includes('email') && !isEmail(value)) return 'Please enter a valid email';
    if (lowerCaseType.includes('name') && isBlank(value)) return 'Please enter a name';
    if (lowerCaseType.includes('number') && !isPhoneNum(value)) return 'Please enter a phone number';
  }
};
