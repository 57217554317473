import { Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { BounceEllipsis } from '../icons';
import { useStore } from '../../stores/Store';

interface IResyncStatusProps {
  businessId: string;
  callback: Function;
}

const ResyncStatus = ({ businessId, callback }: IResyncStatusProps) => {
  // Global State
  const { resyncStore } = useStore();
  const {
    resyncingBusinesses,
  } = resyncStore;
  const [prevState, setPrevState] = useState<boolean>(false);
  const firestoreDataStatus = resyncStore.dataStatusSnapshots?.get(businessId);
  const pendingData = resyncStore.dataStatusPendingData?.get(businessId);

  if (firestoreDataStatus && pendingData) {
    resyncingBusinesses.set(businessId, false);
  }

  useEffect(() => {
    if (pendingData !== prevState) {
      if (!pendingData && prevState) {
        callback();
      }
      setPrevState(pendingData);
    }
  }, [pendingData]);

  /**
   * Determine which icon to show for any given data check
   */
  const pendingIcon = (check: any, key: string) => {
    if (!check?.status || !firestoreDataStatus?.etl?.started) return null;
    if (key !== 'etl') {
      if (check?.started < firestoreDataStatus?.etl?.started) return null;

      if (check?.status === 'failed') {
        return <CloseOutlined />;
      }

      if (check?.status === 'done') {
        return <CheckOutlined />;
      }

      return (
        <BounceEllipsis />
      );
    }

    const etlDone = firestoreDataStatus?.etl?.status === 'done';
    return etlDone ? <CheckOutlined /> : <BounceEllipsis />;
  };

  /**
   * Build the tooltip content for the pending status
   */
  const pendingTooltip = (
    <ul className='dashboard-resync-status'>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.etl, 'etl')}
        </span>
        Getting Data
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.modelTraining, 'modelTraining')
          || pendingIcon(firestoreDataStatus?.cashflowTraining, 'cashflowTraining')
          || pendingIcon(firestoreDataStatus?.gstTraining, 'gstTraining')}
        </span>
        Calculating Estimates
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.insights, 'insights')}
        </span>
        Calculating Insights
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.tablegroup, 'tablegroup')}
        </span>
        Checking Period Close
      </li>
      <li>
        <span className='status-icon'>
          {pendingIcon(firestoreDataStatus?.dashboard, 'dashboard')}
        </span>
        Updating Dashboard
      </li>
    </ul>
  );

  return (pendingData
    && !resyncingBusinesses.get(businessId)
    ? (
      <Tooltip
        title={pendingTooltip}
        placement='right'
      >
        <InfoCircleOutlined />
      </Tooltip>
    ) : null
  );
};

export default observer(ResyncStatus);
