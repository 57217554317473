/**
 * The Enum list for Filter message without data.
 */
export enum FilterMsg {
  NO_CLIENTS_TAG = 'NO CLIENTS TAGGED. You can add custom tags to specific clients in their Client Setting screen',
  NO_DATA = 'NO INDUSTRY DATA IMPORTED. Please make sure the clients’ industries are specified in their Xero files’ Line of Business settings.',
  NO_MATCHED_OPTION = 'No matches found',
}

/**
 * The Enum list for Filter type.
 */
export enum FilterType {
  INDUSTRY = 'Industry',
  CUSTOM_TAG = 'Custom Tags',
}
