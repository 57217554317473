import React from 'react';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';
import GenAI from '../../icons/GenAI';
import LLMAssistantModal from '../../modals/LLMAssistantModal';

const TextActions = ({
  block,
  className
}: {
  block: PracticeTypes.ReportBlock,
  className: string
}) => {
  const { reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;

  return (
    <aside className={className}>
      {!isEditing && (
        <>
          <Tooltip
            title='AI-assisted Edit'
            placement='bottom'
          >
            <ButtonSecondary
              className='borderless'
              icon={<GenAI />}
              mixpanelEvent='Report Editor - Gen AI'
              onClick={() => {
                LLMAssistantModal(block, reportTemplateStore);
              }}
            />
          </Tooltip>
          <Tooltip
            title='Manual Edit'
            placement='bottom'
          >
            <ButtonSecondary
              className='borderless'
              icon={<EditOutlined />}
              mixpanelEvent='Report Editor - Edit Text'
              mixpanelProperties={{ editBy: 'Action Button' }}
              onClick={() => { reportTemplateStore.editBlock = block.id; }}
            />
          </Tooltip>
        </>
      )}
      {isEditing && (
        <>
          <ButtonSecondary
            className='borderless'
            icon={<CheckCircleOutlined />}
            mixpanelEvent='Report Editor - Save Text'
            onClick={() => {
              reportTemplateStore.editBlock = null;
            }}
          />
        </>
      )}
    </aside>
  );
};

export default observer(TextActions);
