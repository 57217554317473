import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import StandardModal from './wrappers/StandardModal';
import DeleteRuleSuccess from './content/DeleteRuleSuccess';

const DeleteRuleSuccessModal = ({
  rule,
}: {rule: {id: string, title: string}}) => {
  const ModalProps = {
    name: 'Delete Rule - Success Modal',
    className: 'rule-modal rule-modal__delete',
    width: ModalWidths.MEDIUM,
    okButtonProps: {
      className: 'button-secondary',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      className: 'button-secondary btn-hidden',
      shape: 'round',
      size: 'large',
      disabled: true,
    },
    content: <DeleteRuleSuccess rule={rule} />,
  };

  NiceModal.register('DeleteRuleSuccessModal', StandardModal);
  return NiceModal.show('DeleteRuleSuccessModal', ModalProps);
};

export default DeleteRuleSuccessModal;
