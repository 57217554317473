import React from 'react';
import { PracticeTypes } from '@aider/constants-library';
import PageActions from './EditorActions/PageActions';
import GeneralActions from './EditorActions/GeneralActions';
import TextActions from './EditorActions/TextActions';
import ChartActions from './EditorActions/ChartActions';
import TableActions from './EditorActions/TableActions';
import HeaderActions from './EditorActions/HeaderActions';

const EditorRowActions = ({
  block,
  blockInstance
} : {
  block: PracticeTypes.ReportBlock,
  blockInstance: number
}) => {
  const className = 'report-editor__content__row--actions';

  if (!block || block.type === 'page') {
    return (
      <PageActions
        block={block}
        className={className}
        blockInstance={blockInstance}
      />
    );
  }

  if (block.type === 'header') {
    return (
      <HeaderActions
        block={block}
        className={className}
      />
    );
  }

  if (block.type === 'text') {
    return (
      <TextActions
        block={block}
        className={className}
      />
    );
  }

  if (block.type === 'chart') {
    return (
      <ChartActions
        block={block}
        className={className}
      />
    );
  }

  if (block.type === 'table') {
    return (
      <TableActions
        block={block}
        className={className}
      />
    );
  }

  return (
    <GeneralActions
      blockId={block.id}
      className={className}
    />
  );
};

export default EditorRowActions;
