import React from 'react';
import AiderAlert from '../AiderAlert';
import SectionReconnect from './SectionReconnect';
import { useStore } from '../../stores/Store';

const BankRecReconnectBanner = ({ periodType, lastUpdated }
: {periodType: string, lastUpdated: any}) => {
  const { localeStore, businessesStore } = useStore();
  const period = localeStore.translation(`period-close.period-type.${periodType}`);
  const osp = localeStore.translation(`osps.${businessesStore.selectedBusinessOsp}`);
  const title = localeStore.translation('period-close.banner.bankReconciliation.bank-rec-banner-title', { period, osp });
  return (
    <AiderAlert
      className='insight-banner'
      type='warning'
      title={title}
      callToAction={(
        <SectionReconnect
          lastUpdated={lastUpdated}
          buttonOnly
        />
      )}
    />
  );
};

export default BankRecReconnectBanner;
