import { action, observable, computed, makeAutoObservable, reaction } from 'mobx'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { PeriodTypes } from '@aider/aider-period-library';
import type { RootStore } from '../Store';
import { TabItems } from '../../entities/types';

export default class TimePeriodStore {
  rootStore: RootStore;

  @observable periodGranularity: string = PeriodTypes.MONTHLY;

  @observable profitabilityPeriodSelected: string = '';

  @observable profitabilityPeriods: { value: string, label: string }[] = [];

  @observable incomeTaxPeriods = [];

  @observable incomeTaxPeriodSelected: string = '';

  @observable reconciliationPeriods = [];

  @observable gstPeriods = [];

  @observable reconciliationPeriodSelected: string = '';

  @observable gstPeriodSelected: string = '';

  @action setIncomeTaxPeriods(periods) {
    this.incomeTaxPeriods = periods;
  }

  @action setIncomeTaxPeriod(period) {
    this.incomeTaxPeriodSelected = period;
  }

  @action setReconciliationPeriods(periods) {
    this.reconciliationPeriods = periods;
  }

  @action setGstPeriods(periods) {
    this.gstPeriods = periods;
  }

  @action setReconciliationPeriod(period) {
    this.reconciliationPeriodSelected = period;
  }

  @action setGstPeriod(period) {
    this.gstPeriodSelected = period;
  }

  @action.bound setPeriodGranularity(granularity) {
    this.periodGranularity = granularity;
  }

  @action.bound setProfitabilityPeriodSelected(period) {
    this.profitabilityPeriodSelected = period;
  }

  @action.bound setProfitabilityPeriods(periods) {
    this.profitabilityPeriods = periods;
  }

  @computed get incomeTaxPeriodData() {
    return this.incomeTaxPeriods
      .find((period) => period.periodData?.name === this.incomeTaxPeriodSelected);
  }

  @computed get incomeTaxPeriodSummaries() {
    return this.incomeTaxPeriods
      .find((period) => period.periodData?.name === this.incomeTaxPeriodSelected)?.summaries;
  }

  @computed get incomeTaxPeriodDataHealthStatus() {
    return this.incomeTaxPeriods
      .find((period) => period.periodData?.name === this.incomeTaxPeriodSelected)?.dataHealthStatus;
  }

  @computed get incomeTaxPeriodChartSummary() {
    return this.incomeTaxPeriods
      .find((period) => period.periodData?.name === this.incomeTaxPeriodSelected)?.chartSummary;
  }

  @computed get isDataHealthStatusAvailable() {
    return this.incomeTaxPeriodDataHealthStatus !== undefined;
  }

  @computed get reconciliationPeriodSummaries() {
    return this.reconciliationPeriods
      .find((period) => period.periodData?.name === this.reconciliationPeriodSelected)?.summaries;
  }

  @computed get reconciliationPeriodData() {
    return this.reconciliationPeriods
      .find((period) => period.periodData?.name === this.reconciliationPeriodSelected);
  }

  @computed get reconciliationPeriodChartSummary() {
    return this.reconciliationPeriods
      .find(
        (period) => period.periodData?.name === this.reconciliationPeriodSelected
      )?.chartSummary;
  }

  @computed get gstPeriodData() {
    return this.gstPeriods
      .find((period) => period.periodData?.name === this.gstPeriodSelected);
  }

  @computed get gstPeriodSummaries() {
    return this.gstPeriods
      .find((period) => period.periodData?.name === this.gstPeriodSelected)?.summaries;
  }

  @computed get gstPeriodChartSummary() {
    return this.gstPeriods
      .find(
        (period) => period.periodData?.name === this.gstPeriodSelected
      )?.chartSummary;
  }

  @action.bound getInsightOptions(insightData) {
    const allPeriodNames: { value: string, label: string }[] = [];
    const { selectedBusiness } = this.rootStore.businessStore;
    const today = new Date();
    let year = today.getFullYear();
    const fyEnd = selectedBusiness?.financialYearEnd?.split('/') || ['31', '3'];
    const thisYearDate = new Date(`${year}-${fyEnd?.[1]}-${fyEnd?.[0]}`);
    if (thisYearDate.getTime() > today.getTime()) {
      year -= 1;
    }
    switch (this.periodGranularity) {
      case PeriodTypes.QUARTERLY:
        insightData
          // Get all profitability insights & add all period names to allPeriodNames
          .map((insight) => {
            if (!TabItems.Profitability.includes(insight.insightKey)) return null;
            return insight.quarters
              ?.map((quarter) => (quarter.periodData?.name
                ? allPeriodNames.push({
                  value: quarter.periodData.name,
                  label: quarter.periodData.name,
                })
                : null))
              .filter((data) => data);
          });
        break;
      case PeriodTypes.YEARLY:
        allPeriodNames.push({
          value: `${thisYearDate?.toLocaleString('default', { month: 'short' })} ${year}`,
          label: `FY ${year}`,
        });
        break;
      case PeriodTypes.MONTHLY:
      default:
        insightData
          // Get all profitability insights & add all period names to allPeriodNames
          .map((insight) => {
            if (!TabItems.Profitability.includes(insight.insightKey)) return null;
            return insight.periods
              ?.map((period) => (period.periodData?.name
                ? allPeriodNames.push({
                  value: period.periodData.name,
                  label: period.periodData.name,
                })
                : null))
              .filter((data) => data);
          });
        break;
    }

    // Remove duplicate period names
    this.setProfitabilityPeriods(Array.from(
      new Set(
        allPeriodNames.map(
          (period) => JSON.stringify(period)
        )
      )
    ).map((str) => JSON.parse(str)));
  }

  constructor(initStore) {
    makeAutoObservable(this);
    this.rootStore = initStore;
  }
}
