import React from 'react';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { DashboardSortDirection, DashboardSortTarget } from '../../models/enums/components';

type ComplianceTableStatusSortType = {
  activeTarget: DashboardSortTarget,
  activeOrder: DashboardSortDirection,
  setSort: Function,
}

const PeriodCloseTableStatusSort = (
  { activeTarget, activeOrder, setSort } : ComplianceTableStatusSortType
) => (
  <div className='period-close__tooltip-wrapper period-close__tooltip-wrapper--header'>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.status && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.status, DashboardSortDirection.ascending)}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Status',
        order: 'Ascending'
      }}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled className='active' />
          <CaretDownFilled />
        </div>
      )}
    >
      Status Red-Green
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.status && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.status, DashboardSortDirection.descending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled />
          <CaretDownFilled className='active' />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Status',
        order: 'Descending'
      }}
    >
      Status Green-Red
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.progress && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.progress, DashboardSortDirection.ascending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled className='active' />
          <CaretDownFilled />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Checklist Progress',
        order: 'Ascending'
      }}
    >
      % Reviewed Small-Large
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.progress && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.progress, DashboardSortDirection.descending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled />
          <CaretDownFilled className='active' />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Checklist Progress',
        order: 'Descending'
      }}
    >
      % Reviewed Large-Small
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.active && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.active, DashboardSortDirection.ascending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled className='active' />
          <CaretDownFilled />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Active Alerts',
        order: 'Ascending'
      }}
    >
      Outstanding Alerts Small-Large
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.active && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.active, DashboardSortDirection.descending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled />
          <CaretDownFilled className='active' />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Active Alerts',
        order: 'Descending'
      }}
    >
      Outstanding Alerts Large-Small
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.reviewed && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.reviewed, DashboardSortDirection.ascending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled className='active' />
          <CaretDownFilled />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Reviewed Alerts',
        order: 'Ascending'
      }}
    >
      Reviewed Alerts Small-Large
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.reviewed && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('checklistStatus', DashboardSortTarget.reviewed, DashboardSortDirection.descending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled />
          <CaretDownFilled className='active' />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column: 'Checklist Status',
        sortKey: 'Reviewed Alerts',
        order: 'Descending'
      }}
    >
      Reviewed Alerts Large-Small
    </ButtonSecondary>
  </div>
);

export default PeriodCloseTableStatusSort;
