import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Markdown from 'markdown-to-jsx';
import { Dropdown } from 'antd';
import Search from 'antd/es/input/Search';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import ButtonSecondary from '../../../components/customAntD/ButtonSecondary';
import Notification from '../../../components/Notification';
import GenAI from '../../../components/icons/GenAI';
import { useStore } from '../../../stores/Store';
import GenAIWhite from '../../../components/icons/GenAIWhite';
import ContentEditor from './ContentEditor';
import handleError from '../../../lib/errorHandler';

const ExecutiveSummary = () => {
  const rootStore = useStore();
  const { reportDownloadStore } = rootStore;
  const { executiveSummary } = reportDownloadStore;
  const [generatingExecutiveSummary, setGeneratingExecutiveSummary] =
    useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [editingExecutiveSummary, setEditingExecutiveSummary] = useState(false);
  const [editedExecutiveSummary, setEditedExecutiveSummary] = useState(executiveSummary);

  const generateExecutiveSummary = (prompt) => {
    setGeneratingExecutiveSummary(true);
    reportDownloadStore
      .generateExecutiveSummary(prompt)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Executive Summary generated successfully',
          description: 'Your executive summary has been generated.',
        });
      })
      .catch((error) => {
        handleError({ error });
        Notification({
          type: 'error',
          title: 'Executive Summary generation failed',
          description:
            'Could not generate executive summary, please try again...',
        });
      })
      .finally(() => {
        setGeneratingExecutiveSummary(false);
      });
  };

  let items = [
    {
      key: '1',
      label: (
        <ButtonSecondary
          shape='default'
          onClick={() => generateExecutiveSummary(
            'Please provide a simple and short executive summary based on the financial performance for a company in the <<lineOfBusiness>> industry in <<country>>. Don\'t provide follow up actions or headline.\n Financial Performance:\n <<insights>>'
          )}
          mixpanelEvent='Generate Executive Summary'
          className='generative-button'
        >
          Generate Executive Summary
        </ButtonSecondary>
      ),
    }
  ];

  if (executiveSummary) {
    items = [
      ...items,
      {
        key: '2',
        label: (
          <ButtonSecondary
            shape='default'
            onClick={() => generateExecutiveSummary(
              `Simplify & rewrite the following executive summary and performance insights in a style that my advisory client, a small business owner in the <<lineOfBusiness>> industry in <<country>> without a good understanding of accounting terminology, can understand. Avoid introductions and postcripst.\n\n Executive Summary:\n ${executiveSummary}.\n Financial Performance:\n <<insights>>`
            )}
            mixpanelEvent='Rewrite Executive Summary'
            className='generative-button'
          >
            Rewrite in client's tone
          </ButtonSecondary>
        ),
      },
      {
        key: '3',
        label: (
          <>
            <Search
              value={customPrompt}
              onClick={(e) => e?.stopPropagation()}
              placeholder='Enter your prompt here'
              onSearch={() => {
                trackMixpanelEvent({
                  description: 'Rewrite Executive Summary',
                  rootStore,
                  properties: { customPrompt },
                });
                generateExecutiveSummary(
                  `${customPrompt}\n\n Executive Summary:\n ${executiveSummary}.\n Financial Performance:\n <<insights>>`
                );
                setCustomPrompt('');
              }}
              onChange={(e) => {
                setCustomPrompt(e.target.value);
              }}
              enterButton={<GenAIWhite />}
              className='generative-button'
            />
          </>
        ),
      },
    ];
  }

  const editExecutiveSummary = () => {
    setEditedExecutiveSummary(reportDownloadStore.executiveSummary);
    setEditingExecutiveSummary(true);
  };

  const saveExecutiveSummary = () => {
    setEditingExecutiveSummary(false);
    reportDownloadStore.setExecutiveSummary(editedExecutiveSummary);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 className='report-content-heading3'>Executive Summary</h3>
        <div
          style={{
            display: 'flex',
            alignItems: 'right',
          }}
        >
          {editingExecutiveSummary ? (
            <ButtonSecondary
              icon={<SaveOutlined />}
              onClick={saveExecutiveSummary}
              shape='circle'
              size='large'
              style={{ marginRight: 10 }}
              mixpanelEvent='Save Executive Summary'
            />
          ) : (
            <ButtonSecondary
              disabled={generatingExecutiveSummary}
              icon={<EditOutlined />}
              onClick={editExecutiveSummary}
              shape='circle'
              size='large'
              style={{ marginRight: 10 }}
              mixpanelEvent='Edit Executive Summary'
            />
          )}
          <Dropdown
            disabled={generatingExecutiveSummary || editingExecutiveSummary}
            menu={{ items }}
            placement='bottomLeft'
            arrow
          >
            <ButtonSecondary
              shape='circle'
              size='large'
              icon={<GenAI />}
              loading={generatingExecutiveSummary}
            />
          </Dropdown>
        </div>
      </div>
      {executiveSummary && !editingExecutiveSummary && (
        <Markdown>{executiveSummary}</Markdown>
      )}
      {editingExecutiveSummary && (
        <ContentEditor
          content={executiveSummary}
          onChange={(content) => {
            setEditedExecutiveSummary(content);
          }}
        />
      )}
    </>
  );
};

export default observer(ExecutiveSummary);
