/**
 * @file ManageProfile.tsx
 * @desc ManageProfile component allows the user to view and edit their profile details
 */
import { Button, Col, Form, Row } from 'antd';
import * as Sentry from '@sentry/browser';
import { Content, Footer } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import RequiredInput from '../form/RequiredInput';
import Notification from '../Notification';
import { useEnterIntercept } from '../../lib/customHooks';

const ManageProfile = () => {
  const rootStore = useStore();
  const { userStore } = rootStore;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const formOffsetCols = 6;

  // Method to save the user details if the form is valid
  const onFinish = async (values) => {
    setSaving(true);
    trackMixpanelEvent({
      description: 'Save User Profile',
      rootStore
    });

    try {
      userStore.user = {
        id: userStore.id,
        username: values.username,
        givenName: values.firstName,
        familyName: values.lastName,
        email: values.email.toLowerCase(),
        phoneNumber: values.phone,
      };
      await userStore.persistUser();
      setSaving(false);

      Notification({
        type: 'success',
        title: 'Changes saved',
      });

      return ({ status: 'success' });
    } catch (error) {
      Sentry.captureException(error);
      setSaving(false);
      Notification({
        type: 'error',
        title: 'Sorry, unable to save changes',
        description: 'Please try again.'
      });
      return ({ status: 'error', error });
    }
  };
  // Method to handle form validation errors
  const onFinishFailed = () => {
    Notification({
      type: 'error',
      title: 'Sorry, unable to save changes',
      description: 'Please try again.'
    });
  };

  // Inject enter intercept handler into form
  useEnterIntercept({
    dependencies: [form],
    callback: form.submit
  });

  return (
    <>
      <header className='sub-content__header'>
        <h2 className='sub-content__header--title wrapper'>My Profile</h2>
      </header>
      <Content className='sub-content__content scrollbar'>
        <div className='wrapper'>
          {userStore.hasUserDetails && (
          <Form
            className='settings-form'
            labelCol={{ span: formOffsetCols }}
            requiredMark='optional'
            colon={false}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateTrigger='onBlur'
            form={form}
          >
            <Row>
              <Col xs={0} sm={formOffsetCols} />
              <Col>
                <h3>User Details</h3>
              </Col>
            </Row>
            <Form.Item
              name='firstName'
              label='First Name'
              required
              initialValue={userStore.user.givenName}
              rules={[{ required: true, message: 'Please enter a first name' }]}
            >
              <RequiredInput loading={saving} />
            </Form.Item>

            <Form.Item
              name='lastName'
              label='Last Name'
              required
              initialValue={userStore.user.familyName}
              rules={[{ required: true, message: 'Please enter a last name' }]}
            >
              <RequiredInput loading={saving} />
            </Form.Item>

            <Form.Item
              name='username'
              label='Display Name'
              required
              initialValue={userStore.user.username}
              rules={[{ required: true, message: 'Please enter a display name' }]}
            >
              <RequiredInput loading={saving} />
            </Form.Item>
            <Row>
              <Col xs={0} sm={formOffsetCols} />
              <Col>
                <h3>Contact Details</h3>
              </Col>
            </Row>
            <Form.Item
              name='email'
              label='Email'
              required
              initialValue={userStore.user.email}
              rules={[{ required: true, type: 'email', message: 'Please enter an email' }]}
            >
              <RequiredInput loading={saving} />
            </Form.Item>
            <Form.Item
              name='phone'
              label='Phone'
              initialValue={userStore.user.phoneNumber}
              extra='Please include country code: eg. +64 +61'
              rules={[{
                pattern: /^(?:\+){1}(?:[0-9]●?){1,14}[0-9]$/,
                message: 'Please use international phone format'
              }]}
            >
              <RequiredInput loading={saving} />
            </Form.Item>
          </Form>
          )}
        </div>
      </Content>
      <Footer className='sub-content__footer'>
        <Button
          type='primary'
          htmlType='submit'
          onClick={form.submit}
          shape='round'
          size='large'
          loading={saving}
        >
          {saving ? 'Saving' : 'Save'}
        </Button>
      </Footer>
    </>
  );
};

export default observer(ManageProfile);
