import React from 'react';
import AccountNavbar from '../../components/accountSettings/AccountNavbar';
import AccountHeader from '../../components/accountSettings/Header';
import ManageAdvisors from '../../components/accountSettings/ManageAdvisors';
import SubLayoutSettings from '../../layouts/SubLayoutSettings';

const Advisors = () => (
  <SubLayoutSettings
    SubNav={(<AccountNavbar />)}
    HeaderContent={(<AccountHeader />)}
    SubMain={(<ManageAdvisors />)}
  />
);

export default Advisors;
