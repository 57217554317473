import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const aiderSvg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ stroke: 'currentColor' }}
  >
    <path
      d='M19.3778 8.1261C19.0368 6.01101 17.9232 4.01247 16.093 2.59665C14.5607 1.41393 12.6788 0.770752 10.745 0.770752C5.91497 0.770752 2 4.68583 2 9.516C2 10.9534 2.35394 12.3649 3.03162 13.6339C3.03162 13.6426 3.02299 13.6555 3.01867 13.6642L2.25035 16.539C2.18129 16.7936 2.33236 17.0526 2.58703 17.1174C2.66904 17.1389 2.75105 17.1389 2.82875 17.1174L5.40132 16.4267C5.88044 16.798 6.38115 17.1087 6.90343 17.3634'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.0179 20.0268C22.7186 17.4455 22.1704 13.9491 19.6842 12.024C17.0771 10.0081 13.3261 10.4873 11.306 13.0944C10.4989 14.139 10.0586 15.4254 10.0586 16.7462C10.0586 20.044 12.7348 22.7203 16.0325 22.7203C16.7879 22.7203 17.5303 22.5735 18.2295 22.2972L19.6842 22.7462L21.1949 23.2123C21.4582 23.2944 21.7086 23.0613 21.6481 22.7937L21.2985 21.2527L21.0179 20.0225V20.0268Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const AdvisoryAssistant = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={aiderSvg}
    {...props}
  />
);

export default AdvisoryAssistant;
