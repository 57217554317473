import { clearPersistedStore, makePersistable, stopPersisting } from 'mobx-persist-store';
import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { ApiConstants } from '@aider/constants-library';
import type { RootStore } from './Store';
import { GET, PUT } from '../lib/requests';
import { AIDER_LINKS } from '../models/constants/root';

class UserStore {
  rootStore: RootStore;

  id: string;

  accountType: string;

  username: string;

  email: string;

  givenName: string;

  familyName: string;

  phoneNumber: string;

  requiresUnsubscribe: boolean;

  unsubscribeError: boolean;

  showUnsubscribeModal: boolean;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  initStorePersistance() {
    makePersistable(this, {
      name: 'UserStore',
      properties: [
        'id',
        'accountType',
        'username',
        'email',
        'givenName',
        'familyName',
        'phoneNumber',
      ],
      storage: window.localStorage,
    });
  }

  async clearStoredData() {
    await clearPersistedStore(this);
    await stopPersisting(this);
  }

  set setid(id: string) {
    this.id = id;
  }

  set user({
    id,
    username,
    email,
    givenName,
    familyName,
    phoneNumber,
  }) {
    if (id) this.id = id;
    if (username) this.username = username;
    if (email) this.email = email.toLowerCase();
    if (givenName) this.givenName = givenName;
    if (familyName) this.familyName = familyName;
    if (phoneNumber) this.phoneNumber = phoneNumber;
  }

  get user() {
    return {
      id: this.id,
      username: this.username,
      email: this.email.toLowerCase(),
      givenName: this.givenName,
      familyName: this.familyName,
      phoneNumber: this.phoneNumber
    };
  }

  get userInitials() {
    const inits = (this.givenName && this.familyName && `${this.givenName[0]}${this.familyName[0]}`) || 'UNK';
    return inits.toUpperCase();
  }

  get hasUserDetails() {
    return typeof this.id !== 'undefined'
      && typeof this.username !== 'undefined'
      && typeof this.email !== 'undefined'
      && typeof this.givenName !== 'undefined'
      && typeof this.familyName !== 'undefined';
  }

  async retrieveUserDetail() {
    const url = `${ApiConstants.apiEndpointsBase.user}/users/${this.id}`;
    return GET({ url, rootStore: this.rootStore })
      .then((data) => {
        if (data) {
          this.user = {
            id: data.userId,
            username: data.displayName,
            email: data.email.toLowerCase(),
            givenName: data.givenName,
            familyName: data.familyName,
            phoneNumber: data.phoneNumber,
          };
        }

        return this.user;
      });
  }

  async persistUser() {
    this.rootStore.authenticationStore.renewTokens();

    const url = `${AIDER_LINKS.users.href}/${this.id}`;
    const data = {
      _embedded: {
        user: {
          email: this.email.toLowerCase(),
          givenName: this.givenName,
          familyName: this.familyName,
          displayName: this.username,
          phoneNumber: this.phoneNumber,
        },
      }
    };
    await PUT({ url, data, rootStore: this.rootStore });
    return this.user;
  }

  async unsubscribeFromEmailService() {
    this.requiresUnsubscribe = false;
    try {
      await PUT({
        url: `${ApiConstants.apiEndpointsBase.configuration}/users/email/preferences`,
        data: { clientSummary: 'disabled' },
        rootStore: this.rootStore,
      });
      this.unsubscribeError = false;
      this.showUnsubscribeModal = true;
    } catch (e) {
      this.unsubscribeError = true;
      this.showUnsubscribeModal = true;
      Sentry.captureException('Error unsubscribing from email service', e);
    }
  }
}

export default UserStore;
