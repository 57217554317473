import { makeAutoObservable } from 'mobx';
import type { RootStore } from './Store';

export type alert = {
  [businessId: string]: {
    [category: string]: number,
  }
};

export default class AlertStore {
  rootStore: RootStore;

  alerts = {};

  activeCount = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }

  clearAlerts(businessId?: string, category?: string) {
    if (businessId && category) {
      delete this.alerts[businessId][category];
      return;
    }

    if (businessId && !category) {
      delete this.alerts[businessId];
      return;
    }

    this.alerts = {};
  }

  alert(businessId: string, category: string, value: number) {
    if (!businessId) return;
    if (!category) return;
    const currentAlert = this.getCategoryAlert(businessId, category);
    this.alerts[businessId] = this.alerts[businessId] || {};
    this.alerts[businessId][category] = currentAlert + value;
  }

  overwriteAlert(businessId: string, category: string, value: number) {
    if (!businessId) return;
    if (!category) return;
    if (!this.alerts[businessId]) this.alerts[businessId] = {};
    this.alerts[businessId][category] = value;
    this.activeCount = value;
  }

  getCategoryAlert(businessId: string, category: string) {
    if (!this.alerts[businessId]) return 0;
    if (!this.alerts[businessId][category]) return 0;

    return this.alerts[businessId][category];
  }

  getBusinessAlerts(businessId: string) {
    if (!this.alerts[businessId]) return 0;

    return Object.values(this.alerts[businessId]).reduce((acc: any, category: any) => category + acc, 0);
  }
}
