import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'mobx-react';
import NiceModal from '@ebay/nice-modal-react';
import { ConfigProvider } from 'antd';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { rootStore } from './stores/Store';
import { Brand } from './ts/enums/Colors';

// Provide the store to the children
ReactDOM.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: Brand.blue,
        colorSuccess: '#73d13d',
        colorWarning: '#ffc53d',
        borderRadius: 4,
        fontSize: 16
      },
    }}
  >
    <NiceModal.Provider>
      <Provider
        rootStore={rootStore}
        authStore={rootStore.authStore}
        businessStore={rootStore.businessStore}
        actionStore={rootStore.actionStore}
        pageStore={rootStore.pageStore}
        GraphStore={rootStore.GraphStore}
        tagStore={rootStore.tagStore}
      >
        <App />
      </Provider>
    </NiceModal.Provider>
  </ConfigProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();
