import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';
import { Brand } from '../../../ts/enums/Colors';
/**
 * TRashcan Icon
 * @param props
 * @returns
 */
export const InfoRing = ({
  width = 17,
  height = 17,
  strokeWidth = 2,
  color = Brand.blue,
  fill = 'none',
  lineCap = 'round',
  lineJoin = 'round',
  padding = 0.25,
  scale = 1,
  style = {},
  className = '',
  ...rest
}: IconInterface) => {
  const scaleWidth = width * scale;
  const scaleHeight = height * scale;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { min, max } = getPlotExtremes({
    width: scaleWidth,
    height: scaleHeight,
    padding,
  });
  return (
    <svg
      width={scaleWidth}
      height={scaleHeight}
      viewBox='0 0 17 17'
      fill={fill}
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap={lineCap}
      strokeLinejoin={lineJoin}
      style={{ ...style }}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...rest}
    >
      <path
        d='M8.6901 14.8988C12.372 14.8988 15.3568 11.914 15.3568 8.2321C15.3568 4.5502 12.372 1.56543 8.6901 1.56543C5.00821 1.56543 2.02344 4.5502 2.02344 8.2321C2.02344 11.914 5.00821 14.8988 8.6901 14.8988Z'
        strokeWidth={strokeWidth * 0.75}
      />
      <path
        d='M8.68945 10.8991V8.23242'
      />
      <path
        d='M8.68945 5.56543H8.69612'
      />
    </svg>
  );
};
