import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const aiderSvg = () => (
  <svg
    height='1em'
    viewBox='0 0 175 169'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M109.2 88.3947C116.696 91.6647 123.878 94.7146 130.928 98.0434C132.527 98.7981 133.668 100.521 135.019 101.798C133.595 103.027 132.358 104.666 130.715 105.418C121.205 109.766 111.619 113.952 102.019 118.101C93.6703 121.709 87.7811 127.693 84.1711 136.003C80.2031 145.137 76.3066 154.305 72.132 163.344C71.197 165.368 69.2359 166.919 67.7471 168.688C66.2922 166.85 64.4042 165.209 63.4583 163.138C59.597 154.682 55.9074 146.143 52.3759 137.544C48.6437 128.456 42.4015 122.043 33.3533 118.173C23.7415 114.061 14.1981 109.782 4.7168 105.379C3.12579 104.64 2.00122 102.897 0.661133 101.618C2.04974 100.366 3.23773 98.6734 4.85999 97.9349C14.3748 93.6037 23.9922 89.4982 33.5725 85.3109C41.8699 81.6844 47.8395 75.7564 51.4402 67.4125C55.6489 57.6601 59.874 47.9131 64.2722 38.2458C64.9427 36.772 66.5855 35.7406 67.7786 34.5046C68.9819 35.7189 70.6298 36.7247 71.3132 38.1827C74.6938 45.3949 78.3557 52.5437 80.9194 60.0557C85.7216 74.1271 94.5826 83.7631 109.2 88.3947Z'
      fill='url(#paint0_linear_180307_2392)'
    />
    <path
      d='M113.602 31.856C119.915 29.4664 123.818 25.2394 126.19 19.3775C128.054 14.7698 130.126 10.2448 132.167 5.71028C132.798 4.30795 133.628 2.99518 135.005 0.473358C136.589 3.58012 137.68 5.50149 138.577 7.50937C149.018 30.8836 145.007 26.5971 168.258 37.1122C170.246 38.0111 172.156 39.083 174.102 40.0745C174.09 40.5922 174.078 41.1099 174.066 41.6276C169.428 43.6451 164.9 45.9803 160.13 47.612C150.973 50.7449 145.036 56.6555 141.999 65.898C140.44 70.6404 137.931 75.0705 135.199 81.0771C133.491 77.8408 132.42 76.1064 131.622 74.2545C121.454 50.6567 124.875 54.7367 102.217 44.406C100.071 43.4276 98.006 42.2723 95.9036 41.1991C95.8782 40.68 95.853 40.1608 95.8276 39.6417C101.643 37.1087 107.459 34.5757 113.602 31.856Z'
      fill='url(#paint1_linear_180307_2392)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_180307_2392'
        x1='67.8403'
        y1='34.5046'
        x2='67.8403'
        y2='168.688'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4263EA' />
        <stop
          offset='1'
          stopColor='#00E0E1'
        />
      </linearGradient>
      <linearGradient
        id='paint1_linear_180307_2392'
        x1='134.965'
        y1='0.473358'
        x2='134.965'
        y2='81.0771'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4263EA' />
        <stop
          offset='1'
          stopColor='#00E0E1'
        />
      </linearGradient>
    </defs>
  </svg>
);

const GenAI = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={aiderSvg}
    {...props}
  />
);

export default GenAI;
