import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import AddClientSuccessCommon from './content/AddClientSuccessCommon';
import Mixpanel from '../../lib/mixpanel';
import { AiderLogoIcon } from '../icons';
import StandardModal from './wrappers/StandardModal';

const AddClientSuccessModal = async (rootStore) => {
  Mixpanel.track('addClient_confirming', {
    updatedClientImportCount: rootStore.connectionsStore.newConnectionCount,
    previousClientImportCount: rootStore.connectionsStore.previousClientCount,
  });

  /**
   * Handle the OK button click
   * @returns {void}
   */
  const okHandler = () => {
    rootStore.connectionsStore.resetAddClient();
    Mixpanel.track('addClient_confirmed');
  };

  /**
   * Return the modal props object
   */
  const ModalProps = {
    name: 'Tier Change Modal - Add Client Success',
    className: 'tier-modal',
    width: ModalWidths.LARGE,
    okText: "Let's go!",
    onOk: okHandler,
    afterClose: rootStore.connectionsStore.resetAddClient,
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      danger: true,
      disabled: true,
      shape: 'round',
      size: 'large',
      className: 'button-secondary btn-hidden',
    },
    content: (
      <section className='centered-modal add-client-modal'>
        <AiderLogoIcon className='xero-aider-logo__aider' />
        <AddClientSuccessCommon />
      </section>
    ),
  };
  NiceModal.register('AddClientSuccessModal', StandardModal);
  return NiceModal.show('AddClientSuccessModal', ModalProps);
};

export default AddClientSuccessModal;
