import { makeAutoObservable } from 'mobx';
import type { HelpTitle } from '../../entities/helpText';
import type { RootStore } from '../Store';

export default class HelpPanelStore {
  rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }

  selectedIndex: number = null;

  visibleHelpItems: HelpTitle[] = [];

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  setHelpItemVisible(item: HelpTitle) {
    if (!this.visibleHelpItems.includes(item)) {
      // Won't trigger observers unless visibleHelpItems is reassigned, so .push() doesn't work
      this.visibleHelpItems = this.visibleHelpItems.concat([item]);
    }
  }

  setHelpItemHidden(item: HelpTitle) {
    this.visibleHelpItems = this.visibleHelpItems.filter((title) => title !== item);
  }

  hideAllHelpItems() {
    this.visibleHelpItems = [];
  }

  toggleHelpItemVisibility(item: HelpTitle) {
    if (this.visibleHelpItems.includes(item)) {
      this.setHelpItemHidden(item);
    } else {
      this.setHelpItemVisible(item);
    }
  }
}
