import { makeAutoObservable } from 'mobx';
import type { RootStore } from './Store';

export default class LoadingStore {
  rootStore: RootStore;

  loadingMessages = {
    advisors: 'Retrieving user details…',
    checklists: 'Loading checklists...',
    defaultBusiness: 'Analysing client list...',
    help: 'Loading help content...',
    dashboard: 'Loading Dashboard...',
    insights: 'Loading insights...',
    settings: 'Loading settings...',
    business: 'Loading clients...',
    auth: 'Verifying identity and permissions...',
    split: 'Checking for new beta features...',
    invitationAcceptance: 'Verifying invitation...',
    queryParams: 'Loading requested page and data...',
    clientManagement: 'Loading client details...',
    locale: 'Loading language settings...',
    initialLogin: 'Creating user account. This may take a moment...',
    appReferral: 'Processing referral...',
  }

  loading: string[] = [];

  bypassLoading: string[] = [];

  setGlobalBypass = (...loaders: (keyof LoadingStore['loadingMessages'])[]) => {
    this.bypassLoading.push(...loaders.map((loader) => this.loadingMessages[loader]));
  }

  removeGlobalBypass = (loader: keyof LoadingStore['loadingMessages']) => {
    this.bypassLoading = this.bypassLoading.filter((loadingMessage) => loadingMessage !== this.loadingMessages[loader]);
  }

  setLoading = (loader: keyof LoadingStore['loadingMessages']) => {
    if (this.bypassLoading.includes(this.loadingMessages[loader])) return;
    this.loading.unshift(this.loadingMessages[loader]);
  }

  setDoneLoading = (loader: keyof LoadingStore['loadingMessages']) => {
    this.loading = this.loading.filter((loadingMessage) => loadingMessage !== this.loadingMessages[loader]);
    this.removeGlobalBypass(loader);
  }

  isLoading = (loader: keyof LoadingStore['loadingMessages']) => this.loading.includes(this.loadingMessages[loader])

  get isAnyLoading() { return this.loading.length > 0; }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }
}
