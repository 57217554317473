// eslint-disable-next-line no-shadow
export enum PageType {
  CLIENTLIST = 'CLIENT_LIST',
  CONTACT = 'CONTACT',
  CONTACT_THANKS = 'CONTACT_THANKS'
}

export enum InsightRowType {
  HEADER = 'header',
  SUBHEADER = 'subheader',
  SEPARATED = 'separated',
  NOTE = 'note',
  SUMMARY = 'summary-',
}

export enum InsightColumnType {
  TREND = 'dataTrend'
}
