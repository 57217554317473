import React, { useEffect } from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import CommentsModal from '../modals/CommentsModal';

const CommentButton = ({ locationIdentifier, title }) => {
  const rootStore = useStore();
  const { commentsStore } = rootStore;
  const [form] = Form.useForm();
  useEffect(() => commentsStore.loadComments(locationIdentifier), []);

  return (
    <ButtonSecondary
      className='rule-button rule-button__edit'
      icon={<MessageOutlined />}
      loading={commentsStore.isLoading}
      mixpanelEvent='Opened comments'
      mixpanelProperties={{ locationIdentifier }}
      onClick={() => {
        commentsStore.selectedLocation = locationIdentifier;
        CommentsModal({
          rootStore,
          form,
          locationIdentifier,
          title
        });
      }}
    >
      <span>{commentsStore.comments.get(locationIdentifier)?.length || 0}</span>
    </ButtonSecondary>
  );
};

export default observer(CommentButton);
