import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../stores/Store';
import DashboardSort from './DashboardSort';
import { useDebouncedEffect } from '../../lib/customHooks';
import { DashboardColumns } from '../../models/constants/components';
import { EWSIcon } from '../icons';
import { Routers } from '../../models/enums/utils';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const DashboardHeader = () => {
  const rootStore = useStore();
  const { dashboardStore, localeStore } = rootStore;
  const { translation } = localeStore;
  const [searchString, setSearchString] = useState(() => dashboardStore.activeSearchString);
  const navigate = useNavigate();

  const handleSearch = (value) => {
    setSearchString(value);
  };

  const handleSearchType = (e) => {
    setSearchString(e?.target?.value);
  };

  useDebouncedEffect(() => {
    const prevSearchString = dashboardStore.activeSearchString;
    if (prevSearchString === searchString) return;
    dashboardStore.loadingMore = true;
    dashboardStore.activeSearchString = searchString;
    dashboardStore.initialiseDashboard(true);
  }, [searchString], 1000);

  return (
    <header className='dashboard-table__header'>
      <div className='dashboard-table__header__column'>
        <h2 className='dashboard-table__header__column--title'>
          {dashboardStore.rows.length >= 0 && dashboardStore.totalBusinessCount
            ? (
              <>
                {translation('performance-dashboard.header-group.clients', {
                  showCount: dashboardStore.rows.length,
                  totalCount: dashboardStore.totalBusinessCount + dashboardStore.loadingBusinesses.size,
                })}
              </>
            ) : (
              <>
                {translation('performance-dashboard.header-group.clients-fallback', {
                  showCount: dashboardStore.rows.length,
                })}
              </>
            )}
        </h2>
        <div className='dashboard-table__header__column--action'>
          <Button
            icon={<PlusOutlined />}
            ghost
            shape='round'
            size='small'
            onClick={() => {
              trackMixpanelEvent({ description: 'Dashboard: Add clients button clicked', rootStore });
              return navigate(Routers.CLIENTS_MANAGEMENT);
            }}
          >
            Add Clients
          </Button>
          <DashboardSort column='client' />
        </div>
        <div className='dashboard-table__header__column--action'>
          <Input.Search
            placeholder='Search by name'
            onSearch={handleSearch}
            onChange={handleSearchType}
            defaultValue={dashboardStore.activeSearchString}
            allowClear
          />
        </div>
      </div>
      {dashboardStore.columns.map((column) => (
        <div
          className='dashboard-table__header__column'
          key={`dashboard-header-category__${uuid()}`}
        >
          <h2 className='dashboard-table__header__column--title'>
            {translation(`performance-dashboard.header-group.${column.key}`)}
          </h2>
          <div className='dashboard-table__header__column--subtext'>
            {column?.children?.map((child) => {
              const detail = DashboardColumns?.[child.key];
              return (
                <div
                  className='dashboard-table__header__column--subtext__column'
                  key={`dashboard-header-column__${uuid()}`}
                >
                  <Tooltip
                    placement='bottom'
                    title={(
                      <div className='dashboard-tooltip'>
                        {detail.ews && (
                          <h4>
                            <EWSIcon />
                            {translation('common.ews')}
                          </h4>
                        )}
                        <p>{translation(`performance-dashboard.headers.${child.key}.tooltip`, detail?.tooltipOptions || {})}</p>
                      </div>
                    )}
                  >
                    {detail.ews && <EWSIcon />}
                    {translation(`performance-dashboard.headers.${child.key}.title`, detail?.tooltipOptions || {})}
                  </Tooltip>
                  <DashboardSort column={child.key} />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </header>
  );
};

export default observer(DashboardHeader);
