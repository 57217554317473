import React from 'react';
import { observer } from 'mobx-react';
import cookie from 'js-cookie';
import Separator from '../Separator';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { GoogleLogo, QuickbooksLogo, QuickbooksLogoHover, XeroLoginLogoHover, XeroLoginLogo } from '../icons';
import { useStore } from '../../stores/Store';
import { LoginErrorType } from '../../stores/authenticationStore';
import AiderAlert from '../AiderAlert';
import TermsCheckbox from './TermsCheckbox';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import { CookieKeys } from '../../ts/enums/Constants';

const SocialButtons = ({ className, label, registration, invitation }: { className?: string, label?: string, registration?: boolean, invitation?: boolean }) => {
  const { authenticationStore, invitationStore, localeStore } = useStore();
  const [googleLoading, setGoogleLoading] = React.useState(false);
  const [xeroLoading, setXeroLoading] = React.useState(false);
  const [qbLoading, setQbLoading] = React.useState(false);
  const [isQbHovered, setIsQbHovered] = React.useState(false);
  const [isXeroHovered, setIsXeroHovered] = React.useState(false);

  const errorKey = registration ? 'Sign up' : 'Login';

  return (
    <div className={`social-buttons ${className}`}>
      <Separator>{label}</Separator>
      {authenticationStore.loginError
        && authenticationStore.loginErrorType === LoginErrorType.EMAIL_NOT_VERIFIED
        ? (
          <AiderAlert type='error' title='Sign up with Intuit unsuccessful'>
            <p className='top-line'>Your email address is not yet verified by Intuit.</p>
            <p>
              <ButtonPrimary className='aider-alert__content--body__alert-with-button' href='https://accounts.intuit.com/app/account-manager/security' type='link' shape='default'>
                Please sign in to Intuit and verify your email address first {'>'}
              </ButtonPrimary>
              Once that is done, come back and sign up with Intuit.
            </p>
          </AiderAlert>
        )
        : authenticationStore.loginError
        && <AiderAlert type='error' title={`${errorKey} unsuccessful`}>Something went wrong with the {errorKey.toLowerCase()} process. Please try again.</AiderAlert>}

      {invitation && invitationStore.error && <AiderAlert type='error' title='Something went wrong, could not accept invitation.'>Please check your email address and try again</AiderAlert>}
      {(registration || invitation) && <TermsCheckbox />}
      {invitation && (
        <AiderAlert
          title='Please use the same email address as your invite.'
        >If you have multiple {localeStore.translation('osps.xero')} / {localeStore.translation('osps.google')} / {localeStore.translation('osps.intuit')} logins, use the one with the SAME email address as your invite.
        </AiderAlert>
      )}
      <div className='social-buttons__buttons'>
        <ButtonSecondary
          className='social-buttons__login-buttons'
          ghost
          loading={googleLoading}
          disabled={!!googleLoading || ((registration || invitation) && authenticationStore.termsAccepted === false)}
          onClick={() => {
            cookie.remove(CookieKeys.sessionTimedOut);
            setGoogleLoading(true);
            authenticationStore.signInWithGoogle()
              .then(() => {
                setGoogleLoading(false);
              });
          }}
          size='large'
          icon={<GoogleLogo className={googleLoading || ((registration || invitation) && authenticationStore.termsAccepted === false) ? 'social-buttons__disabled' : ''} />}
        >Google
        </ButtonSecondary>
        <ButtonSecondary
          shape='default'
          className='social-buttons__login-buttons borderless'
          loading={xeroLoading}
          disabled={!!xeroLoading || ((registration || invitation) && authenticationStore.termsAccepted === false)}
          onClick={() => {
            cookie.remove(CookieKeys.sessionTimedOut);
            setXeroLoading(true);
            authenticationStore.signInWithSSO()
              .then(() => {
                setXeroLoading(false);
              });
          }}
          icon={
            isXeroHovered
              ? <XeroLoginLogoHover />
              : <XeroLoginLogo className={qbLoading || ((registration || invitation) && authenticationStore.termsAccepted === false) ? 'social-buttons__disabled' : ''} />
          }
          onMouseEnter={() => setIsXeroHovered(true)}
          onMouseLeave={() => setIsXeroHovered(false)}
        />
        <ButtonSecondary
          shape='default'
          className='social-buttons__login-buttons borderless'
          loading={qbLoading}
          disabled={!!qbLoading || ((registration || invitation) && authenticationStore.termsAccepted === false)}
          onClick={() => {
            cookie.remove(CookieKeys.sessionTimedOut);
            setQbLoading(true);
            authenticationStore.signInWithSSO('intuit', 'quickbooks')
              .then(() => {
                setQbLoading(false);
              });
          }}
          onMouseEnter={() => setIsQbHovered(true)}
          onMouseLeave={() => setIsQbHovered(false)}
          icon={
            isQbHovered
              ? <QuickbooksLogoHover />
              : <QuickbooksLogo className={qbLoading || ((registration || invitation) && authenticationStore.termsAccepted === false) ? 'social-buttons__disabled' : ''} />
          }
        />
      </div>
      <Separator />
    </div>
  );
};

SocialButtons.defaultProps = {
  label: 'Sign up with',
  className: '',
  registration: false,
  invitation: false,
};

export default observer(SocialButtons);
