/**
 * @file FirmInformation.tsx
 * @desc FirmInformation component allows a user to manage practice information
 */
import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { Content, Footer } from 'antd/lib/layout/layout';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import FirmInformationForm from '../form/FirmInformationForm';

const FirmInformation = () => {
  const rootStore = useStore();
  const { practiceStore } = rootStore;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const formOffsetCols = 6;

  return (
    <>
      <header className='sub-content__header'>
        <h2 className='sub-content__header--title wrapper'>Firm Information</h2>
      </header>
      <Content className='sub-content__content scrollbar'>
        <div className='wrapper'>
          {practiceStore.hasId && (
            <FirmInformationForm
              form={form}
              saving={saving}
              setSaving={setSaving}
              formOffsetCols={formOffsetCols}
            />
          )}
        </div>
      </Content>
      <Footer className='sub-content__footer'>
        <Button
          type='primary'
          htmlType='submit'
          onClick={form.submit}
          shape='round'
          size='large'
          loading={saving}
        >
          {saving ? 'Saving' : 'Save'}
        </Button>
      </Footer>
    </>
  );
};

export default observer(FirmInformation);
