import React from 'react';
import SuccessIllustration from '../../illustrations/SuccessIllustration';

const DeleteRuleSuccess = ({ rule }:{rule: {id: string, title: string}}) => (
  <section className='rule-modal__body centered-modal'>
    <div className='illustration'>
      <SuccessIllustration />
    </div>
    <h3>&quot;
      <span
        className={rule.title.length > 33 ? 'truncated' : ''}
        style={{
          maxWidth: rule.title.length > 33 ? `${rule.title.lastIndexOf(' ', 32) || 33}ch` : 'auto'
        }}
      >
        {rule?.title}
      </span>&quot;<br />has been deleted for this and future periods
    </h3>
  </section>
);

export default DeleteRuleSuccess;
