import { useEffect, useCallback } from 'react';

export const useEnterIntercept = ({ dependencies = [], callback }) => {
  const enterIntercept = useCallback((event) => {
    // Method to intercept the enter key and submit the form
    if (event.key === 'Enter') {
      callback(event);
    }

    // Ignore warning for deps in this hook as it is necessary for the hook to work
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  // Add event listener to intercept enter key, and perform cleanup on unmount
  const removeEnterIntercept = useCallback(() => {
    document.removeEventListener('keydown', enterIntercept);
  }, [enterIntercept]);

  useEffect(() => {
    document.addEventListener('keydown', enterIntercept);
    return removeEnterIntercept;
  }, [enterIntercept, removeEnterIntercept]);
};
