import React, { useEffect, useState } from 'react';
import { Checkbox, Select } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import PeriodGranularitySelectComponent from '../../../components/PeriodGranularity';
import { InsightType } from '../../../ts/enums/Constants';
import { useStore } from '../../../stores/Store';

interface InsightSelectorProps {
  insightsAvailable: any[];
}
interface reportInsightCheckedList {
  title: string;
  insightKey: string;
}

const InsightSelector: React.FC<InsightSelectorProps> = ({
  insightsAvailable,
}) => {
  const CheckboxGroup = Checkbox.Group;
  const rootStore = useStore();
  const { localeStore, reportDownloadStore, timePeriodStore } = rootStore;
  const { translation } = localeStore;

  const profitabilityOptions: reportInsightCheckedList[] = [
    { title: translation('insights.revenue.shortTitle'), insightKey: 'revenue' },
    { title: translation('insights.directCosts.shortTitle'), insightKey: 'directCosts' },
    { title: translation('insights.grossProfit.shortTitle'), insightKey: 'grossProfit' },
    { title: translation('insights.operationalExpenses.shortTitle'), insightKey: 'operationalExpenses' },
    { title: translation('insights.netProfit.shortTitle'), insightKey: 'netProfit' },
  ];
  const cashflowOptions: reportInsightCheckedList[] = [
    { title: translation('insights.cashFlow.shortTitle'), insightKey: 'cashFlow' },
    { title: translation('insights.cashFlowActual.shortTitle'), insightKey: 'cashFlowActual' },
    { title: translation('insights.invoiceStatus.shortTitle'), insightKey: 'invoiceStatus' },
  ];
  const complianceOptions: reportInsightCheckedList[] = [
    { title: translation('insights.gst.shortTitle'), insightKey: 'gst' },
    { title: translation('insights.incomeTax.shortTitle'), insightKey: 'incomeTax' },
  ];

  const [checkedListProfitability, setCheckedListProfitability] = useState<
    reportInsightCheckedList[]
  >(() => profitabilityOptions.filter((option) => insightsAvailable.includes(option.insightKey)));
  const [checkedListCashflow, setCheckedListCashflow] = useState<
    reportInsightCheckedList[]
  >([]);
  const [checkedListCompliance, setCheckedListCompliance] = useState<
    reportInsightCheckedList[]
  >([]);

  useEffect(() => {
    reportDownloadStore.setInsightsSelected([
      ...checkedListProfitability.map((item) => item.insightKey),
      ...checkedListCashflow.map((item) => item.insightKey),
      ...checkedListCompliance.map((item) => item.insightKey),
    ]);
  }, [checkedListProfitability, checkedListCashflow, checkedListCompliance]);

  const [indeterminateProfitability, setIndeterminateProfitability] =
    useState(false);
  const [indeterminateCashflow, setIndeterminateCashflow] = useState(false);
  const [indeterminateCompliance, setIndeterminateCompliance] = useState(false);

  const [checkAllProfitability, setCheckAllProfitability] = useState(
    () => profitabilityOptions.filter((option) => insightsAvailable.includes(option.insightKey)).length === profitabilityOptions.length
  );
  const [checkAllCashflow, setCheckAllCashflow] = useState(false);
  const [checkAllCompliance, setCheckAllCompliance] = useState(false);

  const filterAvailableInsights = (options: reportInsightCheckedList[]): reportInsightCheckedList[] => (
    options.filter((option) => insightsAvailable.indexOf(option.insightKey) > -1)
  );

  const availableProfitabilityOptions = filterAvailableInsights(profitabilityOptions);
  const availableCashflowOptions = filterAvailableInsights(cashflowOptions);
  const availableComplianceOptions = filterAvailableInsights(complianceOptions);

  const onProfitabilityChange = (list: CheckboxValueType[]) => {
    setCheckedListProfitability(
      profitabilityOptions.filter((option) => list.includes(option.title))
    );
    setIndeterminateProfitability(
      !!list.length && list.length < availableProfitabilityOptions.length
    );
    setCheckAllProfitability(list.length === availableProfitabilityOptions.length);
  };

  const onCheckAllChangeProfitability = (e: CheckboxChangeEvent) => {
    const insights = e.target.checked ? availableProfitabilityOptions : [];
    setCheckedListProfitability(insights);
    setIndeterminateProfitability(false);
    setCheckAllProfitability(insights.length > 0);
  };

  const onCashflowChange = (list: CheckboxValueType[]) => {
    setCheckedListCashflow(
      cashflowOptions.filter((option) => list.includes(option.title))
    );
    setIndeterminateCashflow(
      !!list.length && list.length < availableCashflowOptions.length
    );
    setCheckAllCashflow(list.length === availableCashflowOptions.length);
  };

  const onCheckAllChangeCashflow = (e: CheckboxChangeEvent) => {
    const insights = e.target.checked ? availableCashflowOptions : [];
    setCheckedListCashflow(insights);
    setIndeterminateCashflow(false);
    setCheckAllCashflow(insights.length > 0);
  };

  const onComplianceChange = (list: CheckboxValueType[]) => {
    setCheckedListCompliance(
      complianceOptions.filter((option) => list.includes(option.title))
    );
    setIndeterminateCompliance(
      !!list.length && list.length < availableComplianceOptions.length
    );
    setCheckAllCompliance(list.length === availableComplianceOptions.length);
  };

  const onCheckAllChangeCompliance = (e: CheckboxChangeEvent) => {
    const insights = e.target.checked ? availableComplianceOptions : [];
    setCheckedListCompliance(insights);
    setIndeterminateCompliance(false);
    setCheckAllCompliance(insights.length > 0);
  };

  const handlePeriodSelection = (newPeriod: string) => {
    timePeriodStore.setProfitabilityPeriodSelected(newPeriod);
  };

  return (
    <div className='insight-selector'>
      <Checkbox
        className='group-separator insights-tab'
        indeterminate={indeterminateProfitability}
        onChange={onCheckAllChangeProfitability}
        checked={checkAllProfitability}
        disabled={
          !(
            profitabilityOptions.filter((option) => insightsAvailable.includes(option.insightKey)).length > 0
          )
        }
      >
        Review Performance:
      </Checkbox>
      <Checkbox
        className='insights-tab'
        indeterminate={indeterminateProfitability}
        onChange={onCheckAllChangeProfitability}
        checked={checkAllProfitability}
        disabled={
          !(
            profitabilityOptions.filter((option) => insightsAvailable.includes(option.insightKey)).length > 0
          )
        }
      >
        Profitability Review
      </Checkbox>
      <>
        <br />
        <PeriodGranularitySelectComponent
          className='report-period-selection'
          type={InsightType.PROFITABILITY}
        />
        {timePeriodStore.profitabilityPeriodSelected && (
          <Select
            className='report-period-selection'
            defaultValue={timePeriodStore.profitabilityPeriodSelected}
            onChange={handlePeriodSelection}
            size='large'
            options={timePeriodStore.profitabilityPeriods}
            value={timePeriodStore.profitabilityPeriodSelected}
          />
        )}
      </>
      <CheckboxGroup
        className='insight'
        options={profitabilityOptions.map((option) => ({
          value: option.title,
          label:
            option.title
            + (!insightsAvailable.includes(option.insightKey)
              ? ' (not available)'
              : ''),
          disabled: !insightsAvailable.includes(option.insightKey),
        }))}
        value={checkedListProfitability.map((option) => option.title)}
        onChange={onProfitabilityChange}
      />
      <br />
      <Checkbox
        className='group-separator insights-tab'
        indeterminate={(checkAllCashflow && availableComplianceOptions.length > 0 && !checkAllCompliance)
          || (!checkAllCashflow && availableCashflowOptions && checkAllCompliance)
          || (indeterminateCashflow)
          || (indeterminateCompliance)}
        onChange={(e) => {
          onCheckAllChangeCashflow(e);
          onCheckAllChangeCompliance(e);
        }}
        checked={((availableCashflowOptions.length === 0 || checkAllCashflow)
          && (availableComplianceOptions.length === 0 || checkAllCompliance))}
        disabled={
          !(
            cashflowOptions.filter((option) => insightsAvailable.includes(option.insightKey)).length > 0
            || complianceOptions.filter((option) => insightsAvailable.includes(option.insightKey)).length > 0
          )
        }
      >
        Plan for this month
      </Checkbox>
      <Checkbox
        className='insights-tab'
        indeterminate={indeterminateCashflow}
        onChange={onCheckAllChangeCashflow}
        checked={checkAllCashflow}
        disabled={
          !(
            cashflowOptions.filter((option) => insightsAvailable.includes(option.insightKey)).length > 0
          )
        }
      >
        Cash Flow Management
      </Checkbox>
      <CheckboxGroup
        className='insight'
        options={cashflowOptions.map((option) => ({
          value: option.title,
          label:
            option.title
            + (!insightsAvailable.includes(option.insightKey)
              ? ' (not available)'
              : ''),
          disabled: !insightsAvailable.includes(option.insightKey),
        }))}
        value={checkedListCashflow.map((option) => option.title)}
        onChange={onCashflowChange}
      />
      <br />
      <Checkbox
        className='insights-tab'
        indeterminate={indeterminateCompliance}
        onChange={onCheckAllChangeCompliance}
        checked={checkAllCompliance}
        disabled={
          !(
            complianceOptions.filter((option) => insightsAvailable.includes(option.insightKey)).length > 0
          )
        }
      >
        Tax Bill Forecasts
      </Checkbox>
      <CheckboxGroup
        className='insight'
        options={complianceOptions.map((option) => ({
          value: option.title,
          label:
            option.title
            + (!insightsAvailable.includes(option.insightKey)
              ? ' (not available)'
              : ''),
          disabled: !insightsAvailable.includes(option.insightKey),
        }))}
        value={checkedListCompliance.map((option) => option.title)}
        onChange={onComplianceChange}
      />
    </div>
  );
};

export default InsightSelector;
