import React from 'react';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { PeriodCloseTypes } from '@aider/constants-library';

const ChecklistTableTooltip = ({ tooltipData } : { tooltipData: PeriodCloseTypes.PeriodCloseTableCellTooltip[] }) => {
  if (!tooltipData) return null;
  const title = (
    <div className='aider-tooltip period-close-cell-tooltip'>
      {tooltipData?.map((item) => {
        switch (item.type) {
          case 'heading':
            return (
              <h4 key={item.value}>{item.value}</h4>
            );
          case 'link':
            return (
              <a key={item.value} href={item.link} target='_blank' rel='noreferrer'>{item.value} <RightOutlined /></a>
            );
          case 'text':
          default:
            return (
              <p key={item.value}>{item.value}</p>
            );
        }
      })}
    </div>
  );
  return (
    <Tooltip
      title={title}
      placement='bottom'
    >
      <InfoCircleOutlined className='checklist-table-tooltip-icon' />
    </Tooltip>
  );
};

export default ChecklistTableTooltip;
