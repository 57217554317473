import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { State, Text } from '../../../ts/enums/Colors';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';

/**
 * Exclamation alert icon
 * @param props
 * @returns
 */
export const ExclamationAlert = ({
  width = 18,
  height = 18,
  color = Text.white,
  strokeWidth = 1,
  fill = State.danger,
  lineCap = 'round',
  padding = 0.25,
  ...rest
}: IconInterface) => {
  const { min, max } = getPlotExtremes({ width, height, padding });
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      stroke={color}
      strokeWidth={strokeWidth}
      viewBox={`0 0 ${width} ${height}`}
      strokeLinecap={lineCap}
      fill='none'
      {...rest}
    >
      <circle cx={width / 2} cy={height / 2} r={width / 2} fill={fill} />
      <path
        fill={color}
        d={`M ${width / 2} ${max.y - 3 * strokeWidth}
          L ${width / 2 - strokeWidth / 2} ${min.y}
          Q ${width / 2} ${min.y - strokeWidth}, ${
          width / 2 + strokeWidth / 2
        } ${min.y}
          L ${width / 2} ${max.y - 3 * strokeWidth} `}
      />
      <circle
        cx={width / 2}
        cy={max.y - strokeWidth / 2}
        r={strokeWidth / 2}
        fill={color}
      />
    </svg>
  );
};
