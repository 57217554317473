import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import OSPSelector from './OSPSelector';

const LoadingZoneInitial = () => {
  const rootStore = useStore();
  const { localeStore } = rootStore;
  return (
    <>
      <h1>{localeStore.translation('loading-zone.title')}</h1>
      <div className='loadingZone__initial-osp'>
        <OSPSelector initial />
      </div>
    </>
  );
};

export default observer(LoadingZoneInitial);
