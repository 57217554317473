import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Form, Input } from 'antd';
import { ModalWidths } from '../../models/enums/modals';
import AsyncModal from './wrappers/AsyncModal';

const SaveReportAsModal = async (form, previousName, successCallback) => {
  const onOk = () => form
    .validateFields()
    .then((values) => successCallback(values.templateName))
    .catch((error) => {
      console.error('Error saving report as template', error);
    });

  const ModalProps = {
    name: 'Save Report as Template',
    title: 'Save Template as...',
    width: ModalWidths.MEDIUM,
    okText: 'Save as',
    cancelText: 'Cancel',
    onOk,
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      danger: true,
      shape: 'round',
      size: 'large',
      className: 'button-secondary',
    },
    content: (
      <Form form={form}>
        <Form.Item
          name='templateName'
          label='Template Name'
          rules={[{ required: true, message: 'Please enter a template name' }]}
          initialValue={`${previousName} (copy)`}
        >
          <Input />
        </Form.Item>
      </Form>
    ),
  };
  NiceModal.register('SaveReportAsModal', AsyncModal);
  return NiceModal.show('SaveReportAsModal', ModalProps);
};

export default SaveReportAsModal;
