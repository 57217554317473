import { observer } from 'mobx-react';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useStore } from '../stores/Store';
import AddClientModalFollowup from './modals/AddClientModalFollowup';
import AddClientSuccessModal from './modals/AddClientSuccessModal';

const AddClientRootController = () => {
  const rootStore = useStore();

  const navigationInfo = window.performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];

  if (rootStore.connectionsStore.pendingConnections && navigationInfo[0].type === 'back_forward') {
    rootStore.connectionsStore.addError = true;
  }

  const showModal = async () => {
    await rootStore.authenticationStore.updateAuthDetails();
    NiceModal.remove('AddClientModalFollowup');
    AddClientSuccessModal(rootStore);
  };

  /** Fire Modal Show pending data loaded */
  if (
    rootStore.connectionsStore.pendingConnections
    && rootStore.connectionsStore.loading
    && !rootStore.connectionsStore.goingToXero
    && !rootStore.connectionsStore.addError
  ) {
    AddClientModalFollowup({
      onOk: rootStore.connectionsStore.resetAddClient,
      error: rootStore.connectionsStore.addError,
    });
  }

  if (
    rootStore.connectionsStore.pendingConnections
    && rootStore.connectionsStore.loading
    && !rootStore.connectionsStore.goingToXero
    && rootStore.connectionsStore.addError
  ) {
    AddClientModalFollowup({
      onOk: rootStore.connectionsStore.resetAddClient,
      error: rootStore.connectionsStore.addError,
    });
  }

  /** Fire Modal Show once data loaded */
  if (
    rootStore.connectionsStore.pendingConnections
    && rootStore.connectionsStore.newConnectionCount > 0
    && !rootStore.connectionsStore.goingToXero
    && rootStore.authenticationStore.firestoreAuthId
  ) {
    showModal();
  }
  return <></>;
};
export default observer(AddClientRootController);
