import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { message, Upload } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import { useStore, rootStore } from '../../stores/Store';
import { AccountSettings } from '../../models/enums/components';
import Notification from '../Notification';
import PracticeInitialsLogo from '../PracticeInitialsLogo';

const getBase64 = (
  img: RcFile,
  callback: (url: string) => void
) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(
    reader.result as string
  ));
  reader.readAsDataURL(img);
};

const ManageBranding = () => {
  const { practiceStore } = useStore();
  const {
    handleUploadImage,
    practice
  } = practiceStore;
  const [loading, setLoading] = useState<boolean>(false);

  const beforeUpload = async (file: RcFile) => {
    const isPng = file.type === 'image/png';
    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!isPng) {
      Notification({
        type: 'error',
        title: 'You can only upload PNG file',
      });
      message.error('You can only upload PNG file!');
    }

    if (!isLt5M) {
      Notification({
        type: 'error',
        title: 'Image must smaller than 5MB!',
      });
    }

    return isPng && isLt5M;
  };

  const handleUpload = async (
    option: any
  ) => {
    trackMixpanelEvent({
      description: 'Uploading logo',
      rootStore,
    });
    handleUploadImage(option);
  };

  const handleChange: UploadProps['onChange'] = useCallback((
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'error') {
      setLoading(false);
      Notification({
        type: 'error',
        title: 'Logo uploaded failed',
      });
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, async (url) => {
        setLoading(false);
        Notification({
          type: 'success',
          title: 'Logo uploaded and saved',
        });
        practiceStore.brandingLogo = url;
        practiceStore.persistPractice();
      });
    }
  }, []);

  const uploadButton = (
    <div className='upload-button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Upload</div>
    </div>
  );

  return (
    <>
      <header className='sub-content__header'>
        <h2 className='sub-content__header--title wrapper'>{AccountSettings.BRAND}</h2>
      </header>
      <Content className='sub-content__content manageBranding__content scrollbar'>
        <div className='wrapper'>
          <p><b>Firm Logo</b> (optional)</p>
          <Upload
            name='avatar'
            listType='picture-card'
            className='avatar__uploader'
            showUploadList={false}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            customRequest={handleUpload}
          >
            {
              practice.brandingLogo
                ? <img className='practice-logo' src={practice.brandingLogo} alt='avatar' style={{ width: '100%' }} />
                : (
                  <PracticeInitialsLogo />
                )
              }
            {uploadButton}
          </Upload>

          <div className='descriptions'>
            <p>Logo used for the Performance Report cover</p>
            <p>Please select a version that works on a white background</p>
            <p>Recommended image size: 120 x 120 pixels</p>
            <p>Max file size: 5MB</p>
            <p>Required file type: PNG</p>
          </div>
        </div>
      </Content>
    </>
  );
};

export default observer(ManageBranding);
