import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const logoNavSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M4.13594 25.1016C5.51864 25.1016 6.6393 26.2379 6.6393 27.6395C6.6393 29.0413 5.51864 30.1774 4.13594 30.1774C2.75347 30.1774 1.63281 29.0413 1.63281 27.6395C1.63281 26.2379 2.75347 25.1016 4.13594 25.1016Z' fill='#00E0E1' />
    <path fillRule='evenodd' clipRule='evenodd' d='M26.0431 14.2825C26.0343 14.0308 26.0031 13.7803 25.9503 13.5344C25.6034 9.79581 22.5477 6.88382 18.4602 6.88382C14.092 6.88382 10.8485 10.1923 10.8485 14.2758V14.3296C10.8485 18.4131 13.438 21.6745 17.7796 21.6745C19.6224 21.6745 21.4783 20.4 22.0683 19.3091C22.0683 20.2163 22.3469 21.5133 24.0568 21.5133C25.1549 21.5133 26.0453 20.6105 26.0453 19.4973L26.0431 14.2825ZM25.5882 4.91262C30.6866 8.90855 31.6243 16.338 27.6828 21.5065C23.7411 26.6753 16.4129 27.626 11.3147 23.6301L7.88338 24.5619C7.77666 24.5906 7.66442 24.5906 7.55792 24.5619C7.22075 24.4707 7.02013 24.1199 7.11006 23.7779L8.13747 19.887C8.14189 19.8734 8.15514 19.8601 8.15514 19.8465C7.25191 18.132 6.7793 16.2181 6.7793 14.2742C6.77908 7.73994 12.0036 2.44234 18.4491 2.44189C21.0329 2.44122 23.544 3.31034 25.5882 4.91262ZM22.0682 19.2464H22.066H22.0682ZM22.0682 13.9533V19.0179L20.5989 17.5149L20.4023 17.2909C19.8859 17.8222 19.1963 18.1447 18.4624 18.1981C16.2661 18.1981 14.7747 16.368 14.7747 14.2736V14.2198C14.7747 12.1254 16.1336 10.3491 18.4093 10.3491C20.4929 10.3491 21.9246 11.9977 22.0682 13.9533Z' fill='white' />
  </svg>

);

const LogoNavIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={logoNavSvg}
    {...props}
    aria-label='logo'
  />
);

export default LogoNavIcon;
