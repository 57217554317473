import React from 'react';
import { Spin } from 'antd';
import AiderLoader from '../assets/Aider_loader3.gif';

interface LoadingSpinnerProps {
  branded?: boolean,
  centered?: boolean,
  className?: string
}

const LoadingSpinner = ({ branded, centered, className }:LoadingSpinnerProps) => (
  <div className={`loading-spinner ${centered ? 'loading-spinner__centered' : ''} ${className}`}>
    {
        branded ? (
          <img src={AiderLoader} alt='Loading' style={{ width: 100 }} />
        ) : (
          <Spin />
        )
      }
  </div>
);

LoadingSpinner.defaultProps = {
  branded: false,
  centered: false,
  className: '',
};

export default LoadingSpinner;
