import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import StandardModal from './wrappers/StandardModal';
import { AiderLogoIcon } from '../icons';
import type { RootStore } from '../../stores/Store';

const UnsubscribeModal = ({ rootStore, error }: {rootStore: RootStore, error?:boolean}) => {
  const { userStore } = rootStore;
  const modalProps = {
    name: `Email Unsubscribe ${error ? 'Error ' : ''}Modal`,
    className: 'confirmation-modal',
    okText: 'Okay',
    width: ModalWidths.MEDIUM,
    cancelButtonProps: {
      className: 'button-secondary btn-hidden',
      disabled: true,
    },
    afterClose: () => {
      userStore.showUnsubscribeModal = false;
      userStore.requiresUnsubscribe = false;
    },
    content: (
      <section>
        <AiderLogoIcon className='xero-aider-logo__aider' />
        <div className='confirmation-modal__body'>
          <h3>Unsubscribe {error && 'un'}successful</h3>
          {error ? (
            <p>{error}</p>
          ) : (
            <p>You have been unsubscribed from our mailing list.</p>
          )}
        </div>
      </section>
    )
  };

  NiceModal.register('UnsubscribeModal', StandardModal);
  return NiceModal.show('UnsubscribeModal', modalProps);
};

export default UnsubscribeModal;
