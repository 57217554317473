/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../stores/Store';
import Input from '../atoms/Input';
import ButtonLink from '../atoms/ButtonLink';

/**
 * TierPriceConfirmForm Page.
 *
 * This page is used to display the Tier Price Confirmation Page:
 * To make an request to the CS team to upgrade to a Bespoke plan (current Tier is the top tier).
 *
 * @state: user's name, email, phoneNumber (authStore)
 * @action: Cancel (setActiveModalWithClickEvent) ->  Open a Modal to show no clients was added
 * @action: Send Request (setActiveModalWithClickEvent) -> Send Email to CS team, open a new page
 * to show confirmation then (ContactThankYouPage)
 */

const TierPriceConfirmForm: React.FC = () => {
  const { userStore, actionStore } = useStore();
  const navigate = useNavigate();
  // Get user's information: Name, Email, Phone
  const [name, setName] = useState<string>(userStore.username);
  const [emailAddress, setEmailAddress] = useState<string>(userStore.email);
  const [phone, setPhone] = useState<string>(userStore.phoneNumber);
  const [comment, setComment] = useState<string>('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    actionStore
      .sendCustomTierEmailToCS({
        name,
        email: emailAddress,
        phone,
        comment,
      })
      .then(() => navigate('/contact-thankyou'));
  };

  const handleError = (err: string) => {
    Sentry.captureException(err);
  };

  return (
    <form className='tier__confirm__form' onSubmit={handleSubmit}>
      <div className='input__wrapper'>
        <Input
          label='Name'
          value={name}
          type='text'
          onChange={setName}
          onError={handleError}
          isRequired
        />
      </div>

      <div className='input__wrapper'>
        <Input
          label='Email Address'
          type='email'
          value={emailAddress}
          onChange={setEmailAddress}
          onError={handleError}
          isRequired
        />
        <Input
          label='Contact Number'
          type='tel'
          value={phone}
          onChange={setPhone}
          onError={handleError}
          isRequired={false}
        />
      </div>

      <div className='textarea__wrapper'>
        <Input
          label='Comment'
          type='textarea'
          value={comment}
          onChange={setComment}
          onError={handleError}
          isRequired={false}
        />
      </div>

      <div className='input__wrapper'>
        <ButtonLink
          key='button__Email-Send-Request'
          className='blue-button'
          type='submit'
          text='Send Request'
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};

export default TierPriceConfirmForm;
