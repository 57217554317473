import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import StandardModal from './wrappers/StandardModal';
import type ReportTemplateStore from '../../stores/reportTemplateStore';
import ReportEditorSettingsTitle from './content/ReportEditorSettingsTitle';
import ReportBlockSettingsContent from './content/ReportBlockSettingsContent';

const ReportBlockSettingsModal = (
  type: 'chart' | 'table',
  reportTemplateStore: ReportTemplateStore
) => {
  NiceModal.register('ReportBlockSettingsModal', StandardModal);

  const closeModal = () => {
    NiceModal.hide('ReportBlockSettingsModal');
  };

  const afterClose = () => {
    NiceModal.remove('ReportBlockSettingsModal');
    reportTemplateStore.clearEditBlock();
  };

  const ModalProps = {
    name: `report-editor-settings-modal__${type}-settings`,
    title: (<ReportEditorSettingsTitle type={type} />),
    className: 'insight-graph-modal',
    width: ModalWidths.CONTAINER,
    content: <ReportBlockSettingsContent {...{ type, closeModal }} />,
    afterClose,
    footer: null
  };

  return NiceModal.show('ReportBlockSettingsModal', ModalProps);
};

export default ReportBlockSettingsModal;
