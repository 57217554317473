import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import AiderTag from '../AiderTag';

const EmptyResultRow = () => {
  const { dashboardStore } = useStore();

  const generateIndustryList = () => {
    if (dashboardStore?.activeIndustryFilter?.length === 1) {
      return (<AiderTag ghost onClick={() => dashboardStore.removeIndustryFilter(dashboardStore?.activeIndustryFilter?.[0])}>{dashboardStore?.activeIndustryFilter?.[0]}</AiderTag>);
    }
    return dashboardStore?.activeIndustryFilter?.map((industry, index) => {
      const onClick = () => dashboardStore.removeIndustryFilter(industry);
      const isLast = index === dashboardStore?.activeIndustryFilter?.length - 1;
      if (isLast) return (<> OR <AiderTag ghost onClick={onClick}>{industry}</AiderTag></>);
      return (<AiderTag ghost onClick={onClick}>{industry}</AiderTag>);
    });
  };

  const generateTagList = () => {
    if (dashboardStore?.activeTagFilter?.length === 1) {
      return (<AiderTag ghost onClick={() => dashboardStore.removeTagFilter(dashboardStore?.activeTagFilter?.[0])}>{dashboardStore?.activeTagFilter?.[0]}</AiderTag>);
    }
    return dashboardStore?.activeTagFilter?.map((tag, index) => {
      const onClick = () => dashboardStore.removeTagFilter(tag);
      const isLast = index === dashboardStore?.activeTagFilter?.length - 1;
      if (isLast) return (<> AND <AiderTag ghost onClick={onClick}>{tag}</AiderTag></>);
      return (<AiderTag ghost onClick={onClick}>{tag}</AiderTag>);
    });
  };

  return (
    <div className='dashboard-table__body--row dashboard-table__body--row__empty'>
      <header className='dashboard-table__body--row__empty--header'>
        <ExclamationCircleFilled className='dashboard-table__body--row__empty--icon' />
        <h5>No Matches Found</h5>
      </header>
      <div className='dashboard-table__body--row__empty--body'>
        <p>You have no client...</p>
        {dashboardStore?.activeIndustryFilter?.length > 0 && (
          <p className='dashboard-table__body--row__empty--body--list'>
            who is in the <span className='emphasis'>industry</span>{' '}
            {generateIndustryList()}
          </p>
        )}
        {dashboardStore?.activeTagFilter?.length > 0 && (
          <p className='dashboard-table__body--row__empty--body--list'>
            {dashboardStore?.activeIndustryFilter?.length > 0 && <>AND </>}
            who is <span className='emphasis'>custom tagged</span> with{' '}
            {generateTagList()}
          </p>
        )}
        {dashboardStore?.activeSearchString?.length > 0 && (
          <p className='dashboard-table__body--row__empty--body--list'>
            {(dashboardStore?.activeIndustryFilter?.length > 0
              || dashboardStore?.activeTagFilter?.length > 0) && <>AND </>}
            whose <span className='emphasis'>name</span> contains{' '}
            <span className='emphasis'>
              &quot;{dashboardStore?.activeSearchString}&quot;
            </span>
          </p>
        )}
        <p className='emphasis'>
          Try{' '}
          {(dashboardStore?.activeIndustryFilter?.length > 0
            || dashboardStore?.activeTagFilter?.length > 0) && (
            <>removing some of the filters</>
          )}
          {(dashboardStore?.activeIndustryFilter?.length > 0
            || dashboardStore?.activeTagFilter?.length > 0)
            && dashboardStore?.activeSearchString?.length > 0 && <> and/or </>}
          {dashboardStore?.activeSearchString?.length > 0 && (
            <>a different or shorter search keyword</>
          )}{' '}
          to broaden your search.
        </p>
      </div>
    </div>
  );
};

export default observer(EmptyResultRow);
