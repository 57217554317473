import * as Sentry from '@sentry/browser';

/**
 * Automatically handles an error sent by logging it to Sentry
 * and optionally logging it to the console if in development mode.
 * @param err Error object
 * @param transaction Name of the transaction
 * @param operation Name of the operation
 * @param status Status of the operation
 */
function handleError(
  {
    status = 'unknown_error',
    error,
    transaction = 'unknown_transaction',
    sentryTransaction,
    operation = 'unknown_operation',
  }:
  {
  status?: string,
  error: any,
  transaction?: string,
  sentryTransaction?: any,
  operation?: string
}
) {
  const currentTransaction = sentryTransaction || Sentry.startTransaction({
    name: transaction,
  });
  const sentryChild = currentTransaction.startChild({ op: operation });
  sentryChild.setStatus(status);
  sentryChild.finish();
  currentTransaction.finish();
  Sentry.captureException(error);

  if (process.env.NODE_ENV === 'development') {
    console.error(error); // eslint-disable-line no-console
  }
}

export default handleError;
