import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from '../../stores/Store';
import DashboardDataRow from './DashboardDataRow';
import DashboardHeader from './DashboardHeader';
import EmptyResultRow from './EmptyResultRow';
import DashboardPendingRow from './DashboardPendingRow';
import LoadingRow from './LoadingRow';

const DashboardTable = () => {
  const { dashboardStore } = useStore();

  useEffect(() => {
    const handleScroll = async (event) => {
      const { scrollHeight, clientHeight, scrollTop } = event.target;
      const scrollDiff = scrollHeight - scrollTop;
      if (
        (Math.ceil(scrollDiff) === clientHeight
          || Math.floor(scrollDiff) === clientHeight)
        && !dashboardStore.loadingMore
      ) {
        if (dashboardStore.hasMorePages) {
          dashboardStore.activePage += 1;
          dashboardStore.appendDashboard();
        } else if (
          dashboardStore.rows.length < dashboardStore.totalBusinessCount
        ) {
          dashboardStore.appendDashboard();
        }
      }
    };

    const tableContent = document.querySelector('.dashboard-table');
    tableContent.addEventListener('scroll', handleScroll);

    return () => {
      tableContent.removeEventListener('scroll', handleScroll);
    };
  }, [dashboardStore]);

  const rows = [];

  dashboardStore.businesses.forEach((row) => {
    const key = `dashboard-row__${row.businessId}`;
    if (row.loading) {
      rows.push({ order: row.order, row: (<DashboardPendingRow id={row.businessId} key={key} />) });
    } else {
      rows.push({ order: row.order, row: (<DashboardDataRow row={{ id: row.businessId }} key={key} />) });
    }
  });

  if (!dashboardStore.hasMorePages) {
    dashboardStore.loadingBusinesses.forEach((row) => {
      const key = `dashboard-row__${row.businessId}`;
      rows.push({ order: row.order, row: (<DashboardPendingRow id={row.businessId} key={key} />) });
    });
  }

  return (
    <main className='dashboard-table scrollbar'>
      <DashboardHeader />
      <div className='dashboard-table__body'>
        <>
          {(dashboardStore.loadingMore || dashboardStore.loading) && <LoadingRow />}
          {!dashboardStore.loading
          && dashboardStore.rows.length === 0
          && dashboardStore.loadingBusinessesGenerated
          && dashboardStore.loadingBusinesses.size < 1
          && (
          <EmptyResultRow />
          )}
          {rows.sort((a, b) => (a.order > b.order ? 1 : -1)).map((Row) => Row.row)}

        </>

      </div>
    </main>
  );
};

export default observer(DashboardTable);
