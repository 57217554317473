// TODO: Refactor this file to remove all the eslint-disable

import cookie from 'js-cookie';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { refreshTokens } from './backendTokenHelper';
import { getAcquisitionData } from './acquisitionHelper';
import { makeGetRequest, makeHeadRequest, makePostRequest } from './apiHelper';
import handleError from '../../lib/errorHandler';

export const addUserWithXero = async (
  user,
  apiLinks,
  partnerCustomerTokenInRedirectURL,
) => {
  const utm = cookie.get('utm');
  let parsedUtm = {};
  if (utm) {
    try {
      parsedUtm = JSON.parse(utm);
    } catch (e) {
      handleError({ error: e });
    }
  }

  const body = {
    _embedded: {
      user: {
        providerUserId: user.uid,
        acquisition: parsedUtm,
        partnerToken: partnerCustomerTokenInRedirectURL,
      },
    },
  };

  const headers = {
    'Content-Type': 'application/hal+json',
  };

  return makePostRequest(apiLinks.users.href, headers, JSON.stringify(body));
};

/**
 * @deprecated
 */
export const addUser = (
  authResult,
  apiLinks,
  isInvitation,
  partnerCustomerTokenInRedirectURL,
  fields,
  providerType
) => {
  if (authResult && authResult.user && partnerCustomerTokenInRedirectURL) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { acquisition } = getAcquisitionData();

    const body = JSON.stringify({
      _embedded: {
        user: {
          providerUserId: authResult.uid,
          acquisition,
          partnerToken: partnerCustomerTokenInRedirectURL,
        },
      },
    });

    const headers = {
      'Content-Type': 'application/hal+json',
    };

    return makePostRequest(apiLinks.users.href, headers, body);
    // return Promise.reject('Cannot create user from empty auth result.');
  }
  if (authResult && authResult.user) {
    let givenName = null;
    let familyName = null;
    let gender = null;
    let providerId = null;
    let idToken = null;

    const authDomain = authResult.user.authDomain || authResult.user.w;

    if (
      authResult.user.providerData
      && authResult.user.providerData.length > 0
    ) {
      if (authResult.user.providerData[0].providerId) {
        providerId = authResult.user.providerData[0].providerId;
      }
    }

    if (authResult.credential) {
      if (authResult.credential.providerId) {
        providerId = providerId || authResult.credential.providerId;
      }

      if (authResult.credential.idToken) {
        idToken = authResult.credential.idToken;
      }
    }

    if (authResult.additionalUserInfo) {
      if (authResult.additionalUserInfo.providerId) {
        providerId = providerId || authResult.additionalUserInfo.providerId;
      }

      if (authResult.additionalUserInfo.profile) {
        if (authResult.additionalUserInfo.profile.given_name) {
          givenName = authResult.additionalUserInfo.profile.given_name;
        }

        if (authResult.additionalUserInfo.profile.first_name) {
          givenName = authResult.additionalUserInfo.profile.first_name;
        }

        if (authResult.additionalUserInfo.profile.family_name) {
          familyName = authResult.additionalUserInfo.profile.family_name;
        }

        if (authResult.additionalUserInfo.profile.last_name) {
          familyName = authResult.additionalUserInfo.profile.last_name;
        }

        if (authResult.additionalUserInfo.profile.gender) {
          gender = authResult.additionalUserInfo.profile.gender;
        }
      }
    }

    let { phoneNumber } = authResult.user;
    if (authResult.emailSignUp) {
      console.log('email signup');
      if (
        authResult.emailSignUp.firstName
        && authResult.emailSignUp.lastName
        && authResult.emailSignUp.phoneNumber
      ) {
        console.log('valid');

        givenName = authResult.emailSignUp.firstName;
        familyName = authResult.emailSignUp.lastName;
        phoneNumber = authResult.emailSignUp.phoneNumber;
      } else {
        console.log('empty');
        // eslint-disable-next-line  prefer-promise-reject-errors
        return Promise.reject('Cannot create user from empty auth result.');
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const displayName =
      authResult.user.displayName
      || [givenName, familyName]
        .filter((name) => name)
        .join(' ')
        .trim()
      || null;

    const { acquisition, partnerToken } = getAcquisitionData();
    console.log('other');

    const body = JSON.stringify({
      _embedded: {
        user: {
          providerUserId: authResult.user.uid,
          givenName: fields ? fields.firstName : givenName,
          familyName: fields ? fields.lastName : familyName,
          displayName: fields
            ? `${fields.firstName} ${fields.lastName}`
            : `${givenName} ${familyName}`,
          photoUrl: authResult.user.photoURL,
          email: authResult.user.email.toLowerCase(),
          emailVerified: authResult.user.emailVerified,
          phoneNumber: phoneNumber || fields.phone,
          gender,
          idToken,
          providerId: providerType === 'password' ? providerType : providerId,
          authDomain,
          extras: authResult.additionalUserInfo || null,
          acquisition,
          partnerToken: isInvitation ? null : null,
          invitationToken: isInvitation ? partnerToken : null,
        },
      },
    });

    const headers = {
      'Content-Type': 'application/hal+json',
    };

    return makePostRequest(apiLinks.users.href, headers, body);
  }
  // eslint-disable-next-line  prefer-promise-reject-errors
  return Promise.reject('Cannot create user from empty auth result.');
};

export const addUserWithGoogle = (
  user,
  apiLinks,
) => {
  if (user) {
    let givenName = null; // eslint-disable-line no-unused-vars
    let familyName = null; // eslint-disable-line no-unused-vars
    let gender = null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    let providerId = null;
    let idToken = null;

    const authDomain = user.authDomain || user.w;
    const displayNameSplit = {
      givenName: user.displayName.split(' ')[0],
      familyName: user.displayName.split(' ')[1],
    };

    if (user.providerData && user.providerData.length > 0) {
      if (user.providerData[0].providerId) {
        providerId = user.providerData[0].providerId;
      }
    }

    if (user.credential) {
      if (user.credential.providerId) {
        providerId = providerId || user.credential.providerId;
      }

      if (user.credential.idToken) {
        idToken = user.credential.idToken;
      }
    }

    if (user.additionalUserInfo) {
      if (user.additionalUserInfo.providerId) {
        providerId = providerId || user.additionalUserInfo.providerId;
      }

      if (user.additionalUserInfo.profile) {
        if (user.additionalUserInfo.profile.given_name) {
          givenName = user.additionalUserInfo.profile.given_name;
        }

        if (user.additionalUserInfo.profile.first_name) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          givenName = user.additionalUserInfo.profile.first_name;
        }

        if (user.additionalUserInfo.profile.family_name) {
          familyName = user.additionalUserInfo.profile.family_name;
        }

        if (user.additionalUserInfo.profile.last_name) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          familyName = user.additionalUserInfo.profile.last_name;
        }

        if (user.additionalUserInfo.profile.gender) {
          gender = user.additionalUserInfo.profile.gender;
        }
      }
    }

    let parsedUtm = {};
    const utm = cookie.get('utm');
    if (utm) {
      parsedUtm = JSON.parse(utm);
    }

    const body = JSON.stringify({
      _embedded: {
        user: {
          givenName: displayNameSplit.givenName,
          familyName: displayNameSplit.familyName,
          displayName: user.displayName,
          photoUrl: user.photoURL,
          email: user.email.toLowerCase(),
          emailVerified: user.emailVerified,
          phoneNumber: user.phoneNumber || null,
          providerUserId: user.uid,
          gender,
          idToken,
          providerId: user.providerData[0].providerId,
          authDomain,
          extras: null,
          acquisition: parsedUtm,
          partnerToken: null,
          invitationToken: null,
        },
      },
    });

    const headers = {
      'Content-Type': 'application/hal+json',
    };

    return makePostRequest(apiLinks.users.href, headers, body);
  }
  // eslint-disable-next-line  prefer-promise-reject-errors
  return Promise.reject('Cannot create user from empty auth result.');
};

export const getUser = async (apiLinks, userId, updatedTokens) => {
  const backendTokens = await refreshTokens();
  let headers = {
    Authorization: `Bearer ${backendTokens.access_token}`,
  };

  if (updatedTokens) {
    headers = {
      Authorization: `Bearer ${updatedTokens.access_token}`,
    };
  }
  try {
    const userResponse = await makeGetRequest(
      `${apiLinks.users.href}/${userId}`,
      headers
    );
    const { userDetails } = userResponse._embedded;
    return userDetails;
  } catch (e) {
    console.log('retry');
    const verboseBackendTokens = await refreshTokens();
    const requestHeaders = {
      Authorization: `Bearer ${verboseBackendTokens.access_token}`,
    };
    const userResponse = await makeGetRequest(
      `${apiLinks.users.href}/${userId}`,
      requestHeaders
    );
    const { userDetails } = userResponse._embedded;
    return userDetails;
  }
};

export const checkUserExists = async (apiLinks, userId) => makeHeadRequest(`${apiLinks.users.href}/${userId}`)
  .then(() => true)
  .catch(() => false);
