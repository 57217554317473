import React from 'react';
import { Form, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { PracticeTypes, ReportEnums } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import { renderToString } from 'react-dom/server';
import { useStore } from '../../../stores/Store';
import WysiwygEditor from '../WysiwygEditor';
import AiderAlert from '../../AiderAlert';

const TextComponent = ({
  className,
  block,
}: {
  className: string,
  block: PracticeTypes.ReportBlock,
}) => {
  const { insightStore, reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;

  const { insightsLoading } = insightStore;
  const { fetchingVariables } = reportTemplateStore;

  const customEntityTransform = (entity, text) => {
    const variableKey = text.replace('#{', '').replace('}', '');
    const tempVar = reportTemplateStore.fallbackFormattedVariables[variableKey];
    const unsupported = !tempVar || tempVar?.toLowerCase().includes('data not available');

    if (!entity) {
      return null;
    }

    if (entity.type === 'IMAGE'
      || ReportEnums.ReportVariables?.[variableKey] === ReportEnums.VariableFormattingTypes.IMAGE
    ) {
      return `<img src="${text}" alt="${entity.data.alt}" />`;
    }

    if (entity.type === 'TREND') {
      if (unsupported) {
        return renderToString(<AiderAlert type='warning' title={`${tempVar || 'Trend Data not available for selected period'}`} />);
      }
      return `<a class="wysiwyg-trend" data-trend data-value="${entity.data.value}">${text}</a>`;
    }

    return null;
  };

  const returnAppropriateComponent = () => {
    const variablePattern = /[#]{([^}]+)}/g;

    if (isEditing) {
      if (fetchingVariables || insightsLoading) {
        return <Skeleton active title={false} paragraph={{ rows: 2 }} />;
      }

      return (
        <WysiwygEditor
          blockType={block.type}
          content={block.content}
          variables={reportTemplateStore?.flatSortedTemplateVariables}
          onChange={(content) => { // eslint-disable-line @typescript-eslint/no-unused-vars
            // This does nothing other than run the action which allows
            // the form to be updated with the new content. It requires
            // the new value to be passed in, but we don't need to do anything with it.
          }}
        />
      );
    }

    // we have a block with content
    if (block.content && (block.content.blocks.length > 1 || block.content.blocks[0].text !== '')) {
      if (!insightsLoading && !fetchingVariables) {
        const notHaveFallbackVariables = Object.keys(reportTemplateStore.fallbackFormattedVariables).length === 0;
        // This check was giving undefined formatted values as making this check invalid. Added a nil check in formatting function instead of only checking null.
        const allSortedVariablesInFallbackVariables = Object.values(reportTemplateStore.flatSortedTemplateVariables)
          .every((v) => reportTemplateStore.fallbackFormattedVariables?.[v?.value?.replace('{', '').replace('}', '')]);

        if (notHaveFallbackVariables || !allSortedVariablesInFallbackVariables) {
          return <Skeleton active title={false} paragraph={{ rows: 2 }} />;
        }

        return (
          <Markdown className={`${className}--${block.type}--content`}>
            {_.template(draftToHtml(block.content, null, null, customEntityTransform), { interpolate: variablePattern })(reportTemplateStore.fallbackFormattedVariables)}
          </Markdown>
        );
      }
      return <Skeleton active title={false} paragraph={{ rows: 2 }} />;
    }

    return (
      <AiderAlert
        title={`Empty ${block.type === 'header' ? 'title' : 'text'} block`}
      />
    );
  };

  return (
    <Form.Item
      className={`${className} ${className}--${isEditing ? 'editing' : 'read-only'} ${className}--${block.type}`}
      name={block.id}
      label={null}
      initialValue={block.content}
    >
      {returnAppropriateComponent()}
    </Form.Item>
  );
};

export default observer(TextComponent);
