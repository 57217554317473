import React from 'react';
import type { ReactElement } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import '../styles/index.scss';

import { observer } from 'mobx-react';
import NavBar from '../components/NavBar';
import TrialBanner from '../components/TrialBanner';
import GlobalLoader from '../components/GlobalLoader';
import { useStore } from '../stores/Store';

/**
 * MainLayout
 * The base layout for the app. It contains the main navigation and the main content area.
 * @returns {ReactNode}
 */
const MainLayout = ({ hasSubLayout }:{hasSubLayout?: boolean}): ReactElement => {
  const { loadingStore } = useStore();
  return (
    <Layout className='main'>
      {!loadingStore.isLoading('auth') && (
        <div className='main__nav'>
          <NavBar />
        </div>
      )}
      <div className='main__content'>
        <TrialBanner />
        {!hasSubLayout && <GlobalLoader />}
        <Outlet />
      </div>
    </Layout>
  );
};

MainLayout.defaultProps = {
  hasSubLayout: false,
};

export default observer(MainLayout);
