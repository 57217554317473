import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const iconSvg = () => (
  <svg
    width='1em'
    viewBox='0 0 28 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='1' y='1.95215' width='26' height='26' rx='5' fill='white' />
    <rect x='1' y='1.95215' width='26' height='26' rx='5' stroke='currentColor' strokeWidth='2' />
  </svg>
);

const ChecklistStatusIconTodo = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={iconSvg}
    {...props}
    aria-label='Checklist status: To do'
  />
);

export default ChecklistStatusIconTodo;
