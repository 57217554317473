import React from 'react';
import BarChart from '../molecules/BarChart';
import HybridChart from '../molecules/HybridChart';
import LineChart from '../molecules/LineChart';
import SGBarChart from '../molecules/SGBarChart';

const renderChart = (insightData, index, hoverIndex, chartId) => {
  switch (insightData.graph.type) {
    case 'lineChart':
      return (
        <LineChart
          key={`${chartId}-lineChart`}
          insightData={insightData}
          index={index}
          hoverIndex={hoverIndex}
          chartId={chartId}
        />
      );
    case 'stackedGroupedBarChart':
      return (
        <SGBarChart
          key={`${chartId}-stackedGroupedBarChart`}
          insightData={insightData}
          index={index}
          hoverIndex={hoverIndex}
          chartId={chartId}
        />
      );
    case 'barChart':
      return (
        <BarChart
          key={`${chartId}-barChart`}
          insightData={insightData}
          index={index}
          hoverIndex={hoverIndex}
          chartId={chartId}
        />
      );
    default:
      return (
        <HybridChart
          key={`${chartId}-hybridChart`}
          insightData={insightData}
          index={index}
          hoverIndex={hoverIndex}
          chartId={chartId}
        />
      );
  }
};

const InsightChart = ({ chartId = null, className = 'insightContainer', insightData, index, hoverIndex }) => {
  try {
    if (!insightData?.graph) {
      return null;
    }
    return (
      <div
        className={className}
        id={`${index}-insightContainer`}
        key={chartId || index}
      >
        {renderChart(insightData, index, hoverIndex, chartId)}
      </div>
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error with chart:', e);
    return null;
  }
};

export default InsightChart;
