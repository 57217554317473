import React from 'react';
import { observer } from 'mobx-react';
import ExecutiveSummary from './ExecutiveSummary';
import ActionPoints from './ActionPoints';
import InsightSummary from './InsightSummary';
import { useStore } from '../../../stores/Store';

interface ReportContentProps {
  insightsAvailable: any[];
}

const ReportContent: React.FC<ReportContentProps> = ({
  insightsAvailable,
}) => {
  const rootStore = useStore();
  const { businessStore, insightStore, reportDownloadStore, timePeriodStore } = rootStore;

  const templateData = insightStore?.insightData?.find(
    (insight) => insight?.summaries?.[0]?.templateData?.year
        && insight?.summaries?.[0]?.templateData?.month
  )?.summaries?.[0]?.templateData;

  const { month, year } = insightsAvailable.length > 0 && templateData
    ? templateData
    : { month: null, year: null };

  const reportPeriodElement = () => {
    if (
      timePeriodStore.profitabilityPeriodSelected
    ) {
      return (
        <h2>{timePeriodStore.profitabilityPeriodSelected} and Year-To-Date</h2>
      );
    }

    if (month !== undefined) {
      return (
        <h2>
          {month} {year} and Year-To-Date
        </h2>
      );
    }

    return <></>;
  };

  return (
    <div className='report-content scrollbar'>
      <h2>{businessStore.selectedBusinessData.name}</h2>
      {reportPeriodElement()}
      <h1 className='report-content-heading1'>Performance Report</h1>
      <div className='page-break' />
      <hr />
      <ExecutiveSummary />
      <hr />
      <ActionPoints />
      <div className='page-break' />
      {reportDownloadStore.mappedInsights.length > 0 ? (reportDownloadStore.mappedInsights.map(
        (insight, index) => (
          <InsightSummary
            key={insight.insightKey}
            insight={insight}
            index={index}
          />
        )
      )) : null}
    </div>
  );
};

export default observer(ReportContent);
