import cookie from 'js-cookie';
import * as Sentry from '@sentry/browser';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { ApiConstants } from '@aider/constants-library';
import { LIMIT_EXCEEDED } from '../../entities/types';
import BusinessService from '../../services/businessService';
import { rootStore } from '../../stores/Store';
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
} from './apiHelper';

export const signUpUsingGoogle = (firebase) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  firebase.auth().signInWithRedirect(provider);
};

/**
 * @deprecated
 */
export const signUpUsingFacebook = (firebase) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.setCustomParameters({ auth_type: 'https' });
  firebase.auth().signInWithRedirect(provider);
};

/**
 * @param {*} apiLinks
 * @returns
 */
export const signUpUsingXero = async () => {
  const redirectUrl = process.env.REACT_APP_SSO_REDIRECT_URL;
  const serviceEndpoint = `${ApiConstants.apiEndpointsBase.connectionService}/sso/osp/{ospKey}/app/{appKey}`;
  const url = `${serviceEndpoint.replace(
    '{ospKey}',
    'xero'
  ).replace('{appKey}', 'accounting')}?redirectUrl=${encodeURIComponent(
    redirectUrl
  )}`;
  const response = await makePostRequest(url);
  const resultUrl = response._embedded.sso._links.redirect.href;
  window.location.href = resultUrl;
};

export const createConnectionWithBackend = async (
  access_token,
  practiceId
) => {
  const url = `${ApiConstants.apiEndpointsBase.connectionModuleV2}/businesses/${practiceId}/connections/osp/xero/app/accounting?connectionType=managed&redirectUrl=${encodeURIComponent(
    process.env.REACT_APP_SSO_REDIRECT_URL
  )}`;
  const response = await makePostRequest(url, {
    Authorization: `Bearer ${access_token}`,
  }).catch((e) => {
    console.log('create connection error:', e);
    return e;
  });
  try {
    if (response) {
      rootStore.authStore.setBulkConnectionsStatus(true);
      rootStore.authStore.setAuthDetail(response.authDocId);
      const resultUrl = response._links.redirect.href;
      return (window.location.href = resultUrl);
    }
    return response;
  } catch (e) {
    return response;
  }
};

export const reconnectConnection = async (
  apiLinks,
  access_tokens,
  businessId
) => {
  const { access_token } = await BusinessService.refreshPracticeCredentials();
  console.log('.rc');
  cookie.set('skipCreation', 'true', { secure: true });
  const url = `${ApiConstants.apiEndpointsBase.connectionModuleV2
  }/businesses/${rootStore.businessStore.practiceId.id
  }/connections/osp/xero/app/accounting?connectionType=managed&managedFor=${businessId}&redirectUrl=${encodeURIComponent(
    process.env.REACT_APP_SSO_REDIRECT_URL
  )}`;
  const response = await makePostRequest(url, {
    Authorization: `Bearer ${access_token}`,
  }).catch((e) => {
    console.log('create connection error:', e);
    if (e.error === LIMIT_EXCEEDED) {
      rootStore.businessStore.initiateSubscription().then((canInitiate) => {
        if (canInitiate) {
          return rootStore.authStore.hasExceededQuota(true);
        }
      });
    }
  });
  try {
    rootStore.authStore.setBulkConnectionsStatus(true);
    rootStore.authStore.setReconnectionStatus(
      rootStore.businessStore.selectedBusinessName
    );
    rootStore.authStore.setAuthDetail(response.authDocId);
    const resultUrl = response._links.redirect.href;
    return (window.location.href = resultUrl);
  } catch (e) {
    console.log('no response', response);
  }
};
export const deleteConnection = async (tokens, disconnectionUrl) => {
  const urlRedux = `${ApiConstants.apiEndpointsBase.connectionModule}/businesses/${rootStore.businessStore.practiceId.id}/connections/${rootStore.businessStore.xeroConnectionId}`;
  const response = await makeDeleteRequest(urlRedux, {
    Authorization: `Bearer ${tokens.access_token}`,
  }).catch(async (e) => { });
  try {
    return response;
  } catch (e) {
    console.log('no response', response);
  }
};

export const getConnections = async (access_token, businessId) => {
  const url = `${ApiConstants.apiEndpointsBase.connectionModule}/businesses/${businessId}/connections`;
  const sentryTransaction = Sentry.startTransaction({
    name: 'Get Business Connections',
  });
  const span = sentryTransaction.startChild({
    op: 'makeGETRequest',
    data: { url },
  });
  const response = await makeGetRequest(url, {
    Authorization: `Bearer ${access_token}`,
  }).catch((e) => {
    console.log('check connection err', e);
  });
  span.setStatus('ok');
  span.finish();
  sentryTransaction.finish();
  return response;
};
export const checkConnectionConfiguration = async (
  access_token,
  connectionId
) => {
  const url = `${ApiConstants.apiEndpointsBase.connectionModule
  }/connections/${encodeURIComponent(connectionId)}/configuration`;
  const response = await makeGetRequest(url, {
    Authorization: `Bearer ${access_token}`,
  }).catch((e) => {
    console.log('check connection err', e);
  });
  return response;
};

/**
 * @deprecated
 */
export const signUpUsingEmail = (firebase, fields = {}) => {
  const { firstName, email, password, phoneNumber } = fields;

  if (!firstName || !email || !password) {
    return Promise.reject('First name, email and password fields are required');
  }

  if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
    return Promise.reject('Invalid phone number');
  }

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .catch((error) => catchCreateUser(firebase, fields, error));
};

/**
 * @dprecated
 */
const catchCreateUser = (firebase, fields, error) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      const { email, password } = fields;
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(catchSignInUser);
    case 'auth/weak-password':
      return Promise.reject('Weak password');
    case 'auth/invalid-email':
      return Promise.reject('Invalid email');
    case 'auth/operation-not-allowed':
      return Promise.reject('Oops, email sign up not enabled');
    default:
      return Promise.reject(error);
  }
};

/**
 * @deprecated
 */
const catchSignInUser = (error) => {
  switch (error.code) {
    case 'auth/user-disabled':
      return Promise.reject('Oops, your account has been disabled');
    case 'auth/invalid-email':
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return Promise.reject('Invalid email or password');
    default:
      return Promise.reject(error);
  }
};
