import { observer } from 'mobx-react';
import React from 'react';
import { CheckOutlined, DownloadOutlined, RightOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ClientManagementEnums } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import LoadingZoneSteps from './LoadingZoneSteps';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import { useStore } from '../../stores/Store';
import AiderAlert from '../AiderAlert';
import { Routers } from '../../models/enums/utils';
import ClientListDisplay from './ClientListDisplay';
import { ClientManagementIcon, NonSalesTaxIcon, SalesTaxIcon } from '../icons';
import { handleTierChange } from '../../lib/componentHelpers/activateClientTierModalHelper';
import handleError from '../../lib/errorHandler';
import OSPSelector from './OSPSelector';

const LoadingZoneDetail = ({ initial } : {initial?: boolean}) => {
  const rootStore = useStore();
  const { businessesStore, clientManagementStore, localeStore, practiceStore } = rootStore;
  const [activateLoading, setActivateLoading] = React.useState(false);
  const [unsupportedLoading, setUnsupportedLoading] = React.useState(false);
  const [ospVisible, setOspVisible] = React.useState(false);
  const navigate = useNavigate();

  const supportedTitle = businessesStore.supportedBusinesses.size === 1
    ? 'loading-zone.supported-businesses.title.singular'
    : 'loading-zone.supported-businesses.title.plural';

  const unsupportedTitle = businessesStore.unsupportedBusinesses.size === 1
    ? 'loading-zone.unsupported-businesses.title.singular'
    : 'loading-zone.unsupported-businesses.title.plural';

  const activeSupportedCount = Array.from(businessesStore.supportedBusinesses.values())
    .reduce((acc, curr) => (curr?.aipStatus === ClientManagementEnums.BusinessActivationStatus.activated ? acc + 1 : acc), 0);
  const activeUnsupportedCount = Array.from(businessesStore.unsupportedBusinesses.values())
    .reduce((acc, curr) => (curr?.aipStatus === ClientManagementEnums.BusinessActivationStatus.activated ? acc + 1 : acc), 0);

  const xeroCount = businessesStore.ospClientCounts[ClientManagementEnums.OSPKeys.xero];
  const intuitCount = businessesStore.ospClientCounts[ClientManagementEnums.OSPKeys.intuit];
  const combinationCount = businessesStore.ospClientCounts.combination;

  return (
    <>
      <h1>{localeStore.translation('loading-zone.title')}</h1>

      <LoadingZoneSteps activeStep={2} />
      <h2 className='loadingZone__imported'>
        <DownloadOutlined />
        <span className='strong'>{localeStore.translation('loading-zone.imported.count', { count: businessesStore.clientBusinesses.size })}</span>
        <span>-</span>
        <span>
          {xeroCount > 0 && (
            <span>{localeStore.translation('loading-zone.imported.xero', { count: xeroCount })}</span>
          )}
          {xeroCount > 0 && intuitCount > 0 && (
            ', '
          )}
          {intuitCount > 0 && (
            <span>{localeStore.translation('loading-zone.imported.intuit', { count: intuitCount })}</span>
          )}
          {(xeroCount > 0 || intuitCount > 0) && combinationCount > 0 && (', ')}
          {combinationCount > 0 && (
            <span>{localeStore.translation('loading-zone.imported.combo', { count: combinationCount })}</span>
          )}
        </span>
      </h2>
      {!initial && !ospVisible && (
        <ButtonPrimary
          className='loadingZone__add-business'
          onClick={() => setOspVisible(true)}
          icon={<PlusCircleOutlined />}
          mixpanelEvent='Client Management - Show Client Import Buttons'
        >
          {localeStore.translation('loading-zone.add-business')}
        </ButtonPrimary>
      )}
      {!initial && ospVisible && (
        <ButtonSecondary
          className='loadingZone__add-business'
          onClick={() => setOspVisible(false)}
          icon={<MinusCircleOutlined />}
          mixpanelEvent='Client Management - Hide Client Import Buttons'
        >
          {localeStore.translation('loading-zone.add-business-cancel')}
        </ButtonSecondary>
      )}
      {(ospVisible || initial) && (
        <div className='loadingZone__import-more'>
          <span className='loadingZone__import-more--label'>{localeStore.translation('loading-zone.import-more')}</span>
          <div className='loadingZone__import-more__action-column'>
            <OSPSelector />
            <p>{localeStore.translation('loading-zone.disclaimer')}</p>
          </div>
        </div>
      )}

      <section className='loadingZone__clients'>
        {!practiceStore.isUSPractice && (
          <AiderAlert
            title={localeStore.translation(supportedTitle, { count: businessesStore.supportedBusinesses.size })}
            type='info'
            icon={<SalesTaxIcon />}
            ghost
            showIcon
            largeIcon
          >
            {businessesStore.supportedBusinesses.size > 0
              && (
                <p className='loadingZone__clients--activated'>
                  {localeStore.translation('loading-zone.supported-businesses.activated', { count: activeSupportedCount })}
                </p>
              )}
            {activeSupportedCount !== businessesStore.supportedBusinesses.size && !practiceStore.isGlobalPractice && (
              <Markdown>
                {localeStore.translation('loading-zone.supported-businesses.description')}
              </Markdown>
            )}

            <ClientListDisplay
              clientList={businessesStore.supportedBusinesses}
              label={localeStore.translation('loading-zone.supported-businesses.list')}
            />
            {activeSupportedCount !== businessesStore.supportedBusinesses.size && (
              <>
                <ButtonPrimary
                  loading={activateLoading}
                  disabled={activateLoading}
                  icon={<CheckOutlined />}
                  onClick={async () => {
                    await setActivateLoading(true);
                    trackMixpanelEvent({ description: 'Loading Zone: Activate all supported clients button clicked', rootStore });
                    try {
                      const { subscription } = await businessesStore.activateBulkClients();
                      handleTierChange(subscription, rootStore.practiceStore.subscription.currency, rootStore.practiceStore.countryCode);
                    } catch (error) {
                      handleError({ error, status: 'unknown_error', transaction: 'Activate Clients', operation: 'handleClick' });
                    }
                    await setActivateLoading(false);
                  }}
                >{localeStore.translation('loading-zone.supported-businesses.activate-button')}
                </ButtonPrimary>
                <ButtonPrimary
                  className='loadingZone__clients--customize'
                  type='link'
                  ghost
                  icon={<RightOutlined />}
                  size='small'
                  onClick={() => {
                    trackMixpanelEvent({ description: 'Loading Zone: Customize Selection button clicked', rootStore });
                    clientManagementStore.getClientManagementDashboard();
                    navigate(Routers.ACTIVATE_CLIENTS);
                  }}
                >{localeStore.translation('loading-zone.supported-businesses.customize')}
                </ButtonPrimary>
              </>
            )}
          </AiderAlert>
        )}
        <AiderAlert
          title={localeStore.translation(unsupportedTitle, { count: businessesStore.unsupportedBusinesses.size })}
          type='info'
          icon={<NonSalesTaxIcon />}
          ghost
          showIcon
          largeIcon
          theme='secondary'
        >
          {businessesStore.unsupportedBusinesses.size > 0 && (
            <p className='loadingZone__clients--activated'>
              {localeStore.translation('loading-zone.unsupported-businesses.activated', { count: activeUnsupportedCount })}
            </p>
          )}
          {activeUnsupportedCount !== businessesStore.unsupportedBusinesses.size && !practiceStore.isGlobalPractice && (
            <Markdown>
              {localeStore.translation('loading-zone.unsupported-businesses.description')}
            </Markdown>
          )}

          <ClientListDisplay
            clientList={businessesStore.unsupportedBusinesses}
            label={localeStore.translation('loading-zone.unsupported-businesses.list')}
          />
          {activeUnsupportedCount !== businessesStore.unsupportedBusinesses.size && (
            <>
              <ButtonPrimary
                loading={unsupportedLoading}
                disabled={unsupportedLoading}
                icon={<CheckOutlined />}
                onClick={async () => {
                  setUnsupportedLoading(true);
                  trackMixpanelEvent({ description: 'Loading Zone: Activate all unsupported clients button clicked', rootStore });
                  businessesStore.activateBulkClients(ClientManagementEnums.PlatformSupportStatus.notSupported)
                    .then(({ subscription }) => {
                      handleTierChange(subscription, practiceStore.subscription.currency, practiceStore.countryCode);
                    })
                    .catch((error) => {
                      handleError({ error, status: 'unknown_error', transaction: 'Activate Clients', operation: 'handleClick' });
                    })
                    .finally(() => {
                      setUnsupportedLoading(false);
                    });
                }}
              >{localeStore.translation('loading-zone.unsupported-businesses.activate-button')}
              </ButtonPrimary>
              <ButtonPrimary
                className='loadingZone__clients--customize'
                type='link'
                ghost
                icon={<RightOutlined />}
                size='small'
                onClick={() => {
                  trackMixpanelEvent({ description: 'Loading Zone: Customize Selection button clicked', rootStore });
                  clientManagementStore.getClientManagementDashboard();
                  navigate(Routers.ACTIVATE_CLIENTS);
                }}
              >{localeStore.translation('loading-zone.unsupported-businesses.customize')}
              </ButtonPrimary>
            </>
          )}

        </AiderAlert>
      </section>
      {((!practiceStore.isUSPractice && activeSupportedCount === businessesStore.supportedBusinesses.size)
        || activeUnsupportedCount === businessesStore.unsupportedBusinesses.size)
      && (
        <ButtonPrimary
          className='loadingZone__manage-clients'
          onClick={() => {
            trackMixpanelEvent({ description: 'Loading Zone: Manage Clients button clicked', rootStore });
            clientManagementStore.getClientManagementDashboard();
            navigate(Routers.ACTIVATE_CLIENTS);
          }}
          icon={<ClientManagementIcon />}
        >{localeStore.translation('loading-zone.manage-button')}
        </ButtonPrimary>
      )}
    </>
  );
};

LoadingZoneDetail.defaultProps = {
  initial: false,
};

export default observer(LoadingZoneDetail);
