import React from 'react';
import { PeriodCloseTypes } from '@aider/constants-library';
import CommentButton from '../../../components/comments/CommentButton';
import PromptRuleButton from '../../../components/rule/PromptRuleButton';
import RuleCheckListOptions from './RulesCheckListOptions';
import CheckListOptions from './CheckListOptions';
import { useStore } from '../../../stores/Store';

const CheckListActions = ({
  checklistIdentifier,
  selectedPeriod,
  title,
}: {
  checklistIdentifier: PeriodCloseTypes.ChecklistItem['checklistIdentifier'],
  selectedPeriod: string,
  title: string,
}) => {
  const { commentsStore, checklistStore } = useStore();
  const activeChecklist = checklistStore?.currentChecklistGroups?.tableGroups?.find((item) => item.tableGroupPeriod.periodName === selectedPeriod);
  const periodStart = activeChecklist?.tableGroupPeriod?.periodStart;
  const periodEnd = activeChecklist?.tableGroupPeriod?.periodEnd;
  const checklistType = activeChecklist?.tableGroupType;

  return selectedPeriod
    && (
      <div className='dataHealthChecklist__row--multi-action'>
        <div className='dataHealthChecklist__row--multi-action__block'>
          <PromptRuleButton
            checklistIdentifier={checklistIdentifier}
          />
        </div>
        <div className='dataHealthChecklist__row--multi-action__block'>
          <CommentButton
            locationIdentifier={
              commentsStore.getLocationIdentifier(
                checklistType,
                periodStart,
                periodEnd,
                checklistIdentifier.itemId
              )
            }
            title={title}
          />
        </div>
        <div className='dataHealthChecklist__row--multi-action__block'>
          <RuleCheckListOptions
            checklistIdentifier={checklistIdentifier}
            selectedPeriod={selectedPeriod}
          />
          <CheckListOptions checklistIdentifier={checklistIdentifier} />
        </div>
      </div>
    );
};

export default CheckListActions;
