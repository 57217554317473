import { Col, Row, Skeleton, Space } from 'antd';
import React from 'react';

export const ShimmerLoader = () => (
  <section className='shimmer-loader'>
    <Row>
      <Col span={24}>
        <Skeleton.Input active />
      </Col>
    </Row>
    <Row>
      <Col flex={8} className='trio'>
        <Skeleton.Node active>
          <Space />
        </Skeleton.Node>
      </Col>
      <Col span={8} className='trio'>
        <Skeleton.Node active>
          <Space />
        </Skeleton.Node>
      </Col>
      <Col span={8} className='trio'>
        <Skeleton.Node active>
          <Space />
        </Skeleton.Node>
      </Col>
    </Row>
    <Row>
      <Col flex={1} className='duo'>
        <Skeleton.Node active>
          <Space />
        </Skeleton.Node>
      </Col>
      <Col flex={1} className='duo'>
        <Row>
          <Col span={24}>
            <Skeleton.Node active>
              <Space />
            </Skeleton.Node>
          </Col>
          <Col span={24}>
            <Skeleton.Node active>
              <Space />
            </Skeleton.Node>
          </Col>
        </Row>
      </Col>
    </Row>

    {/* {ShimmerLoaderSummary}
    <br /> */}
    {/* {ShimmerLoaderGraphWriteUp} */}
  </section>
);
