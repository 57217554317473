// TODO: Refactor this file to remove all the eslint-disable
import * as React from 'react';
import TextPill from '../../components/TextPill';
import { useStore } from '../../stores/Store';
import { InsightTab } from '../../stores/v1/pageStore';

const getTabTitle = (activeTab) => {
  switch (activeTab) {
    case InsightTab.overview:
      return 'Overview';
    case InsightTab.dataCompliance:
      return 'Period Close';
    case InsightTab.cashflow:
      return 'Cash flow';
    case InsightTab.taxAndCompliance:
      return 'Tax & Compliance';
    case InsightTab.profitability:
      return 'Profitability';
    case InsightTab.report:
      return 'Performance Report';
    case InsightTab.advisory:
      return 'Advisory Assistant';
    default:
      return '';
  }
};

const getTabClass = (tab, activeTab, isLoadingETL) => {
  const currentActiveTab = tab === activeTab;

  if (!isLoadingETL && currentActiveTab) {
    return 'ttActive';
  }
  if (isLoadingETL) {
    return 'disabledTabTitle';
  }
  return '';
};

const TabComponent = ({
  id = '',
  onClick,
  tab,
  isBeta = false,
  isNew = false
}) => {
  const { businessesStore, pageStore, alertStore } = useStore();
  const isLoadingETL = businessesStore.isLoadingETL; // eslint-disable-line
  const activeTab = pageStore.tabActive;
  const tabTitle = getTabTitle(tab);
  const alertCount = alertStore.getCategoryAlert(businessesStore.selectedBusinessId, tab);
  const tabClass = getTabClass(tab, activeTab, isLoadingETL);

  return (
    <div
      id={id}
      className='notificationManagerCard'
      style={{ cursor: 'pointer' }}
      onClick={() => onClick(tab)}
    >
      <span className={`${tabClass} tabTitle`}>
        {tabTitle}
        {isBeta && <TextPill>Beta</TextPill>}
        {isNew && <TextPill>New</TextPill>}
        {alertCount > 0 && (
          <div className='circleBase big-alert alertRed'>
            <div className='big-alert-text'>{alertCount}</div>
          </div>
        )}
      </span>
    </div>
  );
};

const InsightTabs = ({ onClick }) => (
  <>
    {/* Overview */}
    <TabComponent
      onClick={onClick}
      tab={InsightTab.overview}
    />
    {/* Period Close */}
    <TabComponent
      onClick={onClick}
      tab={InsightTab.dataCompliance}
    />
    {/* Cash Flow */}
    <TabComponent
      onClick={onClick}
      tab={InsightTab.cashflow}
    />
    {/* Tax and Compliance */}
    <TabComponent
      onClick={onClick}
      tab={InsightTab.taxAndCompliance}
    />
    {/* Profitability */}
    <TabComponent
      id='profitabilityTab'
      onClick={onClick}
      tab={InsightTab.profitability}
    />
    {/* Report */}
    <TabComponent
      id='reportTab'
      onClick={onClick}
      tab={InsightTab.report}
    />
    {/* Advisory AI */}
    <TabComponent
      id='advisoryAITab'
      onClick={onClick}
      tab={InsightTab.advisory}
      isBeta
    />
  </>
);

export default InsightTabs;
