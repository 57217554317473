// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */

import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { SelectedTags } from '../../ts/types/Overview';
import { getTags } from '../../services/DataService';

export default class TagStore {
  rootStore: any;

  getTags: any;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.getTags = getTags;
    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }

  tags: string[] = [];

  selectedTags: Array<SelectedTags> = [];

  /**
   * Sets the tags array to the provided tags and sorts them in alphabetical order (A-Z)
   * @param {string[]} tags - An array of strings representing the new tags.
   * @returns {void}
  */
    setTags = (tags:string[]):void => {
      this.tags = tags.sort((a: string, b: string) => a.localeCompare(b));
    }

    /**
   * Sets the selectedTags array to include the provided tag and type.
   * @param {string} tag - A string representing the new tag to be selected.
   * @param {string} type - A string representing the type of the new tag to be selected.
   * @returns {void}
  */
    setSelectedTags = (tag: string, type: string): void => {
      const selectedTags = [...this.selectedTags];
      const currentTag = tag;
      const obj = selectedTags.find((selectedTag) => selectedTag.type === type);

      const tempTag = {
        type,
        tags: [currentTag],
      };

      if (!obj) {
        selectedTags.push(tempTag);
        this.selectedTags = [...selectedTags];
      } else {
        obj.tags = obj.tags.includes(tag)
          ? obj.tags.filter((iTag) => iTag !== tag)
          : [...obj.tags, tag];
      }

      this.selectedTags = selectedTags.filter((selectedTag) => !isEmpty(selectedTag.tags));
    };

    /**
     * Add new practice tags to the existing tag list
     * @param {string[]} tags - An array of strings representing the new practice tags
     * @returns {void}
    */
     addPracticeTags = (tags: string[]) : void => {
       const oldTags = this.tags;
       const newTags = tags.filter((tag) => !oldTags.includes(tag));
       this.tags = [...oldTags, ...newTags];
     };
}
