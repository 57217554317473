import * as React from 'react';
import { CaretRight } from './Icons';
import { Brand } from '../../ts/enums/Colors';
import { ExternalLinkProps } from '../../ts/interfaces/atoms/ExternalLink';

/**
 *
 * @param param0
 * @returns
 */
export const ExternalLink = ({
  label,
  to,
  newTab,
  Icon,
  style,
}:ExternalLinkProps) => (
  <button
    type='button'
    onClick={() => window.open(to, newTab ? '_blank' : '_self')}
    style={{
      background: 'none',
      color: Brand.blue,
      border: 'none',
      ...style
    }}
  >
    {label}{' '}{Icon}
  </button>
);

ExternalLink.defaultProps = {
  newTab: false,
  Icon: <CaretRight scale='1.5' style={{ marginBottom: '0.2em' }} />,
  style: {},
};
