import React from 'react';
import { observer } from 'mobx-react';
import { PeriodCloseTypes } from '@aider/constants-library';
import { InputNumber, Tooltip } from 'antd';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import { useStore } from '../../stores/Store';

const CustomCell = ({
  cell,
  checklistIdentifier,
  row
}: {
  cell: Extract<PeriodCloseTypes.ChecklistCell, { cellType: 'custom' }>;
  checklistIdentifier: PeriodCloseTypes.ChecklistIdentifier;
  row: PeriodCloseTypes.ChecklistRow;
}) => {
  const { businessesStore, checklistStore } = useStore();
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(cell.rawData);
  const format = new Format(businessesStore.selectedBusiness?.currencyCode, businessesStore.selectedBusiness?.countryCode);
  format.precision = 2;

  const handleSave = () => {
    setEditing(false);
    const rowInx = row?.rowIndex;
    const colInx = cell?.cellIndex;
    checklistStore.updateCustomCell(
      checklistIdentifier.checklistId,
      checklistIdentifier.sectionId,
      checklistIdentifier.itemId,
      rowInx,
      colInx,
      cell.customCellId,
      value
    );
  };

  return editing ? (
    <>
      <InputNumber
        autoFocus
        size='small'
        controls={false}
        precision={2}
        onChange={(val) => setValue(val)}
        value={value}
        formatter={(val) => `${businessesStore.selectedBusiness?.currencySymbol || '$'} ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(val) => val?.replace(/.*\$\s?|(,*)/g, '') as unknown as number}
        onPressEnter={handleSave}
        onKeyUp={(e) => {
          if (e.key === 'Escape') {
            setEditing(false);
          }
        }}
        addonAfter={(
          <span className='checklist-input__controls'>
            <Tooltip title='Save' placement='bottom'>
              <ButtonPrimary
                className='success'
                size='small'
                shape='default'
                icon={<CheckOutlined />}
                onClick={handleSave}
                mixpanelEvent='Checklist - Custom Cell Save Edit'
                mixpanelProperties={{
                  checklistId: checklistIdentifier.checklistId,
                  sectionId: checklistIdentifier.sectionId,
                  itemId: checklistIdentifier.itemId,
                  rowIndex: row?.rowIndex,
                  cellIndex: cell?.cellIndex,
                  cellId: cell.customCellId,
                  value
                }}
              />
            </Tooltip>
            <Tooltip title='Cancel' placement='bottom'>
              <ButtonSecondary
                danger
                size='small'
                shape='default'
                icon={<CloseOutlined />}
                onClick={() => setEditing(false)}
                mixpanelEvent='Checklist - Custom Cell Cancel Edit'
              />
            </Tooltip>
          </span>
        )}
      />
    </>
  ) : (
    <Tooltip
      title={format.formatValue({
        value: cell.data,
        format: ValueTypes.textUpperFirst
      })}
    >
      <ButtonSecondary
        className='checklist-input__toggle-button no-padding'
        size='small'
        type='link'
        onClick={() => setEditing(true)}
        icon={<EditOutlined />}
      >
        {cell?.rawData || cell?.rawData === 0
          ? format.formatValue({
            format: ValueTypes.currency,
            value: cell.rawData
          })
          : format.formatValue({
            value: cell.data,
            format: ValueTypes.textUpperFirst
          })}
      </ButtonSecondary>
    </Tooltip>
  );
};

export default observer(CustomCell);
