import { Form } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import Notification from '../../Notification';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import ClientPanel from './ClientPanel';
import AdvisorPanel from './AdvisorPanel';
import { IManagePermissionsAdvisorPanelRef, IManagePermissionsClientPanelRef } from '../../../models/interfaces/components';
import ButtonPrimary from '../../customAntD/ButtonPrimary';

const ManagePermissions = () => {
  // Import stores
  const rootStore = useStore();
  const { advisorsStore, advisorClientStore, loadingStore, practiceStore, userStore } = rootStore;

  // States for managing loading states
  const [saving, setSaving] = useState(false);
  const [clientsLoading, setClientsLoading] = useState<boolean>(true);
  const [form] = Form.useForm();

  // Refs for accessing child component methods
  const advisorPanelRef = useRef({} as IManagePermissionsAdvisorPanelRef);
  const clientPanelRef = useRef({} as IManagePermissionsClientPanelRef);

  /**
   * Function to retrieve the access value for a client from the advisorPermissionList
   * @param {string} clientId The id of the client
   * @returns {boolean}
   */
  const getAdvisorAccess = (clientId: string) => advisorClientStore.advisorPermissionList.find((client) => client.id === clientId)?.access;

  /**
   * Retrieves the list of clients for the selected advisor
   * @param id The id of the selected advisor
   */
  const fetchClients = useCallback(async (id: string) => {
    setClientsLoading(true);
    await advisorClientStore.retrieveAdvisorClients(id);
    if (clientPanelRef.current) {
      clientPanelRef.current.getUpdatedChecklistValues({ accessor: getAdvisorAccess });
      clientPanelRef.current.updateSelectAll(advisorClientStore.activeClients.length);
    }
    setClientsLoading(false);
  }, [userStore.id]);

  /**
   * Fetches the list of advisors and clients on mount
   * defaulting to use the logged in user as the selected advisor
   */
  useEffect(() => {
    if (practiceStore.id && userStore.hasUserDetails) {
      const fetchAdvisors = async () => {
        loadingStore.setLoading('advisors');
        await advisorsStore.retrieveAllAdvisors();
        loadingStore.setDoneLoading('advisors');
      };
      fetchAdvisors();
      fetchClients(userStore.id);
    }
  }, [practiceStore.id, userStore.hasUserDetails, advisorsStore.retrieveAllAdvisors, fetchClients, userStore.id]);

  /**
   * Function to handle the saving of the form
   * @param values form values to save
   * @returns Save Status object
   */
  const onFinish = async (values) => {
    const selectedClients = Object.keys(values).filter((key) => values[key]);

    trackMixpanelEvent({
      description: 'Manage Permissions: Save Changes',
      properties: {
        selectedAdvisor: advisorPanelRef.current.selectedUserEmail,
      },
      rootStore
    });

    setSaving(true);

    try {
      await advisorClientStore.updateAdvisorClients(selectedClients);
      await advisorClientStore.persistAdvisorClients(advisorPanelRef.current.selectedUser);
      setSaving(false);

      Notification({
        type: 'success',
        title: 'Changes saved',
      });

      return ({ status: 'success' });
    } catch (error) {
      Sentry.captureException(error);
      setSaving(false);
      Notification({
        type: 'error',
        title: 'Sorry, unable to save changes',
        description: 'Please try again.'
      });
      return ({ status: 'error', error });
    }
  };

  return (
    <>
      <header className='sub-content__header'>
        <h2 className='sub-content__header--title wrapper'>Manage Permissions</h2>
      </header>

      <Content className='sub-content__content'>
        <div className='manage-permissions wrapper'>
          <section className='manage-permissions__column'>
            <AdvisorPanel
              ref={advisorPanelRef}
              fetchClients={fetchClients}
            />
          </section>
          <section className='manage-permissions__column'>
            <ClientPanel
              ref={clientPanelRef}
              clientsLoading={clientsLoading}
              form={form}
              onFinish={onFinish}
              saving={saving}
              selectedUserEmail={advisorPanelRef.current.selectedUserEmail}
            />
          </section>
        </div>

      </Content>
      <Footer className='sub-content__footer'>
        <ButtonPrimary
          htmlType='submit'
          onClick={form.submit}
          size='large'
          loading={saving}
          disabled={saving}
          mixpanelEvent='Manage Permissions: Save Changes'
        >
          {saving ? 'Saving' : 'Save'}
        </ButtonPrimary>
      </Footer>
    </>
  );
};
export default observer(ManagePermissions);
