import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';

const PeriodSelection = ({ headerTitle, periods, onChange }) => (
  <div className='periodSelecterHeader mb-3'>
    <div className='periodSelecterHeader__block'>
      <h3 className='text-white mb-0'>{headerTitle}</h3>
      <Select
        className='incomeTax-dropdown'
        defaultValue={periods[0].periodData?.name}
        onChange={onChange}
        size='large'
        options={periods
          .map((period) => (
            { value: period.periodData?.name, label: period.periodData?.name }
          ))}
      />
    </div>
  </div>
);
export default observer(PeriodSelection);
