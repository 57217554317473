import React from 'react';

const SuccessIllustration = () => (
  <svg viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='60' cy='60' r='60' fill='#F5F5F9' />
    <path d='M60.2489 84.8374L82.2489 46.7322C82.525 46.2539 82.3612 45.6424 81.8829 45.3662L72.3566 39.8662C71.8783 39.5901 71.2667 39.7539 70.9906 40.2322L53.9906 69.6771L42.7322 63.1771C42.2539 62.901 41.6424 63.0648 41.3662 63.5431L36.8662 71.3374C36.5901 71.8156 36.7539 72.4272 37.2322 72.7034L58.8829 85.2034C59.3612 85.4795 59.9728 85.3156 60.2489 84.8374Z' fill='#94C1FF' />
    <path d='M70.3566 43.3303L71.8566 40.7322C72.1327 40.2539 72.7443 40.0901 73.2226 40.3662L81.8829 45.3662C82.3612 45.6424 82.525 46.2539 82.2489 46.7322L60.2489 84.8374C59.9728 85.3156 59.3612 85.4795 58.8829 85.2034L37.2322 72.7034C36.7539 72.4272 36.5901 71.8156 36.8662 71.3374L41.8662 62.6771C42.1424 62.1988 42.7539 62.0349 43.2322 62.3111L55.3566 69.3111L66.8566 49.3925' stroke='#4263EA' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M52.5777 78.0985C52.4476 78.3238 52.2221 78.4936 51.9716 78.5683C51.717 78.6293 51.4367 78.5944 51.2117 78.4645C50.9863 78.3344 50.8163 78.1093 50.7419 77.8583C50.6113 77.3443 50.9411 76.773 51.4518 76.6286C51.7062 76.568 51.9863 76.6023 52.2117 76.7324C52.4367 76.8623 52.6069 77.088 52.6815 77.3386C52.7422 77.5929 52.7076 77.8735 52.5777 78.0985Z' fill='#4263EA' />
    <path d='M56.0416 80.0985C55.9115 80.3238 55.686 80.4936 55.4354 80.5683C55.1808 80.6293 54.9005 80.5944 54.6756 80.4645C54.4502 80.3344 54.2801 80.1093 54.2057 79.8583C54.0751 79.3443 54.4101 78.7641 54.9157 78.6286C55.17 78.568 55.4502 78.6023 55.6756 78.7324C55.9094 78.8674 56.0707 79.088 56.1454 79.3386C56.206 79.5929 56.1766 79.8646 56.0416 80.0985Z' fill='#4263EA' />
    <path d='M56.0416 80.0985C55.9115 80.3238 55.686 80.4936 55.4354 80.5683C55.1808 80.6293 54.9005 80.5944 54.6756 80.4645C54.4502 80.3344 54.2801 80.1093 54.2057 79.8583C54.0751 79.3443 54.4101 78.7641 54.9157 78.6286C55.17 78.568 55.4502 78.6023 55.6756 78.7324C55.9094 78.8674 56.0707 79.088 56.1454 79.3386C56.206 79.5929 56.1766 79.8646 56.0416 80.0985Z' fill='#4263EA' />
    <path d='M58.0416 76.6346C57.9115 76.86 57.686 77.0298 57.4354 77.1044C57.1808 77.1655 56.9005 77.1305 56.6756 77.0006C56.4502 76.8705 56.2801 76.6455 56.2057 76.3944C56.0751 75.8804 56.4101 75.3002 56.9157 75.1648C57.17 75.1041 57.4502 75.1384 57.6756 75.2686C57.9094 75.4036 58.0707 75.6241 58.1454 75.8747C58.206 76.1291 58.1766 76.4007 58.0416 76.6346Z' fill='#4263EA' />
    <path d='M61.5054 78.6346C61.3753 78.86 61.1499 79.0298 60.8993 79.1044C60.6447 79.1655 60.3644 79.1305 60.1394 79.0006C59.914 78.8705 59.744 78.6455 59.6696 78.3944C59.539 77.8804 59.874 77.3002 60.3796 77.1648C60.6339 77.1041 60.914 77.1384 61.1394 77.2686C61.3644 77.3984 61.5346 77.6241 61.6092 77.8747C61.6699 78.1291 61.6353 78.4096 61.5054 78.6346Z' fill='#4263EA' />
    <path d='M63.5054 75.1707C63.3753 75.3961 63.1499 75.5659 62.8993 75.6405C62.6447 75.7016 62.3644 75.6666 62.1394 75.5367C61.914 75.4066 61.744 75.1816 61.6696 74.9306C61.539 74.4165 61.874 73.8364 62.3796 73.7009C62.6339 73.6402 62.914 73.6746 63.1394 73.8047C63.3644 73.9346 63.5346 74.1603 63.6092 74.4109C63.6699 74.6652 63.6353 74.9458 63.5054 75.1707Z' fill='#4263EA' />
    <path d='M59.5054 82.0985C59.3753 82.3238 59.1499 82.4936 58.8993 82.5683C58.6447 82.6293 58.3733 82.5995 58.1394 82.4645C57.914 82.3344 57.744 82.1093 57.6696 81.8583C57.5339 81.3531 57.874 80.7641 58.3796 80.6286C58.6339 80.568 58.914 80.6023 59.1394 80.7324C59.3644 80.8623 59.5346 81.088 59.6092 81.3386C59.6699 81.5929 59.6353 81.8735 59.5054 82.0985Z' fill='#4263EA' />
    <path d='M69.2115 47.2879C69.0814 47.5133 68.8559 47.6831 68.6053 47.7577C68.3508 47.8188 68.0704 47.7838 67.8455 47.6539C67.6201 47.5238 67.4501 47.2988 67.3757 47.0478C67.2399 46.5426 67.58 45.9536 68.0856 45.8181C68.34 45.7574 68.6201 45.7918 68.8455 45.9219C69.0704 46.0518 69.2407 46.2775 69.3153 46.528C69.3759 46.7824 69.3414 47.0629 69.2115 47.2879Z' fill='#4263EA' />
  </svg>

);

export default SuccessIllustration;
