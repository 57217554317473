import React from 'react';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { SyncOutlined } from '@ant-design/icons';

import { useLocation } from 'react-router-dom';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import ResyncStatus from '../manualResync/ResyncStatus';
import { useStore } from '../../stores/Store';
import handleError from '../../lib/errorHandler';
import { Routers } from '../../models/enums/utils';

type DashboardDataStatusProps = {
  businessName: string;
  businessId: string;
  businessOsp: string;
}

const DashboardDataStatus = ({
  businessName, businessId, businessOsp
}: DashboardDataStatusProps) => {
  const rootStore = useStore();
  const { localeStore, periodCloseDashboardStore, dashboardStore, resyncStore } = rootStore;
  const location = useLocation();

  const {
    loadingBusinesses,
    resyncingBusinesses,
    triggerETLResync,
  } = resyncStore;

  const triggerResync = async (e) => {
    e.stopPropagation();
    if (!resyncingBusinesses.get(businessId) && !loadingBusinesses.get(businessId)) {
      try {
        trackMixpanelEvent({
          description: 'Business Resync - Overview',
          properties: {
            businessId,
            businessName,
            businessOsp,
          },
          rootStore,
        });
        resyncingBusinesses.set(businessId, true);
        loadingBusinesses.set(businessId, true);
        await triggerETLResync(businessId);
      } catch (err) {
        resyncingBusinesses.set(businessId, false);
        resyncStore.error.set(businessId, true);
        setTimeout(() => { resyncStore.error.set(businessId, false); }, 5000);
        handleError({ error: err, transaction: 'Dashboard - Resync' });
      }
    }
  };

  /** Render component */

  return (
    <span
      key={`dashboard-data-status-${businessId}`}
      className='dashboard-data-status'
      onClick={triggerResync}
    >
      {(
        !resyncStore.dataStatusPendingData?.get(businessId)
      ) && (
        <Tooltip
          className='dashboard-data-status__tooltip'
          title={(
            <div className='dashboard-tooltip'>
              {resyncingBusinesses.get(businessId)
                ? localeStore.translation('data-status.resyncing', {
                  osp: localeStore.translation(`osps.${businessOsp}`),
                })
                : localeStore.translation('data-status.resync', {
                  osp: localeStore.translation(`osps.${businessOsp}`),
                })}
            </div>
          )}
          placement='right'
        >
          <SyncOutlined
            spin={resyncingBusinesses.get(businessId)}
            onClick={triggerResync}
          />
        </Tooltip>
      )}
      <ResyncStatus
        key={`dashboard-resync-status-${businessId}`}
        businessId={businessId}
        callback={() => {
          if (location.pathname === Routers.PERIOD_CLOSE_DASHBOARD) {
            return periodCloseDashboardStore.retrieveDashboardRow(businessId);
          }
          return dashboardStore.updateBusinessRow(businessId);
        }}
      />
    </span>
  );
};

export default observer(DashboardDataStatus);
