// TODO: Refactor this file to eliminate eslint-disable
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import cookie from 'js-cookie';
import { makeAutoObservable, toJS } from 'mobx';
import { firestore } from 'firebase';
import { CustomRuleEnums } from '@aider/constants-library';
import {
  AccountType,
  BCFailReason,
  CLIENT_DETAIL_THRESHOLD,
  RoleKey,
  SHORTLIST_LENGTH,
  SUBSCRIPTION_PREFIX_LENGTH,
} from '../../entities/types';
import businessService from '../../services/businessService';
import { ISubscription } from '../../ts/interfaces/components/Modal';
import { rootStore } from '../Store';
import { Rule } from '../../models/interfaces/Rules';
import Mixpanel from '../../lib/mixpanel';

/**
 * @deprecated
 */
export default class BusinessStore {
  rootStore: any;

  businessService: any;

  fetchFullBusinessInviteList: any;

  fetchPendingInviteCount: any;

  inviteUser: any;

  resendInvite: any;

  getConnectionProfileData: any;

  getBusinessUsers: any;

  getPracticeAdvisors: any;

  editBusinessDetails: any;

  editUserDetails: any;

  pingProxyServer: any;

  inviteUserFromSuggested: any;

  inviteAdvisor: any;

  activateSubscription: any;

  cancelSubscription: any;

  initiateSubscription: any;

  deleteSubscription: any;

  disconnectBusiness: any;

  fetchAdvisorInvitationList: any;

  removeBusiness: any;

  getConnectionsForBusinesses: any;

  getUsersForBusinesses: any;

  getInvitationsForBusinesses: any;

  getUserDetail: any;

  getBusinessCheckList: any;

  uploadProfileImage: any;

  getAllBusinessAlerts: any;

  updatePracticeInformation: any;

  addBusinessTags: any;

  removeBusinessTags: any;

  constructor(initStore) {
    this.rootStore = initStore;
    this.businessService = businessService;
    this.pingProxyServer = this.businessService.pingProxyServer;
    this.fetchFullBusinessInviteList =
      this.businessService.fetchFullBusinessInviteList;
    this.fetchPendingInviteCount = this.businessService.fetchPendingInviteCount;
    this.inviteUser = this.businessService.inviteUser;
    this.resendInvite = this.businessService.resendInvite;
    this.getConnectionProfileData =
      this.businessService.getConnectionProfileData;
    this.getBusinessUsers = this.businessService.getBusinessUsers;
    this.getPracticeAdvisors = this.businessService.getPracticeAdvisors;
    this.editBusinessDetails = this.businessService.editBusinessDetails;
    this.editUserDetails = this.businessService.editUserDetails;
    this.inviteUserFromSuggested = this.businessService.inviteUserFromSuggested;
    this.inviteAdvisor = this.businessService.inviteAdvisor;
    this.disconnectBusiness = this.businessService.disconnectBusiness;
    this.fetchAdvisorInvitationList =
      this.businessService.fetchAdvisorInvitationList;
    this.getConnectionsForBusinesses =
      this.businessService.getConnectionsForBusinesses;
    this.getInvitationsForBusinesses =
      this.businessService.getInvitationsForBusinesses;
    this.getUsersForBusinesses = this.businessService.getUsersForBusinesses;
    this.removeBusiness = this.businessService.removeBusiness;
    this.getUserDetail = this.businessService.getUserDetail;
    this.uploadProfileImage = this.businessService.uploadProfileImage;
    this.getBusinessCheckList = this.businessService.getBusinessCheckList;
    this.getAllBusinessAlerts = this.businessService.getAllBusinessAlerts;
    this.updatePracticeInformation =
      this.businessService.updatePracticeInformation;
    this.addBusinessTags = this.businessService.addBusinessTags;
    this.removeBusinessTags = this.businessService.removeBusinessTags;

    makeAutoObservable(
      this,
      {
        rootStore: false,
        businessService: false,
      },
      { autoBind: true }
    );
  }

  selectedBusiness: any = {};

  clientFormFields = {
    taxRate: null,
    taxNumber: '',
    taxAgent: '',
    salesTaxBasis: '',
    salesTaxPeriod: '',
    superPaymentFrequency: '',
    financialYearEnd: '',
    lineOfBusiness: '',
    address: '',
    phoneNumber: '',
    hasSelectedBankAccount: false,
    selectedBankAccounts: [],
  };

  selectedBusinessData = {
    id: 0,
    name: 'Loading...',
    inbox: 1,
    apps: 2,
    data_is_shared: true,
    selected: true,
    notifications: [],
    connectedApps: [],
    tags: [],
    currencySymbol: '$',
    currencySymbolLong: 'NZ$',
  };

  selectedBusinessDetail: any = {
    unreadNotifications: 0,
    data: {},
    disconnected: false,
    users: [],
    connectedApps: [],
  };

  editClientFields = {};

  users = [];

  practiceAlerts = {};

  advisorsLoading = false;

  updatingPracticeInformation = false;

  configureBusinessBankAccount = false;

  bankAccountSelectionModal = false;

  businessRules = {};

  selectedBusinessRule: Rule = {
    title: '',
    section: CustomRuleEnums.RuleCategories.profitAndLoss,
    order: 1,
    rule: {
      from: [],
      show: CustomRuleEnums.RuleShow.transactions,
      criteria: CustomRuleEnums.TransactionRuleCriteria.above,
      value: 0,
      instruction: '',
    },
  };

  savingBusinessConnection = false;

  selectedBusinessName = '';

  selectedBusinessLine = '';

  selectedBusinessTaxBasis = '';

  selectedBusinessTaxPeriod = '';

  subscription = {};

  initSubscription = {};

  selectedBusinessUsers = [];

  selectedLiveUsers = [];

  totalInvites = [];

  selectedBusinessId = null;

  practiceUsers = { userCount: 0, users: null };

  activeUser = {
    roleKey: null,
  };

  selectedBusinessAccessToken = null;

  tokenCache = [];

  currentConnection = [];

  selectedBusinessEntityId = null;

  businessListData = [];

  businessListDetail = [];

  businessInvitationList = [];

  loading = false;

  inviteUserModalOpen = false;

  configureBusinessModalOpen = false;

  acceptedInvites = [];

  invitations = {};

  advisorInvitations = {};

  xeroDisconnectLink = null;

  invitedUserDetails = null;

  constructedBusinessField = {};

  practiceId = {
    name: '',
    id: '',
    profile: {},
  };

  confirmationWindowIsRendered = false;

  clientConfirmationWindowIsRendered = false;

  isFirstTimeSetup = false;

  editDetailsIsLoading = false;

  businessInviteInitial = false;

  disconnectConfirmation = false;

  clientSyncsForCurrentPractice = null;

  authDetailSync = null;

  connectionStatus = null;

  connectionState = null;

  seeMoreInfo = null;

  seeMoreToggled = false;

  connectionResult = [];

  connectionPending = [];

  connectionFailure = [];

  connectionTrustFiltered = [];

  // cleared per session? persisted?
  actionRequiredList = [];

  xeroConnection = {};

  xeroConnectionId = null;

  businessApps = [];

  initialFields = null;

  removeConfirmation = null;

  duplicateBusinessName = null;

  displayError = null;

  reconnectedConnectionName = null;

  businessAppsConnected = [];

  selectedAdvisorsClients = {
    currentClients: [],
    validClients: [],
    allClients: [],
  };

  businessInstance = {};

  practiceSubscription = {
    trialDaysLeft: 0,
    type: null,
    status: null,
    reason: null,
  };

  selectedBankAccounts = [];

  selectedClientFields: any = {};

  businessInviteStatusArray = [];

  sortBy = 'shortlist';

  isDescending = true;

  businessesLoaded = false;

  practiceLogoURL = null;

  insightSummary = null;

  insightData = null;

  checkListData = null;

  checkListLoading = true;

  newRuleSaving = false;

  permissionsLoading = false;

  clientInformationLoading = false;

  mockLoading = false;

  subscriptionDetails: ISubscription | null = null;

  currentNonce: string = '';

  ruleLoadingMsg:
    | {
      id: string;
      msg?: string;
      loadingStatus: boolean | null;
    }[]
    | null = [
      {
        id: '',
        msg: 'Analysing data for matching items... please wait',
        loadingStatus: false,
      },
    ];

  isRuleLoading: boolean = false;

  clientShortlist = [];

  activePeriod: string = '';

  setSelectedBusinessAttribute(name, value) {
    this.selectedBusiness[name] = value;
  }

  // THis is not getting called initially anywhere. There was no print out from this function on loading the app and then selecting the settings modal
  setSelectedBusinessDefaults(businessId) {
    const businessInstance = this.rootStore.businessesStore.businesses.get(businessId);
    const businessDetail = this.businessListDetail.find(
      (business) => business.businessId === businessId
    );

    this.selectedBusiness = {
      id: businessInstance.id,
      name: businessInstance.name,
      lineOfBusiness: businessInstance.lineOfBusiness,
      salesTaxNumber: businessDetail?.data?.salesTaxBasis || '',
      salesTaxBasis: businessDetail?.data?.taxNumber || '',
      salesTaxPeriod: businessDetail?.data?.salesTaxPeriod || '',
      taxRate: businessInstance?.taxRate
        ? businessInstance.taxRate
        : businessInstance.countryCode === 'AU'
          ? 25
          : 28,
      countryCode: businessInstance.countryCode || 'NZ',
      financialYearEnd: businessDetail?.data?.financialYearEnd
        ? businessDetail?.data?.financialYearEnd
        : businessInstance.countryCode === 'NZ'
          ? '31/3'
          : '30/6',
      address: businessDetail?.data?.address || '',
      phoneNumber: businessDetail?.data?.phoneNumber || '',
      superPaymentFrequency:
        businessDetail?.profile?.superPaymentFrequency ? businessDetail?.profile
          ?.superPaymentFrequency : businessInstance.countryCode === 'AU'
          ? 'quarterly'
          : 'monthly',
    };

    this.setEditClientFields({ ...this.selectedBusiness });
  }

  setClientFormFields(name, value) {
    this.clientFormFields[name] = value;
  }

  setEditClientFields(fields) {
    this.editClientFields = fields;
    this.clientFormFields = fields;
  }

  setPracticeLogoURL(url) {
    this.practiceLogoURL = url;
  }

  triggerMockLoading(time) {
    this.mockLoading = true;
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (this.mockLoading = false), time);
  }

  // eslint-disable-next-line consistent-return
  updateActionRequiredList(id) {
    const businessDetail = this.businessListDetail.find(
      (i) => i.businessId === id
    );

    if (businessDetail.profile && businessDetail.profile.bankAccountsVerified) {
      return null;
    }
    // if the ID in the list doesn't exist yet, add it.
    if (!this.actionRequiredList.includes(id)) {
      this.actionRequiredList = [...this.actionRequiredList, id];
    }
  }

  setPermissionsLoading(loading) {
    this.permissionsLoading = loading;
  }

  setUpdatingPracticeInformation(loading) {
    this.updatingPracticeInformation = loading;
  }

  setSelectedBusinessEntityId(entityId) {
    this.selectedBusinessEntityId = entityId;
  }

  setSelectedBankAccounts(bankAccountList) {
    this.selectedBankAccounts = bankAccountList;
  }

  setBusinessRules(businessRules, tableGroupId) {
    this.businessRules[tableGroupId] = businessRules;
  }

  setBusinessSelectedRule(businessRule) {
    this.selectedBusinessRule = businessRule;
  }

  setClientFields(fields) {
    this.clientFormFields = fields;
  }

  // eslint-disable-next-line consistent-return
  removeFromActionRequiredList(id) {
    // if the ID in the list doesn't exist yet, add it.
    if (this.actionRequiredList.includes(id)) {
      this.actionRequiredList.splice(this.actionRequiredList.indexOf(id), 1);
      return this.actionRequiredList;
    }
  }

  changeSortInStore(sortBy) {
    if (this.sortBy === sortBy) {
      this.sortBy = 'alphabetical';
      // this.isDescending = !rootStore.businessStore.isDescending;
    } else {
      this.sortBy = sortBy;
      this.isDescending = true;
    }
  }

  // eslint-disable-next-line consistent-return
  sortBusinessListData(sortBy, isDescending) {
    if (
      rootStore.businessStore.businessListDetail.length >= 1
      && this.businessesLoaded
    ) {
      // todo inefficient data structure
      // @ts-ignore
      this.businessListData.replace(
        // eslint-disable-next-line consistent-return, array-callback-return
        this.businessListData.slice().sort((ax, bx) => {
          const axDetail = rootStore.businessStore.businessListDetail.find(
            (business) => business.businessId === ax.id
          );
          const bxDetail = rootStore.businessStore.businessListDetail.find(
            (business) => business.businessId === bx.id
          );
          if (sortBy === 'alphabetical' && isDescending) {
            const nameA = ax.name.toLowerCase();
            const nameB = bx.name.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          } else if (sortBy === 'alphabetical' && !isDescending) {
            const nameA = ax.name.toLowerCase();
            const nameB = bx.name.toLowerCase();
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
          } else if (sortBy === 'insights') {
            if (axDetail.unreadNotifications < bxDetail.unreadNotifications) {
              return 1;
            }
            if (axDetail.unreadNotifications > bxDetail.unreadNotifications) {
              return -1;
            }
          }
          if (sortBy === 'alerts') {
            if (axDetail.alerts < bxDetail.alerts) {
              return 1;
            }
            if (axDetail.alerts > bxDetail.alerts) {
              return -1;
            }
          }
        })
      );
    } else {
      return false;
    }
  }

  invalidateBusinessList() {
    this.practiceId = {
      name: '',
      id: '',
      profile: {},
    };
    this.practiceSubscription = null;
    this.clientShortlist = [];
    this.sortBy = 'shortlist';
    this.businessListDetail = [];
    this.businessListData = [];
    this.tokenCache = [];
    this.selectedBusinessId = null;
    this.selectedBusinessName = '';
    this.selectedBusinessLine = '';
    this.selectedBusinessTaxBasis = '';
    this.selectedBusinessData = {
      id: 0,
      name: 'Loading...',
      inbox: 1,
      apps: 2,
      data_is_shared: true,
      selected: true,
      notifications: [],
      connectedApps: [],
      tags: [],
      currencySymbol: '$',
      currencySymbolLong: 'NZ$',
    };
    this.selectedBusinessDetail = {
      unreadNotifications: 0,
      data: {},
      disconnected: false,
      users: [],
      connectedApps: [],
    };
  }

  setBusinessApps(apps) {
    // eslint-disable-next-line no-return-assign
    return (this.businessApps = apps);
  }

  setBusinessDetailApps(apps, id) {
    const appList = apps.filter(
      (app) => typeof app._links.connect === 'undefined'
    );
    const businessDetail = this.businessListDetail.find(
      (i) => i.businessId === id
    );
    businessDetail.connectedApps = appList;
    // eslint-disable-next-line no-return-assign
    return (this.businessAppsConnected = appList);
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setClientInformationLoading(loading) {
    this.clientInformationLoading = loading;
  }

  setInitialFields(fields) {
    this.initialFields = fields;
  }

  renderDisconnectConfirmation(isOpen) {
    this.disconnectConfirmation = isOpen;
  }

  renderRemoveConfirmation(isOpen) {
    this.removeConfirmation = isOpen;
  }

  storeSubscription(subscription) {
    const sub = subscription.data._embedded.subscription.productId.slice(
      SUBSCRIPTION_PREFIX_LENGTH
    );
    if (!cookie.get('impersonatingUser')) {
      Mixpanel.people.set({ subscription: sub });
    }
    this.subscription = subscription.data._embedded.subscription;
  }

  storeInitSubscription(subscriptionLinks) {
    this.initSubscription = subscriptionLinks;
  }

  fetchAdvisorsLoading(loading) {
    this.advisorsLoading = loading;
  }

  renderDuplicateWarning(warningForBusinessName) {
    if (!warningForBusinessName) {
      cookie.remove('duplicateConnection');
    }
    this.duplicateBusinessName = warningForBusinessName;
  }

  renderErrorOccurred(error) {
    if (!error) {
      cookie.remove('error');
    }
    if (error === 'connection') {
      // begin process again
      cookie.remove('claimant');
      cookie.remove('unclaimedConnection');
      cookie.remove('inviteAdvisors');
      cookie.remove('redirect');
    }
    this.displayError = error;
  }

  renderReconnectionConfirmation(warningForBusinessName) {
    if (!warningForBusinessName) {
      cookie.remove('reconnectedConnection');
    }
    this.reconnectedConnectionName = warningForBusinessName;
  }

  storeAdvisorsClients(clients) {
    const allClients = clients.currentClients
      .concat(clients.validClients)
      // eslint-disable-next-line consistent-return, array-callback-return
      .sort((ax, bx) => {
        const nameA = ax.name.toLowerCase();
        const nameB = bx.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      });
    this.selectedAdvisorsClients = {
      currentClients: clients.currentClients,
      validClients: clients.validClients,
      allClients,
    };
  }

  // eslint-disable-next-line consistent-return
  updateBusinessListDetail(id, notifications, data) {
    const businessDetail = this.businessListDetail.find(
      (i) => i.businessId === id
    );
    if (businessDetail) {
      if (notifications !== null) {
        businessDetail.alerts = notifications.alerts;
        businessDetail.silentAlerts = notifications.silentAlerts;
        // eslint-disable-next-line no-return-assign
        return (businessDetail.unreadNotifications =
          notifications.notifications);
      }

      if (data !== null && data.configuration) {
        const {
          address,
          countryCode,
          currencyCode,
          financialYearEnd,
          lineOfBusiness,
          phoneNumber,
          salesTaxBasis,
          salesTaxPeriod,
          shortCode,
          taxNumber,
          tenantConnectionId,
          tenantType,
          timezoneId,
          id: dataId,
        } = data.configuration.entities[0];

        const dataObject = {
          address,
          countryCode,
          currencyCode,
          financialYearEnd,
          lineOfBusiness,
          phoneNumber,
          salesTaxBasis,
          salesTaxPeriod,
          shortCode,
          taxNumber,
          tenantConnectionId,
          tenantType,
          timezoneId,
          dataId,
        };
        businessDetail.disconnected = data.configuration.disconnected;
        // deprecated: tenant connection id used to save bank accounts to xero context
        businessDetail.entityId = tenantConnectionId;
        this.initialFields = dataObject;

        // Ensure that the selected business is updated with sales tax data
        this.selectedBusiness.salesTaxBasis = dataObject.salesTaxBasis;
        this.selectedBusiness.salesTaxPeriod = dataObject.salesTaxPeriod;
        this.selectedBusiness.salesTaxNumber = dataObject.taxNumber;

        // eslint-disable-next-line no-return-assign
        return (businessDetail.data = dataObject);
      }
    }
  }

  storeConnectionsForBusinesses(businessList) {
    // eslint-disable-next-line array-callback-return
    return this.businessListDetail.map((i) => {
      const connectionSet = businessList.find(
        (business) => business.businessId === i.businessId
      );
      if (connectionSet.connections.length < 1) {
        // eslint-disable-next-line no-param-reassign
        i.disconnected = true;
        // eslint-disable-next-line array-callback-return
        return;
      }
      // eslint-disable-next-line no-param-reassign
      i.connectedApps = connectionSet.connections;
    });
  }

  // eslint-disable-next-line consistent-return
  updateBusinessListApps(id, apps) {
    const businessDetail = this.rootStore.businessesStore.businesses.get(id);
    if (apps !== null) {
      this.selectedBusinessData.connectedApps = apps;
      // eslint-disable-next-line no-return-assign
      return (businessDetail.connectedApps = apps);
    }
  }

  updateBusinessListProfile(id, data) {
    const businessDetail = this.businessListDetail.find(
      (i) => i.businessId === id
    );
    if (data !== null && typeof data !== 'undefined') {
      const {
        address,
        countryCode,
        currencyCode,
        financialYearEnd,
        lineOfBusiness,
        phoneNumber,
        salesTaxBasis,
        salesTaxPeriod,
        shortCode,
        taxNumber,
        tenantConnectionId,
        tenantType,
        timezoneId,
        taxRate,
        salesSource,
        purchasesSource,
        operationalExpensesSource,
        reconciledDays,
        incomeTax,
        taxAgent,
        payrollTax,
        fringeBenefitTax,
        dividendWithholdingTax,
        hasSelectedBankAccount,
        bankAccountsVerified,
        superPaymentFrequency,
      } = data;

      const dataObject = {
        taxRate,
        address,
        countryCode,
        currencyCode,
        financialYearEnd,
        lineOfBusiness,
        phoneNumber,
        salesTaxBasis,
        salesTaxPeriod,
        shortCode,
        taxNumber,
        tenantConnectionId,
        tenantType,
        timezoneId,
        salesSource,
        purchasesSource,
        operationalExpensesSource,
        reconciledDays,
        incomeTax,
        taxAgent,
        payrollTax,
        fringeBenefitTax,
        dividendWithholdingTax,
        hasSelectedBankAccount,
        bankAccountsVerified,
        superPaymentFrequency,
      };

      businessDetail.profile = dataObject;
    }
  }

  updateBusinessListContext(id, data) {
    const businessDetail = this.businessListDetail.find(
      (i) => i.businessId === id
    );
    if (data !== null && typeof data !== 'undefined') {
      rootStore.businessStore.setSelectedBankAccounts(
        data.selectedBankAccounts
      );
      if (data.selectedBankAccounts) {
        this.removeFromActionRequiredList(id);

        if (businessDetail && businessDetail.profile) {
          businessDetail.profile.hasSelectedBankAccount =
            data.selectedBankAccounts;
        }
      }
    }
  }

  setSelectedBusinessName(name) {
    this.selectedBusinessName = name;
  }

  /**
   * Action to update the list of all businesses in MobX Store
   * so the selected business contains the latest data
   * @param { string[] } newTags - The tags to set for the selected business
   */
  updateCurrentBusinessListTags(newTags: string[]) {
    this.selectedBusinessData.tags = newTags;
    this.businessListData = this.businessListData.map((business) => {
      if (toJS(business.id) === this.selectedBusinessId) {
        return this.selectedBusinessData;
      }
      return business;
    });
  }

  setSelectedBusinessLine(lineOfBusiness) {
    this.selectedBusinessLine = lineOfBusiness;
  }

  setSelectedBusinessTaxBasis(taxBasis) {
    this.selectedBusinessTaxBasis = taxBasis;
  }

  updatePracticeSync(syncs) {
    // eslint-disable-next-line prefer-destructuring
    this.clientSyncsForCurrentPractice = syncs[0];
  }

  updateAuthDetailsSync(syncs) {
    // eslint-disable-next-line prefer-destructuring
    this.authDetailSync = syncs[0];
  }

  toggleSeeMore(seeMoreToggled) {
    this.seeMoreToggled = seeMoreToggled;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setBulkConnectionStatsNull(stats) {
    this.connectionTrustFiltered = [];
    this.connectionFailure = [];
    this.connectionPending = [];
    this.connectionResult = [];
    this.seeMoreInfo = null;
    this.seeMoreToggled = false;
  }

  setConnectionStatus(status, results) {
    // reason
    this.connectionStatus = status;
    this.connectionState = results?.state;

    if (results) {
      this.connectionResult = Object.values(results).filter(
        // @ts-ignore
        (result) => result.status === 'complete' && result.state === 'finished'
      );
      this.connectionPending = Object.values(results).filter(
        // @ts-ignore
        (result) => result.status === 'in_progress'
      );
      this.connectionFailure = Object.values(results).filter(
        (result: { [key: string]: any }) => result.status !== 'complete'
          && result.state === 'finished'
          && result.reason !== BCFailReason.TRUST
      );
      this.connectionTrustFiltered = Object.values(results).filter(
        (result: { [key: string]: any }) => result.status !== 'complete'
          && result.state === 'finished'
          && result.reason === BCFailReason.TRUST
      );
    }
  }

  async updateAuthDetailSync() {
    return firestore()
      .collection('/authDetails')
      .doc(rootStore.authStore.authDetail)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (
          data.status === 'complete'
          && data.status !== this.connectionStatus
        ) {
          this.subscriptionDetails = data.subscriptionDetails;
          this.currentNonce = data.nonce;
        }
        this.setConnectionStatus(data.status, data.results);
        return this.updateAuthDetailsSync([data]);
      });
  }

  setBusinessAlerts(arrayOfBusinessesAlerts) {
    // eslint-disable-next-line consistent-return
    Object.entries(arrayOfBusinessesAlerts).forEach(([id]) => {
      const businessToUpdate = this.businessListDetail.find(
        (i) => i.businessId === id
      );
      if (businessToUpdate) {
        // eslint-disable-next-line no-return-assign
        return (businessToUpdate.insightAlerts = arrayOfBusinessesAlerts[id]);
      }
    });
    this.practiceAlerts = arrayOfBusinessesAlerts;
  }

  setAllUsers(users) {
    this.users = users;
  }

  updateBusinessListUsers(id, data) {
    const businessDetail = this.rootStore.businessesStore.businesses.get(id);
    const businessUsers = data.businessUsers.filter(
      (user) => user.accountType !== 'AI'
    );
    // Users that are not advisors.
    businessDetail.users = businessUsers.filter(
      (i) => i.roleKey === RoleKey.Admin
        || i.accountType === AccountType.DigitalAssistant
    );
    // Users that are not advisors, but are live in the app.
    // Used in messaging/action centre/people context
    businessDetail.liveUsers = businessUsers.filter(
      (i) => (i.roleKey === RoleKey.Admin
        || i.accountType === AccountType.DigitalAssistant)
        && i.notificationStatus !== 'unregistered'
    );
    // Users that are advisors
    businessDetail.advisors = businessUsers.filter(
      (i) => i.roleKey === RoleKey.Advisor
        || i.accountType === AccountType.AdvisoryPortal
    );
    // The count of advisors
    businessDetail.advisorsCount = businessDetail.advisors.length;
    // The count of advisors and LIVE users
    businessDetail.allCount =
      businessDetail.users.filter(
        (user) => user.notificationStatus !== 'unregistered'
      ).length + businessDetail.advisorsCount;
    // The count of all users, live or not, advisors or not.
    businessDetail.fullCount = businessUsers.length;

    // Sets current user list. Live users.
    this.setUsersList(businessUsers);
    // eslint-disable-next-line no-return-assign
    return (businessDetail.usersCount = businessDetail.users.length);
  }

  storeInvitationCount(data, id) {
    const businessDetail = this.businessListDetail.find(
      (i) => i.businessId === id
    );
    if (data) {
      businessDetail.invitesSent = data;
    } else {
      businessDetail.invitesSent = [];
    }
  }

  storeInvitationCountForBusinesses(data) {
    const array = [];
    const ar = array.concat.apply([], Object.values(data));
    this.totalInvites = ar;
    // eslint-disable-next-line consistent-return
    Object.entries(data).forEach(([id]) => {
      const businessToUpdate = this.businessListDetail.find(
        (i) => i.businessId === id
      );
      if (businessToUpdate) {
        // eslint-disable-next-line no-return-assign
        return (businessToUpdate.extendedInvites = data[id]);
      }
    });
    this.businessInviteStatusArray = data;
  }

  updatePracticeAdvisors(data) {
    this.activeUser = data.businessUsers.find(
      (i) => i.id === rootStore.userStore.id
    );
    this.practiceUsers.users = data.businessUsers.filter(
      (user) => user.roleKey !== 'AI' && user.displayName !== 'Aider'
    );
    this.practiceUsers.userCount = data.count;
  }

  async storeInvitationList(invitationList) {
    if (typeof invitationList[0] !== 'undefined') {
      // eslint-disable-next-line no-return-assign
      return (this.businessInvitationList = invitationList[0].filter(
        (user) => user.emailAddress.toLowerCase() !== rootStore.authStore.practiceUser.user.email.toLowerCase()
      ));
    }
    // eslint-disable-next-line no-return-assign, prefer-destructuring
    return (this.businessInvitationList = invitationList[0]);
  }

  setPracticeId(practiceId) {
    if (practiceId.profile && practiceId.profile.logoUrl) {
      const logoURL = practiceId.profile.logoUrl;
      rootStore.businessStore.setPracticeLogoURL(logoURL);
    }
    if (!cookie.get('impersonatingUser')) {
      Mixpanel.people.set({
        address: practiceId.address,
        practiceId: practiceId.id,
        lineOfBusiness: practiceId.lineOfBusiness,
        practiceName: practiceId.name,
        subscriptionPeriodEnd: practiceId.subscription.currentPeriodEnd,
        subscriptionPaymentMethod: practiceId.subscription.paymentMethod,
        subscriptionPriceType: practiceId.subscription.priceType,
        numberOfClients: practiceId.subscription.items[0].numberOfClients,
        subscriptionStripeId: practiceId.subscription.stripeId,
        subscriptionStatus: practiceId.subscription.status,
        subscriptionType: practiceId.subscription.type,
      });
    }
    cookie.set('practiceIdentifier', practiceId.id, { secure: true });
    this.practiceId = practiceId;
    // If the practice id has not been set in the practice store set it (needed for split)
    this.rootStore.practiceStore.id = this.rootStore.practiceStore.id ?? practiceId.id;
    this.rootStore.subscriptionStore.subscription = practiceId.subscription;
    this.practiceSubscription = practiceId.subscription;
  }

  renderConfirmationWindow(isRendered, data?) {
    this.confirmationWindowIsRendered = isRendered;
    if (data) {
      this.invitedUserDetails = data;
    } else {
      this.invitedUserDetails = null;
    }
  }

  renderClientConfirmationWindow(isRendered) {
    this.clientConfirmationWindowIsRendered = isRendered;
  }

  editDetailsLoading(isLoading) {
    this.editDetailsIsLoading = isLoading;
  }

  pushDataToDetailArray(business) {
    this.businessListDetail.push({
      name: business.name,
      businessId: business.id,
      unreadNotifications: null,
      data: null,
      invitesSent: [],
      profile: business.profile,
      connectedApps: [],
      extendedInvites: [],
      businessClass: business.businessClass,
    });
  }

  storeData(data) {
    if (rootStore.authStore.bulkConnectionsStatus) {
      rootStore.authStore.setBulkConnectionSuccess(true, 0, 0);
    }
    this.businessListDetail = [];
    this.businessListData = [];
    const businessListResponse = data.businesses
      .sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .map((obj) => ({ ...obj, connectedApps: [] }));

    this.businessListData = businessListResponse.filter(
      (i) => i.businessClass !== 'management'
    );
    Mixpanel.people.set({ businessCount: this.businessListData.length });
    // eslint-disable-next-line prefer-destructuring
    this.practiceId = data.businesses.filter(
      (i) => i.businessClass === 'management'
    )[0];

    // clear the detailed list so we can push to it.
    // If we find ourselves in this method we have fetched new data anyway.
    businessListResponse.map(async (business, ix, arr) => {
      this.pushDataToDetailArray(business);
      if (ix <= SHORTLIST_LENGTH) {
        rootStore.businessStore.updateShortlist(business.id);
      }

      if (business.id === this.selectedBusinessId) {
        rootStore.businessStore.updateShortlist(business.id);
        this.selectBusiness2(business.id);
      }

      rootStore.businessStore.updateBusinessListDetail(
        business.id,
        { notifications: 0, total: 0, alerts: 0 },
        null
      );
      if (arr.length <= CLIENT_DETAIL_THRESHOLD) {
        rootStore.businessStore.getUsersForBusinesses(business.id);
      }
      // @ts-ignore
      if (
        arr[0].businessClass === 'management'
        && ix === 1
        && business.businessClass !== 'management'
        && !this.selectedBusinessId
      ) {
        this.selectBusiness2(business.id);
      }

      return true;
    });

    this.businessesLoaded = true;
    rootStore.authStore.setShallowLoader(false);
    rootStore.authStore.setLoading(false);
    this.setLoading(false);

    return rootStore.businessStore.getInvitationsForBusinesses();
  }

  setConnectionSettings(data) {
    this.currentConnection = data._embedded;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setXeroConnection(xeroConnection, otherData) {
    this.xeroConnection = xeroConnection;
    this.xeroConnectionId = xeroConnection.id;
    if (
      xeroConnection
      && xeroConnection._links
      && xeroConnection._links.disconnect
    ) {
      this.xeroDisconnectLink = xeroConnection._links.disconnect.href;
    } else {
      this.xeroDisconnectLink = null;
    }
  }

  async buildAccessTokenCache(businesses) {
    // add cache entry without token , and set to already expired.
    const cache = businesses.map((business) => ({
      id: business.id,
      tokens: null,
      expiresAt: Math.floor(Date.now() / 1000),
    }));
    this.tokenCache = cache;
    rootStore.authStore.tokenRegistry = cache;
    return cache;
  }

  setBusinessAccessToken(access) {
    this.selectedBusinessAccessToken = access;
  }

  setSelectedBusinessId(id) {
    this.selectedBusinessId = id;
    rootStore.businessesStore.selectedBusinessId = id;
  }

  setSelectedBusinessData(businessData) {
    this.selectedBusinessData = {
      ...businessData,
    };
  }

  setSelectedBusinessDetail(businessData) {
    this.selectedBusinessDetail = {
      ...businessData,
    };
  }

  shouldInviteUser(modalOpen) {
    if (!modalOpen) {
      this.businessInviteInitial = false;
      this.selectBusiness2(rootStore.businessStore.selectedBusinessId);
    }
    this.inviteUserModalOpen = modalOpen;
  }

  setBusinessFirstTimeSetup(isFirstTime) {
    this.isFirstTimeSetup = isFirstTime;
  }

  shouldConfigureBusiness(modalOpen) {
    this.configureBusinessModalOpen = modalOpen;
  }

  setConstructedFields(field) {
    this.constructedBusinessField = field;
  }

  shouldConfigureBusinessBankAccount(modalOpen) {
    this.configureBusinessBankAccount = modalOpen;
  }

  renderBankAccountSelectionModal(renderBankAccountSelection) {
    if (!renderBankAccountSelection) {
      this.configureBusinessBankAccount = false;
    }
    this.bankAccountSelectionModal = renderBankAccountSelection;
  }

  addToInvitations(data) {
    this.invitations = {
      // @ts-ignore
      pendingInvitations: [...this.invitations.pendingInvitations, data],
      acceptedInvitations: [],
      cancelledInvitations: [],
    };
  }

  storeInvitationListSplit(data) {
    if (data) {
      const pendingInvitations = data.filter(
        (invitee) => invitee.status === 'pending'
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const acceptedInvitations = data.filter(
        (invitee) => invitee.status === 'accepted'
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const cancelledInvitations = data.filter(
        (invitee) => invitee.status === 'cancelled'
      );
      // @ts-ignore
      this.invitations = {
        pendingInvitations,
        acceptedInvitations: [],
        cancelledInvitations: [],
      };
    } else {
      this.invitations = {
        pendingInvitations: [],
        acceptedInvitations: [],
        cancelledInvitations: [],
      };
    }
  }

  storeAdvisorInvitations(data) {
    const pendingInvitations = data.filter(
      (invitee) => invitee.status === 'pending'
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const acceptedInvitations = data.filter(
      (invitee) => invitee.status === 'accepted'
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cancelledInvitations = data.filter(
      (invitee) => invitee.status === 'cancelled'
    );
    // @ts-ignore
    this.advisorInvitations = {
      pendingInvitations,
      acceptedInvitations: [],
      cancelledInvitations: [],
    };
  }

  setUsersList(users) {
    this.selectedBusinessUsers = users;
    this.selectedLiveUsers = users.filter(
      (user) => user.notificationStatus !== 'unregistered'
    );
  }

  // eslint-disable-next-line consistent-return
  removeFromShortlist(id) {
    if (this.clientShortlist.includes(id)) {
      this.clientShortlist.splice(this.clientShortlist.indexOf(id), 1);
      return this.clientShortlist;
    }
  }

  // eslint-disable-next-line consistent-return
  updateShortlist(id) {
    if (this.clientShortlist.includes(id)) {
      this.clientShortlist.splice(this.clientShortlist.indexOf(id), 1);
      this.clientShortlist.unshift(id);
      return this.clientShortlist;
    }
    // make sure shortlist only contains unique values
    if (this.clientShortlist.length >= SHORTLIST_LENGTH) {
      this.clientShortlist.pop();
    }
    this.clientShortlist.unshift(id);
  }

  clearLastBusiness() {
    rootStore.businessStore.setSelectedBusinessName(null);
    rootStore.businessStore.setSelectedBusinessLine(null);
    rootStore.businessStore.setSelectedBusinessTaxBasis(null);
    this.selectedBusinessDetail = {
      unreadNotifications: 0,
      data: {},
      disconnected: false,
      users: [],
      connectedApps: [],
    };
  }

  async storeInsightData(insightData) {
    // eslint-disable-next-line no-return-assign
    return (this.insightData = insightData);
    // TODO: add all the different time period store default information based on the insight data
  }

  async storeCheckListData(checkListData) {
    this.checkListData = checkListData;
    return this.checkListData;
  }

  async storeCheckListTableGroupData(tableGroupData) {
    const tableGroupIndex = this.checkListData?.tableGroups?.findIndex(
      (tableGroup) => tableGroup.tableGroupId === tableGroupData.tableGroupId
    );
    const checklistData = this.checkListData;
    checklistData.tableGroups[tableGroupIndex] = tableGroupData;

    this.storeCheckListData(checklistData);
  }

  storeInsightSummary(summaryData) {
    this.insightSummary = summaryData;
  }

  setCheckListLoading(loading) {
    this.checkListLoading = loading;
  }

  setNewRuleSaving(saving) {
    this.newRuleSaving = saving;
  }

  async selectBusiness2(businessId) {
    rootStore.businessStore.setLoading(true);
    this.setSelectedBusinessId(businessId);
    rootStore.insightStore.getBusinessInsights();
    rootStore.businessStore.getBusinessCheckList(); // Checks the practice Credentials inside
    const businessData = this.businessListData.find(
      (i) => i.id === businessId
    );
    // isFirstTimeSetup
    this.setSelectedBusinessData(businessData);
    if (businessData) {
      this.setSelectedBusinessName(businessData.name);
    }
    const businessDetail = rootStore.businessStore.businessListDetail.find(
      (i) => i.businessId === businessId
    );
    this.setSelectedBusinessDetail(businessDetail);
    rootStore.businessStore.getUsersForBusinesses(businessId); // Checks the practice Credentials inside
    rootStore.businessStore.shouldConfigureBusinessBankAccount(false);
    await rootStore.businessStore.getConnectionProfileData( // Checks the practice Credentials inside
      { id: businessId, name: 'NA' }
    );
    rootStore.businessStore.setClientFields(businessDetail?.profile);

    this.fetchFullBusinessInviteList( // Checks the practice Credentials inside
      this.rootStore.authStore.links,
      businessId,
      rootStore.authStore.access_token,
      true
    );
    this.setBusinessAccessToken(rootStore.authStore.access_token);

    rootStore.businessStore.setLoading(true);
  }

  // eslint-disable-next-line camelcase
  async selectBusinessAfterClaimingConnection(id, access_token) {
    this.updateShortlist(id);
    this.businessInviteInitial = true;
    this.setInitialFields({
      taxRate: '',
      lineOfBusiness: '',
      taxNumber: '',
      salesTaxBasis: '',
      salesTaxPeriod: '',
      financialYearEnd: '',
      address: '  ',
      // config
      operationalExpensesSource: '',
      salesSource: '',
      purchasesSource: '',
      reconciledDays: '',
      // new config
      incomeTax: '',
      taxAgent: '',
      payrollTax: '',
      fringeBenefitTax: '',
      dividendWithholdingTax: '',
      disconnected: false,
    });
    this.setLoading(true);
    this.setSelectedBusinessId(id);
    this.setBusinessAccessToken(access_token);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const businessDetail = rootStore.businessStore.businessListDetail.find(
      (i) => i.businessId === id
    );

    const businessData = this.businessListData.find((i) => i.id === id);
    rootStore.businessStore.getConnectionProfileData(
      businessData
    );

    this.setSelectedBusinessData(businessData);
    this.setLoading(false);
  }

  async setRuleLoadingMsg(id: string, loadingStatus: boolean, msg?: string) {
    const existIndex = this.ruleLoadingMsg.findIndex((obj) => obj.id === id);

    if (existIndex >= 0) {
      this.ruleLoadingMsg[existIndex] = {
        ...this.ruleLoadingMsg[existIndex],
        loadingStatus,
      };
    } else {
      this.ruleLoadingMsg.push({ id, msg, loadingStatus });
    }
  }

  setSavingBusinessConnection(status: boolean) {
    this.savingBusinessConnection = status;
    if (status) {
      this.existingBusinessListDetail = this.businessListDetail;
    }
  }

  existingBusinessListDetail = null;

  getBusinessRuleById(tableGroupId: string, id: string) {
    return this.businessRules[tableGroupId]?.find((rule) => rule.id === id);
  }

  setActivePeriod(period: string) {
    this.activePeriod = period;
  }

  // Remove during refactor
  setBusinessListDetail(businessListDetail) {
    this.businessListDetail = businessListDetail;
  }

  setChecklistData(data) {
    this.checkListData = data;
  }
}
