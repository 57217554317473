import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { observer } from 'mobx-react';
import { UsersEnums } from '@aider/constants-library';
import { useStore } from '../../../stores/Store';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import { IManagePermissionsAdvisorPanelProps } from '../../../models/interfaces/components';
import AiderAlert from '../../AiderAlert';
import { mailToHref } from '../../../lib/supportHelpers';

const AdvisorPanel = forwardRef(({
  fetchClients
}:IManagePermissionsAdvisorPanelProps, ref) => {
  const rootStore = useStore();
  const { advisorsStore, loadingStore, userStore } = rootStore;

  const [selectedUser, setSelectedUser] = useState<string>(userStore.id);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>(userStore.email);

  const errorSubject = 'Manage Permissions - Missing Advisors';
  const errorBody = `${
    'Hi Aider,\n\n'
    + 'I\'m trying to manage permissions for my practice but I can\'t see any advisors.\n\n'
    + 'Regards,'}`;

  const mailto = mailToHref({ subject: errorSubject, body: errorBody, rootStore });

  /**
   * Handles the selection of an advisor
   * retrieves the list of client permissions for the selected advisor
   * @param e The click event
   */
  const onAdvisorSelect: MenuProps['onClick'] = async (e) => {
    const advisorEmail = advisorsStore.advisors.find((advisor) => advisor.id === e.key)?.email;

    trackMixpanelEvent({
      description: 'Manage Permissions: Select Advisor',
      properties: {
        selectedAdvisor: advisorEmail
      },
      rootStore
    });

    setSelectedUser(e.key);
    setSelectedUserEmail(advisorEmail);

    await fetchClients(e.key);
  };

  /**
   * Generates the list of advisors for the menu
   */
  const advisorListItems: MenuProps['items'] = advisorsStore.sortedActiveAdvisors.map((advisor) => ({
    label: (
      <>
        <h4>{advisor.fullName}{advisor.id === userStore.id ? ' (Me)' : advisorsStore.isAdmin(advisor) ? ` (${UsersEnums.RoleNames.Admin})` : '' }</h4>
        <p>{advisor.email}</p>
      </>
    ),
    key: advisor.id,
    disabled: advisorsStore.isAdmin(advisor),
  }));

  // Expose state to parent component
  useImperativeHandle(ref, () => ({
    selectedUser,
    selectedUserEmail,
  }));

  return (
    <>
      <h3>Select an active advisor</h3>
      <aside className='manage-permissions__panel scrollbar'>
        {!loadingStore.isLoading('advisors')
          && (advisorListItems.length === 0 ? (
            <AiderAlert
              className='manage-permissions__alert'
              type='error'
              title='Sorry, something went wrong.'
            >
              <p>We're unable to show you the list of advisors.</p>
              <p>Please try reloading the page.</p>
              <p>If the problem persists, <a href={mailto}>please report this problem</a>.</p>
            </AiderAlert>
          ) : (
            <Menu
              className='manage-permissions__panel--items'
              onClick={onAdvisorSelect}
              defaultSelectedKeys={[userStore.id]}
              items={advisorListItems}
            />
          ))}
      </aside>
    </>
  );
});

export default observer(AdvisorPanel);
