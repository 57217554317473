export const truncateString = (str: string, maxLength: number): string => {
  if (!str) return str;
  if (str.length <= maxLength) {
    return str;
  }
  const lastSpace = str.lastIndexOf(' ', maxLength);
  return `${str.slice(0, lastSpace)}...`;
};

/**
  * Takes a string and a counter and returns the string with an 's'
  * appended if the counter is greater than 1.
  * @param {string} str - The string to pluralise
  * @param {number} counter - The appropriate counter that pluralisation depends on
  * @param {number} threshold - The threshold by which a string should be pluralised, defaults to 1
  * @returns {string}
  */
export const pluralise = (str: string, counter: number, threshold: number = 1): string => (
  counter > threshold ? `${str}s` : str
);
