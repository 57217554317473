/**
 * To generate y axis label for chart
 * @param: title, unit, isCurrency
 * @returns string
 */
export const yAxisFormat = (
  title: string,
  unit: string | '',
  isCurrency: boolean
) => (isCurrency ? `${title} in ${unit}` : `${unit} ${title}`);

export default yAxisFormat;
