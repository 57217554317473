import React from 'react';
import { truncateString } from '../../../lib/stringMutation';
import AlertIllustration from '../../illustrations/AlertIllustration';

const DeleteRuleConfirmation = ({ rule }:{ rule:any }) => (
  <section className='rule-modal__body centered-modal'>
    <div className='illustration'>
      <AlertIllustration />
    </div>
    <h3>
      Are you sure you want to delete <br />
      &quot;{truncateString(rule?.title, 33)}&quot;
    </h3>
    <p>
      The data check will be deleted for this and future periods.
      <br />
      This won&apos;t affect prior periods if they also contain this data
      check.
      <br />
      This action cannot be undone.
    </p>
  </section>
);

export default DeleteRuleConfirmation;
