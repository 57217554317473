import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../stores/Store';

const TaxStatus = ({ status, countryCode }: { status: string, countryCode?: string }) => {
  const { businessesStore } = useStore();
  const businessCountryCode = countryCode || businessesStore.selectedBusiness.countryCode;
  const reportNameMap = {
    AU: 'BAS / GST Returns',
    CA: 'GST / HST Returns',
    US: 'Sales Tax Reports',
  };

  switch (status) {
    case 'draft':
      return (
        <div className='dashboard-tooltip'>
          <p>Draft - period to date based on transactions</p>
        </div>
      );
    case 'final':
      return (
        <div className='dashboard-tooltip'>
          <p>Finalised</p>
        </div>
      );
    case 'estimate':
      return (
        <div className='dashboard-tooltip'>
          {businessCountryCode === 'AU'
            ? (
              <>
                <h4>
                  BAS status not available due to the limitation of Xero's new
                  BAS report.
                </h4>
                <p>
                  Gst amount shown is based on transaction data and excludes late
                  claims and manual adjustments.
                </p>
              </>
            ) : (
              <p>
                Salex tax amount shown is based on transaction data and excludes
                late claims and manual adjustments.
              </p>
            )}
        </div>
      );
    case 'unavailable':
      return (
        <div className='dashboard-tooltip'>
          <p>This business doesn't seem to be registered for {reportNameMap[businessCountryCode]}</p>
        </div>
      );
    default:
      return null;
  }
};

TaxStatus.defaultProps = {
  countryCode: undefined,
};

export default observer(TaxStatus);
