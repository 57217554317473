import React from 'react';
import type { ReactElement } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import '../styles/index.scss';
import GlobalLoader from '../components/GlobalLoader';

/**
 * MainLayout
 * The base layout for the app. It contains the main navigation and the main content area.
 * @returns {ReactNode}
 */
const MainLayout = (): ReactElement => (
  <Layout className='main'>
    <div className='main__content'>
      <GlobalLoader />
      <Outlet />
    </div>
  </Layout>
);

export default MainLayout;
