import * as Sentry from '@sentry/browser';
import { IAddressAutocompleteProps, IGetPlaceIdDetailsProps, IGetTimezoneProps } from '../models/interfaces/lib';
import { GET } from './requests';

/**
 * Utility function to get address autocomplete options
 * from an inputted value
 * @param {string} address - The address query string to get autocomplete options for
 * @param {function} callback - The callback function to return the options to
 * @param {object} rootStore - The root store object
 */
export const addressAutocomplete = async (
  {
    address,
    callback,
    rootStore
  }:IAddressAutocompleteProps
) => {
  // Helper to apply common error structure for this method
  const sentryLog = (e) => Sentry.captureException('Google Places Autocomplete Error:', e);

  try {
    const key = rootStore.authenticationStore.config.osp.google.googlePlaces.apiKey;

    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(
      address
    )}&key=${encodeURIComponent(key)}`;
    const res = await GET(
      {
        url: process.env.REACT_APP_PROXY_URL + url,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        skipAuth: true,
        rootStore
      }
    );

    const options = [];

    // If the response is not OK, log it to Sentry
    if (res.status !== 'OK') {
      sentryLog(res.status);
    }

    // If the response does not have predictions, log it to Sentry
    if (
      !res.predictions
      || (res.predictions[0] && !res.predictions[0].description)
    ) {
      sentryLog('Invalid prediction format returned from Google Places API');
    } else {
      options.push(...res.predictions.map((p) => ({
        value: p.description,
        text: p.description
      })));
    }

    callback(options);
    return res.predictions;
  } catch (e) {
    sentryLog(e);
    return e;
  }
};

/**
 * Utility function to get detailed information from a place ID
 * @param {string} placeId - The Google Address Place ID
 * @param {object} rootStore - The root store object
 */
export const getPlaceIdDetails = async ({ placeId, rootStore }: IGetPlaceIdDetailsProps) => {
  try {
    const key = rootStore.authenticationStore.config.osp.google.googlePlaces.apiKey;

    const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${encodeURIComponent(
      placeId
    )}&key=${encodeURIComponent(key)}`;

    const res = await GET({
      url: process.env.REACT_APP_PROXY_URL + url,
      rootStore,
    });

    // If the response is not OK, log it to Sentry
    if (res.status !== 'OK') {
      throw Error(`Invalid Status: ${res.status}`);
    }

    // If the response does not have resultant data, log it to Sentry
    if (
      !res.result
    ) {
      throw Error('Invalid prediction format returned from Google Places API');
    }

    return res.result;
  } catch (e) {
    Sentry.captureException('Google Places Get Detail Error:', e);
    return e;
  }
};

/**
 * Utility function to find an address component from an array of address components
 * @param addressComponents - Array of address components
 * @param type - The type of address component to find
 * @returns
 */
export const findAddressComponent = (addressComponents, type) => addressComponents.find((component) => component.types.includes(type));

/**
 * Utility function to get the timezone of a location
 * @param {object} location - The location to get the timezone of
 * @param {object} rootStore - The root store object
 * @returns
 */
export const getTimezone = async ({ location, rootStore }:IGetTimezoneProps) => {
  const key = rootStore.authenticationStore.config.osp.google.googlePlaces.apiKey;

  const ts = Math.round(new Date().getTime() / 1000);
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${location.latitude
  },${location.longitude
  }&timestamp=${ts}&key=${encodeURIComponent(key)}`;

  try {
    const res = await GET({
      url: process.env.REACT_APP_PROXY_URL + url,
      rootStore,
    });
    if (res.status !== 'OK') {
      throw Error(`Invalid Status: ${res.status}`);
    }
    if (!res.timeZoneId && !res.rawOffset && !res.dstOffset) {
      throw Error('Invalid timezone format returned from Google API');
    }
    return res;
  } catch (e) {
    Sentry.captureException('Google Places Get Timezone Error:', e);
    return e;
  }
};
