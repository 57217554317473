import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';
import { Brand } from '../../../ts/enums/Colors';
/**
 * TRashcan Icon
 * @param props
 * @returns
 */
export const Resync = ({
  width = 17,
  height = 17,
  strokeWidth = 1.5,
  color = Brand.blue,
  fill = 'none',
  lineCap = 'round',
  lineJoin = 'round',
  padding = 0.25,
  scale = 1,
  style = {},
  className = '',
  ...rest
}: IconInterface) => {
  const scaleWidth = width * scale;
  const scaleHeight = height * scale;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { min, max } = getPlotExtremes({
    width: scaleWidth,
    height: scaleHeight,
    padding,
  });
  return (
    <svg
      width={scaleWidth}
      height={scaleHeight}
      viewBox='0 0 17 17'
      fill={fill}
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap={lineCap}
      strokeLinejoin={lineJoin}
      style={{ ...style }}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...rest}
    >
      <path
        d='M16.0234 2.89941V6.89941H12.0234'
      />
      <path
        d='M1.35547 13.5654V9.56543H5.35547'
      />
      <path
        d='M3.0288 6.2328C3.36691 5.27732 3.94156 4.42307 4.69911 3.74974C5.45667 3.07642 6.37245 2.60597 7.36099 2.3823C8.34954 2.15863 9.37864 2.18903 10.3523 2.47065C11.3259 2.75227 12.2123 3.27594 12.9288 3.9928L16.0221 6.89946M1.35547 9.56613L4.4488 12.4728C5.1653 13.1897 6.05172 13.7133 7.02534 13.9949C7.99897 14.2766 9.02806 14.307 10.0166 14.0833C11.0052 13.8596 11.9209 13.3892 12.6785 12.7159C13.436 12.0425 14.0107 11.1883 14.3488 10.2328'
      />
    </svg>
  );
};
