import * as React from 'react';
import { State, Text } from '../../../ts/enums/Colors';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';

/**
 * Exclamation Triangle icon
 * @param props
 * @returns
 */
export const ExclamationTriangle = ({
  width = 25,
  height = 25,
  color = Text.white,
  strokeWidth = 1,
  fill = State.danger,
  lineCap = 'round',
  ...rest
}: IconInterface) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    stroke={color}
    strokeWidth={strokeWidth}
    viewBox={`0 0 ${width} ${height}`}
    strokeLinecap={lineCap}
    fill='none'
    {...rest}
  >
    <path d='M10.3036 2.40498L0.575411 19.6266C0.374838 19.995 0.26871 20.4126 0.267587 20.8379C0.266464 21.2632 0.370384 21.6815 0.569009 22.051C0.767634 22.4205 1.05404 22.7284 1.39973 22.9441C1.74543 23.1598 2.13836 23.2758 2.53943 23.2805H21.9958C22.3969 23.2758 22.7898 23.1598 23.1355 22.9441C23.4812 22.7284 23.7676 22.4205 23.9663 22.051C24.1649 21.6815 24.2688 21.2632 24.2677 20.8379C24.2666 20.4126 24.1604 19.995 23.9599 19.6266L14.2317 2.40498C14.0269 2.04704 13.7386 1.7511 13.3946 1.54571C13.0506 1.34032 12.6624 1.23242 12.2676 1.23242C11.8728 1.23242 11.4847 1.34032 11.1407 1.54571C10.7967 1.7511 10.5084 2.04704 10.3036 2.40498Z' fill={fill} />
    <path d='M12.2637 8.42188V13.2568' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.2637 18.0918H12.272' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
