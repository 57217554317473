import React from 'react';
import { observer } from 'mobx-react';
import { v4 as uuid } from 'uuid';
import { Content, Footer } from 'antd/lib/layout/layout';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import { useStore } from '../../../stores/Store';
import CheckListTemplate, { PracticeRule } from '../../checklist/ChecklistTemplate';
import PeriodSelectionHeader from '../../checklist/PeriodSelectionHeader';
import NoRulesBanner from '../../../components-v2/molecules/CheckList/NoRulesBanner';
import CreateRuleButton from '../../CreateRuleButton';
import { useAuthenticatedEffect } from '../../../lib/customHooks/useAuthenticatedEffect';
import PendingRuleBanner from '../../../components-v2/molecules/CheckList/PendingRuleBanner';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import handleError from '../../../lib/errorHandler';
import Notification from '../../Notification';

const ManageChecklists = () => {
  const { localeStore, practiceStore, checklistStore, rulesStore, loadingStore } = useStore();

  const [buttonLoading, setButtonLoading] = React.useState(false);

  useAuthenticatedEffect(async () => {
    loadingStore.setLoading('checklists');
    if (practiceStore.id) {
      const checklistId = checklistStore.selectedChecklist?.id
        ? checklistStore.selectedChecklist?.id
        : (
          practiceStore.isSalesTaxPractice
            ? ComplianceChecklistEnums.Types.gstPeriodChecklist
            : ComplianceChecklistEnums.Types.monthlyChecklist
        );
      checklistStore.selectedChecklist = checklistId;
      checklistStore.selectedChecklistType = ComplianceChecklistEnums.Types[checklistId];

      if (rulesStore.rules?.get(practiceStore.id)?.has(checklistId)) {
        loadingStore.setDoneLoading('checklists');
      }

      rulesStore.retrieveRules(practiceStore.id, checklistId)
        .then(() => {
          loadingStore.setDoneLoading('checklists');
        });
    }
  }, [practiceStore.id, checklistStore.selectedChecklistType]);

  // Create the categories based on the constant
  const categoriesList = Object.keys(ComplianceChecklistEnums.Categories);
  const renderCategories = () => categoriesList.map((category) => (
    <div className='checklist__container' key={`checklist__container-${uuid()}`}>
      <div className='checklist__section__header'>
        <h2 className='checklist__section__header__title'>
          {localeStore.translation(`period-close.section-header.${category}`)}
        </h2>
        <CreateRuleButton
          businessId={practiceStore.id}
          sectionId={category}
          checklistId={checklistStore.selectedChecklist?.id}
          allowKeywords
        />
      </div>
      <PendingRuleBanner
        businessId={practiceStore.id}
        checklistId={checklistStore.selectedChecklist?.id}
        sectionId={category}
      />
      <div
        key={`insightDataSection-${category}`}
        className='checklist__section__data'
      >
        {
          rulesStore.sortedBusinessRules(practiceStore.id, checklistStore.selectedChecklistType, category).map((rule: PracticeRule) => (
            rule.section === category ? <CheckListTemplate key={`checklist-template-${uuid()}`} rule={rule} /> : <></>
          ))
        }
        {
          !rulesStore.businessHasRules(practiceStore.id, checklistStore.selectedChecklistType, category)
          && <NoRulesBanner categoryId={category} isPractice />
        }
      </div>
    </div>
  ));

  return !loadingStore.isLoading('checklists') && (
    <>
      <header className='sub-content__header'>
        <div className='wrapper'>
          <h2 className='sub-content__header--title'>{localeStore.translation('account-settings.checklist-templates.title')}</h2>
          <PeriodSelectionHeader simple />
        </div>
      </header>
      <Content className='sub-content__content practice-checklist scrollbar'>
        <div className='wrapper'>
          <div className='practice-checklist__introduction'>
            <Markdown>
              {localeStore.translation('account-settings.checklist-templates.checklist-introduction')}
            </Markdown>
          </div>
          {categoriesList && renderCategories()}
        </div>
      </Content>
      <Footer className='sub-content__footer practice-checklist__footer'>
        <ButtonPrimary
          loading={buttonLoading}
          size='large'
          onClick={async () => {
            try {
              // Need to send the request for recalculating to the insights module
              setButtonLoading(true);
              checklistStore.fireUpdatePracticeBusinessesChecklists(ComplianceChecklistEnums.Types[checklistStore.selectedChecklist?.type]);
              Notification({
                type: 'success',
                title: 'Client checklist update started',
                description: 'This may take some time. Changes will be applied to all checks that have NOT been customised at the client level.'
              });
            } catch (error) {
              handleError({ error, status: 'unknown_error', transaction: 'Apply Practice Rules', operation: 'handleClick' });
              Notification({
                type: 'error',
                title: 'Sorry, unable to start client checklist update',
                description: 'Please try again.'
              });
            }
            setButtonLoading(false);
          }}
          mixpanelEvent='Apply practice rules to clients'
        >
          {localeStore.translation('account-settings.checklist-templates.save-button')}
        </ButtonPrimary>
      </Footer>
    </>
  );
};

export default observer(ManageChecklists);
