import React from 'react';
import { AiderBrand } from '../icons';

const OnboardingHeader = () => (
  <header className='onboarding__header'>
    <div className='onboarding__header--content'>
      <p>WELCOME TO</p>
      <AiderBrand className='onboarding__header--logo' />
    </div>
  </header>
);

export default OnboardingHeader;
