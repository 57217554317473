import React from 'react';
import { observer } from 'mobx-react';
import ExternalLinkIcon from '../icons/ExternalLinkIcon';
import { useStore } from '../../stores/Store';
import NavLinkCustom from '../NavLinkCustom';
import { Routers } from '../../models/enums/utils';
import { AccountSettings } from '../../models/enums/components';

const AccountNavbar = () => {
  const { localeStore, subscriptionStore } = useStore();

  /**
   * Trigger function to direct to the appropriate stripe page
   * @param e
   */
  const handleSubscriptionClick = (e) => {
    e.preventDefault();
    subscriptionStore.paywallRedirect();
  };

  return (
    <nav className='navBar navBar-sub'>
      <NavLinkCustom
        to={Routers.SETTINGS}
        label={localeStore.translation('account-settings.user-profile.title')}
        trackPath={AccountSettings.USERRPROFILE}
        className='navBar-sub__item'
        isEnd
      />
      <NavLinkCustom
        to={Routers.ASSISTANT_SETTINGS}
        label={localeStore.translation('account-settings.assistant.title')}
        trackPath={AccountSettings.ASSISTANT}
        className='navBar-sub__item flex-center__vertical flex-space'
        isEnd
      />
      <NavLinkCustom
        to={Routers.FIRM}
        label={localeStore.translation('account-settings.firm.title')}
        trackPath={AccountSettings.FIRM}
        className='navBar-sub__item'
        isEnd
      />
      <NavLinkCustom
        to={Routers.BRAND}
        label={localeStore.translation('account-settings.brand.title')}
        trackPath={AccountSettings.BRAND}
        className='navBar-sub__item'
        isEnd
      />
      <NavLinkCustom
        to={Routers.ADVISORS}
        label={localeStore.translation('account-settings.advisors.title')}
        trackPath={AccountSettings.ADVISORS}
        className='navBar-sub__item'
        isEnd
      />
      <NavLinkCustom
        to={Routers.PERMISSIONS}
        label={localeStore.translation('account-settings.permissions.title')}
        trackPath={AccountSettings.PERMISSIONS}
        className='navBar-sub__item'
        isEnd
      />
      <NavLinkCustom
        to={Routers.CHECKLIST_TEMPLATES}
        label={localeStore.translation('account-settings.checklist-templates.title')}
        trackPath={AccountSettings.CHECKLIST_TEMPLATES}
        className='navBar-sub__item flex-center__vertical flex-space'
        isEnd
      />
      <a
        href={window.location.pathname}
        className='navBar-sub__item external'
        onClick={handleSubscriptionClick}
      >
        <span>{localeStore.translation('account-settings.subscription.title')}</span>
        <ExternalLinkIcon />
      </a>
    </nav>
  );
};

export default observer(AccountNavbar);
