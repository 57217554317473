import { Checkbox, Form } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import AiderAlert from '../AiderAlert';

const TermsCheckbox = () => {
  const { authenticationStore } = useStore();

  const updateTerms = (fields) => {
    authenticationStore.termsAccepted = fields[0].value;
  };

  return (
    <AiderAlert className='terms-checkbox' showIcon={false} type='info' ghost>
      <Form onFieldsChange={updateTerms}>
        <Form.Item name='terms' valuePropName='checked' className='signup__details--checkbox' initialValue={authenticationStore.termsAccepted}>
          <Checkbox>
            I have read and I agree to the <Link to='https://www.aider.ai/terms' target='_blank'>terms</Link> and the <Link to='https://www.aider.ai/privacy-policy/' target='_blank'>privacy policy</Link>
          </Checkbox>
        </Form.Item>
      </Form>
    </AiderAlert>
  );
};

export default observer(TermsCheckbox);
