import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Markdown from 'markdown-to-jsx';
import { Dropdown } from 'antd';
import Search from 'antd/es/input/Search';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import ButtonSecondary from '../../../components/customAntD/ButtonSecondary';
import Notification from '../../../components/Notification';
import GenAI from '../../../components/icons/GenAI';
import GenAIWhite from '../../../components/icons/GenAIWhite';
import { useStore } from '../../../stores/Store';
import ContentEditor from './ContentEditor';

const ActionPoints = () => {
  const rootStore = useStore();
  const { reportDownloadStore } = rootStore;
  const [generatingActionPoints, setGeneratingActionPoints] = useState(false);
  const { actionPoints } = reportDownloadStore;
  const [customPrompt, setCustomPrompt] = useState('');
  const [editingActionPoints, setEditingActionPoints] = useState(false);
  const [editedActionPoints, setEditedActionPoints] = useState(
    actionPoints
  );

  const generateActionPoints = (prompt) => {
    setGeneratingActionPoints(true);
    reportDownloadStore
      .generateActionPoints(prompt)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Action Points generated successfully',
          description: 'Your action points have been generated.',
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Action Points generation failed',
          description: 'Could not generate action points, please try again...',
        });
      })
      .finally(() => {
        setGeneratingActionPoints(false);
      });
  };

  let items = [
    {
      key: '1',
      label: (
        <ButtonSecondary
          shape='default'
          onClick={() => generateActionPoints(
            "Please provide three related action points for improvements, using line breaks and numbered list, based on the financial performance for a company in the <<lineOfBusiness>> industry in <<country>>. Don't provide a headline.\n Financial Performance:\n <<insights>>"
          )}
          mixpanelEvent='Generate Action Points'
          className='generative-button'
        >
          Generate Action Points
        </ButtonSecondary>
      ),
    },
  ];

  if (actionPoints) {
    items = [
      ...items,
      {
        key: '2',
        label: (
          <ButtonSecondary
            shape='default'
            onClick={() => generateActionPoints(
              `Simplify & rewrite the following action points in a style that my advisory client, a small business owner in the <<lineOfBusiness>> industry in <<country>> without a good understanding of accounting terminology, can understand. Avoid introductions and postcripst.\n\n Action Points:\n ${actionPoints}.\n Financial Performance:\n <<insights>>`
            )}
            mixpanelEvent='Rewrite Action Points'
            className='generative-button'
          >
            Rewrite in client's tone
          </ButtonSecondary>
        ),
      },
      {
        key: '3',
        label: (
          <>
            <Search
              value={customPrompt}
              onClick={(e) => e?.stopPropagation()}
              placeholder='Enter your prompt here'
              onSearch={() => {
                trackMixpanelEvent({
                  description: 'Rewrite Action Points',
                  rootStore,
                  properties: { customPrompt },
                });
                generateActionPoints(
                  `${customPrompt}\n\n Action Points:\n ${actionPoints}.\n Financial Performance:\n <<insights>>`
                );
                setCustomPrompt('');
              }}
              onChange={(e) => {
                setCustomPrompt(e.target.value);
              }}
              enterButton={<GenAIWhite />}
              className='generative-button'
            />
          </>
        ),
      },
    ];
  }

  const editActionPoints = () => {
    setEditedActionPoints(actionPoints);
    setEditingActionPoints(true);
  };

  const saveActionPoints = () => {
    setEditingActionPoints(false);
    reportDownloadStore.setActionPoints(editedActionPoints);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 className='report-content-heading3'>Action Points</h3>
        <div
          style={{
            display: 'flex',
            alignItems: 'right',
          }}
        >
          {editingActionPoints ? (
            <ButtonSecondary
              icon={<SaveOutlined />}
              onClick={saveActionPoints}
              shape='circle'
              size='large'
              style={{ marginRight: 10 }}
              mixpanelEvent='Save Action Points'
            />
          ) : (
            <ButtonSecondary
              disabled={generatingActionPoints}
              icon={<EditOutlined />}
              onClick={editActionPoints}
              shape='circle'
              size='large'
              style={{ marginRight: 10 }}
              mixpanelEvent='Edit Action Points'
            />
          )}
          <Dropdown
            disabled={generatingActionPoints || editingActionPoints}
            menu={{ items }}
            placement='bottomLeft'
            arrow
          >
            <ButtonSecondary
              shape='circle'
              size='large'
              icon={<GenAI />}
              loading={generatingActionPoints}
            />
          </Dropdown>
        </div>
      </div>
      {actionPoints && !editingActionPoints && (
        <Markdown>{actionPoints}</Markdown>
      )}
      {editingActionPoints && (
        <ContentEditor
          content={actionPoints}
          onChange={(content) => {
            setEditedActionPoints(content);
          }}
        />
      )}
    </>
  );
};

export default observer(ActionPoints);
