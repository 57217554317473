import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';

/**
 * Icon of a circle with a plus sign inside it
 * @param props
 * @returns
 */
export const CirclePlus = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  strokeWidth = 1.5,
  fill = 'none',
  lineCap = 'round',
  padding = 0.33,
  ...rest
}: IconInterface) => {
  const { min, max } = getPlotExtremes({ width, height, padding });
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      stroke={color}
      strokeWidth={strokeWidth}
      viewBox={`0 0 ${width} ${height}`}
      strokeLinecap={lineCap}
      fill={fill}
      {...rest}
    >
      <circle cx={width / 2} cy={height / 2} r={width / 2 - strokeWidth} />
      <line x1={min.x} y1={height / 2} x2={max.x} y2={height / 2} strokeLinecap={lineCap} />
      <line x1={width / 2} y1={max.y} x2={width / 2} y2={min.y} strokeLinecap={lineCap} />
    </svg>
  );
};
