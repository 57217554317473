import React from 'react';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';

const HeaderActions = ({
  block,
  className
} : {
  block: PracticeTypes.ReportBlock,
  className: string
}) => {
  const blockId = block.id;
  const { reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === blockId;

  return (
    <aside className={`${className} ${className}--${block.type}`}>
      {!isEditing && (
        <ButtonSecondary
          className='borderless edit-button'
          icon={<EditOutlined />}
          mixpanelEvent='Report Editor - Edit Header'
          mixpanelProperties={{ editBy: 'Action Button' }}
          onClick={() => { reportTemplateStore.editBlock = blockId; }}
        />
      )}
      {isEditing && (
        <ButtonSecondary
          className='borderless save-button'
          icon={<CheckCircleOutlined />}
          mixpanelEvent='Report Editor - Save Header'
          onClick={() => {
            reportTemplateStore.editBlock = null;
          }}
        />
      )}
    </aside>
  );
};

export default observer(HeaderActions);
