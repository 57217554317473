import React, { useEffect } from 'react';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { useStore } from '../../stores/Store';
import { useAuthenticatedEffect } from '../../lib/customHooks/useAuthenticatedEffect';
import EditorRow from './EditorRow';
import ReportTemplateSelector from './ReportTemplateSelector';
import ReportTemplateFooter from './ReportTemplateFooter';
import ComponentRow from './ComponentRow';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import usePrevious from '../../lib/customHooks/usePrevious';

export enum PeriodMap {
  monthlyPeriods = 'monthly',
  quarterlyPeriods = 'quarterly',
  gstPeriods = 'gst',
}

const PerformanceReportV2 = () => {
  const rootStore = useStore();
  const { businessesStore, localeStore, practiceStore, reportTemplateStore } = rootStore;
  const [granularity, setGranularity] = React.useState('monthlyPeriods');
  const prevGranularity = usePrevious(granularity);
  const [period, setPeriod] = React.useState(0);
  const prevPeriod = usePrevious(period);

  useAuthenticatedEffect(() => {
    reportTemplateStore.fetchPerformanceReportTemplates();
  }, [practiceStore.id]);

  useEffect(() => {
    if (prevGranularity !== granularity || prevPeriod !== period) {
      const selectedPeriod = businessesStore?.selectedBusinessPeriods?.[granularity]?.[period];
      reportTemplateStore.selectedPeriodData = {
        start: selectedPeriod?.periodStart,
        end: selectedPeriod?.periodEnd,
        name: selectedPeriod?.name,
        granularity: PeriodMap[granularity],
      };

      if (businessesStore.initialFetchComplete && businessesStore.selectedBusiness) {
        reportTemplateStore.fetchSelectedPeriodVariables();
      }
    }
  }, [granularity, period, businessesStore.initialFetchComplete, businessesStore.selectedBusiness]);

  const onGranularityChange = (value) => {
    trackMixpanelEvent({ description: 'Report Editor - Change Granularity', properties: { granularity }, rootStore });
    setGranularity(value);
    setPeriod(0);
  };

  const onPeriodChange = (value) => {
    trackMixpanelEvent({ description: 'Report Editor - Change Period', properties: { period }, rootStore });
    setPeriod(value);
  };

  return (
    <section className='report-editor'>
      <header className='report-editor__header'>
        <div className='periodSelecterHeader__block'>
          <h3 className='report-editor__header-text'>Profitability Period</h3>
          <Select
            className='report-editor__granularity-dropdown'
            defaultValue='monthlyPeriods'
            onChange={onGranularityChange}
            size='large'
            options={[
              { label: localeStore.translation('report-template-editor.granularity.monthly'), value: 'monthlyPeriods' },
              { label: localeStore.translation('report-template-editor.granularity.quarterly'), value: 'quarterlyPeriods' },
              { label: localeStore.translation('report-template-editor.granularity.salesTax'), value: 'gstPeriods' },
            ]}
            value={granularity}
          />
          <Select
            onChange={onPeriodChange}
            size='large'
            options={businessesStore?.selectedBusinessPeriods?.[granularity]?.map((periodValue, periodInx) => ({
              label: periodValue.name,
              value: periodInx,
            }))}
            value={period}
          />
        </div>
        {reportTemplateStore.reportTemplateSelectionList.length > 0 && (
          <div className='periodSelecterHeader__block report-editor__header-template'>
            <h3 className='report-editor__header-text'>Template</h3>
            <ReportTemplateSelector className='report-editor__header-template-selector' />
          </div>
        )}
      </header>
      <div className='report-editor__content scrollbar'>
        {reportTemplateStore.selectedPerformanceReportBlocks.map((
          block: PracticeTypes.ReportBlock,
          index: number,
          src: PracticeTypes.ReportBlock[]
        ) => {
          const blockInstance = src.filter((b, i) => b.type === block.type && i <= index).length;
          return (
            <React.Fragment key={`report-row-${block?.id}`}>
              {block.type === 'page' && block.position > 0 && (
                <ComponentRow
                  key={`component-row_${block.id}`}
                  block={block}
                />
              )}
              <EditorRow
                key={block.id}
                block={block}
                blockInstance={blockInstance}
              />
            </React.Fragment>
          );
        })}
        {reportTemplateStore.selectedPerformanceReportBlocks.length > 0 && (
          <ComponentRow
            key='component-row_last'
            block={{ type: null, id: null, position: null }}
          />
        )}
        <EditorRow
          block={{ type: 'page', id: null, position: null }}
          blockInstance={reportTemplateStore.selectedPerformanceReportPageCount + 1}
        />
      </div>
      <ReportTemplateFooter />
    </section>
  );
};

export default observer(PerformanceReportV2);
