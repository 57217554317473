import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';
import LoadingSpinner from './LoadingSpinner';

const GlobalLoader = () => {
  const { loadingStore } = useStore();
  const [patience, setPatience] = React.useState(null);
  const [showLongRun, setShowLongRun] = React.useState(false);

  let timeout;

  React.useEffect(() => {
    if (loadingStore.isAnyLoading) {
      if (patience !== loadingStore.loading[0]) {
        clearTimeout(timeout);
        setShowLongRun(false);
        setPatience(loadingStore.loading[0]);
        timeout = setTimeout(() => {
          setShowLongRun(true);
        }, 3000);
      }
    } else {
      clearTimeout(timeout);
      setPatience(null);
      setShowLongRun(false);
    }
    return () => {
      clearTimeout(timeout);
      setShowLongRun(false);
    };
  }, [loadingStore.loading, loadingStore.isAnyLoading]);

  return loadingStore.loading.length > 0 && (
    <div className='global-loader'>
      <LoadingSpinner branded />
      {loadingStore.isAnyLoading && <h1 className='loading-spinner__overlay'>{loadingStore.loading[0]}</h1> }
      {loadingStore.isAnyLoading
        && showLongRun
        && <p className=''>This seems to be taking longer than expected, please do not refresh your page...</p>}
    </div>
  );
};

export default observer(GlobalLoader);
