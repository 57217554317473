import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const finalSvg = () => (
  <svg width='1em' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>Finalised</title>
    <rect x='1.0332' y='0.5' width='19' height='19' rx='9.5' fill='currentColor' />
    <path
      d='M8.60454 15.112C8.31521 15.112 8.08654 15.0327 7.91854 14.874C7.75987 14.706 7.68054 14.468 7.68054 14.16V6.082C7.68054 5.774 7.75987 5.54067 7.91854 5.382C8.08654 5.214 8.32454 5.13 8.63254 5.13H13.5185C13.7612 5.13 13.9432 5.19067 14.0645 5.312C14.1859 5.43333 14.2465 5.606 14.2465 5.83C14.2465 6.06333 14.1859 6.24533 14.0645 6.376C13.9432 6.49733 13.7612 6.558 13.5185 6.558H9.47254V9.344H13.2245C13.4579 9.344 13.6352 9.40467 13.7565 9.526C13.8872 9.64733 13.9525 9.82 13.9525 10.044C13.9525 10.2773 13.8872 10.4593 13.7565 10.59C13.6352 10.7113 13.4579 10.772 13.2245 10.772H9.47254V14.16C9.47254 14.7947 9.18321 15.112 8.60454 15.112Z'
      fill='white'
    />
  </svg>

);

const FinalOutlined = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={finalSvg} {...props} />
);

export default FinalOutlined;
