import { SUPPORT_EMAIL } from '../models/constants/root';
import { IMailToHrefProps } from '../models/interfaces/lib';

/**
 * Create a mailto href string
 * @param email - The email address to send the email to (if not provided, defaults to support email)
 * @param subject - The email subject
 * @param body - The email body with newlines entered as `\n`
 * @param technicalDetails - An array of objects containing technical details to be included in the email
 * @param rootStore - The root store
 * @param includeAdditionalDetails - Whether to include additional details in the email (defaults to true)
 *
 * @example
 * ```ts
 * // Default Usage
 * const subject = 'I have a question';
 * const body = 'I have a question about the app\n\nPlease help';
 * const mailto = mailToHref({ subject, body, rootStore });
 * ```
 * @returns The mailto href string with basic technical details included
 * ```ts
 * `mailto:success@aider.ai
 *    ?subject=Aider: I%20have%20a%20question
 *    &body=I%20have%20a%20question%20about%20the%20app%0D%0APlease%20help%0D%0A%0D%0A
 *    =====================================================%0D%0A
 *    Additional%20Details:%0D%0A
 *    =====================================================%0D%0A
 *    Display%20Name:%20testuser%0D%0A
 *    Full%20Name:%20Test%20User%0D%0A
 *    User%20Email:%20test@user.com%0D%0A
 *    Practice%20Name:%20Test%20Business%0D%0A
 *    Timezone:%20Pacific/Auckland%0D%0A
 *    Time:%207/4/2023,%2011:36:09%20AM%0D%0A
 *    Browser:%20Mozilla/5.0%20(win32)%20AppleWebKit/537.36%20(KHTML,%20like%20Gecko)%20jsdom/11.12.0%0D%0A`
 * ```
 */
export const mailToHref = ({
  email = null,
  subject,
  body,
  rootStore,
  technicalDetails = null,
  includeAdditionalDetails = true
}: IMailToHrefProps) => {
  /** Create the email copy */
  const errorSubject = `Aider: ${subject}`;
  let errorBody = `${body}\n\n`;

  if (technicalDetails) {
    errorBody += `${'=====================================================\n'
    + 'Technical Details:\n'
    + '=====================================================\n'}`;

    technicalDetails.forEach((detail) => {
      errorBody += `${detail.key}: ${detail.value}\n`;
    });
  }

  if (includeAdditionalDetails) {
    errorBody += `${'=====================================================\n'
    + 'Additional Details:\n'
    + '=====================================================\n'}`;
    if (rootStore.userStore.username) errorBody += `Display Name: ${rootStore.userStore.username}\n`;
    if (rootStore.userStore.givenName) errorBody += `Full Name: ${rootStore.userStore.givenName} ${rootStore.userStore.familyName}\n`;
    if (rootStore.userStore.email) errorBody += `User Email: ${rootStore.userStore.email}\n`;
    if (rootStore.practiceStore.name) errorBody += `Practice Name: ${rootStore.practiceStore.name}\n`;
    errorBody += `${`Timezone: ${Intl.DateTimeFormat().resolvedOptions().timeZone}\n`
      + `Time: ${new Date().toLocaleString()}\n`
      + `Browser: ${navigator.userAgent}\n`}`;
  }

  /** Format the email copy so able to be parsed by url */
  const formattedSubject = errorSubject.replace(/\s/g, '%20');
  const formattedBody = errorBody.replace(/\n/g, '%0D%0A').replace(/\s/g, '%20');

  /** Return formatted mailto string */
  return `mailto:${email || SUPPORT_EMAIL}?subject=${formattedSubject}&body=${formattedBody}`;
};
