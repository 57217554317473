import * as React from 'react';
import { AiderEarlyWarning } from '../../atoms/Icons';
import { Background, Brand } from '../../../ts/enums/Colors';
import { EstimateConfidenceBannerProps } from '../../../ts/interfaces/molecules/EarlyWarning';
import { rootStore } from '../../../stores/Store';
import { HelpTitle, selectIndex } from '../../../entities/helpText';

export const EstimateConfidenceBanner = (
  { confidence, insightKey }: EstimateConfidenceBannerProps
) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
  <aside
    style={{
      display: 'flex',
      alignContent: 'flex-start',
      borderRadius: '0.5em',
      backgroundColor: Background.blue,
      fontSize: '1rem',
      fontWeight: 400,
      color: Brand.blue,
      padding: '0.6rem',
      gap: '0.5rem',
      margin: '0.5rem 0',
      cursor: 'pointer',
    }}
    onClick={() => {
      rootStore.helpPanelStore.setHelpItemVisible(HelpTitle.EWS);
      selectIndex(insightKey, rootStore);
    }}
  >
    <AiderEarlyWarning />
    <div
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0.4rem 0', gap: '0.2rem' }}

    >
      <h3 style={{ fontSize: '1.1rem', fontWeight: 700, margin: 0 }}>
        Aider <span style={{ color: Brand.darkTeal }}>Early Warning System</span>
      </h3>
      {confidence && (
        <p style={{ margin: 0, padding: 0 }}>
          Estimates confidence: <span style={{ fontWeight: 600 }}>{confidence}</span>
        </p>
      )}
    </div>
  </aside>
);
