import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const warningSvg = () => (
  <svg height='1em' viewBox='0 0 120 121' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_56_4726)'>
      <circle cx='60' cy='60.2014' r='60' fill='#F5F5F9' />
      <path d='M86.7615 85.5767C89.0451 85.5767 90.4914 83.1269 89.3883 81.1274L62.4697 32.3376C61.329 30.2699 58.3571 30.2699 57.2163 32.3376L30.2978 81.1274C29.1946 83.1269 30.6409 85.5767 32.9245 85.5767L86.7615 85.5767Z' fill='white' />
      <path d='M91.8427 84.5757C91.8427 84.9277 91.6567 85.269 91.3271 85.4516C90.8437 85.7192 90.2353 85.5439 89.9677 85.061L59.8427 30.6416L29.7177 85.061C29.4502 85.5439 28.8418 85.7192 28.3584 85.4516C27.8759 85.1841 27.7011 84.5752 27.9677 84.0923L58.9677 28.0923C59.144 27.7739 59.479 27.5766 59.8427 27.5766C60.2065 27.5766 60.5415 27.7739 60.7177 28.0923L91.7177 84.0923C91.8027 84.2456 91.8427 84.4121 91.8427 84.5757Z' fill='#EB5757' />
      <path d='M91.843 84.5767C91.843 85.1289 91.3953 85.5767 90.843 85.5767L49.843 85.5767C49.2908 85.5767 48.843 85.1289 48.843 84.5767C48.843 84.0244 49.2908 83.5767 49.843 83.5767L90.843 83.5767C91.3953 83.5767 91.843 84.0244 91.843 84.5767Z' fill='#EB5757' />
      <path d='M43.843 84.5767C43.843 85.1289 43.3953 85.5767 42.843 85.5767L28.843 85.5767C28.2908 85.5767 27.843 85.1289 27.843 84.5767C27.843 84.0244 28.2908 83.5767 28.843 83.5767L42.843 83.5767C43.3953 83.5767 43.843 84.0244 43.843 84.5767Z' fill='#EB5757' />
      <path d='M47.8433 84.5767C47.8433 84.8369 47.7329 85.0967 47.5532 85.2866C47.3633 85.4668 47.103 85.5767 46.8433 85.5767C46.583 85.5767 46.3232 85.4668 46.1333 85.2866C45.7632 84.917 45.7632 84.2368 46.1333 83.8667C46.3232 83.687 46.583 83.5767 46.8433 83.5767C47.103 83.5767 47.3633 83.687 47.5532 83.8667C47.7329 84.0566 47.8433 84.3169 47.8433 84.5767Z' fill='#EB5757' />
      <path d='M84.8433 80.5767C84.8433 80.8369 84.7329 81.0967 84.5532 81.2866C84.3633 81.4668 84.103 81.5767 83.8433 81.5767C83.583 81.5767 83.3232 81.4668 83.1333 81.2866C82.7632 80.9067 82.7632 80.2471 83.1333 79.8667C83.3232 79.687 83.583 79.5767 83.8433 79.5767C84.103 79.5767 84.3633 79.687 84.5532 79.8667C84.7329 80.0566 84.8433 80.3169 84.8433 80.5767Z' fill='#EB5757' />
      <path d='M80.8433 80.5767C80.8433 80.8369 80.7329 81.0967 80.5532 81.2866C80.3633 81.4668 80.103 81.5767 79.8433 81.5767C79.583 81.5767 79.3232 81.4668 79.1333 81.2866C78.7632 80.9067 78.7632 80.2368 79.1333 79.8667C79.3232 79.687 79.583 79.5767 79.8433 79.5767C80.103 79.5767 80.3633 79.687 80.5532 79.8667C80.7329 80.0566 80.8433 80.3169 80.8433 80.5767Z' fill='#EB5757' />
      <path d='M76.8433 80.5767C76.8433 80.8369 76.7329 81.0967 76.5532 81.2866C76.3633 81.4668 76.103 81.5767 75.8433 81.5767C75.583 81.5767 75.3232 81.4668 75.1333 81.2866C74.7632 80.9067 74.7632 80.2471 75.1333 79.8667C75.3232 79.687 75.583 79.5767 75.8433 79.5767C76.103 79.5767 76.3633 79.687 76.5532 79.8667C76.7329 80.0566 76.8433 80.3169 76.8433 80.5767Z' fill='#EB5757' />
      <path d='M80.8433 76.5767C80.8433 76.8369 80.7329 77.0967 80.5532 77.2866C80.3633 77.4668 80.103 77.5767 79.8433 77.5767C79.583 77.5767 79.3232 77.4668 79.1333 77.2866C78.7632 76.9067 78.7632 76.2368 79.1333 75.8667C79.3232 75.687 79.583 75.5767 79.8433 75.5767C80.1133 75.5767 80.3633 75.687 80.5532 75.8667C80.7329 76.0566 80.8433 76.3066 80.8433 76.5767Z' fill='#EB5757' />
      <path d='M76.8433 76.5767C76.8433 76.8369 76.7329 77.0967 76.5532 77.2866C76.3633 77.4668 76.103 77.5767 75.8433 77.5767C75.583 77.5767 75.3232 77.4668 75.1333 77.2866C74.7632 76.9067 74.7632 76.2368 75.1333 75.8667C75.3232 75.687 75.583 75.5767 75.8433 75.5767C76.103 75.5767 76.3633 75.687 76.5532 75.8667C76.7329 76.0566 76.8433 76.3169 76.8433 76.5767Z' fill='#EB5757' />
      <path d='M80.8433 72.5767C80.8433 72.8369 80.7329 73.0967 80.5532 73.2866C80.3633 73.4668 80.1133 73.5767 79.8433 73.5767C79.583 73.5767 79.3232 73.4668 79.1333 73.2866C78.7632 72.917 78.7632 72.2368 79.1333 71.8667C79.3232 71.687 79.583 71.5767 79.8433 71.5767C80.103 71.5767 80.3633 71.687 80.5532 71.8667C80.7329 72.0566 80.8433 72.3169 80.8433 72.5767Z' fill='#EB5757' />
      <path d='M76.8433 68.5767C76.8433 68.8369 76.7329 69.0967 76.5532 69.2866C76.3633 69.4668 76.103 69.5767 75.8433 69.5767C75.583 69.5767 75.3232 69.4668 75.1333 69.2866C74.7632 68.917 74.7632 68.2471 75.1333 67.8667C75.3232 67.687 75.583 67.5767 75.8433 67.5767C76.103 67.5767 76.3633 67.687 76.5532 67.8667C76.7329 68.0566 76.8433 68.3169 76.8433 68.5767Z' fill='#EB5757' />
      <path d='M59.843 46.5764L59.843 68.5765' stroke='#EB5757' strokeWidth='2' strokeLinecap='round' />
      <path d='M59.843 73.5767V74.5765' stroke='#EB5757' strokeWidth='2.5' strokeLinecap='round' />
    </g>
    <defs>
      <clipPath id='clip0_56_4726'>
        <rect width='120' height='120' fill='white' transform='translate(0 0.201416)' />
      </clipPath>
    </defs>
  </svg>
);

const WarningIllustration = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={warningSvg} {...props} />
);

export default WarningIllustration;
