import React from 'react';
import { CaretDownFilled, CaretUpFilled, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { DashboardSortDirection, DashboardSortTarget } from '../../models/enums/components';

type ComplianceTableNameSortType = {
  activeTarget: DashboardSortTarget,
  activeOrder: DashboardSortDirection,
  setSort: Function,
}

const PeriodCloseTableNameSort = (
  { activeTarget, activeOrder, setSort } : ComplianceTableNameSortType
) => (
  <div className='period-close__tooltip-wrapper period-close__tooltip-wrapper--header'>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.name && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('name', DashboardSortTarget.name, DashboardSortDirection.ascending)}
      icon={<SortAscendingOutlined />}
      mixpanelEvent='Period Close Dashboard - Sort'
      mixpanelProperties={{
        column: 'Business Name',
        sortKey: 'Name',
        order: 'Ascending'
      }}
    >
      Name A-Z
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.name && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('name', DashboardSortTarget.name, DashboardSortDirection.descending)}
      icon={<SortDescendingOutlined />}
      mixpanelEvent='Period Close Dashboard - Sort'
      mixpanelProperties={{
        column: 'Business Name',
        sortKey: 'Name',
        order: 'Descending'
      }}
    >
      Name Z-A
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.updated && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('name', DashboardSortTarget.updated, DashboardSortDirection.ascending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled className='active' />
          <CaretDownFilled />
        </div>
      )}
      mixpanelEvent='Period Close Dashboard - Sort'
      mixpanelProperties={{
        column: 'Business Name',
        sortKey: 'Updated At',
        order: 'Ascending'
      }}
    >
      Sync Date Oldest-Newest
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeTarget === DashboardSortTarget.updated && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort('name', DashboardSortTarget.updated, DashboardSortDirection.descending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled />
          <CaretDownFilled className='active' />
        </div>
      )}
      mixpanelEvent='Period Close Dashboard - Sort'
      mixpanelProperties={{
        column: 'Business Name',
        sortKey: 'Updated At',
        order: 'Descending'
      }}
    >
      Sync Date Newest-Oldest
    </ButtonSecondary>
  </div>
);

export default PeriodCloseTableNameSort;
