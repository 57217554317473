import React from 'react';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { DashboardSortDirection, DashboardSortTarget } from '../../models/enums/components';

type ComplianceTableSortType = {
  activeColumn: ComplianceChecklistEnums.CategoryKeys | 'name' | 'checklistStatus',
  activeTarget: DashboardSortTarget,
  activeOrder: DashboardSortDirection,
  setSort: Function,
  column: ComplianceChecklistEnums.CategoryKeys | 'name' | 'checklistStatus',
}

const PeriodCloseTableSort = ({
  activeColumn,
  activeTarget,
  activeOrder,
  setSort,
  column
} : ComplianceTableSortType) => (
  <div className='period-close__tooltip-wrapper period-close__tooltip-wrapper--header'>
    <ButtonSecondary
      className={`${activeColumn === column && activeTarget === DashboardSortTarget.active && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort(column, DashboardSortTarget.active, DashboardSortDirection.ascending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled className='active' />
          <CaretDownFilled />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column,
        sortKey: 'Active Alerts',
        order: 'Ascending'
      }}
    >
      Outstanding Alerts Small-Large
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeColumn === column && activeTarget === DashboardSortTarget.active && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort(column, DashboardSortTarget.active, DashboardSortDirection.descending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled />
          <CaretDownFilled className='active' />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column,
        sortKey: 'Active Alerts',
        order: 'Descending'
      }}
    >
      Outstanding Alerts Large-Small
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeColumn === column && activeTarget === DashboardSortTarget.reviewed && activeOrder === DashboardSortDirection.ascending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort(column, DashboardSortTarget.reviewed, DashboardSortDirection.ascending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled className='active' />
          <CaretDownFilled />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column,
        sortKey: 'reviewed Alerts',
        order: 'Ascending'
      }}
    >
      Reviewed Alerts Small-Large
    </ButtonSecondary>
    <ButtonSecondary
      className={`${activeColumn === column && activeTarget === DashboardSortTarget.reviewed && activeOrder === DashboardSortDirection.descending ? 'active' : ''}`}
      shape='default'
      onClick={() => setSort(column, DashboardSortTarget.reviewed, DashboardSortDirection.descending)}
      icon={(
        <div className='dashboard-subheading__sorter'>
          <CaretUpFilled />
          <CaretDownFilled className='active' />
        </div>
      )}
      mixpanelEvent='Compliance Dashboard - Sort'
      mixpanelProperties={{
        column,
        sortKey: 'reviewed Alerts',
        order: 'Descending'
      }}
    >
      Reviewed Alerts Large-Small
    </ButtonSecondary>
  </div>
);

export default PeriodCloseTableSort;
