import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';
import ButtonSecondary from './customAntD/ButtonSecondary';
import { trackMixpanelEvent } from '../lib/mixpanel';

const TrialBanner = () => {
  const rootStore = useStore();
  const { practiceStore, subscriptionStore } = rootStore;
  const [redirecting, setRedirecting] = React.useState(false);

  return (
    practiceStore?.subscription?.type === 'trial' && practiceStore?.subscription?.trialDaysLeft >= 1 ? (
      <div className='trial-banner'>
        <div className='trial-banner__contents'>
          <div className='trial-banner__contents__left'>
            <h4>{practiceStore?.subscription?.trialDaysLeft || 0} days left in your firms's full feature free trial.</h4>
            <ButtonSecondary
              size='large'
              onClick={async () => {
                setRedirecting(true);
                subscriptionStore.paywallRedirect();
              }}
              className='trial-banner__contents__button'
              loading={redirecting}
            >Subscribe now
            </ButtonSecondary>
          </div>
          <div>
            <a
              href='https://ask.aider.ai/help/faqs'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => trackMixpanelEvent({
                description: 'Navigate to Subscription FAQ',
                rootStore
              })}
            >
              Subscription FAQs <RightOutlined />
            </a>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default observer(TrialBanner);
