import React, { useCallback, useEffect } from 'react';
import packageJson from '../../package.json';
import handleError from '../lib/errorHandler';

const AuthHandler = ({ rootStore }) => {
  const { authenticationStore } = rootStore;
  const [pending, setPending] = React.useState(false);
  const checkCache = () => {
    const version = localStorage.getItem('version');
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload();
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  };

  const authenticate = useCallback(async () => {
    try {
      if (!authenticationStore.performedInitialAuthCheck) {
        checkCache();
        rootStore.authStore.authService.initializeAuthentication();
      }
    } catch (e) {
      handleError({ error: e, status: 'unknown_error', transaction: 'App', operation: 'useEffect' });
    }
    authenticationStore.performedInitialAuthCheck = true;
    setPending(false);
  }, [authenticationStore.accessToken]);

  useEffect(() => {
    if (!pending) {
      setPending(true);
      authenticate();
    }
  }, [rootStore.authStore.authService.firebaseApp, rootStore.authStore.authService]);

  return <></>;
};

export default AuthHandler;
