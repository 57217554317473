import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLink from '../atoms/ButtonLink';
import tierPageLogo from '../../entities/icons/tierPageLogo.svg';
import tierContactThanks from '../../entities/icons/tierContactThanks.svg';
/**
 * ContactThankYouPage Page.
 *
 * This page is used to display confirmation that a customised plan will be updated:
 *
 * @state: How many clients are permitted to be added in the current Tier?
 * @action: Open a Modal to show no clients was added
 */
const ContactThankYouPage: React.FC = () => {
  // Handle Modal toggle
  const navigate = useNavigate();
  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/insights');
  };

  return (
    <div className='contact__thank'>
      <img src={tierPageLogo} alt='Thank you for your request to upgrade to a Bespoke Plan' />

      <div className='contact__thank--wrapper'>
        <img src={tierContactThanks} alt='Thank you for your request to upgrade to a Bespoke Plan' />
        <h3 className='contact__title'>
          Thank you for your request to upgrade to a Bespoke Plan
        </h3>
        <p className='contact__subTitle'>
          Aider&apos;s Customer Success team will get in touch within 24 hours.
        </p>
        <ButtonLink
          key='button__Email-Ok'
          className='blue-button'
          type='submit'
          text='Ok'
          onClick={handleOnClick}
        />
      </div>

    </div>
  );
};

export default ContactThankYouPage;
