import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { PeriodTypes } from '@aider/aider-period-library';
import { InsightType } from '../ts/enums/Constants';
import { useStore } from '../stores/Store';

const appliedToInsights = [InsightType.PROFITABILITY, InsightType.REPORT];

export interface IPeriodGranularityProps {
  id?: string;
  className?: string;
  type: InsightType;
  yearly?: boolean
}

const PeriodGranularitySelectComponent = ({ type, id = '', className = '', yearly = false }: IPeriodGranularityProps) => {
  const { timePeriodStore, insightStore } = useStore();

  const onChangeHandler = (newPeriod) => {
    const currentPeriod = timePeriodStore.periodGranularity;
    timePeriodStore.setPeriodGranularity(newPeriod);
    timePeriodStore.getInsightOptions(insightStore?.insightData);
    let period = timePeriodStore.profitabilityPeriods[0]?.value;
    if (!period) {
      // There was no data for selected period
      timePeriodStore.setPeriodGranularity(currentPeriod);
      timePeriodStore.getInsightOptions(insightStore?.insightData);
      period = timePeriodStore.profitabilityPeriods[0]?.value;
    }
    timePeriodStore.setProfitabilityPeriodSelected(period);
  };

  const options = [
    { value: PeriodTypes.MONTHLY, label: 'Monthly' }
  ];

  if (insightStore?.insightData?.find((insight) => insight.quarters?.length)) {
    options.push({ value: PeriodTypes.QUARTERLY, label: 'Quarterly' });
  }

  if (yearly) {
    options.push({ value: PeriodTypes.YEARLY, label: 'Financial Year' });
  } else if (
    !yearly
    && timePeriodStore.periodGranularity === PeriodTypes.YEARLY
  ) {
    onChangeHandler(PeriodTypes.MONTHLY);
  }

  if (!appliedToInsights.includes(type)) {
    return null;
  }

  return (
    <Select
      id={id}
      className={`profitability-period-dropdown ${className}`}
      defaultValue={timePeriodStore.periodGranularity}
      onChange={onChangeHandler}
      options={options}
      size='large'
      value={timePeriodStore.periodGranularity}
    />
  );
};

export default observer(PeriodGranularitySelectComponent);
