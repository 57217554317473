import React from 'react';
import { observer } from 'mobx-react';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import { useStore } from '../stores/Store';
import { AiderBrand } from '../components/icons';
import SocialButtons from '../components/signin/SocialButtons';
import ButtonPrimary from '../components/customAntD/ButtonPrimary';
import { Routers } from '../models/enums/utils';
import Root from '../components/Root';
import AiderAlert from '../components/AiderAlert';
import { CookieKeys } from '../ts/enums/Constants';

const Login = () => {
  const { loadingStore, authenticationStore, localeStore } = useStore();
  const navigate = useNavigate();

  const sessionTimedOut = cookie.get(CookieKeys.sessionTimedOut);

  return (
    <>
      <Root />
      {!loadingStore.isAnyLoading && (
        <main className='login-page wrapper wrapper-xs'>
          <header className='login-page__header'>
            <AiderBrand />
          </header>
          {sessionTimedOut
            && (
            <AiderAlert
              className='session-timeout__alert'
              title='You have been logged out due to inactivity'
            >
              <p>{`For security reasons, ${localeStore.translation('osps.xero')} and ${localeStore.translation('osps.intuit')} require us to log you out after 15 minutes of inactivity. Please log in again.`}</p>
            </AiderAlert>
            )}
          <SocialButtons label='Log in with' />
          <section className='login-page__links'>
            <p className='login-page__links__link'>
              <span className='login-page__links__link__text'>New to Aider?</span>
              <ButtonPrimary
                icon={<RightOutlined />}
                type='link'
                onClick={() => {
                  authenticationStore.loginError = false;
                  navigate(Routers.SIGNUP);
                }}
              >Create an account for your firm
              </ButtonPrimary>
            </p>
            <p className='login-page__links__link'>
              <span className='login-page__links__link__text'>Need access to your firm's account?</span>
              <ButtonPrimary
                icon={<RightOutlined />}
                type='link'
                href='https://ask.aider.ai/help/get-started-advisory-platform#Q2'
                target='_blank'
              >Find out how
              </ButtonPrimary>
            </p>
          </section>

        </main>
      )}
    </>
  );
};

export default observer(Login);
