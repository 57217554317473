import React from 'react';
import LoadingSpinner from '../LoadingSpinner';

const LoadingRow = () => (
  <div className='dashboard-table__body--row loading'>
    <LoadingSpinner />
  </div>
);

export default LoadingRow;
