import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import FirmInformationForm from '../form/FirmInformationForm';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import { useStore } from '../../stores/Store';
import LoadingSpinner from '../LoadingSpinner';

const OnboardingFirmBody = () => {
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const formOffsetCols = 7;

  const { authStore } = useStore();

  return (
    !authStore.isLoading && authStore.user ? (
      <div className='onboarding__body wrapper'>
        <FirmInformationForm
          form={form}
          saving={saving}
          setSaving={setSaving}
          successAction={() => {
            // Use a hard direct to ensure stores are initialised correctly
            window.location.href = '/';
          }}
          formOffsetCols={formOffsetCols}
          isNewPractice
        />
        <Row>
          <Col xs={0} sm={formOffsetCols} />
          <Col>
            <ButtonPrimary
              type='primary'
              htmlType='submit'
              onClick={form.submit}
              shape='round'
              size='large'
              loading={saving}
            >
              {saving ? 'Saving' : 'Save'}
            </ButtonPrimary>
          </Col>
        </Row>
      </div>
    ) : <LoadingSpinner branded />
  );
};

export default observer(OnboardingFirmBody);
