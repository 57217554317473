export function renderTooltipActionItems(
  context: {
    dataIndex: string | number;
  },
  insightData: {
    summaries: {
      [x: string]: {
        datapoints: any[];
      };
    };
  }
) {
  return (
    insightData.summaries[context.dataIndex].datapoints
          && insightData.summaries[context.dataIndex].datapoints.map((dp, ix) => {
            if (ix > 0) {
              return `${dp.label}: ${dp.formattedValue} \n `;
            }

            return '';
          })
  );
}

export function renderTooltipActionItemsFromResponse(
  context: {
    dataIndex: string | number;
  },
  insightData: {
    summaries: {
      [x: string]: {
        tooltips: any[];
      };
    };
  }
) {
  return insightData.summaries[context.dataIndex].tooltips.map((dp, ix) => {
    if (ix) {
      return `${dp} \n `;
    }

    return '';
  });
}
