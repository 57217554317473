import React, { useState } from 'react';
import { Form, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useStore, rootStore } from '../../stores/Store';
import { IManageAdvisorsProps } from '../../models/interfaces/components';
import RequiredInput from '../form/RequiredInput';
import Notification from '../Notification';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const ManageAdvisorsFooter = () => {
  const { advisorsStore, localeStore } = useStore();
  const { inviteAdvisor } = advisorsStore;
  const [saving, setSaving] = useState<boolean>(false);

  const [form] = Form.useForm();
  const onFinish = async (
    values: Pick<IManageAdvisorsProps, 'givenName' | 'familyName' | 'email'>
  ) => {
    trackMixpanelEvent({
      description: 'Invite Advisor',
      rootStore,
    });
    setSaving(true);

    inviteAdvisor(values)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Advisor Added',
        });
        setSaving(false);
        form.resetFields();
        return { status: 'success' };
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Sorry, unable to save changes',
          description: 'Please try again.',
        });
        setSaving(false);
        return { status: 'error' };
      });
  };
  const onFinishFailed = () => {
    setSaving(false);
    Notification({
      type: 'error',
      title: 'Sorry, unable to add advisor',
      description: 'Please try again.',
    });
  };

  return (
    <div className='manageAdvisors__footer'>
      <h4>Invite a colleague</h4>
      <Form
        className='manageAdvisors__footer__form'
        layout='vertical'
        autoComplete='off'
        requiredMark='optional'
        form={form}
        validateTrigger='onBlur'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label='First Name'
          name='givenName'
          rules={[
            {
              required: true,
              message: 'Please enter their first name or initial',
            },
          ]}
          className='manageAdvisors__footer__form__fullName'
        >
          <RequiredInput loading={saving} />
        </Form.Item>

        <Form.Item
          label='Last Name'
          name='familyName'
          rules={[
            {
              required: true,
              message: 'Please enter their last name or initial',
            },
          ]}
          className='manageAdvisors__footer__form__fullName'
        >
          <RequiredInput loading={saving} />
        </Form.Item>
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email address.',
            },
            {
              required: true,
              message: 'Please enter their email address.',
            },
          ]}
          className='manageAdvisors__footer__form__email'
          extra={`Please use the same email they use for ${localeStore.translation('osps.xero')}/${localeStore.translation('osps.intuit')} login.`}
        >
          <RequiredInput loading={saving} />
        </Form.Item>

        <Form.Item className='manageAdvisors__footer__form__button'>
          <Button
            type='primary'
            htmlType='submit'
            shape='round'
            icon={<MailOutlined />}
            loading={saving}
          >
            Invite
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ManageAdvisorsFooter;
