/** Enum containing a map of potential modal widths */
export enum ModalWidths {
  SMALL = '400px',
  MEDIUM = '600px',
  LARGE = '800px',
  XLARGE = '1000px',
  XXLARGE = '1200px',
  XXXLARGE = '1400px',
  CONTAINER = '80%',
  FULL = '100%',
}

/** Enum containing a map of modal heights */
export enum ModalHeights {
  SMALL = '400px',
  MEDIUM = '600px',
  LARGE = '800px',
  XLARGE = '1000px',
  XXLARGE = '1200px',
  XXXLARGE = '1400px',
  FULL = '100%',
}

/** Types of modals included in the Subscription Tier Process */
export enum TierModalTypes {
  DEFAULT,
  UPGRADE,
  BESPOKE
}

export enum ClientSettingsPages {
  CLIENT_INFORMATION = 'client-information',
  CLIENT_CONFIGURATION = 'client-configuration',
  DEACTIVATE_CLIENT = 'deactivate-client',
  REMOVE_CLIENT = 'remove-client',
}

export enum ModalType {
  NEW = 'new',
  EDIT = 'edit',
}
