import React from 'react';
import { ClientManagementEnums } from '@aider/constants-library';
import { XeroLogoScaleable } from './icons';
import ButtonSecondary from './customAntD/ButtonSecondary';
import { useStore } from '../stores/Store';

const InsightReconnectButton = () => {
  const { connectionsStore } = useStore();
  const handleReconnectClick = (osp: ClientManagementEnums.OSPKeys) => {
    connectionsStore.connectionType = osp;
    connectionsStore.modalAddConnection();
  };

  return (
    <div className='insight-reconnect'>
      <>
        <span className='osp__logo'>
          <XeroLogoScaleable />
        </span>
        <span className='status-text'>
          Disconnected
        </span>
        <ButtonSecondary
          className='reconnect'
          size='small'
          ghost
          onClick={() => handleReconnectClick(ClientManagementEnums.OSPKeys.xero)}
          mixpanelEvent='Button Click - Reconnect Client'
        >
          Reconnect
        </ButtonSecondary>
      </>
    </div>
  );
};

export default InsightReconnectButton;
