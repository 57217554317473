import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { ClientManagementEnums, ApiConstants } from '@aider/constants-library';
import type { RootStore } from './Store';
import { IAdvisorClient, IAdvisorClients } from '../models/interfaces/components';
import { GET, POST, DELETE } from '../lib/requests';

class AdvisorClientStore {
  rootStore: RootStore;

  activeClients: IAdvisorClient[] = [];

  inactiveClients: IAdvisorClient[] = [];

  get advisorClients(): IAdvisorClients {
    return { currentClients: this.activeClients, validClients: this.inactiveClients };
  }

  set advisorClients(clients: IAdvisorClients) {
    this.activeClients = clients.currentClients;
    this.inactiveClients = clients.validClients;
  }

  get advisorClientsList(): IAdvisorClient[] {
    return [...this.activeClients, ...this.inactiveClients];
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get advisorPermissionList() {
    const allowed = this.activeClients.map((client) => ({ ...client, access: true }));
    const denied = this.inactiveClients.map((client) => ({ ...client, access: false }));

    const res = [...allowed, ...denied].sort((a, b) => a.name.localeCompare(b.name, 'en')).filter((client) => {
      if (
        this.rootStore.businessesStore.sortedActiveClientBusinesses.filter((business) => client.id === business.id)[0]?.aipStatus
        === ClientManagementEnums.BusinessActivationStatus.activated
      ) {
        return true;
      }
      return false;
    });
    return res;
  }

  get advisorClientIds() {
    const allowed = this.activeClients.map((client) => (client.id));
    const denied = this.inactiveClients.map((client) => (client.id));
    return [...allowed, ...denied];
  }

  isActiveClient(clientId): boolean {
    return this.activeClients.map((client) => client.id).includes(clientId);
  }

  async retrieveAdvisorClients(advisorId: string) {
    try {
      const practiceId = this.rootStore.practiceStore.id;
      const url = `${ApiConstants.apiEndpointsBase.root}/practices/${practiceId}/advisors/${advisorId}/clients`;

      const res = await GET({
        url,
        rootStore: this.rootStore
      });
      if (!res?._embedded?.clients) {
        throw Error('Client data not returned');
      }
      this.activeClients = res._embedded.clients.currentClients;
      this.inactiveClients = Array.from(this.rootStore.businessesStore.clientBusinesses.values())
        .filter((client) => this.activeClients.findIndex((i) => i.id === client.id) === -1); // returns true if not found in activeClients

      return this.advisorClients;
    } catch (error) {
      Sentry.captureException('Could not retrieve advisor clients', error);
      return error;
    }
  }

  async persistAdvisorClients(id?: string) {
    try {
      const advisorId = id || this.rootStore.userStore.id;

      /** These need to run one after another, so as not to create a race condition */
      await this.addAdvisorToBusiness(advisorId);
      await this.removeAdvisorFromBusiness(advisorId);

      return this.advisorClients;
    } catch (error) {
      Sentry.captureException('Could not persist advisor clients', error);
      return error;
    }
  }

  async updateAdvisorClients(currentClients: string[]) {
    const newActiveClients = [];
    const newInactiveClients = [];
    this.advisorClientsList.forEach((client) => {
      if (currentClients.includes(client.id)) {
        newActiveClients.push(client);
      } else {
        newInactiveClients.push(client);
      }
    });
    this.activeClients = newActiveClients;
    this.inactiveClients = newInactiveClients;
    return this.advisorClients;
  }

  async addAdvisorToBusiness(advisorId) {
    try {
      const data = {
        advisorAddRequests: this.activeClients.map((i) => ({ clientBusinessId: i.id, advisorUserId: advisorId }))
      };

      const url = `${ApiConstants.apiEndpointsBase.business}/practices/${this.rootStore.practiceStore.id}/clients/advisors`;

      const res = await POST({
        url,
        data,
        rootStore: this.rootStore
      });

      if (!res) {
        throw Error('Advisor not added to business');
      }

      return res;
    } catch (error) {
      Sentry.captureException('Could not add advisor to business', error);
      return error;
    }
  }

  async removeAdvisorFromBusiness(advisorId: string) {
    try {
      const advisorRemoveRequests = this.inactiveClients.map((i) => ({ clientBusinessId: i.id, advisorUserId: advisorId }));
      const data = {
        advisorRemoveRequests
      };

      const url = `${ApiConstants.apiEndpointsBase.business}/practices/${this.rootStore.practiceStore.id}/clients/advisors`;

      const res = await DELETE({
        url,
        data,
        rootStore: this.rootStore
      });

      if (!res) {
        throw Error('Advisor not removed from business');
      }
      return res;
    } catch (error) {
      Sentry.captureException('Could not remove advisor from business', error);
      return error;
    }
  }
}

export default AdvisorClientStore;
