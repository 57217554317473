import { Form, Input } from 'antd';
import React from 'react';

const EditPromptContent = ({ form }) => (
  <Form form={form} requiredMark='optional' layout='vertical'>
    <Form.Item
      label='Prompt Label'
      name='label'
      rules={[
        {
          required: true,
          message: 'Please input a label for your prompt!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label='Prompt Content'
      name='prompt'
      rules={[
        {
          required: true,
          message: 'Please input a prompt!',
        },
      ]}
    >
      <Input.TextArea rows={5} />
    </Form.Item>
  </Form>
);

export default EditPromptContent;
