import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';

/**
 * This component is used to navigate to a page when the pageStore.navigateTo is set.
 * @returns
 */
const Navigator = ({ to }: {to?:string}) => {
  const { pageStore } = useStore();
  const navigate = useNavigate();
  const target = to || pageStore.navigateTo;
  useEffect(() => {
    if (target) {
      pageStore.navigateTo = null;
      navigate(target);
    }
  }, [target, navigate, pageStore.navigateTo]);
  return <></>;
};

Navigator.defaultProps = {
  to: undefined
};

export default observer(Navigator);
