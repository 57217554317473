import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';
import LoadingZoneInitial from '../components/loadingZone/LoadingZoneInitial';
import LoadingZoneDetail from '../components/loadingZone/LoadingZoneDetail';
import { useLocale } from '../lib/customHooks/useLocale';

const LoadingZone = () => {
  const { businessesStore, loadingStore } = useStore();

  useLocale('practice');

  return !loadingStore.isAnyLoading && (
    <section className='loadingZone scrollbar'>
      {businessesStore.clientBusinesses.size > 0 ? <LoadingZoneDetail /> : <LoadingZoneInitial />}
    </section>
  );
};

export default observer(LoadingZone);
