export const CONNECTION_PENDING = 'connection pending';
export const CONFIGURATION_PENDING = 'configuration pending';
export const CLAIM_PENDING = 'claim_pending';
export const DUPLICATE_CLIENT_CONNECTION = 'duplicate_client_connection';
export const PENDING = 'pending';
export const LIMIT_EXCEEDED = 'Free tier client limit reached';
export const BUSINESS_NOT_FOUND_ERROR = 'Request failed with status code 404';
export const TAB_HEIGHT = '43vh';
export const CHAT_COMPONENT_HEIGHT = '43vh';
export const INPUT_HEIGHT = '6vh';
export const TOKEN_EXPIRY_INTERVAL = 900;
export const SUBSCRIPTION_PREFIX_LENGTH = 22;
export const SHORTLIST_LENGTH = 21;
export const CLIENT_DETAIL_THRESHOLD = 1;
export const GIVEN_NAME_REGEX_18 = /(.{18})..+/;
export const BUSINESS_NAME_REGEX_26 = /(.{26})..+/;
export const BUSINESS_NAME_REGEX_21 = /(.{21})..+/;
export const BUSINESS_NAME_REGEX_TOAST = /(.{18})..+/;
export const BANK_NAME_REGEX_30 = /(.{30})..+/;
export const ADDRESS_REGEX_45 = /(.{40})..+/;
export const MESSAGE_REGEX_40 = /(.{40})..+/;
export const REGEX_SEARCH_QUERY = /[^\w\s]/gi;
export const ESCAPE_REGEX_CHARS = /([.*+?^=!:${}()|[\]\\])/g;

export enum CONFIG {
  info = 'information',
  configuration = 'configuration',
  disconnect = 'disconnect',
  remove = 'remove',
}
export const shortMonthLabelArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const longMonthLabelArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export enum SETTINGS {
  profile = 'profile',
  branding = 'branding',
  advisors = 'advisors',
  permissions = 'permissions',
  subscription = 'subscription',
  library = 'library',
  feedback = 'feedback',
  signout = 'signout',
  information = 'information',
}
export const FilteredItems = [];
// TODO: deprecate these
export const TabItems = {
  profitability: [
    'revenue',
    'operationalExpenses',
    'grossProfit',
    'directCosts',
    'netProfit',
  ],
  Profitability: [
    'revenue',
    'operationalExpenses',
    'grossProfit',
    'directCosts',
    'netProfit',
  ],
  health: ['gstPeriodChecklist'],
  DataHealth: ['gstPeriodChecklist'],
  cashflow: ['cashFlow', 'invoiceStatus', 'cashFlowActual'],
  Cashflow: ['cashFlow', 'invoiceStatus', 'cashFlowActual'],
  Compliance: ['reconciliation', 'gst', 'incomeTax'],
  compliance: ['reconciliation', 'gst', 'incomeTax'],
  report: ['reports'],
};
// TODO: deprecate these
export const InsightsOrder = ['cashFlow', 'invoiceStatus', 'cashFlowActual', 'reconciliation', 'gst', 'incomeTax', 'revenue', 'directCosts', 'grossProfit', 'operationalExpenses', 'netProfit'];
// TODO: deprecate these
export const InsightNames = {
  directCosts: 'Direct Costs',
  cashFlow: 'Cash Position Estimates',
  cashFlowActual: 'Cash flow trend - monthly pattern',
  grossProfit: 'Gross Profit',
  gst: 'GST forecast',
  incomeTax: 'Income tax forecast',
  netProfit: 'Net Profit',
  operationalExpenses: 'Operating Expenses',
  reconciliation: 'GST Period Completion',
  revenue: 'Revenue',
  invoiceStatus: 'Invoicing Status',
};

export enum BCFailReason {
  TRUST = 'Invalid organisation type: TRUST',
}
export enum RoleKey {
  Admin = 'admin',
  Advisor = 'advisor',
}
export enum AccountType {
  DigitalAssistant = 'digital_assistant',
  AdvisoryPortal = 'advisory_portal',
}
export enum OtherAlertType {
  BankSelection = 'bank-account-selection',
  XeroReconnection = 'Xe',
}
