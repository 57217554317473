import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const refreshSvg = () => (
  <svg
    height='1em'
    viewBox='0 0 13 13'
    fill='none'
    style={{ stroke: 'currentColor' }}
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M11.7598 2.62305V5.62305H8.75977' />
    <path d='M0.759766 10.623V7.62305H3.75977' />
    <path d='M2.01477 5.12382C2.26835 4.40721 2.69933 3.76652 3.2675 3.26153C3.83567 2.75653 4.5225 2.4037 5.26391 2.23595C6.00532 2.06819 6.77714 2.09099 7.50736 2.3022C8.23758 2.51342 8.90239 2.90617 9.43977 3.44382L11.7598 5.62382M0.759766 7.62382L3.07977 9.80382C3.61714 10.3415 4.28195 10.7342 5.01217 10.9454C5.74239 11.1566 6.51421 11.1794 7.25562 11.0117C7.99703 10.8439 8.68387 10.4911 9.25203 9.98611C9.8202 9.48112 10.2512 8.84042 10.5048 8.12382' />
  </svg>

);

const RefreshIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={refreshSvg}
    {...props}
    aria-label='refresh'
  />
);

export default RefreshIcon;
