import React from 'react';
import PeriodCloseDashboardIllustration from '../illustrations/PeriodCloseDashboardIllustration';
import SplashCommon from './SplashCommon';

const PeriodCloseDashboardSplash = () => (
  <SplashCommon
    title='Connect your clients to manage their financial close at scale'
    image={<PeriodCloseDashboardIllustration />}
  >
    <ul>
      <li>Track all clients' period close progress at a glance</li>
      <li>Stay in control of the hectic period close process</li>
      <li>Prioritise and manage your workload intelligently</li>
      <li>Work efficiently through the massive volume of work</li>
    </ul>
  </SplashCommon>
);

export default PeriodCloseDashboardSplash;
