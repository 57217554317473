import * as React from 'react';

/**
 * @param props
 * @returns
 */
export const RoundedBoxTick = () => (
  <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1' y='1.95215' width='26' height='26' rx='5' fill='white' />
    <path d='M22 8.95215L11 19.9521L6 14.9521' stroke='#27AE60' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
    <rect x='1' y='1.95215' width='26' height='26' rx='5' stroke='#27AE60' strokeWidth='2' />
  </svg>
);
