import Chart, { ChartComponentLike } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import React, { useState, useEffect } from 'react';
import { IChartWrapper } from '../../ts/interfaces/atoms/ChartWrapper';

Chart.register(annotationPlugin);
Chart.register({
  id: 'custom_canvas_background_color',
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext('2d');
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
} as ChartComponentLike);

const ChartWrapper = ({
  type,
  data,
  options,
  plugins,
  id,
  hasGradient,
  setB64Image,
  setChartHandle,
  chartId,
}: IChartWrapper) => {
  const [chartInstance, setChartInstance] = useState(null);
  const animation = {
    onComplete(c) {
      if (setB64Image) {
        setB64Image(id, c.chart.toBase64Image());
      }
    },
  };
  useEffect(() => {
    const chartOptions = { ...options };
    const ctx = document.getElementById(chartId || id) as HTMLCanvasElement;
    if (ctx && hasGradient && ctx.getContext('2d')) {
      data.datasets
        .filter((ds) => ds.fill)
        .map((ds) => {
          const newDS = ds;
          const background2 = ctx
            .getContext('2d')
            .createLinearGradient(0, 0, 0, 250);
          background2.addColorStop(1, ds.backgroundColor);
          newDS.backgroundColor = background2;
          return newDS;
        });
    }
    chartOptions.animation = animation;
    const chartIns = new Chart(ctx, {
      type,
      data,
      options: chartOptions,
      plugins,
    });

    if (setChartHandle) {
      setChartHandle(chartIns, id);
    }

    setChartInstance(chartIns);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!chartInstance?.config) return;

    chartInstance.config.data = data;
    chartInstance.config.options = { ...options, animation };
    chartInstance.update();
  }, [data, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <canvas
      id={chartId || id}
      ref={(canvas) => canvas}
    />
  );
};

ChartWrapper.defaultProps = {
  setChartHandle: null,
};

export default ChartWrapper;
