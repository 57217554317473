// TODO: Refactor this file to remove all the eslint-disable
import * as React from 'react';
import { rootStore } from '../../stores/Store';
import formatNumber from '../atoms/FormatNumber';
import { backgroundArray, borderArrayCF } from '../atoms/GraphSettings';
import { renderTooltipActionItems } from '../atoms/TooltipActionItem';
import ChartWrapper from './ChartWrapper';
import { ChartColor } from '../../ts/enums/Colors';
import { ChartFontSize } from '../../ts/enums/Constants';
// eslint-disable-next-line import/no-named-as-default
import yAxisFormat from '../../utils/yAxisFormat';

type LineChartProps = {
  insightData: {
    graph: {
      data: any,
      legend: any,
      yAxis: any,
      xAxis: any,
      title: any,
    },
    summaries: any,
    insightKey: any

  };
  index?: any;
  hoverIndex?: any;
  chartId: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LineChart({ insightData, index, hoverIndex, chartId }: LineChartProps) {
  const insightDataArr = insightData;
  const isForecast = insightData.insightKey === 'cashFlow';
  const borderDash = isForecast ? [4, 3] : [1, 0];
  const dataSetArray = insightData.graph.legend.map((item, ix) => ({
    label: item.label,
    data: insightDataArr.graph.data.map(
      (dp) => dp.values[ix] && dp.values[ix].rawValue
    ),
    borderColor: borderArrayCF[ix],
    backgroundColor: backgroundArray[ix],
    order: 1,
    pointBackgroundColor: ix > 0 && isForecast ? '#fff' : borderArrayCF[ix],
    pointBorderColor: borderArrayCF[ix],
    borderDash: ix > 0 ? borderDash : [1, 0],
    borderWidth: 2,
    type: 'line',
    fill: false,
  }));
  const annotationObject = {
    zero: {
      // Indicates the type of annotation
      type: 'line',
      yMin: 0,
      yMax: 0,
      borderColor: 'rgba(159,160,172,0.67)',
    },
    box1: undefined,
  };
  const { title, units, isCurrency } = insightData.graph.yAxis;
  if (insightData.graph.yAxis.annotations) {
    annotationObject.box1 = {
      // Indicates the type of annotation
      type: 'line',
      yMin: insightData.graph.yAxis.annotations[0].rawValue,
      yMax: insightData.graph.yAxis.annotations[0].rawValue,
      borderColor: 'rgba(159,160,172,0.67)',
      borderDash: [4, 3],
      label: {
        content: insightData.graph.yAxis.annotations[0].label,
        enabled: true,
        position: 'end',
        backgroundColor: 'rgba(159,160,172,0.67)',
        color: '#505050',
      },
    };
  }
  return (
    // @ts-ignore
    <ChartWrapper
      // @ts-ignore
      chartId={chartId}
      id={`${insightData.insightKey}`}
      setChartHandle={(chart, id) => rootStore.actionStore.setChartObject(chart, id)}
      setB64Image={(id, chart) => rootStore.actionStore.setChartImage(chart, id)}
      type='bar'
      data={{
        labels: insightData.graph.data.map((datapoint) => datapoint.xLabel),
        datasets: dataSetArray,
      }}
      options={{
        responsive: true,
        animation: true,
        plugins: {
          title: {
            display: false,
            text: insightData.graph.title,
          },
          tooltip: {
            callbacks: {
              label(context) {
                const graphData = insightData.graph.data[context.dataIndex];
                if (!graphData) return null;

                const value = graphData.values[context.datasetIndex];
                if (!value || value.formattedValue === null) return null;

                let { label } = context.dataset;
                if (label) label += `: ${value.formattedValue}`;
                else label = value.formattedValue;

                return label;
              },
              footer: (contextUnresolved) => {
                const context = contextUnresolved[0];
                if (insightData.summaries[context.dataIndex]) {
                  return renderTooltipActionItems(context, insightData);
                }

                return '';
              },
            },
            backgroundColor: ChartColor.opaqueBlack,
            titleFont: {
              size: ChartFontSize.defaultSize,
            },
            bodyFont: {
              size: ChartFontSize.defaultSize,
            },
            footerFont: {
              size: ChartFontSize.defaultSize,
            },
          },
          annotation: {
            annotations: annotationObject,
          },
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 20,
              boxHeight: 20,
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              callback(value, inx, values) {
                return formatNumber(value, insightData.graph.yAxis.units);
              },
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            title: {
              display: true,
              text: yAxisFormat(title, units, isCurrency),
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },

            grid: {
              display: true,
              borderDash: [1, 2],
              color: '#d5d8e3',
            },
          },
          x: {
            ticks: {
              color: ChartColor.darkGrey,
              font: {
                size: ChartFontSize.defaultSize,
              },
            },
            grid: {
              display: false,
            },
          },
        },
      }}
    />
  );
}

LineChart.defaultProps = {
  index: 0,
  hoverIndex: 0,
};

export default LineChart;
