import React from 'react';
import { Tooltip } from 'antd';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { DashboardSortDirection, DashboardSortTarget } from '../../models/enums/components';
import PeriodCloseTableStatusSort from './PeriodCloseTableStatusSort';
import PeriodCloseTableSort from './PeriodCloseTableSort';

type PeriodCloseDataHeaderRenderType = {
  title: string,
  setSort: Function,
  sortDirection: DashboardSortDirection,
  sortTarget: DashboardSortTarget,
  sortColumn: ComplianceChecklistEnums.CategoryKeys | 'name' | 'checklistStatus',
  column: ComplianceChecklistEnums.CategoryKeys | 'name' | 'checklistStatus',
}

const PeriodCloseDataHeaderRender = (
  { title, column, setSort, sortDirection, sortTarget, sortColumn } : PeriodCloseDataHeaderRenderType
) => (
  <div className={`dashboard-subheading ${column === 'checklistStatus' ? '' : 'dashboard-subheading__centered'}`}>
    <span className='dashboard-subheading__text'>{title}</span>
    <Tooltip
      title={
        column === 'checklistStatus'
          ? (
            <PeriodCloseTableStatusSort
              activeOrder={sortDirection}
              activeTarget={sortTarget}
              setSort={setSort}
            />
          ) : (
            <PeriodCloseTableSort
              activeColumn={sortColumn}
              activeTarget={sortTarget}
              activeOrder={sortDirection}
              setSort={setSort}
              column={column}
            />
          )
      }
      placement='bottomRight'
    >
      <div className='dashboard-subheading__sorter'>
        <CaretUpFilled className={`${sortColumn === column && sortDirection === DashboardSortDirection.ascending ? 'active' : ''}`} />
        <CaretDownFilled className={`${sortColumn === column && sortDirection === DashboardSortDirection.descending ? 'active' : ''}`} />
      </div>
    </Tooltip>
  </div>
);

export default PeriodCloseDataHeaderRender;
