import React from 'react';
import DashboardIllustration from '../illustrations/DashboardIllustration';
import SplashCommon from './SplashCommon';

const PerformanceDashboardSplash = () => (
  <SplashCommon
    title='Connect your advisory clients to manage them at scale'
    image={<DashboardIllustration />}
  >
    <ul>
      <li>Instantly identify which clients need your attention today</li>
      <li>Access the compliance and financial KPIs you need for all your clients on one page</li>
      <li>Smart technology alerts you to client opportunities and risks so you can take action</li>
    </ul>
  </SplashCommon>
);

export default PerformanceDashboardSplash;
