import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import { AiderEarlyWarning, EarlyWarning } from '../../atoms/Icons';

export const InsightInfo = () => {
  const tooltipContents = ReactDOMServer.renderToString(
    <div className='custom-tooltip'>
      <p>
        <EarlyWarning scale={0.8} style={{ marginBottom: '0.2em' }} /> Aider Early Warning System
      </p>
      <span>
        Indicative trends, assuming historical patterns continue.
        If potential issues are indicated, investigate further using
        related insights or talk to your client about doing full analysis.
      </span>
    </div>
  );

  return (
    <>
      <AiderEarlyWarning style={{ marginBottom: '0.2em', marginLeft: '0.5rem' }} data-tip={tooltipContents} />
      <ReactTooltip html delayHide={250} place='bottom' />
    </>
  );
};
