import { RootStore } from '../stores/Store';

export enum HelpTitle {
    EWS = 'Aider Early Warning System',
    USAGE = 'Usage & Benefits',
    SORTING = 'Sorting Options',
    DATA = 'Data Source',
    CALCULATION = 'Calculation',
    ALERTS = 'Alerts',
    DASHBOARD_SORTING = 'Dashboard Sorting Options',
}

export enum HelpType {
    LIST = 'list',
    HTML = 'html',
}

enum AlertType {
    GOOD = 'Green',
    NEUTRAL = 'White/Grey',
    BAD = 'Red'
}

interface HelpTable {
    headings: string[],
    content: CellPair[],
}

interface CellPair {
    cellOne: string,
    cellTwo: string,
}

export interface HelpItemData {
    subTitle: HelpTitle,
    type?: HelpType,
    body?: string,
    headings?: string[],
    content?: string[],
    table?: HelpTable,
}

export interface HelpGroup {
    key: string,
    title: string,
    content: HelpItemData[],
}

export const HelpItemGroups: HelpGroup[] = [
  {
    key: 'GSTCompletion',
    title: 'GST Completion',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'Looking at clients % of data reconciled throughout the last and current GST period helps you to proactively identify clients at risk or clients that may fall behind with their reconciliations during and after the GST periods complete.\n\nThe % of reconciled transactions is calculated as an aggregated value across all selected bank accounts per business. \n\nIt helps to calculate what transactions are required to complete the GST related transactions in context of the clients GST period and due date.\n',
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DASHBOARD_SORTING,
        headings: ['Sort by highest completion %', 'Sort by lowest completion %', 'Sort by highest % change - due date', 'Sort by oldest due date'],
        content: ['sorts clients with the highest % of reconciled transactions for the current GST period items at the top of the client list.', 'sorts clients with the lowest % of reconciled transactions for the current GST period items at the top of the client list.', 'sorts clients with the oldest reconciled date at the top of the client list. ', 'sorts clients with the oldest GST period due dates at the top of the client list.'],
      },
      {
        subTitle: HelpTitle.DATA,
        body: 'For each client, the reconciled and unreconciled bank transactions from their Xero Org for their current GST period. \nThese are aggregate values from all bank accounts selected in their AIP client settings.\n',
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul><li class="disc-item">
                <b>% of Completion =</b> unreconciledLines / (unreconciledLines + reconciledLines)
                </li></ul></div>`
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        table: {
          headings: ['If current date is..', 'alert is triggered if % of completion is...'],
          content: [
            { cellOne: 'Start of GST period', cellTwo: '(no alert)' },
            { cellOne: '1st quarter of the GST period', cellTwo: '(no alert)' },
            { cellOne: '2nd quarter of the GST period', cellTwo: '<10%' },
            { cellOne: '3rd quarter of the GST period', cellTwo: '<20%' },
            { cellOne: '4th quarter of the GST period', cellTwo: '<30%' },
            { cellOne: 'GST period complete', cellTwo: '<50%' },
            { cellOne: '1st quarter of the GST payment period', cellTwo: '<60%' },
            { cellOne: '2nd quarter of the GST payment period', cellTwo: '<70%' },
            { cellOne: '3rd quarter of the GST payment period', cellTwo: '<80%' },
            { cellOne: '4th quarter of the GST payment period', cellTwo: '<100%' },
            { cellOne: 'GST due date', cellTwo: '<100%' },
          ],
        },
        body: `<div><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
                    <b>${AlertType.GOOD}:</b> reconciliation status is below the % of completion expected at this point in the GST period.
                    </li><li class="disc-item">
                    <b>${AlertType.NEUTRAL}:</b> nearly in the GST period so no risk yet of falling behind; reconciliation status is at or above the % of completion expected at this point in the GST period.The alert is triggered when the % of completion of the Xero Org’s reconciled bank transactions is below an acceptable threshold based on the Xero Org’s GST period and due date.
                    </li></ul><p class="help-alert-header">ALERT TRIGGER THRESHOLD</p><p>The acceptable threshold for the clients GST % of completion increases from 0-100% throughout the GST period and the days following to the due date as follows:</p></div>`,
      }]
  },
  {
    key: 'netProfit',
    title: 'Net Profit',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'The Net Profit insights help you assess whether your client’s business is struggling to survive (spending more than it earns) or growing sustainably.\n'
          + '\n'
          + 'It also gives you a rough idea of whether income tax will go up and/or how much money there is currently for shareholder salaries.\n'
          + '\n'
          + 'Net Profit YTD figures on the Advisory Dashboard alert you of any client who may need to review their spendings to secure a profit for the year. You can deep dive into the client’s spendings on the Client Insights Screen for more details.\n'
          + '\n'
          + 'The 3 summary boxes in the Client Insights Screenmake it easy for you to remain on top of key Net Profit metrics for the current financial year (to the end of the last month), the last month, and the current month to date.\n'
          + '\n'
          + 'Use the graph in the Client Insights Screen to help you spot and factor in any seasonal and year-on-year trends in your analysis. Click on keys in the legend to hide or show individual graph elements.\n'
          + '\n'
          + 'Use the Share Insight form to share the insights and graphs with your clients and start a discussion about Net Profit – or even do this for regular check-ins. You can also send multiple insights in the same email for a more productive discussion.',
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DASHBOARD_SORTING,
        headings: ['Sort by highest net profit', 'Sort by lowest net profit', 'Sort by highest % net profit change', 'Sort by lowest % net profit change'],
        content: ['sorts clients with the highest YTD net profit for the current year at the top of the client list.', 'sorts clients with the lowest YTD net profit for the current year at the top of the client list.', 'sorts clients with the highest (best) % change in net profit for the current year to date vs. the last year to date at the top of the client list.', 'sorts clients with the lowest (worst) % change in net profit for the current year to date vs. the last year to date at the top of the client list.'],
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: ['Xero Profit and Loss report:'],
        content: ['Net Profit as per report.'],
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul>
          <li class="disc-item">
          <b>Net Profit</b> as per Profit and Loss report (Total Trading Income - Total Cost of Sales - Total Operating Expenses).</li>
          <li class="disc-item">
          FY to date figure is to the last full month. Comparison with last year is to the same month last year.</li>
          <li class="disc-item">
          <b>% Change =</b> ( Period Amount - Comparison Period Amount ) ÷ Comparison Period Amount.</li>
          </ul></div>`,
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logic only applies when the data has been reconciled to the end of the last full month.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> significant decline in Net Profit for the period vs. last period (down 20% or more).
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> minor fluctuation in Net Profit for the period vs. last period (up or down less than 20%).
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> significant growth in Net Profit for the period vs. last period (up 20% or more).
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          Net Profit for a period is <b>down 20% or more</b> vs. last period.
          </li><li class="disc-item">
          Counts toward the Profitability tab’s total number of alerts.
          </li></ul></div>`,
      }]
  },
  {
    key: 'cashflowForecast',
    title: 'Cash Position & 2-monthly Estimates',
    content: [
      {
        subTitle: HelpTitle.EWS,
        type: HelpType.HTML,
        body: '<div>'
          + '<p>This insight is an indicative estimate based on the assumption that the historical trends will continue. Aider’s artificial intelligence uses statistical analysis and your client’s daily bank balances to project what the cash positions will be in the near future.</p>'
          + '<p><strong>Use this as an early warning sign of potential issues.</strong> Use together with other Cash Flow insights to determine if it’s worth offering the client additional service to investigate the issue using more advanced cash flow tools.</p>'
          + '<p class="help-alert-header">ESTIMATE\'s CONFIDENCE LEVELS</strong></p>'
          + '<ul>'
          + '<li class="disc-item"><strong>HIGH</strong>: Historical cash flow records show strong patterns. The AI is highly confident the estimated cash positions will be reliable.</li>'
          + '<li class="disc-item"><strong>MEDIUM</strong>: Historical records show slightly unstable patterns. Future cash positions have a 50% likelihood of falling around the estimates.</li>'
          + '<li class="disc-item"><strong>LOW</strong>: Historical records don\'t show stable patterns that the AI can use for estimation. Or we don\'t have enough data yet for analysis (newly added businesses and businesses with very few bank transactions). There is a low likelihood that the estimates will be reliable.</li>'
          + '</ul>'
          + '</div>'
      },
      {
        subTitle: HelpTitle.USAGE,
        body: 'The daily end cash position for the current and next months gives you early warnings about the client’s cash flow situation. This allows you to start a conversation with the client about ways to avoid cash flow issues, explore short-term funding options if necessary, and help them with the timing of planned expenditures to minimise cash flow difficulties.\n\n'
          + 'The end of month cash position estimate for the current month on the Dashboard alerts you of any client who may run into cash flow difficulties. You can deep dive into the client’s daily cash position estimates on the Client Insight Screen for more details.\n\n'
          + 'The 3 summary boxes in the Client Insights Screen make it easy for you to spot any trends in the end cash positions estimated for the current and next months, and get a snapshot of today’s actual opening cash position.\n\n'
          + 'Use the graph in the Client Insights Screen to help you spot the risky periods of the months to inform the timing of any interventions needed. Click on keys in the legend to hide or show individual graph elements.\n\n'
          + 'Use the Share Insight form to share the insight and graph with your clients, and start a discussion about how the client is managing their cash flow and what can be done to avoid or handle cash flow issues in the near future. You can also send multiple insights in the same email for a more productive discussion.'
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DASHBOARD_SORTING,
        headings: ['Sort by highest cash position', 'Sort by lowest cash position', 'Sort by highest cash position % change', 'Sort by lowest cash position % change'],
        content: [
          'sorts clients with the highest end of month cash position estimate at the top of the client list.',
          'sorts clients with the lowest end of month cash position estimate at the top of the client list.',
          'sorts clients with the highest (best) % change in end of month cash position estimate compared to the last month at the top of the client list.',
          'sorts clients with the lowest (worst) % change in end of month cash position estimate compared to the last month at the top of the client list.'],
      },
      {
        subTitle: HelpTitle.DATA,
        type: HelpType.LIST,
        headings: [
          'Daily opening and closing Bank Statement Balances (Cash Validation Report) for each bank account selected in the Client Configuration. This is analysed to create the  prediction algorithm for income and expenses that are not recorded as invoices or bills. For new clients added, we import 60 days of historical balances. Thereafter, balances are imported daily and the prediction algorithm updated accordingly. This is so that the prediction will improve over time. This data source enables us to offer cash position estimates even if the client doesn\'t record invoices and bills in Xero.',
          'If Bank Statement Balances are not available, the prediction algorithm will use Bank Balances in Xero (Bank Summary Report) instead. The estimates may be less accurate in this case because unreconciled items are not reflected in the Bank Balances in Xero.',
        ],
        content: ['', '', '', '', ''],
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul><li class="disc-item">
          <b>Today’s Starting Cash Position =</b> sum of the opening Bank Balances for the selected bank accounts this morning.
          </li><li class="disc-item">
          <b>Starting Cash Position =</b> sum of the opening Bank Balances for the selected bank accounts on the first of the month.
          </li><li class="disc-item">
          <b>Estimated Net Cash Flow =</b> statistical estimate for the month's net cash flow.
          </li><li class="disc-item">
          <b>Estimated End Cash Position =</b> Starting Cash Position + Estimated Net Cash Flows
          </li><li class="disc-item">
          <b>Actual Daily End Cash Position =</b> sum of the closing Bank Balances for the selected bank accounts for the day.
          </li></ul></div>`,
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logic only applies when the data has been reconciled to the end of each month shown.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> significant decrease in the estimated  End Cash Position for the month vs. the previous month (down 20% or more).
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> minor fluctuation in the estimated  End Cash Position for the month vs. the previous month (up or down less than 20%).
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> significant increase in the estimated  End Cash Position for the month vs. the previous month (up 20% or more).
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          Estimated End Cash Position for a month is down 20% or more vs. the previous month.
          </li><li class="disc-item">
          Counts toward the Cash Flow tab's total number of alerts.
          </li></ul></div>`,
      }]
  },
  {
    key: 'cashflowTrend',
    title: 'Cash Flow Trend - Monthly',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'The daily end cash position for the last 3 months helps you analyse the client’s cash flow for monthly patterns indicating recurrent issues. This allows you to start a conversation with the client about ways to improve their cash flow management, such as changes to invoicing frequency, terms and due dates.\n\n'
          + 'The 3 summary boxes in the Client Insights Screen make it easy for you to compare the End Cash Positions for the last 3 months.\n\n'
          + 'Use the graph in the Client Insights Screen to help you spot and factor in any weekly or time of month trends in your analysis. E.g. Does the client always run out of money at the end of a week, or the end of a month? Click on keys in the legend to hide or show individual graph elements.\n\n'
          + 'Use the Share Insight form to share the insights and graphs with your clients, and start a discussion about how the client is managing their cash flow and what can be done to avoid recurrent cash flow issues. You can also send multiple insights in the same email for a more productive discussion.'
      }, {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: ['Daily closing Bank Statement Balances (Cash Validation Report) for each bank account selected in the Client Configuration. For new clients added, we import 60 days of historical balances. Thereafter balances are imported daily. This data source enables us to offer cash flow trend insights as long as bank statements are regularly imported into Xero regardless of the reconciliation status.',
          'If Bank Statement Balances are not available, then Bank Balances in Xero (Bank Summary Report) will be used instead. The insight may be less accurate in this case because unreconciled items are not reflected in the Bank Balances in Xero.'],
        content: ['', '']
      }, {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: '<div><ul><li class="disc-item"><b>End Cash Position =</b> sum of the closing Bank Balances for the selected bank accounts for the day or month.</li></ul></div>',
      }, {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logic only applies when the data has been reconciled to the end of each month shown.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> significant decrease in the End Cash Position for the month vs. the previous month (down 20% or more).
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> minor fluctuation in the End Cash Position for the month vs. the previous month (up or down less than 20%).
          </li><li class="disc-item">
          <b>${AlertType.GOOD}</b>:</b> significant increase in the End Cash Position for the month vs. the previous month (up 20% or more).
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          End Cash Position for a month is <b>down 20% or more</b> vs. the previous month.
          </li><li class="disc-item">
          Counts toward the Cash Flow tab’s total number of alerts.
          </li></ul></div>`,
      }]
  },
  {
    key: 'incomeTaxEstimate',
    title: 'Income Tax Estimate',
    content: [
      {
        subTitle: HelpTitle.EWS,
        type: HelpType.HTML,
        body: '<div>'
          + '<p>This insight is an indicative estimate based on the assumption that the historical trends will continue.</p>'
          + '<p><strong>Use this as an early warning sign of potential issues.</strong></p>'
          + '</div>'
      },
      {
        subTitle: HelpTitle.USAGE,
        body: 'Looking at your clients\' current YTD tax position and comparing it with last year\'s tax in real-time helps you to proactively identify clients at risk, or clients that may fall behind with their tax savings/payments throughout the financial year. \n'
          + 'The tax position for the current year includes an apportion of last year\'s total expenses vs. current years expenses claimed to provide a real-time view of your clients’ adjusted tax position. \n',
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DASHBOARD_SORTING,
        headings: ['Sort by highest income tax', 'Sort by lowest income tax', 'Sort by lowest (worst) % change', 'Sort by highest (best) % change'],
        content: ['sorts clients with the highest income tax payable for the current financial year at the top of the client list.',
          'sorts clients with the lowest income tax payable for the current financial year at the top of the client list.',
          'sorts clients with the highest % change of income tax payable for the current financial year vs. last year at the top of the client list.',
          'sorts clients with the lowest % change of income tax payable for the current financial year vs. last year at the top of the client list.'],
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: [
          'The following Net Profit and Operating Expenses amounts per Profit and Loss Reports.',
          'Net Profit last year excluding Shareholder salaries (distributions to shareholders are removed from last year Net Profit to facilitate like for like comparison.)',
          'Net Profit this year to last full month.',
          'Net Profit this year to last full month (irrespective of reconciliation status)',
          'Operating Expenses last year YTD ',
          'Operating Expenses this year YTD',
          'Tax Rate setting in Client Configuration (defaults to: NZ=28%, AU=25%, CA=15%, US=21%)'],
        content: ['', '', '', '', ''],
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul><li class="disc-item">
          <b>Income Tax YTD (last full month) =</b> this year YTD Net Profit x Tax Rate
          </li><li class="disc-item">
          <b>Income Tax last year YTD =</b> (last year YTD Net Profit) x Tax Rate
          </li><li class="disc-item">
          <b>% Change =</b> (Income Tax YTD - Income Tax last year YTD) / Income Tax last year YTD
          </li><li class="disc-item">
          <b>Income Tax estimate =</b> (Income Tax YTD / # of full months to date this year) x 12
          </li></ul></div>`
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logic only applies when the data has been reconciled to the end of the last full month.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> when the current YTD tax position is 20% above last year's tax position, red indicates a significant increase in tax payable.
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> +/- 20% change in YTD income tax vs. previous YTD.
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> when the current YTD tax position is 20% below last year's tax position, green indicates a significant decrease in tax payable.
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          Actual or estimated Income Tax for a period is <b>up 20% or more</b> vs. last period.
          </li><li class="disc-item">
          Counts toward the Taxes & Compliance tab's total number of alerts.
          </li></ul></div>`,
      }]
  },
  {
    key: 'gstEstimate',
    title: 'GST Estimate Due (vs Avg)',
    content: [
      {
        subTitle: HelpTitle.EWS,
        type: HelpType.HTML,
        body: '<div>'
          + '<p>This insight is an indicative estimate based on the assumption that the GST period’s transaction, invoice, and bill patterns will continue.</p>'
          + '<p><strong>Use this as an early warning sign of potential issues.</strong> Warn the client early to avoid bill shock and cash flow issues.</p>'
          + '<p class="help-alert-header">ESTIMATE\'s CONFIDENCE LEVELS</strong></p>'
          + '<ul>'
          + '<li class="disc-item"><strong>HIGH</strong>: Reconciliation for this GST period is 75-100% complete. We are highly confident the estimated GST due will be reliable.</li>'
          + '<li class="disc-item"><strong>MEDIUM</strong>: Reconciliation for this GST period is 50-74% complete. Estimated GST due has a 50% likelihood of falling around the estimate.</li>'
          + '<li class="disc-item"><strong>LOW</strong>: Reconciliation for this GST period is 0-49% complete. There is a low likelihood that the estimate will be reliable.</li>'
          + '</ul>'
          + '</div>'
      },
      {
        subTitle: HelpTitle.USAGE,
        type: HelpType.HTML,
        body: '<div>'
        + '<p>Your clients’ GST position for the period next due helps you proactively identify clients who need early warnings due to significant increase in the estimated GST vs. their average GST payable. This removes bill shock and helps them avoid tax-related cash flow issues.</p>'
        + '<p>The insight gives you two views to assess the risk of a higher GST bill for the period:</p>'
        + '<ol>'
        + '<li>GST for the reconciled transactions to date + any additional invoices and bills that count towards the period (summary box 2 on the Client Insights page).</li>'
        + '<li>GST estimate for the entire period: This includes the above + any unreconciled transactions with GST amount, and projection for any remaining days in the period. This estimate assumes the period’s transaction, invoice, and bill patterns will continue for the entire period (Dashboard & summary box 3 on the Client Insights pages).</li>'
        + '</ol>'
        + '<p>The graph on the Client Insights page allows you to visually compare the GST for this period vs. the finalised Xero GST Reports for the last three periods, plus the average period amount for the past 12 months.</p>'
        + '<p>Share with your client early so they can set aside enough money to pay their GST. You can also look at their Cash Flow insights to determine if they may be receptive to additional services such as cash flow management advice.</p>'
        + '</div>'
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.SORTING,
        headings: ['Sort by highest GST amount', 'Sort by lowest GST amount', 'Sort by worst % change', 'Sort by best % change'],
        content: ['sorts clients with the highest GST estimate for the period next due at the top of the client list.',
          'sorts clients with the lowest GST estimate for the period next due at the top of the client list.',
          'sorts clients with the highest % increase of GST estimate for the period next due vs. their average GST payable at the top of the client list.',
          'sorts clients with the highest % decrease of GST estimate for the period next due vs. their average GST payable at the top of the client list.'],
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: [
          'Tax related financial settings in Xero',
          'AU client\'s BAS extension setting in Aider',
          'Finalised GST/BAS Reports in Xero',
          'Bank transactions imported into Xero',
          'Invoices and Bills in Xero',
        ],
        content: ['', '', '', '', ''],
      },
      {
        subTitle: HelpTitle.CALCULATION,
        type: HelpType.HTML,
        body: `<div><ul><li class="disc-item">
          <strong>Invoices & Bills for Cash / Payments tax basis =</strong> Invoices and Bills paid or due during the period.<br />
          Assumption: Invoices & Bills due will be paid on time. If the GST period is complete (i.e. during the filing period) and these have not been paid on time, then the estimate may be affected by the overdue Invoices and Bills.
          </li><li class="disc-item">
          <strong>Invoices & Bills for Accrual / Invoice tax basis =</strong> Authorised or paid Invoices and Bills created during the period.<br />
          Assumption: Invoice & Bill creation pattern in this period so far will continue for the rest of the period.
          </li><li class="disc-item">
          <b>Average GST =</b> sum of the GST amounts for last 12 months' finalised GST reports / number of GST periods in the last 12 months with finalised GST reports
          </li><li class="disc-item">
          <strong>Period Reconciled To Date =</strong> ( ( GST from reconciled & unreconciled transactions + any GST adjustments imported to date ) / ( days passed this period / total number of days this period ) ) + GST from Invoices paid or due during this period - GST from Bills paid or due during this period
          </li><li class="disc-item">
          <strong>Period Estimate (Accrual / Invoice basis) =</strong> ( ( GST from reconciled & unreconciled transactions + any GST adjustments imported to date )) + GST from authorised or paid Invoices created during this period - GST from authorised or paid Bills created during this period ) / ( days passed this period / total number of days this period )
          </li><li class="disc-item">
          <b>Period Estimate % Change =</b> ( Period Estimate - Average GST ) / Average GST
          </li><li class="disc-item">
          <b>GST for past periods =</b> GST from finalised GST Reports in Xero. If any are missing, then the period will show up as “No data” and will be excluded from the Average GST calculation.
          </li></ul></div>`
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> when the current estimated GST position is 20% above the average position, red indicates a significant increase in GST payable.
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> +/- 20 % change in estimate vs. average GST payable.
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> when the current estimated GST position is 20% below the average position, green indicates a significant decrease in GST payable.
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><p>The alert is triggered when the % change of the current GST payable is above the acceptable threshold of 20% compared to the adjusted average GST position.</p></div>`,
      }]
  },
  {
    key: 'revenue',
    title: 'Revenue',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'The Revenue insight helps you assess whether the business is bringing in enough income to stay operational or grow.\n'
          + '\n'
          + 'Revenue YTD figures on the Advisory Dashboard alert you to any client who may need to refocus on bringing in more revenue. Deep dive into the client’s data on the Client Insights Screen for more details.\n'
          + '\n'
          + 'The 3 summary boxes in the Client Insights Screen make it easy for you to remain on top of key Revenue metrics for the current financial year (to the end of the last month), the last month, and the current month to date.\n'
          + '\n'
          + 'Use the graph in the Client Insights Screen to help you spot and factor in any seasonal and year-on-year trends in your analysis. Click on keys in the legend to hide or show individual graph elements.\n'
          + '\n'
          + 'Use the Share Insight form to share the insights and graphs with your clients and start a discussion about Revenue – or even do this for regular check-ins. You can also send multiple insights in the same email for a more productive discussion.'
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DASHBOARD_SORTING,
        headings: ['Sort by highest revenue', 'Sort by lowest revenue', 'Sort by highest revenue % change (growth)', 'Sort by lowest revenue % change (decline)'],
        content: ['sorts clients with the highest YTD revenue for the current year at the top of the client list.',
          'sorts clients with the lowest YTD revenue for the current year at the top of the client list.',
          'sorts clients with the highest (best) % change in revenue for the current year to date vs. the last year to date at the top of the client list.',
          'sorts clients with the lowest (worst) % change in revenue for the current year to date vs. the last year to date at the top of the client list.'],
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: ['Xero Profit and Loss report:'],
        content: ['All Trading Income to date including Sales and other revenues.'],
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul>
          <li class="disc-item">
          <b>Total Trading Income</b> as per Profit and Loss report.</li>
          <li class="disc-item">
          FY to date figure is to the last full month. Comparison with last year is to the same month last year.</li>
          <li class="disc-item">
          <b>% Change =</b> ( Period Amount - Comparison Period Amount ) ÷ Comparison Period Amount.</li>
          </ul></div>`,
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logic only applies when the data has been reconciled to the end of the last full month.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> significant decline in Revenue for the period vs. last period (down 20% or more).
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> minor fluctuation in Revenue for the period vs. last period (up or down less than 20%).
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> significant growth in Revenue for the period vs. last period (up 20% or more).
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          Revenue for a period is <b>down 20% or more</b> vs. last period.
          </li><li class="disc-item">
          Counts toward the Profitability tab’s total number of alerts.
          </li></ul></div>`,

      }]
  },

  {
    key: 'unreconciledItems',
    title: 'Unreconciled items',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'This insight helps you assess how far behind your clients are with the reconciliation needed for performance analysis. \n\nThe total number gives you an idea of how much effort would be required to bring this up to date. The oldest item date helps you assess how behind your clients are and how far back to go to find items needing attention.\n\nBringing reconciliation up to date is crucial for improving the reliability of other performance insights, some of which will not be available if the reconciliation is too out of date.'
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DASHBOARD_SORTING,
        headings: ['Sort by highest # unreconciled', 'Sort by lowest # unreconciled', 'Sort by oldest item date - oldest first', 'Sort by oldest item date - latest first'],
        content: ['Shows clients with the highest number of unreconciled items at the top.', 'Shows clients with the lowest number of unreconciled items at the top.', 'Shows clients with the oldest unreconciled items at the top.', 'Shows clients who are most up to date with reconciliation at the top.'],
      },
      {
        subTitle: HelpTitle.DATA,
        body: 'Number of unreconciled items from each bank account selected in the client’s Client Configuration. By default this will be all active bank accounts associated with their Xero Organisation. '
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.CALCULATION,
        headings: ['Total = Aggregate number of unreconciled items from all selected bank accounts.', 'Oldest item = Date of the oldest unreconciled items across all selected bank accounts.'],
        content: ['', '', '', ''],
      },
      {
        subTitle: HelpTitle.ALERTS,
        type: HelpType.HTML,
        body: `<div><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
                <b>${AlertType.BAD}:</b> Unreconciled transactions are affecting the reliability of key profitability and income tax insights.
                </li><li class="disc-item">
                <b>${AlertType.GOOD} Checkmark:</b> Unreconciled transactions are NOT affecting the reliability of key profitability and income tax insights.
                </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
                The alert is triggered when data has not been reconciled to the end of the last month.
                </li></ul></div>`,
      }
    ],
  },
  {
    key: 'operationalExpenses',
    title: 'Operating Expenses',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'The Operating Expenses insight helps you keep track of your client’s businesses’ fixed and variable overhead costs. This helps you to ensure they are sustainable.\n'
          + '\n'
          + 'The 3 summary boxes in the Client Insights Screen make it easy for you to remain on top of key Operating Expenses metrics for the current financial year (to the end of the last month), the last month, and the current month to date.\n'
          + '\n'
          + 'Use the graph in the Client Insights Screen to help you spot and factor in any seasonal and year-on-year trends in your analysis. Click on keys in the legend to hide or show individual graph elements.\n'
          + '\n'
          + 'Use the Share Insight form to share the insights and graphs with your clients and start a discussion about Operating Expenses – or even do this for regular check-ins. You can also send multiple insights in the same email for a more productive discussion.'
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: ['Xero Profit and Loss report:'],
        content: ['All Operating Expenses to date.'],
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul>
          <li class="disc-item">
          <b>Total Operating Expenses</b> as per Profit and Loss report.</li>
          <li class="disc-item">
          FY to date figure is to the last full month. Comparison with last year is to the same month last year.</li>
          <li class="disc-item">
          <b>% Change =</b> ( Period Amount - Comparison Period Amount ) ÷ Comparison Period Amount.</li>
          </ul></div>`,
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logc only applies when the data has been reconciled to the end of the last full month.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> significant increase in Operating Expenses for the period vs. last period (down 20% or more).
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> minor fluctuation in Operating Expenses for the period vs. last period (up or down less than 20%).
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> significant decrease in Operating Expenses for the period vs. last period (up 20% or more).
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          Operating Expenses for a period is <b>up 20% or more</b> vs. last period.
          </li><li class="disc-item">
          Counts toward the Profitability tab’s total number of alerts.
          </li></ul></div>`,
      }]
  },

  {
    key: 'directCosts',
    title: 'Direct Costs',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'The Direct Costs insight helps you assess whether the costs of producing and/or acquiring goods and services for sale are impacting your client’s profitability. While Direct Costs are likely to go up or down in line with the Revenue trends, if it rises faster than Revenue, then the client may want to consider options like switching suppliers/vendors, re-negotiating contracts, improving efficiencies, minimising wastage, or raising prices.\n'
          + '\n'
          + 'The 3 summary boxes in the Client Insights Screen make it easy for you to remain on top of key Direct Costs metrics for the current financial year (to the end of the last month), the last month, and the current month to date.\n'
          + '\n'
          + 'Use the graph in the Client Insights Screen to help you spot and factor in any seasonal and year-on-year trends in your analysis. Click on keys in the legend to hide or show individual graph elements.\n'
          + '\n'
          + 'Use the Share Insight form to share the insights and graphs with your clients and start a discussion about Direct Costs – or even do this for regular check-ins. You can also send multiple insights in the same email for a more productive discussion.',
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: ['Xero Profit and Loss report:'],
        content: ['All Costs of Sales to date including materials, equipment, and services.'],
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul>
          <li class="disc-item">
          <b>Total Cost of Sales</b> as per Profit and Loss report (Total Trading Income - Total Cost of Sales - Total Operating Expenses).</li>
          <li class="disc-item">
          FY to date figure is to the last full month. Comparison with last year is to the same month last year.</li>
          <li class="disc-item">
          <b>% Change =</b> ( Period Amount - Comparison Period Amount ) ÷ Comparison Period Amount.</li>
          </ul></div>`,
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logic only applies when the data has been reconciled to the end of the last full month.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> significant increase in Direct Costs for the period vs. last period (down 20% or more).
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> minor fluctuation in Direct Costs for the period vs. last period (up or down less than 20%).
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> significant decrease in Direct Costs for the period vs. last period (up 20% or more).
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          Direct Costs for a period is <b>up 20% or more</b> vs. last period.
          </li><li class="disc-item">
          Counts toward the Profitability tab’s total number of alerts.
          </li></ul></div>`,
      }]
  },
  {
    key: 'grossProfit',
    title: 'Gross Profit',
    content: [
      {
        subTitle: HelpTitle.USAGE,
        body: 'The Gross Profit insight shows the impact of Direct Costs vs. Revenue more clearly. Use this to quickly spot when Direct Costs are rising faster than Revenue. Assess whether it’s a temporary glitch or a sustained trend that needs addressing.\n'
          + '\n'
          + 'This insight also helps clients see how much money the business has left to cover existing Operating Expenses and any new investments; needed be it headcounts, equipment, and so on.\n'
          + '\n'
          + 'The 3 summary boxes in the Client Insights Screen make it easy for you to remain on top of key Gross Profit metrics for the current financial year (to the end of the last month), the last month, and the current month to date.\n'
          + '\n'
          + 'Use the graph in the Client Insights Screen to help you spot and factor in any seasonal and year-on-year trends in your analysis. Click on keys in the legend to hide or show individual graph elements.\n'
          + '\n'
          + 'Use the Share Insight form to share the insights and graphs with your clients and start a discussion about Gross Profit – or even do this for regular check-ins. You can also send multiple insights in the same email for a more productive discussion.',
      },
      {
        type: HelpType.LIST,
        subTitle: HelpTitle.DATA,
        headings: ['Xero Profit and Loss report:'],
        content: ['Gross Profit as per report.'],
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.CALCULATION,
        body: `<div><ul>
          <li class="disc-item">
          <b>Gross Profit</b> as per Profit and Loss report (Total Trading Income - Total Cost of Sales - Total Cost of Sales).</li>
          <li class="disc-item">
          FY to date figure is to the last full month. Comparison with last year is to the same month last year.</li>
          <li class="disc-item">
          <b>% Change =</b> ( Period Amount - Comparison Period Amount ) ÷ Comparison Period Amount.</li>
          </ul></div>`,
      },
      {
        type: HelpType.HTML,
        subTitle: HelpTitle.ALERTS,
        body: `<div><p>Alert logic only applies when the data has been reconciled to the end of the last full month.</p><p class="help-alert-header">COLOR CODING</p><ul><li class="disc-item">
          <b>${AlertType.BAD}:</b> significant decline in Gross Profit for the period vs. last period (down 20% or more).
          </li><li class="disc-item">
          <b>${AlertType.NEUTRAL}:</b> minor fluctuation in Gross Profit for the period vs. last period (up or down less than 20%).
          </li><li class="disc-item">
          <b>${AlertType.GOOD}:</b> significant growth in Gross Profit for the period vs. last period (up 20% or more).
          </li></ul><p class="help-alert-header">RED ALERT TRIGGER THRESHOLD</p><ul><li class="disc-item">
          Gross Profit for a period is <b>down 20% or more</b> vs. last period.
          </li><li class="disc-item">
          Counts toward the Profitability tab’s total number of alerts.
          </li></ul></div>`,
      }]
  },
];

export function mapInsightToHelpKey(insightId) {
  switch (insightId) {
    case 'revenue': return 'revenue';
    case 'incomeTax': return 'incomeTaxEstimate';
    case 'gst': return 'gstEstimate';
    case 'cashFlow': return 'cashflowForecast';
    case 'cashFlowActual': return 'cashflowTrend';
    case 'reconciliation': return 'GSTCompletion';
    case 'invoiceStatus': return null;
    case 'netProfit': return 'netProfit';
    case 'operationalExpenses': return 'operationalExpenses';
    case 'directCosts': return 'directCosts';
    case 'grossProfit': return 'grossProfit';
    default: return null;
  }
}

export function selectIndex(insightId: string, rootStore: RootStore) {
  const key = mapInsightToHelpKey(insightId);
  const relevantIndex = HelpItemGroups.findIndex((item) => item.key === key);
  rootStore.helpPanelStore.setSelectedIndex(relevantIndex);
}
