function renderVisibility(alertLevel: number) {
  switch (alertLevel) {
    case 2:
      return 'muted';
    default:
      return '';
  }
}

export default renderVisibility;
