import React from 'react';
import AiderAlert from '../AiderAlert';
import SectionResync from './SectionResync';
import { useStore } from '../../stores/Store';

const BankRecSyncBanner = ({ periodType, sectionId, checklistId, lastUpdated, firestoreUpdatedAt }
: {periodType: string, sectionId: string, checklistId: string, lastUpdated: any, firestoreUpdatedAt: any}) => {
  const { localeStore } = useStore();
  // How many hours since the checklist / section was last resynced
  const lastResync = firestoreUpdatedAt || lastUpdated;
  const hoursSinceLastSync = Math.floor((new Date().getTime() - new Date(lastResync).getTime()) / 3600000);
  const hoursToHideBanner = 12;
  const periodTranslation = localeStore.translation(`period-close.period-type.${periodType}`);
  const title = localeStore.translation(`period-close.banner.${sectionId}.title`, { periodType: periodTranslation });
  return hoursSinceLastSync >= hoursToHideBanner && (
    <AiderAlert
      className='insight-banner'
      type='warning'
      title={title}
      callToAction={(
        <SectionResync
          checklistId={checklistId}
          sectionId={sectionId}
          lastUpdated={lastUpdated}
          firestoreUpdatedAt={firestoreUpdatedAt}
          buttonOnly
        />
      )}
    />
  );
};

export default BankRecSyncBanner;
