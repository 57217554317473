import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';

/**
 * The CaretDown icon. Also known as a "chevron down". `⌄`
 * @param props
 * @returns
 */
export const CaretRight = ({
  width = 8,
  height = 16,
  color = 'currentColor',
  strokeWidth = 2,
  fill = 'none',
  lineCap = 'round',
  padding = 0.25,
  scale = 1,
  ...rest
}: IconInterface) => {
  const scaleWidth = width * scale;
  const scaleHeight = height * scale;
  const { min, max } = getPlotExtremes({ width: scaleWidth, height: scaleHeight, padding });
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={scaleWidth}
      height={scaleHeight}
      stroke={color}
      fill={fill}
      strokeWidth={strokeWidth}
      viewBox={`0 0 ${scaleWidth} ${scaleHeight}`}
      strokeLinecap={lineCap}
      {...rest}
    >
      <polyline
        points={`${min.x},${min.y} ${max.x},${scaleHeight / 2} ${min.x},${max.y}`}
      />
    </svg>
  );
};
