import React from 'react';
import { observer } from 'mobx-react';
import { DateFormats } from '@aider/aider-formatting-library';
import { useStore } from '../../stores/Store';
import DataLoadingInfo from './DataLoadingInfo';
import ResyncPillButton from '../atoms/ResyncPillButton';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import ResyncError from '../../components/manualResync/ResyncError';

const InsightResyncButton = () => {
  const rootStore = useStore();
  const { businessesStore, localeStore, resyncStore, checklistStore, insightStore } = rootStore;
  const { selectedBusinessId, selectedBusiness } = businessesStore;
  const { resyncingBusinesses, loadingBusinesses, triggerETLResync } = resyncStore;

  const businessDetails = businessesStore.businesses.get(selectedBusinessId);
  const [ospKey] = businessDetails?.connectedOsps || [null];

  // Duplicated from DashboardBusinessNameCell.tsx
  const dataStatus = resyncStore.dataStatusSnapshots?.get(selectedBusinessId);
  let resyncButtonText = null;
  let resyncPillText = null;
  if (resyncStore.error?.get(selectedBusinessId)) {
    resyncButtonText = 'Try again';
    resyncPillText = (
      <>
        Could not finish update.
      </>
    );
  } else if (resyncStore.dataStatusPendingData?.get(selectedBusinessId) || resyncStore.resyncingBusinesses?.get(selectedBusinessId)) {
    resyncButtonText = 'Resync';
    resyncPillText = (
      <>
        Updating data...<span className='date'>please wait</span>
      </>
    );
  } else if (resyncStore.dataStatusSnapshots?.get(selectedBusinessId)?.etl?.ended) {
    resyncButtonText = 'Resync';
    resyncPillText = (
      <>
        Updated:
        <span className='date'>
          {localeStore.formatDate(null, DateFormats.dayTime, dataStatus?.etl?.ended)}
        </span>
      </>
    );
  }

  const triggerResync = async (e) => {
    e.stopPropagation();
    if (!resyncingBusinesses.get(selectedBusinessId)) {
      try {
        trackMixpanelEvent({
          description: 'Business Resync - Insight',
          properties: {
            businessId: selectedBusinessId,
            businessName: selectedBusiness?.name,
            businessOsp: ospKey,
          },
          rootStore,
        });
        resyncingBusinesses.set(selectedBusinessId, true);
        await triggerETLResync(selectedBusinessId);
      } catch (err) {
        resyncingBusinesses.set(selectedBusinessId, false);
        loadingBusinesses.set(selectedBusinessId, false);
      }
    }
  };

  // Refesh the checklist on complete
  const handleComplete = () => {
    loadingBusinesses.set(selectedBusinessId, false);
    checklistStore.retrieveChecklist(checklistStore.activeChecklistType, selectedBusinessId);
    insightStore.getBusinessInsights(selectedBusinessId);
    return { done: true, callback: () => null };
  };

  return (
    <div className='insight-resync'>
      {resyncPillText}
      {
        resyncButtonText && !resyncStore.dataStatusPendingData?.get(selectedBusinessId) && (
          <div className='insight-resync__status'>
            <ResyncError businessId={selectedBusinessId} />
            <ResyncPillButton
              onClick={triggerResync}
              state={resyncingBusinesses.get(selectedBusinessId)}
            >
              {resyncButtonText}
            </ResyncPillButton>
          </div>
        )
      }
      <DataLoadingInfo
        businessId={selectedBusinessId}
        handleComplete={handleComplete}
      />
    </div>
  );
};

export default observer(InsightResyncButton);
