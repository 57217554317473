import React from 'react';

const RuleModalError = ({ newRule }:{newRule:boolean}) => (
  <>
    {newRule
      ? <>Something went wrong: Unable to add your new data check. To try again, click the "Add" button.</>
      : <>'Something went wrong: Unable to save your edit. To try again click the "Update" button.</>}
  </>
);

export default RuleModalError;
