import React, { useMemo } from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { TreeSelect } from 'antd';
import { useStore } from '../../stores/Store';
import { InsightTab } from '../../stores/v1/pageStore';

const getTabTitle = (activeTab) => {
  switch (activeTab) {
    case InsightTab.cashflow:
      return 'Cash flow';
    case InsightTab.taxAndCompliance:
      return 'Tax & Compliance';
    case InsightTab.profitability:
      return 'Profitability';
    default:
      return '';
  }
};

const WysiwygQuickContent = () => {
  const { reportTemplateStore, localeStore } = useStore();
  const filteredInsightData = reportTemplateStore.filteredInsightGraphData;

  const handleSelect = (insightTitle) => {
    reportTemplateStore.injectTrendText(insightTitle);
  };

  const treeData = useMemo(() => [InsightTab.cashflow, InsightTab.taxAndCompliance, InsightTab.profitability].map((categoryId) => {
    const categorizedInsights = filteredInsightData.filter(
      (insight) => (
        (insight.categoryData?.categoryId as InsightTab) === categoryId)
        || (categoryId === InsightTab.taxAndCompliance && insight.insightKey === 'gst')
    );
    return ({
      key: categoryId,
      value: categoryId,
      title: getTabTitle(categoryId),
      selectable: false,
      children: categorizedInsights.map((insight) => ({
        title: localeStore.translation(`insights.${insight.insightKey}.title`),
        value: insight.insightKey,
        key: insight.insightKey,
      }))
    });
  }), [filteredInsightData]);

  return (
    <TreeSelect
      showSearch
      value={null}
      className='ant-tree-select'
      popupClassName='ant-tree-dropdown'
      treeExpandedKeys={[InsightTab.profitability, InsightTab.cashflow, InsightTab.taxAndCompliance]}
      treeData={treeData}
      placeholder='Insert Quick Content'
      onChange={handleSelect}
      suffixIcon={<CaretDownFilled />}
    />
  );
};

export default observer(WysiwygQuickContent);
