import React from 'react';
import { Check } from '../../components-v2/atoms/Icons';
import { useStore } from '../../stores/Store';

const LoadingZoneSteps = ({ activeStep }:{activeStep: 1|2}) => {
  const { localeStore } = useStore();

  return (
    <div className='loadingZone__steps'>
      <div className={`loadingZone__steps__step loadingZone__steps__step--${activeStep === 1 ? 'active' : 'complete'}`}>
        <div className='loadingZone__steps__step-number'>{activeStep === 1 ? 1 : <Check />}</div>
        <div className='loadingZone__steps__step-text'>{localeStore.translation('loading-zone.stages.stage1')}</div>
      </div>
      <div className='loadingZone__seperator' />
      <div className={`loadingZone__steps__step ${activeStep === 2 && 'loadingZone__steps__step--active'}`}>
        <div className='loadingZone__steps__step-number'>2</div>
        <div className='loadingZone__steps__step-text'>{localeStore.translation('loading-zone.stages.stage2')}</div>
      </div>
    </div>
  );
};

export default LoadingZoneSteps;
