import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const earlyAccessFlagSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M55.6328 0.218262H0.632812V55.2183L55.6328 0.218262Z' fill='#00E0E1' />
    <g filter='url(#filter0_d_332_36360)'>
      <path d='M8.66437 29.5012C8.44281 29.7227 8.21653 29.8359 7.98554 29.8406C7.75456 29.8359 7.53064 29.7251 7.31379 29.5082L3.67219 25.8666C3.45535 25.6498 3.34692 25.4282 3.34692 25.202C3.34692 24.9662 3.4577 24.7376 3.67926 24.5161L6.07636 22.119C6.24606 21.9493 6.41577 21.8644 6.58547 21.8644C6.75518 21.8644 6.92017 21.9445 7.08045 22.1048C7.24544 22.2698 7.33029 22.4395 7.33501 22.6139C7.33501 22.7836 7.25015 22.9534 7.08045 23.1231L5.24904 24.9545L6.19656 25.902L7.87241 24.2261C8.0374 24.0612 8.20475 23.9787 8.37445 23.9787C8.54887 23.9739 8.71858 24.0541 8.88357 24.2191C9.04856 24.3841 9.1287 24.5538 9.12399 24.7282C9.12399 24.8979 9.04149 25.0652 8.8765 25.2302L7.20066 26.9061L8.22596 27.9314L10.0574 26.1C10.2271 25.9303 10.3991 25.8478 10.5736 25.8525C10.7433 25.8525 10.9083 25.9326 11.0685 26.0929C11.2335 26.2579 11.316 26.4252 11.316 26.595C11.316 26.7647 11.2312 26.9344 11.0615 27.1041L8.66437 29.5012Z' fill='white' />
      <path d='M12.55 25.7428C12.4039 25.8889 12.2483 25.9785 12.0833 26.0115C11.9136 26.0398 11.7581 26.0068 11.6166 25.9125C11.4705 25.8135 11.355 25.6556 11.2702 25.4387L9.24077 19.8879C9.14177 19.6192 9.11113 19.3812 9.14885 19.1738C9.19127 18.9616 9.29262 18.7754 9.4529 18.6151C9.61318 18.4549 9.79467 18.3582 9.99737 18.3252C10.2048 18.2875 10.4452 18.3158 10.7186 18.4101L16.2694 20.4395C16.4957 20.5149 16.6583 20.6257 16.7573 20.7718C16.861 20.9132 16.8964 21.0664 16.8634 21.2314C16.8351 21.3917 16.7502 21.5426 16.6088 21.684C16.4203 21.8725 16.2317 21.9763 16.0431 21.9951C15.8593 22.0093 15.633 21.9621 15.3643 21.8537L14.0845 21.3375L14.9401 21.2032L12.0268 24.1164L12.1682 23.2538L12.6844 24.5336C12.7881 24.807 12.8376 25.031 12.8329 25.2054C12.8282 25.3798 12.7339 25.5589 12.55 25.7428ZM10.86 20.0506L11.9985 22.8295L11.4611 22.6881L13.5188 20.6304L13.6602 21.1678L10.8742 20.0364L10.86 20.0506Z' fill='white' />
      <path d='M18.3603 19.9325C18.1529 20.1399 17.936 20.2436 17.7098 20.2436C17.4788 20.2389 17.2596 20.1329 17.0521 19.9254L13.3186 16.1919C13.1065 15.9798 13.0004 15.7606 13.0004 15.5343C13.0051 15.3033 13.1112 15.0841 13.3186 14.8767L15.0581 13.1372C15.6238 12.5715 16.1989 12.2745 16.7834 12.2463C17.368 12.2086 17.9054 12.4348 18.3956 12.9251C18.7115 13.2409 18.9165 13.5874 19.0108 13.9645C19.1051 14.3322 19.0839 14.7117 18.9472 15.103C18.8105 15.4848 18.5512 15.8667 18.1694 16.2485L18.0987 16.0646L18.4381 15.7252C18.6408 15.5225 18.8694 15.3929 19.124 15.3363C19.3738 15.275 19.6496 15.2915 19.9513 15.3858L20.9412 15.6687C21.1534 15.7299 21.3231 15.8289 21.4503 15.9656C21.5729 16.0976 21.6271 16.2556 21.613 16.4394C21.5988 16.6138 21.4998 16.793 21.316 16.9768C21.1322 17.1607 20.9436 17.2738 20.7503 17.3162C20.5618 17.3539 20.3425 17.3374 20.0927 17.2667L18.2684 16.7293C18.1081 16.6822 17.9643 16.6845 17.837 16.7364C17.7098 16.7788 17.5895 16.8566 17.4764 16.9697L17.0875 17.3586L18.3532 18.6244C18.5606 18.8318 18.6691 19.0486 18.6785 19.2749C18.6832 19.4965 18.5771 19.7157 18.3603 19.9325ZM16.1683 16.4394L17.0309 15.5767C17.2808 15.3269 17.4222 15.0865 17.4552 14.8555C17.4882 14.6245 17.401 14.4053 17.1936 14.1979C16.9956 13.9999 16.7811 13.9174 16.5501 13.9504C16.3144 13.9787 16.0716 14.1177 15.8218 14.3676L14.9591 15.2303L16.1683 16.4394Z' fill='white' />
      <path d='M23.2963 14.8692C23.0889 15.0767 22.8697 15.1827 22.6387 15.1874C22.4077 15.1827 22.1885 15.0767 21.9811 14.8692L18.3041 11.1923C18.092 10.9802 17.9859 10.761 17.9859 10.5347C17.9859 10.3084 18.0896 10.0916 18.297 9.88414C18.5092 9.67201 18.726 9.5683 18.9476 9.57301C19.1739 9.57301 19.3931 9.67908 19.6052 9.89121L22.8579 13.1439L24.5832 11.4186C24.7624 11.2394 24.9462 11.1499 25.1348 11.1499C25.3233 11.1404 25.5048 11.2229 25.6792 11.3973C25.8537 11.5718 25.9385 11.7556 25.9338 11.9489C25.9338 12.1375 25.8442 12.3213 25.6651 12.5004L23.2963 14.8692Z' fill='white' />
      <path d='M27.8205 10.4723C27.6131 10.6797 27.3962 10.7834 27.17 10.7834C26.939 10.7787 26.7151 10.6679 26.4982 10.4511L24.5607 8.51362L25.5436 8.9591L21.5767 8.24492C21.3835 8.21193 21.2232 8.12236 21.0959 7.97622C20.9686 7.82066 20.9073 7.64624 20.9121 7.45296C20.9168 7.25026 21.0134 7.05463 21.202 6.86607C21.334 6.73407 21.4872 6.64686 21.6616 6.60444C21.8313 6.5573 22.0387 6.56201 22.2838 6.61858L24.7304 7.08527L24.4759 7.33983L24.0021 4.88617C23.9503 4.63632 23.9456 4.4289 23.988 4.26391C24.0304 4.09892 24.1223 3.94571 24.2638 3.80429C24.4429 3.62516 24.6267 3.53088 24.8153 3.52145C25.0039 3.51202 25.1688 3.57331 25.3103 3.7053C25.4564 3.83258 25.553 4.01407 25.6002 4.24977L26.3144 8.18836L25.8618 7.21255L27.7993 9.15002C28.2471 9.59786 28.2542 10.0386 27.8205 10.4723Z' fill='white' />
      <path d='M12.443 39.9919C12.2969 40.1381 12.1413 40.2276 11.9763 40.2606C11.8066 40.2889 11.651 40.2559 11.5096 40.1617C11.3635 40.0627 11.248 39.9047 11.1631 39.6879L9.13374 34.1371C9.03474 33.8684 9.0041 33.6303 9.04181 33.4229C9.08424 33.2108 9.18559 33.0246 9.34587 32.8643C9.50615 32.704 9.68764 32.6074 9.89034 32.5744C10.0978 32.5367 10.3382 32.565 10.6116 32.6592L16.1624 34.6886C16.3887 34.7641 16.5513 34.8749 16.6503 35.021C16.754 35.1624 16.7893 35.3156 16.7563 35.4806C16.7281 35.6409 16.6432 35.7917 16.5018 35.9332C16.3132 36.1217 16.1247 36.2254 15.9361 36.2443C15.7523 36.2584 15.526 36.2113 15.2573 36.1029L13.9774 35.5867L14.833 35.4523L11.9197 38.3656L12.0612 37.5029L12.5773 38.7828C12.6811 39.0562 12.7306 39.2801 12.7258 39.4545C12.7211 39.629 12.6268 39.8081 12.443 39.9919ZM10.753 34.2997L11.8915 37.0787L11.3541 36.9372L13.4117 34.8796L13.5532 35.417L10.7672 34.2856L10.753 34.2997Z' fill='white' />
      <path d='M20.0099 32.4534C19.4583 33.0049 18.8785 33.3726 18.2704 33.5564C17.6623 33.7403 17.0589 33.745 16.4602 33.5706C15.8662 33.3915 15.31 33.0426 14.7914 32.5241C14.4049 32.1375 14.1126 31.7274 13.9146 31.2937C13.7213 30.8553 13.6247 30.4098 13.6247 29.9573C13.6294 29.5 13.7355 29.0498 13.9429 28.6067C14.1503 28.1542 14.4591 27.7228 14.8692 27.3127C15.086 27.0958 15.3359 26.8979 15.6187 26.7187C15.9016 26.5302 16.1821 26.3958 16.4602 26.3157C16.6629 26.2544 16.8444 26.252 17.0047 26.3086C17.1649 26.3652 17.2922 26.4595 17.3865 26.5914C17.4855 26.7187 17.5468 26.8601 17.5703 27.0157C17.5939 27.1713 17.5703 27.3174 17.4996 27.4541C17.4242 27.5861 17.2946 27.6827 17.1107 27.744C16.9033 27.8195 16.7124 27.9114 16.538 28.0198C16.3636 28.1188 16.2009 28.2437 16.0501 28.3946C15.7578 28.6868 15.5692 28.9885 15.4844 29.2997C15.3995 29.6014 15.4137 29.9078 15.5268 30.2189C15.6399 30.53 15.8521 30.8412 16.1632 31.1523C16.4743 31.4634 16.7878 31.6779 17.1037 31.7957C17.4148 31.9089 17.7236 31.9254 18.03 31.8452C18.3364 31.7557 18.6357 31.5648 18.928 31.2725C19.0506 31.1499 19.1684 31.0038 19.2815 30.8341C19.39 30.6597 19.4795 30.4711 19.5502 30.2684C19.6304 30.0657 19.7388 29.9243 19.8755 29.8441C20.0169 29.7593 20.1631 29.7263 20.3139 29.7451C20.4695 29.7593 20.6133 29.8135 20.7453 29.9078C20.8725 29.9973 20.9645 30.1223 21.021 30.2825C21.0823 30.4381 21.0847 30.6102 21.0281 30.7987C20.9621 31.0627 20.8348 31.3456 20.6463 31.6473C20.4624 31.9442 20.2503 32.2129 20.0099 32.4534Z' fill='white' />
      <path d='M24.7124 27.7508C24.1609 28.3024 23.581 28.6701 22.9729 28.8539C22.3648 29.0378 21.7614 29.0425 21.1627 28.8681C20.5688 28.6889 20.0125 28.3401 19.494 27.8215C19.1074 27.435 18.8151 27.0249 18.6171 26.5912C18.4239 26.1528 18.3272 25.7073 18.3272 25.2547C18.3319 24.7975 18.438 24.3473 18.6454 23.9042C18.8528 23.4516 19.1616 23.0203 19.5717 22.6102C19.7886 22.3933 20.0384 22.1953 20.3213 22.0162C20.6041 21.8276 20.8846 21.6933 21.1627 21.6131C21.3654 21.5519 21.5469 21.5495 21.7072 21.6061C21.8675 21.6626 21.9948 21.7569 22.089 21.8889C22.188 22.0162 22.2493 22.1576 22.2729 22.3132C22.2965 22.4687 22.2729 22.6149 22.2022 22.7516C22.1268 22.8836 21.9971 22.9802 21.8133 23.0415C21.6058 23.1169 21.4149 23.2088 21.2405 23.3173C21.0661 23.4163 20.9035 23.5412 20.7526 23.692C20.4603 23.9843 20.2718 24.286 20.1869 24.5971C20.1021 24.8988 20.1162 25.2052 20.2293 25.5164C20.3425 25.8275 20.5546 26.1386 20.8657 26.4497C21.1769 26.7609 21.4904 26.9754 21.8062 27.0932C22.1173 27.2064 22.4261 27.2229 22.7325 27.1427C23.0389 27.0531 23.3383 26.8622 23.6305 26.57C23.7531 26.4474 23.8709 26.3013 23.9841 26.1315C24.0925 25.9571 24.1821 25.7686 24.2528 25.5659C24.3329 25.3632 24.4413 25.2217 24.5781 25.1416C24.7195 25.0567 24.8656 25.0237 25.0165 25.0426C25.172 25.0567 25.3158 25.111 25.4478 25.2052C25.5751 25.2948 25.667 25.4197 25.7236 25.58C25.7848 25.7356 25.7872 25.9076 25.7306 26.0962C25.6646 26.3602 25.5374 26.643 25.3488 26.9447C25.165 27.2417 24.9528 27.5104 24.7124 27.7508Z' fill='white' />
      <path d='M27.6644 24.6433C27.4428 24.8648 27.2166 24.978 26.9856 24.9827C26.7546 24.978 26.5307 24.8672 26.3138 24.6503L22.6722 21.0087C22.4554 20.7919 22.347 20.5703 22.347 20.344C22.347 20.1083 22.4577 19.8797 22.6793 19.6582L25.0764 17.2611C25.2461 17.0914 25.4158 17.0065 25.5855 17.0065C25.7552 17.0065 25.9202 17.0866 26.0805 17.2469C26.2455 17.4119 26.3303 17.5816 26.335 17.756C26.335 17.9257 26.2502 18.0954 26.0805 18.2652L24.2491 20.0966L25.1966 21.0441L26.8724 19.3682C27.0374 19.2032 27.2048 19.1208 27.3745 19.1208C27.5489 19.116 27.7186 19.1962 27.8836 19.3612C28.0486 19.5262 28.1287 19.6959 28.124 19.8703C28.124 20.04 28.0415 20.2073 27.8765 20.3723L26.2007 22.0482L27.226 23.0735L29.0574 21.2421C29.2271 21.0724 29.3992 20.9899 29.5736 20.9946C29.7433 20.9946 29.9083 21.0747 30.0686 21.235C30.2336 21.4 30.3161 21.5673 30.3161 21.737C30.3161 21.9068 30.2312 22.0765 30.0615 22.2462L27.6644 24.6433Z' fill='white' />
      <path d='M33.1904 19.2728C33.0018 19.4614 32.7897 19.6452 32.554 19.8244C32.3277 20.0035 32.0967 20.1591 31.861 20.2911C31.6253 20.4231 31.3991 20.5268 31.1822 20.6022C30.9936 20.6682 30.8239 20.6776 30.6731 20.6305C30.5175 20.5786 30.3902 20.4891 30.2913 20.3618C30.1923 20.2345 30.1333 20.0954 30.1145 19.9446C30.0909 19.789 30.1192 19.6429 30.1993 19.5062C30.2748 19.3648 30.4115 19.2563 30.6095 19.1809C30.8734 19.0866 31.1421 18.9499 31.4156 18.7708C31.689 18.5917 31.9247 18.4031 32.1227 18.2051C32.4338 17.894 32.6106 17.6371 32.653 17.4344C32.6954 17.2222 32.6553 17.0549 32.5328 16.9323C32.4244 16.8239 32.2924 16.7815 32.1368 16.805C31.986 16.8239 31.7762 16.9205 31.5075 17.0949L30.5034 17.7455C29.9566 18.1038 29.4545 18.2758 28.9972 18.2617C28.54 18.2475 28.1252 18.0543 27.7527 17.6818C27.5076 17.4367 27.3355 17.1657 27.2366 16.8687C27.1376 16.5623 27.1093 16.2464 27.1517 15.9211C27.1988 15.5912 27.312 15.2612 27.4911 14.9312C27.6702 14.5918 27.9177 14.2642 28.2336 13.9483C28.4787 13.7032 28.7545 13.484 29.0609 13.2907C29.3673 13.088 29.6666 12.9489 29.9589 12.8735C30.1239 12.8217 30.2795 12.8217 30.4256 12.8735C30.5717 12.9254 30.6943 13.0102 30.7933 13.1281C30.8876 13.2412 30.9418 13.3708 30.9559 13.517C30.9748 13.6584 30.9442 13.7975 30.864 13.9342C30.7839 14.0615 30.6401 14.1675 30.4327 14.2524C30.2347 14.3278 30.0343 14.4386 29.8316 14.5847C29.6289 14.7214 29.4474 14.8699 29.2872 15.0302C29.1127 15.2046 28.9855 15.3743 28.9053 15.5393C28.8205 15.6996 28.7828 15.8504 28.7922 15.9919C28.8063 16.1286 28.8629 16.2464 28.9619 16.3454C29.0609 16.4444 29.1858 16.4892 29.3367 16.4798C29.4875 16.4609 29.6973 16.3643 29.966 16.1898L30.963 15.5464C31.5193 15.1881 32.0237 15.009 32.4762 15.009C32.9288 15.009 33.3365 15.1905 33.6995 15.5535C33.9446 15.7986 34.1167 16.0696 34.2157 16.3666C34.3147 16.6636 34.343 16.9747 34.3006 17.3C34.2534 17.6206 34.1379 17.9482 33.9541 18.2829C33.7702 18.6176 33.5157 18.9476 33.1904 19.2728Z' fill='white' />
      <path d='M37.7963 14.667C37.6077 14.8555 37.3956 15.0394 37.1599 15.2185C36.9336 15.3976 36.7026 15.5532 36.4669 15.6852C36.2312 15.8172 36.0049 15.9209 35.7881 15.9963C35.5995 16.0623 35.4298 16.0718 35.279 16.0246C35.1234 15.9728 34.9961 15.8832 34.8971 15.7559C34.7981 15.6286 34.7392 15.4896 34.7203 15.3387C34.6968 15.1832 34.7251 15.037 34.8052 14.9003C34.8806 14.7589 35.0173 14.6505 35.2153 14.575C35.4793 14.4808 35.748 14.3441 36.0214 14.1649C36.2948 13.9858 36.5305 13.7972 36.7285 13.5992C37.0396 13.2881 37.2164 13.0312 37.2589 12.8285C37.3013 12.6164 37.2612 12.449 37.1386 12.3264C37.0302 12.218 36.8982 12.1756 36.7427 12.1992C36.5918 12.218 36.382 12.3147 36.1133 12.4891L35.1092 13.1396C34.5624 13.4979 34.0604 13.67 33.6031 13.6558C33.1458 13.6417 32.731 13.4484 32.3586 13.076C32.1135 12.8309 31.9414 12.5598 31.8424 12.2628C31.7434 11.9564 31.7151 11.6406 31.7576 11.3153C31.8047 10.9853 31.9178 10.6553 32.097 10.3253C32.2761 9.98593 32.5236 9.6583 32.8394 9.34246C33.0846 9.09733 33.3603 8.87813 33.6667 8.68485C33.9732 8.48215 34.2725 8.34308 34.5648 8.26766C34.7298 8.2158 34.8853 8.2158 35.0315 8.26766C35.1776 8.31951 35.3002 8.40436 35.3992 8.52221C35.4934 8.63535 35.5477 8.76499 35.5618 8.91112C35.5807 9.05254 35.55 9.19161 35.4699 9.32832C35.3897 9.4556 35.246 9.56166 35.0385 9.64651C34.8405 9.72194 34.6402 9.83272 34.4375 9.97885C34.2348 10.1156 34.0533 10.2641 33.893 10.4243C33.7186 10.5988 33.5913 10.7685 33.5112 10.9334C33.4263 11.0937 33.3886 11.2446 33.3981 11.386C33.4122 11.5227 33.4688 11.6406 33.5678 11.7396C33.6667 11.8385 33.7917 11.8833 33.9425 11.8739C34.0934 11.855 34.3031 11.7584 34.5718 11.584L35.5689 10.9405C36.1251 10.5823 36.6295 10.4031 37.0821 10.4031C37.5346 10.4031 37.9424 10.5846 38.3054 10.9476C38.5505 11.1927 38.7226 11.4638 38.8216 11.7608C38.9206 12.0577 38.9488 12.3689 38.9064 12.6941C38.8593 13.0147 38.7438 13.3423 38.5599 13.677C38.3761 14.0117 38.1215 14.3417 37.7963 14.667Z' fill='white' />
    </g>
    <defs>
      <filter id='filter0_d_332_36360' x='-0.653076' y='3.52051' width='43.5786' height='44.75' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.705882 0 0 0 0 0.729412 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_332_36360' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_332_36360' result='shape' />
      </filter>
    </defs>
  </svg>

);

const EarlyAccessFlag = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={earlyAccessFlagSvg}
    {...props}
    aria-label='logo'
  />
);

export default EarlyAccessFlag;
