import React from 'react';
import { observer } from 'mobx-react';
import { Footer } from 'antd/lib/layout/layout';

import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { FeatureFlags } from '@aider/constants-library/dist/enums/split';
import { useStore } from '../stores/Store';
import ActivateClientTable from '../components/clientManagement/ActivateClientTable';
import handleError from '../lib/errorHandler';
import Notification from '../components/Notification';
import ButtonSecondary from '../components/customAntD/ButtonSecondary';
import { Routers } from '../models/enums/utils';

import ButtonPrimary from '../components/customAntD/ButtonPrimary';
import { handleTierChange } from '../lib/componentHelpers/activateClientTierModalHelper';
import { useLocale } from '../lib/customHooks/useLocale';

const ActivateClients = () => {
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [deactivateLoading, setDeactivateLoading] = React.useState(false);
  const [removeClientsLoading, setRemoveClientsLoading] = React.useState(false);
  const { splitStore, businessesStore, clientManagementStore, loadingStore, localeStore, practiceStore } = useStore();
  const navigate = useNavigate();

  useLocale('practice');

  const onActivateButtonClick = async () => {
    try {
      setButtonLoading(true);
      // Fire the call to set selected clients as active
      const { successful, failed, subscription } = await businessesStore.activateBusinesses();
      // Clear the selected clients
      businessesStore.clearBusinessesForActivation();

      loadingStore.setGlobalBypass('clientManagement', 'business', 'defaultBusiness');

      // Get updated business data
      await Promise.all([
        clientManagementStore.getClientManagementDashboard(),
        businessesStore.fetchBusinessData(),
      ]);

      if (successful && successful.length) {
        Notification({
          type: 'success',
          title: `${successful.length} clients activated successfully`
        });
      }

      if (failed && failed.length) {
        Notification({
          type: 'error',
          title: `${failed.length} clients failed to activate`
        });
      }

      if (subscription) {
        handleTierChange(subscription, practiceStore.subscription.currency, practiceStore.countryCode);
      }
    } catch (error) {
      handleError({ error, status: 'unknown_error', transaction: 'Activate Clients', operation: 'handleClick' });
      Notification({
        type: 'error',
        title: 'Sorry, unable to activate selected clients',
      });
    }
    setButtonLoading(false);
  };

  const onDeactivateButtonClick = async () => {
    try {
      setDeactivateLoading(true);
      // Fire the call to set selected clients as active
      const { successful, failed, subscription } = await businessesStore.deactivateBusinesses();
      // Clear the selected clients
      businessesStore.clearBusinessesForActivation();
      // Set global bypass for client management
      loadingStore.setGlobalBypass('clientManagement', 'business', 'defaultBusiness');
      // Get updated business data
      await Promise.all([
        clientManagementStore.getClientManagementDashboard(),
        businessesStore.fetchBusinessData(),
      ]);

      if (successful && successful.length) {
        Notification({
          type: 'success',
          title: `${successful.length} clients deactivated successfully`
        });
      }

      if (failed && failed.length) {
        Notification({
          type: 'error',
          title: `${failed.length} clients failed to deactivate`
        });
      }

      if (subscription) {
        handleTierChange(subscription, practiceStore.subscription.currency, practiceStore.countryCode);
      }
    } catch (error) {
      handleError({ error, status: 'unknown_error', transaction: 'Deactivate Clients', operation: 'handleClick' });
      Notification({
        type: 'error',
        title: 'Sorry, unable to deactivate selected clients',
      });
    }
    setDeactivateLoading(false);
  };

  const onRemoveButtonClick = async () => {
    try {
      setRemoveClientsLoading(true);
      // Fire the call to set selected clients as active
      const subscription = await businessesStore.disconnectAndRemoveBusinesses();
      // Clear the selected clients
      businessesStore.clearBusinessesForActivation();
      // Set global bypass for client management
      loadingStore.setGlobalBypass('clientManagement', 'business', 'defaultBusiness');
      // Get updated business data
      await Promise.all([
        clientManagementStore.getClientManagementDashboard(),
        businessesStore.fetchBusinessData(),
      ]);

      if (subscription) {
        handleTierChange(subscription, practiceStore.subscription.currency, practiceStore.countryCode);
      }
    } catch (error) {
      handleError({ error, status: 'unknown_error', transaction: 'Remove Clients', operation: 'handleClick' });
      Notification({
        type: 'error',
        title: 'Sorry, unable to remove selected clients',
      });
    }
    setRemoveClientsLoading(false);
  };

  return (
    splitStore.splitInitiated && !loadingStore.isAnyLoading && (
      <>
        <header className='client-management__header'>
          <h1>{localeStore.translation('client-management.title')}</h1>
          <span className='client-management__header-links'>
            <ButtonPrimary
              type='link'
              onClick={() => navigate(Routers.ADVISORS)}
              mixpanelEvent='Clients Management - View Clients'
              icon={<RightOutlined />}
            >
              {localeStore.translation('client-management.links.users')}
            </ButtonPrimary>
            <ButtonPrimary
              type='link'
              onClick={() => navigate(Routers.PERMISSIONS)}
              mixpanelEvent='Clients Management - Permissions Click Through'
              icon={<RightOutlined />}
            >
              {localeStore.translation('client-management.links.permissions')}
            </ButtonPrimary>
          </span>

        </header>
        <section className='client-management'>
          <ActivateClientTable savingData={buttonLoading} />
        </section>
        <Footer className='client-management__footer'>
          <ButtonSecondary
            loading={buttonLoading}
            size='large'
            onClick={onActivateButtonClick}
            mixpanelEvent='Activate Clients'
          >
            {localeStore.translation('client-management.buttons.activate')}
          </ButtonSecondary>
          <div className='client-management__footer--remove-group'>
            {/* Only show remove button if feature is enabled */}
            {splitStore.featureEnabled(FeatureFlags.BulkRemoveClients) && (
              <ButtonPrimary
                loading={removeClientsLoading}
                size='large'
                danger
                onClick={onRemoveButtonClick}
                mixpanelEvent='Remove Clients'
              >
                {localeStore.translation('client-management.buttons.remove')}
              </ButtonPrimary>
            )}
            {/* Only show deactivate button if feature is enabled */}
            {splitStore.featureEnabled(FeatureFlags.BulkDeactivate) && (
              <ButtonSecondary
                loading={deactivateLoading}
                size='large'
                danger
                onClick={onDeactivateButtonClick}
                mixpanelEvent='Deactivate Clients'
              >{localeStore.translation('client-management.buttons.deactivate')}
              </ButtonSecondary>
            )}
          </div>
        </Footer>
      </>
    )
  );
};

export default observer(ActivateClients);
