import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { ReportTypes } from '@aider/constants-library/dist/enums/docX';
import ButtonPrimary from '../../../components/customAntD/ButtonPrimary';
import Notification from '../../../components/Notification';
import { ExclamationAlert } from '../../atoms/Icons';
import { useStore } from '../../../stores/Store';

const DownloadButton = () => {
  const rootStore = useStore();
  const { businessStore, reportDownloadStore, timePeriodStore } = rootStore;
  const { selectedBusinessId, selectedBusinessName } = businessStore;
  const [downloading, setDownloading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const downloadAsDocx = async () => {
    setHasError(false);
    setDownloading(true);
    reportDownloadStore
      .downloadAsDocx(ReportTypes.performanceReport)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Report downloaded successfully',
          description:
            'Your report has been downloaded, check your downloads folder.',
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Report download failed',
          description: 'Could not download, please try again...',
        });
        setHasError(true);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <div className='report-download'>
      {hasError && (
        <div className='error-message'>
          <ExclamationAlert style={{ marginBottom: '0.2em' }} /> Something went
          wrong, please try again
        </div>
      )}
      <ButtonPrimary
        onClick={() => downloadAsDocx()}
        mixpanelEvent='Save Monthly Report'
        mixpanelProperties={{
          businessId: selectedBusinessId,
          businessName: selectedBusinessName,
          insightsIncluded: reportDownloadStore.insightsSelected,
          periodSelected: timePeriodStore.profitabilityPeriodSelected,
        }}
        loading={downloading}
        disabled={downloading}
        icon={<DownloadOutlined />}
      >
        Download as Word doc
      </ButtonPrimary>
    </div>
  );
};

export default DownloadButton;
