import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import TaxStatus from '../tooltipInners/TaxStatus';
import { CircleDraft } from '../../components-v2/atoms/Icons/CircleDraft';
import { CircleFinal } from '../../components-v2/atoms/Icons/CircleFinal';
import { InfoRing } from '../../components-v2/atoms/Icons';

const ImageCell = ({ cell }) => {
  const data = cell?.rawData ?? '';
  const hasReportStatus = ['draft', 'final', 'estimate'].includes(data);
  const hasPayrollStatus = Number.isInteger(data);

  const ToolTip = hasReportStatus
    ? <TaxStatus status={data} />
    : cell.data;

  return (
    <Tooltip title={ToolTip} placement='bottom'>
      {(data === 'draft'
        || (hasPayrollStatus && data >= 1)
      ) && <CircleDraft />}

      {(data === 'final'
        || (hasPayrollStatus && data === 0)
      ) && <CircleFinal />}

      {data === 'estimate' && <InfoRing />}

      {!hasReportStatus
        && !hasPayrollStatus
        && <img src={cell.link} alt={cell.data} title={cell.data} />}
    </Tooltip>
  );
};

export default observer(ImageCell);
