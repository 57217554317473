import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { DeleteOutlined } from '@ant-design/icons';
import { ModalWidths } from '../../models/enums/modals';
import AsyncModal from './wrappers/AsyncModal';
import { RootStore } from '../../stores/Store';
import handleError from '../../lib/errorHandler';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import Notification from '../Notification';
import DeletePromptContent from './content/DeletePromptContent';

export interface IDeleteRuleModal {
  prompt: any,
  rootStore: RootStore;
  error?: any;
}

const DeletePromptModal = ({
  rootStore, prompt
}: IDeleteRuleModal) => {
  /** Process the delete confirmation */
  const onOk = async () => {
    try {
      trackMixpanelEvent({
        description: 'Delete Rule - Delete Triggered',
        rootStore,
        properties: { userId: rootStore.userStore.id, prompt },
      });

      await rootStore.assistantStore.deleteUserPrompt(prompt);
      NiceModal.remove('DeleteRuleModal');
      Notification({ type: 'success', title: 'Prompt deleted successfully', description: `Successfully deleted prompt "${prompt.label}".` });
    } catch (e: any) {
      trackMixpanelEvent({
        description: 'Delete Rule - Error Encountered',
        rootStore,
        properties: { prompt, e },
      });
      Notification({ type: 'error', title: 'Could not delete' });
      handleError({
        error: e,
        status: 'error_deleting',
        transaction: 'Delete data rule',
        operation: 'handleDeletes',
      });
    }
  };

  const ModalProps = {
    name: 'Delete Rule - Confirm Modal',
    className: 'rule-modal rule-modal__delete',
    width: ModalWidths.MEDIUM,
    okText: 'Delete',
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
      danger: true,
      icon: <DeleteOutlined />,
    },
    cancelButtonProps: {
      className: 'button-secondary',
      shape: 'round',
      size: 'large',
    },
    onOk,
    asyncOk: true,
    content: (<DeletePromptContent promptName={prompt?.label} />),
    rootStore,
  };

  NiceModal.register('DeletePromptModal', AsyncModal);
  return NiceModal.show('DeletePromptModal', ModalProps);
};

export default DeletePromptModal;
