import React, { useState } from 'react';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import ImpersonateUser from '../ImpersonateUser';
import { Routers } from '../../models/enums/utils';
import { useStore } from '../../stores/Store';

const SplashCommon = ({ title, image, children }: { title: string, image: React.ReactNode, children: React.ReactNode }) => {
  const [loading, setLoading] = useState(false);
  const { localeStore } = useStore();
  const navigate = useNavigate();

  const handleAddClients = () => {
    setLoading(true);
    navigate(Routers.CLIENTS_MANAGEMENT);
    setLoading(false);
  };

  return (
    <>
      <ImpersonateUser className='full-width' />
      <section className='splash-screen scrollbar'>
        <section className='splash-screen__details'>
          <h1>
            {title}
          </h1>
          {children}
          <p>
            <ButtonPrimary
              loading={loading}
              disabled={loading}
              onClick={handleAddClients}
              mixpanelEvent='Splash Screen: Add clients button clicked'
              className='splash-screen__details__button'
              icon={<PlusOutlined />}
            >
              Add Clients
            </ButtonPrimary>
          </p>
          <h2>Looking for a client added by your colleague?</h2>
          <p>For security reasons, you must have permission to access their client data.</p>
          <ul>
            <li>Simply add the clients again to confirm you have access to their {localeStore.translation('osps.xero')} or {localeStore.translation('osps.intuit')} data. Don't worry, we won't charge your firm twice for the same clients. </li>
            <li>OR ask your colleague to grant you permission via your firm's "Account Settings" <RightOutlined /> "Manage Permissions" screen.</li>
          </ul>
          <h2>How to get the most out of your Aider platform</h2>
          <p><a href='https://ask.aider.ai/help/product-guides' target='_blank' rel='noopener noreferrer'>Get Started Guides <RightOutlined /></a></p>
        </section>
        <section className='splash-screen__preview'>
          {image}
        </section>
      </section>
    </>
  );
};

export default SplashCommon;
