import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ModalWidths } from '../../models/enums/modals';
import Mixpanel from '../../lib/mixpanel';
import { AiderLogoIcon } from '../icons';
import StandardModal from './wrappers/StandardModal';
import ActivateClientBespokeContent from './content/ActivateClientBespokeContent';

const ActivateClientBespokeModal = async (subscription) => {
  /**
   * Handle the OK button click
   * @returns {void}
   */
  const okHandler = () => {
    Mixpanel.track('Button Clicked: Activate Client Bespoke Modal', {
      subscription,
    });
  };

  /**
   * Return the modal props object
   */
  const ModalProps = {
    name: 'Tier Change Modal - Bespoke',
    className: 'tier-modal',
    width: ModalWidths.LARGE,
    okText: 'Contact us',
    onOk: okHandler,
    okButtonProps: {
      className: 'button-primary btn-hidden',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps: {
      danger: true,
      disabled: true,
      shape: 'round',
      size: 'large',
      className: 'button-secondary btn-hidden',
    },
    content: (
      <section className='centered-modal add-client-modal'>
        <AiderLogoIcon className='xero-aider-logo__aider' />
        <ActivateClientBespokeContent subscription={subscription} />
      </section>
    ),
    maskClosable: false,
    closable: false,
    keyboard: false,
  };
  NiceModal.register('ActivateClientBespokeModal', StandardModal);
  return NiceModal.show('ActivateClientBespokeModal', ModalProps);
};

export default ActivateClientBespokeModal;
