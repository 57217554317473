import React from 'react';
import { Tooltip } from 'antd';
import { pluralise } from '../../lib/stringMutation';
import { ChecklistStatusIconDone, ChecklistStatusIconInProgress, ChecklistStatusIconTodo } from '../icons';

const PeriodCloseStatusCell = (value: {status: string; percentage: number}, record: any, index: number) => {
  let status: string;
  let Icon: React.ReactNode;
  let label: string;

  const percentage: number = value?.percentage ? value.percentage : 0;

  switch (value.status) {
    case 'todo':
      status = 'danger';
      Icon = <ChecklistStatusIconTodo />;
      label = 'To do';
      break;
    case 'done':
      status = 'success';
      Icon = <ChecklistStatusIconDone />;
      label = 'Done';
      break;
    case 'inProgress':
    default:
      status = 'normal';
      Icon = <ChecklistStatusIconInProgress />;
      label = 'In progress';
  }

  let fractionTooltip = <span className='period-close__tooltip-wrapper'>All alerts reviewed.</span>;
  if (record?.totals && record?.totals.active > 0) {
    fractionTooltip = (
      <span className='period-close__tooltip-wrapper'>
        {record.totals.reviewed > 0 && (
          <>
            {record.totals.reviewed} out of {record.totals.categoryItemCount} {pluralise('alert', record.totals.categoryItemCount)} reviewed.
            <br />
          </>
        )}
        {record.totals.active} {pluralise('alert', record.totals.active)} still outstanding.
      </span>
    );
  }
  return (
    <div className={`period-close__status-cell cell-${status}`} key={`${record.businessName}-${index}`}>
      <div className='period-close__status-cell__row'>
        <span className='period-close__status-cell__row--start'>
          {Icon}
          {label}
        </span>
        <span className='period-close__status-cell__row--end'>
          {record?.totals?.reviewed > 0 && (
            <Tooltip
              title={<span className='period-close__tooltip-wrapper'>Reviewed Alert Total: {record.totals.reviewed}</span>}
              placement='bottom'
            >
              <span className='dashboard-pill dashboard-pill--small dashboard-pill--muted'>{record.totals.reviewed}</span>
            </Tooltip>
          )}
          {record?.totals?.active > 0 && (
            <Tooltip
              title={<span className='period-close__tooltip-wrapper'>Outstanding Alert Total: {record.totals.active}</span>}
              placement='bottom'
            >
              <span className='dashboard-pill dashboard-pill--small dashboard-pill--danger'>{record.totals.active}</span>
            </Tooltip>
          )}
        </span>
      </div>
      <div className='period-close__status-cell__row'>
        <div className='period-close__status-cell__row--percentage'>
          <progress max={100} value={percentage} />
          <span>{Math.round(percentage)}%</span>
        </div>
        {record?.totals && (
          <Tooltip
            title={fractionTooltip}
            placement='bottom'
          >
            <span>({record.totals.reviewed}/{record.totals.categoryItemCount})</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default PeriodCloseStatusCell;
