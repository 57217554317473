import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useStore } from '../../stores/Store';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const WysiwygDeleteButton = () => {
  const rootStore = useStore();
  const { reportTemplateStore } = rootStore;

  const handleDelete = () => {
    trackMixpanelEvent({ description: 'Report Editor - Wysiwyg Delete Block', rootStore });
    reportTemplateStore.deleteActiveBlock();
  };

  return (
    <div className='wysiwyg__custom-buttons'>
      <div className='wysiwyg__delete-button'>
        <Tooltip title='Delete block'>
          <div
            className='rdw-option-wrapper'
            onClick={handleDelete}
          >
            <DeleteOutlined />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default WysiwygDeleteButton;
