import { useEffect } from 'react';
import { useStore } from '../../stores/Store';

// Custom hook to require authentication before running a useEffect
export const useLocale = (reference: 'business' | 'practice') => {
  const { businessesStore, localeStore, practiceStore } = useStore();

  const dependencies = reference === 'business' ? [businessesStore?.selectedBusiness] : [practiceStore?.countryCode];
  return useEffect(() => {
    if (reference === 'business') {
      localeStore.setLocale(businessesStore?.selectedBusiness?.countryCode);
    } else {
      localeStore.setLocale(practiceStore?.countryCode);
    }
  }, dependencies);
};
