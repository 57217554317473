import React from 'react';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import EditorRowActions from './EditorRowActions';
import AddComponent from './EditorComponents/AddComponent';

const ComponentRow = ({
  block
} : {
  block: PracticeTypes.ReportBlock,
}) => {
  const className = 'report-editor__content__row';
  return (
    <section className={className}>
      <EditorRowActions
        block={null}
        blockInstance={-1}
      />
      <AddComponent
        block={block}
        className={`${className}--content`}
      />
    </section>
  );
};

export default observer(ComponentRow);
