import React from 'react';
import SubLayoutSettings from '../../layouts/SubLayoutSettings';
import AccountNavbar from '../../components/accountSettings/AccountNavbar';
import AccountHeader from '../../components/accountSettings/Header';
import AssistantSettings from '../../components/accountSettings/manageAssistantPrompts/AssistantSettings';

const Assistant = () => (
  <SubLayoutSettings
    SubNav={(<AccountNavbar />)}
    HeaderContent={(<AccountHeader />)}
    SubMain={(<AssistantSettings />)}
  />
);

export default Assistant;
