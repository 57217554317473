import * as React from 'react';

/**
 * @param props
 * @returns
 */
export const RoundedBoxClock = () => (
  <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1' y='1.95215' width='26' height='26' rx='5' fill='white' />
    <path d='M14.6438 6.2259C14.4295 6.21008 14.2148 6.20216 14 6.20215V4.95215C14.2457 4.95227 14.4913 4.96144 14.7362 4.97965L14.6438 6.2259ZM17.1488 6.7884C16.7481 6.63378 16.3365 6.50886 15.9175 6.41465L16.1913 5.19465C16.67 5.30215 17.1412 5.44465 17.5987 5.62215L17.1488 6.7884ZM18.8612 7.6759C18.6825 7.55671 18.4995 7.44413 18.3125 7.3384L18.9288 6.2509C19.3561 6.49307 19.7651 6.7663 20.1525 7.0684L19.3837 8.05465C19.2143 7.92244 19.04 7.79652 18.8612 7.67715V7.6759ZM21.1537 9.9134C20.9065 9.56212 20.6337 9.22955 20.3375 8.9184L21.2425 8.0559C21.58 8.41215 21.8925 8.7934 22.1763 9.1934L21.1537 9.9134ZM22.0837 11.6034C22.0017 11.4052 21.9125 11.2101 21.8163 11.0184L22.9325 10.4559C23.1532 10.8948 23.3413 11.3493 23.495 11.8159L22.3075 12.2071C22.2403 12.0032 22.1657 11.8018 22.0837 11.6034ZM22.7463 14.7371C22.7361 14.3076 22.6944 13.8793 22.6213 13.4559L23.8525 13.2434C23.9362 13.7259 23.985 14.2159 23.9975 14.7059L22.7475 14.7371H22.7463ZM22.5825 16.6596C22.6238 16.4471 22.6575 16.2359 22.6837 16.0221L23.925 16.1759C23.865 16.6636 23.7689 17.1462 23.6375 17.6196L22.4325 17.2859C22.49 17.0796 22.54 16.8709 22.5825 16.6596ZM21.3925 19.6334C21.6225 19.2709 21.825 18.8909 22 18.4984L23.1425 19.0046C22.9425 19.4546 22.7112 19.8871 22.4487 20.3021L21.3925 19.6334ZM20.1875 21.1396C20.34 20.9871 20.4863 20.8296 20.625 20.6671L21.5725 21.4834C21.412 21.6694 21.2449 21.8496 21.0712 22.0234L20.1875 21.1396Z' fill='#0A1E70' />
    <path d='M14 6.20215C12.5611 6.20225 11.1444 6.55721 9.87547 7.23557C8.60651 7.91394 7.52443 8.89478 6.72507 10.0912C5.92571 11.2876 5.43374 12.6627 5.29274 14.0947C5.15174 15.5267 5.36606 16.9713 5.91672 18.3007C6.46738 19.6301 7.33738 20.8031 8.44966 21.716C9.56194 22.6288 10.8822 23.2532 12.2934 23.534C13.7047 23.8147 15.1634 23.7431 16.5403 23.3255C17.9173 22.9078 19.17 22.1571 20.1875 21.1396L21.0712 22.0234C19.9084 23.1869 18.4766 24.0456 16.9025 24.5234C15.3285 25.0012 13.661 25.0834 12.0476 24.7628C10.4342 24.4421 8.92482 23.7284 7.65318 22.6849C6.38155 21.6415 5.38692 20.3005 4.75742 18.7808C4.12792 17.261 3.88298 15.6095 4.04432 13.9725C4.20565 12.3355 4.76827 10.7635 5.68233 9.39592C6.59639 8.02832 7.83367 6.90729 9.28453 6.13215C10.7354 5.35702 12.3551 4.95171 14 4.95215V6.20215Z' fill='#0A1E70' />
    <path d='M13.375 8.70215C13.5408 8.70215 13.6997 8.768 13.8169 8.88521C13.9342 9.00242 14 9.16139 14 9.32715V15.8396L18.06 18.1596C18.1997 18.2439 18.301 18.3796 18.342 18.5375C18.3831 18.6955 18.3608 18.8632 18.2798 19.0049C18.1988 19.1466 18.0657 19.251 17.9087 19.2958C17.7518 19.3406 17.5836 19.3222 17.44 19.2446L13.065 16.7446C12.9693 16.69 12.8898 16.6111 12.8345 16.5158C12.7792 16.4205 12.75 16.3123 12.75 16.2021V9.32715C12.75 9.16139 12.8158 9.00242 12.9331 8.88521C13.0503 8.768 13.2092 8.70215 13.375 8.70215Z' fill='#0A1E70' />
    <rect x='1' y='1.95215' width='26' height='26' rx='5' stroke='#0A1E70' strokeWidth='2' />
  </svg>
);
