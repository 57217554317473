import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { observer } from 'mobx-react';
import slugify from 'slugify';
import { useStore } from '../../../stores/Store';
import TextPill from '../../TextPill';
import { AccountSettings } from '../../../models/enums/components';
import PromptItem from './PromptItem';
import { UserPrompt } from '../../../stores/assistantStore';
import AiderAlert from '../../AiderAlert';

const AssistantSettings = () => {
  const { assistantStore } = useStore();
  const titles = {
    dashboardPrompts: 'Dashboard Assistant Prompts',
    insightsPrompts: 'Insights Assistant Prompts',
    assistantPrompts: 'AI Assistant Prompts',
  };
  return (
    <>
      <header className='sub-content__header '>
        <h2 className='sub-content__header--title wrapper'>{AccountSettings.ASSISTANT} <TextPill type='secondary' size='small'>NEW</TextPill></h2>
      </header>
      <Content className='sub-content__content scrollbar'>
        <div className='wrapper'>
          {
            Object.keys(assistantStore.paginatedPrompts)
              .map((key) => {
                const prompts: UserPrompt[] = assistantStore.paginatedPrompts[key];

                if (!prompts.length) {
                  return (
                    <React.Fragment key={`${key}-header`}>
                      <div className='checklist__section__header'>
                        <h2 className='checklist__section__header__title'>
                          {titles[key]}
                        </h2>
                      </div>
                      <AiderAlert
                        className='prompt-banner'
                        type='info'
                      >No prompts saved
                      </AiderAlert>
                    </React.Fragment>
                  );
                }

                return prompts.map((prompt, inx) => (
                  <React.Fragment key={prompt?.id ? prompt.id : slugify(prompt.label + prompt.text)}>
                    {inx === 0 && (
                    <div className='checklist__section__header'>
                      <h2 className='checklist__section__header__title'>
                        {titles[key]}
                      </h2>
                    </div>
                    )}
                    <PromptItem prompt={prompt} />
                  </React.Fragment>
                ));
              })
          }
        </div>
      </Content>
    </>
  );
};
export default observer(AssistantSettings);
