import React from 'react';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { CustomRuleEnums, PeriodCloseTypes } from '@aider/constants-library';

import { useStore } from '../../stores/Store';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import GenAIWhite from '../icons/GenAIWhite';

const PromptRuleButton = ({
  checklistIdentifier,
}: {
  checklistIdentifier: PeriodCloseTypes.ChecklistItem['checklistIdentifier'];
}) => {
  const rootStore = useStore();
  const { businessesStore, rulesStore } = rootStore;
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const rule = rulesStore.rule(
    businessesStore.selectedBusiness?.id,
    checklistIdentifier.checklistId,
    checklistIdentifier.sectionId,
    checklistIdentifier.itemId
  );
  const onClick = async () => {
    setButtonLoading(true);
    // run the rule execution of the LLM
    await rulesStore.handlePromptRule(rule, checklistIdentifier.sectionId, checklistIdentifier.itemId);
    // Set the button loading to false after the rule has been executed
    setButtonLoading(false);
  };

  return (rule?.rule?.show === CustomRuleEnums.RuleShow.prompt) && (
    <div className='dataHealthChecklist__row--multi-action__block'>
      <Tooltip
        title='Execute AI Prompt'
        placement='bottom'
      >
        <ButtonSecondary
          loading={buttonLoading}
          disabled={buttonLoading}
          type='default'
          ghost
          shape='circle'
          icon={<GenAIWhite className='icon__blue' />}
          className='rule-button rule-button__edit'
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
};

export default observer(PromptRuleButton);
