export enum ValuePrefix {
  currency = '$',
  showTop = 'show top',
  none = '',
  percentage = '',
}

export enum ValueSuffix {
  currency = '',
  showTop = '',
  none = '',
  percentage = '% or more',
}

export enum ValueDefault {
  currency = '',
  showTop = '10',
  none = '',
  percentage = '',
}

export enum ValueLabel {
  currency = 'Amount',
  showTop = 'No. of Trans.',
  none = '',
  percentage = 'Percent',
}
