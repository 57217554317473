import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ClientManagementEnums } from '@aider/constants-library';
import cookie from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../stores/Store';
import { Routers } from '../models/enums/utils';
import { trackMixpanelEvent } from '../lib/mixpanel';
import { CookieKeys } from '../ts/enums/Constants';

const AppReferral = ({ type }: {type: 'login' | 'connect' | 'disconnect' }) => {
  const rootStore = useStore();
  const { authenticationStore, loadingStore } = rootStore;
  const navigate = useNavigate();
  const { ospKey } = useParams();
  loadingStore.setLoading('auth');
  useEffect(() => {
    if (!ospKey) {
      trackMixpanelEvent({ description: 'App Referral', properties: { osp: 'Missing OSP' }, rootStore });
      navigate(Routers.LOGIN);
    } else if (ospKey === ClientManagementEnums.OSPKeys.intuit) {
      switch (type) {
        case 'connect':
          cookie.set(CookieKeys.appConnect, ospKey);
          trackMixpanelEvent({ description: 'App Referral Connect', properties: { ospKey }, rootStore });
          break;
        case 'disconnect':
          cookie.set(CookieKeys.appDisconnect, ospKey);
          trackMixpanelEvent({ description: 'App Referral Disconnect', properties: { ospKey }, rootStore });
          break;
        case 'login':
        default:
          cookie.set(CookieKeys.appReferral, ospKey);
          trackMixpanelEvent({ description: 'App Referral Login', properties: { ospKey }, rootStore });
          break;
      }
      authenticationStore.signInWithSSO('intuit', 'quickbooks');
    } else {
      loadingStore.setDoneLoading('auth');
      trackMixpanelEvent({ description: 'App Referral', properties: { osp: 'Missing OSP' }, rootStore });
      navigate(Routers.LOGIN);
    }
  }, []);

  return <></>;
};

export default observer(AppReferral);
