import React, { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { WarningIllustration } from '../../icons';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import { useStore } from '../../../stores/Store';

const PaywallModalContent = () => {
  const { subscriptionStore } = useStore();
  const [loading, setLoading] = useState(false);
  return (
    <section className='paywall-modal__body centered-modal'>
      <div className='illustration'>
        <WarningIllustration />
      </div>
      <h3>Account Deactivated</h3>
      <p>
        Please set up a valid payment method to regain access to your Aider Dashboard,
        data compliance checklists, performance insights, workflow support and more.
      </p>
      <ButtonPrimary
        loading={loading}
        size='large'
        onClick={() => {
          setLoading(true);
          subscriptionStore.paywallRedirect();
        }}
        mixpanelEvent='Paywall Modal - Manage Payment Settings'
      >
        Manage payment settings
      </ButtonPrimary>
      <ButtonPrimary
        href='https://ask.aider.ai/help/aip-subscription-billing-faqs'
        target='_blank'
        type='link'
        mixpanelEvent='Paywall Modal - Subscription FAQs'
        icon={<RightOutlined />}
      >
        Subscription FAQs
      </ButtonPrimary>

      <ButtonPrimary
        href='https://ask.aider.ai/help/kb-tickets/new'
        target='_blank'
        type='link'
        mixpanelEvent='Paywall Modal - Contact CS Team'
        icon={<RightOutlined />}
      >
        Contact Customer Success Team
      </ButtonPrimary>

    </section>
  );
};

export default observer(PaywallModalContent);
