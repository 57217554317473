import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Markdown from 'markdown-to-jsx';
import { DateFormats } from '@aider/aider-formatting-library';
import { useAuthenticatedEffect } from '../../lib/customHooks/useAuthenticatedEffect';
import { useStore } from '../../stores/Store';
import SummarySelector from './SummarySelector';
import { SummaryType } from '../../stores/clientSummaryStore';

export enum ActivityType {
  bankTransaction = 'Bank Transactions',
  invoice = 'Invoices',
  bill = 'Bills',
  activeAccount = '# of Active Accounts per Xero file',
  accountAboveBelow = '# of Accounts above/below $0',
}

const Overview = () => {
  const { businessesStore, clientSummaryStore, localeStore } = useStore();

  const [summaryType, setSummaryType] = React.useState('');

  useAuthenticatedEffect(() => {
    // fetch data
    if (!clientSummaryStore.summary || clientSummaryStore.summaryType !== summaryType) {
      clientSummaryStore.fetchSummaryData();
      setSummaryType(clientSummaryStore.summaryType);
    }
  }, [clientSummaryStore.summaryType, clientSummaryStore.summary]);

  const summaryData = () => {
    const { summary } = clientSummaryStore;

    if (!summary) {
      return [];
    }

    return summary.activity.map((data, index) => {
      const { activity, previous, last, current } = data;

      return {
        key: `${activity}_${index}`,
        activity: localeStore.translation(`overview.table.body.${activity}`, {
          osp: localeStore.translation(`osps.${businessesStore.selectedBusinessOsp}`),
        }),
        previous: (previous?.value || 0).toLocaleString(),
        last: (last?.value || 0).toLocaleString(),
        current: (current?.value || 0).toLocaleString(),
      };
    });
  };

  const getDateString = (key) => {
    const { summary } = clientSummaryStore;

    if (!summary) {
      return '';
    }

    const start = summary.activity[0]?.[key]?.start;
    const end = summary.activity[0]?.[key]?.end;

    if (start && end) {
      const startDate = localeStore.formatDate(start, DateFormats.dayMonth);
      const endDate = localeStore.formatDate(end, DateFormats.string);
      return `(${startDate} - ${endDate})`;
    }

    return '';
  };

  const summaryColumnTitle = ({ key }) => {
    const type = clientSummaryStore.summaryType;
    const periodType = _.capitalize(key);
    const dates = getDateString(key);

    if (key === 'current' && type === SummaryType.fy) {
      return <Markdown>{localeStore.translation('overview.table.header.fytd', { periodType, dates })}</Markdown>;
    }

    if (type === SummaryType.fy) {
      return <Markdown>{localeStore.translation('overview.table.header.fy', { periodType, dates })}</Markdown>;
    }

    if (type === SummaryType.gst) {
      return <Markdown>{localeStore.translation('overview.table.header.gst', { periodType, dates })}</Markdown>;
    }

    return '';
  };

  const summaryColumns = () => (
    [
      {
        title: localeStore.translation('overview.table.header.activity'),
        align: 'left',
        dataIndex: 'activity',
        key: 'activity',
        width: 500,
      },
      {
        title: summaryColumnTitle({ key: 'previous' }),
        align: 'right',
        dataIndex: 'previous',
        key: 'previous',
        width: 500,
      },
      {
        title: summaryColumnTitle({ key: 'last' }),
        align: 'right',
        dataIndex: 'last',
        key: 'last',
        width: 500,
      },
      {
        title: summaryColumnTitle({ key: 'current' }),
        align: 'right',
        dataIndex: 'current',
        key: 'current',
        width: 500,
      },
    ]
  );

  return (
    <>
      <div className='activity_summary-header'>
        <h2 className='activity_summary-title'>Activity Summary</h2>
        <SummarySelector className='actvity_summary-selector' />
      </div>
      <Table
        className='activity_summary-table'
        dataSource={summaryData()}
        columns={summaryColumns() as ColumnsType}
        pagination={false}
      />
    </>
  );
};

export default observer(Overview);
