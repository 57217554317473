import React, { useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'antd';
import * as Sentry from '@sentry/browser';
import { ModalWidths } from '../../../models/enums/modals';
import { IStandardModalProps } from '../../../models/interfaces/modals';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import { ExclamationAlert } from '../../../components-v2/atoms/Icons';
import { useStore } from '../../../stores/Store';

/**
 * Component that renders a basic modal
 * @param {string} name - the name of the modal (used when tracking)
 * @param {string} title - the title of the modal
 * @param {ModalWidths} width - the width of the modal (default: ModalWidths.SMALL)
 * @param {ModalHeights} height - the height of the modal (default: null)
 * @param {string} minHeight - the minimum height of the modal (default: null)
 * @param {string} maxHeight - the maximum height of the modal (default: null)
 * @param {React.ReactChildren} content - the content of the modal
 * @param {React.ReactChildren} errorMessage - the content of the error message
 * @param {string} okText - the text of the ok button (default: null)
 * @param {string} cancelText - the text of the cancel button (default: null)
 * @param {any} okButtonProps - the props of the ok button
 *                                 (default: { shape: 'round', size: 'large' })
 * @param {any} cancelButtonProps - the props of the cancel button
 *                                     (default: { shape: 'round', size: 'large' })
 * @param {() => any} onOk - the function to be executed when the ok button is clicked
 *                              (default: undefined)
 * @param {() => any} onCancel - the function to be executed when the cancel button is clicked
 *                                  (default: undefined)
 * @param {() => any} afterClose - the function to be executed when the modal is closed
 *                                     (default: undefined)
 * @param {boolean} asyncOk - whether the ok button should be async or not.
 *                               **if true, onOk should return a promise**
 *                               (default: false)
 * @param {string} className - class name of the container of the modal dialog
 * @param {React.ReactNodeArray} footer - the footer of the modal
 * @param {boolean} closable - whether the modal should be closable or not
 * @param {boolean} maskClosable - whether the modal should be closable by clicking on the mask
 * @param {boolean} keyboard - whether the modal should be closable by pressing the esc key
 * @param {boolean} hasFollowUpModal - whether the modal will be calling a follow up modal
 * @param {RootStore} rootStore - the root store
 * @param {any} rest - the rest of the props
 */
export default NiceModal.create((
  {
    name,
    title = null,
    width = ModalWidths.SMALL,
    height = null,
    minHeight = null,
    maxHeight = null,
    content,
    errorMessage = 'Something went wrong',
    okText = null,
    cancelText = null,
    okButtonProps = {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
    },
    cancelButtonProps = {
      className: 'button-secondary',
      danger: true,
      shape: 'round',
      size: 'large'
    },
    onOk = undefined,
    onCancel = undefined,
    afterClose = undefined,
    className = undefined,
    footer = undefined,
    closable = true,
    maskClosable = true,
    keyboard = true,
    hasFollowUpModal = false,
    navigateTo = null,
  } : IStandardModalProps
) => {
  const modal = useModal();
  const rootStore = useStore();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  useEffect(() => {
    trackMixpanelEvent({ description: `${name} - Modal Opened`, rootStore, properties: { modal: name } });
  }, [name, rootStore]);

  const performNavigation = () => {
    rootStore.pageStore.setNavigateTo(navigateTo);
  };
  const okAction = async () => {
    trackMixpanelEvent({ description: `${name} - Modal Confirmed`, rootStore, properties: { modal: name } });
    if (onOk) {
      setHasError(false);
      setConfirmLoading(true);
      await onOk().then((res) => {
        setConfirmLoading(false);
        if (res?.status === 'error') {
          if (res.error.errorFields) return;
          setHasError(true);
          return;
        }
        if (!hasFollowUpModal) modal.hide();
        if (navigateTo) {
          performNavigation();
        }
      })
        .catch((err) => {
          Sentry.captureException(err);
          return err;
        });
      return;
    }
    if (!hasFollowUpModal) modal.hide();
  };

  const cancelAction = () => {
    trackMixpanelEvent({ description: `${name} - Modal closed`, rootStore, properties: { modal: name } });
    if (onCancel) onCancel();
    modal.hide();
  };

  const afterCloseAction = () => {
    if (afterClose) afterClose();
    modal.remove();
  };

  const optionalProps:{footer?: React.ReactNodeArray} = { };
  if (typeof footer !== 'undefined') {
    optionalProps.footer = footer;
  }

  const modalClassName = `aider-modal ${className || ''}`;

  return (
    <Modal
      open={modal.visible}
      title={title}
      width={width}
      bodyStyle={{ minHeight, maxHeight, height }}
      centered
      onOk={okAction}
      onCancel={cancelAction}
      afterClose={afterCloseAction}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      okText={okText}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      wrapClassName={modalClassName}
      closable={closable}
      maskClosable={maskClosable}
      keyboard={keyboard}
      {...optionalProps}
    >
      {hasError && <div className='error-message'><ExclamationAlert style={{ marginBottom: '0.2em' }} /> {errorMessage}</div>}
      {content}
    </Modal>
  );
});
